import {
	pieBorderlineColor,
	pieFailedColor,
	pieMissingInformationColor,
	pieNotCostEffectiveColor,
	piePassedColor
} from 'Services/colors'
import { partResults } from 'Services/Constants'
import { PART, PARTS } from 'Services/Strings'

export const initChartData = () => {
	return [
		{
			type: partResults.printable,
			count: 0,
			percent: 0,
			name: 'printable',
			color: piePassedColor
		},
		{
			type: partResults.borderline,
			count: 0,
			percent: 0,
			name: 'borderline',
			color: pieBorderlineColor
		},
		{
			type: partResults.notPrintable,
			count: 0,
			percent: 0,
			name: 'notPrintable',
			color: pieFailedColor
		},
		{
			type: partResults.notCostEffective,
			count: 0,
			percent: 0,
			name: 'notCostEffective',
			color: pieNotCostEffectiveColor
		},
		{
			type: partResults.missingInformation,
			count: 0,
			percent: 0,
			name: 'missingInformation',
			color: pieMissingInformationColor
		},
		{
			type: partResults.sheetMetal,
			count: 0,
			percent: 0,
			name: partResults.sheetMetal,
			color: pieMissingInformationColor
		}
	]
}

export const countToPieChartLabel = (percent: number) =>
	percent <= 0.01 ? ' ' : `${percent}%`

export const countToPieChartLongLabel = (count: number, percent: number) =>
	count === 0
		? ' '
		: `${count} ${(count > 1 ? PARTS : PART).toLowerCase()}\n(${percent}%)`

export const calculatePercent = (count: number, total: number) =>
	((100 * count) / total).toFixed(1)

export const calculateRestPercent = (counts: number[], total: number) => {
	let othersPercent = 0
	counts.forEach(
		count => (othersPercent += parseFloat(((100 * count) / total).toFixed(1)))
	)
	const restPercentage = (100 - othersPercent).toFixed(1)

	return +restPercentage < 0 ? 0 : restPercentage
}

export const setChartDataCounter = (chartData: any, type: string) => {
	const dataIndex = chartData.findIndex((data: any) => data.type === type)
	if (dataIndex !== -1) {
		chartData[dataIndex].count = chartData[dataIndex].count + 1
	}
}

export const setChartDataCount = (
	chartData: any,
	type: string,
	count: number
) => {
	const dataIndex = chartData.findIndex((data: any) => data.type === type)
	if (dataIndex !== -1) {
		chartData[dataIndex].count = count
	}
}
