import React, { FC, useEffect } from 'react'
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { formValueSelector } from 'redux-form'

import { STEP_ID } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { companies, technology } from 'Scenes/Components/PrinterForm/constants'
import PrinterForm from 'Scenes/Components/PrinterForm/PrinterForm'
import {
	createNewPrinterAndRemovePrinterName,
	newCompanySelected,
	onMaintenanceMachineCostFieldsSelectorNewPrinterChange,
	resetData
} from 'Scenes/Home/Customize/CustomizeUserMaterials/CustomizeUserMaterialsActions'

type IProps = {
	selectedPrinterCompany: string
	selectedPrinterTechnology: string
}

const NewPrinterForm: FC<IProps> = ({
	selectedPrinterCompany,
	selectedPrinterTechnology
}) => {
	const { parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const {
		isNewCompanyDisabled,
		maintenanceMachineSelectorsValueNewPrinter,
		selectedNewPrinter,
		addingNewItem,
		onMachineCostValue,
		onMachineCostSelector,
		printerAdded
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizeUserMaterialsReducer
	)
	const { printingTechnologies, printersFullData } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const dispatch = useDispatch()

	const onSubmit = (data: Record<string, any>) => {
		dispatch(
			createNewPrinterAndRemovePrinterName(data, adminSelectedOrganizationId)
		)
	}

	useEffect(() => {
		if (printerAdded) {
			dispatch(goToNextStep(parentSteps, currentStep, STEP_ID.ADD_PRINTER))
		}
	}, [currentStep, dispatch, parentSteps, printerAdded])

	useEffect(() => {
		dispatch(resetData())
	}, [dispatch])

	return (
		<div className="tm-material--form-wrapper">
			<PrinterForm
				selectedPrinterTechnology={selectedPrinterTechnology}
				isNewCompanyDisabled={isNewCompanyDisabled}
				onSubmit={onSubmit}
				newCompanySelected={(company: string) =>
					dispatch(newCompanySelected(company))
				}
				selectedPrinterCompany={selectedPrinterCompany}
				onMaintenanceMachineCostFieldsSelectorChange={(
					e: React.ChangeEvent<HTMLInputElement>
				) =>
					dispatch(onMaintenanceMachineCostFieldsSelectorNewPrinterChange(e))
				}
				onMachineCostSelector={onMachineCostSelector}
				maintenanceMachineCostFieldsSelectorValue={
					maintenanceMachineSelectorsValueNewPrinter
				}
				onMachineCostValue={onMachineCostValue}
				initialValues={selectedNewPrinter}
				printingTechnologies={printingTechnologies}
				addingNewItem={addingNewItem}
				printersFullData={printersFullData}
				isAdminForm={false}
			/>
		</div>
	)
}

const selector = formValueSelector('adminPrinterFormState')

const mapStateToProps = (state: Record<string, unknown>) => {
	return {
		selectedPrinterCompany: selector(state, companies),
		selectedPrinterTechnology: selector(state, technology)
	}
}

export default connect(mapStateToProps)(NewPrinterForm)
