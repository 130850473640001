import React, { FC, useEffect, useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import { onOrganizationSelect } from '../CustomizeActions'
import AddOrganizationModal from './AddOrganizationModal'
import EditOrganizationModal from './EditOrganizationModal'
import Devider from 'Scenes/Components/Devider/Devider'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { IOrganization } from 'Services/models/IOrganization'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

import { ReactComponent as Plus } from 'assets/img/svg/icons/plus-circle.svg'

const PlusIcon: any = Plus

type IProps = {
	showEdit: boolean
	showAdd: boolean
}

const OrganizationPanel: FC<IProps> = ({ showEdit, showAdd }) => {
	const [showEditModal, setShowEditModal] = useState(false)
	const [showAddModal, setShowAddModal] = useState(false)
	const { availableOrganizations, organizationDetails, isAdminByRole } =
		useSelector((state: RootStateOrAny) => state.user)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()

	const adminSelectedOrganization = useMemo(
		() =>
			availableOrganizations.find(
				(organization: IOrganization) =>
					organization.id === adminSelectedOrganizationId
			),
		[adminSelectedOrganizationId, availableOrganizations]
	)

	const selectedOrganization: IOrganization = isAdminByRole
		? adminSelectedOrganization
		: organizationDetails

	useEffect(() => {
		if (!adminSelectedOrganizationId) {
			dispatch(onOrganizationSelect(organizationDetails?.id))
		}
	}, [adminSelectedOrganizationId, dispatch, organizationDetails?.id])

	const onEditOrganizationClick = () => {
		setShowEditModal(true)
	}

	const cancelEditOrganizationModal = () => {
		setShowEditModal(false)
	}

	const onAddOrganizationClick = () => {
		setShowAddModal(true)
	}

	const cancelAddOrganizationModal = () => {
		setShowAddModal(false)
	}

	const handleOrganizationSelect = (value: number) => {
		dispatch(onOrganizationSelect(value))
	}

	return (
		<>
			<Devider />
			<div className="customize-settings--header--organization">
				<div className="customize-settings--header--organization-name">
					<div>
						{getString('SETTINGS_CURRENT_SITE_LABEL')}{' '}
						<b>{selectedOrganization?.name}</b>
					</div>
					{showEdit && <EditIcon onClick={onEditOrganizationClick} />}
				</div>
				{isAdminByRole && (
					<div className="customize-settings--header--organization-selector">
						<span>{getString('SELECT_ORGANIZATION_LABEL')}</span>
						<Select
							disabled={!showAdd}
							className="customize-settings--header--organization--select-field"
							value={adminSelectedOrganizationId}
							renderValue={selected =>
								renderOrganizationValue(
									availableOrganizations,
									selected as number
								)
							}
							title={renderOrganizationValue(
								availableOrganizations,
								adminSelectedOrganizationId
							)}
							onChange={event =>
								handleOrganizationSelect(event.target.value as number)
							}
							classes={{
								select: 'customize-settings--header--organization--select'
							}}
							inputProps={{
								name: 'simpleSelect',
								id: 'simple-select'
							}}
							MenuProps={{
								PaperProps: {
									style: {
										transform: 'translate3d(0, 0, 0)'
									}
								}
							}}
						>
							{availableOrganizations?.map((organization: IOrganization) => {
								return (
									<MenuItem key={organization.id} value={organization.id}>
										{`${organization?.name}${
											organization?.private ? ` (${getString('PRIVATE')})` : ''
										}`}
									</MenuItem>
								)
							})}
						</Select>
						{showAdd && (
							<PlusIcon
								className="add-new-organization"
								onClick={onAddOrganizationClick}
							/>
						)}
					</div>
				)}
			</div>
			<EditOrganizationModal
				show={showEditModal}
				savedOrganizationName={selectedOrganization?.name}
				isOrganizationPrivate={!!selectedOrganization?.private}
				organizationId={selectedOrganization?.id}
				onCancel={cancelEditOrganizationModal}
			/>
			<AddOrganizationModal
				show={showAddModal}
				onCancel={cancelAddOrganizationModal}
			/>
		</>
	)
}

export default WithFeatureToggleHOC(
	OrganizationPanel,
	FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
)
