import React, { Component, memo } from 'react'

import classnames from 'classnames'
import cx from 'classnames'

import ImageAndDescriptionCard from '../../PartAnalysis/PartImageAndDescription/ImageAndDescriptionCard'
import AssemblyGallery from './components/AssemblyGallery'
import BenefitAnalysisChart from './components/BenefitAnalysisChart'
import PrintabilityAnalysisChart from './components/PrintabilityAnalysisChart'
import {
	calculatePercent,
	calculateRestPercent,
	countToPieChartLabel,
	countToPieChartLongLabel,
	initChartData,
	setChartDataCount
} from './ProjectResultDistributionService'
import FlexBox from 'Scenes/Components/FlexBox'

import './ProjectResultDistributionChart.scss'

class ProjectResultDistributionChart extends Component {
	constructor(props) {
		super(props)
		this.state = {
			partResultHovered: ''
		}
	}

	createChartData(partsCount, printabilityData) {
		if (partsCount === 0 || !printabilityData) {
			return null
		}
		const chartData = initChartData()

		Object.keys(printabilityData).forEach(type => {
			setChartDataCount(chartData, type, printabilityData[type])
		})

		chartData.forEach(data => {
			data.percent = calculatePercent(data.count, partsCount)
		})

		const maxCountType = chartData.sort((a, b) => b.count - a.count)[0]
		chartData.find(data => data.type === maxCountType.type).percent =
			calculateRestPercent(
				chartData
					.filter(data => data.type !== maxCountType.type)
					.map(data => data.count),
				partsCount
			)

		return chartData.map(data => {
			return {
				x: countToPieChartLabel(data.percent),
				y: data.count,
				label: countToPieChartLongLabel(data.count, data.percent),
				name: data.name,
				color: data.color
			}
		})
	}

	renderPrintableBenefitsResultsStats = () => {
		const {
			benefitsState,
			numberOfAllBenefits,
			numberOfPrintableParts,
			numberOfPrintablePart,
			isAssembly,
			totalPartsCount,
			printabilityData
		} = this.props

		const partsCountWithoutFailed = totalPartsCount - printabilityData?.failed

		if (!partsCountWithoutFailed) {
			return <></>
		}

		return (
			<BenefitAnalysisChart
				numberOfPrintableParts={numberOfPrintableParts}
				numberOfPrintablePart={numberOfPrintablePart}
				numberOfAllBenefits={numberOfAllBenefits}
				benefitsState={benefitsState}
				isAssembly={isAssembly}
				styles={styles}
			/>
		)
	}

	renderPrintabilityAnalysis = (chartData, partsCountWithoutFailed) => {
		const {
			numberOfPrintableParts,
			isAssembly,
			is2dProject,
			allPartsInapplicable,
			isMetaDataProject
		} = this.props

		return (
			<PrintabilityAnalysisChart
				allPartsInapplicable={allPartsInapplicable}
				chartData={chartData}
				partsCountWithoutFailed={partsCountWithoutFailed}
				numberOfPrintableParts={numberOfPrintableParts}
				isAssembly={isAssembly}
				styles={styles}
				is2dProject={is2dProject}
				isMetaDataProject={isMetaDataProject}
			/>
		)
	}

	render() {
		const {
			printabilityData,
			totalPartsCount,
			contentStyle,
			isAssembly,
			assemblyImage,
			image,
			name,
			allPartsInapplicable,
			projectsAssembly
		} = this.props

		const partsCountWithoutFailed = totalPartsCount - printabilityData?.failed
		const chartData = this.createChartData(
			partsCountWithoutFailed,
			printabilityData
		)

		return (
			<FlexBox
				flexDirection="row"
				className={classnames('with-scroll', {
					'without-assembly-image': !isAssembly,
					small: allPartsInapplicable || !chartData
				})}
				style={{ ...styles.horizontalFlex, ...contentStyle }}
			>
				{this.renderPrintabilityAnalysis(chartData, partsCountWithoutFailed)}
				{this.renderPrintableBenefitsResultsStats()}
				{isAssembly && (
					<FlexBox
						flexDirection="column"
						className={cx('project-results--block with-image', {
							gallery: projectsAssembly
						})}
						style={styles.footerFlex}
					>
						{projectsAssembly ? (
							<AssemblyGallery projectsAssembly={projectsAssembly} />
						) : (
							<ImageAndDescriptionCard
								inResultBlock
								image={image}
								title={name}
								style={assemblyImage}
								imageInsideCard={true}
							/>
						)}
					</FlexBox>
				)}
			</FlexBox>
		)
	}
}

const styles = {
	card: {},
	horizontalFlex: { flex: 1, alignItems: 'flex-start' },
	footerFlex: { height: '100%' },
	footer: { flex: 1, marginLeft: 0 },
	pieSuper: { flex: 2 },
	text: {
		width: '100%',
		textAlign: 'left',
		lineHeight: '16px'
	}
}

export default memo(ProjectResultDistributionChart)
