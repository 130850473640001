import {
	ADMIN_USER_DATEPICKER_TOGGLED,
	ADMIN_USER_DELETED,
	ADMIN_USER_FETCHED,
	ADMIN_USER_GENERATE_REPORT_LOADING,
	ADMIN_USER_INFO_UPDATE_SUCCESS,
	ADMIN_USER_INFO_UPDATE_TOGGLED,
	ADMIN_USER_PERMISSION_SUCCESS,
	ADMIN_USER_PERMISSION_TOGGLED,
	ADMIN_USER_SELECTED,
	ADMIN_USERS_DESKTOP_PRINTER_TOGGLED,
	ADMIN_USERS_SEARCH_PHRASE_CHANGED,
	GET_ADMIN_SORT_BY_CREATED_CLICKED,
	GET_ADMIN_SORT_BY_SUBSCRIPTION_CLICKED,
	GET_ADMIN_USER_INFO,
	GET_ADMIN_USER_INFO_ERROR,
	GET_ADMIN_USERS_CALLED,
	GET_ADMIN_USERS_GOT_ERROR,
	GET_ADMIN_USERS_SUCCESS,
	USER_INFO_EDIT_SELECT
} from '../../../../global actions/types'
import {
	createUserDataInfo,
	createUserDataMaterials,
	createUserDataPrinters,
	generateTableHeaders,
	makeRolesAsArray,
	verifyUserOnAdminRoles
} from './AdminUsersService'
import { adminUserTableParam, InfoData, SortBy } from './constants'
import { getString } from 'Services/Strings/StringService'

import '../adminHome.scss'

const INITIAL_STATE = {
	users: [],
	totalUsers: 0,
	usersTableHead: [],
	usersTableData: [],
	showNoUsersAlert: false,
	organizationOwner: false,
	adminUserManager: false,
	userOrganization: '',
	pageNumber: 1,
	isLastPage: false,
	NumOfUsersSeen: 0,
	showPagination: false,
	limitUsers: 50,
	showAdminUsersAlert: false,
	sortBy: '',
	sortASC: false,
	searchPhrase: '',
	userUpdateLoading: false,
	userSelected: null,
	loading: false,
	userSubscriptionInfoData: [],
	userPersonalInfoData: [],
	userProjectAndPartInfoData: [],
	userPrintersData: [],
	userMaterialsData: [],
	userPrintersHeaders: [],
	userMaterialsHeaders: [],
	usersRoles: [],
	showExportExcel: true,
	isAdminUserReportLoading: false,
	error: false
}

const AdminUsersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_ADMIN_USERS_CALLED:
			return { ...state, loading: true }
		case GET_ADMIN_USERS_SUCCESS:
			const { data, pageNumber } = action.payload,
				{ users, totalUsers, roles } = data,
				NumOfUsersSeen = (pageNumber - 1) * state.limitUsers + users.length,
				isLastPage = NumOfUsersSeen >= totalUsers
			return {
				...state,
				users,
				totalUsers,
				usersTableData: users.map(user => Object.values(user)),
				showNoUsersAlert: !users.length,
				pageNumber,
				NumOfUsersSeen,
				isLastPage,
				showPagination: !(isLastPage && pageNumber === 1),
				showAdminUsersAlert: false,
				loading: false,
				usersRoles: roles,
				userSelected: null
			}
		case GET_ADMIN_USERS_GOT_ERROR:
			return {
				...state,
				showAdminUsersAlert: true,
				loading: false
			}
		case GET_ADMIN_SORT_BY_SUBSCRIPTION_CLICKED:
			return { ...state, sortBy: SortBy.subscription, sortASC: !state.sortASC }
		case ADMIN_USER_DATEPICKER_TOGGLED:
			return {
				...state,
				showDatepicker: action.payload
			}
		case GET_ADMIN_SORT_BY_CREATED_CLICKED:
			return { ...state, sortBy: SortBy.created, sortASC: !state.sortASC }
		case ADMIN_USERS_DESKTOP_PRINTER_TOGGLED: {
			const { userId, desktopPrinter } = action.payload
			const users = state.users.map(user => {
				if (user.id === userId) {
					user.desktopPrinter = !desktopPrinter
				}
				return user
			})
			return {
				...state,
				users
			}
		}
		case ADMIN_USERS_SEARCH_PHRASE_CHANGED:
			const searchPhrase = action.payload
			return {
				...state,
				searchPhrase,
				showExportExcel: !searchPhrase
			}
		case USER_INFO_EDIT_SELECT:
			const { userSelected } = action.payload
			return {
				...state,
				userSelected
			}
		case ADMIN_USER_SELECTED:
			const {
				user,
				userPrinters,
				userMaterials,
				disableLicenseInfo,
				disableCompanyInfo
			} = action.payload
			const userRolesArray = makeRolesAsArray(user.roles)

			return {
				...state,
				loading: false,
				userSelected: {
					id: user.id,
					expireDate: user[adminUserTableParam.expireDate],
					partsCredit: user[adminUserTableParam.partsCredit],
					trial: user.trial,
					verified: user.verified,
					roles: userRolesArray,
					organizationOwner: user[adminUserTableParam.organizationOwner],
					allowRegularRegistration: user.allowRegularRegistration
				},
				userSubscriptionInfoData: createUserDataInfo(
					InfoData.subscriptionInfo,
					user,
					disableLicenseInfo,
					disableCompanyInfo
				),
				userPersonalInfoData: createUserDataInfo(
					InfoData.personalInfo,
					user,
					disableLicenseInfo,
					disableCompanyInfo
				),
				userProjectAndPartInfoData: createUserDataInfo(
					InfoData.projectAndParts,
					user,
					disableLicenseInfo,
					disableCompanyInfo
				),
				userPrintersData: createUserDataPrinters(userPrinters),
				userPrintersHeaders: generateTableHeaders([
					getString('PRINTER_ID'),
					getString('PRINTER_NAME')
				]),
				userMaterialsHeaders: generateTableHeaders(['Id', 'Name']),
				userMaterialsData: createUserDataMaterials(userMaterials),
				adminUserManager: verifyUserOnAdminRoles(userRolesArray),
				organizationOwner: user[adminUserTableParam.organizationOwner],
				userOrganization: user.organizationId
			}
		case ADMIN_USER_INFO_UPDATE_TOGGLED:
			return { ...state, userUpdateLoading: action.payload }

		case ADMIN_USER_INFO_UPDATE_SUCCESS:
			return {
				...state,
				userUpdateLoading: false,
				userSelected: null
			}
		case GET_ADMIN_USER_INFO:
			return { ...state, loading: action.payload }
		case ADMIN_USER_FETCHED: {
			const {
				user,
				userPrinters,
				userMaterials,
				roles,
				disableLicenseInfo,
				disableCompanyInfo
			} = action.payload
			const userRolesArray = makeRolesAsArray(user.roles)

			return {
				...state,
				userSelected: {
					id: user.id,
					expireDate: user[adminUserTableParam.expireDate],
					partsCredit: user[adminUserTableParam.partsCredit],
					trial: user.trial,
					verified: user.verified,
					roles: userRolesArray,
					organizationOwner: user[adminUserTableParam.organizationOwner],
					allowRegularRegistration: user.allowRegularRegistration
				},
				userSubscriptionInfoData: createUserDataInfo(
					InfoData.subscriptionInfo,
					user,
					disableLicenseInfo,
					disableCompanyInfo
				),
				userPersonalInfoData: createUserDataInfo(
					InfoData.personalInfo,
					user,
					disableLicenseInfo,
					disableCompanyInfo
				),
				userProjectAndPartInfoData: createUserDataInfo(
					InfoData.projectAndParts,
					user,
					disableLicenseInfo,
					disableCompanyInfo
				),
				userPrintersData: createUserDataPrinters(userPrinters),
				userMaterialsData: createUserDataMaterials(userMaterials),
				userPrintersHeaders: generateTableHeaders([
					getString('PRINTER_ID'),
					getString('PRINTER_NAME')
				]),
				userMaterialsHeaders: generateTableHeaders(['Id', 'Name']),
				loading: false,
				usersRoles: roles,
				adminUserManager: verifyUserOnAdminRoles(userRolesArray),
				organizationOwner: user[adminUserTableParam.organizationOwner],
				userOrganization: user.organizationId,
				userIdError: null,
				error: false
			}
		}
		case ADMIN_USER_GENERATE_REPORT_LOADING: {
			return {
				...state,
				isAdminUserReportLoading: action.payload
			}
		}
		case ADMIN_USER_PERMISSION_SUCCESS: {
			const { updatedUser } = action.payload
			const userRolesArray = makeRolesAsArray(updatedUser.roles || '')

			return {
				...state,
				userSelected: {
					...state.userSelected,
					organizationOwner: updatedUser.organization_owner,
					roles: userRolesArray
				}
			}
		}
		case ADMIN_USER_PERMISSION_TOGGLED: {
			const { parameter, value } = action.payload
			return {
				...state,
				[parameter]: value
			}
		}
		case GET_ADMIN_USER_INFO_ERROR: {
			return {
				...INITIAL_STATE,
				error: true,
				userIdError: action.payload
			}
		}
		case ADMIN_USER_DELETED: {
			return {
				...INITIAL_STATE
			}
		}
		default:
			return state
	}
}

export default AdminUsersReducer
