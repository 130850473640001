import React, { FC, memo } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { Close } from '@material-ui/icons'

import * as CustomizeCO2CalculationAction from '.././CustomizeCO2CalculationAction'
import { CO2Params } from '../CustomizeCO2CalculationEnums'
import {
	Button,
	ItemGrid,
	Table
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import { CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../CustomizeCO2Calculation.scss'

interface MaterialType {
	material: { type: string; category: string; name: string; id: number }
	co2PerKg: number
	disposeFactor: number
	printerName: string
}

interface IProps {
	materialList: Array<MaterialType>
	columnName?: keyof MaterialType
	deleteMaterial: Function
}

interface IReduxStore {
	CustomizeCO2CalculationReducer: any
}

const EmissionsRawMaterialTable: FC<IProps> = ({
	materialList,
	deleteMaterial,
	columnName = CO2Params.co2PerKg
}) => {
	const onDeleteMaterial = (itemToDelete: any) => {
		deleteMaterial(itemToDelete)
	}

	const renderDeleteButton = (itemToDelete: number) => {
		return (
			<Button
				title={CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM}
				onClick={() => onDeleteMaterial(itemToDelete)}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
			>
				<Close className="custom-calculation-co2--table--icon" />
			</Button>
		)
	}

	const renderCo2MaterialData = () => {
		return materialList.map((material: MaterialType) => [
			material.material.type,
			material.material.category,
			material.material.name,
			material[columnName],
			renderDeleteButton(material.material.id)
		])
	}

	if (materialList.length > 0) {
		return (
			<div className="customize__table">
				<ItemGrid>
					<Table
						editTableStyle={'co2CalculationTable'}
						tableHead={[
							getString('ORIGINAL_MATERIAL_TYPE'),
							getString('ORIGINAL_MATERIAL_CATEGORY'),
							getString('ORIGINAL_MATERIALS_BUTTON'),
							getString('CO2_PER_KG_PLACE_HOLDER'),
							getString('ACTIONS')
						]}
						tableData={renderCo2MaterialData()}
					/>
				</ItemGrid>
			</div>
		)
	} else {
		return <></>
	}
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeCO2CalculationAction }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(EmissionsRawMaterialTable)
)
