import { cloneDeep, filter, isArray, map, max, min, orderBy } from 'lodash'

import { ConfigurationResultTypes } from '../MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import { PRINTER_TECHNOLOGY } from '../NewPartConfiguration/constants'
import {
	addNewConfigurationId,
	addPrinterConfigurationId,
	configurationOrder,
	dotsColor,
	exponentialLineColor,
	fillAreaColor,
	getXAxisDropDownValues,
	seriesNames,
	solutionMapConstant,
	solutionMapScoreConstants
} from './PartAnalysisConstants'
import { getMaterialProperties } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/MainPartAnalysisService'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { chartGraphDataOptions } from 'Services/Constants'
import { Material } from 'Services/models/IMaterial'
import { Project } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'
import { getTheme } from 'themes/getTheme'

const theme = getTheme()

export const filterConfigurations = (
	configurations: any,
	isWeightReductionPage: boolean = false
) => {
	const orderedByDate =
		(configurations?.length > 0 &&
			orderBy(
				configurations?.filter(
					(conf: any) =>
						((conf.resultType === ConfigurationResultTypes.WeightReduction &&
							isWeightReductionPage) ||
							(conf.resultType !== ConfigurationResultTypes.WeightReduction &&
								!isWeightReductionPage)) &&
						conf?.id !== addNewConfigurationId &&
						conf?.id !== addPrinterConfigurationId
				),
				['createdAt'],
				['asc']
			)) ||
		[]

	return orderedByDate?.sort(
		(a, b) =>
			configurationOrder.indexOf(a?.order) -
			configurationOrder.indexOf(b?.order)
	)
}

export const getConfiguration = (configurations: any, solutionId: number) => {
	return configurations?.find(
		(configuration: any) => configuration.id === solutionId
	)
}

export const getAmOriginalMaterialType = (
	configurations: any,
	project: Project
) => {
	const configuration = configurations?.find(
		(configuration: any) =>
			configuration.id &&
			configuration.id !== -1 &&
			!!configuration?.filters?.printerMaterialSubCategory
	)

	if (configuration) {
		return {
			isAmOriginalMaterial: true,
			subCategoryData: {
				subCategory: configuration?.filters?.printerMaterialSubCategory[0],
				printerMaterialID: project?.filters?.printerMaterialID || null
			}
		}
	}
	return {
		isAmOriginalMaterial: false,
		subCategoryData: null
	}
}

// need to find the position to show at start/middle/end of window
// of dropdown to avoid overflow hidden with scrollable header
export const getDropdownPosition = (
	object: HTMLElement | null,
	container: HTMLElement | null
) => {
	const defaultLeftPosition = 0
	const buttonWidthHeight = 40
	const dropdownWidth = 320
	const defaultContainerWidth = 1200

	// find container width and button left position
	const objectContainer = container?.clientWidth || defaultContainerWidth
	let buttonLeftPosition = (object && object.offsetLeft) || defaultLeftPosition

	// check if there is enough space to show
	// if not enough then subtract dropdown from container
	const containerLessWidth =
		dropdownWidth > objectContainer - buttonLeftPosition
	if (containerLessWidth) {
		buttonLeftPosition = objectContainer - dropdownWidth
	}

	return {
		left: buttonLeftPosition,
		top: buttonWidthHeight
	}
}

const makeBestDots = (
	bestSolutions: any,
	xName: string = solutionMapConstant.score,
	yName = solutionMapConstant.cost
) => {
	return bestSolutions.map((solution: any) => {
		return [solution[xName], Math.round(solution[yName])]
	})
}
const makeExponential = (
	alternativeSolutions: any,
	xName: string = solutionMapConstant.score,
	yName = solutionMapConstant.cost,
	xMax: number,
	xMin: number
) => {
	if (xName !== solutionMapConstant.score || !alternativeSolutions.length)
		return [[]]

	let x: any = []
	let y: any = []
	orderBy(alternativeSolutions, [xName], ['asc']).forEach((solution: any) => {
		x.push(solution[xName])
		y.push(solution[yName])
	})

	// make log from Y
	let Y = y.map((y: any) => Math.log(y))

	// initial data - array of xy from alternativeSolutions
	let data = x.map((x: any, i: any) => [x, y[i]])

	// take the length of data
	let count: any = data.length

	//
	let xY = x.map((x: any, i: any) => x * Y[i])

	// we need calculate the "straight" type using the method of squares
	let sumX = x.reduce((acc: any, x: any) => acc + x)
	let sumY = Y.reduce((acc: any, y: any) => acc + y, 0)
	let sumXY = xY.reduce((acc: any, xy: any) => acc + xy, 0)
	let sumX2 = x.reduce((acc: any, x: any) => acc + x * x, 0)
	let A0 = (sumY * sumX2 - sumX * sumXY) / (count * sumX2 - sumX * sumX)
	let A1 = (count * sumXY - sumY * sumX) / (count * sumX2 - sumX * sumX)

	// exponentiate of Y, x should be the same
	let a = Math.exp(A0)
	let b = A1

	//points for exp curve
	let approxData = []
	for (let i = 0; i < xMax; i += (xMax - xMin) / 50) {
		//take a logarithm y = a * e^(b*x)
		const lnY = a * Math.exp(b * i)
		// create a dot
		approxData.push([i, lnY])
	}

	return approxData
}

const makeTooltip = (
	solution: any,
	printingTechnologies: any,
	xAxisName: string,
	roles: Array<Number>,
	unitSystem: UnitSystem
) => {
	const allowProperties =
		roles.includes(UserRole.QA) ||
		roles.includes(UserRole.SUPER_ADMIN) ||
		roles.includes(UserRole.ADMIN)
	const technologyName = printingTechnologies?.find(
		(tech: any) =>
			tech?.name === solution.printer?.printerTechnologyName ||
			solution.printer?.technology?.name
	)
	const xAxisDropDownValues = getXAxisDropDownValues(unitSystem)
	const selectedData: any = xAxisDropDownValues.find(
		(elem: any) => elem.value === xAxisName
	)
	const solutionPointInfo =
		solution[xAxisName] > 10
			? Math.round(solution[xAxisName])
			: solution[xAxisName]
	return `<div class="solution-info" data-qa="data-qa-solution-${
		solution.position
	}-in-tooltip">
    <div><b>${getString('SOLUTION')}:</b> ${solution.position}</div>
    <div><b>${getString('SOLUTION_NAME_LABEL')}:</b> ${
		solution.printerName
	}</div>
    <div><b>${getString('PROFILE_FORM_COMPANY_LABEL')}:</b> ${
		solution.printer?.company
	}</div>
    <div><b>${getString('MATERIAL_COST_TECHNOLOGY')}:</b> 
			${
				technologyName?.userReadableName ||
				solution.printer?.technologyName ||
				solution.printer?.technology?.name ||
				'-'
			}
			</div>
    <div><b>${getString('DESCRIPTION')}:</b> ${
		solution.printer?.description
	}</div>
    ${
			allowProperties
				? `<div><b>${selectedData.name}:</b> 
				${solutionPointInfo} ${selectedData.units}</div>`
				: ''
		}
  </div>`
}

const calculatePercentage = (value: number) => {
	return (value / 100) * 10
}

export const calculateChartData = (
	alternativeSolutions: any,
	xName: any = solutionMapConstant.score,
	yName: any = solutionMapConstant.cost
) => {
	const filtered = filter(alternativeSolutions, (solution: any) => {
		let idx = !!solution.isPartOfGraphExponentialLine
		return (solution[xName] === 0 || !!solution[xName]) && !idx
	})

	const filteredChoices = alternativeSolutions.filter(
		(solution: any) => solution.isPartOfGraphExponentialLine
	)

	const countOfSteps = 10
	let xAxis: any = []
	let yAxis: any = []

	filtered.forEach((solution: any) => {
		xAxis.push(solution[xName])
		yAxis.push(solution[yName])
	})
	filteredChoices.forEach((solution: any) => {
		xAxis.push(solution[xName])
		yAxis.push(solution[yName])
	})

	const upperXValue = max(xAxis) || xAxis[xAxis.length - 1]
	const upperYValue = max(yAxis) || yAxis[yAxis.length - 1]
	const lowerYValue = min(yAxis) || yAxis[0]
	const lowerXValue = min(xAxis) || xAxis[0]

	const minX =
		lowerXValue - calculatePercentage(lowerXValue) > 0
			? lowerXValue - calculatePercentage(lowerXValue)
			: 0
	const maxX = upperXValue + calculatePercentage(upperXValue)
	const XDifference = (maxX - minX) / countOfSteps / 4
	const minY =
		lowerYValue - calculatePercentage(lowerYValue) > 0
			? lowerYValue - calculatePercentage(lowerYValue)
			: 0
	const maxY = upperYValue + calculatePercentage(upperYValue)
	const YDifference = (maxY - minY) / countOfSteps / 6

	return {
		filtered,
		filteredChoices,
		countOfSteps,
		upperXValue,
		upperYValue,
		minX,
		maxX,
		XDifference,
		YDifference
	}
}

export const generateChartData = (
	alternativeSolutions: any = [],
	xName: any = solutionMapConstant.score,
	yName: any = solutionMapConstant.cost,
	updateHoverElement: Function,
	printingTechnologies: any,
	showActualScore: boolean,
	configurationMaterial: Material,
	hoverElement: any,
	roles: Array<Number>,
	unitSystem: UnitSystem
) => {
	const componentChartData: any = cloneDeep(chartGraphDataOptions)
	const selectedData = configurationMaterial
		? getMaterialProperties(xName, configurationMaterial)
		: 0

	const {
		filtered,
		filteredChoices,
		countOfSteps,
		upperXValue,
		upperYValue,
		minX,
		maxX,
		XDifference,
		YDifference
	} = calculateChartData(alternativeSolutions, xName, yName)

	componentChartData.states = {
		hover: {
			filter: {
				type: 'darken',
				value: 0.1
			}
		}
	}
	componentChartData.chart.events = {
		mouseLeave: function () {
			updateHoverElement(null)
		},
		dataPointMouseEnter: function (event: any, chartContext: any, config: any) {
			if (hoverElement - 1 !== config.dataPointIndex)
				updateHoverElement(config.dataPointIndex)
			if (config.seriesIndex === seriesNames.alternativeSolutions) {
				const container: any = document.querySelector('#chartDataApex')
				let circle = container.querySelectorAll('circle')[config.dataPointIndex]
				circle.setAttribute('style', 'fill: black')
			}
		},
		dataPointMouseLeave: function (event: any, chartContext: any, config: any) {
			updateHoverElement(null)
			if (config.seriesIndex === seriesNames.alternativeSolutions) {
				const container: any = document.querySelector('#chartDataApex')
				let circle = container.querySelectorAll('circle')[config.dataPointIndex]
				circle.setAttribute('style', 'fill: #008ffb')
			}
		}
	}

	if (xName === solutionMapConstant.score) {
		componentChartData.fill = {
			opacity: 1,
			type: ['solid', 'solid', 'solid', 'gradient'],
			gradient: {
				type: 'vertical',
				shadeIntensity: 0,
				opacityFrom: 0,
				opacityTo: 1,
				stops: [0, 50, 90]
			}
		}
	}
	if (selectedData > 0) {
		const thirdOfTheLength = maxX / 3
		const textAnchor =
			selectedData >= thirdOfTheLength * 2
				? 'end'
				: selectedData >= thirdOfTheLength
				? 'middle'
				: 'start'

		componentChartData.annotations = {
			position: 'front',
			xaxis: [
				{
					x: selectedData,
					strokeDashArray: 3,
					borderColor: theme.colors.benefitsBackground,
					label: {
						borderColor: 'transparent',
						textAnchor,
						offsetY: 0,
						offsetX: 0,
						orientation: 'horizontal',
						style: {
							color: theme.colors.benefitsBackground,
							background: 'transparent',
							cssClass: 'apexcharts-xaxis-annotation-label'
						},
						text: `${getString('MATERIAL_COMPARISON_ORIGINAL_COLUMN_NAME')}: ${
							configurationMaterial.name
						}`
					}
				}
			]
		}
	} else {
		componentChartData.annotations = {
			position: 'front',
			xaxis: []
		}
	}
	componentChartData.stroke = {
		show: true,
		curve: 'straight',
		lineCap: 'round',
		colors: undefined,
		width: 2,
		dashArray: 0
	}
	componentChartData.chart.animations = {
		enabled: false,
		easing: 'linear',
		speed: 100,
		animateGradually: {
			enabled: true,
			delay: 500
		},
		dynamicAnimation: {
			enabled: true,
			speed: 500
		}
	}

	componentChartData.xaxis = {
		type: 'numeric',
		min: 0,
		max:
			xName === solutionMapConstant.score
				? solutionMapScoreConstants.high
				: maxX,
		tickPlacement: undefined,
		decimalsInFloat: 3,
		tickAmount: countOfSteps,
		strokeDashArray: 0,
		borderColor: '#DEDEDE',
		labels: {
			formatter: function (val: any) {
				if (xName === solutionMapConstant.score && !showActualScore) return ``
				if (val === 0) return val

				if (val > 0 && val < 10 && upperXValue < 10) {
					return parseFloat(val).toFixed(2)
				}

				if (val < 0) {
					return ''
				}

				return parseFloat(val).toFixed(0)
			}
		},
		axisBorder: {
			show: true,
			color: '#848484',
			height: 1,
			width: '100%',
			offsetX: 0,
			offsetY: 0
		}
	}
	componentChartData.yaxis = {
		min: 0,
		max: upperYValue + calculatePercentage(upperYValue),
		tickAmount: countOfSteps,
		labels: {
			formatter: function (val: any) {
				return `$${parseFloat(val).toFixed(0)}`
			}
		},
		axisBorder: {
			show: true,
			color: '#848484',
			height: '100%',
			width: 1,
			offsetX: -1,
			offsetY: 0
		}
	}
	componentChartData.grid = {
		strokeDashArray: 0,
		borderColor: '#DEDEDE',
		row: {
			colors: ['transparent', 'transparent'],
			opacity: 0.5
		},
		column: {
			colors: ['transparent', 'transparent']
		},
		xaxis: {
			lines: {
				show: true
			}
		},
		yaxis: {
			lines: {
				show: true
			}
		}
	}

	const series = [
		{
			name: 'exponential',
			type: 'line',
			data: makeExponential(filteredChoices, xName, yName, maxX, minX)
		},
		{
			name: 'dots',
			type: 'scatter',
			data: makeBestDots(alternativeSolutions, xName)
		},
		{
			name: 'bestChoices',
			type: 'scatter',
			data: makeBestDots(filteredChoices, xName)
		}
		// {
		// 	name: 'highlightArea',
		// 	type: xName === solutionMapConstant.score ? 'area' : 'none',
		// 	//TODO: to change the area you need to find the maxDot for the last
		// 	// element in the array sorted by score
		// 	data: [
		// 		[0, maxY],
		// 		[maxX, 100],
		// 		[maxX, maxY],
		// 		[0, maxY]
		// 	]
		// }
	]

	componentChartData.markers = {
		strokeWidth: 1,
		fillOpacity: 1,
		strokeOpacity: 1,
		size: series.map((el, id) => {
			return id === seriesNames.exponentialLine ||
				id === seriesNames.highlightedArea
				? 0
				: 6
		}),
		hover: {
			size: 6
		},
		discrete: [
			{
				seriesIndex: seriesNames.exponentialLine,
				strokeColor: '#fff'
			},
			{
				seriesIndex: seriesNames.alternativeSolutions,
				strokeColor: '#fff'
			},
			{
				seriesIndex: seriesNames.bestChoices,
				dataPointIndex: 0,
				strokeColor: '#000',
				fillColor: exponentialLineColor,
				size: 6
			}
		]
	}

	componentChartData.colors = series.map((el, id) => {
		switch (id) {
			case seriesNames.exponentialLine:
				return exponentialLineColor
			case seriesNames.bestChoices:
				return exponentialLineColor
			case seriesNames.highlightedArea:
				return fillAreaColor
			default:
				return dotsColor
		}
	})

	componentChartData.legend = {
		show: false
	}

	componentChartData.tooltip = {
		shared: false,
		intersect: true,
		custom: function ({ seriesIndex, dataPointIndex, ...props }: any) {
			if (seriesIndex === 0) return ``
			let hoverData: any = {}
			if (seriesIndex === 1) {
				//need to get id solution without best choices
				const index = dataPointIndex - filteredChoices.length
				hoverData = filtered[index]
			} else {
				hoverData = filteredChoices[dataPointIndex]
			}

			const selectedY: any = hoverData[yName]
			const selectedX: any = hoverData[xName]

			let result = alternativeSolutions?.filter((solution: any) => {
				const isEqualData =
					solution[yName] === selectedY && solution[xName] === selectedX
				const closeDataByY = Math.abs(solution[yName] - selectedY) < YDifference
				const closeDataByX = Math.abs(solution[xName] - selectedX) < XDifference
				return (
					isEqualData ||
					(closeDataByY && solution[xName] === selectedX) ||
					(closeDataByX && solution[yName] === selectedY)
				)
			})

			return `
		    <div class="apexchart-custom-tooltip" data-qa="data-qa-graph-tooltip">
		     ${map(result, (solution: any) =>
						makeTooltip(
							solution,
							printingTechnologies,
							xName,
							roles,
							unitSystem
						)
					).join('<br/>')}
		    </div>
		    `
		}
	}

	return {
		componentChartData,
		series
	}
}

export const setNewTooltip = (
	solution: any,
	show: boolean,
	printingTechnologies: any,
	selectedXAxisName: string,
	roles: Array<Number>,
	unitSystem: UnitSystem
) => {
	const firstElem = 0
	const tooltipHeight = 58
	const tooltipWidth = 220

	if (!solution[selectedXAxisName]) return

	let position: any = {}
	const tooltip: any = document
		.getElementById('chartID')
		?.getElementsByClassName('apexcharts-tooltip-1')

	const container: any = document.querySelector('#chartDataApex')
	const elemRect = container.getBoundingClientRect()
	let circles = container.querySelectorAll('circle') || []

	// colored the markers on the graph
	for (const circle of circles) {
		const rel = +circle.getAttribute('rel') + 1
		const index = +circle.getAttribute('index')

		if (
			index === seriesNames.alternativeSolutions ||
			index === seriesNames.bestChoices
		) {
			if (
				rel === solution.position &&
				(index === seriesNames.alternativeSolutions ||
					index === seriesNames.bestChoices) &&
				show
			) {
				const targetRect = circle.getBoundingClientRect()
				position = {
					top: targetRect.top - elemRect.top,
					left: targetRect.left - elemRect.left
				}
				circle.setAttribute('style', 'fill: black')
			} else {
				circle.setAttribute(
					'style',
					`fill: ${
						index === seriesNames.bestChoices ? exponentialLineColor : dotsColor
					}`
				)
			}
		}
	}

	// apply the tooltip to the selected mark
	if (show) {
		tooltip[
			firstElem
		].innerHTML = `<div class="apexchart-custom-tooltip">${makeTooltip(
			solution,
			printingTechnologies,
			selectedXAxisName,
			roles,
			unitSystem
		)}</div>`

		const tooltipProps = tooltip[firstElem].getBoundingClientRect()
		const calculateLeft = position.left - tooltipWidth
		const calculateTop = position.top - tooltipProps.height - tooltipHeight

		const leftPosition = calculateLeft < 20 ? tooltipWidth - 90 : calculateLeft
		const topPosition = calculateTop < 20 ? 0 : calculateTop

		tooltip[firstElem].style.top = `${topPosition}px`
		tooltip[firstElem].style.left = `${leftPosition}px`
	} else {
		tooltip[firstElem].style.top = 0
		tooltip[firstElem].style.left = 0
		tooltip[firstElem].innerHTML = ''
	}
}

export const getPrinterTechnologies = (
	filters: Array<Record<string, any>> | Record<string, any>
) => {
	if (isArray(filters)) {
		return (
			filters
				?.map(filter => {
					if (filter.name === PRINTER_TECHNOLOGY) {
						return !!filter.value
							? isArray(filter.value)
								? filter.value
								: filter.value.split(',')
							: null
					}
				})
				?.filter(val => !!val)[0] || null
		)
	}
	return (
		Object.keys(filters)
			?.map(key => {
				if (key === PRINTER_TECHNOLOGY) {
					return filters[key]
				}
			})
			?.filter(val => !!val)[0] || null
	)
}

export const getScoreText = (score: number, showActualScore: boolean) => {
	if (showActualScore) return score
	switch (true) {
		case score <= solutionMapScoreConstants.low:
			return getString('LOW')
		case score <= solutionMapScoreConstants.medium:
			return getString('MEDIUM')
		case score <= solutionMapScoreConstants.high:
			return getString('GOOD')
	}
}
