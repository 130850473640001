import { ItemList } from 'Scenes/Components/ItemsList'
import {
	customizeGeometryAnalysisStrings,
	GeometryAnalysisPartEnum
} from 'Scenes/Home/Customize/CustomizePartFiltering/PartFilteringService'
import {
	KEY_2d,
	KEY_3d,
	METADATA
} from 'Scenes/Home/NewUploadProject/constants'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

export const disabledFilesTypeFeatures = [
	{
		key: KEY_2d,
		feature: FeatureComponentId.UPLOAD_2D_PROJECT
	},
	{ key: METADATA, feature: FeatureComponentId.META_DATA_PROJECT }
]

const MAX_ITEM_COUNT = 3
const START_ITEM_COUNT = 0

export const makeFilterItemsList = (
	filterItemsList: ItemList[],
	geometryAnalysis: Record<GeometryAnalysisPartEnum, boolean>
) => {
	const geometryAnalysisList: ItemList[] = Object.keys(geometryAnalysis)
		.filter(geometryName => {
			const excludePartSubstrings =
				geometryName === GeometryAnalysisPartEnum.excludePartSubstrings
			const geometryAnalysisOn =
				geometryAnalysis[geometryName as GeometryAnalysisPartEnum]

			return (
				(excludePartSubstrings && geometryAnalysisOn) ||
				(!excludePartSubstrings && !geometryAnalysisOn)
			)
		})
		.map(key => ({
			allowRemove: true,
			geometryAnalysis: true,
			key: key,
			value: getString(
				customizeGeometryAnalysisStrings[key as GeometryAnalysisPartEnum]
			)
		}))

	const filterItemsListArr = [...filterItemsList, ...geometryAnalysisList]
	let slicedFilterItemsList = filterItemsListArr

	if (filterItemsListArr?.length > MAX_ITEM_COUNT) {
		slicedFilterItemsList = filterItemsListArr.slice(
			START_ITEM_COUNT,
			MAX_ITEM_COUNT
		)
		const restFilters = filterItemsListArr.slice(MAX_ITEM_COUNT)

		slicedFilterItemsList.push({
			allowRemove: false,
			key: '',
			value: `+${filterItemsListArr?.length - MAX_ITEM_COUNT}`,
			rest: restFilters
		})
	}

	return slicedFilterItemsList
}

export const getUploadExplanation = (key: string | null) => {
	let explanationText = ''
	let explanationSubText = ''

	switch (key) {
		case KEY_2d:
			explanationText = getString('CHOOSE_MATERIAL_ON_UPLOAD_EXPLINATION')
			break
		case KEY_3d:
			explanationText = getString(
				'CHOOSE_MATERIAL_ON_UPLOAD_EXPLANATION_ASSEMBLY'
			)
			break
		case METADATA:
			explanationText = getString('CHOOSE_MATERIAL_METADATA_EXPLANATION')
			explanationSubText = getString('CHOOSE_MATERIAL_METADATA_EXPLANATION_SUB')
			break
	}

	return { explanationText, explanationSubText }
}
