import { FC, memo } from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'

import { isEmpty } from 'lodash'

import {
	ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL,
	ADMIN_PROJECTS_CREATED_AT,
	ADMIN_PROJECTS_DEFAULT_MATERIAL,
	ADMIN_PROJECTS_EMAIL,
	ADMIN_PROJECTS_EXTERNAL_ASSEMBLY_FILE_ID,
	ADMIN_PROJECTS_ID,
	ADMIN_PROJECTS_MATERIAL_OR_BOM,
	ADMIN_PROJECTS_NAME,
	ADMIN_PROJECTS_PARTS_IN_PROJECT,
	ADMIN_PROJECTS_PUBLISHED_AT,
	ADMIN_PROJECTS_STATUS,
	AM_TYPE_IMPROVEMENTS,
	AM_VALUE_TEXT,
	ASSEMBLY,
	BOM,
	MANUFACTURING_METHOD_TEXT,
	MULTI_MATERIAL,
	OWNER_ID,
	OWNER_NAME,
	PART_STATUSES_TEXTS,
	PRODUCT_LIFE_CYCLE,
	PROGRESS,
	PROJECT_INFORMATION,
	PROJECT_UNIT_TYPE,
	STANDARD_COST,
	STATUS_BREAKDOWN,
	STATUS_INFORMATION,
	UPLOAD_INDIVIDUAL_PARTS_SELECTION,
	UPLOAD_PROJECT_APPLICATION,
	UPLOAD_PROJECT_SCENARIO_METHODS_HEADER,
	UPLOAD_PROJECT_YEARLY_PROD,
	UPLOADING_MESSAGE,
	USER_INFORMATION
} from '../../../../../Services/Strings'
import Flexbox from 'Scenes/Components/FlexBox'
import { manufacturingMethodTypes } from 'Services/Constants'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { Part, PartStatus } from 'Services/models/IPart'

import './AdminProjectInfo.scss'

const generateHttpLineProperty = (title: string, data: number | string) => {
	return (
		<p>
			<b>{title}:</b> {data}
		</p>
	)
}

const generateNumOfPartsByStatus = (
	statusName: string,
	sumOfStatus: number
) => {
	if (sumOfStatus) {
		return (
			<p>
				{statusName}: {sumOfStatus}
			</p>
		)
	}
	return <></>
}
const AdminProjectInfo: FC<InjectedFormProps<any> & any> = ({ ...props }) => {
	const project = props.initialValues
	const allProjectParts = props.allProjectParts
	const progress = UPLOADING_MESSAGE.format(
		project?.numberOfPartsAnalyzed,
		project?.totalParts
	)

	const statusPartsCounts = allProjectParts.reduce(
		(acc: Record<PartStatus, number>, part: Part) => {
			acc[part.status] = (acc[part.status] || 0) + 1
			return acc
		},
		{}
	)

	const completeParts =
		(Number(statusPartsCounts[PartStatus.published]) || 0) +
		(Number(statusPartsCounts[PartStatus.complete]) || 0)
	const failedParts = statusPartsCounts[PartStatus.failed]
	const awaitingCombinedHeatmapParts =
		statusPartsCounts[PartStatus.awaitingCombinedHeatmap]
	const awaitingFileParts = statusPartsCounts[PartStatus.awaitingFile]
	const toBeSentForAnalysisParts =
		statusPartsCounts[PartStatus.toBeSentForAnalysis]
	const awaitingAnalysisParts = statusPartsCounts[PartStatus.awaitingAnalysis]
	const awaitingForBOMParts = statusPartsCounts[PartStatus.awaitingForBOM]
	const toBeSentForWallThicknessParts =
		statusPartsCounts[PartStatus.toBeSentForWallThickness]
	const awaitingWallThicknessParts =
		statusPartsCounts[PartStatus.awaitingWallThickness]
	const awaitingTrayOrientationParts =
		statusPartsCounts[PartStatus.awaitingTrayOrientation]
	const rejectedParts = statusPartsCounts[PartStatus.rejected]
	const missingInformationParts =
		statusPartsCounts[PartStatus.missingInformation]

	const empty = '-'
	const amProjectValue = project?.amValue && JSON.parse(project?.amValue)
	const defaultAmType = AM_TYPE_IMPROVEMENTS

	const productLifeCycle =
		UPLOAD_PROJECT_SCENARIO_METHODS_HEADER[project?.productLifeCycle] || empty

	const projectManufactureMethod: ImanufacturingTypes =
		project?.manufactureMethod
	const manufactureMethod =
		manufacturingMethodTypes[projectManufactureMethod] || empty
	const amValue = isEmpty(amProjectValue)
		? empty
		: amProjectValue.map((el: any) => defaultAmType[el]).join(', ')

	const materialOrBom =
		project?.defaultMaterialId && !project?.bomFileURL
			? project?.defaultMaterial?.name || project?.defaultMaterial
			: project?.bomFileURL
			? BOM
			: MULTI_MATERIAL

	const assembly = project?.isAssembly
		? ASSEMBLY
		: UPLOAD_INDIVIDUAL_PARTS_SELECTION

	return (
		<Flexbox className="admin-project-form" justifyContent="space-around">
			<Flexbox className="admin-project-form" flexDirection="column">
				<h3>{PROJECT_INFORMATION}</h3>
				{generateHttpLineProperty(ADMIN_PROJECTS_NAME, project?.name)}
				{generateHttpLineProperty(ADMIN_PROJECTS_ID, project?.id)}
				{generateHttpLineProperty(
					ADMIN_PROJECTS_EXTERNAL_ASSEMBLY_FILE_ID,
					project?.externalAssemblyFileId
				)}
				{generateHttpLineProperty(
					ADMIN_PROJECTS_MATERIAL_OR_BOM,
					materialOrBom
				)}

				<p>
					<b>{ADMIN_PROJECTS_PARTS_IN_PROJECT}:</b> {project?.partCount}.{' '}
					<b>{ADMIN_PROJECTS_DEFAULT_MATERIAL}:</b>{' '}
					{project?.defaultMaterial?.name || project?.defaultMaterial}
				</p>
				{generateHttpLineProperty(ADMIN_PROJECTS_STATUS, project?.status)}
				{generateHttpLineProperty(
					ADMIN_PROJECTS_CREATED_AT,
					project?.createdAt
				)}
				{generateHttpLineProperty(
					ADMIN_PROJECTS_PUBLISHED_AT,
					project?.publishedAt
				)}
			</Flexbox>

			<Flexbox className="admin-project-form" flexDirection="column">
				<h3>
					<br />
				</h3>
				{generateHttpLineProperty(
					UPLOAD_PROJECT_YEARLY_PROD,
					project?.quantity
				)}
				{generateHttpLineProperty(
					UPLOAD_PROJECT_APPLICATION,
					project?.application
				)}
				{generateHttpLineProperty(
					ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL,
					assembly
				)}
				{generateHttpLineProperty(PROJECT_UNIT_TYPE, project?.unitType)}
				{generateHttpLineProperty(MANUFACTURING_METHOD_TEXT, manufactureMethod)}
				{generateHttpLineProperty(AM_VALUE_TEXT, amValue)}
				{generateHttpLineProperty(PRODUCT_LIFE_CYCLE, productLifeCycle)}
				{generateHttpLineProperty(
					STANDARD_COST,
					project?.projectStandardCost || empty
				)}
			</Flexbox>
			<Flexbox className="admin-project-form" flexDirection="column">
				<h3>{USER_INFORMATION}</h3>
				{generateHttpLineProperty(OWNER_NAME, project?.owner?.name)}
				{generateHttpLineProperty(OWNER_ID, project?.owner?.id)}
				{generateHttpLineProperty(ADMIN_PROJECTS_EMAIL, project?.owner?.email)}
			</Flexbox>
			<Flexbox className="admin-project-form" flexDirection="column">
				<h3>{STATUS_INFORMATION}</h3>
				{generateHttpLineProperty(PROGRESS, progress)}
				<p>
					<b>{STATUS_BREAKDOWN}</b>
				</p>
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.complete,
					completeParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingCombinedHeatmap,
					awaitingCombinedHeatmapParts
				)}
				{generateNumOfPartsByStatus(PART_STATUSES_TEXTS.failed, failedParts)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingFile,
					awaitingFileParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.toBeSentForAnalysis,
					toBeSentForAnalysisParts
				)}

				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingAnalysis,
					awaitingAnalysisParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingForBOMParts,
					awaitingForBOMParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.toBeSentForWallThickness,
					toBeSentForWallThicknessParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingWallThickness,
					awaitingWallThicknessParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.awaitingTrayOrientation,
					awaitingTrayOrientationParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.rejected,
					rejectedParts
				)}
				{generateNumOfPartsByStatus(
					PART_STATUSES_TEXTS.missingInformation,
					missingInformationParts
				)}
			</Flexbox>
		</Flexbox>
	)
}

export default memo(
	reduxForm<any, any>({
		form: 'adminProject'
	})(AdminProjectInfo)
)
