import React, { FC, useCallback, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Divider } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Filter from '@material-ui/icons/FilterList'
import cx from 'classnames'

import { changeFilterKey, filterParts } from './FilterPartsAction'
import { benefitsFiltersValues, filters } from './filterPartsEnum'
import {
	filterAll,
	filterPartProjectType,
	filterPartStatus,
	filterProductionMethod,
	filterProperty2D,
	filterProperty3D
} from './FilterPartsService'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Project } from 'Services/models/IProject'
import { PARTS_FILTER } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './FilterParts.scss'

const DividerTSX: any = Divider
const ButtonTSX: any = Button
const CheckBoxTSX: any = Checkbox
const ClickAwayListenerTSX: any = ClickAwayListener
const FilterIconTSX: any = Filter

interface FiltersForPartsProps {
	is2dProject: boolean
	allPartsCount: number
	standalonePartsCount?: number
	searchPhrase: string
	selectedFilterValues: string[]
	assemblyProjects?: Project[]
	page: number
	limit: number
	refetchParts: boolean
}

interface FilteredCheckboxProps {
	value: string
	idx: number
	title: string
	handleChange: Function
	selectedFilterValues: any
}

export const FilteredCheckbox: FC<FilteredCheckboxProps> = ({
	value,
	title,
	idx,
	handleChange,
	selectedFilterValues
}) => {
	return (
		<div
			title={title}
			className="filters-for-part__option"
			key={value + idx}
			onClick={() => handleChange(value)}
		>
			<CheckBoxTSX
				color="primary"
				checked={selectedFilterValues?.indexOf(value) > -1}
			/>
			<div
				className={cx(
					'filters-for-part__option-title',
					benefitsFiltersValues[value as any] || ''
				)}
			>
				{title}
			</div>
		</div>
	)
}

const FiltersForParts: FC<FiltersForPartsProps> = ({
	is2dProject,
	allPartsCount,
	standalonePartsCount = 0,
	searchPhrase,
	selectedFilterValues,
	assemblyProjects,
	page,
	limit,
	refetchParts
}) => {
	const [open, setOpen] = React.useState(false)
	const dispatch = useDispatch()
	const user = useSelector((state: RootStateOrAny) => state.user)
	const { projectId } = useParams() as { projectId: string }

	const prevSearchPhrase = usePrevious(searchPhrase)

	useEffect(() => {
		let delayTime = 0
		if (prevSearchPhrase !== searchPhrase) {
			delayTime = 500
		}
		const delayDebounceFn = setTimeout(() => {
			dispatch(
				filterParts(
					projectId,
					searchPhrase,
					selectedFilterValues,
					!!assemblyProjects,
					page,
					limit,
					false,
					user
				)
			)
		}, delayTime)

		return () => clearTimeout(delayDebounceFn)
	}, [
		dispatch,
		searchPhrase,
		allPartsCount,
		selectedFilterValues,
		projectId,
		page,
		limit
	])

	useEffect(() => {
		if (refetchParts) {
			dispatch(
				filterParts(
					projectId,
					searchPhrase,
					selectedFilterValues,
					!!assemblyProjects,
					page,
					limit,
					false,
					user
				)
			)
		}
	}, [refetchParts])

	let renderFilters = is2dProject ? filterProperty2D : filterProperty3D

	if (!Feature.isFeatureOn(FeatureComponentId.BUY_TO_FLY)) {
		renderFilters = renderFilters.filter(
			filter => filter !== filters.BUY_TO_FLY
		)
	}
	const isOptimizationAnalysisOn = Feature.isFeatureOn(
		FeatureComponentId.OPTIMIZATION_ANALYSIS
	)
	const handleChange = (value: string) =>
		dispatch(changeFilterKey(value, !!assemblyProjects))

	const toggleFilterMenu = useCallback((open: boolean) => {
		setOpen(open)
	}, [])

	return (
		<div className="filters-for-part">
			<ClickAwayListenerTSX onClickAway={() => toggleFilterMenu(false)}>
				<div
					className="filters-for-part__menu-box"
					data-qa="data-qa-filter-btn"
				>
					<ButtonTSX
						className="border-button"
						onClick={() => toggleFilterMenu(!open)}
					>
						<FilterIconTSX />
						{PARTS_FILTER}
					</ButtonTSX>
					<div
						className="filters-for-part__menu"
						style={{ display: open ? '' : 'none' }}
					>
						<div className="filters-for-part__menu-body">
							<div data-qa="parts-filters-property-section">
								<h4>{getString('PROPERTY')}</h4>
								<div>
									{renderFilters.map((value: string, idx: number) => (
										<FilteredCheckbox
											key={value + idx}
											value={value}
											title={value}
											idx={idx}
											handleChange={handleChange}
											selectedFilterValues={selectedFilterValues}
										/>
									))}
								</div>
							</div>
							<div data-qa="parts-filters-status-section">
								<h4>{getString('ADMIN_PARTS_STATUS')}</h4>
								<div>
									{filterPartStatus.map((value: string, idx: number) => (
										<FilteredCheckbox
											key={value + idx}
											value={value}
											title={value}
											idx={idx}
											handleChange={handleChange}
											selectedFilterValues={selectedFilterValues}
										/>
									))}
								</div>
							</div>
							{assemblyProjects && (
								<div data-qa="parts-filters-parts-section">
									<h4>{getString('PARTS')}</h4>
									<div>
										{assemblyProjects?.map((value: Project, idx: number) => (
											<FilteredCheckbox
												key={value.name + idx}
												value={value.id}
												title={value.name}
												idx={idx}
												handleChange={handleChange}
												selectedFilterValues={selectedFilterValues}
											/>
										))}
										{standalonePartsCount ? (
											filterPartProjectType.map(
												(value: string, idx: number) => (
													<FilteredCheckbox
														key={value + idx}
														value={value}
														title={value}
														idx={idx}
														handleChange={handleChange}
														selectedFilterValues={selectedFilterValues}
													/>
												)
											)
										) : (
											<></>
										)}
									</div>
								</div>
							)}
							{isOptimizationAnalysisOn && (
								<div data-qa="parts-filters-status-section">
									<h4>{getString('PRODUCTION_METHOD')}</h4>
									<div>
										{filterProductionMethod.map((value: string, idx: number) => (
											<FilteredCheckbox
												key={value + idx}
												value={value}
												title={value}
												idx={idx}
												handleChange={handleChange}
												selectedFilterValues={selectedFilterValues}
											/>
										))}
									</div>
								</div>
							)}
						</div>
						<DividerTSX />
						<div
							className="filters-for-part__option clear-all"
							key={filterAll}
							onClick={() => handleChange(filterAll)}
						>
							<div>{getString('CLEAR_SELECTION')}</div>
						</div>
					</div>
				</div>
			</ClickAwayListenerTSX>
		</div>
	)
}

export default FiltersForParts
