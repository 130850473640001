import {
	SETUP_PROFILE_DETAILS,
	USER_PROFILE_ADDRESS_CHANGED,
	USER_PROFILE_ADDRESS_SELECTED,
	USER_PROFILE_COMPANY_CHANGED,
	USER_PROFILE_EMAIL_CHANGED,
	USER_PROFILE_LOADER,
	USER_PROFILE_NAME_CHANGED,
	USER_PROFILE_ORGANIZATION_CHANGED,
	USER_PROFILE_UPDATED
} from '../../../global actions/types'
import { ERROR } from '../../../Services/Strings'

const INITIAL_STATE = {
	userName: '',
	userEmail: '',
	userCompany: '',
	userOrganization: '',
	validUserName: '',
	validUserEmail: '',
	validUserCompany: '',
	validUserAddress: '',
	disableUploadButton: true,
	profileLoading: false
}

const disableUploadButton = state => {
	if (
		state.validUserName === ERROR ||
		state.validUserEmail === ERROR ||
		state.validUserCompany === ERROR ||
		state.validUserAddress === ERROR
	) {
		return true
	}
	return false
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SETUP_PROFILE_DETAILS: {
			const { userDetails, organizationDetails } = action.payload
			return {
				...state,
				userName: userDetails.name,
				userEmail: userDetails.email,
				userCompany: userDetails.company,
				userOrganization: organizationDetails.id
			}
		}
		case USER_PROFILE_NAME_CHANGED: {
			const { userName, validUserName } = action.payload
			const newState = { ...state, userName, validUserName }
			return {
				...newState,
				disableUploadButton: disableUploadButton(newState)
			}
		}
		case USER_PROFILE_EMAIL_CHANGED: {
			const { userEmail, validUserEmail } = action.payload
			const newState = { ...state, userEmail, validUserEmail }
			return {
				...newState,
				disableUploadButton: disableUploadButton(newState)
			}
		}
		case USER_PROFILE_COMPANY_CHANGED: {
			const { userCompany, validUserCompany } = action.payload
			const newState = { ...state, userCompany, validUserCompany }
			return {
				...newState,
				disableUploadButton: disableUploadButton(newState)
			}
		}
		case USER_PROFILE_ADDRESS_CHANGED: {
			const validUserAddress = action.payload
			const newState = { ...state, validUserAddress }
			return {
				...newState,
				disableUploadButton: disableUploadButton(newState)
			}
		}
		case USER_PROFILE_ORGANIZATION_CHANGED: {
			const { userOrganization } = action.payload
			const newState = { ...state, userOrganization }
			return { ...newState, disableUploadButton: disableUploadButton(newState) }
		}

		case USER_PROFILE_LOADER: {
			return {
				...state,
				profileLoading: action.payload
			}
		}
		case USER_PROFILE_UPDATED: {
			const { name, email, company } = action.payload
			return {
				...state,
				userName: name,
				userEmail: email,
				userCompany: company,
				disableUploadButton: true,
				validUserName: '',
				validUserEmail: '',
				validUserCompany: '',
				validUserAddress: ''
			}
		}
		default:
			return state
	}
}
