import React, { FC } from 'react'

import Tab from '@material-ui/core/Tab'

import { styles } from '../../../ProjectAnalysis/ProjectMultipleParts/style'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Loader from 'Scenes/Loader/Loader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as Close } from 'assets/img/svg/icons/circle_close.svg'

const CloseButton: any = Close

function defaultProps(index: any, selected: any) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
		selected: selected
	}
}

interface ConfigurationHeaderProps {
	label: string
	index: number
	handleChange: Function
	selected: boolean
	setShowRemoveAlert: any
	showRemove: boolean
	setRemoveConfiguration: any
	id: number
	qaDataElementName?: string
	isLeading: boolean
	disabled: boolean
	isWeightReductionConfiguration?: boolean
}

const ConfigurationHeader: FC<ConfigurationHeaderProps> = ({
	label,
	index,
	handleChange,
	selected,
	setShowRemoveAlert,
	showRemove,
	setRemoveConfiguration,
	id,
	qaDataElementName,
	isLeading,
	isWeightReductionConfiguration,
	disabled
}) => {
	const showLeadingConfiguration = Feature.isFeatureOn(
		FeatureComponentId.SHOW_LEADING_CONFIGURATION
	)
	return (
		<Tab
			disabled={disabled}
			data-qa={`data-qa-configuration-${qaDataElementName}`}
			classes={{
				wrapper: 'tab-wrapper',
				selected: 'tab-selected'
			}}
			label={
				<div className="tab-label">
					{showLeadingConfiguration && isLeading ? (
						<DetailsPopup
							isHover={true}
							data={getString('LEADING_CONFIGURATION_HINT')}
							popperDirection="bottom-start"
							popperClassName="info-box-wrapper"
							popperContactClassName="info-box-data"
							inPortal
						>
							<IconFactory iconName="starBorder" className="leading-icon" />
						</DetailsPopup>
					) : (
						<></>
					)}
					<span className="text">{label}</span>
				</div>
			}
			value={index}
			icon={
				disabled ? (
					<Loader load={true} size={30} message="" showFlex={false} />
				) : showRemove && !isWeightReductionConfiguration ? (
					<CloseButton
						onClick={(e: any) => {
							e.preventDefault()
							e.stopPropagation()
							setShowRemoveAlert(true)
							setRemoveConfiguration({ id, label })
						}}
					/>
				) : (
					<></>
				)
			}
			onChange={e => {
				handleChange(e, index)
			}}
			{...defaultProps(index, selected)}
		/>
	)
}

export default ConfigurationHeader
