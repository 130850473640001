import React, { FC } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'

type IProps = {
	state: IFileWithMaterialAndQuantity
	materialTypesList: string[]
	disabledMenuItem: string
	onMaterialTypeChange: (value: string) => void
}

const MaterialType: FC<IProps> = ({
	state,
	materialTypesList,
	disabledMenuItem,
	onMaterialTypeChange
}) => {
	return (
		<Select
			displayEmpty
			className={'file-row--select-field'}
			data-qa={'data-qa-comsTool-material-type'}
			value={state.materialType}
			renderValue={selected =>
				(selected as React.ReactNode) || disabledMenuItem
			}
			onChange={(event: any) => onMaterialTypeChange(event.target.value)}
			classes={{
				select: 'file-row--select'
			}}
			inputProps={{
				name: 'simpleSelect',
				id: 'simple-select'
			}}
			MenuProps={{
				PaperProps: {
					style: {
						transform: 'translate3d(0, 0, 0)'
					}
				}
			}}
		>
			<MenuItem disabled>{disabledMenuItem}</MenuItem>
			{materialTypesList.map((type: string) => {
				return (
					<MenuItem
						key={type}
						style={{ textTransform: 'capitalize' }}
						value={type}
					>
						{type}
					</MenuItem>
				)
			})}
		</Select>
	)
}

export default MaterialType
