import React, { FC } from 'react'

import cx from 'classnames'

import NumberField from 'Scenes/Components/NumberField'
import { ZERO_NUMBER_FIELD_VALUE } from 'Services/Constants'
import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'

type IProps = {
	state: IFileWithMaterialAndQuantity
	onStandardCostChange: (value: number) => void
}

const StandardCost: FC<IProps> = ({ state, onStandardCostChange }) => {
	return (
		<div
			className="file-row--standard-cost-input"
			data-qa={'data-qa-comsTool-standard-cost'}
		>
			<NumberField
				placeholder=""
				variant="outlined"
				value={state.standardCost as number | null}
				onChangeValue={(value: number) => onStandardCostChange(value)}
				inputClass={cx(
					'field-with-label outlined',
					'file-row--select-field',
					'file-row--number-input'
				)}
				minValue={ZERO_NUMBER_FIELD_VALUE}
				allowZero={false}
				changeOnScroll={false}
				isCommaDisabled={false}
				isDotDisabled={false}
				allowEmpty
				showArrows
			/>
			<span className="currency">{'$'}</span>
		</div>
	)
}

export default StandardCost
