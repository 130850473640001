import { defaultMetal } from '../../../../Services/Constants'
import { sortOriginalMaterials } from 'Services/Utils/sortingTools'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

export class MaterialSelectorService {
	materialsList: Array<{ name: string } & any>
	categoriesList: Array<string>
	materialTypesList: Array<string>
	chosenMaterial: any
	chosenCategory: any
	chosenType: any
	default: any
	defaultMaterialForTypeArr: Array<any> = [defaultMaterial, defaultMetal]
	constructor(
		private materialsData: Array<any>,
		type: string,
		category: string,
		initialMaterials: any,
		private materialCategories: any[]
	) {
		this.default = this.defaultMaterialForTypeArr[0]
		this.materialsData = materialsData
		this.chosenType = type || this.default.type
		this.chosenCategory = category?.toLowerCase() || this.default.category
		this.chosenMaterial = initialMaterials
		this.categoriesList = this.generateMaterialCategories(
			materialsData,
			this.chosenType
		)
		this.materialsList = this.filteredMaterialsByCategory(
			materialsData,
			this.chosenCategory
		)
		this.materialTypesList = this.generateMaterialTypes(materialsData)
	}

	getDataList() {
		return {
			materialTypesList: this.materialTypesList,
			categoriesList: this.categoriesList,
			materialsList: this.materialsList
		}
	}
	getDefault(fieldType: 'type' | 'category' | 'material', fieldValue: string) {
		let selectedTypeDefaultMaterial: any = this.defaultMaterialForTypeArr.find(
			material => material[fieldType] == fieldValue
		)
		const defaultMaterial = this.materialCategories.find(
			material =>
				material.defaultMaterialSelection.id === selectedTypeDefaultMaterial.id
		)?.defaultMaterialSelection

		return this.materialsData.find(material => {
			if (material.name.toLowerCase() === defaultMaterial.name.toLowerCase()) {
				return material
			}
		})
	}
	materialTypeChanged = (value: any) => {
		const defaultMaterial = this.getDefault('type', value)
		const newMaterialsCategoriesList = this.generateMaterialCategories(
			this.materialsData,
			value
		)

		this.categoriesList = newMaterialsCategoriesList
		const newMaterials = this.filteredMaterialsByCategory(
			this.materialsData,
			defaultMaterial?.category?.toLowerCase() || this.categoriesList[0]
		)
		this.materialsList = newMaterials
		this.chosenMaterial = defaultMaterial || newMaterials[0]
		this.chosenCategory = defaultMaterial?.category || newMaterials[0].category
		this.chosenType = defaultMaterial?.type || newMaterials[0].type

		return {
			chosenMaterialType: this.chosenType,
			chosenMaterialCategory: this.chosenCategory,
			chosenMaterial: this.chosenMaterial
		}
	}
	materialCategoryChanged = (value: any) => {
		const defaultMaterial = this.materialCategories.find(
			material =>
				material.defaultMaterialSelection?.category?.toLowerCase() ===
				value?.toLowerCase()
		)?.defaultMaterialSelection

		const newMaterials = this.filteredMaterialsByCategory(
			this.materialsData,
			defaultMaterial?.category?.toLowerCase() || value
		)

		const newMaterialsCategoriesList = this.generateMaterialCategories(
			this.materialsData,
			defaultMaterial?.type || newMaterials[0].type
		)

		this.categoriesList = newMaterialsCategoriesList
		this.materialsList = newMaterials
		this.chosenCategory =
			defaultMaterial?.category?.toLowerCase() ||
			newMaterials[0]?.category?.toLowerCase()
		this.chosenMaterial = defaultMaterial || newMaterials[0]
		this.chosenType = defaultMaterial?.type || newMaterials[0]?.type
		return {
			chosenMaterialType: this.chosenType,
			chosenMaterialCategory: this.chosenCategory,
			chosenMaterial: this.chosenMaterial
		}
	}

	materialChanged = (value: any) => {
		this.chosenMaterial = this.materialsData.find(
			material => value === material.name
		)
		return {
			chosenMaterialType: this.chosenType,
			chosenMaterialCategory: this.chosenCategory,
			chosenMaterial: this.chosenMaterial
		}
	}
	generateMaterialCategories = (materials: Array<any>, type: string) => {
		if (!materials.length || !type) {
			return []
		}
		return materials
			.map(material => {
				if (material.type?.toLowerCase() === type?.toLowerCase()) {
					material.category = material.category.toLowerCase()
					return material.category
				}
			})
			.sort()
			.filter((material, pos, self) => {
				if (self.indexOf(material) == pos) return material
			})
	}

	filteredMaterialsByCategory = (materials: Array<any>, category: string) => {
		if (!materials.length || !category) {
			return []
		}
		return materials
			.filter(
				(material, pos, self) =>
					self.indexOf(material) == pos && material.category === category
			)
			.sort(sortOriginalMaterials)
	}
	generateMaterialTypes = (materials: Array<any>) => {
		if (!materials.length) {
			return []
		}
		return materials
			.map(material => material.type)
			.sort()
			.filter((material, pos, self) => {
				if (self.indexOf(material) == pos) return material
			})
	}
}
