import { cloneDeep } from 'lodash'

import { store } from 'index'
import { IPrinter } from 'Services/models/IPrintersTypes'
import { sortArrayOfObjectsInAlphabeticalOrder } from 'Services/Utils/sortingTools'

export interface ISimpleConfigurationMaterial {
	id: string
	name: string
}

export interface ISimpleConfigurationPrinter {
	printerId: string
	name: string
	company: string
	isMainstream: boolean
	isDesktop?: boolean
	materials: Array<ISimpleConfigurationMaterial>
}

export interface ISimpleConfigurationCompany {
	companyName: string
	printers: Array<ISimpleConfigurationPrinter>
}

export interface ISimpleConfigurationPrinterCompany {
	printers: Array<ISimpleConfigurationPrinter>
}

export class SimpleConfigurationSelectorService {
	private printersCompaniesMapList: Map<
		string,
		ISimpleConfigurationPrinterCompany
	> = new Map()
	private printersMapList: Map<string, ISimpleConfigurationPrinter> = new Map()
	private inhouseList: boolean = false
	private fullList: boolean = false
	private userPrinters: IPrinter[] = []
	constructor(
		private printersFullData: Array<ISimpleConfigurationCompany> = [],
		inhouseList?: boolean,
		fullList?: boolean,
		printers?: IPrinter[]
	) {
		if (inhouseList) {
			this.inhouseList = inhouseList
			this.userPrinters = printers || store?.getState()?.user.printers || []
		}
		if (fullList) {
			this.fullList = fullList
		}
		this.printersCompaniesMapList =
			this.createSimpleConfigurationsDropDownsData(this.printersFullData)
	}

	private getPrintersProperList = (printers: ISimpleConfigurationPrinter[]) => {
		if (!printers) {
			return []
		}
		if (this.inhouseList) {
			return printers.filter((printer: ISimpleConfigurationPrinter) => {
				const userPrinter = this.userPrinters.find(
					(p: any) => p.id === printer.printerId
				)
				return !!userPrinter
			})
		}
		if (this.fullList) {
			return printers
		}
		return printers.filter(
			(printer: ISimpleConfigurationPrinter) => printer.isMainstream
		)
	}

	getPrinterMaterialFullDataByMaterialID = (printerMaterialId: string) => {
		const fullDataCompany = cloneDeep(
			this.printersFullData?.find(
				(company: ISimpleConfigurationCompany) =>
					company.printers.findIndex(
						printer =>
							printer.materials.findIndex(
								material => material.id == printerMaterialId
							) > -1
					) > -1
			)
		)
		if (fullDataCompany) {
			const filteredPrinters = fullDataCompany.printers.filter(
				printer =>
					printer.materials.findIndex(
						material => material.id == printerMaterialId
					) > -1
			)
			fullDataCompany.printers = filteredPrinters
			if (fullDataCompany.printers?.length) {
				const filteredMaterials = fullDataCompany.printers[0].materials.filter(
					material => material.id == printerMaterialId
				)
				fullDataCompany.printers[0].materials = filteredMaterials
			}
		}

		return fullDataCompany
	}

	createSimpleConfigurationsDropDownsData = (
		printersFullData: Array<ISimpleConfigurationCompany>
	): Map<string, ISimpleConfigurationPrinterCompany> => {
		if (!printersFullData?.length) {
			return new Map()
		}
		const printersCompaniesMapList = new Map<
			string,
			ISimpleConfigurationPrinterCompany
		>()
		printersFullData.forEach((obj: ISimpleConfigurationCompany) => {
			let printers = this.getPrintersProperList(obj.printers)

			if (printers.length) {
				printersCompaniesMapList.set(obj.companyName, {
					printers
				})
			}
		})
		return printersCompaniesMapList
	}
	createSimpleConfigurationsPrintersLists = (
		chosenPrinterCompany: string
	): Map<string, ISimpleConfigurationPrinter> => {
		let printersCompaniesMap: Map<string, ISimpleConfigurationPrinter> =
			new Map()
		const companySubData =
			this.printersCompaniesMapList.get(chosenPrinterCompany)
		if (companySubData) {
			const companyPrinters = companySubData.printers
			companyPrinters.forEach((printerObj: any) => {
				printersCompaniesMap.set(printerObj.name, printerObj)
			})
		}
		this.printersMapList = printersCompaniesMap
		return printersCompaniesMap
	}
	getCompaniesNamesList = (): Array<string> => {
		return Array.from(this.printersCompaniesMapList.keys()).sort((a, b) => {
			const nameA = a.toLowerCase()
			const nameB = b.toLowerCase()
			if (nameA < nameB)
				//sort string ascending
				return -1
			if (nameA > nameB) return 1
			return 0
		})
	}
	getPrintersNamesList = (
		value: string
	): Array<ISimpleConfigurationPrinter> => {
		this.printersMapList = this.createSimpleConfigurationsPrintersLists(value)
		return sortArrayOfObjectsInAlphabeticalOrder(
			Array.from(this.printersMapList.values()),
			'name'
		)
	}
	getPrinterMaterialsList = (
		value: any
	): Array<ISimpleConfigurationMaterial> => {
		const chosedPrinter = this.printersMapList.get(value?.name)
		if (!chosedPrinter) {
			return []
		}
		if (!this.inhouseList) {
			return chosedPrinter.materials || []
		}
		const userPrinter: any = this.userPrinters.find(
			(p: any) => p.id === chosedPrinter.printerId
		)

		const userMaterials = chosedPrinter.materials?.filter(material => {
			const userMaterial = userPrinter?.materials?.find(
				(m: any) => m.id === material.id
			)
			return !!userMaterial
		})
		if (!userMaterials?.length) {
			return chosedPrinter.materials || []
		}
		return userMaterials
	}
}
