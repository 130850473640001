import { ChangeEvent, FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { onChangePermission } from '../AdminUsersActions'
import { togglePermission } from '../constants'
import CastorSwitch from 'Scenes/Components/CastorSwitch'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import FlexBox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IOrganization } from 'Services/models/IOrganization'
import { PROFILE_FORM_SITE_LABEL } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './AdminUserInfo.scss'

const AdminUserPermissions: FC = () => {
	const dispatch = useDispatch()
	const { organizationOwner, adminUserManager, userOrganization } = useSelector(
		(state: RootStateOrAny) => state.AdminUsersReducer
	)
	const { availableOrganizations } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const customizeOrganizationsIsOn = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
	)

	const renderUserOrganizationValue = (selected: number) => {
		const chosenOrganization: IOrganization = availableOrganizations?.find(
			(organization: IOrganization) => organization.id === selected
		)
		return `${chosenOrganization?.name}${
			chosenOrganization?.private ? ` (${getString('PRIVATE')})` : ''
		}`
	}

	return (
		<div className="admin-user-permission">
			<FlexBox
				data-qa="data-qa-organization-owner-toggle"
				alignItems="center"
				justifyContent="flex-start"
			>
				<CastorSwitch
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						dispatch(
							onChangePermission(
								e.target.checked,
								togglePermission.organizationOwner
							)
						)
					}
					checked={organizationOwner}
				/>
				<span className="data-table-text-field">
					{getString('ORGANIZATION_OWNER')}
				</span>
				<DetailsPopup
					isHover={true}
					data={getString('ORGANIZATION_OWNER_POPUP_MESSAGE')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
					inPortal={true}
				>
					<IconFactory
						iconName="info"
						className="solution-analysis-header-text-icon without-stroke"
					/>
				</DetailsPopup>
			</FlexBox>
			<FlexBox
				data-qa="data-qa-admin-toggle"
				alignItems="center"
				justifyContent="flex-start"
			>
				<CastorSwitch
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						dispatch(
							onChangePermission(
								e.target.checked,
								togglePermission.adminUserManager
							)
						)
					}
					checked={adminUserManager}
				/>
				<span className="data-table-text-field">{getString('ADMIN')}</span>
				<DetailsPopup
					isHover={true}
					data={getString('ADMIN_POPUP_MESSAGE')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
					inPortal={true}
				>
					<IconFactory
						iconName="info"
						className="solution-analysis-header-text-icon without-stroke"
					/>
				</DetailsPopup>
			</FlexBox>
			{customizeOrganizationsIsOn && (
				<div className="admin-user-permission--organization-wrapper">
					<div className="admin-user-permission--organization-label">
						{PROFILE_FORM_SITE_LABEL}:
					</div>
					<Select
						className="admin-user-permission--organization-select"
						value={userOrganization}
						renderValue={selected =>
							renderUserOrganizationValue(selected as number)
						}
						onChange={event =>
							dispatch(
								onChangePermission(
									event.target.value as number,
									togglePermission.userOrganization,
									userOrganization
								)
							)
						}
						inputProps={{
							name: 'simpleSelect',
							id: 'profile-organization'
						}}
						MenuProps={{
							PaperProps: {
								style: {
									transform: 'translate3d(0, 0, 0)'
								}
							}
						}}
					>
						{availableOrganizations?.map((organization: IOrganization) => {
							return (
								<MenuItem key={organization.id} value={organization.id}>
									{organization.name}
									{organization.private && ` (${getString('PRIVATE')})`}
								</MenuItem>
							)
						})}
					</Select>
				</div>
			)}
		</div>
	)
}

export default memo(AdminUserPermissions)
