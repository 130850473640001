import {
	GET_PROJECT_INFO,
	GET_PROJECT_INFO_FAIL,
	GET_PROJECT_INFO_SUCCESS
} from 'global actions/types'

const INITIAL_STATE = {
	folderId: null,
	isBundle: null,
	bundleId: null,
	error: false
}

const ProjectPageReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case GET_PROJECT_INFO: {
			return {
				...INITIAL_STATE
			}
		}
		case GET_PROJECT_INFO_SUCCESS: {
			const { isBundle, bundleId, id } = action.payload
			return {
				...state,
				isBundle,
				bundleId,
				folderId: id,
				error: false
			}
		}

		case GET_PROJECT_INFO_FAIL: {
			return {
				...state,
				error: true,
				getInfoSuccess: true
			}
		}

		default:
			return state
	}
}

export default ProjectPageReducer
