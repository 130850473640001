import {
	HANDLE_NOTIFICATION,
	USER_CUSTOM_SETTINGS_UPDATED
} from 'global actions/types'
import { uploadUserSettings } from 'Services/Network'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const changeHolesNotify = (
	name: string,
	value: boolean,
	organizationId?: number
) => {
	return async (dispatch: any) => {
		try {
			const { data }: any = await uploadUserSettings(
				{
					[name]: value
				},
				organizationId
			)

			if (data) {
				dispatch({
					type: USER_CUSTOM_SETTINGS_UPDATED,
					payload: { userCustomizationSettings: data.userSettings }
				})

				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: getString('DATA_WAS_UPDATED')
					}
				})
			}
		} catch (error) {
			console.log(error, 'error')
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
		}
	}
}
