import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	HANDLE_NOTIFICATION,
	SETUP_PROFILE_DETAILS,
	USER_DETAIL_CHANGED,
	USER_PROFILE_ADDRESS_CHANGED,
	USER_PROFILE_COMPANY_CHANGED,
	USER_PROFILE_EMAIL_CHANGED,
	USER_PROFILE_LOADER,
	USER_PROFILE_NAME_CHANGED,
	USER_PROFILE_ORGANIZATION_CHANGED,
	USER_PROFILE_UPDATED
} from '../../../global actions/types'
import { updateUserProfileNetwork } from '../../../Services/Network'
import {
	ERROR,
	PROFILE_NOTIFICATION_ERROR,
	PROFILE_NOTIFICATION_SUCCESS,
	SHOW_NOTIFICATION,
	SUCCESS,
	YES
} from '../../../Services/Strings'
import { isLoggedInSuccess } from 'global actions'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { IOrganization } from 'Services/models/IOrganization'
import { IUserDetails } from 'Services/models/IUser'
import { getString } from 'Services/Strings/StringService'

export const setupProfileDetails = (
	userDetails: IUserDetails,
	organizationDetails: IOrganization
) => {
	return {
		type: SETUP_PROFILE_DETAILS,
		payload: { userDetails, organizationDetails }
	}
}

export const onUserNameChange = (value: string) => {
	let validUserName = SUCCESS
	if (!value || !value.trim()) {
		validUserName = ERROR
	}
	return {
		type: USER_PROFILE_NAME_CHANGED,
		payload: { userName: value, validUserName }
	}
}

export const onUserEmailChange = (value: string) => {
	let validUserEmail = SUCCESS
	if (!verifyEmail(value)) {
		validUserEmail = ERROR
	}
	return {
		type: USER_PROFILE_EMAIL_CHANGED,
		payload: { userEmail: value, validUserEmail }
	}
}

export const onUserCompanyChange = (value: string) => {
	let validUserCompany = SUCCESS
	if (!value || !value.trim()) {
		validUserCompany = ERROR
	}
	return {
		type: USER_PROFILE_COMPANY_CHANGED,
		payload: { userCompany: value, validUserCompany }
	}
}

export const onUserOrganizationChange = (
	value: number,
	organizationDetails: IOrganization,
	userDetails: IUserDetails,
	userOrganization: number,
	isAdmin: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const warningText =
			userDetails.organization_owner &&
			organizationDetails.id === userOrganization &&
			!isAdmin
				? getString('CHANGE_ORGANIZATION_WARNING')
				: !isAdmin &&
				  !userDetails.organization_owner &&
				  organizationDetails.private
				? getString('CHANGE_PRIVATE_ORGANIZATION_WARNING')
				: ''

		if (warningText) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: warningText,
					headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
					showCancel: true,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({
							type: USER_PROFILE_ORGANIZATION_CHANGED,
							payload: { userOrganization: value }
						})
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: YES
				}
			})
		} else {
			dispatch({
				type: USER_PROFILE_ORGANIZATION_CHANGED,
				payload: { userOrganization: value }
			})
		}
	}
}

export const onValidAddressChange = (validAddress: string) => {
	return {
		type: USER_PROFILE_ADDRESS_CHANGED,
		payload: validAddress
	}
}

export const updateUserProfile = (userDetail: Record<string, any>) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: USER_PROFILE_LOADER,
			payload: true
		})
		try {
			const response = await updateUserProfileNetwork(userDetail)
			dispatch({
				type: USER_PROFILE_LOADER,
				payload: false
			})
			const preparedUserData = {
				data: {
					generalData: response.data
				}
			}
			isLoggedInSuccess(dispatch, preparedUserData)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: PROFILE_NOTIFICATION_SUCCESS
				}
			})
		} catch (error: any) {
			dispatch({
				type: USER_PROFILE_LOADER,
				payload: false
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || PROFILE_NOTIFICATION_ERROR
				}
			})
			console.log(error)
		}
	}
}

// function that returns true if value is email, false otherwise
const verifyEmail = (value: string) => {
	var emailRex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (emailRex.test(value)) {
		return true
	}
	return false
}
