import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as CostAndLeadActions from './CostAndLeadActions'
import TransparentButton from '../../../Components/TransparentButton'

class CostAndLeadActionButton extends Component {
	componentDidMount = () => {
		const { setupActionButtonText, isDefault, edit, inputKey } = this.props
		setupActionButtonText(isDefault, edit, inputKey)
	}

	onClick = clickType => {
		const {
			onActionButtonClicked,
			inputKey,
			editInputs,
			settingsData,
			originalUserSettingsValues,
			costAndLeadNames,
			adminSelectedOrganizationId
		} = this.props

		onActionButtonClicked(
			clickType,
			settingsData,
			inputKey,
			editInputs,
			originalUserSettingsValues,
			costAndLeadNames,
			adminSelectedOrganizationId
		)
	}

	renderActions = () => {
		const { inputKey, actionButtonTexts } = this.props
		if (Object.keys(actionButtonTexts).length) {
			return actionButtonTexts[inputKey].map(actionButtonText => {
				return (
					<TransparentButton
						key={actionButtonText}
						onClick={this.onClick.bind(this, actionButtonText)}
						className="custom-input--action"
					>
						{actionButtonText}
					</TransparentButton>
				)
			})
		}
	}

	render() {
		return <div>{this.renderActions()}</div>
	}
}

const mapStateToProps = ({ CostAndLeadReducer, CustomizeReducer }) => {
	const {
		editInputs,
		settingsData,
		originalUserSettingsValues,
		actionButtonTexts,
		costAndLeadNames
	} = CostAndLeadReducer
	const { adminSelectedOrganizationId } = CustomizeReducer
  
	return {
		editInputs,
		settingsData,
		originalUserSettingsValues,
		actionButtonTexts,
		costAndLeadNames,
		adminSelectedOrganizationId
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CostAndLeadActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CostAndLeadActionButton)
