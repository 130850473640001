import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import { PART_COST } from './constants'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import { ROLL_BACK_TO_FIRST_STEP } from 'global actions/types'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import {
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { getString } from 'Services/Strings/StringService'

import './CustomizeCostingFunctionEditor.scss'

const CustomizeCostingFunctionEditor: FC = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const renderFormHeader = () => {
		return (
			<div className="customize-settings--header cost-function-editor">
				<CastorFormHeader
					goBack={() => history.push(USER_HOME_ROUTE + CUSTOMIZE_ROUTE)}
					explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel />
			</div>
		)
	}

	useEffect(() => {
		// on component unmount
		return () => {
			dispatch({
				type: ROLL_BACK_TO_FIRST_STEP,
				payload: { id: PART_COST }
			})
		}
	}, [dispatch])

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			{renderFormHeader()}
			<h3 className="costing-function-editor--header">
				{getString('COSTING_FUNCTION_EDITOR_TITLE')}
			</h3>
			<div className="costing-function-editor--body">
				<Step1 />
				<Step2 />
				<Step3 />
			</div>
		</NavBarAndMaterial>
	)
}

export default CustomizeCostingFunctionEditor
