import extendedTablesStyle from '../../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import * as CustomizeUserMaterialsActions from './../CustomizeUserMaterialsActions'
import {
	IN_HOUSE_PRINTERS_ACTION,
	MATERIAL_COST_DELETE_ITEM,
	USER_MATERIAL_NAME,
	USER_MATERIAL_NO_MATERIALS_FOUND,
	USER_MATERIAL_TYPE
} from '../../../../../Services/Strings.js'
import ItemGrid from '../../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import {
	Button,
	Muted,
	Table
} from '../../../../Components/thirdParty/CreativeTim/components/index.js'
import { sortOriginalMaterials } from 'Services/Utils/sortingTools'

class CustomizeUserMaterialsTable extends Component {
	onDeleteClick = itemToDelete => {
		const { onDeleteClick, userId, adminSelectedOrganizationId } = this.props
		onDeleteClick(itemToDelete, userId, adminSelectedOrganizationId)
	}

	renderDeleteButton = itemToDelete => {
		const { classes } = this.props
		return (
			<Button
				title={MATERIAL_COST_DELETE_ITEM}
				onClick={this.onDeleteClick.bind(this, itemToDelete)}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
				customClass={classes.actionButton}
			>
				<Close className={classes.icon} />
			</Button>
		)
	}

	renderUserMaterialsTblData = () => {
		const { userMaterials } = this.props
		if (!userMaterials.length) {
			return [['', <Muted>{USER_MATERIAL_NO_MATERIALS_FOUND}</Muted>, '']]
		}

		return userMaterials
			.sort(sortOriginalMaterials)
			.map(userMaterial => [
				userMaterial.name,
				userMaterial.type,
				this.renderDeleteButton(userMaterial)
			])
	}

	render() {
		return (
			<ItemGrid
				data-qa={'data-qa-traditional-manufacturing-materials-table'}
				classes={{ grid: 'customize-user-material--table-wrapper' }}
			>
				<Table
					editTableStyle={'materialCostTableCell'}
					tableHead={[
						USER_MATERIAL_NAME,
						USER_MATERIAL_TYPE,
						IN_HOUSE_PRINTERS_ACTION
					]}
					tableData={this.renderUserMaterialsTblData()}
				/>
			</ItemGrid>
		)
	}
}

const mapStateToProps = ({
	CustomizeUserMaterialsReducer,
	user,
	CustomizeReducer
}) => {
	const userMaterials = CustomizeUserMaterialsReducer.userId
			? CustomizeUserMaterialsReducer.userMaterials
			: user.userMaterials,
		{ userId, itemToDelete, showDeleteAlert } = CustomizeUserMaterialsReducer,
		{ adminSelectedOrganizationId } = CustomizeReducer
	return {
		userMaterials,
		userId,
		itemToDelete,
		showDeleteAlert,
		adminSelectedOrganizationId
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CustomizeUserMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(extendedTablesStyle)(CustomizeUserMaterialsTable))
