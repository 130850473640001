import { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import Numeral from 'numeral'

import {
	assemblingCalculationType,
	assemblingCostOptions
} from './CostAnalysisTableConstants'
import {
	assemblyRowElement,
	generateInventoryRows,
	generateMaterialRow,
	generateTotalCostRow,
	generateUpfrontRows,
	getProductionRows,
	nonPrintedPartsRowElement
} from './CostAnalysisTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import { setAssemblingCostParams } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import {
	AssemblingParams,
	IConfiguration
} from 'Services/models/IConfiguration'
import { CustomFunctionStringCategory } from 'Services/models/IFunctionString'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { Part } from 'Services/models/IPart'
import { ISolution } from 'Services/models/ISolution'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'
import { getTheme } from 'themes/getTheme'

const { showEffectiveIcon } = getTheme()

const getCostAnalysisTableRows = (
	solution: ISolution,
	configuration: IConfiguration,
	chainBenefits: IChainBenefits | any,
	userCustomizationSettings: Record<string, any>,
	defaultSettings: Record<string, any>,
	part: Part,
	unitSystem: UnitSystem,
	isShowValuesInRanges?: boolean,
	drawingCostPercentage?: number,
	originalDesignSolution?: ISolution,
	originalDesignPartIsUnprintable?: boolean,
	onEditAssemblingCostClick?: () => void
) => {
	let costDetailsSolutionWithoutCluster
	let supplyChainCostsBreakDownWithoutCluster
	let disregardMaterial = false
	let isCluster = false
	const { fullTrayAssumption } = userCustomizationSettings

	if (part?.parts) {
		costDetailsSolutionWithoutCluster =
			configuration.clusterCombinedCostDetails as Record<string, any>
		isCluster = true

		if (!costDetailsSolutionWithoutCluster) {
			return []
		}

		supplyChainCostsBreakDownWithoutCluster =
			costDetailsSolutionWithoutCluster.costDetails
				?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
		disregardMaterial = Boolean(part?.disregardMaterial)
	}
	let originalDesignCostsBreakDown
	let originalDesignMinCostsBreakDown
	let originalDesignMaxCostsBreakDown
	let originalDesignPerPartCosts
	let originalDesignMinPartCosts
	let originalDesignMaxPartCosts

	const { maxCostDetails, minCostDetails } = solution?.costDetails

	const supplyChainCostsBreakDown =
		solution.costDetails.threeDPrintingCostsBreakDown.supplyChainCostsBreakDown

	const costsBreakDown = supplyChainCostsBreakDown.costsBreakDown
	const minCostsBreakDown =
		minCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			?.costsBreakDown
	const maxCostsBreakDown =
		maxCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			?.costsBreakDown

	// part costs
	const perPartCosts = supplyChainCostsBreakDown.perPartCosts
	const minPartCosts =
		minCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			?.perPartCosts
	const maxPartCosts =
		maxCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			?.perPartCosts

	const supplyChainTraditionalDetails =
		configuration.costResults.supplyChainTraditionalDetails || {}
	const costsBreakDownTraditional =
		supplyChainTraditionalDetails.costsBreakDown || {}
	const perPartCostsTraditional =
		supplyChainTraditionalDetails.perPartCosts || {}

	const { upfrontBreakDown = {}, inventoryBreakDown = {} } =
		costsBreakDownTraditional

	if (originalDesignSolution) {
		const {
			maxCostDetails: originalDesignMaxCostDetails,
			minCostDetails: originalDesignMinCostDetails
		} = originalDesignSolution?.costDetails
		const originalDesignSupplyChainCostsBreakDown =
			originalDesignSolution.costDetails.threeDPrintingCostsBreakDown
				.supplyChainCostsBreakDown

		originalDesignCostsBreakDown =
			originalDesignSupplyChainCostsBreakDown.costsBreakDown
		originalDesignMinCostsBreakDown =
			originalDesignMinCostDetails?.threeDPrintingCostsBreakDown
				?.supplyChainCostsBreakDown?.costsBreakDown
		originalDesignMaxCostsBreakDown =
			originalDesignMaxCostDetails?.threeDPrintingCostsBreakDown
				?.supplyChainCostsBreakDown?.costsBreakDown
		originalDesignPerPartCosts =
			originalDesignSupplyChainCostsBreakDown.perPartCosts
		originalDesignMinPartCosts =
			originalDesignMinCostDetails?.threeDPrintingCostsBreakDown
				?.supplyChainCostsBreakDown?.perPartCosts
		originalDesignMaxPartCosts =
			originalDesignMaxCostDetails?.threeDPrintingCostsBreakDown
				?.supplyChainCostsBreakDown?.perPartCosts
	}
	const totalPartEffective =
		solution.costDetails.totalCost <
			(costDetailsSolutionWithoutCluster
				? costDetailsSolutionWithoutCluster.costDetails?.totalCost
				: configuration.costResults.actualResult) &&
		(originalDesignSolution
			? solution.costDetails.totalCost <=
			  originalDesignSolution.costDetails.totalCost
			: true)
	const upfrontEffective =
		perPartCosts.totalUpfrontCostsPerUnit <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.perPartCosts
						?.totalUpfrontCostsPerUnit
				: perPartCostsTraditional.totalUpfrontCostsPerUnit) &&
		(originalDesignPerPartCosts
			? perPartCosts.totalUpfrontCostsPerUnit <=
			  originalDesignPerPartCosts.totalUpfrontCostsPerUnit
			: true)
	const toolingEffective =
		costsBreakDown.upfrontBreakDown.tooling <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.costsBreakDown
						?.upfrontBreakDown.tooling
				: upfrontBreakDown.tooling) &&
		(originalDesignCostsBreakDown
			? costsBreakDown.upfrontBreakDown.tooling <=
			  originalDesignCostsBreakDown.upfrontBreakDown.tooling
			: true)
	const designEffective =
		costsBreakDown.upfrontBreakDown.design <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.costsBreakDown
						?.upfrontBreakDown.design
				: upfrontBreakDown.design) &&
		(originalDesignCostsBreakDown
			? costsBreakDown.upfrontBreakDown.design <=
			  originalDesignCostsBreakDown.upfrontBreakDown.design
			: true)

	const verificationEffective =
		costsBreakDown.upfrontBreakDown.verification <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.costsBreakDown
						?.upfrontBreakDown.verification
				: upfrontBreakDown.verification) &&
		(originalDesignCostsBreakDown
			? costsBreakDown.upfrontBreakDown.verification <=
			  originalDesignCostsBreakDown.upfrontBreakDown.verification
			: true)
	const productionEffective =
		perPartCosts.totalProductionCostsPerUnit <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.perPartCosts
						?.totalProductionCostsPerUnit
				: perPartCostsTraditional.totalProductionCostsPerUnit) &&
		(originalDesignPerPartCosts
			? perPartCosts.totalProductionCostsPerUnit <=
			  originalDesignPerPartCosts.totalProductionCostsPerUnit
			: true)
	const inventoryEffective =
		perPartCosts?.totalInventoryCostsPerUnit <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.perPartCosts
						?.totalInventoryCostsPerUnit
				: perPartCostsTraditional?.totalInventoryCostsPerUnit) &&
		(originalDesignPerPartCosts
			? perPartCosts?.totalInventoryCostsPerUnit <=
			  originalDesignPerPartCosts?.totalInventoryCostsPerUnit
			: true)

	const holdingEffective =
		costsBreakDown.inventoryBreakDown.holding <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.costsBreakDown
						?.inventoryBreakDown.holding
				: inventoryBreakDown.holding) &&
		(originalDesignCostsBreakDown
			? costsBreakDown.inventoryBreakDown.holding <=
			  originalDesignCostsBreakDown.inventoryBreakDown.holding
			: true)

	const orderingEffective =
		costsBreakDown.inventoryBreakDown.ordering <
		(costDetailsSolutionWithoutCluster
			? supplyChainCostsBreakDownWithoutCluster?.costsBreakDown
					?.inventoryBreakDown.ordering
			: inventoryBreakDown.ordering)
	const obsolescenceEffective =
		costsBreakDown.inventoryBreakDown.obsolescence <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.costsBreakDown
						?.inventoryBreakDown.obsolescence
				: inventoryBreakDown.obsolescence) &&
		(originalDesignCostsBreakDown
			? costsBreakDown.inventoryBreakDown.ordering <=
			  originalDesignCostsBreakDown.inventoryBreakDown.ordering
			: true)

	const annualEffective =
		costsBreakDown.inventoryBreakDown.annualMaintenance <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.costsBreakDown
						?.inventoryBreakDown.annualMaintenance
				: inventoryBreakDown.annualMaintenance) &&
		(originalDesignCostsBreakDown
			? costsBreakDown.inventoryBreakDown.annualMaintenance <=
			  originalDesignCostsBreakDown.inventoryBreakDown.annualMaintenance
			: true)

	const otherEffective =
		costsBreakDown.inventoryBreakDown.other <
			(costDetailsSolutionWithoutCluster
				? supplyChainCostsBreakDownWithoutCluster?.costsBreakDown
						?.inventoryBreakDown.other
				: inventoryBreakDown.other) &&
		(originalDesignCostsBreakDown
			? costsBreakDown.inventoryBreakDown.other <=
			  originalDesignCostsBreakDown.inventoryBreakDown.other
			: true)

	const supplyChainOn = chainBenefits?.Global?.on

	const isStandardCost =
		configuration.manufactureMethod === ImanufacturingTypes.standardCost ||
		part.blockManufacturingMethodOperation

	const productionCostIsCustomized = solution?.costCalculatedWithCustomFunction
		? solution.costCalculatedWithCustomFunction[
				CustomFunctionStringCategory.productionCost
		  ]
		: false

	const traditionalCostCalculatedCustom =
		configuration?.traditionalCostCalculatedCustom

	let rows = [
		generateMaterialRow(
			solution,
			configuration,
			costDetailsSolutionWithoutCluster,
			disregardMaterial,
			originalDesignSolution,
			originalDesignPartIsUnprintable
		),
		generateTotalCostRow(
			showEffectiveIcon,
			totalPartEffective,
			solution,
			configuration,
			supplyChainOn,
			userCustomizationSettings,
			part,
			chainBenefits,
			isStandardCost,
			isShowValuesInRanges,
			drawingCostPercentage,
			costDetailsSolutionWithoutCluster,
			originalDesignSolution,
			originalDesignPartIsUnprintable
		)
	]

	if (
		isCluster &&
		costDetailsSolutionWithoutCluster?.costDetails.threeDPrintingCostsBreakDown
			.nonPrintedPartsCost
	) {
		rows.push(
			nonPrintedPartsRowElement(
				costDetailsSolutionWithoutCluster.costDetails
					.threeDPrintingCostsBreakDown.nonPrintedPartsCost
			)
		)
	}

	if (
		(isCluster && productionCostIsCustomized) ||
		(traditionalCostCalculatedCustom &&
			productionCostIsCustomized &&
			!originalDesignSolution)
	) {
		if (isCluster) {
			const assemblingCost = Numeral(
				solution.costDetails.threeDPrintingCostsBreakDown.assemblingCost
			).format('0,0[.]00')
			const assemblingCostWithoutCluster = Numeral(
				costDetailsSolutionWithoutCluster?.costDetails
					.threeDPrintingCostsBreakDown.assemblingCost
			).format('0,0[.]00')

			rows.push(
				assemblyRowElement(
					`${getString('ASSEMBLY')} [$]`,
					assemblingCost,
					assemblingCostWithoutCluster,
					false,
					false,
					false,
					onEditAssemblingCostClick
				)
			)
		}
		return rows
	}

	const allRows = [
		generateUpfrontRows(
			supplyChainOn,
			perPartCosts,
			showEffectiveIcon,
			upfrontEffective,
			perPartCostsTraditional,
			costsBreakDown,
			toolingEffective,
			supplyChainTraditionalDetails,
			designEffective,
			verificationEffective,
			isStandardCost,
			isShowValuesInRanges,
			minPartCosts,
			maxPartCosts,
			minCostsBreakDown,
			maxCostsBreakDown,
			productionCostIsCustomized,
			traditionalCostCalculatedCustom,
			costDetailsSolutionWithoutCluster,
			originalDesignCostsBreakDown,
			originalDesignMinPartCosts,
			originalDesignMaxPartCosts,
			originalDesignMinCostsBreakDown,
			originalDesignMaxCostsBreakDown,
			originalDesignPerPartCosts,
			originalDesignPartIsUnprintable
		),
		getProductionRows(
			perPartCosts,
			showEffectiveIcon,
			productionEffective,
			perPartCostsTraditional,
			solution,
			configuration,
			isShowValuesInRanges,
			unitSystem,
			costDetailsSolutionWithoutCluster,
			minPartCosts,
			maxPartCosts,
			productionCostIsCustomized,
			traditionalCostCalculatedCustom,
			fullTrayAssumption,
			originalDesignPerPartCosts,
			originalDesignMinPartCosts,
			originalDesignMaxPartCosts,
			originalDesignSolution,
			originalDesignPartIsUnprintable,
			isCluster,
			onEditAssemblingCostClick
		),
		generateInventoryRows(
			supplyChainOn,
			perPartCosts,
			showEffectiveIcon,
			inventoryEffective,
			perPartCostsTraditional,
			costsBreakDown,
			holdingEffective,
			supplyChainTraditionalDetails,
			orderingEffective,
			obsolescenceEffective,
			annualEffective,
			otherEffective,
			isShowValuesInRanges,
			minPartCosts,
			maxPartCosts,
			minCostsBreakDown,
			maxCostsBreakDown,
			productionCostIsCustomized,
			traditionalCostCalculatedCustom,
			costDetailsSolutionWithoutCluster,
			originalDesignPerPartCosts,
			originalDesignMinPartCosts,
			originalDesignMaxPartCosts,
			originalDesignCostsBreakDown,
			originalDesignMinCostsBreakDown,
			originalDesignMaxCostsBreakDown,
			originalDesignPartIsUnprintable
		)
	]
	return [...rows, ...allRows]
}

export function useCostAnalysisTableRows(
	solution: ISolution,
	configuration: any,
	chainBenefits: any,
	userCustomizationSettings: Record<string, any>,
	defaultSettings: Record<string, any>,
	part: Part,
	drawingCostPercentage: number,
	originalDesignSolution: ISolution,
	originalDesignPartIsUnprintable?: boolean,
	onEditAssemblingCostClick?: () => void
) {
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const { userUnitSystem } = useSelector((state: RootStateOrAny) => state.user)
	const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric

	const [costAnalysisTableRows, setCostAnalysisTableRows] = useState(
		getCostAnalysisTableRows(
			solution,
			configuration,
			chainBenefits,
			userCustomizationSettings,
			defaultSettings,
			part,
			unitSystem,
			isShowValuesInRanges,
			drawingCostPercentage,
			originalDesignSolution,
			originalDesignPartIsUnprintable,
			onEditAssemblingCostClick
		)
	)

	useEffect(() => {
		setCostAnalysisTableRows(
			getCostAnalysisTableRows(
				solution,
				configuration,
				chainBenefits,
				userCustomizationSettings,
				defaultSettings,
				part,
				unitSystem,
				isShowValuesInRanges,
				drawingCostPercentage,
				originalDesignSolution,
				originalDesignPartIsUnprintable,
				onEditAssemblingCostClick
			)
		)
	}, [
		solution,
		configuration,
		chainBenefits,
		originalDesignSolution,
		userCustomizationSettings
	])

	return costAnalysisTableRows
}

export function removeLastElement(rows: any[], isWeightReduction: boolean) {
	for (let row of rows) {
		if (!Array.isArray(row[0])) {
			if (isWeightReduction) {
				row?.splice(3, 2)
			} else {
				row?.splice(2, 2)
			}
			row = row.map((r: any) => {
				r.type =
					r.type && r.type === DataTableFieldType.TextWithIcon
						? DataTableFieldType.Text
						: r.type
			})
		}

		if (Array.isArray(row[0])) {
			removeLastElement(row, isWeightReduction)
		}
	}
}

export const useAssemblingCostModal = (
	configurationId: number,
	onAssemblyModalCancel: () => void,
	assemblingParams?: AssemblingParams | null,
	showAssemblingCostAlert?: boolean
) => {
	const [initialType, setInitialType] = useState(
		assemblingCalculationType.DEFAULT
	)
	const [initialValue, setInitialValue] = useState('')
	const [type, setType] = useState(assemblingCalculationType.DEFAULT)
	const [value, setValue] = useState('')
	const [loading, setLoading] = useState(false)

	const dispatch = useDispatch()

	const confirmDisabled =
		(type !== assemblingCalculationType.DEFAULT && !value) ||
		(initialValue === value && initialType === type)

	const onTypeChange = (event: React.ChangeEvent<any>) => {
		setType(event.target.value)
		setValue('')
	}
	const onValueChange = (value: string) => {
		setValue(value)
	}
	const onModalClose = () => {
		onAssemblyModalCancel()
		setType(assemblingCalculationType.DEFAULT)
		setValue('')
	}
	const onModalConfirm = () => {
		dispatch(setAssemblingCostParams(configurationId, type, value, setLoading))
	}

	const renderTypeTitle = (selected?: unknown): any => {
		const option = assemblingCostOptions.find(
			option => option.type === selected
		)
		return option?.text
	}

	useEffect(() => {
		let type = assemblingCalculationType.DEFAULT
		let value = ''
		if (typeof assemblingParams?.totalCost !== 'undefined') {
			type = assemblingCalculationType.TOTAL_COST
			value = assemblingParams?.totalCost.toString()
		} else if (typeof assemblingParams?.totalTime !== 'undefined') {
			type = assemblingCalculationType.TOTAL_TIME
			value = assemblingParams?.totalTime.toString()
		}
		setType(type)
		setInitialType(type)
		setValue(value)
		setInitialValue(value)
	}, [
		assemblingParams?.totalCost,
		assemblingParams?.totalTime,
		showAssemblingCostAlert
	])

	return {
		type,
		value,
		loading,
		confirmDisabled,
		onModalConfirm,
		onModalClose,
		onTypeChange,
		onValueChange,
		renderTypeTitle
	}
}
