import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { COST_SUPPLY_CHAIN_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import {
	isContentPresent,
	isCustomProjectScenarioChanged
} from '../../OnboardingWizardService'
import OnboardingStepFrame from '../OnboardingStepFrame'
import { fetchCustomizeData } from 'Scenes/Home/Customize/CustomizeActions'
import CostCalculationParams from 'Scenes/Home/Customize/CustomizeCostAndLeadSettings/CostCalculationParams'
import CustomizeSupplyChain from 'Scenes/Home/Customize/CustomizeSupplyChain/CustomizeSupplyChain'
import CustomizeSupplyChainRadioButton from 'Scenes/Home/Customize/CustomizeSupplyChain/CustomizeSupplyChainRadioButton'
import CustomizeUserPostProcesses from 'Scenes/Home/Customize/CustomizeUserPostProcesses/CustomizeUserPostProcesses'
import { defaultSettingScenario } from 'Services/Constants'
import { IPostProcess } from 'Services/models/IPostProcess'

import './CostAndSupplyChain.scss'

const CostAndSupplyChainComponents = {
	[COST_SUPPLY_CHAIN_IDS.PROJECT_LIFE_CYCLE]: (
		<CustomizeSupplyChainRadioButton />
	),
	[COST_SUPPLY_CHAIN_IDS.COST_CALCULATION]: <CostCalculationParams />,
	[COST_SUPPLY_CHAIN_IDS.SUPPLY_CHAIN]: <CustomizeSupplyChain />,
	[COST_SUPPLY_CHAIN_IDS.POST_PROCESSES]: <CustomizeUserPostProcesses />
}

const CostAndSupplyChain: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const {
		costAndLeadNames,
		drawingCost2dNames,
		settingsData,
		userSettingsEditMode
	} = useSelector((state: RootStateOrAny) => state.CostAndLeadReducer)

	const { baseDefaultProjectScenarios, projectScenarioSettings } = useSelector(
		(state: RootStateOrAny) => state.CustomizeSupplyChainReducer
	)
	const { defaultProjectScenario, allOptionalPostProcessesData } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const { step, stepContent } = useStep(steps, STEP_ID.COST_AND_SUPPLY_CHAIN)
	const dispatch = useDispatch()

	const changesInCurrentStep =
		(isContentPresent(stepContent, COST_SUPPLY_CHAIN_IDS.COST_CALCULATION) &&
			Object.keys(costAndLeadNames).some(key => {
				const userSettings = settingsData?.userSettings || {}
				return userSettings[key]
			}) &&
			!userSettingsEditMode) ||
		(isContentPresent(stepContent, COST_SUPPLY_CHAIN_IDS.SUPPLY_CHAIN) &&
			isCustomProjectScenarioChanged(
				baseDefaultProjectScenarios,
				projectScenarioSettings
			)) ||
		(isContentPresent(stepContent, COST_SUPPLY_CHAIN_IDS.PROJECT_LIFE_CYCLE) &&
			defaultProjectScenario &&
			defaultProjectScenario !== defaultSettingScenario.lowVolume) ||
		(isContentPresent(stepContent, COST_SUPPLY_CHAIN_IDS.POST_PROCESSES) &&
			allOptionalPostProcessesData &&
			allOptionalPostProcessesData.some(
				(postProcess: IPostProcess) =>
					postProcess.organizationId || postProcess.custom
			))

	useEffect(() => {
		dispatch(
			fetchCustomizeData(
				costAndLeadNames,
				settingsData,
				drawingCost2dNames,
				undefined,
				adminSelectedOrganizationId,
				true
			)
		)
	}, [])

	return (
		<OnboardingStepFrame
			step={step}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div className="cost-and-supply-chain--wrapper">
				{stepContent?.map(component => {
					return (
						<React.Fragment key={component.id}>
							{
								CostAndSupplyChainComponents[
									component.id as keyof typeof CostAndSupplyChainComponents
								]
							}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default memo(CostAndSupplyChain)
