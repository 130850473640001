import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	CUSTOMIZE_BUTTON_LOADER_UPDATED,
	HANDLE_LOADER,
	OFF_THE_SHELF_SUBSTRING_CHANGE,
	PART_FILTERING_FORM_DATA_ARRANGED,
	SAVE_PART_FILTERING_ERROR
} from '../../../../global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import {
	getCustomizationSettings,
	uploadUserSettings
} from '../../../../Services/Network'
import {
	CONFIRM,
	OFF_THE_SHELF_SELECTOR_ITEMS
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import { AlertType } from '../../../Components/alerts/AlertTypes'
import {
	getGlobalFilteringArray,
	getPartFilteringArray,
	validateUserSettingsResponseData
} from '../CustomizeLogic'
import { TagParams } from './PartFilteringService'

export const partFilteringChanged = (
	partFilteringArr: TagParams[],
	add: boolean,
	reset: boolean = false,
	organizationId: string
) => {
	const offTheShelfSubstrings = partFilteringArr
		.filter(tag => tag.itemType === OFF_THE_SHELF_SELECTOR_ITEMS[0])
		.map(tag => tag.text)
	const manufacturedSubstrings = partFilteringArr
		.filter(tag => tag.itemType === OFF_THE_SHELF_SELECTOR_ITEMS[1])
		.map(tag => tag.text)

	if (reset) {
		return (dispatch: Dispatch<AnyAction>) => {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('RESET_ALL_MESSAGE'),
					headerTitle: getString('RESET_ALL'),
					alertType: AlertType.WARNING,
					onConfirm: () => {
						updateUserTagSettings(
							dispatch,
							partFilteringArr,
							offTheShelfSubstrings,
							manufacturedSubstrings,
							organizationId
						)
						dispatch({ type: ALERT_POPUP_CANCELED })
					},
					confirmText: CONFIRM
				}
			})
		}
	}

	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
			payload: !!add
		})

		updateUserTagSettings(
			dispatch,
			partFilteringArr,
			offTheShelfSubstrings,
			manufacturedSubstrings,
			organizationId
		)
	}
}

const updateUserTagSettings = (
	dispatch: Dispatch<AnyAction>,
	partFilteringArr: TagParams[],
	offTheShelfSubstrings: string[],
	manufacturedSubstrings: string[],
	organizationId: string
) => {
	uploadUserSettings(
		{ offTheShelfSubstrings, manufacturedSubstrings },
		organizationId
	)
		.then(() => {
			dispatch({
				type: OFF_THE_SHELF_SUBSTRING_CHANGE,
				payload: {
					partFilteringArr,
					offTheShelfSubstrings,
					manufacturedSubstrings
				}
			})
			dispatch({
				type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
				payload: false
			})
		})
		.catch((error: any) => {
			dispatch({
				type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
				payload: false
			})
			dispatch({
				type: SAVE_PART_FILTERING_ERROR
			})
			console.error(error)
		})
}

export const getPartFilteringData = (organizationId: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		getCustomizationSettings(organizationId)
			.then(response => {
				const settingsData = validateUserSettingsResponseData(response.data)
				const globalOffTheShelfSubstrings =
					settingsData.userSettings.globalOffTheShelfSubstrings
				const partFilteringArr = getPartFilteringArray(settingsData)
				const filteredGlobalOffTheShelf = getGlobalFilteringArray(
					globalOffTheShelfSubstrings
				)

				dispatch({
					type: PART_FILTERING_FORM_DATA_ARRANGED,
					payload: {
						partFilteringArr,
						filteredGlobalOffTheShelf
					}
				})
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
			})
			.catch(error => {
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				console.error(error)
			})
	}
}
