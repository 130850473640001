import { ChangeEvent, FC, memo } from 'react'

import { customizeGeometryAnalysisStrings } from './PartFilteringService'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import InfoBox from 'Scenes/Components/InfoBox'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

interface CustomizePartNameCheckboxProps {
	updateSelectedValue: (value: boolean) => void
	excludePartSubstrings: boolean
}

const CustomizePartNameCheckbox: FC<CustomizePartNameCheckboxProps> = ({
	updateSelectedValue,
	excludePartSubstrings
}) => {
	return (
		<div className="analysis-exclude-strings-checkbox">
			<CastorCheckbox
				className="solution-feature-modal-checkbox"
				checked={excludePartSubstrings}
				onChange={(event: ChangeEvent<HTMLInputElement>) =>
					updateSelectedValue(event.target.checked)
				}
			/>
			<span>
				{getString(customizeGeometryAnalysisStrings.excludePartSubstrings)}
			</span>
			<InfoBox
				boxContact={getString('CUSTOMIZE_PART_FILTERING_BY_NAME_EXCLUDE')}
				iconClassName="admin-form-field__info_icon"
				boxDirection="auto"
				inPortal={true}
			/>
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(CustomizePartNameCheckbox),
	FeatureComponentId.GEOMETRY_ANALYSIS_FILTER
)
