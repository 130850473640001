import { ChangeEvent, FC, memo, useEffect, useMemo, useState } from 'react'

import Plus from '@material-ui/icons/Add'
import Minus from '@material-ui/icons/Remove'
import cx from 'classnames'
import { isNull, isNumber } from 'lodash'

import Devider from 'Scenes/Components/Devider/Devider'
import FlexBox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { MAX_LAYER_THICKNESS } from 'Scenes/Components/PrinterForm/constants'
import TextField from 'Scenes/Components/TextField/TextField'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { maxMultiplier } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisService'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

interface CostLayerThicknessProps {
	initialPrinterValue: number
	layerThicknessValue: number
	isSettings?: boolean
	withInfo?: boolean
	defaultLayerThickness: number
	inputClassName?: string
	onChangeLayerThickness: (value: number | null) => void
	onResetLayerThickness: () => void
}

const CostLayerThickness: FC<CostLayerThicknessProps> = ({
	initialPrinterValue,
	layerThicknessValue,
	isSettings = false,
	withInfo = false,
	inputClassName = '',
	onChangeLayerThickness,
	defaultLayerThickness = 0,
	onResetLayerThickness
}) => {
	const [initialLayerThickness, setInitialLayerThickness] =
		useState<number>(initialPrinterValue)

	const updateLayerThickness = (value: number) => {
		onResetLayerThickness()

		if (value) {
			onChangeLayerThickness(value)
		}
	}

	useEffect(() => {
		updateLayerThickness(layerThicknessValue)
	}, [layerThicknessValue])

	useEffect(() => {
		setInitialLayerThickness(initialPrinterValue)
	}, [initialPrinterValue])

	useEffect(() => {
		updateLayerThickness(layerThicknessValue)
		setInitialLayerThickness(initialPrinterValue)

		return () => {
			onResetLayerThickness()
		}
	}, [])

	const handleChange = (value: number | string) => {
		onChangeLayerThickness(+value)
	}

	const inputValue = useMemo(
		() => (isNull(defaultLayerThickness) ? '' : defaultLayerThickness || '0'),
		[defaultLayerThickness]
	)

	const disableMinus = useMemo(
		() =>
			!layerThicknessValue ||
			defaultLayerThickness <= 0 ||
			defaultLayerThickness <= initialLayerThickness / maxMultiplier,
		[defaultLayerThickness, layerThicknessValue, initialLayerThickness]
	)

	const disablePlus = useMemo(
		() =>
			!layerThicknessValue ||
			defaultLayerThickness >= initialLayerThickness * maxMultiplier ||
			defaultLayerThickness > MAX_LAYER_THICKNESS,
		[defaultLayerThickness, layerThicknessValue, initialLayerThickness]
	)

	const errorMessage = useMemo(() => {
		const valueExistAndDisabled =
			isNumber(defaultLayerThickness) && (disableMinus || disablePlus)
		const minMaxError =
			defaultLayerThickness === 0 || defaultLayerThickness > MAX_LAYER_THICKNESS

		return valueExistAndDisabled
			? minMaxError
				? getString('NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING_ZERO').format(
						0,
						MAX_LAYER_THICKNESS
				  )
				: getString('SETTING_LAYER_THICKNESS_ERROR').format(
						maxMultiplier,
						maxMultiplier
				  )
			: ''
	}, [defaultLayerThickness, disableMinus, disablePlus])

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	return (
		<>
			{isSettings ? (
				<Devider size="95%" className="cost-details-row-divider" />
			) : (
				<></>
			)}
			<div className={cx({ 'disabled-section': !layerThicknessValue })}>
				<div className="co2-parameters">
					<FlexBox className="co2-parameters-header" alignItems="center">
						{getString('SETTING_LAYER_THICKNESS')}
						{withInfo ? (
							<InfoBox
								boxContact={getString('SETTING_LAYER_THICKNESS_TEXT')}
								iconClassName="cost-details-icon"
							/>
						) : (
							<>:</>
						)}
					</FlexBox>

					{withInfo ? (
						<></>
					) : (
						<div className="co2-parameters-description">
							{getString('SETTING_LAYER_THICKNESS_TEXT')}
						</div>
					)}

					<div className="text_field financial-form--text-field">
						<div className="text_field__label financial-form--text-field-label">
							{getString('SETTING_LAYER_THICKNESS_LABEL')}
						</div>
						<FlexBox className="thickness-layer" alignItems="center">
							<Button
								disabled={disableMinus}
								className="plus-button"
								color="transparent"
								onClick={() => {
									handleChange(
										defaultLayerThickness - initialLayerThickness / 2
									)
								}}
							>
								<Minus />
							</Button>
							<TextField
								withDebounce={true}
								dataQa="thickness"
								type="number"
								preventKeyDownProps={handleKeyDown}
								disabled={!layerThicknessValue}
								inputClassName={inputClassName}
								inputProps={{ value: inputValue }}
								input={{
									onChange: (event: ChangeEvent<HTMLInputElement>) =>
										handleChange(event.target.value)
								}}
							/>
							<Button
								disabled={disablePlus}
								className="minus-button"
								color="transparent"
								onClick={() => {
									handleChange(
										defaultLayerThickness + initialLayerThickness / 2
									)
								}}
							>
								<Plus />
							</Button>
							{initialLayerThickness ? (
								<p className="initial-value">
									{getString('INITIAL_VALUE')}: {initialLayerThickness}
								</p>
							) : (
								<p></p>
							)}
						</FlexBox>
					</div>
					<p className="error">{errorMessage}</p>
				</div>
			</div>
		</>
	)
}

export default memo(
	WithFeatureToggleHOC(
		CostLayerThickness,
		FeatureComponentId.CUSTOMIZE_LAYER_THICKNESS
	)
)
