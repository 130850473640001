import { cloneDeep, forEach, isEmpty, isString, max } from 'lodash'

import { getManufacturingNameWihCompare } from '../../SolutionAnalysisTabsService'
import { checkErrorValues } from './LeadTimeForm/constants'
import {
	IComparisonPoint,
	IFormParameters,
	ILeadTimeResults,
	IPreparedValues,
	ManufacturingData
} from './LeadTimeInterface'
import { amColor, crossColor, methodColor } from 'Services/colors'
import {
	chartGraphDataOptions,
	manufacturingMethodTypes
} from 'Services/Constants'
import {
	ISeries,
	LeadData,
	LeadDataWithSolution
} from 'Services/models/CostComparisonModels'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { Part } from 'Services/models/IPart'
import { REQUIRED } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const theme = getTheme()

const defaultAM = [
	{ days: 2, quantity: 10 },
	{ days: 10, quantity: 29 }
]

const defaultTM = [
	{ days: 6, quantity: 10 },
	{ days: 11, quantity: 29 }
]

const makeDataSeries = (resultValue: ManufacturingData[]) => {
	const makeArrOfValue: IPreparedValues[] = []
	resultValue?.forEach(({ days, quantity }) => {
		makeArrOfValue.push({
			x: days,
			y: quantity
		})
	})
	return makeArrOfValue
}

const makeDataKeys = (resultValue: ManufacturingData[], key: string) => {
	const makeArrOfValue: number[] = []
	resultValue.forEach((data: ManufacturingData) => {
		makeArrOfValue.push(data[key])
	})
	return makeArrOfValue
}

export const getStepsValue = (
	upperValueAM: number,
	upperValueTM: number,
	isStandard: boolean,
	countOfSteps: number = 8
) => {
	let upper: number = upperValueAM

	if (!isStandard) {
		upper = upperValueAM > upperValueTM ? upperValueAM : upperValueTM
	}

	const maxPosVal = upper % 2 > 0 ? upper + 1 : upper + 2

	// Get values for showing step in chart
	const steps: number = Math.ceil(maxPosVal / countOfSteps)
	const stepValue = Math.ceil(maxPosVal / steps)
	const maxValue = stepValue * steps

	return { maxValue, stepValue }
}

export class LeadTimeService {
	drawChart = (
		tmResults: ManufacturingData[],
		amResults: ManufacturingData[],
		comparisonPoint: IComparisonPoint[],
		manufactureMethod: string,
		isStandard: boolean,
		configuration: any,
		isAmOriginalMaterial: boolean
	): any => {
		const countOfStep: number = 4
		const minOfStep: number = 5
		let upperValueTM: number = 0
		const componentChartData: any = cloneDeep(chartGraphDataOptions)
		const comparisonExist = comparisonPoint?.length > 1
		let graphLineText: string =
			getManufacturingNameWihCompare(manufactureMethod)

		// initialize data values
		let amResultsKeys: any[] = []
		let amResultsQuantities: any[] = []
		let amResultsValues: any[] = []
		let tmResultsKeys: any[] = []
		let tmResultsQuantities: any[] = []
		let tmResultsValues: any[] = []

		if (amResults) {
			amResultsKeys = makeDataKeys(amResults, 'days') || []
			amResultsQuantities = makeDataKeys(amResults, 'quantity') || []
			amResultsValues = makeDataSeries(amResults) || []
		}

		if (!isStandard && tmResults.length > 0) {
			tmResultsKeys = makeDataKeys(tmResults, 'days') || []
			tmResultsQuantities = makeDataKeys(tmResults, 'quantity') || []
			tmResultsValues = makeDataSeries(tmResults) || []
			upperValueTM = tmResultsKeys[tmResultsKeys.length - 1]
		}
		const lastAmPoint =
			amResultsValues.length > 0
				? amResultsValues[amResultsValues.length - 1]
				: null
		const upperValueAM =
			amResultsKeys.length > 0 ? +amResultsKeys[amResultsKeys.length - 1] : 0
		const firstParts = amResultsValues[0]?.y
		const allParts = (lastAmPoint && lastAmPoint?.y) || null

		const quantityOffsetY = -5
		const quantityOffsetX = 10

		const { maxValue: maxXValue, stepValue: stepXValue } = getStepsValue(
			upperValueAM,
			upperValueTM,
			isStandard
		)

		const { maxValue: maxYValue, stepValue: stepYValue } = getStepsValue(
			max(amResultsQuantities),
			max(tmResultsQuantities),
			isStandard,
			countOfStep
		)

		let series: ISeries[] = []

		componentChartData.colors = [amColor, methodColor, crossColor]

		// X-axis
		componentChartData.xaxis.type = 'numeric'
		componentChartData.xaxis.title.text = ''
		componentChartData.xaxis.categories = []
		componentChartData.xaxis.min = 0
		componentChartData.xaxis.max = maxXValue
		componentChartData.xaxis.step = stepXValue
		componentChartData.xaxis.tickPlacement = undefined
		componentChartData.xaxis.tickAmount = stepXValue

		// Y-axis
		componentChartData.yaxis.tickPlacement = undefined
		componentChartData.yaxis.forceNiceScale = true
		componentChartData.yaxis.max = minOfStep
		componentChartData.yaxis.step = minOfStep
		componentChartData.yaxis.tickAmount = minOfStep

		if (maxYValue > minOfStep) {
			componentChartData.yaxis.max = maxYValue + maxYValue / stepYValue
			componentChartData.yaxis.step = stepYValue
			componentChartData.yaxis.tickAmount = stepYValue
		}

		componentChartData.xaxis.labels = {
			show: true,
			formatter: (val: any) => {
				return `${val.toFixed(0)}`
			}
		}

		componentChartData.title.text = isAmOriginalMaterial
			? getString('PART_ANALYSIS_TABS_TITLE_LEAD_TIME')
			: isStandard
			? getString('PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_LEAD_NO_COMPARE')
			: getString('PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_LEAD_METAL').format(
					graphLineText
			  )
		componentChartData.yaxis.title.text = getString(
			'PART_FINANCIAL_ANALYSIS_QUANTITY_OF_PART'
		)

		componentChartData.stroke = {
			curve: 'stepline',
			width: 3
		}

		componentChartData.markers = {
			size: 6,
			strokeWidth: 0,
			hover: {
				size: 8
			}
		}

		// add dotted border
		componentChartData.grid = {
			show: true,
			borderColor: theme.colors.chartLineColor,
			strokeDashArray: 4,
			position: 'back',
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: true
				}
			}
		}

		componentChartData.annotations = {
			xaxis: [
				!isStandard && !isAmOriginalMaterial
					? {
							x: upperValueTM,
							strokeDashArray: 3,
							borderColor: theme.colors.chartMethodColor,
							label: {
								borderColor: 'transparent',
								textAnchor: 'start',
								offsetY: quantityOffsetY,
								offsetX: quantityOffsetX,
								orientation: 'horizontal',
								style: {
									color: theme.colors.benefitsBackground,
									background: 'transparent'
								},
								text: ''
							}
					  }
					: {},
				{
					x: upperValueAM,
					strokeDashArray: 3,
					borderColor: theme.colors.chartAMColor,
					label: {
						borderColor: 'transparent',
						textAnchor: 'start',
						offsetY: quantityOffsetY,
						offsetX: quantityOffsetX,
						orientation: 'horizontal',
						style: {
							color: theme.colors.chartAMColor,
							background: 'transparent'
						},
						text: ''
					}
				}
			],
			texts: [
				{
					x: 0,
					y: theme.costAnalysisTextOffset,
					text: getString('DELIVERY_TIME_DAYS'),
					textAnchor: 'start',
					fontSize: '13px',
					fontWeight: 400,
					appendTo: '.apexcharts-xaxis-title',
					backgroundColor: 'transparent'
				}
			]
		}

		series = [
			{
				name: getString('AM'),
				data: amResultsValues
			}
		]

		if (!isStandard && !isAmOriginalMaterial) {
			series.push({
				name: graphLineText,
				data: tmResultsValues
			})

			if (comparisonExist) {
				series.push({
					name: '',
					data: [
						{
							x: comparisonPoint[0]?.days || null,
							y: comparisonPoint[1]?.quantity || null
						}
					]
				})
			}
		}
		if (!isAmOriginalMaterial) {
			componentChartData.tooltip = {
				shared: false,
				intersect: true,
				custom: function ({ series, dataPointIndex, seriesIndex }: any) {
					const crossDataPoint =
						comparisonExist && !isStandard ? series[2][dataPointIndex] : null
					const amPoint = series[0][dataPointIndex]
					const amDayPoint = amResultsKeys[dataPointIndex]
					let tmPoint = null
					let tmDayPoint = null

					if (!isStandard) {
						tmPoint = series[1][dataPointIndex]
						tmDayPoint = tmResultsKeys[dataPointIndex]
					}

					const equal = amDayPoint === tmDayPoint

					const amHtml =
						(amPoint &&
							`<div><div class="am"/></div>${getString(
								'PART_ANALYSIS_LEAD_TIME_GRAPHIC_TEXT'
							).format(amPoint, amDayPoint)}</div>`) ||
						''
					const crossHtml =
						crossDataPoint &&
						`<div class="custom-tooltip-lead flex start">
          ${comparisonPoint
						?.map(elem => {
							return `<div><div class="${
								elem.method === 'am' ? 'am' : 'default'
							}"/></div>${getString(
								'PART_ANALYSIS_LEAD_TIME_GRAPHIC_TEXT'
							).format(elem?.quantity, elem?.days)}</div>`
						})
						.join('')}</div>`

					const tmHtml =
						(tmPoint &&
							`<div><div class="default"/></div>${getString(
								'PART_ANALYSIS_LEAD_TIME_GRAPHIC_TEXT'
							).format(tmPoint, tmDayPoint)}</div>`) ||
						''

					if (equal)
						return `
         <div class="custom-tooltip-lead flex">
           ${amHtml}
           ${tmHtml}
         </div>
          `
					return `
          <div class="custom-tooltip-lead">
            ${
							seriesIndex === 1
								? tmHtml
								: seriesIndex === 0
								? amHtml
								: crossHtml
						}
          </div>
        `
				}
			}
		}

		if (isAmOriginalMaterial) {
			componentChartData.tooltip = {
				shared: false,
				intersect: true,
				custom: function ({ series, dataPointIndex, seriesIndex }: any) {
					const crossDataPoint =
						comparisonExist && !isStandard ? series[2][dataPointIndex] : null
					const amPoint = series[0][dataPointIndex]
					const amDayPoint = amResultsKeys[dataPointIndex]

					const amHtml =
						(amPoint &&
							`<div><div class="am"/></div>${getString(
								'PART_ANALYSIS_LEAD_TIME_GRAPHIC_TEXT'
							).format(amPoint, amDayPoint)}</div>`) ||
						''
					const crossHtml =
						crossDataPoint &&
						`<div class="custom-tooltip-lead flex start">
          ${comparisonPoint
						?.map(elem => {
							return `<div><div class="${
								elem.method === 'am' ? 'am' : 'default'
							}"/></div>${getString(
								'PART_ANALYSIS_LEAD_TIME_GRAPHIC_TEXT'
							).format(elem?.quantity, elem?.days)}</div>`
						})
						.join('')}</div>`

					return `
          <div class="custom-tooltip-lead">
            ${seriesIndex === 0 ? amHtml : crossHtml}
          </div>
        `
				}
			}
		}

		componentChartData.legend = {
			show: true,
			showForSingleSeries: true,
			onItemClick: {
				toggleDataSeries: false
			},
			markers: {
				width: 0,
				height: 0
			},
			labels: {
				colors: ['#fff'],
				useSeriesColors: false
			},
			inverseOrder: true,
			formatter: function (seriesName: any, opts: any) {
				return `<div class="seriesName-${
					opts.seriesIndex === 1
						? 'mold'
						: opts.seriesIndex === 0
						? 'am'
						: 'none'
				}">${seriesName}</div>`
			}
		}

		return { componentChartData, series, firstParts, allParts }
	}

	setChartDataLabels = (
		solutionQuantity: number,
		quantitySubmited: number = 0
	): number[] => {
		let actualQuantity = quantitySubmited || solutionQuantity
		const chartDataLabels = [
			actualQuantity * 0.5,
			actualQuantity * 0.6,
			actualQuantity * 0.7,
			actualQuantity * 0.8,
			actualQuantity * 0.9,
			actualQuantity,
			actualQuantity * 1.1,
			actualQuantity * 1.2,
			actualQuantity * 1.3,
			actualQuantity * 1.4,
			actualQuantity * 1.5
		]
		return chartDataLabels
	}

	moldCostCalculation = (part: Part, count: number): number => {
		const maintenacePresentage =
			(part.moldCost * part.moldMaintenanceCost) / 100
		return (
			part.moldCost +
			maintenacePresentage +
			part.DFMCosts +
			count * part.moldPartCost
		)
	}
	getLeadTimeData = (
		leadData: any,
		tmResults: ManufacturingData[],
		amResults: ManufacturingData[],
		comparisonPoint: IComparisonPoint[],
		formParameters: IFormParameters,
		unlockLeadTimeGraph: boolean | null,
		quantity: number,
		manufacturingMethod: string,
		partStandardCost: boolean,
		configuration: any,
		isAmOriginalMaterial: boolean
	) => {
		let updatedLeadData = leadData

		let tmResultsArray = tmResults
		let amResultsArray = amResults
		let comparisonPointArray = comparisonPoint
		const isStandard =
			checkOnStandard(manufacturingMethod) ||
			(!checkOnStandard(manufacturingMethod) && partStandardCost)

		if (
			getErrorMessage(
				unlockLeadTimeGraph,
				tmResults,
				amResults,
				quantity,
				isStandard
			)
		) {
			tmResultsArray = defaultTM
			amResultsArray = defaultAM
			comparisonPointArray = []
		}

		updatedLeadData.actualResult =
			(tmResults && tmResults[tmResults.length - 1]?.quantity) ||
			(amResults && amResults[amResults.length - 1]?.quantity)
		updatedLeadData.formParameters = formParameters

		updatedLeadData.chartData = this.drawChart(
			tmResultsArray,
			amResultsArray,
			comparisonPointArray,
			manufacturingMethod,
			isStandard,
			configuration,
			isAmOriginalMaterial
		)

		return updatedLeadData
	}
	getLeadDataWithSolution = (
		solutionId: number,
		manufacturingMethod: string,
		configuration: any,
		quantity: number,
		isAmOriginalMaterial: boolean
	): LeadDataWithSolution => {
		let leadData: LeadDataWithSolution = new LeadDataWithSolution()

		if (solutionId > 0) {
			if (configuration?.leadTimeResults) {
				const { tmResults, amResults, comparisonPoint, formParameters } =
					configuration?.leadTimeResults

				leadData = this.getLeadTimeData(
					leadData,
					tmResults,
					amResults,
					comparisonPoint,
					formParameters,
					configuration?.unlockLeadTimeGraph,
					quantity,
					manufacturingMethod,
					configuration?.part?.standardCost && !configuration.standardCost,
					configuration,
					isAmOriginalMaterial
				)
			}
		}

		return leadData
	}
	getLeadData = (
		leadTimeResults: ILeadTimeResults,
		manufacturingMethod: string,
		unlockLeadTimeGraph: boolean | null,
		quantity: number,
		partStandardCost: boolean,
		configuration: any,
		isAmOriginalMaterial: boolean
	): LeadData => {
		let leadData: LeadData = new LeadData()

		const { tmResults, amResults, formParameters, comparisonPoint } =
			leadTimeResults

		return this.getLeadTimeData(
			leadData,
			tmResults,
			amResults,
			comparisonPoint,
			formParameters,
			unlockLeadTimeGraph,
			quantity,
			manufacturingMethod,
			partStandardCost,
			configuration,
			isAmOriginalMaterial
		)
	}
}

export const validateForm = (...validationValue: any) => {
	const value = validationValue[0] // value from form
	const name = validationValue[3] // name from form

	let error = null

	forEach(checkErrorValues, (item: any) => {
		if (name === item.name) {
			const valueIsEmpty = !value && value !== 0
			const stringIsEmpty =
				item.checkIfEmpty && isString(value) && !value?.trim()
			if (valueIsEmpty || stringIsEmpty) error = REQUIRED

			if (value && item.checkIfMinMax) {
				const checkOnlyMin = item?.min && value < item?.min
				const checkAndEqualMin = !item?.min && value <= item?.min
				if (value > item?.max || checkAndEqualMin || checkOnlyMin) {
					error = item.notInclude
						? getString('NUMBER_VALIDATION_REQUIRED_NOT_INCLUDING').format(
								item?.min,
								item?.max
						  )
						: getString('NUMBER_VALIDATION_REQUIRED').format(
								item?.min,
								item?.max
						  )
				}
			}
		}
	})

	return error
}

export const getErrorMessage = (
	unlockLeadTimeGraph: boolean | null,
	tmResults: ManufacturingData[],
	amResults: ManufacturingData[],
	quantity: number,
	isStandard: boolean
) => {
	const MAX_SHIPMENT = 45
	const MAX_SHIPMENT_DAYS = 365

	let textMessage: string | null = null

	if (unlockLeadTimeGraph) return textMessage

	const checkMaxResults =
		tmResults?.length >= MAX_SHIPMENT || amResults?.length >= MAX_SHIPMENT

	const checkEmpty = !isStandard && (isEmpty(tmResults) || isEmpty(amResults)) // check on empty only for non StandardCost

	if (
		(tmResults && tmResults[0]?.days > MAX_SHIPMENT_DAYS) ||
		(amResults && amResults[amResults.length - 1]?.days > MAX_SHIPMENT_DAYS)
	) {
		textMessage = getString(
			'UNAVAILABLE_LEAD_TIME_ANALYSIS_GRAPH_GIVEN_INFO_DAYS'
		)
	}
	if (checkMaxResults || checkEmpty) {
		textMessage = getString(
			'UNAVAILABLE_LEAD_TIME_ANALYSIS_GRAPH_GIVEN_INFO'
		).format(MAX_SHIPMENT)
	}

	return textMessage
}

export const checkOnStandard = (manufacturingMethod: string) => {
	return (
		ImanufacturingTypes.standardCost === manufacturingMethod ||
		manufacturingMethodTypes.standardCost === manufacturingMethod
	)
}

export const getMethodName = (method: string) => {
	let methodText

	switch (method) {
		case manufacturingMethodTypes.mold:
		case ImanufacturingTypes.mold:
			methodText = getString('PART_FINANCIAL_ANALYSIS_INJECTION_MOLDING')
			break
		case ImanufacturingTypes.cast:
		case manufacturingMethodTypes.cast:
			methodText = getString('CASTING')
			break
		case ImanufacturingTypes.investmentCast:
		case manufacturingMethodTypes.investmentCast:
			methodText = getString('INVESTMENT_CASTING')
			break
		case ImanufacturingTypes.standardCost:
		case manufacturingMethodTypes.standardCost:
			methodText = getString('STANDARD_COST')
			break
		case ImanufacturingTypes.cnc:
		case manufacturingMethodTypes.cnc:
			methodText = getString('CNC')
			break
		default:
			methodText = method
			break
	}

	return methodText
}

export const getAllDataForTMResults = (
	amResults: ManufacturingData[],
	tmResults: ManufacturingData[]
) => {
	const tmResultsValues = makeDataSeries(tmResults) || []

	const lastTmPoint =
		tmResultsValues.length > 0
			? tmResultsValues[tmResultsValues.length - 1]
			: null
	const allTmDays = (lastTmPoint && lastTmPoint?.x) || null
	const firstTmDay = tmResultsValues[0]?.x || null
	const firstTmPart = tmResultsValues[0]?.y || null

	return {
		firstTmPart,
		allTmDays,
		firstTmDay
	}
}
