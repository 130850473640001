import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import React, { FC, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { withStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles.js'
import Close from '@material-ui/icons/Close'

import * as styles from '../customizeStyles.css.js'
import {
	COST_AND_LEAD_BUTTON_ACTIONS,
	MATERIAL_COST_ACTION,
	MATERIAL_COST_DELETE_ITEM,
	MATERIAL_COST_KG_PRICE,
	MATERIAL_COST_MATERIAL,
	MATERIAL_COST_PRICE
} from '../../../../Services/Strings.js'
import { getString } from '../../../../Services/Strings/StringService'
import {
	Button,
	CustomInput,
	Danger,
	Table
} from '../../../Components/thirdParty/CreativeTim/components/index'
import { getEditableRowActionButtons } from '../CustomizeCostAndLeadSettings/CostAndLeadService'
import { ValidationStatus } from '../CustomizeCostAndLeadSettings/CustomizePrinterProps/CustomizePrinterCostsTypes'
import { IMaterialCost } from './CustomizeMaterialCostTypes.js'
import {
	deleteMaterialCostItem,
	onMaterialCostActionClicked,
	onMaterialCostChange,
	setupMaterialCostsTable
} from './MaterialCostActions'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	UnitsConversionService,
	UnitSystem
} from 'Services/UnitsConversionService'

type IProps = {
	classes: ClassNameMap<string>
}

const CustomizeMaterialCostTbl: FC<IProps> = ({ classes }) => {
	const {
		materialCosts,
		editableMaterialCostsList,
		deletableMaterialCostsList
	} = useSelector((state: RootStateOrAny) => state.MaterialCostReducer)
	const { printers: inHousePrinters, userUnitSystem } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const materialCostPriceTableHeader = useMemo(
		() =>
			customizeUnitSystem
				? MATERIAL_COST_PRICE[userUnitSystem as UnitSystem]
				: MATERIAL_COST_KG_PRICE,
		[customizeUnitSystem, userUnitSystem]
	)
	const userConversionService = new UnitsConversionService(
		UnitSystem.metric,
		customizeUnitSystem ? userUnitSystem : UnitSystem.metric
	)

	const dispatch = useDispatch()

	const renderDeleteButton = (itemToDelete: IMaterialCost) => {
		return (
			<Button
				title={MATERIAL_COST_DELETE_ITEM}
				onClick={() =>
					dispatch(
						deleteMaterialCostItem(itemToDelete, adminSelectedOrganizationId)
					)
				}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
			>
				<Close className={classes.icon} />
			</Button>
		)
	}

	const renderEditableValue = (
		materialCost: IMaterialCost,
		editableMaterialCostsList: IMaterialCost[],
		onMaterialCostChange: (
			materialId: string,
			value: string,
			editableMaterialCostsList: IMaterialCost[]
		) => void
	) => {
		const value = materialCost.materialPrice
		const inputStyle = !value ? styles.defaultValue : {}
		const label = value
			? `$${userConversionService.convertPricePerWeightUnit(+value)}`
			: materialCost.valueLabel
		const isError = materialCost.validationStatus === ValidationStatus.error
		const isSuccess = materialCost.validationStatus === ValidationStatus.success
		if (materialCost.edit) {
			return (
				<div className="custom-input">
					<CustomInput
						id="outlined-dense"
						success={isSuccess}
						error={isError}
						formControlProps={{
							fullWidth: true,
							style: { margin: 'unset' }
						}}
						inputProps={{
							label: label,
							autoFocus: true,
							value: value,
							onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
								dispatch(
									onMaterialCostChange(
										materialCost.materialId,
										e.target.value,
										editableMaterialCostsList
									)
								)
							},
							type: 'number'
						}}
					/>
					{isError && (
						<Danger
							style={{
								textAlign: 'left',
								position: 'absolute',
								bottom: '-7px',
								fontSize: '12px'
							}}
						>
							{materialCost.validationMessage}
						</Danger>
					)}
				</div>
			)
		}
		return <span style={inputStyle}>{label}</span>
	}

	const renderEditButton = (
		materialCost: IMaterialCost,
		editablePrinterCostsList: IMaterialCost[],
		onMaterialCostActionClicked: (
			actionButton: keyof typeof COST_AND_LEAD_BUTTON_ACTIONS,
			materialCost: IMaterialCost,
			editablePrinterCostsList: IMaterialCost[],
			organizationId?: number,
			userUnitSystem?: UnitSystem
		) => void,
		adminSelectedOrganizationId?: number
	) => {
		const actionButtons = getEditableRowActionButtons(
			materialCost.edit,
			materialCost.materialPrice
		)

		return actionButtons.map(actionButton => {
			const disabled =
				actionButton === COST_AND_LEAD_BUTTON_ACTIONS.APPLY &&
				materialCost.validationStatus === ValidationStatus.error
			return (
				<TransparentButton
					key={actionButton}
					className="custom-input--action"
					disabled={disabled}
					onClick={() =>
						dispatch(
							onMaterialCostActionClicked(
								actionButton,
								materialCost,
								editablePrinterCostsList,
								adminSelectedOrganizationId,
								customizeUnitSystem ? userUnitSystem : UnitSystem.metric
							)
						)
					}
				>
					{actionButton}
				</TransparentButton>
			)
		})
	}

	const renderMaterialCostTblData = () => {
		const editableMaterialCostsData = editableMaterialCostsList.map(
			(item: IMaterialCost) => [
				item.materialName,
				item.printerCompany,
				item.printerName,
				renderEditableValue(
					item,
					editableMaterialCostsList,
					onMaterialCostChange
				),
				renderEditButton(
					item,
					editableMaterialCostsList,
					onMaterialCostActionClicked,
					adminSelectedOrganizationId
				)
			]
		)

		const deletableMaterialCostsData = deletableMaterialCostsList.map(
			(item: IMaterialCost) => [
				item.materialName,
				item.printerCompany,
				item.printerName,
				`$${userConversionService.convertPricePerWeightUnit(
					+item.materialPrice
				)}`,
				renderDeleteButton(item)
			]
		)

		return [...editableMaterialCostsData, ...deletableMaterialCostsData]
	}

	useEffect(() => {
		dispatch(setupMaterialCostsTable(materialCosts, inHousePrinters))
	}, [materialCosts, inHousePrinters, dispatch])

	if (editableMaterialCostsList.length || deletableMaterialCostsList.length) {
		return (
			<Table
				editTableStyle={'materialCostTableCell'}
				tableHead={[
					MATERIAL_COST_MATERIAL,
					getString('PROFILE_FORM_COMPANY_LABEL'),
					getString('PRINTER'),
					materialCostPriceTableHeader,
					MATERIAL_COST_ACTION
				]}
				tableData={renderMaterialCostTblData()}
				className="materials-costs-table"
			/>
		)
	}
	return <div />
}

export default withStyles(extendedTablesStyle as any)(CustomizeMaterialCostTbl)
