import { isBoolean, omit } from 'lodash'
import { difference } from 'lodash'
import moment from 'moment'

import { DataTableService } from '../../../Components/DataTable/DataTableService'
import { DataTableFieldType } from '../../../Components/DataTable/IDataTableField'
import { adminUserTableParam, InfoData } from './constants'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IUserRole } from 'Services/models/IUserRole'
import { getString } from 'Services/Strings/StringService'

const dataTableService = new DataTableService()

export const checkForUserInfoChanges = (
	userSelected: Record<string, unknown>,
	newData: Record<string, unknown>
) => {
	let fieldsForUpdate = {}
	Object.keys(newData).forEach(fieldName => {
		const initialValue = isBoolean(newData[fieldName])
			? !!userSelected[fieldName]
			: userSelected[fieldName]
		const fieldsValuesEqual = newData[fieldName] !== initialValue
		const idField = fieldName === adminUserTableParam.id
		if (fieldsValuesEqual || idField) {
			const newValue = newData[fieldName]
			fieldsForUpdate = {
				[fieldName]: newValue,
				...fieldsForUpdate
			}
		}
	})
	return fieldsForUpdate
}

export const findRoleName = (usersRoles: IUserRole[], roleNumber: number) => {
	const role = usersRoles.find(
		(usersRole: IUserRole) => usersRole.id === roleNumber
	)
	return role?.name
}

export const validations = {
	minSelectedValues: (value: number[]) => value && !value?.length
}

export const prepareInfoForRequest = (
	newUpdatedInfo: Record<string, unknown>
) => {
	const roleEditingOn = Feature.isFeatureOn(FeatureComponentId.ROLE_EDITING)
	let preparedInfoForRequest
	if (!roleEditingOn && newUpdatedInfo?.roles) {
		const { roles, ...info } = newUpdatedInfo
		preparedInfoForRequest = { info }
	} else if (!newUpdatedInfo?.roles) {
		preparedInfoForRequest = { info: newUpdatedInfo }
	} else {
		const roles: Array<number> | string = newUpdatedInfo.roles as
			| Array<number>
			| string
		const preparedRoles = typeof roles !== 'string' ? roles.toString() : roles

		newUpdatedInfo = omit(newUpdatedInfo, adminUserTableParam.roles)
		preparedInfoForRequest = { info: newUpdatedInfo, roles: preparedRoles }
	}

	if (preparedInfoForRequest.info.expireDate) {
		preparedInfoForRequest.info.expireDate = moment(
			preparedInfoForRequest.info.expireDate as Date
		).endOf('day')
	}

	return preparedInfoForRequest
}

export const makeRolesAsArray = (
	roles: string | number | Array<number> = ''
) => {
	return typeof roles === 'number'
		? [roles]
		: typeof roles === 'string'
		? roles.split(',').map((role: string) => +role)
		: roles
}

export const updateUserInfo = (
	user: Record<string, any>,
	userInfoUpdated: Record<string, any>
) => {
	const updateTrial = {
		trial:
			userInfoUpdated.trial !== null && userInfoUpdated.trial !== undefined
				? userInfoUpdated.trial
				: null
	}
	const updateVerifiedStatus = {
		verified: userInfoUpdated.verified || false
	}
	const updateAmountOfRemainParts = {
		[adminUserTableParam.remainingParts]:
			userInfoUpdated.amount_of_remain_parts || null
	}
	const updatePartsBundle = {
		[adminUserTableParam.partsCredit]: userInfoUpdated.parts_bundle || null
	}
	const updateSubscriptionExpire_date = {
		[adminUserTableParam.expireDate]:
			userInfoUpdated.subscription_expire_date || null
	}
	const updatedFieldsValues = [
		updateTrial,
		updateAmountOfRemainParts,
		updatePartsBundle,
		updateSubscriptionExpire_date,
		updateVerifiedStatus
	]
	for (let fieldObj of updatedFieldsValues) {
		const [key, value] = Object.entries(fieldObj)[0]
		user[key] = value
	}
	return user
}

export const createUserDataInfo = (
	infoType: InfoData,
	userData: Record<string, any>,
	disableLicenseInfo?: boolean,
	disableCompanyInfo?: boolean,
) => {
	return generateTableFieldsRows(
		tableRowData(infoType, userData, disableLicenseInfo, disableCompanyInfo)
	)
}

export const createUserDataPrinters = (userPrinters: Record<any, string>[]) => {
	const printers = userPrinters?.map(up => up.dataValues || up)
	return generateTableFieldsRows(tableRowData(InfoData.printersInfo, printers))
}
export const createUserDataMaterials = (user: Record<string, any>) => {
	return generateTableFieldsRows(tableRowData(InfoData.materialsInfo, user))
}
export const generateTableHeaders = (headersText: Array<any>) => {
	return (
		headersText.length &&
		headersText.map(text => {
			return dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: text
			})
		})
	)
}
export const generateTableFieldsRows = (
	rowsValues: Array<Record<string, any>>
) => {
	if (!rowsValues || !rowsValues.length) {
		return []
	}

	return rowsValues.map(texts => {
		return [
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: texts.headerFieldText,
				className: 'admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: texts.valueFieldText,
				className: `admin--projects--project-info--field1`
			})
		]
	})
}

export const getFieldData = (
	dataObj: any,
	fieldName: any,
	typeText = false
) => {
	if (!dataObj[fieldName] && typeof dataObj[fieldName] !== 'number') {
		return typeText ? '-' : '0'
	}
	if (
		dataObj[fieldName] !== undefined &&
		dataObj[fieldName] !== null &&
		typeof dataObj[fieldName] === 'number'
	) {
		return dataObj[fieldName].toString() || '-'
	}
	if (dataObj[fieldName] && typeof dataObj[fieldName] === 'string') {
		return dataObj[fieldName] || '-'
	}
}

export const tableRowData = (
	type: string,
	userData: any,
	disableLicenseInfo?: boolean,
	disableCompanyInfo?: boolean
) => {
	switch (type) {
		case InfoData.projectAndParts:
			return [
				{
					headerFieldText: getString('ADMIN_ADDED_CUSTOMIZATION'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.addedCustomization
					)
				},
				{
					headerFieldText: getString('ADMIN_TOTAL_PARTS_SCANNED'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.totalPartsScanned
					)
				},
				{
					headerFieldText: getString('ADMIN_TOTAL_PARTS_CHARGED'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.totalPartsCharged
					)
				},
				{
					headerFieldText: getString('ADMIN_TOTAL_DELETED_PARTS'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.totalPartsDeleted
					)
				},
				{
					headerFieldText: getString('ADMIN_TOTAL_PROJECTS_UPLOADED'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.totalProjectUploaded
					)
				}
			]
		case InfoData.personalInfo:
			return [
				{
					headerFieldText: getString('ADMIN_USER_NAME'),
					valueFieldText: userData.name
				},
				...(!disableCompanyInfo
					? [
							{
								headerFieldText: getString('REGISTER_COMPANY_PLACEHOLDER'),
								valueFieldText: getFieldData(
									userData,
									adminUserTableParam.company,
									true
								)
							}
					  ]
					: []),
				{
					headerFieldText: getString('ADMIN_USER_DATE_CREATED'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.created,
						true
					)
				},
				{
					headerFieldText: getString('ADMIN_USER_EMAIL'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.email,
						true
					)
				},
				{
					headerFieldText: getString('CITY'),
					valueFieldText: getFieldData(userData, adminUserTableParam.city, true)
				},
				{
					headerFieldText: getString('STATE'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.state,
						true
					)
				},
				{
					headerFieldText: getString('COUNTRY'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.country,
						true
					)
				},
				{
					headerFieldText: getString('SEND_TO_SERVICE_BUREAU_ZIP'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.zipCode,
						true
					)
				},
				{
					headerFieldText: getString('ADMIN_USER_LAT'),
					valueFieldText: getFieldData(userData, adminUserTableParam.lat)
				},
				{
					headerFieldText: getString('ADMIN_USER_LONG'),
					valueFieldText: getFieldData(userData, adminUserTableParam.long)
				},
				{
					headerFieldText: getString('ADMIN_USER_CURRENCY'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.currency,
						true
					)
				},
				{
					headerFieldText: getString('ADMIN_USER_LOCALE'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.locale,
						true
					)
				}
			]
		case InfoData.subscriptionInfo:
			return [
				...(!disableLicenseInfo
					? [
							{
								headerFieldText: getString('ADMIN_USER_ON_TRIAL'),
								valueFieldText: userData[adminUserTableParam.trial]
									? getString('YES')
									: getString('NO')
							},
							{
								headerFieldText: getString('ADMIN_USER_IS_VERIFIED'),
								valueFieldText: userData[adminUserTableParam.verified]
									? getString('YES')
									: getString('NO')
							},
							{
								headerFieldText: getString('ADMIN_USER_SUBSCRIPTION_DATE'),
								valueFieldText: getFieldData(
									userData,
									adminUserTableParam.subscriptionDate
								)
							},
							{
								headerFieldText: getString('ADMIN_USER_EXPIRE_DATE'),
								valueFieldText: new Date(
									userData[adminUserTableParam.expireDate]
								).toDateString()
							},
							{
								headerFieldText: getString('PARTS_CREDIT'),
								valueFieldText: getFieldData(
									userData,
									adminUserTableParam.partsCredit
								)
							}
					  ]
					: []),
				{
					headerFieldText: getString('ADMIN_USER_UPLOAD_FILES_ALL_TIME'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.allTimeUploaded
					)
				},
				{
					headerFieldText: getString('ADMIN_USER_MESH_HEALED_DOWNLOADED'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.partDownloadCount
					)
				},
				...(!disableLicenseInfo
					? [
							{
								headerFieldText: getString('REMAINING_PARTS_CREDIT'),
								valueFieldText: getFieldData(
									userData,
									adminUserTableParam.remainingParts
								)
							}
					  ]
					: []),
				{
					headerFieldText: getString('ADMIN_USER_LOCKED_PARTS'),
					valueFieldText: getFieldData(
						userData,
						adminUserTableParam.totalPartsLocked
					)
				}
			]
		case InfoData.materialsInfo:
			return userData.map((data: any) => {
				return {
					headerFieldText: getFieldData(data, adminUserTableParam.id),
					valueFieldText: getFieldData(data, adminUserTableParam.name)
				}
			})

		case InfoData.printersInfo:
			return userData.map((data: any) => {
				return {
					headerFieldText: getFieldData(data, adminUserTableParam.id),
					valueFieldText: getFieldData(data, adminUserTableParam.name)
				}
			})

		default:
			return []
	}
}

export const verifyUserOnAdminRoles = (userRoles: Array<number>) => {
	const adminRoles = [UserRole.USER_MANAGER, UserRole.ORGANIZATIONS_ADMIN]

	const roleDifference = difference(adminRoles, userRoles)

	return roleDifference.length === 0
}
