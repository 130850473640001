import React, { FC, memo, useEffect } from 'react'
import {
	connect,
	DispatchProp,
	RootStateOrAny,
	useDispatch,
	useSelector
} from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'
import { change, reset } from 'redux-form'

import * as SolutionAnalysisActions from '../SolutionAnalysisActions'
import { SolutionAnalysisInitialState } from '../SolutionAnalysisReducer'
import ConfigurationOrganizationSelector from './ConfigurationOrganizationSelector'
import SolutionConfigureButtons from './SolutionConfigureButtons'
import SolutionConfigureContentSelection from './SolutionConfigureContentSelection'
import { IProps, IReduxProps, IReduxStore } from './SolutionConfigureInterface'
import SolutionConfigureMaterials from './SolutionConfigureMaterials'
import {
	changeAdvancedFilters,
	setupAdvancedFilters
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import {
	keepConfigurationUniqueFilters,
	prepareFiltersToSend
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import Devider from 'Scenes/Components/Devider/Devider'
import Flexbox from 'Scenes/Components/FlexBox'
import { onInHousePrintersAlertOpen } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import Loader from 'Scenes/Loader/Loader'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { IMaterial } from 'Services/models/IMaterial'
import { IPrinter } from 'Services/models/IPrintersTypes'
import { IUserFilterNames } from 'Services/models/IUserFilter'

import 'assets/css/slider.scss'

import '../SolutionAnalysis.scss'

const DeviderTSX: any = Devider

const SolutionConfigure: FC<IProps & IReduxProps> = ({
	configuration,
	priorities,
	partId,
	projectId,
	loadingCalculation,
	enableSolutionButtons,
	solutionName,
	configurationId,
	solutionPriorities,
	showSolutionPriorities,
	batchSize,
	chosenMaterial,
	chosenSubCategory,
	chooseAmSpecifyMaterial,
	materials,
	printerMaterials,
	initialMaterial,
	initialSubCategory,
	chosenMaterialType,
	chosenAmMaterialType,
	chosenMaterialCategory,
	chosenAmMaterialCategory,
	materialTypesList,
	amMaterialTypesList,
	categoriesList,
	amCategoriesList,
	materialsList,
	amSubCategories,
	showMaterialsFilters,
	showAmMaterialsFilters,
	allOptionalPostProcessesData,
	solution,
	originalPostProcessValues,
	PostProcessOnCounter,
	tempSolutionPostProcessToggles,
	optionalPostProcessAvailability,
	optionalPostProcessesBreakDown,
	toleranceIncluded,
	clusterId,
	userPrinters,
	project,
	part,
	printingOrientationCalc,
	printingOrientationVector,
	configurationChanged,
	trayOrientationCustom,
	trayOrientationLoader,
	userCurrencySign,
	simpleConfiguration,
	printersCompaniesList,
	simpleConfigurationMaterialsList,
	simpleConfigurationPrinters,
	simpleConfigurationSelectorCompanyValue,
	simpleConfigurationSelectorPrinterValue,
	simpleConfigurationSelectorMaterialValue,
	simpleInhouseConfiguration,
	printersFullData,
	disableAdvancedButtons,
	printCostQuantity,
	tourConfigurationId,
	freezeConfiguration,
	configurationPrintIssues,
	initialToleranceValue,
	materialCategories,
	disableConfiguration,
	partSolution,
	disableCalculateButton,
	printingTechnologies,
	customConfiguration,
	printerMaterialID,
	isAmOriginalMaterial,
	calculateWithOrganizationId,
	organizationSettingsLoading,
	showOrganizationTMMaterialWarning,
	organizationDetails,
	isAdminByRole,
	userDetails,
	isInitialLoadingAnyConfiguration,
	userUnitSystem,
	change,
	onConfigureBackClick,
	onPrioritizeFieldChange,
	onCalculateClick,
	onFilterFeatureClick,
	onPrioritiesFeatureClick,
	onPriorityRemove,
	onBatchSizeChange,
	onResetClick,
	onChangeMaterial,
	onChangeMaterialCategory,
	onChangeMaterialType,
	onChangeAmMaterial,
	onChangeAmSubCategory,
	onChangeAmMaterialCategory,
	onChangeAmMaterialType,
	originalFilterButtonClicked,
	amMaterialFilterButtonClicked,
	onPostProcessesClick,
	onChangeOrientationClick,
	onInhouseTogleClick,
	onCompanyChangeSimpleConfiguration,
	onPrinterChangeSimpleConfiguration,
	onMaterialChangeSimpleConfiguration,
	onSimpleInhouseConfigurationChange,
	onTolerancesClick,
	onInHousePrintersAlertOpen,
	isResetDisableButton,
	manufacturingMethod
}) => {
	const dispatch = useDispatch()
	const showDefaultMaterialFilters = Feature.isFeatureOn(
		FeatureComponentId.DEFAULT_MATERIAL_FILTERS
	)
	const { filters, isError } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[configuration.id] ||
			new AdvancedSettingsInitialState()
	)
	const isSimpleDisableButton =
		simpleConfiguration && !simpleConfigurationSelectorMaterialValue
	configurationId = configuration.id
	const disableInHouseFilter =
		!isAdminByRole &&
		!userPrinters.length &&
		!!(
			(calculateWithOrganizationId &&
				organizationDetails.id !== calculateWithOrganizationId) ||
			!userDetails.organization_owner
		)

	useEffect(() => {
		dispatch(
			setupAdvancedFilters(
				configuration.id,
				false,
				configuration.filters,
				undefined,
				disableInHouseFilter,
				calculateWithOrganizationId
			)
		)
	}, [configuration.filters, calculateWithOrganizationId])

	useEffect(() => {
		if (showDefaultMaterialFilters && configurationChanged) {
			dispatch(
				changeAdvancedFilters(
					configuration.id,
					chosenMaterial?.defaultFilters,
					true
				)
			)
		}
	}, [chosenMaterial, configurationChanged])

	const isSolutionPrintable = (configuration: IConfiguration): boolean => {
		const result = configuration.result
		return !(
			result === CADAnalysisResult.notPrintable ||
			configuration.solution === null
		)
	}

	const resetInHouse = () => {
		onSimpleInhouseConfigurationChange(
			configurationId,
			printersFullData,
			userPrinters,
			configuration,
			false
		)
	}

	const resetConfiguration = (resetOrganization = false) => {
		if (simpleConfiguration) {
			resetInHouse()
		}
		dispatch(
			setupAdvancedFilters(
				configuration.id,
				false,
				configuration.filters,
				undefined,
				disableInHouseFilter,
				configuration.organizationId
			)
		)
		if (resetOrganization) {
			dispatch(
				SolutionAnalysisActions.changeOrganizationToConfigure(
					configuration.id,
					configuration.organizationId,
					true
				)
			)
		} else {
			onResetClick(
				configurationId,
				configuration,
				priorities,
				initialMaterial,
				materials,
				originalPostProcessValues,
				solution,
				project,
				materialCategories
			)
		}
	}

	const calculateConfiguration = () => {
		const inHouseOn = filters.find(
			(filter: any) => filter.name === IUserFilterNames.inHousePrinters
		)?.checked
		if (!userPrinters.length && inHouseOn) {
			onInHousePrintersAlertOpen(calculateWithOrganizationId, configurationId)
			return
		}
		const material = simpleConfiguration ? initialMaterial : chosenMaterial
		const subCategoryData = simpleConfiguration
			? initialSubCategory
			: chosenSubCategory
		const preparedFilters = prepareFiltersToSend(filters, userUnitSystem)
		const solutionFilters = keepConfigurationUniqueFilters(
			preparedFilters,
			filters,
			configuration.filters
		)
		if (printerMaterialID) {
			solutionFilters.printerMaterialID = printerMaterialID
		} else if (isAmOriginalMaterial && subCategoryData) {
			if (chooseAmSpecifyMaterial) {
				solutionFilters.printerMaterialID =
					subCategoryData?.subCategory?.id ||
					subCategoryData?.id ||
					subCategoryData?.chosenMaterialId
			}
			if (!chooseAmSpecifyMaterial && solutionFilters.printerMaterialID) {
				delete solutionFilters.printerMaterialID
			}
		}
		onCalculateClick(
			configurationId,
			solution,
			partId,
			projectId,
			Object.fromEntries(solutionPriorities),
			solutionFilters,
			null,
			solutionName,
			configuration.solution && configuration.solution.id,
			material,
			subCategoryData,
			batchSize,
			tempSolutionPostProcessToggles,
			allOptionalPostProcessesData,
			optionalPostProcessesBreakDown,
			optionalPostProcessAvailability,
			toleranceIncluded,
			clusterId,
			false,
			part,
			userCurrencySign,
			printCostQuantity,
			isAmOriginalMaterial,
			printingOrientationVector,
			printingOrientationCalc,
			false,
			simpleConfiguration,
			simpleConfigurationSelectorPrinterValue,
			simpleConfigurationSelectorMaterialValue,
			simpleInhouseConfiguration,
			undefined,
			tourConfigurationId,
			undefined,
			calculateWithOrganizationId
		)
	}

	return (
		<Flexbox
			flexDirection="column"
			justifyContent="space-between"
			alignItems="flex-end"
			className="solution-analysis-object-content-configure"
		>
			<div className="solution-analysis-object-content-configure--top--wrapper">
				<ConfigurationOrganizationSelector
					configurationOrganizationId={calculateWithOrganizationId}
					configurationId={configurationId}
					disabled={organizationSettingsLoading || loadingCalculation}
					configurationResultType={configuration.resultType}
				/>
				<Loader
					load={organizationSettingsLoading}
					wrapperClassName="solution-analysis-object-content-configure--loader"
				/>
				<Flexbox
					alignItems="flex-start"
					justifyContent="space-between"
					width="100%"
					className="solution-analysis-object-content-configure--top"
				>
					<SolutionConfigureMaterials
						configurationChanged={configurationChanged}
						onPostProcessesClick={onPostProcessesClick}
						onChangeOrientationClick={onChangeOrientationClick}
						onBatchSizeChange={onBatchSizeChange}
						configurationId={configurationId}
						numberOfPostProcessesOn={PostProcessOnCounter}
						batchSize={batchSize}
						initialMaterial={chosenMaterial}
						onChangeMaterial={onChangeMaterial}
						onChangeMaterialCategory={onChangeMaterialCategory}
						onChangeMaterialType={onChangeMaterialType}
						onChangeAmMaterial={onChangeAmMaterial}
						onChangeAmSubCategory={onChangeAmSubCategory}
						onChangeAmMaterialCategory={onChangeAmMaterialCategory}
						onChangeAmMaterialType={onChangeAmMaterialType}
						chosenMaterialType={chosenMaterialType}
						chosenAmMaterialType={chosenAmMaterialType}
						chosenMaterialCategory={chosenMaterialCategory}
						chosenAmMaterialCategory={chosenAmMaterialCategory}
						chosenMaterial={chosenMaterial}
						chosenSubCategory={chosenSubCategory}
						materialTypesList={materialTypesList}
						amMaterialTypesList={amMaterialTypesList}
						categoriesList={categoriesList}
						amCategoriesList={amCategoriesList}
						materialsList={materialsList}
						amSubCategories={amSubCategories}
						showMaterialsFilters={showMaterialsFilters}
						showAmMaterialsFilters={showAmMaterialsFilters}
						originalFilterButtonClicked={originalFilterButtonClicked}
						amMaterialFilterButtonClicked={amMaterialFilterButtonClicked}
						partSolution={partSolution}
						trayOrientationCustom={trayOrientationCustom}
						trayOrientationLoader={trayOrientationLoader}
						updatedPostProcess={tempSolutionPostProcessToggles}
						printable={isSolutionPrintable(configuration)}
						simpleConfiguration={simpleConfiguration}
						disableAdvancedButtons={disableAdvancedButtons}
						cluster={clusterId}
						part={part}
						configuration={configuration}
						configurationPrintIssues={configurationPrintIssues}
						initialToleranceValue={initialToleranceValue}
						lowestToleranceValue={partSolution?.lowestToleranceValue}
						disableConfiguration={disableConfiguration}
						onTolerancesClick={() => onTolerancesClick(configurationId)}
						manufacturingMethod={manufacturingMethod}
						isSpecifiedQuantity={configuration.isSpecifiedQuantity}
						isAmOriginalMaterial={isAmOriginalMaterial}
						printersFullData={printersFullData}
						printerMaterials={printerMaterials}
						showOrganizationTMMaterialWarning={
							showOrganizationTMMaterialWarning
						}
					/>
					<SolutionConfigureContentSelection
						configuration={configuration}
						priorities={priorities}
						printingTechnologies={printingTechnologies}
						solutionPriorities={solutionPriorities}
						showSolutionPriorities={showSolutionPriorities}
						userPrinters={userPrinters}
						simpleConfiguration={simpleConfiguration}
						printersCompaniesList={printersCompaniesList}
						simpleConfigurationMaterialsList={simpleConfigurationMaterialsList}
						simpleConfigurationPrinters={simpleConfigurationPrinters}
						onPrioritizeFieldChange={onPrioritizeFieldChange}
						onFilterFeatureClick={onFilterFeatureClick}
						onPrioritiesFeatureClick={onPrioritiesFeatureClick}
						onPriorityRemove={onPriorityRemove}
						onInhouseTogleClick={onInhouseTogleClick}
						onCompanyChangeSimpleConfiguration={(value: string) => {
							onCompanyChangeSimpleConfiguration(configurationId, value, change)
						}}
						onPrinterChangeSimpleConfiguration={(value: IPrinter) => {
							onPrinterChangeSimpleConfiguration(configurationId, value, change)
						}}
						onMaterialChangeSimpleConfiguration={(value: IMaterial) => {
							onMaterialChangeSimpleConfiguration(
								configurationId,
								value,
								change
							)
						}}
						onSimpleInhouseConfigurationChange={(value: boolean) => {
							onSimpleInhouseConfigurationChange(
								configurationId,
								printersFullData,
								userPrinters,
								configuration,
								value
							)
						}}
						simpleConfigurationSelectorCompanyValue={
							simpleConfigurationSelectorCompanyValue
						}
						simpleConfigurationSelectorPrinterValue={
							simpleConfigurationSelectorPrinterValue
						}
						simpleConfigurationSelectorMaterialValue={
							simpleConfigurationSelectorMaterialValue
						}
						simpleInhouseConfiguration={simpleInhouseConfiguration}
						customConfiguration={customConfiguration}
					/>
				</Flexbox>
			</div>
			<Flexbox className="solution-analysis-object-content-configure-widget--footer">
				<DeviderTSX
					size="100%"
					className="solution-analysis-object-content-configure-widget-devider"
				/>
				<SolutionConfigureButtons
					freezeConfiguration={freezeConfiguration}
					configurationId={configurationId}
					onConfigureBackClick={() => {
						onConfigureBackClick(configurationId)
						resetConfiguration(
							calculateWithOrganizationId !== configuration.organizationId
						)
					}}
					onResetClick={() => resetConfiguration()}
					onCalculateClick={() => calculateConfiguration()}
					loadingCalculation={loadingCalculation}
					enableSolutionButtons={enableSolutionButtons}
					disableConfiguration={disableConfiguration}
					disableCalculateButton={
						isInitialLoadingAnyConfiguration ||
						disableCalculateButton ||
						isSimpleDisableButton ||
						isError
					}
					disableResetAllButton={isResetDisableButton}
				/>
			</Flexbox>
		</Flexbox>
	)
}

const mapStateToProps = (state: RootStateOrAny, ownProps: IProps) => {
	const {
		user: {
			filters,
			priorities,
			materials,
			allOptionalPostProcessesData,
			optionalPostProcessAvailability,
			optionalPostProcessesBreakDown,
			printers,
			userCurrencySign,
			printersFullData,
			materialCategories,
			printingTechnologies,
			printerMaterials,
			organizationDetails,
			isAdminByRole,
			userDetails,
			userUnitSystem
		},
		SolutionAnalysisReducer,
		MaterialSelectorReducer: { material },
		MainPartAnalysisReducer: {
			partId,
			projectId,
			project,
			clusterId,
			solutions,
			currentStepTargetId,
			tourConfigurationId,
			partPrintIssues,
			initialToleranceValue,
			allConfigurationsOrganizationSettings,
			isInitialLoadingAnyConfiguration
		}
	}: IReduxStore = state
	const toleranceIncluded = project && project.toleranceIncluded
	const { calculateWithOrganizationId }: SolutionAnalysisInitialState =
		SolutionAnalysisReducer.states[ownProps.configuration.id] ||
		new SolutionAnalysisInitialState()

	const configurationOrganizationId =
		calculateWithOrganizationId || ownProps.configuration.organizationId
	const configurationOrganizationSettings =
		allConfigurationsOrganizationSettings[configurationOrganizationId] || {}

	return {
		...(SolutionAnalysisReducer.states[ownProps.configuration.id] ||
			new SolutionAnalysisInitialState()),
		allOptionalPostProcessesData:
			configurationOrganizationSettings.allOptionalPostProcessesData ||
			allOptionalPostProcessesData,
		filters,
		priorities,
		partId,
		projectId,
		partSolution: solutions.find(
			s =>
				s.id ===
				(ownProps.configuration.solution && ownProps.configuration.solution.id)
		),
		configurationPrintIssues: partPrintIssues.filter(
			partPrintIssue =>
				partPrintIssue.configurationId === ownProps.configuration.id ||
				!partPrintIssue.configurationId
		),
		material,
		materials: configurationOrganizationSettings.materials || materials,
		optionalPostProcessAvailability:
			configurationOrganizationSettings.optionalPostProcessAvailability ||
			optionalPostProcessAvailability,
		optionalPostProcessesBreakDown,
		toleranceIncluded,
		clusterId,
		userPrinters: configurationOrganizationSettings.printers || printers,
		project,
		userCurrencySign,
		printersFullData:
			configurationOrganizationSettings.printersFullData || printersFullData,
		currentStepTargetId,
		tourConfigurationId,
		initialToleranceValue,
		materialCategories,
		printingTechnologies,
		printerMaterials:
			configurationOrganizationSettings.printerMaterials || printerMaterials,
		organizationDetails,
		isAdminByRole,
		userDetails,
		isInitialLoadingAnyConfiguration,
		userUnitSystem
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{ ...SolutionAnalysisActions, reset, change, onInHousePrintersAlertOpen },
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(SolutionConfigure))
