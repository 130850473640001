import { FC, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import * as CustomizeActions from '../CustomizeActions'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import AddMaterialNamesMapping from './AddMaterialNamesMapping'
import MaterialNamesMappingList from './MaterialNamesMappingList'
import {
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Material } from 'Services/models/IMaterial'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './CustomizeMaterialNamesMapping.scss'

const { defaultMaterial } = getTheme()

interface IProps {
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
	defaultUploadProjectMaterialType: any
	materialCategories: any
	materialTypes: string[]
	materials: Material[]
	disableMaterialNameMappingSaveAll: boolean
	doMaterialNameMappingRefresh: boolean
	isLoadingMaterial?: boolean
	adminSelectedOrganizationId?: number
	fetchUserMaterialNameMapping: Function
	onCreateUserMaterialNameMapping: (
		userMaterialNameMapping: IUserMaterialNamesMapping,
		onChangeSelector: (property: string, value: any) => void,
		organizationId?: number
	) => void
	onUpdateMaterialNameMapping: (
		userMaterialNamesMapping: IUserMaterialNamesMapping[],
		organizationId?: number
	) => void
	onRemoveUserMaterialNameMapping: (
		id?: string,
		fromAlert?: boolean,
		active?: boolean,
		organizationId?: number
	) => void
	onRemoveAllUserMaterialNameMapping: (
		active: boolean,
		organizationId?: number
	) => void
	onChangeSelectedMaterial: Function
}

interface IReduxStore {
	user: any
	CustomizeReducer: any
}

const CustomizeMaterialNamesMapping: FC<IProps> = ({
	userMaterialNamesMapping,
	disableMaterialNameMappingSaveAll,
	isLoadingMaterial,
	materialCategories,
	materialTypes,
	materials,
	adminSelectedOrganizationId,
	doMaterialNameMappingRefresh,
	fetchUserMaterialNameMapping,
	onCreateUserMaterialNameMapping,
	onUpdateMaterialNameMapping,
	onRemoveUserMaterialNameMapping,
	onRemoveAllUserMaterialNameMapping,
	onChangeSelectedMaterial
}) => {
	const history = useHistory()

	useEffect(() => {
		fetchUserMaterialNameMapping(adminSelectedOrganizationId)
	}, [doMaterialNameMappingRefresh, adminSelectedOrganizationId])

	const renderFormHeader = () => {
		return (
			<div className="customize-settings--header">
				<CastorFormHeader
					goBack={() => history.push(USER_HOME_ROUTE + CUSTOMIZE_ROUTE)}
					explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel />
			</div>
		)
	}

	const setMaterialNamesMappingContent = () => {
		return (
			<>
				<CastorFormHeader
					explanationHeader={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE'
					)}
					explanationArray={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				{/* Default materials mappings list */}
				<MaterialNamesMappingList
					title={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_DEFAULT_TITLE')}
					explanation={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_DEFAULT_EXPLANATION'
					)}
					disableMaterialNameMappingSaveAll={disableMaterialNameMappingSaveAll}
					userMaterialNamesMapping={userMaterialNamesMapping.filter(
						materialName => materialName.defaultFormatType
					)}
					isLoadingMaterial={isLoadingMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					isActive={true}
					allowRemove={false}
					updateMaterialNameMapping={(
						userMaterialNamesMapping: IUserMaterialNamesMapping[]
					) =>
						onUpdateMaterialNameMapping(
							userMaterialNamesMapping,
							adminSelectedOrganizationId
						)
					}
					removeUserMaterialNameMapping={(
						id?: string,
						fromAlert?: boolean,
						active?: boolean
					) =>
						onRemoveUserMaterialNameMapping(
							id,
							fromAlert,
							active,
							adminSelectedOrganizationId
						)
					}
					changeSelectedMaterial={onChangeSelectedMaterial}
				/>
				<AddMaterialNamesMapping
					defaultMaterial={defaultMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					disabled={false}
					updateSelectedMaterial={(
						userMaterialNameMapping: IUserMaterialNamesMapping,
						onChangeSelector: (property: string, value: any) => void
					) =>
						onCreateUserMaterialNameMapping(
							userMaterialNameMapping,
							onChangeSelector,
							adminSelectedOrganizationId
						)
					}
				/>
				<MaterialNamesMappingList
					title={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_ADD_BY_LIST_TITLE'
					)}
					explanation={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_ADD_MATERIAL_EXPLANATION'
					)}
					disableMaterialNameMappingSaveAll={disableMaterialNameMappingSaveAll}
					userMaterialNamesMapping={userMaterialNamesMapping.filter(
						materialName => !materialName.defaultFormatType
					)}
					isLoadingMaterial={isLoadingMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					updateMaterialNameMapping={(
						userMaterialNamesMapping: IUserMaterialNamesMapping[]
					) =>
						onUpdateMaterialNameMapping(
							userMaterialNamesMapping,
							adminSelectedOrganizationId
						)
					}
					removeUserMaterialNameMapping={(
						id?: string,
						fromAlert?: boolean,
						active?: boolean
					) =>
						onRemoveUserMaterialNameMapping(
							id,
							fromAlert,
							active,
							adminSelectedOrganizationId
						)
					}
					removeAllUserMaterialNameMapping={() =>
						onRemoveAllUserMaterialNameMapping(
							false,
							adminSelectedOrganizationId
						)
					}
					changeSelectedMaterial={onChangeSelectedMaterial}
				/>
				<MaterialNamesMappingList
					title={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EDIT_MATERIALS_TITLE'
					)}
					explanation={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EDIT_MATERIALS_EXPLANATION'
					)}
					disableMaterialNameMappingSaveAll={disableMaterialNameMappingSaveAll}
					userMaterialNamesMapping={userMaterialNamesMapping.filter(
						materialName => !materialName.defaultFormatType
					)}
					isLoadingMaterial={isLoadingMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					isActive={true}
					updateMaterialNameMapping={(
						userMaterialNamesMapping: IUserMaterialNamesMapping[]
					) =>
						onUpdateMaterialNameMapping(
							userMaterialNamesMapping,
							adminSelectedOrganizationId
						)
					}
					removeUserMaterialNameMapping={(
						id?: string,
						fromAlert?: boolean,
						active?: boolean
					) =>
						onRemoveUserMaterialNameMapping(
							id,
							fromAlert,
							active,
							adminSelectedOrganizationId
						)
					}
					removeAllUserMaterialNameMapping={() =>
						onRemoveAllUserMaterialNameMapping(
							true,
							adminSelectedOrganizationId
						)
					}
				/>
			</>
		)
	}

	return (
		<>
			<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
				<CastorForm
					formHeader={renderFormHeader()}
					formTitle={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE')}
					content={setMaterialNamesMappingContent()}
				/>
			</NavBarAndMaterial>
		</>
	)
}

const mapStateToProps = (state: IReduxStore) => {
	const {
		user: {
			userMaterialNamesMapping,
			isLoadingMaterial,
			disableMaterialNameMappingSaveAll,
			materialTypes,
			materials,
			materialCategories,
			doMaterialNameMappingRefresh
		},
		CustomizeReducer: { adminSelectedOrganizationId }
	} = state
	return {
		disableMaterialNameMappingSaveAll,
		userMaterialNamesMapping,
		isLoadingMaterial,
		materialTypes,
		materials,
		materialCategories,
		doMaterialNameMappingRefresh,
		adminSelectedOrganizationId
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizeMaterialNamesMapping)
