import { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from '@material-ui/core'
import classNames from 'classnames'

import OrganizationPanel from '../OrganizationPanel/OrganizationPanel'
import CustomizePartFilteringByGeometry from './CustomizePartFilteringByGeometry'
import CustomizePartFilteringByName from './CustomizePartFilteringByName'
import { getDisplayGeometryAnalysis } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import {
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import {
	CUSTOMIZE_OFF_THE_SHELF_TITLE,
	NAV_TITLE_CUSTOMIZE_USER
} from 'Services/Strings'
import { getThemeString } from 'themes/getThemeString'

import './index.scss'

const CustomizePartFiltering: FC = () => {
	const history = useHistory()

	const { userCustomizationSettings } = useSelector(
		(state: RootStateOrAny) => state?.user
	)

	const geometryAnalysisPart = getDisplayGeometryAnalysis(
		userCustomizationSettings
	)
	const renderFormHeader = () => {
		return (
			<div className="customize-settings--header">
				<CastorFormHeader
					goBack={() => history.push(USER_HOME_ROUTE + CUSTOMIZE_ROUTE)}
					explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel />
			</div>
		)
	}
	return (
		<NavBarAndMaterial title={NAV_TITLE_CUSTOMIZE_USER}>
			<CastorForm
				formHeader={renderFormHeader()}
				formTitle={CUSTOMIZE_OFF_THE_SHELF_TITLE}
				content={
					<div className="customize-part-filtering">
						<CustomizePartFilteringByName
							geometryAnalysisPart={geometryAnalysisPart}
						/>
						<CustomizePartFilteringByGeometry
							geometryAnalysisPart={geometryAnalysisPart}
						/>
					</div>
				}
			/>
		</NavBarAndMaterial>
	)
}

export default memo(CustomizePartFiltering)
