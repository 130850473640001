import { FC, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import _ from 'lodash'

import * as AdminPartsActions from '../../AdminParts/AdminPartsActions'
import { ICluster, IClusterPart } from '../../../../../Services/models/ICluster'
import {
	ADMIN_PARTS_EXTERNAL_ID,
	ADMIN_PARTS_PART_ID,
	ADMIN_PARTS_TRANSFORMATION_MATRIX_HASH
} from '../../../../../Services/Strings'
import CastorCheckbox from '../../../../Components/CastorCheckbox'
import CastorForm from '../../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../../Components/NavBarAndMaterial'
import { Table } from '../../../../Components/thirdParty/CreativeTim/components'
import Loader from 'Scenes/Loader/Loader'

import './AdminClusters.scss'

const LabelFormControl: any = FormControlLabel

interface IProps extends RouteComponentProps<MatchParams> {
	parts: IClusterPart[]
	clusters: ICluster[]
	hasMorePartsToFetch: boolean
	clusterId: string
	updateClusterLoading: boolean
	setupAdminPartsPage: Function
	onUpdateClusterClick: Function
	fetchMoreClusterParts: Function
}

interface MatchParams {
	projectId: string
	clusterId: string
}

interface IReduxStore {
	AdminPartsReducer: any
}

const clusterPartsTableHead = ['image', 'Information']

const ClusterParts: FC<IProps> = ({
	parts,
	clusters,
	match: {
		params: { projectId }
	},
	updateClusterLoading,
	hasMorePartsToFetch,
	clusterId,
	fetchMoreClusterParts,
	setupAdminPartsPage,
	onUpdateClusterClick
}: IProps) => {
	useEffect(() => {
		if (!clusters?.length) {
			setupAdminPartsPage(projectId)
		} else if (!parts?.length) {
			fetchMoreClusterParts(clusterId, 0)
		}
	}, [clusters, parts])

	const [partsToRemove, setPartsToRemove] = useState<any>({})
	const [partsToRemoveArray, setPartsToRemoveArray] = useState<any[]>([])
	const [allowUpdate, setAllowUpdate] = useState<boolean>(false)

	const onPartRemoveChecked = (transformationMatrixHash: string) => {
		const newPartsToRemove = {
			...partsToRemove,
			[transformationMatrixHash]: !partsToRemove[transformationMatrixHash]
		}
		const newPartsToRemoveArray = _.keys(_.pickBy(newPartsToRemove))
		setPartsToRemove(newPartsToRemove)
		setPartsToRemoveArray(newPartsToRemoveArray)
		setAllowUpdate(
			!!(
				newPartsToRemoveArray.length &&
				newPartsToRemoveArray.length < parts.length - 1
			)
		)
	}

	const renderPartInfo = (part: IClusterPart) => (
		<div key={part.transformationMatrixHash}>
			<h4> {part.partNumber} </h4>
			<div>
				{ADMIN_PARTS_PART_ID}: {part.id}
			</div>
			<div>
				{ADMIN_PARTS_EXTERNAL_ID}: {part.externalId}
			</div>
			<div>
				{ADMIN_PARTS_TRANSFORMATION_MATRIX_HASH}:{' '}
				{part.transformationMatrixHash}
			</div>
		</div>
	)

	const renderPartImage = (part: IClusterPart) => (
		<img
			src={part.imageURL}
			className="admin-clusters--image"
			key={part.transformationMatrixHash}
		/>
	)

	const renderPartSelect = (part: IClusterPart) => (
		<LabelFormControl
			control={
				<CastorCheckbox
					checked={partsToRemove[part.transformationMatrixHash]}
				/>
			}
			onChange={() => onPartRemoveChecked(part.transformationMatrixHash)}
			key={part.transformationMatrixHash}
		/>
	)

	const renderClusterPartsTableData = () => {
		return parts.map(part => {
			return [renderPartImage(part), renderPartInfo(part)]
		})
	}

	const renderClusterPartsTable = () => {
		if (!parts) {
			return <div></div>
		}
		return (
			<div>
				<Table
					tableHead={clusterPartsTableHead}
					tableData={renderClusterPartsTableData()}
				/>
			</div>
		)
	}

	return (
		<NavBarAndMaterial title={'Unification Parts'}>
			<InfiniteScroll
				dataLength={parts?.length || 0}
				next={() => fetchMoreClusterParts(clusterId, parts?.length)}
				hasMore={hasMorePartsToFetch}
				loader={
					<Loader
						load={true}
						size={50}
						showFlex={false}
						wrapperClassName="admin-clusters--loader"
					/>
				}
				scrollableTarget="main-content-scroller"
			>
				<CastorForm
					formTitle={'Parts'}
					formSubTitle="List of all unification parts"
					content={renderClusterPartsTable()}
					style={{ maxWidth: 'unset' }}
				/>
			</InfiniteScroll>
		</NavBarAndMaterial>
	)
}

const mapStateToProps = (
	{ AdminPartsReducer: { clusters, updateClusterLoading } }: IReduxStore,
	{
		match: {
			params: { clusterId }
		}
	}: IProps
) => {
	const cluster: ICluster = clusters.find(
		(cluster: ICluster) => cluster.id === parseInt(clusterId)
	)
	const parts = cluster?.parts
	return {
		parts,
		clusters,
		hasMorePartsToFetch: cluster?.compositionSetParts?.length !== parts?.length,
		updateClusterLoading,
		clusterId
	}
}
const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminPartsActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ClusterParts)
