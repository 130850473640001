import React, { FC, memo } from 'react'

import { Close } from '@material-ui/icons'

import {
	Button,
	ItemGrid,
	Table
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import { CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../../CustomizeCO2Calculation.scss'

interface IProps {
	typicalKwhList: Record<string, any>
	deleteCycleTypicalKwh: Function
}

const CustomizeCO2CycleTable: FC<IProps> = ({
	typicalKwhList,
	deleteCycleTypicalKwh
}) => {
	const onDeleteMaterial = (itemToDelete: any) => {
		deleteCycleTypicalKwh(itemToDelete)
	}
	const renderDeleteButton = (itemToDelete: number) => {
		return (
			<Button
				title={CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM}
				onClick={() => onDeleteMaterial(itemToDelete)}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
			>
				<Close className="custom-calculation-co2--table--icon" />
			</Button>
		)
	}

	const renderCo2MaterialData = () => {
		return typicalKwhList.map(
			(cycle: {
				cycleName: string
				typicalPowerConsumption: number
				id: number
			}) => [
				cycle.cycleName,
				cycle.typicalPowerConsumption,
				renderDeleteButton(cycle.id)
			]
		)
	}

	return (
		<div className="customize__table">
			<ItemGrid>
				<Table
					editTableStyle={'co2CalculationTable'}
					tableHead={[
						getString('MATERIAL_COST_TECHNOLOGY'),
						getString('TYPICAL_POWER_CONSUMPTION'),
						getString('ACTIONS')
					]}
					tableData={renderCo2MaterialData()}
				/>
			</ItemGrid>
		</div>
	)
}

export default memo(CustomizeCO2CycleTable)
