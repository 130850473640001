import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CustomizeAnalysisCostCheckbox from './CustomizeAnalysisCostCheckbox'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import { updateCostAnalysisData } from 'Scenes/Home/Customize/CustomizeAnalysisCost/CustomizeAnalysisCostActions'
import { ICostAnalysisParam } from 'Services/models/ICostAnalysisParameter'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const CustomizeAnalysisCostTable: FC = () => {
	const dispatch = useDispatch()
	const {
		isCostAnalysisLoading,
		costAnalysisData,
		calculatedCost,
		disableUpdateButton,
		isError
	} = useSelector((state: RootStateOrAny) => state.CustomizeAnalysisCostReducer)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	return (
		<div id="block-div" className="cost-analysis-parameters__section">
			<div className="cost-analysis-parameters__section-top">
				<div className="name">{getString('COSTING_PARAMETERS_COST_ITEM')}</div>
				<div className="cost">
					{getString('COSTING_PARAMETERS_COST_EXAMPLE')}
				</div>
				<div className="explanation">
					{getString('COSTING_PARAMETERS_COST_EXPLANATION')}
				</div>
			</div>
			<div>
				{costAnalysisData
					.filter((el: ICostAnalysisParam) => el.visible)
					.map((el: ICostAnalysisParam) => {
						return <CustomizeAnalysisCostCheckbox {...el} />
					})}
			</div>
			<div className={'sticky-bottom'}>
				<div className={'cost-analysis-parameters__section-bottom'}>
					<div className="name">
						{getString('COSTING_PARAMETERS_TOTAL_PRODUCTION')}
					</div>
					<div className="cost">${calculatedCost?.toFixed(2)}</div>
					<div className="explanation">
						{getString('COSTING_PARAMETERS_TOTAL_PRODUCTION_EXPLANATION')}
					</div>
				</div>
				{isError ? (
					<p className="error">{getString('COST_ANALYSIS_UPDATE_ERROR')}</p>
				) : (
					''
				)}
				<div className="cost-analysis-parameters__button">
					<ButtonWithLoader
						size={20}
						style={{ padding: '5px 20px', width: 'unset' }}
						top={20}
						disabled={isError || disableUpdateButton}
						loading={isCostAnalysisLoading}
						onClick={() =>
							dispatch(
								updateCostAnalysisData(
									costAnalysisData,
									adminSelectedOrganizationId
								)
							)
						}
						className="add-button"
						qaDataElementName="data-qa-add-printer"
					>
						{getString('SUBMIT')}
					</ButtonWithLoader>
				</div>
			</div>
		</div>
	)
}
export default memo(CustomizeAnalysisCostTable)
