import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import * as MainPartAnalysisActions from '../../../../../MainPartAnalysisActions'
import * as SolutionAnalysisActions from '../../../../SolutionAnalysisActions'
import { IStateExtractorState } from '../../../../../../../../../Services/HOR/InstanceStateExtractor'
import { ISolutionFea } from '../../../../../../../../../Services/models/ISolutionFea'
import { getString } from '../../../../../../../../../Services/Strings/StringService'
import IconFactory from '../../../../../../../../Components/StarIcon/IconFactory'
import { Danger } from '../../../../../../../../Components/thirdParty/CreativeTim/components'
import { MainPartAnalysisInitialState } from '../../../../../MainPartAnalysisReducer'
import { SolutionAnalysisInitialState } from '../../../../SolutionAnalysisReducer'
import SolutionFeaResults from '../../../../SolutionFea/SolutionFeaResults'
import MechanicalAnalysisButtons from './MechanicalAnalysisButtons'
import {
	analysisTabLSKey,
	immediateFeaLSKey,
	TRUE_STRING,
	viewFeaLSKey
} from 'Services/Constants'
import {
	getStringItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { getTheme } from 'themes/getTheme'

import './MechanicalAnalysisTab.scss'

interface IProps {
	part: Part
	configuration: any
}

interface IReduxProps {
	stepURLExist: boolean
	configurationId: number
	feaNewAnalysisLoad: boolean
	feaOldAnalysisLoad: boolean
	isWeightReductionPart: boolean
	solutionFeaSelectedValues: number[]
	solutionFeaResult: number
	showFeaAnalysisOldAnalysis: boolean
	solutionFea?: ISolutionFea
	onNewFeaClick: (
		...onNewFeaClickParams: Parameters<
			typeof SolutionAnalysisActions.onNewFeaClick
		>
	) => any
	startFEAImmediately: (
		configurationId: number,
		partId: number,
		localStorageKey: string,
		solutionFeaId?: string
	) => void
}

interface IReduxStore {
	SolutionAnalysisReducer: IStateExtractorState
	MainPartAnalysisReducer: MainPartAnalysisInitialState
}

const renderSTLWarning = (part: Part, stepURLExist: boolean) => {
	if (!part?.hasBrepData) {
		return <Danger>{getString('MECHANICAL_ANALYSIS_BREP_EXPLANATION')}</Danger>
	}
	if (!stepURLExist) {
		return <Danger>{getString('MECHANICAL_ANALYSIS_STL_EXPLANATION')}</Danger>
	}
	return <div></div>
}
const renderOrientationWarning = (stepURLExist: boolean) => {
	if (stepURLExist) {
		return (
			<div className="mechanical-analysis--main--explanation--orientation-warning">
				<IconFactory
					iconName={'attentionSign'}
					className="mechanical-analysis--main--explanation--orientation-warning--icon"
				/>
				<div className="mechanical-analysis--main--explanation--orientation-warning--text">
					{getString('MECHANICAL_ANALYSIS_ORIENTATION_EXPLANATION')}
				</div>
			</div>
		)
	}
	return <div></div>
}

const renderMainContent = (
	solutionFeaSelectedValues: number[],
	stepURLExist: boolean,
	solutionFeaResult: number,
	showFeaAnalysisOldAnalysis: boolean,
	part: Part
) => {
	if (!solutionFeaSelectedValues?.length || !showFeaAnalysisOldAnalysis) {
		return (
			<div className="mechanical-analysis--main--explanation">
				{getString('MECHANICAL_ANALYSIS_SHORT_EXPLANATION')}
				{renderSTLWarning(part, stepURLExist)}
				{renderOrientationWarning(stepURLExist)}
			</div>
		)
	}
	return (
		<SolutionFeaResults
			userFeaResults={solutionFeaSelectedValues}
			feaResult={solutionFeaResult}
		/>
	)
}

const { tabOrder } = getTheme()

const MechanicalAnalysisTab: FC<IProps & IReduxProps> = ({
	stepURLExist,
	configurationId,
	part,
	feaNewAnalysisLoad,
	feaOldAnalysisLoad,
	solutionFeaSelectedValues,
	solutionFeaResult,
	showFeaAnalysisOldAnalysis,
	solutionFea,
	isWeightReductionPart,
	onNewFeaClick,
	startFEAImmediately
}) => {
	const openFEAInNewTab = Feature.isFeatureOn(FeatureComponentId.FEA_IN_NEW_TAB)
	const localStorageImmediateFeaKey = immediateFeaLSKey({
		partId: part.id,
		configurationId
	})
	const localStorageViewFeaKey = viewFeaLSKey({
		partId: part.id,
		configurationId
	})

	useEffect(() => {
		const shouldStartFEAImmediately = getStringItemFromLocalStorage(
			localStorageImmediateFeaKey
		)
		const shouldViewFEAImmediately = getStringItemFromLocalStorage(
			localStorageViewFeaKey
		)
		if (openFEAInNewTab) {
			if (shouldStartFEAImmediately) {
				startFEAImmediately(
					configurationId,
					part.id,
					localStorageImmediateFeaKey
				)
			} else if (shouldViewFEAImmediately) {
				startFEAImmediately(
					configurationId,
					part.id,
					localStorageViewFeaKey,
					solutionFea?.id
				)
			}
		}
	}, [
		configurationId,
		localStorageImmediateFeaKey,
		localStorageViewFeaKey,
		openFEAInNewTab,
		part.id,
		solutionFea?.id,
		startFEAImmediately
	])

	const handleNewFeaClick = (solutionFeaId?: string) => {
		if (openFEAInNewTab) {
			const currentUrl = window.location.href
			const localStorageTabKey = analysisTabLSKey({
				partId: part.id,
				configurationId
			})
			setStringItemToLocalStorage(localStorageTabKey, tabOrder.feaAnalysis)
			if (solutionFeaId) {
				setStringItemToLocalStorage(localStorageViewFeaKey, TRUE_STRING)
			} else {
				setStringItemToLocalStorage(localStorageImmediateFeaKey, TRUE_STRING)
			}
			window.open(currentUrl, '_blank')
		} else {
			onNewFeaClick(configurationId, part.id, solutionFeaId)
		}
	}

	return (
		<div className="mechanical-analysis--wrapper">
			<div className="mechanical-analysis">
				<div className="mechanical-analysis--main">
					{renderMainContent(
						solutionFeaSelectedValues,
						stepURLExist,
						solutionFeaResult,
						showFeaAnalysisOldAnalysis,
						part
					)}
				</div>
			</div>

			<div className="mechanical-analysis--actions">
				<MechanicalAnalysisButtons
					isWeightReductionPart={isWeightReductionPart}
					disableButtons={!stepURLExist}
					feaNewAnalysisLoad={feaNewAnalysisLoad}
					showFeaAnalysisOldAnalysis={showFeaAnalysisOldAnalysis}
					feaOldAnalysisLoad={feaOldAnalysisLoad}
					onViewAnalysisClick={() => handleNewFeaClick(solutionFea?.id)}
					onNewFeaClick={() => handleNewFeaClick()}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = (
	{
		SolutionAnalysisReducer,
		MainPartAnalysisReducer: { stepURLExist, partFeas, isWeightReductionPart }
	}: IReduxStore,
	ownProps: IProps
) => {
	const {
		configurationId,
		feaNewAnalysisLoad,
		feaOldAnalysisLoad,
		solutionFeaSelectedValues,
		solutionFeaResult,
		showFeaAnalysisOldAnalysis
	}: SolutionAnalysisInitialState =
		SolutionAnalysisReducer.states[ownProps.configuration.id] ||
		new SolutionAnalysisInitialState()
	return {
		stepURLExist,
		configurationId,
		feaNewAnalysisLoad,
		feaOldAnalysisLoad,
		solutionFeaSelectedValues,
		solutionFeaResult,
		showFeaAnalysisOldAnalysis,
		isWeightReductionPart,
		solutionFea: partFeas?.find(
			pF =>
				(pF.configuration?.id || pF.configurationId) === configurationId
		)
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{ ...SolutionAnalysisActions, ...MainPartAnalysisActions },
		dispatch
	)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(MechanicalAnalysisTab)
)
