import React, { FC, useCallback, useEffect } from 'react'
import Moment from 'react-moment'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import Settings from '@material-ui/icons/Settings'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'

import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import {
	adminProjectsRoute,
	adminUserInfoRoute,
	adminUsersRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_USERS_ADMIN_USER,
	ADMIN_USERS_CHARGED,
	ADMIN_USERS_CUSTOMIZE_USER,
	ADMIN_USERS_CUSTOMIZE_USER_ADDED,
	ADMIN_USERS_ERROR,
	ADMIN_USERS_IS_TRIAL,
	ADMIN_USERS_NO_USERS_FOUND,
	ADMIN_USERS_PAGE_HEADER,
	ADMIN_USERS_SCANNED,
	ADMIN_USERS_SORT_SUBSCRIPTION,
	ADMIN_USERS_SUBSCRIPTION_ENDS,
	ADMIN_USERS_TRIAL_ENDS,
	ADMIN_USERS_VIEW_PROJECTS,
	ADMIN_USERS_VIEW_USER_INFO,
	NO,
	YES
} from '../../../../Services/Strings'
import CastorDatePickers from '../../../Components/CastorDatePickers'
import CastorExcelExport from '../../../Components/CastorExcelExport/CastorExcelExport'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorPagination from '../../../Components/CastorPagination/CastorPagination'
import CastorPopperMenu from '../../../Components/CastorPopperMenu/CastorPopperMenu'
import CastorServerSearchBar from '../../../Components/CastorServerSearchBar/CastorServerSearchBar'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import IconFactory from '../../../Components/StarIcon/IconFactory'
import {
	Button,
	Danger,
	Table
} from '../../../Components/thirdParty/CreativeTim/components'
import CustomizeMenuItems from './AdminUserCustomizeMenu/CustomizeMenuItems'
import CustomizeMenuTarget from './AdminUserCustomizeMenu/CustomizeMenuTarget'
import {
	datePickersToggled,
	downloadReport,
	onAdminUsersUnmounted,
	onCreatedHeadClick,
	onSubscriptionHeadClick,
	onToggleDesktopPrinter,
	onUserInfoClick,
	setSearchPhrase,
	setupAdminUsersPage
} from './AdminUsersActions'
import { adminUserTableParam } from './constants'
import { USERS_ADMIN_ROUTE } from 'Services/Constants/RoutesConstants'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { IOwner } from 'Services/models/IUser'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

import '../adminHome.scss'
import './AdminUsers.scss'

const AdminUsers: FC = () => {
	const dispatch = useDispatch()
	const params: Record<string, any> = useParams()
	const {
		pageNumber,
		limitUsers,
		searchPhrase,
		sortBy,
		sortASC,
		showDatepicker,
		users,
		showExportExcel,
		isAdminUserReportLoading,
		showNoUsersAlert,
		showAdminUsersAlert,
		loading,
		showPagination,
		isLastPage,
		totalUsers
	} = useSelector((state: RootStateOrAny) => state.AdminUsersReducer)
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const { allOrganizations } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const prevSearchPhrase = usePrevious(searchPhrase)
	const prevSortASC = usePrevious(sortASC)
	const customizeUser = Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_USER)
	const subscriptionOff =
		Feature.isFeatureOn(FeatureComponentId.LICENSE_MANAGER) && isOnPrem
	const customizeOrganizations = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
	)

	const setupAdminUsers = useCallback(() => {
		const urlPage = parseInt(params?.page) || 1

		if (searchPhrase !== prevSearchPhrase || sortASC !== prevSortASC) {
			dispatch(
				setupAdminUsersPage(searchPhrase, urlPage, limitUsers, sortBy, sortASC)
			)
		}
	}, [
		dispatch,
		limitUsers,
		params?.page,
		prevSearchPhrase,
		prevSortASC,
		searchPhrase,
		sortASC,
		sortBy
	])

	useEffect(() => {
		setupAdminUsers()
		return () => {
			if (!window.location.pathname.includes(USERS_ADMIN_ROUTE)) {
				dispatch(onAdminUsersUnmounted())
			}
		}
	}, [dispatch, searchPhrase, setupAdminUsers, sortASC])

	const renderDatepickerAlert = () => {
		return (
			<CastorDatePickers
				show={showDatepicker}
				onCancel={() => dispatch(datePickersToggled(false))}
				downloadReport={(startDate: Date, endDate: Date) =>
					dispatch(downloadReport(startDate, endDate))
				}
			/>
		)
	}

	const renderUserProjectsButton = (user: IOwner) => {
		return (
			<div className="admin-user-multiple-line-field">
				<Link to={adminProjectsRoute(user.id)}>
					<Button
						className="link-button"
						size="sm"
						color="primary"
						data-qa={`data-qa-user-${user.name}-view-projects-btn`}
					>
						<IconFactory iconName="search" />
						{ADMIN_USERS_VIEW_PROJECTS}
					</Button>
				</Link>
				<Link
					to={adminUserInfoRoute(user.id)}
					onClick={() => dispatch(onUserInfoClick(user))}
				>
					<Button
						className="link-button"
						size="sm"
						color="primary"
						data-qa={`data-qa-user-${user.name}-view-info-btn`}
					>
						<IconFactory iconName="info" className="info-button" />
						{ADMIN_USERS_VIEW_USER_INFO}
					</Button>
				</Link>
				{customizeUser && (
					<CastorPopperMenu
						menuName={`adminCustomize_${user.id}`}
						targetItem={
							<CustomizeMenuTarget name={ADMIN_USERS_CUSTOMIZE_USER} />
						}
						menuItems={
							<CustomizeMenuItems
								userId={user.id}
								onDesktopPrinterToggle={(e: React.ChangeEvent) =>
									dispatch(
										onToggleDesktopPrinter(e, !!user.desktopPrinter, user.id)
									)
								}
								isDesktopPrinter={!!user.desktopPrinter}
							/>
						}
						qaDataElementName={`data-qa-user-${user.name}-customize`}
					/>
				)}
			</div>
		)
	}

	const renderAdminIcon = (user: IOwner) => {
		if (!user.admin) {
			return <div />
		}
		return (
			<div className="admin-users-icon" title={ADMIN_USERS_ADMIN_USER}>
				{<SupervisorAccount />}
			</div>
		)
	}

	const renderCustomizationIcon = (user: IOwner) => {
		if (!user[adminUserTableParam.addedCustomization as keyof IOwner]) {
			return <div />
		}
		return (
			<div
				className="admin-users-icon"
				title={ADMIN_USERS_CUSTOMIZE_USER_ADDED}
			>
				{<Settings />}
			</div>
		)
	}

	const renderLightUserIcon = (user: IOwner) => {
		if (!user?.light) {
			return <div />
		}
		return (
			<div
				className="admin-users-icon"
				title={getString('ADMIN_USER_LIGHT_USER')}
			>
				<IconFactory iconName="lightUser" width="30px" height="30px" />
			</div>
		)
	}

	const renderUserIcons = (user: IOwner) => {
		return (
			<div className="admin-users-icons">
				{renderAdminIcon(user)}
				{renderCustomizationIcon(user)}
				{renderLightUserIcon(user)}
			</div>
		)
	}

	const renderCreatedField = (createdUser: string) => {
		const userCreatedTime = new Date(createdUser)
		return (
			<div className="admin-user-multiple-line-field">
				{userCreatedTime.toLocaleDateString()}
				<Moment fromNow>{userCreatedTime}</Moment>
			</div>
		)
	}

	const renderSubscriptionField = (user: IOwner) => {
		const userExpireDate = user[
			adminUserTableParam.expireDate as keyof IOwner
		] as string
		const userSubscriptionTime = new Date(userExpireDate)
		return (
			<div className="admin-user-multiple-line-field">
				<div>
					{ADMIN_USERS_IS_TRIAL}:{' '}
					<span className="admin-user-multiple-line-field-bold">
						{user.trial ? YES : NO}
					</span>
				</div>
				{userExpireDate && (
					<div>
						{user.trial
							? ADMIN_USERS_TRIAL_ENDS
							: ADMIN_USERS_SUBSCRIPTION_ENDS}{' '}
						<Moment fromNow className="admin-user-multiple-line-field-bold">
							{userSubscriptionTime}
						</Moment>
					</div>
				)}
			</div>
		)
	}

	const renderTotalPartsField = (user: IOwner) => {
		return (
			<div>
				<div>
					{ADMIN_USERS_SCANNED}:{' '}
					{user[adminUserTableParam.totalPartsScanned as keyof IOwner]}
				</div>
				<div>
					{ADMIN_USERS_CHARGED}:{' '}
					{user[adminUserTableParam.totalPartsCharged as keyof IOwner]}
				</div>
			</div>
		)
	}

	const renderSubscriptionHead = () => {
		return (
			<div
				className={`admin-table-linkable-header ${
					sortBy === 'subscription' ? 'admin-table-linkable-header--sorted' : ''
				}`}
				title={ADMIN_USERS_SORT_SUBSCRIPTION}
				onClick={() => dispatch(onSubscriptionHeadClick())}
			>
				<IconFactory
					iconName="sortArrows"
					className="admin-table-linkable-header--icon"
				/>
				{getString('SUBSCRIPTION')}
			</div>
		)
	}

	const renderCreateHead = () => {
		return (
			<div
				className={
					'admin-table-linkable-header admin-table-linkable-header--sorted'
				}
				title={getString('ADMIN_USERS_SORT_CREATED')}
				onClick={() => dispatch(onCreatedHeadClick())}
			>
				<IconFactory
					iconName="sortArrows"
					className="admin-table-linkable-header--icon"
				/>
				{getString('CREATED')}
			</div>
		)
	}

	const renderCompanyName = (user: IOwner) => {
		return customizeOrganizations
			? renderOrganizationValue(allOrganizations, user.organizationId)
			: user.company
	}

	const renderUsersTableData = () => {
		return users.map((user: IOwner) => {
			return [
				user.name,
				user.email,
				renderCompanyName(user),
				renderTotalPartsField(user),
				renderCreatedField(user.createdAt),
				!subscriptionOff && renderSubscriptionField(user),
				renderUserIcons(user),
				renderUserProjectsButton(user)
			]
		})
	}

	const renderUsersTableHead = () => {
		const companyHeader = customizeOrganizations
			? getString('REGISTER_FORM_ORGANIZATION_LABEL')
			: getString('ADMIN_PROJECTS_COMPANY')
		return [
			'name',
			'email',
			companyHeader,
			'total parts',
			renderCreateHead(),
			!isOnPrem && renderSubscriptionHead(),
			'Other',
			'Actions'
		]
	}

	const changeSearchPhrase = (searchPhrase: string) => {
		dispatch(setSearchPhrase(searchPhrase))
	}

	const renderSearchBar = () => (
		<div className="admin-data-table-search-field">
			<CastorServerSearchBar
				placeholder="Search by name/ ID"
				searchPhrase={searchPhrase}
				setSearchPhrase={changeSearchPhrase}
			/>
		</div>
	)

	const exportData = () => {
		return (
			<div className="admin-data-table-export-data">
				<CastorExcelExport
					disabled={!showExportExcel}
					isLoading={isAdminUserReportLoading}
					onClick={() => dispatch(datePickersToggled(true))}
				/>
			</div>
		)
	}

	const renderUsersTable = () => {
		const renderTable = () => {
			if (showNoUsersAlert) {
				return (
					<div className="align-start">
						<Danger>{ADMIN_USERS_NO_USERS_FOUND}</Danger>{' '}
					</div>
				)
			}
			if (showAdminUsersAlert) {
				return (
					<div className="align-start">
						<Danger>{ADMIN_USERS_ERROR}</Danger>
					</div>
				)
			}
			return (
				<>
					<Table
						tableHead={usersTableHead}
						tableData={renderUsersTableData()}
						loading={loading}
						addMinHeight
					/>
					<CastorPagination
						showPagination={showPagination}
						pageNumber={pageNumber}
						isLastPage={isLastPage}
						linkTo={(pageNumber: number) => {
							return adminUsersRoute(pageNumber)
						}}
						total={totalUsers}
						limit={limitUsers}
					/>
				</>
			)
		}
		const usersTableHead = renderUsersTableHead()
		return (
			<div className="admin-home-page">
				<div className="admin-data-table-add-item-button">
					{renderSearchBar()}
					{exportData()}
				</div>
				{renderTable()}
			</div>
		)
	}

	return (
		<NavBarAndMaterial title={ADMIN_USERS_PAGE_HEADER}>
			{renderDatepickerAlert()}
			<CastorForm
				formTitle={ADMIN_USERS_PAGE_HEADER}
				formSubTitle="List of all users"
				content={renderUsersTable()}
				style={{ maxWidth: 'unset' }}
			/>
		</NavBarAndMaterial>
	)
}

export default AdminUsers
