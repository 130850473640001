export const SKIP_KEY_CODE = 89

export const STEP_ID = {
	START_SCREEN: 1,
	COST_MODEL: 2,
	COST_FUNCTION_EDITOR: 22,
	CUSTOMIZE_COST_PARAMS: 21,
	IN_HOUSE_PRINTERS: 3,
	PRINTERS_AND_MATERIALS_COSTS: 4,
	COST_AND_SUPPLY_CHAIN: 5,
	MATERIAL_NAMES: 6,
	SOLUTION_FILTERING: 7,
	ADVANCED: 8,
	SUSTAINABILITY: 81,
	TM_MATERIAL: 82,
	TM_FORM: 821,
	AM_MATERIAL: 83,
	AM_FORM: 831,
	ADD_PRINTER: 84,
	ADD_PRINTER_FORM: 841,
	DONE_SCREEN: 10
}

export const ONBOARDING = 'onboarding'

export const gaAlertKeys = [
	'holesFiltering',
	'threadDetection',
	'wallThicknessAlert',
	'toleranceAlert',
	'internalCavitiesAlert',
	'cadAlert',
	'millingMetalSupportAlert',
	'heatDeformationAlert',
	'orientationStabilityAlert'
]

export const START_SCREEN_IDS = {
	GREETING: 1,
	DESCRIPTION: 2,
	START_BUTTON: 3,
	ORGANIZATION_SELECTOR: 4
}

export const COST_MODEL_IDS = {
	COST_PARAMS_LINK: 1,
	COST_FUNCTION_LINK: 2
}

export const COST_FUNCTION_IDS = {
	STEPS: 1
}

export const COST_PARAMS_IDS = {
	COST_PARAMS: 1,
	INCLUDE_ALL_CHECKBOX: 2
}

export const IN_HOUSE_COMPONENTS_IDS = {
	PRINTERS_SELECTOR: 1,
	PRINTERS_OTHER_SELECTOR: 2,
	MATERIAL_OTHER_SELECTOR: 3,
	PRINTERS_ONLY_CHECKBOX: 4,
	PRINTERS_TABLE: 5
}

export const PRINTERS_AND_MATERIALS_COSTS_IDS = {
	PRINTERS: 1,
	MATERIALS: 2
}

export const COST_SUPPLY_CHAIN_IDS = {
	PROJECT_LIFE_CYCLE: 1,
	COST_CALCULATION: 2,
	SUPPLY_CHAIN: 3,
	POST_PROCESSES: 4
}

export const MATERIAL_NAMES_IDS = {
	DEFAULT_MATERIAL: 1,
	MATERIAL_BY_NAME: 2,
	EDIT_MATERIAL_NAMES: 3
}

export const SOLUTION_FILTERS_IDS = {
	SOLUTION_PREFERENCES: 1,
	GA_PREFERENCES: 2
}

export const SUSTAINABILITY_IDS = {
	MATERIAL_PRODUCTION: 1,
	PRODUCTION: 2,
	PRODUCT_USE: 3,
	TRANSPORTATION: 4,
	END_OF_LIFE: 5,
	CARBON_CREDIT: 6
}

export const ADVANCED_IDS = {
	DESCRIPTION_BLOCK: 1
}

export const DONE_SCREEN_IDS = {
	DESCRIPTION: 1,
	START_BUTTON: 2
}

export const TM_MATERIAL_IDS = {
	ADD_TM_MATERIAL_BUTTON: 1,
	TM_MATERIALS_TABLE: 2
}

export const TM_FORM_IDS = {
	TM_FORM: 1
}

export const AM_MATERIAL_IDS = {
	ADD_AM_MATERIAL_BUTTON: 1,
	AM_MATERIALS_TABLE: 2
}

export const AM_FORM_IDS = {
	AM_FORM: 1
}

export const ADD_PRINTER_IDS = {
	ADD_PRINTER_BUTTON: 1,
	PRINTERS_TABLE: 2
}

export const ADD_PRINTER_FORM_IDS = {
	PRINTER_FORM: 1
}
