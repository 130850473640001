import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isEmpty, uniqBy } from 'lodash'

import {
	CHANGE_ADVANCED_FILTERS_CHECKBOX,
	CHANGE_ADVANCED_FILTERS_TECHNOLOGY,
	CHANGE_ADVANCED_FILTERS_VALUE,
	CHANGE_ADVANCED_FILTERS_VALUES,
	CHANGE_ADVANCED_GROUP_FILTER,
	CHANGE_ADVANCED_MULTIPLE_FILTERS_TECHNOLOGY,
	CHANGE_GEOMETRY_ANALYSIS_VALUE,
	REMOVE_ADVANCED_FILTERS,
	RESET_ADVANCED_FILTERS,
	SAVE_ADVANCED_FILTERS,
	SETUP_ADVANCED_FILTERS
} from './AdvancedSettingsTypes'
import { store } from 'index'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import {
	getAdvancedSettings,
	initializeConfigurationFilters,
	initializeFilters,
	validateFilter
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import { IN_HOUSE_PRINTERS } from 'Services/Constants'
import { IConfigurationCustomizationSettings } from 'Services/models/IConfigurationCustomizationSettings'
import { IUserFilter } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'

export const setupAdvancedFilters = (
	id: string | number,
	reset?: boolean,
	initialValues?: any,
	keepInHouseFilter?: boolean,
	disableInHouseFilter?: boolean,
	organizationId?: number
): any => {
	return (dispatch: Dispatch<AnyAction>) => {
		let {
			filters,
			userFilters,
			printingTechnologies,
			printerMaterialUniqueNames,
			userCustomizationSettings,
			userUnitSystem
		} = store.getState().user

		const {
			allConfigurationsOrganizationSettings
		}: {
			allConfigurationsOrganizationSettings: Record<
				number,
				IConfigurationCustomizationSettings
			>
		} = store.getState().MainPartAnalysisReducer

		if (
			organizationId &&
			allConfigurationsOrganizationSettings[organizationId]
		) {
			const configurationOrganizationSettings =
				allConfigurationsOrganizationSettings[organizationId]
			userFilters = configurationOrganizationSettings.userFilters || userFilters
			userCustomizationSettings =
				configurationOrganizationSettings.customizationSettings ||
				userCustomizationSettings
			printerMaterialUniqueNames =
				configurationOrganizationSettings.printerMaterialUniqueNames ||
				printerMaterialUniqueNames
		}

		const uniqPrinterMaterialUniqueNames = uniqBy(
			printerMaterialUniqueNames,
			'category'
		)
		let categories = uniqPrinterMaterialUniqueNames?.map(
			(categoryData: Record<string, any>) => {
				return {
					name: categoryData.category,
					userReadableName: categoryData.category,
					type: categoryData.type,
					section: null
				}
			}
		)
		categories = categories.sort((a, b) =>
			a.type > b.type ? -1 : b.type > a.type ? 1 : 0
		)

		const userInHouseFilter = userFilters.find(
			(filter: IUserFilter) => filter.name === IN_HOUSE_PRINTERS
		)

		if (!userFilters?.length) {
			userFilters = initializeFilters(filters)
		}

		if (!isEmpty(initialValues)) {
			userFilters = initializeConfigurationFilters(userFilters, initialValues)
		}

		if (reset) {
			userFilters = initializeFilters(filters)
		}

		if (userInHouseFilter) {
			const inHouseFilterIndex = userFilters.findIndex(
				(filter: IUserFilter) => filter.name === IN_HOUSE_PRINTERS
			)
			if (keepInHouseFilter && inHouseFilterIndex >= 0) {
				userFilters[inHouseFilterIndex].on = userInHouseFilter.on
			}
			userFilters[inHouseFilterIndex].disabledTextExplanation =
				disableInHouseFilter
					? getString('UPLOAD_PROJECT_DISABLED_IN_HOUSE_PRINTER_EXPLANATION')
					: ''
		}

		const preparedAdvancedSettings = getAdvancedSettings(
			filters,
			userFilters,
			printingTechnologies,
			categories,
			userUnitSystem
		)

		dispatch({
			type: SETUP_ADVANCED_FILTERS,
			payload: {
				id,
				preparedAdvancedSettings,
				printingTechnologies,
				categories,
				userCustomizationSettings
			}
		})
	}
}

export const changeAdvancedFilters = (
	id: string | number,
	filterValues: Record<string, any>,
	materialFilterReset?: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CHANGE_ADVANCED_FILTERS_VALUES,
			payload: {
				id,
				filterValues,
				materialFilterReset
			}
		})
	}
}
export const changeAdvancedFilter = (
	id: string | number,
	filterValue: any,
	filterName: string
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		let { tempFilters } =
			store.getState().AdvancedSettingsReducer?.advancedStates[id] ||
			new AdvancedSettingsInitialState()
		const filterError = validateFilter(filterValue, tempFilters, filterName)

		dispatch({
			type: CHANGE_ADVANCED_FILTERS_VALUE,
			payload: {
				id,
				filterError,
				filterValue,
				filterName
			}
		})
	}
}

export const changeAdvancedFilterCheckBox = (
	id: string | number,
	filterChecked: any,
	filterName: string
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CHANGE_ADVANCED_FILTERS_CHECKBOX,
			payload: {
				id,
				filterChecked,
				filterName
			}
		})
	}
}

export const changeAdvancedFilterTechnology = (
	id: string | number,
	filterValue: any,
	filterName: string
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CHANGE_ADVANCED_FILTERS_TECHNOLOGY,
			payload: {
				id,
				filterValue,
				filterName
			}
		})
	}
}

export const changeAdvancedMultipleFilterTechnology = (
	id: string | number,
	filterValue: any,
	filterName: string
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CHANGE_ADVANCED_MULTIPLE_FILTERS_TECHNOLOGY,
			payload: {
				id,
				filterValue,
				filterName
			}
		})
	}
}

export const changeAdvancedGroupFilter = (
	id: string | number,
	groupFilterName: string,
	filterValue: string[]
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const newFilterValue: string[] = Array.from(filterValue)

		dispatch({
			type: CHANGE_ADVANCED_GROUP_FILTER,
			payload: {
				id,
				filterValue: newFilterValue,
				groupFilterName
			}
		})
	}
}

export const removeFiltersItem = (
	id: string | number,
	filterName: string,
	geometryAnalysis: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		geometryAnalysis
			? dispatch({
					type: CHANGE_GEOMETRY_ANALYSIS_VALUE,
					payload: {
						id,
						name: filterName,
						save: true
					}
			  })
			: dispatch({
					type: REMOVE_ADVANCED_FILTERS,
					payload: {
						id,
						filterName,
						geometryAnalysis
					}
			  })
	}
}

export const saveAdvancedFilters = (id: string | number) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SAVE_ADVANCED_FILTERS,
			payload: {
				id
			}
		})
	}
}

export const resetAdvancedFilters = (id: string | number) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: RESET_ADVANCED_FILTERS,
			payload: {
				id
			}
		})
	}
}

export const onChangeGeometryAnalysis = (
	id: string | number,
	name: string,
	value: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CHANGE_GEOMETRY_ANALYSIS_VALUE,
			payload: {
				id,
				name,
				value
			}
		})
	}
}
