import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Action } from 'redux'

import MenuItem from '@material-ui/core/MenuItem'
import cx from 'classnames'

import { materialTypes } from '../../../../Services/Constants'
import {
	SELECT_AM_MATERIAL_CATEGORY_PLACEHOLDER,
	SELECT_AM_MATERIAL_PLACEHOLDER,
	SELECT_AM_SUBCATEGORY_PLACEHOLDER,
	SELECT_MATERIAL_TYPE_PLACEHOLDER
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import TransparentButton from '../../TransparentButton'
import {
	renderButtonField,
	renderCategorySelector,
	renderSelectField,
	sortPrinterMaterials
} from '../Common/MultipleMaterialSelector'
import {
	materialCategoryChanged,
	materialChanged,
	materialTypeChanged,
	setup,
	subCategoryChanged
} from './AmMaterialSelectorActions'
import { PrinterMaterial } from 'Services/models/IPrinterMaterial'

import './AmMaterialSelector.scss'

interface IProps {
	setup: (
		printerMaterials: PrinterMaterial[],
		onChange: Function,
		defaultPrinterMaterial: any,
		defaultMaterialForTypeArr: Record<string, any>[],
		printerMaterialsSubCategories: Record<string, any>[],
		printerMaterialsCategories: Record<string, any>[]
	) => Action<any>
	printerMaterials: PrinterMaterial[]
	onChange: Function
	defaultMaterialForTypeArr: Record<string, any>[]
	defaultPrinterMaterial: Record<string, any>
	hideMaterialNameSelector: boolean
	hiddenMaterialNameSelector: boolean
	materialChanged: Function
	subCategoryChanged: Function
	materialTypeChanged: Function
	materialCategoryChanged: Function
	disabled: boolean
	selectClassName: string
	isButtonSelector: boolean
	rootSelectClassName?: string
	showCategorySelector: boolean
	materialCategory: string
	materialSubCategory: string
	materialCategoriesArr: Record<string, any>[]
	materialSubCategoriesArr: string[]
	qaDataElementNameCategory: string
	disableMaterialCategory?: boolean
	qaDataElementNameMaterial: string
	disableOriginalMaterial?: boolean
	qaDataElementNameType: string
	showMaterialsFilters?: boolean
	printerMaterialsSubCategories: Record<string, any>[]
	printerMaterialsCategories: Record<string, any>[]
	allPrinters: Record<string, any>[]
}

const AmMaterialSelector: React.FC<any> = (props: IProps) => {
	const {
		hideMaterialNameSelector,
		defaultMaterialForTypeArr,
		onChange,
		defaultPrinterMaterial,
		isButtonSelector,
		qaDataElementNameType,
		disableOriginalMaterial,
		disabled
	} = props
	const dispatch = useDispatch()
	const amMaterialSelectorProps = useSelector(
		(state: RootStateOrAny) => state.AmMaterialSelectorReducer
	)
	const {
		material,
		materialSubCategoriesArr,
		materialTypesArr,
		materialType,
		materialSubCategory
	} = amMaterialSelectorProps
	const userReducer = useSelector((state: RootStateOrAny) => state.user)

	const {
		printerMaterials,
		printerMaterialsSubCategories,
		printerMaterialsCategories,
		printersFullData
	} = userReducer
	const allPrinters: Record<string, any>[] = []
	printersFullData.map((company: any) => {
		for (const p of company.printers) {
			allPrinters.push({ id: p.printerId, name: p.name })
		}
	})

	const allProps = { ...props, ...amMaterialSelectorProps, ...userReducer }
	const [materialArr, setMaterialArr] = useState([])

	const [hiddenMaterialNameSelector, setHiddenMaterialNameSelector] = useState(
		!materialArr.length
	)
	useEffect(() => {
		dispatch(
			setup(
				printerMaterials,
				onChange,
				defaultPrinterMaterial,
				defaultMaterialForTypeArr,
				printerMaterialsSubCategories,
				printerMaterialsCategories
			)
		)
	}, [])

	const onSpecifyMaterial = () => {
		let materials = printerMaterials.filter(
			(p: PrinterMaterial) =>
				p.subCategory === materialSubCategory && !p.heatTreatment
		)
		materials = sortPrinterMaterials(materials)
		materials = materials.map((m: PrinterMaterial) => {
			const printerId = JSON.parse(m.printers)
			const printerName = allPrinters.find((p: any) => p.id === printerId[0])
			return { ...m, printerName: printerName?.name || '' }
		})
		setMaterialArr(materials)
		setHiddenMaterialNameSelector(false)
		dispatch(materialChanged(material.id, allPrinters))
	}

	const onMaterialTypeChanged = (value: string) => {
		setMaterialArr([])
		setHiddenMaterialNameSelector(true)
		dispatch(materialTypeChanged(value))
	}

	const onMaterialCategoryChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setMaterialArr([])
		setHiddenMaterialNameSelector(true)
		dispatch(materialCategoryChanged(event.target.value))
	}

	const onSubCategoryChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setMaterialArr([])
		setHiddenMaterialNameSelector(true)
		dispatch(subCategoryChanged(event.target.value))
	}

	const onMaterialChanged = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(materialChanged(event.target.value, allPrinters))
	}

	const renderMaterialSubCategoryMenuItems = (
		materialSubCategoriesArr: Record<string, any>[]
	) => {
		return materialSubCategoriesArr?.map((material: Record<string, any>) => {
			return (
				<MenuItem key={material.subCategory} value={material.subCategory}>
					{material.subCategory}
				</MenuItem>
			)
		})
	}

	const renderMaterialMenuItems = (materialArr: Record<string, any>[]) => {
		return materialArr?.map((material: Record<string, any>) => {
			return (
				<MenuItem key={material.id} value={material.id}>
					{material.printerName
						? `${material.name} (${material.printerName})`
						: material.name}
				</MenuItem>
			)
		})
	}

	const renderNameFieldWithoutSelector = (label: string) => {
		return (
			<div>
				{isButtonSelector && <div className="label">{label}</div>}
				<TransparentButton
					disabled={disabled}
					onClick={() => onSpecifyMaterial()}
					style={{ marginLeft: 20 }}
				>
					{getString('SPECIFY_MATERIAL')}
				</TransparentButton>
			</div>
		)
	}

	const renderSubCategorySelector = () => {
		const selectClassName = isButtonSelector
			? 'field-with-label outlined'
			: 'material-selector material-last-selector'

		return renderSelectField(
			selectClassName,
			material?.subCategory,
			onSubCategoryChanged,
			SELECT_AM_SUBCATEGORY_PLACEHOLDER,
			materialSubCategoriesArr,
			renderMaterialSubCategoryMenuItems(materialSubCategoriesArr),
			'subCategory',
			allProps,
			getString('PRINTER_MATERIAL_SUB_CATEGORY'),
			disableOriginalMaterial
		)
	}

	const renderNameSelector = () => {
		if (hiddenMaterialNameSelector) {
			return renderNameFieldWithoutSelector(getString('PRINTER_MATERIAL_NAME'))
		}
		const selectClassName = isButtonSelector
			? 'field-with-label outlined'
			: 'material-selector material-last-selector'

		return renderSelectField(
			selectClassName,
			material.printerName
				? `${material.name} (${material.printerName})`
				: material.name,
			onMaterialChanged,
			SELECT_AM_MATERIAL_PLACEHOLDER,
			materialArr,
			renderMaterialMenuItems(materialArr),
			'name',
			allProps,
			getString('PRINTER_MATERIAL_NAME'),
			disableOriginalMaterial
		)
	}

	return (
		<div
			className={cx('am-material-selctors-div', {
				'am-material-selctors-div__buttons': isButtonSelector
			})}
		>
			{isButtonSelector
				? renderButtonField(
						materialType,
						materialTypesArr,
						qaDataElementNameType,
						getString('PRINTER_MATERIAL_TYPE'),
						onMaterialTypeChanged
				  )
				: renderSelectField(
						'material-selector',
						materialType,
						onMaterialTypeChanged,
						SELECT_MATERIAL_TYPE_PLACEHOLDER,
						materialTypesArr,
						isButtonSelector,
						'',
						allProps
				  )}
			<div className="flex">
				{renderCategorySelector(
					allProps,
					getString('PRINTER_MATERIAL_CATEGORY'),
					onMaterialCategoryChanged,
					SELECT_AM_MATERIAL_CATEGORY_PLACEHOLDER
				)}
				{renderSubCategorySelector()}
				<div className="name-selector-section">{renderNameSelector()}</div>
			</div>
		</div>
	)
}

export default memo(AmMaterialSelector)
