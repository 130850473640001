import React, { FC, useCallback, useEffect } from 'react'
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { formValueSelector } from 'redux-form'

import { STEP_ID } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { createNewPrinterMaterial } from 'Scenes/admin/AdminHome/AdminPrinterMaterials/AdminPrinterMaterialsActions'
import PrinterMaterialForm from 'Scenes/Components/PrinterMaterialForm/PrinterMaterialForm'
import { setupPrinterMaterialForm } from 'Scenes/Home/Customize/CustomizeUserMaterials/CustomizeUserMaterialsActions'
import { userPrinterMaterialFormName } from 'Scenes/Home/Customize/CustomizeUserMaterials/NewAMMaterial/constants'

type IProps = {
	selectedType: string
	selectedPrinterTechnology: string
	selectedPrinterName: string
	selectedPrinterCompany: string
	selectedCategory: string
	selectedSubCategory: string
	selectedId: string
}

const AMMaterialForm: FC<IProps> = ({
	selectedType,
	selectedPrinterTechnology,
	selectedPrinterName,
	selectedPrinterCompany,
	selectedCategory,
	selectedSubCategory,
	selectedId
}) => {
	const { parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const {
		PMCategoriesAverageCO2,
		selectedNewPrinterMaterial,
		allPrintersCompanies,
		allPMCategories,
		allPMSubCategories,
		addingNewItem,
		printerMaterialAdded
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizeUserMaterialsReducer
	)
	const { printingTechnologies } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()

	const onSubmit = useCallback(
		(data: Record<string, unknown>) => {
			dispatch(
				createNewPrinterMaterial(data, true, adminSelectedOrganizationId)
			)
		},
		[adminSelectedOrganizationId, dispatch]
	)

	useEffect(() => {
		dispatch(setupPrinterMaterialForm(adminSelectedOrganizationId))
	}, [adminSelectedOrganizationId, dispatch])

	useEffect(() => {
		if (printerMaterialAdded) {
			dispatch(goToNextStep(parentSteps, currentStep, STEP_ID.AM_MATERIAL))
		}
	}, [currentStep, dispatch, parentSteps, printerMaterialAdded])

	return (
		<div className="tm-material--form-wrapper">
			<PrinterMaterialForm
				formName={userPrinterMaterialFormName}
				selectedId={selectedId}
				initialValues={selectedNewPrinterMaterial}
				allPrintersCompanies={allPrintersCompanies}
				allCategories={allPMCategories}
				allSubCategories={allPMSubCategories}
				printingTechnologies={printingTechnologies}
				selectedPrinterName={selectedPrinterName}
				selectedCategory={selectedCategory}
				selectedPrinterCompany={selectedPrinterCompany}
				selectedSubCategory={selectedSubCategory}
				onSubmit={onSubmit}
				addingNewItem={addingNewItem}
				selectedType={selectedType}
				selectedPrinterTechnology={selectedPrinterTechnology}
				userCurrencySign={'$'}
				categoriesAverageCO2={PMCategoriesAverageCO2}
				isAdminForm={false}
			/>
		</div>
	)
}

const selector = formValueSelector(userPrinterMaterialFormName)

const mapStateToProps = (state: Record<string, unknown>) => {
	return {
		selectedType: selector(state, 'type'),
		selectedPrinterTechnology: selector(state, 'printerTechnologyName'),
		selectedPrinterName: selector(state, 'printers'),
		selectedPrinterCompany: selector(state, 'printerCompany'),
		selectedCategory: selector(state, 'category'),
		selectedId: selector(state, 'id'),
		selectedSubCategory: selector(state, 'subCategory')
	}
}

export default connect(mapStateToProps)(AMMaterialForm)
