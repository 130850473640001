import { ChangeEvent, FC, useEffect, useReducer, useState } from 'react'
import Chart from 'react-apexcharts'
import { RootStateOrAny, useSelector } from 'react-redux'

import TextField from '@material-ui/core/TextField'
import { isNumber } from 'lodash'

import {
	MAX_X,
	MAX_Y,
	MIN_WIDTH_COMPONENTS,
	MIN_WIDTH_GRAPH,
	MIN_X,
	MIN_Y
} from './Constants'
import { initialState, reducer } from './PartsGraphUseReducer'
import { findGraphParameters, graphChartData } from './PartsGraphViewService'
import FlexBox from 'Scenes/Components/FlexBox'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import useWindowDimensions from 'Services/CustomHooks/useWindowDimensions'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { PartStatus } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

import './PartsGraphView.scss'

const TextFieldTSX: any = TextField
const ChartGraph: any = Chart

export interface GenerativePart {
	mass: number
	stress: number
	cost: number
	partNumber: string
	status: PartStatus
	result: CADAnalysisResult
}

interface PartsGraphViewProps {
	generativeDesignParts?: GenerativePart[]
}

const PartsGraphView: FC<PartsGraphViewProps> = ({
	generativeDesignParts = []
}) => {
	const graphData = findGraphParameters(generativeDesignParts)
	const { width } = useWindowDimensions()
	const [reducerState, dispatchState] = useReducer(reducer, initialState)
	const { project } = useSelector(
		(state: RootStateOrAny) => state.ProjectAnalysisReducer
	)
	const { project: projectBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectBundleReducer
	)

	const graphWidth =
		width - MIN_WIDTH_COMPONENTS > MIN_WIDTH_GRAPH
			? width - MIN_WIDTH_COMPONENTS
			: MIN_WIDTH_GRAPH

	const chartDefaultData = graphChartData(
		graphData,
		generativeDesignParts,
		project?.name || projectBundle?.name || ''
	)
	const [chartData, setChartData] =
		useState<typeof chartDefaultData>(chartDefaultData)

	useEffect(() => {
		setChartData(chartDefaultData)
		dispatchState(initialState)
	}, [generativeDesignParts])

	const updateGraphValue = (value: string, name: string) =>
		dispatchState({
			...reducerState,
			[name]: !!value ? +value : null
		})

	const updateGraphChart = (reset?: boolean) => {
		const newData = findGraphParameters(
			generativeDesignParts,
			reset ? null : reducerState
		)
		setChartData(
			graphChartData(
				newData,
				generativeDesignParts,
				project?.name || projectBundle?.name || ''
			)
		)
		if (reset) {
			dispatchState({
				...initialState
			})
		}
	}

	const disableUpdateButton =
		(isNumber(reducerState.maxY) &&
			reducerState.maxY === 0 &&
			isNumber(reducerState.minY) &&
			reducerState.minY === 0) ||
		(isNumber(reducerState.maxX) &&
			reducerState.maxX === 0 &&
			isNumber(reducerState.minX) &&
			reducerState.minX === 0)

	return (
		<FlexBox
			width="100%"
			height="100%"
			flexDirection="row"
			alignItems="center"
			justifyContent="center"
			className="graph-chart"
		>
			<div data-qa="data-qa-chart-view-graph" style={{ width: graphWidth }}>
				<ChartGraph
					type={'bubble'}
					options={chartData && chartData.componentChartData}
					series={chartData && chartData.series}
					height="420"
					width={graphWidth}
					className="graph"
				/>
			</div>
			<hr />
			<FlexBox
				flexDirection="column"
				alignItems="flex-start"
				justifyContent="center"
				className="graph-chart--options"
			>
				<FlexBox flexDirection="column" alignItems="flex-start">
					<div className="option-title">{getString('X_AXIS_OPTIONS')}</div>
					<div>
						<TextFieldTSX
							label="Min X"
							className="number-field"
							type="number"
							placeholder={Number(graphData.minX).toFixed(4) || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								updateGraphValue(e.target.value, MIN_X)
							}}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							value={reducerState.minX}
						/>
						<TextFieldTSX
							label="Max X"
							className="number-field"
							type="number"
							placeholder={Number(graphData.maxX).toFixed(4) || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								updateGraphValue(e.target.value, MAX_X)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							value={reducerState.maxX}
						/>
					</div>
				</FlexBox>
				<FlexBox flexDirection="column" alignItems="flex-start">
					<div className="option-title">{getString('Y_AXIS_OPTIONS')}</div>
					<div>
						<TextFieldTSX
							label="Min Y"
							className="number-field"
							type="number"
							placeholder={Number(graphData.minY).toFixed(4) || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								updateGraphValue(e.target.value, MIN_Y)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							value={reducerState.minY}
						/>
						<TextFieldTSX
							label="Max Y"
							className="number-field"
							type="number"
							placeholder={Number(graphData.maxY).toFixed(4) || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								updateGraphValue(e.target.value, MAX_Y)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							value={reducerState.maxY}
						/>
					</div>
				</FlexBox>
				<div className="option-button">
					<Button
						disabled={disableUpdateButton}
						color="primary"
						onClick={() => updateGraphChart()}
					>
						{getString('APPLY')}
					</Button>
				</div>
				<div className="option-button">
					<Button color="transparent" onClick={() => updateGraphChart(true)}>
						{getString('RETURN_TO_DEFAULT')}
					</Button>
				</div>
			</FlexBox>
		</FlexBox>
	)
}

export default PartsGraphView
