import React, { FC, memo, Suspense } from 'react'

import Co2Comparison from '../../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/Co2Comparison'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import { manufacturingMethodTypes } from 'Services/Constants'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

const SolutionAnalysis = React.lazy(() => import('../../../SolutionAnalysis'))
const SolutionAnalysisObject: any = SolutionAnalysis

interface IProps {
	configuration: any
	part: Part
	initialBatchSize?: number
	manufacturingMethod: string
	show: boolean
	showEmission: boolean
	isAmOriginalMaterial: boolean
}

const ConfigurationPdf: FC<IProps> = ({
	configuration,
	part,
	initialBatchSize,
	manufacturingMethod,
	show,
	showEmission,
	isAmOriginalMaterial
}) => {
	if (!show) return <div />
	const standardCost = configuration.standardCost || part.standardCost
	return (
		<div className="inline">
			<div className="export-pdf__configuration">
				<h2>{getString('ADDITIVE_MANUFACTURING_SOLUTION')}</h2>
				<br />
				{showEmission && (
					<div className="export-pdf__configuration-co2">
						<Co2Comparison
							configuration={configuration}
							part={part}
							shouldShowStandardCost={
								manufacturingMethod === manufacturingMethodTypes.standardCost ||
								(part.blockManufacturingMethodOperation && standardCost)
							}
							isAmOriginalMaterial={isAmOriginalMaterial}
						/>
					</div>
				)}
				<Suspense fallback={<div />}>
					<SolutionAnalysisObject
						disableUnmount={true}
						showFullIcon={true}
						initialBatchSize={initialBatchSize}
						configuration={configuration}
						part={part}
						cluster={null}
						toPrint
					/>
				</Suspense>
				<LineBreak />
			</div>
		</div>
	)
}

export default memo(ConfigurationPdf)
