import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as MaterialCostActions from './MaterialCostActions'
import {
	MATERIAL_COST_FIELDS_ALERT,
	MATERIAL_COST_INPUTS_LBS_EXPLENATION,
	MATERIAL_COST_INPUTS_WEIGHT_UNIT
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import { Danger } from '../../../Components/thirdParty/CreativeTim/components'
import MaterialPricePicker from '../MaterialPricePicker'
import CustomizeMaterialSelector from './CustomizeMaterialSelector'
import Flexbox from 'Scenes/Components/FlexBox'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	UnitsConversionService,
	UnitSystem
} from 'Services/UnitsConversionService'

import customizeMaterialStyle from './CustomizeMaterialCost.css'

const MaterialCostInputs = ({
	showMaterialCostAlert,
	materialPrice,
	selectedMaterialName,
	selectedMaterial,
	adminSelectedOrganizationId,
	materialCostLoading,
	materialPriceError,
	onMaterialPriceChange,
	onMaterialCostAdd,
	isImperialSystem
}) => {
	const [selectedWeightUnit, setSelectedWeightUnit] = useState(
		MATERIAL_COST_INPUTS_WEIGHT_UNIT[0]
	)
	const [showMaterialExplanation, setShowMaterialExplanation] = useState(false)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)

	useEffect(() => {
		if (isImperialSystem) {
			setSelectedWeightUnit(MATERIAL_COST_INPUTS_WEIGHT_UNIT[1])
		}
	}, [isImperialSystem])

	const renderAlert = () => {
		if (showMaterialCostAlert) {
			return <Danger>{MATERIAL_COST_FIELDS_ALERT}</Danger>
		}
		return <div />
	}

	const renderMaterialExplanation = () => {
		if (showMaterialExplanation) {
			return (
				<span
					style={customizeMaterialStyle.weightSelectorSpan}
					className="weight-selector-info"
				>
					{MATERIAL_COST_INPUTS_LBS_EXPLENATION}
				</span>
			)
		}
		return <div />
	}

	const onWeightSelectorChange = e => {
		setSelectedWeightUnit(e.target.value)
		if (!customizeUnitSystem) {
			setShowMaterialExplanation(
				e.target.value === MATERIAL_COST_INPUTS_WEIGHT_UNIT[1]
			)
		}
	}

	const handleMaterialPriceChange = e => {
		onMaterialPriceChange(e.target.value)
	}

	const handleMaterialCostAdd = () => {
		const inputSystem =
			selectedWeightUnit === MATERIAL_COST_INPUTS_WEIGHT_UNIT[0]
				? UnitSystem.metric
				: UnitSystem.imperial

		const conversionService = new UnitsConversionService(
			inputSystem,
			UnitSystem.metric
		)

		materialPrice = conversionService.convertPricePerWeightUnit(
			materialPrice,
			false
		)

		onMaterialCostAdd(
			materialPrice,
			selectedMaterialName,
			selectedMaterial,
			undefined,
			adminSelectedOrganizationId
		)
	}

	const isButtonDisabled = () => {
		if (parseFloat(materialPrice) > 0 && selectedMaterialName) {
			return false
		}
		return true
	}

	return (
		<div style={customizeMaterialStyle.materialCost}>
			<div style={{ opacity: 0.6 }} className="material-costs-input-hint">
				{getString('MATERIAL_COST_INPUTS_TITLE')}
			</div>
			<div style={customizeMaterialStyle.inputsDiv}>
				<Flexbox
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
					className="customize-material"
				>
					<CustomizeMaterialSelector />
					<MaterialPricePicker
						materialPrice={materialPrice}
						onMaterialPriceChange={handleMaterialPriceChange}
						onWeightSelectorChange={onWeightSelectorChange}
						selectedWeightUnit={selectedWeightUnit}
						materialPriceError={materialPriceError}
					/>

					<ButtonWithLoader
						disabled={isButtonDisabled()}
						size={30}
						top={20}
						style={{ padding: '5px 20px', width: 'unset' }}
						loading={materialCostLoading}
						onClick={handleMaterialCostAdd}
					>
						{getString('ADD')}
					</ButtonWithLoader>
				</Flexbox>
			</div>
			{renderMaterialExplanation()}
			{renderAlert()}
		</div>
	)
}

const mapStateToProps = ({ user, MaterialCostReducer, CustomizeReducer }) => {
	const { printerTechnologiesIds, isImperialSystem } = user
	const {
		materialPrice,
		materialCostLoading,
		selectedPrinterTechnology,
		selectedMaterialName,
		materialCosts,
		showMaterialCostAlert,
		selectedMaterial,
		materialPriceError
	} = MaterialCostReducer
	const { adminSelectedOrganizationId } = CustomizeReducer

	return {
		printerTechnologiesIds,
		materialPrice,
		materialCostLoading,
		selectedPrinterTechnology,
		selectedMaterialName,
		materialCosts,
		selectedMaterial,
		showMaterialCostAlert,
		materialPriceError,
		adminSelectedOrganizationId,
		isImperialSystem
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(Object.assign({}, MaterialCostActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialCostInputs)
