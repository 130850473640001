import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { ONBOARDING } from '../../constants'
import { onFiltersChanged } from 'global actions'
import { setupAdvancedFilters } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import SolutionConfigureFeaturesFilters from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionConfigure/SolutionConfigureFeatures/SolutionConfigureFeaturesFilters'
import { IN_HOUSE_PRINTERS } from 'Services/Constants'
import { IUserFilter } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'
import { UserFilterService } from 'Services/UserFilterService'

const userFilterService = new UserFilterService()

const SolutionPreferences: FC = () => {
	const { tempFilters, isError } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[ONBOARDING] ||
			new AdvancedSettingsInitialState()
	)
	const { userFilters, filters } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()

	const onFilterConfirm = () => {
		dispatch(
			onFiltersChanged(
				tempFilters,
				userFilters,
				false,
				adminSelectedOrganizationId
			)
		)
	}

	const returnToDefault = () => {
		const userInHouseFilter = userFilters.find(
			(filter: IUserFilter) => filter.name === IN_HOUSE_PRINTERS
		)
		const initialUserFilters = userFilterService.initializeFilters(
			filters,
			userInHouseFilter
		)

		dispatch(
			onFiltersChanged(
				tempFilters,
				initialUserFilters,
				true,
				adminSelectedOrganizationId
			)
		)
		dispatch(setupAdvancedFilters(ONBOARDING, true, undefined, true))
	}

	useEffect(() => {
		dispatch(setupAdvancedFilters(ONBOARDING))
	}, [dispatch])

	return (
		<div className="customize-solution-filter__content">
			<SolutionConfigureFeaturesFilters
				id={ONBOARDING}
				title={getString('ONBOARDING_CUSTOMIZE_SOLUTION_PREFERENCES_TITLE')}
				showTitle
				hideInHouseFilter
			/>
			<div className="filters-button">
				<Button
					className="customize-solution-filter-secondary"
					color="secondary"
					onClick={returnToDefault}
				>
					{getString('RETURN_TO_DEFAULT')}
				</Button>
				<Button color="primary" disabled={isError} onClick={onFilterConfirm}>
					{getString('SET')}
				</Button>
			</div>
		</div>
	)
}

export default SolutionPreferences
