import React, { FC } from 'react'
import CustomizeCO2TypicalKwh from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Manufacturing/CustomizeCO2TypicalKwh'
import { getString } from 'Services/Strings/StringService'

const Production: FC = () => {
	return (
		<div
			className="material-production--wrapper"
			data-qa="data-qa-production-section"
		>
			<div className="material-production--header">
				{getString('PRODUCTION')}
			</div>
			<CustomizeCO2TypicalKwh />
		</div>
	)
}

export default Production
