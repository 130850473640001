import { cloneDeep, findIndex, isBoolean, some } from 'lodash'

import { IFilterData } from './AdvancedSettingsInterface'
import {
	CHANGE_ADVANCED_FILTERS_CHECKBOX,
	CHANGE_ADVANCED_FILTERS_TECHNOLOGY,
	CHANGE_ADVANCED_FILTERS_VALUE,
	CHANGE_ADVANCED_FILTERS_VALUES,
	CHANGE_ADVANCED_GROUP_FILTER,
	CHANGE_ADVANCED_MULTIPLE_FILTERS_TECHNOLOGY,
	CHANGE_GEOMETRY_ANALYSIS_VALUE,
	REMOVE_ADVANCED_FILTERS,
	RESET_ADVANCED_FILTERS,
	SAVE_ADVANCED_FILTERS,
	SETUP_ADVANCED_FILTERS
} from './AdvancedSettingsTypes'
import { Action } from 'global actions/ActionModels'
import {
	getDisplayFilterItemsList,
	getDisplayGeometryAnalysis
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import { FilterType, IUserFilterNames } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'

export class AdvancedSettingsInitialState {
	readonly tempFilters: Array<any> = []
	readonly filters: Array<any> = []
	readonly filterItemsList: Array<any> = []
	readonly isError: boolean = false
	readonly categoriesObject: Array<any> = []
	readonly tempGeometryAnalysisPart: Record<string, boolean> = {}
	readonly geometryAnalysisPart: Record<string, boolean> = {}
}

const initialAdvancedState: AdvancedSettingsInitialState =
	new AdvancedSettingsInitialState()

const AdvancedSettingsReducer = (
	state = initialAdvancedState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case SETUP_ADVANCED_FILTERS: {
			const { preparedAdvancedSettings, userCustomizationSettings } = payload

			const filterItemsList = getDisplayFilterItemsList(
				preparedAdvancedSettings
			)

			const geometryAnalysisPart = getDisplayGeometryAnalysis(
				userCustomizationSettings
			)

			return {
				...state,
				tempGeometryAnalysisPart: geometryAnalysisPart,
				geometryAnalysisPart: geometryAnalysisPart,
				filters: preparedAdvancedSettings,
				tempFilters: preparedAdvancedSettings,
				filterItemsList
			}
		}

		case CHANGE_ADVANCED_FILTERS_VALUE: {
			const { filterName, filterValue, filterError } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)
			updatedFilters[changedIndex].value = filterValue
			updatedFilters[changedIndex].error = filterError

			return {
				...state,
				tempFilters: updatedFilters,
				isError: some(updatedFilters, filter => !!filter.error)
			}
		}

		case CHANGE_GEOMETRY_ANALYSIS_VALUE: {
			const { name, value, save } = payload

			const updatedGeometryAnalysisPart =
				cloneDeep(state.tempGeometryAnalysisPart) || {}

			updatedGeometryAnalysisPart[name] = isBoolean(value)
				? value
				: !updatedGeometryAnalysisPart[name]

			return {
				...state,
				tempGeometryAnalysisPart: updatedGeometryAnalysisPart,
				...(save ? { geometryAnalysisPart: updatedGeometryAnalysisPart } : {})
			}
		}

		case CHANGE_ADVANCED_FILTERS_VALUES: {
			const { filterValues, materialFilterReset } = payload

			let updatedFilters = cloneDeep(state.tempFilters)

			if (materialFilterReset) {
				updatedFilters = updatedFilters.map(filter => {
					// reset values only for non-user change
					if (!filter.manualCheck) {
						filter.checked = filter.prevChecked
					}
					return filter
				})
			}

			filterValues &&
				Object.keys(filterValues).map(key => {
					const changedIndex = findIndex(
						updatedFilters,
						filter => filter.name === key
					)
					if (changedIndex != -1 && !!filterValues[key]) {
						updatedFilters[changedIndex].checked = filterValues[key]
						updatedFilters[changedIndex].value = filterValues[key]
					}
				})

			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				filters: updatedFilters,
				tempFilters: updatedFilters,
				filterItemsList
			}
		}

		case CHANGE_ADVANCED_FILTERS_TECHNOLOGY: {
			const { filterName, filterValue } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)
			updatedFilters[changedIndex].value = filterValue

			if (filterName === IUserFilterNames.printerMaterialCategory) {
				updatedFilters[changedIndex].checked = !!filterValue
			}

			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				tempFilters: updatedFilters,
				filterItemsList
			}
		}

		case CHANGE_ADVANCED_MULTIPLE_FILTERS_TECHNOLOGY: {
			const { filterName, filterValue } = payload
			const value = filterValue[0]
			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)

			let oldFilter = updatedFilters[changedIndex]
			const isValueIncludes = (oldFilter.value || []).find(
				(v: string) => v === value
			)

			if (oldFilter.value?.length) {
				if (!isValueIncludes) {
					oldFilter.value.push(value)
				} else if (isValueIncludes && oldFilter.value.length > 1) {
					oldFilter.value = oldFilter.value.filter((v: string) => v !== value)
				}

				updatedFilters[changedIndex].value = oldFilter.value

				if (filterName === IUserFilterNames.printerMaterialCategory) {
					updatedFilters[changedIndex].checked = !!filterValue
				}
			}

			// if main filter is null then first check should trigger uncheck
			if (!oldFilter.value?.length) {
				oldFilter.value = []
				oldFilter.selectOptions.forEach((opt: Record<string, any>) => {
					if (value !== opt.name) {
						oldFilter.value.push(opt.name)
					}
				})
				updatedFilters[changedIndex].value = oldFilter.value
				updatedFilters[changedIndex].checked = true
			}

			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				tempFilters: updatedFilters,
				filterItemsList
			}
		}

		case CHANGE_ADVANCED_FILTERS_CHECKBOX: {
			const { filterChecked, filterName } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)
			const oldIndex = findIndex(
				state.filters,
				filter => filter.name === filterName
			)

			updatedFilters[changedIndex].checked = filterChecked
			updatedFilters[changedIndex].disabledTextField = !filterChecked
			// we need to keep info if the param was changed by click
			updatedFilters[changedIndex].manualCheck = true

			if (!filterChecked) {
				updatedFilters[changedIndex].value =
					state.filters[oldIndex].defaultValue

				if (updatedFilters[changedIndex].type !== FilterType.Boolean) {
					updatedFilters[changedIndex].value = null
				}
				if (updatedFilters[changedIndex].type === FilterType.Group) {
					updatedFilters[changedIndex].selectOptions.forEach(
						(option: IFilterData) => {
							const childFilterIndex = updatedFilters.findIndex(
								filter => filter.name === option.name
							)
							updatedFilters[childFilterIndex].checked = false
							updatedFilters[childFilterIndex].disabledTextField = true
							updatedFilters[childFilterIndex].manualCheck = true
						}
					)
				}

				updatedFilters[changedIndex].error = null
			}

			if (
				filterChecked &&
				updatedFilters[changedIndex].value == null &&
				updatedFilters[changedIndex].type !== FilterType.Boolean &&
				updatedFilters[changedIndex].type !== FilterType.Array
			) {
				updatedFilters[changedIndex].error = getString('PROVIDE_THE_VALUE')
			}

			if (
				filterChecked &&
				updatedFilters[changedIndex].type === FilterType.Group &&
				(updatedFilters[changedIndex].value == null ||
					(Array.isArray(updatedFilters[changedIndex].value) &&
						!updatedFilters[changedIndex].value.length))
			) {
				updatedFilters[changedIndex].error = getString('CHOOSE_THE_OPTION')
			}

			return {
				...state,
				tempFilters: updatedFilters,
				isError: some(updatedFilters, filter => !!filter.error)
			}
		}

		case REMOVE_ADVANCED_FILTERS: {
			const { filterName } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)
			updatedFilters[changedIndex].checked = false
			updatedFilters[changedIndex].value =
				updatedFilters[changedIndex].defaultValue

			if (updatedFilters[changedIndex].groupName) {
				const groupIndex = updatedFilters.findIndex(
					filter => filter.name === updatedFilters[changedIndex].groupName
				)
				if (groupIndex > -1) {
					updatedFilters[groupIndex].value = updatedFilters[
						groupIndex
					].value.filter(
						(name: string) => name !== updatedFilters[changedIndex].name
					)
					updatedFilters[groupIndex].checked =
						updatedFilters[groupIndex].value?.length > 0
				}
			}
			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				filters: updatedFilters,
				tempFilters: updatedFilters,
				filterItemsList
			}
		}

		case SAVE_ADVANCED_FILTERS: {
			const updatedFilters = state.tempFilters.map(filter => {
				if (
					filter.type !== 'array' &&
					filter.checked &&
					filter.value === null
				) {
					filter.value = filter.defaultValue
				}
				return filter
			})
			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				filters: updatedFilters,
				tempFilters: updatedFilters,
				geometryAnalysisPart: state.tempGeometryAnalysisPart,
				filterItemsList,
				isError: false
			}
		}

		case RESET_ADVANCED_FILTERS: {
			const filterItemsList = getDisplayFilterItemsList(state.filters)
			return {
				...state,
				tempFilters: state.filters,
				tempGeometryAnalysisPart: state.geometryAnalysisPart,
				filterItemsList,
				isError: false
			}
		}

		case CHANGE_ADVANCED_GROUP_FILTER: {
			const { filterValue, groupFilterName } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === groupFilterName
			)
			updatedFilters[changedIndex].value = filterValue
			updatedFilters[changedIndex].checked = filterValue.length > 0
			updatedFilters[changedIndex].error = null

			return {
				...state,
				tempFilters: updatedFilters,
				isError: some(updatedFilters, filter => !!filter.error)
			}
		}

		default:
			return state
	}
}

export default AdvancedSettingsReducer
