import { useEffect, useState } from 'react'

import cx from 'classnames'

import { ConfigurationResultTypes } from '../ConfigurationResultTypes'
import { getIssues, sortPrintIssueTableRows } from '../SolutionAnalysisService'
import { OptionalPostProcessesIds } from '../SolutionAnalysisTopDetails/SolutionAnalysisTopConstants'
import { store } from 'index'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import {
	DataTableFieldType,
	IDataTableField
} from 'Scenes/Components/DataTable/IDataTableField'
import {
	cncColor,
	GA_ACTION_COLUMN_INDEX,
	GA_REVIEW_SORTING_COLUMN_INDEX,
	holesColor,
	materialTypes,
	overhangingColor,
	printStatusScore,
	threadsColor,
	wtColor
} from 'Services/Constants'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Locale } from 'Services/models/IUser'
import {
	PartPrintIssue,
	PrintIssue,
	PrintIssueId
} from 'Services/models/PartPrintIssue'
import {
	checkResultsIssues,
	getCNCOrientedIssueString,
	getIconNameForScore,
	getMaterialScore,
	getOrientationStabilityString,
	getResultScore,
	getSizeScore,
	getTextForPartSize,
	getTextForPartThreads,
	getToleranceString
} from 'Services/PrintIssueService'
import {
	CUSTOMIZE,
	MESH_HEALING,
	PART_RESULTS_CAD,
	PART_RESULTS_MATERIAL,
	PART_RESULTS_WALL_THICKNESS,
	TOLERANCE_UPDATE_BUTTON
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'
import { getUserLanguage } from 'Services/Utils/startupTools'
import { getTheme } from 'themes/getTheme'

const theme = getTheme()
const language = getUserLanguage()
const isJaLocale = language === Locale.ja

export const useShouldShowGeometryReviewAndFixButton = (cluster: any) => {
	const [
		shouldShowGeometryReviewAndFixButton,
		setShouldShowGeometryReviewAndFixButton
	] = useState(false)

	useEffect(() => {
		setShouldShowGeometryReviewAndFixButton(shouldShowReviewButton(cluster))
	}, [])

	return shouldShowGeometryReviewAndFixButton
}

export const shouldShowReviewButton = (cluster: any) =>
	(!cluster && Feature.isFeatureOn(FeatureComponentId.REVIEW_AND_FIX_ISSUES)) ||
	false

export const renderRiskButton = (
	dataTableService: DataTableService,
	onPartPrintIssueChanged: Function,
	printIssueLoaders: Record<string, boolean>,
	printIssue?: PartPrintIssue
) => {
	if (
		!printIssue ||
		!printIssue?.printIssue?.manageable ||
		printIssue?.score === printStatusScore.passed
	) {
		return dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text
		})
	}

	const disabledActiveColumn = !Feature.isFeatureActive(
		FeatureComponentId.ACTION_COLUMN
	)
	return dataTableService.RenderFieldObject({
		type: DataTableFieldType.ButtonWithIconAndPopup,
		text: printIssue.active
			? getString('ACCEPT_RISK')
			: getString('UNDO_ACCEPT_RISK'),
		onClick: () =>
			onPartPrintIssueChanged(printIssue.id as number, !printIssue.active),
		className:
			'geometry-analysis--content--print-issues--table--button--only-border',
		extraData: {
			disabled: disabledActiveColumn,
			isHover: disabledActiveColumn,
			hoverText: getString('LIGHT_USER_BUTTONS_INFORMATION'),
			className: 'details-popup--contact-us',
			iconName: printIssue.active ? 'warningBlue' : '',
			iconClassName: `geometry-analysis--content--print-issues--table--button--icon`,
			buttonLoading: printIssueLoaders[printIssue.id as number]
		}
	})
}

export const shouldShowIssueRow = (
	score: number,
	showAllErrors: boolean,
	featureId?: FeatureComponentId
) => {
	if (featureId && !Feature.isFeatureOn(featureId)) {
		return false
	}
	if (showAllErrors) {
		return true
	}
	if (score < printStatusScore.passed) {
		return true
	}
	return false
}

export const getCadAction = (
	dataTableService: DataTableService,
	onMeshHealingClick: Function,
	healedStlURLExist?: boolean
) => {
	if (
		!healedStlURLExist ||
		!Feature.isFeatureOn(FeatureComponentId.MESH_HEALING)
	) {
		return dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text
		})
	}

	const disabledActiveColumn = !Feature.isFeatureActive(
		FeatureComponentId.ACTION_COLUMN
	)
	return dataTableService.RenderFieldObject({
		type: DataTableFieldType.ButtonWithPopup,
		extraData: {
			disabled: disabledActiveColumn,
			isHover: disabledActiveColumn,
			hoverText: getString('LIGHT_USER_BUTTONS_INFORMATION'),
			className: 'details-popup--contact-us'
		},
		text: MESH_HEALING,
		onClick: onMeshHealingClick,
		className: 'geometry-analysis--content--print-issues--table--button'
	})
}

export const useSurfaceAreaOn = (configuration: any) => {
	let isSurfaceAreaOn =
		configuration.postProcessesOptional &&
		configuration.postProcessesOptional[
			OptionalPostProcessesIds.SurfaceAreaMachining.toString()
		]?.toggled
	if (isSurfaceAreaOn == null) {
		isSurfaceAreaOn = false
	}
	return isSurfaceAreaOn
}

export const setDataTable = (
	configurationPrintIssues: PartPrintIssue[],
	orientationVector: number[],
	handleChange: Function,
	configuration: any,
	solution: any,
	onWallThicknessClick: Function,
	onTolerancesClick: Function,
	onMeshHealingClick: Function,
	onPartPrintIssueChanged: Function,
	printIssueLoaders: Record<string, boolean>,
	isSurfaceAreaOn: boolean,
	showAllErrors: boolean,
	partHealedStlURLExist?: boolean,
	selectedValue?: PrintIssueId,
	printIssues?: PrintIssue[]
): any[][] => {
	const dataTableService = new DataTableService()
	const disabledActiveColumn =
		!Feature.isFeatureActive(FeatureComponentId.ACTION_COLUMN) ||
		configuration.resultType === ConfigurationResultTypes.WeightReduction
	const {
		user: { userUnitSystem }
	} = store.getState()
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric

	const {
		partSizeIssue,
		orientationSizeIssue,
		configurationSizeIssue,
		wallThicknessIssue,
		overhangingIssue,
		tolerancesIssue,
		holesIssue,
		CADIssue,
		CNCIssue,
		internalCavitiesIssue,
		printStabilityIssue,
		orientedCNCIssue,
		heatDeformationIssue,
		threadsIssue
	} = getIssues(configurationPrintIssues, orientationVector)

	// we need material issue to know its order
	const materialPrintIssue = printIssues?.find(
		issue => issue.id === PrintIssueId.Material
	)

	const sizeScore = getSizeScore(
		partSizeIssue,
		orientationSizeIssue,
		configurationSizeIssue
	)
	const WTScore = getResultScore(wallThicknessIssue)
	const holesScore = getResultScore(holesIssue)
	const overhangingScore = getResultScore(overhangingIssue)
	const toleranceScore = getResultScore(tolerancesIssue)
	const cadScore = getResultScore(CADIssue)
	const threadsScore = getResultScore(threadsIssue)
	const CNCScore = getResultScore(CNCIssue)
	const orientedStabilityScore = getResultScore(printStabilityIssue)
	const orientedCNCScore = getResultScore(orientedCNCIssue)
	const internalCavitiesScore = getResultScore(internalCavitiesIssue)
	const heatDeformationScore = getResultScore(heatDeformationIssue)

	const analysisSizes = [
		sizeScore,
		WTScore,
		holesScore,
		overhangingScore,
		toleranceScore,
		cadScore,
		CNCScore,
		orientedStabilityScore,
		orientedCNCScore,
		heatDeformationIssue,
		threadsScore
	]
	const solutionWithError = checkResultsIssues(analysisSizes)

	const materialScore = getMaterialScore(
		solution,
		configuration,
		solutionWithError
	)

	const showCNCIssue =
		solution?.printerMaterial.type === materialTypes.metal &&
		solution &&
		isSurfaceAreaOn

	const showActionColumn =
		(Feature.isFeatureOn(FeatureComponentId.ACTION_COLUMN) &&
			configuration.resultType !== ConfigurationResultTypes.WeightReduction) ||
		false

	const printIssueTableHeaders = [
		[
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: getString('SELECT'),
				className: 'geometry-analysis--content--print-issues--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: getString('USER_MATERIAL_TYPE'),
				className: 'geometry-analysis--content--print-issues--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: getString('DESCRIPTION'),
				className: 'geometry-analysis--content--print-issues--title'
			})
		]
	]

	if (showActionColumn) {
		printIssueTableHeaders[0].push(
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: getString('ACTIONS'),
				className: 'geometry-analysis--content--print-issues--title'
			})
		)
	}

	const printIssueTableRows = []

	// no color
	if (shouldShowIssueRow(sizeScore, showAllErrors)) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.Size,
					color: theme.colors.notCostEffectiveColor,
					selectedValue,
					handleChange,
					disabled: true
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('PART_RESULTS_SIZE'),
				order: partSizeIssue?.printIssue.order,
				iconName: getIconNameForScore(sizeScore)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: sizeScore,
				withAdditiveMind: true,
				text: getTextForPartSize(
					configuration?.quantity,
					unitSystem,
					partSizeIssue,
					orientationSizeIssue,
					configurationSizeIssue,
					configuration?.part || configuration?.cluster,
					solution
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						partSizeIssue
					)
				]
			})
		])
	}

	if (
		!configuration?.cluster?.smallPart &&
		!configuration?.part?.smallPart &&
		printIssueTableRows.length > 0 &&
		printIssueTableRows[0][1].data.iconName === CADAnalysisResult.notPrintable
	) {
		const printIssueTableRowsWithHeaders = [
			...printIssueTableHeaders,
			...printIssueTableRows
		]

		if (!showActionColumn) {
			printIssueTableRowsWithHeaders.forEach((row: IDataTableField[]) => {
				row.splice(GA_ACTION_COLUMN_INDEX, 2)
			})
		}
		return printIssueTableRowsWithHeaders
	}

	if (shouldShowIssueRow(WTScore, showAllErrors)) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.WallThickness,
					color: `rgb(${wtColor.map(c => 360 * c).join(' ')})`,
					selectedValue,
					handleChange
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: PART_RESULTS_WALL_THICKNESS,
				order: wallThicknessIssue?.printIssue.order,
				iconName: getIconNameForScore(
					wallThicknessIssue?.score,
					wallThicknessIssue?.active
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: wallThicknessIssue?.score,
				withAdditiveMind: true,
				text: wallThicknessIssue?.message || ''
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.ButtonWithPopup,
						extraData: {
							disabled: disabledActiveColumn,
							isHover: disabledActiveColumn,
							hoverText: getString('LIGHT_USER_BUTTONS_INFORMATION'),
							className: 'details-popup--contact-us'
						},
						text: CUSTOMIZE,
						onClick: onWallThicknessClick,
						className: 'geometry-analysis--content--print-issues--table--button'
					}),
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						wallThicknessIssue
					)
				]
			})
		])
	}

	if (
		showCNCIssue &&
		shouldShowIssueRow(
			CNCScore,
			showAllErrors,
			FeatureComponentId.CNC_SUPPORT_REMOVAL
		)
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.CNC,
					color: `rgb(${cncColor.map(c => 360 * c).join(' ')})`,
					selectedValue,
					handleChange
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('PART_RESULTS_CNC'),
				order: CNCIssue?.printIssue.order,
				iconName: getIconNameForScore(CNCIssue?.score, CNCIssue?.active)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				text: CNCIssue?.message || getString('CNC_SUPPORT_REMOVAL_SUCCESS_MSG'),
				score: CNCIssue?.score,
				withAdditiveMind: true,
				className:
					'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						CNCIssue
					)
				]
			})
		])
	}

	if (
		shouldShowIssueRow(
			holesScore,
			showAllErrors,
			FeatureComponentId.HOLES_ANALYSIS
		)
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.Holes,
					color: `rgb(${holesColor.map(c => 360 * c).join(' ')})`,
					selectedValue,
					handleChange
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('HOLES'),
				order: holesIssue?.printIssue.order,
				iconName: getIconNameForScore(holesIssue?.score, holesIssue?.active)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: holesIssue?.score,
				withAdditiveMind: true,
				text: holesIssue?.message || ''
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						holesIssue
					)
				]
			})
		])
	}

	if (
		shouldShowIssueRow(
			threadsScore,
			showAllErrors,
			FeatureComponentId.THREAD_DETECTION
		)
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.Threads,
					color: `rgb(${threadsColor.map(c => 360 * c).join(' ')})`,
					selectedValue,
					handleChange
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('THREADS'),
				order: threadsIssue?.printIssue.order,
				iconName: getIconNameForScore(threadsIssue?.score, threadsIssue?.active)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: threadsIssue?.score,
				withAdditiveMind: true,
				text:
					threadsIssue?.message ||
					getTextForPartThreads(configuration?.part || configuration?.cluster)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						threadsIssue
					)
				]
			})
		])
	}

	if (
		shouldShowIssueRow(
			overhangingScore,
			showAllErrors,
			FeatureComponentId.OVERHANGING
		) &&
		solution?.printer?.hasSupportIssues
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.Overhanging,
					color: `rgb(${overhangingColor.map(c => 360 * c).join(' ')})`,
					selectedValue,
					handleChange
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('OVERHANGING'),
				order: overhangingIssue?.printIssue.order,
				iconName: getIconNameForScore(
					overhangingIssue?.score,
					overhangingIssue?.active
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: overhangingIssue?.score,
				withAdditiveMind: true,
				text: overhangingIssue?.message || ''
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						overhangingIssue
					)
				]
			})
		])
	}

	// no color
	if (
		solution?.printerMaterial.type === materialTypes.metal &&
		solution?.printerTechnology?.machiningIncluded &&
		!solution?.printerTechnology?.needSurfaceAreaMachining &&
		shouldShowIssueRow(
			orientedCNCScore,
			showAllErrors,
			FeatureComponentId.CNC_ORIENTED_SUPPORT_REMOVAL
		)
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.OrientedCNC,
					color: theme.colors.notCostEffectiveColor,
					selectedValue,
					handleChange,
					disabled: true
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('PART_RESULTS_ORIENTATION_CNC'),
				order: orientedCNCIssue?.printIssue.order,
				iconName: getIconNameForScore(
					orientedCNCScore,
					orientedCNCIssue?.active
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: orientedCNCScore,
				withAdditiveMind: true,
				text: getCNCOrientedIssueString(orientedCNCIssue)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						orientedCNCIssue
					)
				]
			})
		])
	}

	// no color
	if (
		shouldShowIssueRow(
			toleranceScore,
			showAllErrors,
			FeatureComponentId.TOLERANCES
		) &&
		solution
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.Tolerance,
					color: theme.colors.notCostEffectiveColor,
					selectedValue,
					handleChange,
					disabled: true
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('TOLERANCE'),
				order: tolerancesIssue?.printIssue.order,
				iconName: getIconNameForScore(
					tolerancesIssue?.score,
					tolerancesIssue?.active
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: tolerancesIssue?.score,
				withAdditiveMind: true,
				text: getToleranceString(
					unitSystem,
					tolerancesIssue?.score,
					configuration?.part,
					solution
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					dataTableService.RenderFieldObject({
						type: DataTableFieldType.ButtonWithPopup,
						extraData: {
							disabled: disabledActiveColumn,
							isHover: disabledActiveColumn,
							hoverText: getString('LIGHT_USER_BUTTONS_INFORMATION'),
							className: 'details-popup--contact-us'
						},
						text: TOLERANCE_UPDATE_BUTTON,
						onClick: onTolerancesClick,
						className: 'geometry-analysis--content--print-issues--table--button'
					}),
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						tolerancesIssue
					)
				]
			})
		])
	}
	if (
		shouldShowIssueRow(
			internalCavitiesScore,
			showAllErrors,
			FeatureComponentId.INTERNAL_CAVITY
		) &&
		configuration.resultType !== ConfigurationResultTypes.WeightReduction
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.InternalCavities,
					color: theme.colors.notCostEffectiveColor,
					selectedValue,
					handleChange,
					disabled: true
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('INTERNAL_CAVITIES'),
				order: internalCavitiesIssue?.printIssue.order,
				iconName: getIconNameForScore(
					internalCavitiesScore,
					internalCavitiesIssue?.active
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				text: internalCavitiesIssue?.message
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						internalCavitiesIssue
					)
				]
			})
		])
	}

	// no color
	if (
		shouldShowIssueRow(
			cadScore,
			showAllErrors,
			FeatureComponentId.CAD_FILE_INTACT
		)
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.CAD,
					color: theme.colors.notCostEffectiveColor,
					selectedValue,
					handleChange,
					disabled: true
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: PART_RESULTS_CAD,
				order: CADIssue?.printIssue.order,
				iconName: getIconNameForScore(CADIssue?.score, CADIssue?.active)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				text: CADIssue?.message || getString('MESH_HEALING_COMPLETED'),
				score: CADIssue?.score,
				withAdditiveMind: true,
				className: 'data-table-long-text-field-without-read-more'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					getCadAction(
						dataTableService,
						onMeshHealingClick,
						partHealedStlURLExist
					),
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						CADIssue
					)
				]
			})
		])
	}

	// no color
	if (shouldShowIssueRow(materialScore, showAllErrors)) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.Material,
					color: theme.colors.notCostEffectiveColor,
					selectedValue,
					handleChange,
					disabled: true
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: PART_RESULTS_MATERIAL,
				order: materialPrintIssue?.order,
				iconName: getIconNameForScore(materialScore)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				text: configuration.failReason || '',
				score: materialScore,
				withAdditiveMind: true,
				className:
					'solution-orientation--data-table--row--text data-table-long-text-field-without-read-more'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text
			})
		])
	}

	// no color
	if (
		solution?.printerMaterial.type === materialTypes.metal &&
		shouldShowIssueRow(
			heatDeformationScore,
			showAllErrors,
			FeatureComponentId.HEAT_DEFORMATION
		) &&
		configuration.resultType !== ConfigurationResultTypes.WeightReduction
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.HeatDeformation,
					color: theme.colors.notCostEffectiveColor,
					selectedValue,
					handleChange,
					disabled: true
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('PART_RESULTS_HEAT_DEFORMATION'),
				order: heatDeformationIssue?.printIssue.order,
				iconName: getIconNameForScore(
					heatDeformationIssue?.score,
					heatDeformationIssue?.active
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: heatDeformationIssue?.score,
				withAdditiveMind: true,
				text:
					heatDeformationIssue?.message ||
					getString('PART_RESULTS_HEAT_DEFORMATION_SUCCESS')
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						heatDeformationIssue
					)
				]
			})
		])
	}

	// no color
	if (
		solution?.printerTechnology?.hasStabilityIssues &&
		shouldShowIssueRow(
			orientedStabilityScore,
			showAllErrors,
			FeatureComponentId.ORIENTATION_STRUCTURAL_STABILITY
		)
	) {
		printIssueTableRows.push([
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.RadioButtonWithColor,
				extraData: {
					value: PrintIssueId.OrientationStability,
					color: theme.colors.notCostEffectiveColor,
					selectedValue,
					handleChange,
					disabled: true
				}
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.TextWithIcon,
				text: getString('PART_RESULTS_ORIENTATION_STABILITY'),
				order: printStabilityIssue?.printIssue.order,
				iconName: getIconNameForScore(
					orientedStabilityScore,
					printStabilityIssue?.active
				)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.LongText,
				score: orientedStabilityScore,
				withAdditiveMind: true,
				text: getOrientationStabilityString(printStabilityIssue)
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Buttons,
				className: cx('geometry-analysis--content--actions-wrapper', {
					'wide-buttons': isJaLocale
				}),
				nestedDataTableFields: [
					renderRiskButton(
						dataTableService,
						onPartPrintIssueChanged,
						printIssueLoaders,
						printStabilityIssue
					)
				]
			})
		])
	}

	const sortedPrintIssueTableRows = sortPrintIssueTableRows(
		printIssueTableRows,
		GA_REVIEW_SORTING_COLUMN_INDEX
	)
	const printIssueTableRowsWithHeaders = [
		...printIssueTableHeaders,
		...sortedPrintIssueTableRows
	]

	if (!showActionColumn) {
		printIssueTableRowsWithHeaders.forEach((row: IDataTableField[]) => {
			row.splice(GA_ACTION_COLUMN_INDEX, 2)
		})
	}

	return printIssueTableRowsWithHeaders
}

export function useTableData(
	configurationPrintIssues: PartPrintIssue[],
	orientationVector: number[],
	handleChange: Function,
	configuration: any,
	solution: any,
	onWallThicknessClick: Function,
	onTolerancesClick: Function,
	onMeshHealingClick: Function,
	onPartPrintIssueChanged: Function,
	printIssueLoaders: Record<string, boolean>,
	showAllErrors: boolean,
	isSurfaceAreaOn: boolean,
	partHealedStlURLExist?: boolean,
	showPrintIssue?: PrintIssueId,
	printIssues?: PrintIssue[]
) {
	const [tableData, setTableData] = useState<any[][]>([])

	useEffect(() => {
		setTableData(
			setDataTable(
				configurationPrintIssues,
				orientationVector,
				handleChange,
				configuration,
				solution,
				onWallThicknessClick,
				onTolerancesClick,
				onMeshHealingClick,
				onPartPrintIssueChanged,
				printIssueLoaders,
				isSurfaceAreaOn,
				showAllErrors,
				partHealedStlURLExist,
				showPrintIssue,
				printIssues
			)
		)
	}, [configurationPrintIssues, showPrintIssue, showAllErrors])

	return tableData
}

export function useShowNoPresentationMessage(
	printIssues: PrintIssue[],
	showOnlyPrintIssue?: PrintIssueId
) {
	const [showNoPresentationMessage, setShowNoPresentationMessage] =
		useState(false)

	useEffect(() => {
		const selectedRadioIssue = printIssues.find(
			issue => issue.id === showOnlyPrintIssue
		)

		setShowNoPresentationMessage(
			(selectedRadioIssue && !selectedRadioIssue.issuePresented) || false
		)
	}, [showOnlyPrintIssue])

	return showNoPresentationMessage
}
