import { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import FlexBox from '../FlexBox'
import InfoBox from '../InfoBox'
import { onChangeGeometryAnalysis } from './AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import AdvancedExplanation from 'Scenes/Components/AdvancedSettings/common/AdvancedExplanation'
import AdvancedFilter from 'Scenes/Components/AdvancedSettings/common/AdvancedFilter'
import CustomizePartFilteringByGeometry from 'Scenes/Home/Customize/CustomizePartFiltering/CustomizePartFilteringByGeometry'
import { IN_HOUSE_PRINTERS } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

interface AdvancedSettingsProps {
	id: string | number
	explanationArray?: Array<string>
	showTitle?: boolean
	title?: string
	extraExplanation?: string
	allowCustomize: boolean
	withGeometry?: boolean
	withFilterTitle?: boolean
	hideInHouseFilter?: boolean
}

const AdvancedSettings: FC<AdvancedSettingsProps> = ({
	id,
	explanationArray,
	showTitle,
	title,
	extraExplanation,
	allowCustomize,
	withFilterTitle = true,
	withGeometry = false,
	hideInHouseFilter = false
}) => {
	const dispatch = useDispatch()
	const [{ tempFilters, tempGeometryAnalysisPart }] = useSelector(
		(state: RootStateOrAny) => [
			state.AdvancedSettingsReducer?.advancedStates[id] ||
				new AdvancedSettingsInitialState()
		]
	)

	const updateSelectedValue = (name: string, value: boolean) => {
		dispatch(onChangeGeometryAnalysis(id, name, value))
	}

	return (
		<div className="advanced-filter">
			<AdvancedExplanation
				explanationArray={explanationArray}
				showTitle={showTitle}
				title={title}
				extraExplanation={extraExplanation}
			/>
			<FlexBox
				flexDirection="row"
				alignItems="center"
				className={cx({ 'advanced-solution-filtering': withGeometry })}
			>
				<div className="advanced-filter__section">
					{withFilterTitle && withGeometry ? (
						<p>{getString('CUSTOMIZE_SOLUTION_FILTER_TITLE')}</p>
					) : (
						<></>
					)}

					<div className="advanced-filter__bordered">
						<div className="advanced-filter__wrapper">
							{tempFilters.map((filter: any) => {
								if (
									(hideInHouseFilter && filter.name === IN_HOUSE_PRINTERS) ||
									!filter.display
								)
									return null
								return (
									<AdvancedFilter
										id={id}
										key={filter.name}
										filter={filter}
										allowCustomize={allowCustomize}
									/>
								)
							})}
						</div>
					</div>
				</div>

				{withGeometry ? (
					<div className="advanced-geometry-filter">
						<p>
							{getString('CUSTOMIZE_PART_FILTERING_BY_GEOMETRY')}{' '}
							<InfoBox
								boxContact={getString(
									'CUSTOMIZE_FILTERING_BY_GEOMETRY_EXPLANATION_ARRAY'
								)}
								iconClassName="cost-details-icon"
							/>
						</p>
						<CustomizePartFilteringByGeometry
							updateSelectedValue={updateSelectedValue}
							geometryAnalysisPart={tempGeometryAnalysisPart}
							hideSaveButton={true}
							isHeaderOn={false}
							showPartStringFiltering={true}
						/>
					</div>
				) : (
					<></>
				)}
			</FlexBox>
		</div>
	)
}

export default memo(AdvancedSettings)
