import { AnyAction } from 'redux'

import { NextFunction } from 'express'

const throttled: Record<string, any> = {}
export const throttledMiddleware =
	({ getState, dispatch }: { getState: Function; dispatch: Function }) =>
	(next: NextFunction) =>
	(action: AnyAction) => {
		const time = action?.meta?.throttle
		if (!time) return next(action)
		if (throttled[action.type]) {
			return
		}
		throttled[action.type] = true
		setTimeout(() => {
			throttled[action.type] = false
		}, time)
		next(action)
	}
