import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import MenuItem from '@material-ui/core/MenuItem'

import { getString } from '../../../Services/Strings/StringService'
import {
	Placeholders,
	sortPrinterMaterials
} from '../MaterialSelector/Common/MultipleMaterialSelector'
import TransparentButton from '../TransparentButton'
import SelectMenu from './SelectMenu'
import Flexbox from 'Scenes/Components/FlexBox'
import { Material as MaterialInteface } from 'Services/models/IMaterial'
import { getTheme } from 'themes/getTheme'

import './MaterialsSolutionSelector.scss'

const { defaultPrinterMaterial }: Record<string, any> = getTheme()

interface IProps extends Placeholders {
	materialValue: any
	amMaterialValue: any
	materialTypeValue: string
	materialCategoryValue: string
	subCategoryList: Array<any>
	categoriesList: Array<any>
	typesList: Array<any>
	hideMaterialNameSelector: boolean
	flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse'
	materialNameDisabled: boolean
	materialTypeDisabled: boolean
	materialCategoryDisabled: boolean
	printersFullData: Record<string, any>
	printerMaterials: Record<string, any>
	onMaterialsChange: (event: ChangeEvent<HTMLInputElement>) => any
	onSubCategoryChange: (event: ChangeEvent<HTMLInputElement>) => any
	onTypeChange: (event: ChangeEvent<HTMLInputElement>) => any
	onCategoryChange: (event: ChangeEvent<HTMLInputElement>) => any
	showName: boolean
}

const AmMaterialsSolutionSelector: React.FC<IProps & any> = ({
	materialValue,
	materialTypeValue,
	materialCategoryValue,
	printersFullData,
	printerMaterials,
	subCategoryList,
	categoriesList,
	typesList,
	flexDirection,
	materialNameDisabled,
	materialTypeDisabled,
	materialCategoryDisabled,
	onMaterialsChange,
	onSubCategoryChange,
	onTypeChange,
	onCategoryChange,
	showName = false
}: IProps) => {
	const {
		printerMaterials: userPrinterMaterials,
		printersFullData: userPrintersFullData
	} = useSelector((state: RootStateOrAny) => state.user)
	const [selectedMaterial, setSelectedMaterial] = useState(materialValue || {})

	printersFullData = printersFullData || userPrintersFullData
	printerMaterials = printerMaterials || userPrinterMaterials

	const allPrinters: Record<string, any>[] = []
	printersFullData.map((company: any) => {
		for (const p of company.printers) {
			allPrinters.push({ id: p.printerId, name: p.name })
		}
	})
	const materialChanged = (event: ChangeEvent<HTMLInputElement> | any) => {
		const id = event?.target?.value || event?.id
		onMaterialsChange(id)
	}
	const getMaterialArr = () => {
		let materials = printerMaterials.filter(
			(p: any) =>
				p.subCategory === selectedMaterial?.subCategory && !p.heatTreatment
		)
		materials = sortPrinterMaterials(materials)
		materials = materials.map((m: any) => {
			const printerId = JSON.parse(m.printers)
			const printerName = allPrinters.find((p: any) => p.id === printerId[0])
			return { ...m, printerName: printerName?.name || '' }
		})

		return materials
	}

	const [materialArr, setMaterialArr] = useState([])

	const [hiddenMaterialNameSelector, setHiddenMaterialNameSelector] = useState(
		!materialArr.length
	)

	useEffect(() => {
		setSelectedMaterial(materialValue)
	}, [materialValue])

	useEffect(() => {
		if (selectedMaterial?.chosenMaterialId) {
			let materials = getMaterialArr()

			const material = materials.find(
				(m: any) => m.id === selectedMaterial.chosenMaterialId
			)

			const selectedMaterialChanged = {
				...selectedMaterial,
				id: selectedMaterial.chosenMaterialId,
				printerName: material?.printerName
			}

			setSelectedMaterial(selectedMaterialChanged)
			materialChanged(selectedMaterialChanged)
			setMaterialArr(materials)
			setHiddenMaterialNameSelector(false)
		}
	}, [selectedMaterial?.chosenMaterialId])

	const subCategoryChanged = (event: any) => {
		setMaterialArr([])
		setHiddenMaterialNameSelector(true)
		onSubCategoryChange(event.target.value)
	}

	const materialTypeChanged = (event: any) => {
		setMaterialArr([])
		setHiddenMaterialNameSelector(true)
		onTypeChange(event.target.value)
	}

	const materialCategoryChanged = (event: any) => {
		setMaterialArr([])
		setHiddenMaterialNameSelector(true)
		onCategoryChange(event.target.value)
	}
	const createMenuItemTsxElement = (menuItem: any) => {
		return (
			<MenuItem
				key={menuItem}
				style={{ textTransform: 'capitalize' }}
				value={menuItem}
			>
				{menuItem}
			</MenuItem>
		)
	}
	const onSpecifyMaterial = () => {
		const materials = getMaterialArr()
		const material = materials.find(
			(m: any) => m.id === selectedMaterial.chosenMaterialId
		)

		const useDefaultMaterial =
			selectedMaterial.subCategory === defaultPrinterMaterial.subCategory

		const defaultId = useDefaultMaterial
			? defaultPrinterMaterial.id || material?.id
			: materials[0].id

		const defaultName = useDefaultMaterial
			? defaultPrinterMaterial.printerName || material?.printerName
			: materials[0].printerName

		const newSelected = {
			...selectedMaterial,
			id: selectedMaterial.id || defaultId,
			printerName: selectedMaterial.printerName || defaultName
		}

		setSelectedMaterial(newSelected)
		setMaterialArr(materials)
		setHiddenMaterialNameSelector(false)
		materialChanged(newSelected)
	}

	const renderMaterialNameSelector = () => {
		if (!hiddenMaterialNameSelector) {
			return (
				<SelectMenu
					value={selectedMaterial?.id}
					disabled={materialNameDisabled || false}
					onChange={materialChanged}
				>
					{renderMaterialMenuItems()}
				</SelectMenu>
			)
		}

		return (
			<div className="material-selector--hide-picker">
				<TransparentButton
					className="material-selector--hide-picker--button"
					onClick={() => onSpecifyMaterial()}
				>
					{getString('SPECIFY_MATERIAL')}
				</TransparentButton>
			</div>
		)
	}

	const renderAmSubCategorySelector = () => {
		return (
			<SelectMenu
				value={
					selectedMaterial?.subCategory ||
					selectedMaterial?.name ||
					selectedMaterial
				}
				disabled={materialNameDisabled || false}
				onChange={subCategoryChanged}
			>
				{renderSubCategoryMenuItems()}
			</SelectMenu>
		)
	}
	const renderAmTypeSelector = () => {
		return (
			<SelectMenu
				value={materialTypeValue}
				disabled={materialTypeDisabled || false}
				onChange={materialTypeChanged}
			>
				{typesList?.map((menuItem: any) => createMenuItemTsxElement(menuItem))}
			</SelectMenu>
		)
	}
	const renderAmCategorySelector = () => {
		return (
			<SelectMenu
				value={materialCategoryValue?.toLowerCase()}
				disabled={materialCategoryDisabled || false}
				onChange={materialCategoryChanged}
			>
				{categoriesList?.map((menuItem: any) =>
					createMenuItemTsxElement(menuItem)
				)}
			</SelectMenu>
		)
	}
	const renderMaterialMenuItems = () => {
		return materialArr?.map((material: MaterialInteface) => {
			return (
				<MenuItem key={material.id} value={material.id}>
					{material.printerName
						? `${material.name} (${material.printerName})`
						: material.name}
				</MenuItem>
			)
		})
	}

	const renderSubCategoryMenuItems = () => {
		return subCategoryList?.map((material: MaterialInteface) => {
			return (
				<MenuItem
					key={material?.subCategory || material.id}
					value={material?.subCategory || material.name}
				>
					{material?.subCategory || material.name}
				</MenuItem>
			)
		})
	}

	return (
		<Flexbox
			flexDirection={flexDirection || 'column'}
			justifyContent="space-between"
		>
			{typesList && (
				<div>
					{showName && (
						<div className="label">
							{getString('NEW_PART_CONFIGURATION_MATERIAL')}
						</div>
					)}
					{renderAmTypeSelector()}
				</div>
			)}
			{categoriesList?.length > 1 && (
				<div>
					{showName && (
						<div className="label">
							{getString('NEW_PART_CONFIGURATION_MATERIAL_DETAIL')}
						</div>
					)}
					{renderAmCategorySelector()}
				</div>
			)}
			{subCategoryList && (
				<div>
					{showName && (
						<div className="label">{getString('SPECIFY_MATERIAL')}</div>
					)}
					{renderAmSubCategorySelector()}
				</div>
			)}
			{materialArr && (
				<div>
					{showName && (
						<div className="label">{getString('SPECIFY_MATERIAL')}</div>
					)}
					{renderMaterialNameSelector()}
				</div>
			)}
		</Flexbox>
	)
}
export default memo(AmMaterialsSolutionSelector)
