import moment from 'moment'

import {
	EXPIRED_DATE_LICENSE_MSG,
	FUTURE_START_DATE_LICENSE_MSG,
	UPDATE_DATABASE_LICENSE
} from 'Services/Strings'

export interface LicenseData {
	administrator_emails: string
	allowed_administrator: number
	allowed_light_users: number
	allowed_users: number
	expire_date: string
	is_organization_by_env_scenario: boolean
	is_unlimited_light_users: boolean
	organization_name: string
	organization_owners: string
	start_date: string
	total_parts: number
}

export const getLicenseMessage = (licenseData?: LicenseData) => {
	let massageLicense = UPDATE_DATABASE_LICENSE

	if (licenseData) {
		if (
			licenseData?.expire_date &&
			moment(new Date(licenseData.expire_date))
				.endOf('day')
				.isBefore(new Date())
		) {
			massageLicense = EXPIRED_DATE_LICENSE_MSG.format(licenseData.expire_date)
		} else if (
			licenseData?.start_date &&
			moment(new Date(licenseData.start_date))
				.startOf('day')
				.isAfter(new Date())
		) {
			massageLicense = FUTURE_START_DATE_LICENSE_MSG.format(
				licenseData.start_date
			)
		}
	}

	return massageLicense
}
