import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { TextField } from '@material-ui/core'

import CustomizeCO2EmissionsPrinterMaterial from '../CO2Emissions/CustomizeCO2EmissionsPrinterMaterial'
import CustomizeCO2EmissionsRawMaterial from '../CO2Emissions/CustomizeCO2EmissionsRawMaterial'
import EmissionsPrinterMaterialTable from '../CO2Emissions/EmissionsPrinterMaterialTable'
import EmissionsRawMaterialTable from '../CO2Emissions/EmissionsRawMaterialTable'
import {
	onCreateOrUpdateMaterial,
	onCreateOrUpdatePrinterMaterial,
	onDisposeFactorChange,
	onDisposeFactorMaterialChange,
	onDisposeFactorPrinterMaterialChange,
	onSetUserCustomizationSettings
} from '../CustomizeCO2CalculationAction'
import { CO2Params } from '../CustomizeCO2CalculationEnums'
import { ParameterTypes } from '../ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const TextFieldTSX: any = TextField
const { defaultMaterial } = getTheme()

const CustomizeCO2EndOfLife: FC = () => {
	const {
		userSettingsDefaultValues,
		materialTypes,
		materialCategories,
		materials,
		printersFullData
	} = useSelector((state: RootStateOrAny) => state.user)

	const {
		disposeFactorPrinterMaterial,
		disposeFactorMaterial,
		disposeMaterialList,
		disposePrinterList,
		disposeFactorValue
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)

	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const [isEndOfLifeError, setEndOfLifeError] = useState(false)
	const { disposeFactorLoading, disposeFactor } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!disposeFactorLoading && disposeFactor !== disposeFactorValue) {
			dispatch(onDisposeFactorChange(''))
		}
	}, [disposeFactor, disposeFactorLoading])

	useEffect(() => {
		if (!disposeFactorLoading) {
			dispatch(onDisposeFactorChange(''))
		}
	}, [disposeFactorLoading])

	const disposeFactorCheck = (value: string) => {
		if (Number(value) < 0 || Number(value) > 25) {
			setEndOfLifeError(true)
		} else {
			setEndOfLifeError(false)
		}
		dispatch(onDisposeFactorChange(value))
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	const onSaveProductUse = (reset: boolean = false) => {
		if (disposeFactorValue !== '' || reset) {
			dispatch(
				onSetUserCustomizationSettings(
					reset ? null : (disposeFactorValue as any),
					ParameterTypes.disposeFactor,
					reset,
					adminSelectedOrganizationId
				)
			)
		}
	}
	const removePrinterMaterial = (id: string) => {
		const updatedPrinterMaterial = disposePrinterList.find(
			(list: Record<string, any>) => list?.material?.id === id
		)
		if (updatedPrinterMaterial) {
			dispatch(
				onCreateOrUpdatePrinterMaterial(
					{ material: updatedPrinterMaterial.material, disposeFactor: null },
					CO2Params.disposeFactor,
					adminSelectedOrganizationId
				)
			)
		}
	}
	const removeMaterial = (id: string) => {
		const updateMaterial = disposeMaterialList.find(
			(list: Record<string, any>) => list?.material?.id === id
		)
		if (updateMaterial) {
			dispatch(
				onCreateOrUpdateMaterial(
					{ material: updateMaterial.material, disposeFactor: null },
					CO2Params.disposeFactor,
					adminSelectedOrganizationId
				)
			)
		}
	}

	return (
		<>
			<div>
				<div className="custom-calculation-co2--explanation">
					{getString('CO2_CALCULATION_END_OF_LIFE_SUBTITLE')}
				</div>
				<br />
				<div className="custom-calculation-co2--wrapper">
					<div className="custom-calculation-co2--explanation-text">
						{getString('CO2_CALCULATION_END_OF_LIFE_DESCRIPTION_DEFAULT')}
					</div>
				</div>

				<Flexbox
					flexDirection="row"
					className="custom-calculation-co2--wrapper"
				>
					<Flexbox
						flexDirection="row"
						alignItems="center"
						width="100%"
						gap="13px"
					>
						<div className="custom-calculation-co2--explanation-text">
							{getString('CO2_CALCULATION_END_OF_LIFE_INPUT_TEXT')}
						</div>
						<TextFieldTSX
							className="custom-calculation-co2--end-of-life-picker"
							type="number"
							placeholder={disposeFactor}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								disposeFactorCheck(e.target.value)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							onKeyDown={handleKeyDown}
							value={disposeFactorValue}
						/>
					</Flexbox>
					{disposeFactor !== userSettingsDefaultValues.disposeFactor && (
						<ButtonWithLoader
							className="button-to-default"
							color="transparent"
							loading={disposeFactorLoading}
							onClick={() => onSaveProductUse(true)}
						>
							{getString('RETURN_TO_DEFAULT')}
						</ButtonWithLoader>
					)}
					<ButtonWithLoader
						loading={disposeFactorLoading}
						onClick={() => onSaveProductUse(false)}
						disabled={isEndOfLifeError || !disposeFactorValue}
					>
						{getString('SET')}
					</ButtonWithLoader>
				</Flexbox>
				{isEndOfLifeError && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_END_OF_LIFE_DATA_VALIDATION')}
					</div>
				)}
			</div>
			<CustomizeCO2EmissionsRawMaterial
				co2Title={getString('CO2_CALCULATION_END_OF_LIFE_DESCRIPTION_TM')}
				defaultMaterial={defaultMaterial}
				materialCategories={materialCategories}
				materialTypes={materialTypes}
				materials={materials}
				onCo2PerKgMaterialChange={(value: number) =>
					dispatch(onDisposeFactorMaterialChange(value))
				}
				setNewMaterial={(material: any) => {
					dispatch(
						onCreateOrUpdateMaterial(
							material,
							CO2Params.disposeFactor,
							adminSelectedOrganizationId
						)
					)
				}}
				value={disposeFactorMaterial}
				valueName={CO2Params.disposeFactor}
				dataQaSuffix='end-of-life'
			></CustomizeCO2EmissionsRawMaterial>
			<EmissionsRawMaterialTable
				columnName={CO2Params.disposeFactor}
				materialList={disposeMaterialList}
				deleteMaterial={(id: string) => removeMaterial(id)}
			></EmissionsRawMaterialTable>

			<CustomizeCO2EmissionsPrinterMaterial
				co2Title={getString('CO2_CALCULATION_END_OF_LIFE_DESCRIPTION_AM')}
				onCo2PerKgPrinterMaterialChange={(value: number) =>
					dispatch(onDisposeFactorPrinterMaterialChange(value))
				}
				setNewPrinterMaterial={(material: any) => {
					dispatch(
						onCreateOrUpdatePrinterMaterial(
							material,
							CO2Params.disposeFactor,
							adminSelectedOrganizationId
						)
					)
				}}
				value={disposeFactorPrinterMaterial}
				valueName={CO2Params.disposeFactor}
				printersFullData={printersFullData}
				dataQaSuffix='end-of-life'
			></CustomizeCO2EmissionsPrinterMaterial>
			<EmissionsPrinterMaterialTable
				printersList={disposePrinterList}
				columnName={CO2Params.disposeFactor}
				deleteMaterial={(id: string) => removePrinterMaterial(id)}
			></EmissionsPrinterMaterialTable>
		</>
	)
}

export default memo(CustomizeCO2EndOfLife)
