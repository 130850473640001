import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { ListItemText, MenuItem } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import cx from 'classnames'

import { Feature } from '../../../../Services/models/Features'
import {
	chooseOptionValue,
	cncPostProcesses,
	FUNCTION_EDITOR_ALERT_TYPE,
	POST_PROCESS_ACTION_BUTTON_TYPE,
	PP_CALCULATION_TYPE
} from './Constants'
import { useCustomizePostProcessFunctions } from './CustomizePostProcessesHooks'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import InfoBox from 'Scenes/Components/InfoBox'
import NumberField from 'Scenes/Components/NumberField'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { ZERO_NUMBER_FIELD_VALUE } from 'Services/Constants'
import { MaterialTypeEnum } from 'Services/models/IMaterial'
import { IPostProcess } from 'Services/models/IPostProcess'
import { IUser } from 'Services/models/IUser'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	postProcess: IPostProcess
	newInput?: boolean
}

const PostProcessRow: FC<IProps> = ({
	postProcess = {} as IPostProcess,
	newInput = false
}) => {
	const { printingTechnologies }: IUser = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { postProcessesInitialValues, allFunctionStringParams } = useSelector(
		(state: RootStateOrAny) => state.CustomizePostProcessesReducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const {
		renderPrintingTechnologyValue,
		handlePrintingTechnologyChange,
		handleAllTechnologiesClick,
		handleOnlyTechnologyClick,
		renderToggleValue,
		handleToggleChange,
		renderCostCalculationTypeValue,
		handleCostCalculationTypeChange,
		renderLeadTimeTypeValue,
		handleLeadTimeTypeChange,
		onCostCalculationOptionClick,
		getActionButtonType,
		getButtonDisabled,
		handleButtonClick,
		name,
		setName,
		costCalculationValue,
		setCostCalculationValue,
		leadTimeCalculationValue,
		setLeadTimeCalculationValue,
		getValuePlaceholder,
		getValueTitle,
		switchToEditMode
	} = useCustomizePostProcessFunctions(
		printingTechnologies,
		postProcess,
		newInput,
		postProcessesInitialValues,
		allFunctionStringParams,
		adminSelectedOrganizationId
	)

	const actionButtonType = getActionButtonType()

	const costCalculationInputDisabled =
		!postProcess.costCalculationType ||
		postProcess.costCalculationType === chooseOptionValue ||
		postProcess.costCalculationType === PP_CALCULATION_TYPE.FUNCTION ||
		(!!postProcess.id && !postProcess.editMode)

	const leadTimeCalculationInputDisabled =
		!postProcess.leadTimeCalculationType ||
		postProcess.leadTimeCalculationType === chooseOptionValue ||
		postProcess.leadTimeCalculationType === PP_CALCULATION_TYPE.FUNCTION ||
		(!!postProcess.id && !postProcess.editMode)

	return (
		<div className="customize-post-process--row" key={postProcess.id}>
			{newInput ? (
				<div className="customize-post-process--cell">
					<TextField
						data-qa={'data-qa-new-post-process-name-filed'}
						disabled={false}
						placeholder={getString('ADD_NEW_POST_PROCESS_NAME_PLACEHOLDER')}
						className={cx(
							'field-with-label outlined',
							'customize-post-process--select-field',
							'customize-post-process--number-input'
						)}
						value={name}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setName(e.target.value)
						}
					/>
				</div>
			) : (
				<div className="customize-post-process--cell name">
					<span
						title={getString(postProcess.labelName) || postProcess.name}
						className={cx({ 'no-wrap': postProcess.custom })}
					>
						{getString(postProcess.labelName) || postProcess.name}
					</span>
					{postProcess.info && (
						<InfoBox
							boxContact={getString(postProcess.info)}
							boxContactClassName="solution-feature-post-processes--info"
							boxDirection="right-end"
						/>
					)}
				</div>
			)}
			<div className="customize-post-process--cell">
				<Select
					displayEmpty
					disabled={!newInput && !postProcess.editMode}
					className="customize-post-process--select-field"
					value={postProcess?.on}
					renderValue={selected => renderToggleValue(postProcess?.on)}
					onChange={(event: any) => handleToggleChange(event.target.value)}
					onClick={switchToEditMode}
					title={renderToggleValue(postProcess?.on)}
					classes={{
						select: 'customize-post-process--select'
					}}
					inputProps={{
						name: 'simpleSelect',
						id: 'simple-select'
					}}
					MenuProps={{
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
				>
					{postProcess.onOptions?.map(option => {
						return (
							<MenuItem
								key={option.stringKey}
								style={{ textTransform: 'capitalize' }}
								value={option.value as any}
							>
								{getString(option.stringKey)}
							</MenuItem>
						)
					})}
				</Select>
			</div>
			<div className="customize-post-process--cell">
				<Select
					displayEmpty
					disabled={!newInput && !postProcess.editMode}
					className="customize-post-process--select-field"
					value={postProcess.costCalculationType}
					renderValue={renderCostCalculationTypeValue}
					onChange={(event: any) =>
						handleCostCalculationTypeChange(event.target.value)
					}
					onClick={switchToEditMode}
					title={renderCostCalculationTypeValue(
						postProcess.costCalculationType
					)}
					classes={{
						select: 'customize-post-process--select'
					}}
					inputProps={{
						name: 'simpleSelect',
						id: 'simple-select'
					}}
					MenuProps={{
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
				>
					{newInput && (
						<MenuItem value={chooseOptionValue} disabled>
							{getString('CHOOSE')}
						</MenuItem>
					)}
					{postProcess.costCalculationOptions?.map(option => {
						if (option.feature && !Feature.isFeatureOn(option.feature)) {
							return null
						}
						return (
							<MenuItem
								key={option.stringKey}
								style={{ textTransform: 'capitalize' }}
								value={option.value as any}
								onClick={() =>
									onCostCalculationOptionClick(
										option.value,
										FUNCTION_EDITOR_ALERT_TYPE.COST
									)
								}
							>
								{getString(option.stringKey)}
							</MenuItem>
						)
					})}
				</Select>
			</div>
			<div className="customize-post-process--cell">
				<NumberField
					placeholder={getValuePlaceholder(
						postProcess.costCalculationType,
						costCalculationValue
					)}
					variant="outlined"
					value={costCalculationValue as number | null}
					title={getValueTitle(
						postProcess.costCalculationType,
						costCalculationValue
					)}
					onClick={switchToEditMode}
					onChangeValue={(value: any) => setCostCalculationValue(value)}
					inputClass={cx(
						'field-with-label outlined',
						'customize-post-process--select-field',
						'customize-post-process--number-input',
						{
							disabled: costCalculationInputDisabled && !costCalculationValue,
							'with-dollar':
								postProcess.costCalculationType === PP_CALCULATION_TYPE.FIXED,
							'with-percent':
								postProcess.costCalculationType === PP_CALCULATION_TYPE.PERCENT
						}
					)}
					minValue={ZERO_NUMBER_FIELD_VALUE}
					allowZero={false}
					changeOnScroll={false}
					isCommaDisabled={false}
					isDotDisabled={false}
					allowEmpty
					showArrows
					disabled={costCalculationInputDisabled}
				/>
			</div>
			<div className="customize-post-process--cell">
				<Select
					displayEmpty
					disabled={!newInput && !postProcess.editMode}
					className="customize-post-process--select-field"
					value={postProcess.leadTimeCalculationType}
					renderValue={renderLeadTimeTypeValue}
					onChange={(event: any) =>
						handleLeadTimeTypeChange(event.target.value)
					}
					onClick={switchToEditMode}
					title={renderLeadTimeTypeValue(postProcess.costCalculationType)}
					classes={{
						select: 'customize-post-process--select'
					}}
					inputProps={{
						name: 'simpleSelect',
						id: 'simple-select'
					}}
					MenuProps={{
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
				>
					{newInput && (
						<MenuItem value={chooseOptionValue} disabled>
							{getString('CHOOSE')}
						</MenuItem>
					)}
					{postProcess.leadTimeOptions?.map(option => {
						if (option.feature && !Feature.isFeatureOn(option.feature)) {
							return null
						}
						return (
							<MenuItem
								key={option.stringKey}
								style={{ textTransform: 'capitalize' }}
								value={option.value as any}
								onClick={() =>
									onCostCalculationOptionClick(
										option.value,
										FUNCTION_EDITOR_ALERT_TYPE.LEAD_TIME
									)
								}
							>
								{getString(option.stringKey)}
							</MenuItem>
						)
					})}
				</Select>
			</div>
			<div className="customize-post-process--cell">
				<NumberField
					placeholder={getValuePlaceholder(
						postProcess.leadTimeCalculationType,
						leadTimeCalculationValue
					)}
					variant="outlined"
					title={getValueTitle(
						postProcess.leadTimeCalculationType,
						leadTimeCalculationValue
					)}
					value={leadTimeCalculationValue as number | null}
					onChangeValue={(value: any) => setLeadTimeCalculationValue(value)}
					onClick={switchToEditMode}
					inputClass={cx(
						'field-with-label outlined',
						'customize-post-process--select-field',
						'customize-post-process--number-input',
						{
							disabled:
								leadTimeCalculationInputDisabled && !leadTimeCalculationValue
						}
					)}
					minValue={ZERO_NUMBER_FIELD_VALUE}
					allowZero={false}
					changeOnScroll={false}
					isCommaDisabled={false}
					isDotDisabled={false}
					allowEmpty
					showArrows
					disabled={leadTimeCalculationInputDisabled}
				/>
			</div>
			<div className="customize-post-process--cell">
				<Select
					className="customize-post-process--select-field"
					disabled={!newInput && !postProcess.editMode}
					MenuProps={{
						variant: 'menu',
						getContentAnchorEl: null,
						PopoverClasses: {
							root: 'costing-function-editor--popover-root'
						}
					}}
					classes={{
						select: 'customize-post-process--select'
					}}
					multiple
					disableUnderline={true}
					displayEmpty={true}
					value={postProcess.printingTechnologies || []}
					onChange={handlePrintingTechnologyChange}
					onClick={switchToEditMode}
					renderValue={renderPrintingTechnologyValue}
					data-qa="data-qa-printing-technology"
					title={renderPrintingTechnologyValue()}
				>
					<MenuItem value={chooseOptionValue} className="select-toggle-all">
						<Button
							onClick={handleAllTechnologiesClick}
							title={getString('SELECT_ALL_TECHNOLOGIES_HEADER')}
							style={{
								margin: '0 auto',
								padding: 'unset',
								width: 'fit-content'
							}}
							data-qa="data-qa-select-all-technologies"
						>
							{getString('SELECT_ALL_TECHNOLOGIES_HEADER')}
						</Button>
					</MenuItem>
					{printingTechnologies?.length ? (
						printingTechnologies.map(technology => {
							const checked =
								postProcess.printingTechnologies &&
								postProcess.printingTechnologies?.indexOf(technology.name) > -1

							const disabled =
								cncPostProcesses.includes(postProcess.id as number) &&
								technology.type === MaterialTypeEnum.plastic

							return (
								<MenuItem
									className={cx('select-toggle-custom')}
									key={technology.name}
									value={technology.name}
									disabled={disabled}
								>
									<CastorCheckbox checked={!!checked} />
									<ListItemText
										className="select-toggle-custom-label"
										primary={technology.userReadableName}
									/>
									<div
										className="select-toggle-only"
										onClick={(e: React.MouseEvent<HTMLDivElement>) => {
											handleOnlyTechnologyClick(e, technology.name)
										}}
									>
										{getString('SELECT_ONLY_TECHNOLOGY')}
									</div>
								</MenuItem>
							)
						})
					) : (
						<div />
					)}
				</Select>
			</div>
			<div className="customize-post-process--cell action-button">
				{actionButtonType.map(actionButton => {
					if (
						actionButton === POST_PROCESS_ACTION_BUTTON_TYPE.CANCEL &&
						postProcess.loading
					) {
						return null
					}
					return (
						<TransparentButton
							className="custom-input--action customize-post-process--button in-select-row"
							disabled={getButtonDisabled(actionButton)}
							onClick={() => handleButtonClick(actionButton)}
							loading={postProcess.loading}
							key={actionButton}
						>
							{actionButton}
						</TransparentButton>
					)
				})}
			</div>
		</div>
	)
}

export default memo(PostProcessRow)
