import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { removeUserPrinters } from './CustomizeInHousePrintersActions'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import { onInHousePrintersAlertClose } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import CustomizeInHousePrintersTable from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersTable'
import InHouseMaterialOtherSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHouseMaterialOtherSelector'
import InHousePrintersOtherSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHousePrintersOtherSelector'
import InHousePrintersSelector from 'Scenes/Home/Customize/CustomizeInHousePrinters/InHousePrintersSelector'
import {
	CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY,
	NO_INHOUSE_PRINTER_WAS_SET,
	SET
} from 'Services/Strings'

import './CustomizeInHousePrinters.scss'

const CustomizeInHousePrintersAlert: FC = () => {
	let {
		user: { printers },
		CustomizeInHousePrintersReducer: {
			userId,
			showInHousePrintersAlert,
			chosenOrganizationId,
			configurationId
		},
		MainPartAnalysisReducer: { allConfigurationsOrganizationSettings }
	} = useSelector((state: RootStateOrAny) => state)

	printers =
		configurationId && chosenOrganizationId
			? allConfigurationsOrganizationSettings[chosenOrganizationId].printers
			: printers

	const dispatch = useDispatch()

	const InHousePrintersSelectorProps = { zIndex: 99999 }

	const handleCloseInHousePrintersAlert = () => {
		onInHousePrintersAlertClose(dispatch)
	}

	const onInHousePrintersAlertCancel = () => {
		if (printers?.length) {
			dispatch(
				removeUserPrinters(userId, chosenOrganizationId, configurationId)
			)
		}
		handleCloseInHousePrintersAlert()
	}

	return (
		<CastorAlert
			headerTitle={NO_INHOUSE_PRINTER_WAS_SET}
			onCancel={onInHousePrintersAlertCancel}
			show={showInHousePrintersAlert}
			onConfirm={handleCloseInHousePrintersAlert}
			confirmOptionalText={SET}
			disabled={!printers?.length}
			alertType={AlertType.WARNING}
			alertBodyClass="in-house-printers--alert--body"
			mediumSize
		>
			<CastorFormHeader
				explanationArray={CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY}
			/>
			<InHousePrintersSelector {...InHousePrintersSelectorProps} />
			<InHousePrintersOtherSelector />
			<InHouseMaterialOtherSelector {...InHousePrintersSelectorProps} />
			<CustomizeInHousePrintersTable />
		</CastorAlert>
	)
}

export default memo(CustomizeInHousePrintersAlert)
