export const CHANGE_PROJECTS_DATA = "CHANGE_PROJECTS_DATA"
export const CHANGE_ALL_PROJECTS_DATA = "CHANGE_ALL_PROJECTS_DATA"
export const UPDATE_PROJECTS_DATA = "UPDATE_PROJECTS_DATA"
export const GET_ALL_PROJECTS_DATA = "GET_ALL_PROJECTS_DATA"
export const CLEAR_ALL_PROJECTS_DATA = "CLEAR_ALL_PROJECTS_DATA"
export const UPDATE_PROJECTS_DATA_SUCCESS = "UPDATE_PROJECTS_DATA_SUCCESS"
export const UPDATE_PROJECTS_DATA_ERROR = "UPDATE_PROJECTS_DATA_ERROR"
export const CHANGE_RADIO_BUTTON_DEEP_RESET = "CHANGE_RADIO_BUTTON_DEEP_RESET"
export const TOGGLE_USE_ORGANIZATION_CHECKBOX = "TOGGLE_USE_ORGANIZATION_CHECKBOX"
export const CHANGE_RECALCULATE_ORGANIZATION_ID = "CHANGE_RECALCULATE_ORGANIZATION_ID"

