import React, { FC, memo } from 'react'
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { getFormSyncErrors, getFormValues, reduxForm } from 'redux-form'

import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import { CalculationType } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysis'
import {
	onCalculateClick,
	onPrioritiesFeatureClick,
	onPrioritizeFieldChange,
	onPriorityRemove
} from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import SolutionConfigurePrioritizeForm from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionConfigure/SolutionConfigurePrioritizeForm'
import { useSolutionReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import { IConfiguration } from 'Services/models/IConfiguration'
import { MATERIAL_PROPERTIES_IMPORTANCE } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface ConfigurationPrioritizeFormProps {
	configuration: IConfiguration
	filterValues: {
		[key: string]: boolean | number
	}
	filtersErrors: {
		[key: string]: any
	}
	form: string
}

let ConfigurationPrioritizeForm: FC<ConfigurationPrioritizeFormProps> = ({
	configuration,
	filterValues,
	filtersErrors
}) => {
	const dispatch = useDispatch()
	const {
		solutionPriorities,
		showSolutionPriorities,
		solution,
		solutionName,
		batchSize,
		tempSolutionPostProcessToggles,
		printCostQuantity,
		printingOrientationVector,
		printingOrientationCalc,
		simpleConfiguration,
		simpleConfigurationSelectorPrinterValue,
		simpleConfigurationSelectorMaterialValue,
		simpleInhouseConfiguration,
		isAmOriginalMaterial
	} = useSolutionReducer(configuration.id)

	const { part, partId, projectId, project, clusterId, tourConfigurationId } =
		useSelector((state: RootStateOrAny) => {
			return state?.MainPartAnalysisReducer || {}
		})
	const {
		allOptionalPostProcessesData,
		optionalPostProcessesBreakDown,
		optionalPostProcessAvailability,
		userCurrencySign,
		priorities
	} = useSelector((state: RootStateOrAny) => {
		return state?.user
	})

	const toleranceIncluded = project && project.toleranceIncluded
	return (
		<div className="add-new-configuration--form">
			<div className="grey-block property">
				<SolutionConfigurePrioritizeForm
					isNewDesign={false}
					headerText={MATERIAL_PROPERTIES_IMPORTANCE}
					className="solution-analysis-object-content-configure-widget"
					configurationId={configuration.id}
					onPriorityRemove={(id, priority, solutionPriorities) =>
						dispatch(onPriorityRemove(id, priority, solutionPriorities))
					}
					priorities={priorities}
					solutionPriorities={solutionPriorities}
					showSolutionPriorities={showSolutionPriorities}
					onPrioritizeFieldChange={(
						id,
						priorityName,
						priorityValue,
						solutionPriorities
					) =>
						dispatch(
							onPrioritizeFieldChange(
								id,
								priorityName,
								priorityValue,
								solutionPriorities
							)
						)
					}
					onPrioritiesFeatureClick={() =>
						dispatch(onPrioritiesFeatureClick(configuration.id))
					}
				/>
			</div>
			<div className="solution-priorities--buttons">
				<ButtonWithLoader
					qaDataElementName="data-qa-solution-map-recalculate-btn"
					rounded
					className="part-analysis-header-button"
					color="primary"
					loading={false}
					onClick={async () => {
						await dispatch(
							onCalculateClick(
								configuration.id,
								solution,
								partId,
								projectId,
								Object.fromEntries(solutionPriorities),
								filterValues,
								filtersErrors,
								solutionName,
								configuration.solution && configuration.solution.id,
								configuration.material,
								null,
								batchSize,
								tempSolutionPostProcessToggles,
								allOptionalPostProcessesData,
								optionalPostProcessesBreakDown,
								optionalPostProcessAvailability,
								toleranceIncluded,
								clusterId,
								false,
								part,
								userCurrencySign,
								printCostQuantity,
								isAmOriginalMaterial,
								printingOrientationVector,
								printingOrientationCalc,
								false,
								simpleConfiguration,
								simpleConfigurationSelectorPrinterValue,
								simpleConfigurationSelectorMaterialValue,
								simpleInhouseConfiguration,
								undefined,
								tourConfigurationId,
								CalculationType.SOLUTION_MAP
							)
						)
					}}
				>
					{getString('RECALCULATE')}
				</ButtonWithLoader>
			</div>
		</div>
	)
}

export default memo(
	compose(
		connect((state: any, props: any) => {
			const filterValues = getFormValues(
				`solutionConfigureFilter_${props.configuration.id}`
			)(state)
			const filtersErrors = getFormSyncErrors(
				`solutionConfigureFilter_${props.configuration.id}`
			)(state)
			return {
				form: `solutionConfigureFilter_${props.configuration.id}`,
				filterValues,
				filtersErrors
			}
		}),
		reduxForm<any, any>({
			enableReinitialize: true,
			destroyOnUnmount: false
		})
	)(ConfigurationPrioritizeForm)
)
