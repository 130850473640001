import { getString } from 'Services/Strings/StringService'

export interface IFieldsValidation {
	name: string
	min: number
	minMaxErrorMsg: string
	max: number
	secondaryMin: number
}

export const fieldsValidation = [
	{
		name: 'puAnnualKgCO2',
		min: 0,
		secondaryMin: 0,
		minMaxErrorMsg: getString('VALUE_NUMBER_BETWEEN'),
		max: 1000
	},
	{
		name: 'puYearsCO2',
		min: 0,
		secondaryMin: 0,
		minMaxErrorMsg: getString('VALUE_NUMBER_BETWEEN'),
		max: 20
	},
	{
		name: 'disposeFactor',
		min: 0,
		secondaryMin: 0,
		minMaxErrorMsg: getString('VALUE_NUMBER_BETWEEN'),
		max: 25
	},
	{
		name: 'operationCostPerHour',
		min: 1,
		secondaryMin: 1,
		minMaxErrorMsg: getString('VALUE_NUMBER_BETWEEN'),
		max: 999
	}
]

export const AM = 'am'
export const MOLD = 'mold'

export const postProcessesConstants = {
	surfaceAreaRemoval: 'surfaceAreaRemoval',
	supportRemoval: 'supportRemoval',
}