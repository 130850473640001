import { toNumber } from 'lodash'

import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../global actions/types/CastorAlertTypes'
import { SOLUTION_PRESSED } from '../../../global actions/types/partAnalysisTypes'
import { CADAnalysisResult } from '../../../Services/models/CADAnalysisResult'
import { Part, WeightReductionType } from '../../../Services/models/IPart'
import { ProjectWeightReductionStatus } from '../../../Services/models/IProject'
import {
	benefitType,
	ISolutionBenefit
} from '../../../Services/models/ISolutionBenefit'
import { WeightReductionGen2Type } from '../../../Services/models/IWeightReductionGen2'
import { weightReductionGen2Request } from '../../../Services/Network/ProjectAnalysisNetwork'
import { getString } from '../../../Services/Strings/StringService'
import { getPartWeight } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostAnalysisTable/CostAnalysisTableHelper'
import { UnitSystem } from 'Services/UnitsConversionService'

export const getWeightReducedValue = (
	partVolume: number,
	partReductionVolume: number = 0,
	unitSystem: UnitSystem,
	weightReductionDensity?: number | null
) => {
	if (!weightReductionDensity) return ''
	const percentageVolume = getWeightReductionRate(
		partVolume,
		partReductionVolume
	)

	const diffWeightReducedVolume =
		partVolume * weightReductionDensity * (toNumber(percentageVolume) / 100)

	return getPartWeight(diffWeightReducedVolume.toString(), unitSystem)
}

export const getWeightReductionRate = (
	partVolume: number,
	partReductionVolume: number = 0
) => {
	return (100 * ((partVolume - partReductionVolume) / partVolume)).toFixed(1)
}

export const partHaveWeightBenefit = (benefits: ISolutionBenefit[]): boolean =>
	!!benefits.find(benefit => benefit.type === benefitType.weightReduction)

export const isPartLegalWeightReducedSuggestion = (part: Part): boolean =>
	(part.result === CADAnalysisResult.printable ||
		part.result === CADAnalysisResult.borderline) &&
	part.weightReductionType === WeightReductionType.SUITABLE_FOR_WR

export const isPartWeightReduced = (part: Part): boolean =>
	part.weightReductionType === WeightReductionType.COMPLEX_WEIGHT_REDUCED ||
	part.weightReductionType === WeightReductionType.FAST_WEIGHT_REDUCED

export const getWeightReductionRequestText = (
	weightReductionStatus: ProjectWeightReductionStatus,
	weightReductionNumber: number
) => {
	switch (weightReductionStatus) {
		case ProjectWeightReductionStatus.noWeightReductionFound:
			return getString('WEIGHT_REDUCTION_NOT_FOUND_TXT')
		case ProjectWeightReductionStatus.awaitingAnalysis:
			return getString('WEIGHT_REDUCTION_REQUESTED_TXT')
		case ProjectWeightReductionStatus.complete:
			return getString('COMBINE_MULTIPLE_INTO_ONE_CARD_COMPLETED_TXT').format(
				weightReductionNumber
			)

		default:
			return getString('WEIGHT_REDUCTION_CARD_REQ_TXT')
	}
}

export const handleWeightReductionState = async (
	configurationId: number,
	dispatch: any
) => {
	await weightReductionGen2Request(
		configurationId,
		WeightReductionGen2Type.COMPLEX
	)

	dispatch({
		type: SOLUTION_PRESSED,
		payload: { id: configurationId, show: true }
	})

	dispatch({
		type: ALERT_POPPED,
		payload: {
			headerTitle: getString('WEIGHT_REDUCTION_FEA_REQ_SUCCEED_HEADER'),
			confirmText: getString('OK'),
			text: getString('WEIGHT_REDUCTION_FEA_SUCCEEDED'),
			showCancel: false,
			onConfirm: () => {
				dispatch({ type: ALERT_POPUP_CANCELED })
			}
		}
	})
}
