import React, { FC, memo } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import {
	PART_ANALYSIS_NON_PRINTABLE_BUTTON,
	PART_ANALYSIS_NON_PRINTABLE_DESCRIPTION
} from 'Services/Strings'

const ButtonTSX: any = Button

interface TopRetrieveResultsProps {
	onRetrieveResultClick: Function
	configurationId: number | string
}

const TopRetrieveResults: FC<TopRetrieveResultsProps> = ({
	onRetrieveResultClick,
	configurationId
}) => {
	return (
		<Flexbox
			flexDirection="row"
			alignItems="center"
			className="castor-detail cost-details-row center"
		>
			<div className="solution-header-no-configuration">
				{PART_ANALYSIS_NON_PRINTABLE_DESCRIPTION}
			</div>
			<ButtonTSX
				color="primary"
				onClick={(e: React.MouseEvent<HTMLDivElement>) => onRetrieveResultClick(e, configurationId)}
				className="solution-header-no-configuration-button"
				data-qa="data-qa-retrieve-from-configuration-btn"
			>
				{PART_ANALYSIS_NON_PRINTABLE_BUTTON}
			</ButtonTSX>
		</Flexbox>
	)
}

export default memo(TopRetrieveResults)