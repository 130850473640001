import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CustomizeAnalysisCostImage from './CustomizeAnalysisCostImage'
import CustomizeAnalysisCostTable from './CustomizeAnalysisCostTable'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import { getCostAnalysisData } from 'Scenes/Home/Customize/CustomizeAnalysisCost/CustomizeAnalysisCostActions'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const CustomizeAnalysisCost: FC = () => {
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getCostAnalysisData(adminSelectedOrganizationId))
	}, [adminSelectedOrganizationId, dispatch])

	return (
		<div className="cost-analysis-parameters">
			<CastorFormHeader
				explanationHeader={getString('COST_ANALYSIS_CALCULATION_PARAMETERS')}
				explanationArray={getString('COSTING_PARAMETERS_EXPLANATION')}
				isInCard={true}
			/>
			<CustomizeAnalysisCostImage />
			<CustomizeAnalysisCostTable />
		</div>
	)
}
export default WithFeatureToggleHOC(
	memo(CustomizeAnalysisCost),
	FeatureComponentId.COST_ANALYSIS_PARAMETERS
)
