import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isArray } from 'lodash'

import {
	CO2_CARBON_CREDIT_COST_CHANGED,
	CO2_HOLD_FACTOR_CHANGED,
	CO2_NEW_MATERIAL_ADDED,
	CO2_NEW_PRINTER_MATERIAL_ADDED,
	CO2_NEW_PRINTER_TYPICAL_KWH_ADDED,
	CO2_NEW_TM_TYPICAL_KWH_ADDED,
	CO2_PER_KG_MATERIAL_CHANGED,
	CO2_PER_KG_PRINTER_MATERIAL_CHANGED,
	CO2_PER_KW_CHANGED,
	CO2_PRINTER_TYPICAL_KWH_DELETED,
	CO2_TM_TYPICAL_KWH_DELETED,
	CO2_USER_SETTINGS_CARBON_CHANGED,
	CO2_USER_SETTINGS_CARBON_CREDIT_COST,
	CO2_USER_SETTINGS_DATA_LOADING,
	CO2_USER_SETTINGS_DISPOSE,
	CO2_USER_SETTINGS_HOLD,
	CO2_USER_SETTINGS_PU,
	DISPOSE_FACTOR_MATERIAL_CHANGED,
	DISPOSE_FACTOR_NEW_MATERIAL_ADDED,
	DISPOSE_FACTOR_NEW_PRINTER_MATERIAL_ADDED,
	DISPOSE_FACTOR_PRINTER_MATERIAL_CHANGED,
	EMISSION_TBL_DATA_ARRANGED,
	END_OF_LIFE_DISPOSE_FACTOR_CHANGED,
	GET_CO2_MATERIAL,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	PART_LIFE_TIME_CHANGED,
	PRINTER_TYPICAL_KWH_CHANGED,
	TM_TYPICAL_KWH_CHANGED,
	YEARLY_SAVED_AMOUNT_CHANGED
} from '../../../../global actions/types'
import { CO2Params } from './CustomizeCO2CalculationEnums'
import { ParameterTypes } from './ParameterTypes'
import {
	createNewPrinterMaterialCo2Calculation,
	createNewPrinterTypicalKwh,
	createOrUpdateMaterialCo2Calculation,
	deleteCycleTypicalKwh,
	getCustomizationSettings,
	getCycleCo2Calculation,
	getMaterialCo2Calculation,
	getPrinterMaterialCo2Calculation,
	getPrinterTypicalKwh,
	getTypicalKwhList,
	updateCustomizationPrinterSetting,
	updateTmTypicalKwh,
	updateUserCarbonCreditCost,
	updateUserCo2HoldFactor,
	updateUserCo2PerKW,
	updateUserEndOfLifeDisposeFactor,
	updateUserProductUse
} from 'Services/Network'
import { REQUEST_FAILED, SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const loading = 'Loading'
const resetLoading = 'Reset'

export const fetchUserCo2Data = (organizationId?: number) => {
	return async (dispatch: any) => {
		try {
			dispatch({ type: HANDLE_LOADER, payload: 1 })
			const materials = await getMaterialCo2Calculation(organizationId)
			const printers = await getPrinterMaterialCo2Calculation(organizationId)
			const printersTypicalkwhList = await getPrinterTypicalKwh(organizationId)
			const cycleList = await getCycleCo2Calculation()
			const typicalKwhList = await getTypicalKwhList(organizationId)
			const customizationSettings = await getCustomizationSettings(
				organizationId
			)
			const settingsData = customizationSettings?.data

			dispatch({
				type: GET_CO2_MATERIAL,
				payload: {
					disposeMaterialList: materials?.data.disposeMaterialList,
					disposePrinterList: printers?.data.disposePrintersList,
					materialList: materials?.data.materialList,
					printersList: printers?.data.printersList,
					cycleList: cycleList?.data.cycleList,
					typicalKwhList: typicalKwhList?.data.typicalKwhList,
					co2PerKW: settingsData?.userSettings?.co2PerKW,
					printersTypicalkwhList:
						printersTypicalkwhList?.data?.printersTypicalkwhList
				}
			})
			dispatch({
				type: EMISSION_TBL_DATA_ARRANGED,
				payload: {
					settingsData,
					userSettings: settingsData?.userSettings
				}
			})
			dispatch({ type: HANDLE_LOADER, payload: -1 })
		} catch (error) {
			dispatch({ type: HANDLE_LOADER, payload: -1 })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: 'error in fetchUserCo2Data'
				}
			})
		}
	}
}

export const onDisposeFactorMaterialChange = (value: number) => {
	return {
		type: DISPOSE_FACTOR_MATERIAL_CHANGED,
		payload: { value }
	}
}

export const onDisposeFactorPrinterMaterialChange = (value: number) => {
	return {
		type: DISPOSE_FACTOR_PRINTER_MATERIAL_CHANGED,
		payload: { value }
	}
}

export const onCo2PerKgMaterialChange = (value: number) => {
	return {
		type: CO2_PER_KG_MATERIAL_CHANGED,
		payload: { value }
	}
}

export const onPrinterTypicalkwhChange = (value: number | string) => {
	return {
		type: PRINTER_TYPICAL_KWH_CHANGED,
		payload: { value }
	}
}

export const onTmTypicalkwhChange = (value: number | string) => {
	return {
		type: TM_TYPICAL_KWH_CHANGED,
		payload: { value }
	}
}

export const onCo2PerKgPrinterMaterialChange = (value: number) => {
	return {
		type: CO2_PER_KG_PRINTER_MATERIAL_CHANGED,
		payload: { value }
	}
}

export const onCo2PerKwChange = (name: string, value: number | string) => {
	return {
		type: CO2_PER_KW_CHANGED,
		payload: { name, value }
	}
}
export const onPuAnnualKgCO2Change = (value: number) => {
	return {
		type: YEARLY_SAVED_AMOUNT_CHANGED,
		payload: { value }
	}
}
export const onPuYearsCO2Change = (value: number) => {
	return {
		type: PART_LIFE_TIME_CHANGED,
		payload: { value }
	}
}
export const onDisposeFactorChange = (value: number | string) => {
	return {
		type: END_OF_LIFE_DISPOSE_FACTOR_CHANGED,
		payload: { value }
	}
}
export const onHoldFactorChange = (value: number | string) => {
	return {
		type: CO2_HOLD_FACTOR_CHANGED,
		payload: { value }
	}
}
export const onCarbonCreditCostChange = (value: number | string) => {
	return {
		type: CO2_CARBON_CREDIT_COST_CHANGED,
		payload: { value }
	}
}

export const onSetUserCustomizationSettings = (
	value: number,
	parameter: string,
	reset: boolean = false,
	organizationId?: number
) => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: CO2_USER_SETTINGS_DATA_LOADING,
				payload: {
					[`${parameter}${reset ? resetLoading : ''}${loading}`]: true
				}
			})

			switch (parameter) {
				case ParameterTypes.puYearsCO2:
				case ParameterTypes.puAnnualKgCO2: {
					await updateUserProductUse(value, parameter, organizationId)
					dispatch({
						type: CO2_USER_SETTINGS_PU,
						payload: { value, name: parameter }
					})
					break
				}
				case ParameterTypes.disposeFactor: {
					const {
						data: { userSettings: { disposeFactor = value } = {} } = {}
					} = await updateUserEndOfLifeDisposeFactor(value, organizationId)
					dispatch({
						type: CO2_USER_SETTINGS_DISPOSE,
						payload: { value: disposeFactor }
					})
					break
				}
				case ParameterTypes.holdFactor: {
					const { data: { userSettings: { holdFactor = value } = {} } = {} } =
						await updateUserCo2HoldFactor(value, organizationId)

					dispatch({
						type: CO2_USER_SETTINGS_HOLD,
						payload: { value: holdFactor }
					})
					break
				}
				case ParameterTypes.carbonCreditCost: {
					const {
						data: { userSettings: { carbonCreditCost = value } = {} } = {}
					} = await updateUserCarbonCreditCost(value, organizationId)

					dispatch({
						type: CO2_USER_SETTINGS_CARBON_CREDIT_COST,
						payload: { value: carbonCreditCost }
					})
					break
				}
			}

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
			dispatch({
				type: CO2_USER_SETTINGS_DATA_LOADING,
				payload: {
					[`${parameter}${reset ? resetLoading : ''}${loading}`]: false
				}
			})
		} catch (error) {
			dispatch({
				type: CO2_USER_SETTINGS_DATA_LOADING,
				payload: {
					[`${parameter}${reset ? resetLoading : ''}${loading}`]: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: REQUEST_FAILED
				}
			})
		}
	}
}

export const onCreateOrUpdateMaterial = (
	newMaterial: Record<string, any>,
	printerMaterialParam: CO2Params = CO2Params.co2PerKg,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		let messageType

		switch (printerMaterialParam) {
			case CO2Params.disposeFactor:
				messageType = DISPOSE_FACTOR_NEW_MATERIAL_ADDED
				break
			default:
				messageType = CO2_NEW_MATERIAL_ADDED
				break
		}

		try {
			await createOrUpdateMaterialCo2Calculation(newMaterial, organizationId)
			dispatch({
				type: messageType,
				payload: { newMaterial }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onDeletePrinterTypicalKwh = (
	printerToDelete: any,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const userPrinterResponse = await updateCustomizationPrinterSetting(
				printerToDelete,
				organizationId
			)
			const printersTypicalkwhList =
				userPrinterResponse.data.userPrinterSettingsList
			dispatch({
				type: CO2_PRINTER_TYPICAL_KWH_DELETED,
				payload: { printersTypicalkwhList }
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: 'error in onDeletePrinterTypicalKwh'
				}
			})
		}
	}
}

export const onDeleteCycleKwh = (
	cycleToDelete: any,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await deleteCycleTypicalKwh(cycleToDelete, organizationId)
			dispatch({
				type: CO2_TM_TYPICAL_KWH_DELETED,
				payload: { cycleToDelete }
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: 'error in onDeleteCycleKwh'
				}
			})
		}
	}
}

export const onCreateOrUpdatePrinterMaterial = (
	newPrinterMaterial: Record<string, any>,
	printerMaterialParam: CO2Params = CO2Params.co2PerKg,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		let messageType

		switch (printerMaterialParam) {
			case CO2Params.disposeFactor:
				messageType = DISPOSE_FACTOR_NEW_PRINTER_MATERIAL_ADDED
				break
			default:
				messageType = CO2_NEW_PRINTER_MATERIAL_ADDED
				break
		}

		try {
			await createNewPrinterMaterialCo2Calculation(
				newPrinterMaterial,
				organizationId
			)
			dispatch({
				type: messageType,
				payload: { newPrinterMaterial }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onSetNewPrinterTypicalkwh = (
	newPrinter: any,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const newPrinterTypicalKwhResponse = await createNewPrinterTypicalKwh(
				newPrinter,
				organizationId
			)
			const printersTypicalkwhList =
				newPrinterTypicalKwhResponse.data.userPrinterSettingsList
			dispatch({
				type: CO2_NEW_PRINTER_TYPICAL_KWH_ADDED,
				payload: { printersTypicalkwhList }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onUpdateTypicalkwhTm = (cycle: any, organizationId?: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await updateTmTypicalKwh(cycle, organizationId)
			const { cycleResult } = response.data
			dispatch({
				type: CO2_NEW_TM_TYPICAL_KWH_ADDED,
				payload: { cycle: isArray(cycleResult) ? cycleResult[0] : cycleResult }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onUpdateCo2CarbonTypicalKwh = (
	co2PerKW: Record<string, number>,
	reset: boolean = false,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await updateUserCo2PerKW(co2PerKW, reset, organizationId)
			if (response.data.userSettings) {
				dispatch({
					type: CO2_USER_SETTINGS_CARBON_CHANGED,
					payload: { value: response.data.userSettings.co2PerKW }
				})

				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: getString('DATA_WAS_UPDATED')
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.WARN,
						notificationMessage: getString('SOMETHING_WENT_WRONG')
					}
				})
			}
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}
