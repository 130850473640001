import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'

import * as CustomizeActions from '../../CustomizeActions'
import { getSelectedMaterial } from '../../CustomizeMaterialNamesMapping/CastorMaterialNamesMappingService'
import { CO2Params } from '../CustomizeCO2CalculationEnums'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorMaterialSelector from 'Scenes/Components/CastorMaterialSelector/CastorMaterialSelector'
import Flexbox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { Material } from 'Services/models/IMaterial'
import {
	CO2_PER_KG_DATA_VALIDATION,
	CO2_PER_KG_PLACE_HOLDER
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface IProps {
	materialCategories: any
	materialTypes: string[]
	materials: Material[]
	defaultMaterial: Material
	onCo2PerKgMaterialChange: Function
	setNewMaterial: Function
	value: number | string
	valueName?: string
	co2Title?: string
	dataQaSuffix?: string
}

interface IReduxStore {
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2EmissionsRawMaterial: FC<IProps> = ({
	materialCategories,
	materialTypes,
	materials,
	defaultMaterial,
	onCo2PerKgMaterialChange,
	setNewMaterial,
	value,
	valueName = CO2Params.co2PerKg,
	co2Title,
	dataQaSuffix = ''
}) => {
	const [selectedMaterial, setSelectedMaterial] = useState<
		Material | undefined
	>(defaultMaterial)
	const [isError, setIsError] = useState(false)

	useEffect(() => {
		return () => {
			onCo2PerKgMaterialChange('')
		}
	}, [])

	const onChangeSelector = (property: string, value: any) => {
		const _selectedMaterial = getSelectedMaterial(
			materials,
			materialCategories,
			property,
			value
		)
		setSelectedMaterial(_selectedMaterial)
	}
	const onCo2PerKgChange = (value: string) => {
		if (Number(value) <= 0) {
			setIsError(true)
		} else {
			setIsError(false)
			onCo2PerKgMaterialChange(value)
		}
	}

	const onSetClick = () => {
		setNewMaterial({
			material: selectedMaterial,
			[valueName]: value
		})
		setSelectedMaterial(defaultMaterial)
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	return (
		<>
			<div className="custom-calculation-co2--explanation">
				{co2Title || getString('CUSTOMIZE_CO2_EMISSIONS_TITLE')}
			</div>
			<Flexbox
				flexDirection="row"
				className="custom-calculation-co2--wrapper"
			>
				<Flexbox flexDirection="row" alignItems="center" data-qa={`data-qa-row-material-section-${dataQaSuffix}`} >
					<CastorMaterialSelector
						onChangeMaterial={onChangeSelector}
						materialCategories={materialCategories}
						materials={materials}
						disabled={false}
						materialTypes={materialTypes}
						selectedMaterial={selectedMaterial}
						dataQaSuffix={dataQaSuffix}
					/>
					<div className="material-picker">
						<TextFieldTSX
							className="custom-calculation-co2--material-picker"
							type="number"
							placeholder={CO2_PER_KG_PLACE_HOLDER}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								onCo2PerKgChange(e.target.value)
							}}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							onKeyDown={handleKeyDown}
							value={value}
							error={isError}
						/>
					</div>
				</Flexbox>
				<ButtonWithLoader
					className="custom-material-names--AddButton"
					onClick={onSetClick}
					disabled={isEmpty(value) || isError}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</Flexbox>
			{isError && (
				<div className="custom-calculation-co2--error">
					{CO2_PER_KG_DATA_VALIDATION}
				</div>
			)}
		</>
	)
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2EmissionsRawMaterial)
)
