import { FC, memo, useCallback, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import AdvancedSettings from 'Scenes/Components/AdvancedSettings'
import {
	changeAdvancedFilters,
	resetAdvancedFilters,
	saveAdvancedFilters,
	setupAdvancedFilters
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { UPLOAD } from 'Scenes/Home/NewUploadProject/constants'
import { useMaterial } from 'Scenes/Home/NewUploadProject/UploadBlocks/UploadBlocksHooks'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

interface UploadAdvancedSettingsProps {
	showAdvancedSettingsAlert: boolean
	setShowAdvancedSettingsAlert: (value: boolean) => void
}

let UploadAdvancedSettings: FC<UploadAdvancedSettingsProps> = ({
	setShowAdvancedSettingsAlert,
	showAdvancedSettingsAlert
}) => {
	const showDefaultMaterialFilters = Feature.isFeatureOn(
		FeatureComponentId.DEFAULT_MATERIAL_FILTERS
	)
	const { projectOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const { organizationDetails, printers, isAdminByRole, userDetails } =
		useSelector((state: RootStateOrAny) => state.user)
	const [defaultChosenMaterial] = useMaterial()

	const { isError } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[UPLOAD] ||
			new AdvancedSettingsInitialState()
	)
	const dispatch = useDispatch()
	const disableInHouseFilter =
		!isAdminByRole &&
		!printers.length &&
		((projectOrganizationId &&
			organizationDetails.id !== projectOrganizationId) ||
			!userDetails.organization_owner)

	useEffect(() => {
		dispatch(
			setupAdvancedFilters(
				UPLOAD,
				undefined,
				undefined,
				undefined,
				disableInHouseFilter
			)
		)
		if (showDefaultMaterialFilters) {
			dispatch(
				changeAdvancedFilters(
					UPLOAD,
					defaultChosenMaterial?.defaultFilters,
					true
				)
			)
		}
	}, [projectOrganizationId])

	useEffect(() => {
		if (showDefaultMaterialFilters) {
			dispatch(
				changeAdvancedFilters(
					UPLOAD,
					defaultChosenMaterial?.defaultFilters,
					true
				)
			)
		}
	}, [defaultChosenMaterial])

	const handleAlertConfirm = useCallback(() => {
		dispatch(saveAdvancedFilters(UPLOAD))
		setShowAdvancedSettingsAlert(false)
	}, [dispatch, setShowAdvancedSettingsAlert])

	const resetValues = () => {
		dispatch(resetAdvancedFilters(UPLOAD))
		setShowAdvancedSettingsAlert(false)
	}
	const geometryAnalysisFilterOn = Feature.isFeatureOn(
		FeatureComponentId.GEOMETRY_ANALYSIS_FILTER
	)

	return (
		<>
			<CastorAlert
				headerTitle={getString('ADVANCED_MATERIAL_SETTINGS')}
				onCancel={() => resetValues()}
				show={showAdvancedSettingsAlert}
				onConfirm={handleAlertConfirm}
				confirmOptionalText={getString('SET')}
				fullScreen={geometryAnalysisFilterOn}
				mediumSize={!geometryAnalysisFilterOn}
				disabled={isError}
				alertClass={cx({ 'advanced-filters': geometryAnalysisFilterOn })}
			>
				<AdvancedSettings
					withGeometry={!!geometryAnalysisFilterOn}
					id={UPLOAD}
					allowCustomize={false}
					showTitle={false}
					explanationArray={getString('ADD_FILTER_FIELDS_DESCRIPTION')}
				/>
			</CastorAlert>
		</>
	)
}

export default memo(UploadAdvancedSettings)
