import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'

import { TextField } from '@material-ui/core'

import ButtonWithLoader from '../ButtonWithLoader'
import DetailsPopup from '../DetailsPopup'
import FeatureSection from '../FeatureSection'
import InfoBox from '../InfoBox'
import ItemsWithPicturesList, {
	IItemWithPicture
} from '../ItemsWithPicturesList'
import IconFactory from '../StarIcon/IconFactory'
import TransparentButton from '../TransparentButton'
import Flexbox from 'Scenes/Components/FlexBox'
import { isEnterKeyPressed } from 'Services/getKeyCodesService'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectWeightReductionStatus } from 'Services/models/IProject'
import { allWeightReductionView } from 'Services/routeFuncs'
import { SUBMIT_REQUEST } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './WeightReduction.scss'

const TextFieldTSX: any = TextField

interface IProps {
	requested: boolean
	loading: boolean
	updateWeightReductionButtonLoading: boolean
	requestText: string
	projectWeightReductionStatus: string
	weightReductionItemsWithPictures: IItemWithPicture[]
	project: any
	projectWeightReductionMinimumThicknessThreshold: number
	projectWeightReductionThreshold: number
	onWeightReductionThresholdChanged: Function
	onWeightReductionMinimumThicknessThresholdChanged: Function
	sendWeightReductionRequest: Function
	onUpdateWeightReductionButtonClick: () => void
	reducePartWeightClick: Function
}

const WeightReduction: FC<IProps> = ({
	requested,
	requestText,
	weightReductionItemsWithPictures,
	project,
	projectWeightReductionStatus,
	projectWeightReductionThreshold,
	projectWeightReductionMinimumThicknessThreshold,
	onWeightReductionThresholdChanged,
	onWeightReductionMinimumThicknessThresholdChanged,
	sendWeightReductionRequest,
	loading,
	onUpdateWeightReductionButtonClick,
	updateWeightReductionButtonLoading,
	reducePartWeightClick
}) => {
	const [weightRductionInputError, setWeightRductionInputError] =
		useState(false)
	const [
		weightReductionMinimumThicknessInputError,
		setWeightReductionMinimumThicknessInputError
	] = useState(false)

	const [disableSubmit, setDisableSubmit] = useState(false)
	const disabledWeightReduction = !Feature.isFeatureActive(
		FeatureComponentId.WEIGHT_REDUCTION
	)
	useEffect(() => {
		const weightRductionError =
			projectWeightReductionThreshold < 1 ||
			projectWeightReductionThreshold > 100
		if (weightRductionError) {
			setWeightRductionInputError(true)
			setDisableSubmit(true)
		} else {
			setWeightRductionInputError(false)
		}
		if (projectWeightReductionMinimumThicknessThreshold < 0.01) {
			setWeightReductionMinimumThicknessInputError(true)
			setDisableSubmit(true)
		} else {
			setWeightReductionMinimumThicknessInputError(false)
			if (!weightRductionError) {
				setDisableSubmit(false)
			}
		}
	}, [
		projectWeightReductionThreshold,
		projectWeightReductionMinimumThicknessThreshold
	])

	const renderWeightReductionThreshold = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION)) {
			return <></>
		}

		return (
			<>
				<span>{getString('WEIGHT_REDUCTION')}:</span>
				<TextFieldTSX
					disabled={disabledWeightReduction}
					className="weight-reduction-section--subheader--input--wrapper"
					value={projectWeightReductionThreshold}
					type="number"
					onKeyUp={(e: KeyboardEvent) => {
						if (isEnterKeyPressed(e) && !disableSubmit) {
							onUpdateWeightReductionButtonClick()
						}
					}}
					onWheel={(event: ChangeEvent<HTMLInputElement>) =>
						event.target.blur()
					}
					inputProps={{
						className: `weight-reduction-section--subheader--input ${
							weightRductionInputError ? 'input-error' : ''
						}`,
						onChange: (e: ChangeEvent<HTMLInputElement>) =>
							onWeightReductionThresholdChanged(e.target.value),
						min: 1,
						max: 100
					}}
				/>
				%.
			</>
		)
	}

	const renderUpdateWeightReduction = () => {
		if (
			projectWeightReductionStatus ===
				ProjectWeightReductionStatus.awaitingAnalysis ||
			!project?.reductions ||
			!Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION) ||
			(project?.weightReductionThreshold <= 1 &&
				!weightReductionItemsWithPictures.length)
		) {
			return <div></div>
		}

		return (
			<div className="weight-reduction-section--subheader--not-requested">
				{renderWeightReductionThreshold()}
				<DetailsPopup
					isHover={disabledWeightReduction}
					data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper details-popup--contact-us"
					popperContactClassName="info-box-data"
				>
					<TransparentButton
						loaderWrapperClassName="weight-reduction-section--subheader--update--loader"
						className="weight-reduction-section--subheader--update"
						loading={updateWeightReductionButtonLoading}
						onClick={onUpdateWeightReductionButtonClick}
						disabled={disableSubmit || disabledWeightReduction}
					>
						{getString('UPDATE_USER_PROFILE')}
					</TransparentButton>
				</DetailsPopup>
			</div>
		)
	}

	const renderSubHeaderSection = () => {
		if (!requested) {
			return (
				<div className="weight-reduction-section--subheader--not-requested not-requested">
					<div>{renderWeightReductionThreshold()} </div>
					<div>
						<span>{getString('MINIMUM_THICKNESS')}:</span>
						<TextFieldTSX
							className="weight-reduction-section--subheader--input--wrapper"
							value={projectWeightReductionMinimumThicknessThreshold}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							type="number"
							inputProps={{
								className: `weight-reduction-section--subheader--input ${
									weightReductionMinimumThicknessInputError ? 'input-error' : ''
								}`,
								onChange: (e: ChangeEvent<HTMLInputElement>) =>
									onWeightReductionMinimumThicknessThresholdChanged(
										e.target.value
									),
								min: 0.01
							}}
						/>
						mm.
					</div>
				</div>
			)
		}

		return (
			<>
				<Flexbox alignItems="center">
					<IconFactory
						iconName={projectWeightReductionStatus}
						className="weight-reduction-section--subheader--icon"
					/>
					<div className="weight-reduction-section--subheader--text">
						{requestText}
					</div>
				</Flexbox>
				{renderUpdateWeightReduction()}
			</>
		)
	}

	const renderSectionAction = () => {
		if (requested) {
			return <div />
		}
		return (
			<div className="weight-reduction-section--button--wrapper">
				<ButtonWithLoader
					loading={loading}
					disabled={disableSubmit}
					className="weight-reduction-section--button"
					color="primary"
					onClick={sendWeightReductionRequest}
				>
					{SUBMIT_REQUEST}
				</ButtonWithLoader>
			</div>
		)
	}
	const renderSectionContent = () => {
		if (weightReductionItemsWithPictures?.length) {
			return (
				<DetailsPopup
					isHover={disabledWeightReduction}
					data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
					popperDirection="top-start"
					popperClassName="info-box-wrapper details-popup--contact-us"
					popperContactClassName="info-box-data"
					targetClassName="all-weight-reduction--popup"
				>
					<ItemsWithPicturesList
						disabledWeight={disabledWeightReduction}
						items={weightReductionItemsWithPictures}
						seeMoreURL={allWeightReductionView(project?.id)}
						isWeightReduction={true}
					/>
				</DetailsPopup>
			)
		}

		if (requested) {
			return <div />
		}
		return requestText
	}

	const renderSectionHeader = () => {
		return (
			<div className="feature-section--header__with-icon">
				<span>{getString('WEIGHT_REDUCTION_OPPORTUNITIES')}</span>
				<div>
					<InfoBox
						inPortal={true}
						boxDirection="right-start"
						boxContact={getString('WEIGHT_REDUCTION_OPPORTUNITIES_HELPER')}
						iconClassName="admin-form-field__info_icon"
					/>
				</div>
			</div>
		)
	}

	return (
		<div
			id={FeatureComponentId.WEIGHT_REDUCTION}
			className="weight-reduction-feature"
		>
			<FeatureSection
				sectionHeader={renderSectionHeader()}
				sectionSubHeader={renderSubHeaderSection()}
				sectionAction={renderSectionAction()}
				sectionContent={renderSectionContent()}
			/>
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(WeightReduction),
	FeatureComponentId.WEIGHT_REDUCTION
)
