import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CustomizeCO2EmissionsPrinterMaterial from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Emissions/CustomizeCO2EmissionsPrinterMaterial'
import CustomizeCO2EmissionsRawMaterial from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Emissions/CustomizeCO2EmissionsRawMaterial'
import EmissionsPrinterMaterialTable from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Emissions/EmissionsPrinterMaterialTable'
import EmissionsRawMaterialTable from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Emissions/EmissionsRawMaterialTable'
import {
	onCo2PerKgMaterialChange,
	onCo2PerKgPrinterMaterialChange,
	onCreateOrUpdateMaterial,
	onCreateOrUpdatePrinterMaterial
} from 'Scenes/Home/Customize/CustomizeCO2Calculation/CustomizeCO2CalculationAction'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

const MaterialProduction: FC = () => {
	const { printersFullData, materials, materialTypes, materialCategories } =
		useSelector((state: RootStateOrAny) => state.user)
	const {
		printersList,
		materialList,
		co2PerKgMaterial,
		co2PerKgPrinterMaterial
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const removePrinterMaterial = (id: string) => {
		const updatedPrinterMaterial = printersList.find(
			(list: Record<string, any>) => list?.material?.id === id
		)
		if (updatedPrinterMaterial) {
			dispatch(
				onCreateOrUpdatePrinterMaterial(
					{
						material: updatedPrinterMaterial.material,
						co2PerKg: null
					},
					undefined,
					adminSelectedOrganizationId
				)
			)
		}
	}

	const removeMaterial = (id: string) => {
		const updateMaterial = materialList.find(
			(list: Record<string, any>) => list?.material?.id === id
		)

		if (updateMaterial) {
			dispatch(
				onCreateOrUpdateMaterial(
					{
						material: updateMaterial.material,
						co2PerKg: null
					},
					undefined,
					adminSelectedOrganizationId
				)
			)
		}
	}

	const dispatch = useDispatch()

	return (
		<div
			className="material-production--wrapper"
			data-qa="data-qa-material-production-section"
		>
			<div className="material-production--header">
				{getString('CUSTOMIZE_CO2_MATERIAL_PRODUCTION')}
			</div>
			<CustomizeCO2EmissionsRawMaterial
				defaultMaterial={defaultMaterial}
				materialCategories={materialCategories}
				materialTypes={materialTypes}
				materials={materials}
				onCo2PerKgMaterialChange={(value: number) =>
					dispatch(onCo2PerKgMaterialChange(value))
				}
				setNewMaterial={(newMaterial: any) =>
					dispatch(
						onCreateOrUpdateMaterial(
							newMaterial,
							undefined,
							adminSelectedOrganizationId
						)
					)
				}
				value={co2PerKgMaterial}
				dataQaSuffix='material-production'
			></CustomizeCO2EmissionsRawMaterial>
			<EmissionsRawMaterialTable
				materialList={materialList}
				deleteMaterial={(materialId: string) => removeMaterial(materialId)}
			></EmissionsRawMaterialTable>
			<CustomizeCO2EmissionsPrinterMaterial
				onCo2PerKgPrinterMaterialChange={(value: number) =>
					dispatch(onCo2PerKgPrinterMaterialChange(value))
				}
				setNewPrinterMaterial={(newPrinterMaterial: any) =>
					dispatch(
						onCreateOrUpdatePrinterMaterial(
							newPrinterMaterial,
							undefined,
							adminSelectedOrganizationId
						)
					)
				}
				value={co2PerKgPrinterMaterial}
				printersFullData={printersFullData}
				dataQaSuffix='material-production'
			></CustomizeCO2EmissionsPrinterMaterial>
			<EmissionsPrinterMaterialTable
				printersList={printersList}
				deleteMaterial={(materialId: string) =>
					removePrinterMaterial(materialId)
				}
			></EmissionsPrinterMaterialTable>
		</div>
	)
}

export default MaterialProduction
