import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { TextField } from '@material-ui/core'

import {
	onHoldFactorChange,
	onSetUserCustomizationSettings
} from '../CustomizeCO2Calculation/CustomizeCO2CalculationAction'
import { ParameterTypes } from '../CustomizeCO2Calculation/ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import Flexbox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface CustomizeCO2HoldingProps {}

const CustomizeCO2Holding: FC<CustomizeCO2HoldingProps> = () => {
	const [isHoldFactorError, setHoldFactorError] = useState(false)

	const {
		holdFactor,
		holdFactorLoading,
		holdFactorResetLoading,
		userSettingsDefaultValues
	} = useSelector((state: RootStateOrAny) => state.user)

	const { holdFactorValue } = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const dispatch = useDispatch()

	useEffect(() => {
		if (!holdFactorLoading && holdFactor !== holdFactorValue) {
			dispatch(onHoldFactorChange(''))
		}
	}, [holdFactor, holdFactorLoading])

	useEffect(() => {
		setHoldFactorError(Number(holdFactorValue) < 0)
	}, [holdFactorValue])

	const onSaveHoldFactor = (reset: boolean = false) => {
		if (holdFactorValue !== '' || reset) {
			dispatch(
				onSetUserCustomizationSettings(
					reset ? null : holdFactorValue,
					ParameterTypes.holdFactor,
					reset,
					adminSelectedOrganizationId
				)
			)
		}
	}

	const holdFactorCheck = (value: string) => {
		setHoldFactorError(Number(value) < 0)

		dispatch(onHoldFactorChange(value))
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	return (
		<Flexbox
			className="material-production--wrapper product-use"
			flexDirection="column"
			alignItems="flex-start"
			width="100%"
		>
			<CastorFormHeader
				explanationArray={getString(
					'EMISSION_COMPARISON_HOLDING_EXPLANATION_ARRAY'
				)}
				isInCard={true}
			/>
			<Flexbox flexDirection="row" className="custom-calculation-co2--wrapper">
				<Flexbox
					flexDirection="row"
					alignItems="center"
					width="100%"
					gap="13px"
				>
					<div className="custom-calculation-co2--explanation-text">
						{getString('EMISSION_COMPARISON_HOLDING_TITLE')}
					</div>
					<TextFieldTSX
						className="custom-calculation-co2--hold-picker"
						data-qa="data-qa-holding-input"
						type="number"
						placeholder={holdFactor}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							holdFactorCheck(e.target.value)
						}}
						onWheel={(event: ChangeEvent<HTMLInputElement>) =>
							event.target.blur()
						}
						onKeyDown={handleKeyDown}
						value={holdFactorValue}
					/>
				</Flexbox>
				{holdFactor !== userSettingsDefaultValues.holdFactor && (
					<ButtonWithLoader
						color="transparent"
						className="button-to-default"
						loading={holdFactorResetLoading}
						onClick={() => onSaveHoldFactor(true)}
					>
						{getString('RETURN_TO_DEFAULT')}
					</ButtonWithLoader>
				)}
				<ButtonWithLoader
					qaDataElementName="data-qa-holding-set-btn"
					loading={holdFactorLoading}
					onClick={() => onSaveHoldFactor(false)}
					disabled={!holdFactorValue || isHoldFactorError}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</Flexbox>
			{isHoldFactorError && (
				<div
					className="custom-calculation-co2--error"
					style={{ width: '100%' }}
				>
					{getString('CO2_PER_KG_DATA_VALIDATION')}
				</div>
			)}
		</Flexbox>
	)
}

export default memo(
	WithFeatureToggleHOC(CustomizeCO2Holding, FeatureComponentId.CO2_EMISSIONS)
)
