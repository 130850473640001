import { ChangeEvent, FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import { changeHolesNotify } from '../CustomizeSolutionFilters/CustomizeThreadActions'
import CustomizePartNameCheckbox from './CustomizePartNameCheckbox'
import {
	customizeGeometryAnalysisSelectOptions,
	customizeGeometryAnalysisStrings,
	GeometryAnalysisPartEnum
} from './PartFilteringService'
import SelectContent from 'Scenes/Components/SelectContent'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	isHeaderOn?: boolean
	showPartStringFiltering?: boolean
	hideSaveButton?: boolean
	geometryAnalysisPart: Record<GeometryAnalysisPartEnum, boolean>
	updateSelectedValue?: (name: string, value: boolean) => void
}

export const CustomizePartFilteringByGeometry: FC<IProps> = ({
	isHeaderOn = true,
	hideSaveButton = false,
	geometryAnalysisPart,
	updateSelectedValue,
	showPartStringFiltering
}) => {
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const {
		tubeAnalyze,
		sheetMetalAnalyze,
		fastenersAnalyze,
		excludePartSubstrings
	} = geometryAnalysisPart

	const dispatch = useDispatch()

	return (
		<div>
			{isHeaderOn ? (
				<CastorFormHeader
					explanationHeader={getString('CUSTOMIZE_PART_FILTERING_BY_GEOMETRY')}
					explanationArray={getString(
						'CUSTOMIZE_FILTERING_BY_GEOMETRY_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
			) : (
				<></>
			)}

			<div className="customize-solution-filter__threads--wrapper geometry">
				<div className="customize-solution-filter__threads">
					<SelectContent
						updateSelectedValue={value => {
							updateSelectedValue?.(
								GeometryAnalysisPartEnum.sheetMetalAnalyze,
								value
							)
						}}
						value={!!sheetMetalAnalyze}
						hideSaveButton={hideSaveButton}
						title={getString(
							customizeGeometryAnalysisStrings.sheetMetalAnalyze
						)}
						selectOptions={customizeGeometryAnalysisSelectOptions}
						onUpdate={(value: boolean) =>
							dispatch(
								changeHolesNotify(
									GeometryAnalysisPartEnum.sheetMetalAnalyze,
									value,
									adminSelectedOrganizationId
								)
							)
						}
					/>
					<SelectContent
						updateSelectedValue={value => {
							updateSelectedValue?.(
								GeometryAnalysisPartEnum.fastenersAnalyze,
								value
							)
						}}
						value={!!fastenersAnalyze}
						hideSaveButton={hideSaveButton}
						title={getString(customizeGeometryAnalysisStrings.fastenersAnalyze)}
						selectOptions={customizeGeometryAnalysisSelectOptions}
						onUpdate={(value: boolean) =>
							dispatch(
								changeHolesNotify(
									GeometryAnalysisPartEnum.fastenersAnalyze,
									value,
									adminSelectedOrganizationId
								)
							)
						}
					/>
					<SelectContent
						updateSelectedValue={value => {
							updateSelectedValue?.(GeometryAnalysisPartEnum.tubeAnalyze, value)
						}}
						value={!!tubeAnalyze}
						hideSaveButton={hideSaveButton}
						title={getString(customizeGeometryAnalysisStrings.tubeAnalyze)}
						selectOptions={customizeGeometryAnalysisSelectOptions}
						onUpdate={(value: boolean) =>
							dispatch(
								changeHolesNotify(
									GeometryAnalysisPartEnum.tubeAnalyze,
									value,
									adminSelectedOrganizationId
								)
							)
						}
					/>
					{showPartStringFiltering ? (
						<CustomizePartNameCheckbox
							excludePartSubstrings={excludePartSubstrings}
							updateSelectedValue={(value: boolean) =>
								updateSelectedValue?.(
									GeometryAnalysisPartEnum.excludePartSubstrings,
									value
								)
							}
						/>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(CustomizePartFilteringByGeometry),
	FeatureComponentId.GEOMETRY_ANALYSIS_FILTER
)
