import { lowerCase } from 'lodash'

import { isLoggedInFail } from '../../../../global actions'
import {
	ADD_MULTIPLE_USER_MATERIALS_BOM_FILE,
	ADD_MULTIPLE_USER_MATERIALS_BOM_LOADING,
	ADD_MULTIPLE_USER_MATERIALS_BOM_POPUP,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	MATERIAL_ADDED_TO_USER,
	MATERIAL_REMOVED_FROM_USER,
	NEW_ITEM_ADD_CALLED,
	NEW_ITEM_ADD_FAILED,
	NEW_MATERIAL_FORM_LOADED,
	NEW_PRINTER_ADDED_TO_USER,
	SETUP_USER_PRINTER_MATERIAL_FORM_SUCCESS,
	USER_MACHINE_COST_FIELDS_SELECTOR_CHANGED,
	USER_MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED,
	USER_MATERIALS_DELETE_CANCELED,
	USER_MATERIALS_DELETE_CLICKED,
	USER_MATERIALS_MATERIAL_ADD_FAILED,
	USER_MATERIALS_MATERIAL_ADDED,
	USER_MATERIALS_MATERIAL_ADDED_TO_USER,
	USER_MATERIALS_MATERIAL_REMOVED,
	USER_MATERIALS_MATERIAL_SUBMITED,
	USER_MATERIALS_MATERIAL_TYPE_CHANGED,
	USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE,
	USER_MATERIALS_RECIEVED,
	USER_MATERIALS_RESET_DATA,
	USER_MATERIALS_USER_ID_SELECTED,
	USER_MATERIALS_USER_MATERIALS_FORMATED,
	USER_PRINTER_ADDED,
	USER_PRINTER_INITIAL_VALUES_HAD_SET,
	USER_PRINTER_MATERIAL_REMOVED_FROM_USER,
	USER_PRINTER_MATERIALS_MATERIAL_REMOVED,
	USER_PRINTER_NEW_COMPANY_SELECTED,
	USER_PRINTER_REMOVED_FROM_USER,
	USER_PRINTERS_ADDED,
	USER_PRINTERS_MATERIAL_REMOVED,
	USER_PRINTERS_RECIEVED,
	USER_PRINTERS_USER_ID_SELECTED
} from '../../../../global actions/types'
import {
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import { store } from '../../../../index'
import {
	PRINTER_MATERIAL_NAME_ALREADY_EXIST_ERROR,
	PRINTER_NAME_ALREADY_EXIST_ERROR,
	USER_MATERIAL_NAME_ALREADY_EXIST_ERROR
} from '../../../../Services/Constants'
import {
	addMaterialToUser,
	addPrinterToUserBySetting,
	getAllPrintersMaterialsAdmin,
	getAllUserMaterials,
	getAllUserPrinterFromUser,
	getAllUserPrinterMaterialsFromUser,
	getAllUserPrinters,
	getPrinterMaterialCategories,
	removeMaterialFromUser,
	removeUserPrinterFromUser,
	removeUserPrinterMaterialsFromUser,
	uploadMultipleUserMaterialsFile
} from '../../../../Services/Network'
import {
	OK,
	SHOW_NOTIFICATION,
	USER_MATERIAL_ADDED,
	USER_MATERIAL_DELETE_ALERT_BODY,
	USER_MATERIAL_DELETE_ALERT_TITLE,
	USER_MATERIAL_DELETE_FAILED,
	USER_PRINTER_DELETE_ALERT_BODY,
	USER_PRINTER_DELETE_ALERT_TITLE,
	USER_PRINTER_MATERIAL_DELETE_ALERT_BODY,
	USER_PRINTERS_NAME_ALREADY_EXIST_ERROR,
	USER_PRINTERS_UPDATE_ERROR
} from '../../../../Services/Strings'
import { AlertType } from '../../../Components/alerts/AlertTypes'
import { printerToCompany } from 'Scenes/admin/AdminHome/AdminPrinterMaterials/AdminPrinterMaterialService'
import { getString } from 'Services/Strings/StringService'

export const setupUserMaterials = match => {
		if (match.params['userId']) {
			return getUserMaterials(parseInt(match.params['userId']))
		} else {
			return formatUserMaterials()
		}
	},
	resetData = () => {
		return {
			type: USER_MATERIALS_RESET_DATA
		}
	},
	getUserMaterials = userId => {
		return async dispatch => {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			dispatch({
				type: USER_MATERIALS_USER_ID_SELECTED,
				payload: userId
			})
			try {
				const userMaterialsResponse = await getAllUserMaterials(userId)
				const userPrintersResponse = await getAllUserPrinterFromUser(userId)
				const userPrinterMaterialsResponse =
					await getAllUserPrinterMaterialsFromUser(userId)

				const userMaterials = userMaterialsResponse.data.userMaterials
				const userPrinters = userPrintersResponse.data.printers
				const userPrinterMaterials =
					userPrinterMaterialsResponse.data.printerMaterials

				dispatch({
					type: USER_MATERIALS_RECIEVED,
					payload: {
						userMaterials: userMaterials,
						userPrinters: userPrinters,
						userPrinterMaterials: userPrinterMaterials
					}
				})
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
			} catch (error) {
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				console.error(error)
			}
		}
	},
	onUserMaterialAdd = (
		material,
		userId,
		materialCategories,
		organizationId
	) => {
		return async dispatch => {
			dispatch({
				type: USER_MATERIALS_MATERIAL_SUBMITED
			})
			try {
				material.category = lowerCase(material.category)
				material.wallThickness = materialCategories.find(
					category => lowerCase(category.name) === material.category
				).wallThickness
				const response = await addMaterialToUser(
					material,
					userId,
					organizationId
				)
				try {
					if (userId) {
						dispatch({
							type: USER_MATERIALS_MATERIAL_ADDED_TO_USER,
							payload: response.data.userMaterials
						})
					} else {
						const { userMaterials } = store.getState().user
						const addedMaterial = response.data.userMaterials.filter(
							({ id }) =>
								!userMaterials.some(({ id: existId }) => existId === id)
						)

						dispatch({
							type: MATERIAL_ADDED_TO_USER,
							payload: {
								userMaterials: response.data.userMaterials,
								material: addedMaterial
							}
						})
					}
					dispatch({
						type: USER_MATERIALS_MATERIAL_ADDED
					})

					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.SUCCESS,
							notificationMessage: USER_MATERIAL_ADDED
						}
					})
				} catch (error) {
					console.log(error)
					isLoggedInFail(dispatch, error)
				}
			} catch (error) {
				console.log(error)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							error.code === PRINTER_MATERIAL_NAME_ALREADY_EXIST_ERROR
								? getString('PRINTER_MATERIAL_NAME_EXISTS_ERROR')
								: error.code === USER_MATERIAL_NAME_ALREADY_EXIST_ERROR
								? getString('USER_MATERIAL_NAME_EXISTS_ERROR')
								: error.validationMessage
					}
				})
				dispatch({
					type: USER_MATERIALS_MATERIAL_ADD_FAILED
				})
			}
		}
	},
	onDeleteClick = (itemToDelete, userId, organizationId) => {
		return dispatch => {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: USER_MATERIAL_DELETE_ALERT_TITLE,
					confirmText: OK,
					text: USER_MATERIAL_DELETE_ALERT_BODY,
					onConfirm: () =>
						removeUserMaterial(itemToDelete, userId, dispatch, organizationId),
					alertType: AlertType.WARNING
				}
			})
			dispatch({
				type: USER_MATERIALS_DELETE_CLICKED,
				payload: itemToDelete
			})
		}
	},
	onDeleteCancel = () => {
		return {
			type: USER_MATERIALS_DELETE_CANCELED
		}
	},
	removeUserMaterial = async (material, userId, dispatch, organizationId) => {
		dispatch({
			type: USER_MATERIALS_DELETE_CANCELED
		})
		dispatch({
			type: ALERT_CALCULATION_STARTED
		})
		try {
			const response = await removeMaterialFromUser(
				material,
				userId,
				organizationId
			)
			try {
				dispatch({
					type: ALERT_POPUP_CANCELED
				})
				if (userId) {
					dispatch({
						type: USER_MATERIALS_MATERIAL_REMOVED,
						payload: response.data.materials
					})
				} else {
					const { userMaterials } = store.getState().user
					const removedMaterial = userMaterials.filter(
						({ id: existId }) =>
							!response.data.materials.some(({ id }) => existId === id)
					)

					dispatch({
						type: MATERIAL_REMOVED_FROM_USER,
						payload: {
							userMaterials: response.data.materials,
							material: removedMaterial,
							userMaterialNamesMapping: response.data.userMaterialNamesMapping
						}
					})
				}
			} catch (error) {
				console.log(error)
				isLoggedInFail(dispatch, error)
			}
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: USER_MATERIAL_DELETE_FAILED
				}
			})
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
		}
	},
	formatUserMaterials = () => {
		return {
			type: USER_MATERIALS_USER_MATERIALS_FORMATED
		}
	},
	setupNewUserMaterialForm = () => {
		return {
			type: NEW_MATERIAL_FORM_LOADED
		}
	},
	onMaterialTypeChange = (selectedType, materialCategories) => {
		return {
			type: USER_MATERIALS_MATERIAL_TYPE_CHANGED,
			payload: { selectedType, materialCategories }
		}
	},
	newCompanySelected = companySelected => {
		return {
			type: USER_PRINTER_NEW_COMPANY_SELECTED,
			payload: companySelected
		}
	},
	onMachineCostSelector = event => {
		return {
			type: USER_MACHINE_COST_FIELDS_SELECTOR_CHANGED,
			payload: { value: event.target.value }
		}
	},
	onMaintenanceMachineCostFieldsSelectorNewPrinterChange = event => {
		return {
			type: USER_MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED,
			payload: { value: event.target.value }
		}
	},
	setPrinterNameInPrinterReducer = printerName => {
		return {
			type: USER_PRINTER_INITIAL_VALUES_HAD_SET,
			payload: printerName
		}
	},
	getUserPrinters = userId => {
		return async dispatch => {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			dispatch({
				type: USER_PRINTERS_USER_ID_SELECTED,
				payload: userId
			})
			try {
				const response = await getAllUserPrinters(userId)
				const userPrinters = response.data.userPrinters
				dispatch({
					type: USER_PRINTERS_RECIEVED,
					payload: userPrinters
				})
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
			} catch (error) {
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				console.error(error)
			}
		}
	},
	createNewPrinterAndRemovePrinterName = (printer, organizationId) => {
		return async dispatch => {
			dispatch({
				type: USER_MATERIALS_MATERIAL_SUBMITED
			})
			try {
				dispatch({
					type: NEW_ITEM_ADD_CALLED
				})
				const response = await addPrinterToUserBySetting(
					printer,
					organizationId
				)
				dispatch({
					type: USER_PRINTER_ADDED,
					payload: response.data.printerAdded
				})

				dispatch({
					type: NEW_PRINTER_ADDED_TO_USER,
					payload: {
						userPrinters: response.data.printers
					}
				})

				dispatch({
					type: USER_PRINTERS_ADDED
				})

				dispatch({
					type: ALERT_POPPED,
					payload: {
						text: getString('NEW_PRINTER_ADDED_ALERT_BODY'),
						headerTitle: getString('NEW_PRINTER_ADDED_ALERT_HEADER'),
						alertType: AlertType.SUCCESS,
						showCancel: false,
						onConfirm: () => {
							dispatch({
								type: ALERT_POPUP_CANCELED
							})
						},
						confirmText: OK
					}
				})
			} catch (error) {
				console.log(error)
				dispatch({
					type: NEW_ITEM_ADD_FAILED
				})

				const errorMessage =
					error?.code === PRINTER_NAME_ALREADY_EXIST_ERROR
						? USER_PRINTERS_NAME_ALREADY_EXIST_ERROR
						: error?.validationMessage || USER_PRINTERS_UPDATE_ERROR

				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: errorMessage
					}
				})
			}
		}
	},
	setupPrinterMaterialForm = organizationId => {
		return async dispatch => {
			dispatch({ type: HANDLE_LOADER, payload: 1 })
			try {
				const printerResult = await getAllPrintersMaterialsAdmin(organizationId)
				const categoriesResult = await getPrinterMaterialCategories(
					organizationId
				)
				const allPrintersCompanies = printerToCompany(
					printerResult.data.printers
				)

				dispatch({
					type: SETUP_USER_PRINTER_MATERIAL_FORM_SUCCESS,
					payload: {
						allPrintersCompanies,
						allCategories: categoriesResult.data.printerMaterialsCategories,
						allSubCategories:
							categoriesResult.data.printerMaterialsSubCategories,
						categoriesAverageCO2: categoriesResult.data.categoriesAverageCO2
					}
				})
				dispatch({ type: HANDLE_LOADER, payload: -1 })
			} catch (error) {
				console.log(error)
				dispatch({ type: HANDLE_LOADER, payload: -1 })
			}
		}
	},
	onDeletePrinterClick = (itemToDelete, userId, organizationId) => {
		return dispatch => {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: USER_PRINTER_DELETE_ALERT_TITLE,
					confirmText: OK,
					text: USER_PRINTER_DELETE_ALERT_BODY,
					onConfirm: () =>
						removeUserPrinter(itemToDelete, userId, dispatch, organizationId),
					alertType: AlertType.WARNING
				}
			})
			dispatch({
				type: USER_MATERIALS_DELETE_CLICKED,
				payload: itemToDelete
			})
		}
	},
	removeUserPrinter = async (printer, userId, dispatch, organizationId) => {
		dispatch({
			type: USER_MATERIALS_DELETE_CANCELED
		})
		dispatch({
			type: ALERT_CALCULATION_STARTED
		})
		try {
			const response = await removeUserPrinterFromUser(
				printer,
				userId,
				organizationId
			)
			try {
				dispatch({
					type: ALERT_POPUP_CANCELED
				})
				if (userId) {
					dispatch({
						type: USER_PRINTERS_MATERIAL_REMOVED,
						payload: response.data.printers
					})
				} else {
					const { userPrinters } = store.getState().user
					const removedPrinter = userPrinters.filter(
						({ id: existId }) =>
							!response.data.printers.some(({ id }) => existId === id)
					)

					dispatch({
						type: USER_PRINTER_REMOVED_FROM_USER,
						payload: {
							userPrinters: response.data.printers,
							printer: removedPrinter
						}
					})
				}
			} catch (error) {
				console.log(error)
				isLoggedInFail(dispatch, error)
			}
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: USER_MATERIAL_DELETE_FAILED
				}
			})
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
		}
	},
	onDeletePrinterMaterialClick = (itemToDelete, userId, organizationId) => {
		return dispatch => {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: USER_MATERIAL_DELETE_ALERT_TITLE,
					confirmText: OK,
					text: USER_PRINTER_MATERIAL_DELETE_ALERT_BODY,
					onConfirm: () =>
						removeUserPrinterMaterial(
							itemToDelete,
							userId,
							dispatch,
							organizationId
						),
					alertType: AlertType.WARNING
				}
			})
			dispatch({
				type: USER_MATERIALS_DELETE_CLICKED,
				payload: itemToDelete
			})
		}
	},
	uploadMaterialsNameBom = file => {
		return async dispatch => {
			dispatch({
				type: ADD_MULTIPLE_USER_MATERIALS_BOM_FILE,
				payload: file
			})
		}
	},
	openMaterialsNamePopup = isOpen => {
		return async dispatch => {
			dispatch({
				type: ADD_MULTIPLE_USER_MATERIALS_BOM_POPUP,
				payload: isOpen
			})
		}
	},
	sendMaterialsNameBom = (sendFile, organizationId) => {
		return async dispatch => {
			if (!sendFile) {
				return
			}
			dispatch({
				type: ADD_MULTIPLE_USER_MATERIALS_BOM_LOADING,
				payload: true
			})
			dispatch({
				type: ADD_MULTIPLE_USER_MATERIALS_BOM_FILE,
				payload: null
			})

			let reader = new FileReader()
			reader.readAsDataURL(sendFile)
			reader.onload = async () => {
				try {
					const response = await uploadMultipleUserMaterialsFile(
						reader?.result,
						sendFile.name,
						organizationId
					)

					if (response.data) {
						dispatch({
							type: USER_MATERIALS_MULTIPLE_ADD_OR_UPDATE,
							payload: {
								userMaterials: response.data.userMaterials,
								materials: response.data.materials
							}
						})

						if (response.data.code === 200) {
							dispatch({
								type: HANDLE_NOTIFICATION,
								payload: {
									notificationType: SHOW_NOTIFICATION.SUCCESS,
									notificationMessage: getString(
										'ADD_MULTIPLE_USER_MATERIALS_SUCCESS'
									).format(response.data.amountOfUploadingMaterials)
								}
							})
						}
						if (response.data.code === 206) {
							let msg = getString(
								'ADD_MULTIPLE_USER_MATERIALS_PARTIALLY_SUCCESS'
							).format(
								response.data.amountOfUploadingMaterials,
								response.data.amountOfUploadingMaterials -
									response.data.amountOfFailedMaterials
							)

							msg += '\n\n'
							const dataMessage = response.data.msg || null

							if (dataMessage) {
								if (typeof dataMessage[0] === 'string') {
									msg += dataMessage.join('\n')
								} else {
									msg += dataMessage.map(el => JSON.stringify(el)).join('\n')
								}
							}

							const alertMessage = dataMessage
								? msg +
								  getString(
										'ADD_MULTIPLE_USER_MATERIALS_PARTIALLY_SUCCESS_FIX_EXCEL'
								  )
								: msg

							dispatch({
								type: ALERT_POPPED,
								payload: {
									text: alertMessage,
									alertType: AlertType.WARNING,
									headerTitle: getString(
										'ADD_MULTIPLE_USER_MATERIALS_ALERT_TITLE'
									),
									showCancel: false,
									onConfirm: () => {
										dispatch({
											type: ALERT_POPUP_CANCELED
										})
									},
									confirmText: OK
								}
							})
						}

						dispatch({
							type: ADD_MULTIPLE_USER_MATERIALS_BOM_LOADING,
							payload: false
						})
					}
				} catch (err) {
					console.error(err)
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.ERROR,
							notificationMessage:
								err.info ||
								err.message ||
								getString('ADD_MULTIPLE_MATERIALS_ERROR')
						}
					})
					dispatch({
						type: ADD_MULTIPLE_USER_MATERIALS_BOM_LOADING,
						payload: false
					})
				}
			}
		}
	},
	removeUserPrinterMaterial = async (
		printerMaterial,
		userId,
		dispatch,
		organizationId
	) => {
		dispatch({
			type: USER_MATERIALS_DELETE_CANCELED
		})
		dispatch({
			type: ALERT_CALCULATION_STARTED
		})
		try {
			const response = await removeUserPrinterMaterialsFromUser(
				printerMaterial,
				userId,
				organizationId
			)
			try {
				dispatch({
					type: ALERT_POPUP_CANCELED
				})
				if (userId) {
					dispatch({
						type: USER_PRINTER_MATERIALS_MATERIAL_REMOVED,
						payload: response.data.printerMaterials
					})
				} else {
					const { userPrinterMaterials } = store.getState().user
					const removedPrinterMaterial = userPrinterMaterials.filter(
						({ id: existId }) =>
							!response.data.printerMaterials.some(({ id }) => existId === id)
					)

					dispatch({
						type: USER_PRINTER_MATERIAL_REMOVED_FROM_USER,
						payload: {
							userPrinterMaterials: response.data.printerMaterials,
							printerMaterial: removedPrinterMaterial,
							printers: response.data.printers,
							printersFullData: response.data.printersFullData
						}
					})
				}
			} catch (error) {
				console.log(error)
				isLoggedInFail(dispatch, error)
			}
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: USER_MATERIAL_DELETE_FAILED
				}
			})
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
		}
	}
