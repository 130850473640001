import * as EmailValidator from 'email-validator'
import zxcvbn from 'zxcvbn'

import {
	AUTHORIZATION_GET_PROVIDERS_ERROR,
	AUTHORIZATION_GET_PROVIDERS_SUCCESS,
	AUTHORIZATION_TOKEN_UPDATED,
	CHECKBOX_CLICKED,
	CHOOSE_REGISTRATION_ORGANIZATION,
	COMPANY_CHANGED,
	CONFIRM_RESTART_PASSWORD_EMAIL_FAILED,
	EMAIL_CHANGED,
	FORGOT_PASSWORD_CHANGED,
	FORGOT_PASSWORD_POPUP_CLOSE,
	FORGOT_PASSWORD_POPUP_OPEND,
	FORGOT_REENTER_PASSWORD_CHANGED,
	GET_ORGANIZATION_LICENSE_INFO,
	GOT_AUTH_FEATURES,
	HIDE_ALERT,
	HIDE_FORGOT_ALERT,
	HIDE_PASSWORD_REQUEST_ALERT,
	HIDE_RESET_PASSWORD_ERROR_ALERT,
	HIDE_VERIFY_USER_ALERT,
	LOGIN_PASSWORD_CHANGED,
	LOGIN_USER,
	LOGIN_USER_FAIL,
	LOGIN_USER_SUCCESS,
	NAME_CHANGED,
	REENTER_PASSWORD_CHANGED,
	REFRESH_TOKEN_UPDATED,
	REGISTER_USER,
	REGISTER_USER_FAIL,
	REGISTER_USER_SUCCESS,
	RESET_AUTH_STATE,
	RESET_PASSWORD_FAIL,
	RESET_TOKEN_SAVED,
	RESISTER_PASSWORD_CHANGED,
	SEND_RESET_REQUEST_CONFIRMED,
	SEND_RESET_REQUEST_FAIL,
	SETUP_AVAILABLE_ORGANIZATIONS,
	SHOW_EMAIL_SENDING_SUCCESS_POPUP,
	SHOW_FORGOT_PASSWORD_STRENGTH_ALERT,
	SHOW_PASSWORD_STRENGTH_ALERT,
	SHOW_RESET_PASSWORD_SUCCESS_POP_UP,
	SKIP_LOGIN_PROVIDER,
	USER_LOGOUT,
	USER_NOT_LOGGED_IN
} from '../../global actions/types'
import {
	getStringItemFromLocalStorage,
	removeAllLocalStorage,
	setJsonItemToLocalStorage
} from '../../Services/LocalStorageService'
import {
	PASSWORD_STENGTH_EXPLINATION_BODY,
	PASSWORD_STENGTH_EXPLINATION_TITLE
} from '../../Services/Strings'
import { EMAIL, LOGIN_LS_ERRORS, LOGIN_LS_FIELDS } from 'Services/Constants'
import history from 'Services/history'
import { getUserLanguage } from 'Services/Utils/startupTools'

const INITIAL_STATE = {
	email: '',
	password: '',
	reenterPassword: '',
	forgotPassword: '',
	forgotReenterPassword: '',
	resetToken: '',
	name: '',
	company: '',
	loggedIn: false,
	error: '',
	resetRequestError: '',
	restartPasswordError: '',
	resetEmailRequestSuccess: false,
	renderVerifyUser: false,
	resetPasswordSucceeded: false,
	loading: false,
	loadedPage: false,
	agreedToTerms: false,
	strengthScore: null,
	emailValid: null,
	showingSimpleAlertText: null,
	showingRenderVerifyUser: null,
	showingSimpleAlertTitle: null,
	reenterPasswordValid: false,
	reenterPasswordInValid: false,
	forgotReenterPasswordValid: false,
	forgotReenterPasswordInValid: false,
	showForgotPasswordPopUp: false,
	showResetPasswordErrorAlert: false,
	language: getUserLanguage(),
	authorizationToken: null,
	loginProviders: [],
	loginFields: true,
	registerPage: true,
	organizationId: '',
	authFeatures: [],
	availableOrganizations: [],
	organizationByEnvScenario: false
}

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case EMAIL_CHANGED: {
			let strengthScore = calculateStrength(
				state.password,
				action.payload,
				state.name,
				state.company
			)
			const emailValid =
				action.payload.length === 0
					? null
					: EmailValidator.validate(action.payload)
			return { ...state, email: action.payload, emailValid, strengthScore }
		}
		case NAME_CHANGED: {
			let strengthScore = calculateStrength(
				state.password,
				state.email,
				action.payload,
				state.company
			)

			return { ...state, name: action.payload, strengthScore }
		}
		case COMPANY_CHANGED: {
			let strengthScore = calculateStrength(
				state.password,
				state.email,
				state.name,
				action.payload
			)

			return { ...state, company: action.payload, strengthScore }
		}
		case LOGIN_PASSWORD_CHANGED: {
			const newPW = action.payload
			let strengthScore = calculateStrength(
				newPW,
				state.email,
				state.name,
				state.company
			)
			return { ...state, password: newPW, strengthScore }
		}
		case RESISTER_PASSWORD_CHANGED: {
			const { newPW, reenterPasswordValid, reenterPasswordInValid } =
				action.payload
			let strengthScore = calculateStrength(
				newPW,
				state.email,
				state.name,
				state.company
			)

			return {
				...state,
				password: newPW,
				strengthScore,
				reenterPasswordValid,
				reenterPasswordInValid
			}
		}
		case REENTER_PASSWORD_CHANGED:
			const { text, reenterPasswordValid, reenterPasswordInValid } =
				action.payload
			return {
				...state,
				reenterPassword: text,
				reenterPasswordValid,
				reenterPasswordInValid
			}

		case CHECKBOX_CLICKED:
			return { ...state, agreedToTerms: action.payload }
		case REGISTER_USER:
		case LOGIN_USER:
			return { ...state, loading: true, error: '' }
		case REGISTER_USER_SUCCESS:
			return {
				...state,
				showingRenderVerifyUser: true
			}
		case LOGIN_USER_SUCCESS:
			return { ...state, ...INITIAL_STATE, loggedIn: true }
		case USER_NOT_LOGGED_IN:
			return { ...state, loggedIn: false }
		case REGISTER_USER_FAIL:
		case LOGIN_USER_FAIL: {
			const errorMessage =
				action.payload?.errorMessage || action.payload?.error || action.payload
			return {
				...state,
				error: errorMessage,
				emailValid: !errorMessage?.toLowerCase()?.includes(EMAIL) || false,
				loading: false
			}
		}
		case SEND_RESET_REQUEST_FAIL:
			return { ...state, resetRequestError: action.payload }
		case RESET_AUTH_STATE:
			const resetAllState = action.payload?.resetAllState
			return {
				...INITIAL_STATE,
				authFeatures: state.authFeatures,
				availableOrganizations: state.availableOrganizations,
				organizationByEnvScenario: state.organizationByEnvScenario,
				error: resetAllState
					? INITIAL_STATE.error
					: getStringItemFromLocalStorage(LOGIN_LS_ERRORS) || ''
			}
		case SHOW_PASSWORD_STRENGTH_ALERT:
			return {
				...state,
				showingSimpleAlertText: PASSWORD_STENGTH_EXPLINATION_BODY,
				showingSimpleAlertTitle: PASSWORD_STENGTH_EXPLINATION_TITLE
			}
		case HIDE_ALERT:
			return {
				...state,
				showingSimpleAlertText: null,
				showingSimpleAlertTitle: null
			}
		case HIDE_VERIFY_USER_ALERT:
			history.push('/login')
			return {
				...state,
				showingRenderVerifyUser: null
			}
		case HIDE_RESET_PASSWORD_ERROR_ALERT:
			return {
				...state,
				showResetPasswordErrorAlert: false,
				showForgotPasswordPopUp: false
			}
		case HIDE_FORGOT_ALERT:
			return {
				...state,
				showingSimpleAlertText: null,
				showingSimpleAlertTitle: null
			}
		case SHOW_FORGOT_PASSWORD_STRENGTH_ALERT:
			return {
				...state,
				showingSimpleAlertText: PASSWORD_STENGTH_EXPLINATION_BODY,
				showingSimpleAlertTitle: PASSWORD_STENGTH_EXPLINATION_TITLE
			}
		case RESET_TOKEN_SAVED:
			return {
				...state,
				resetToken: action.payload
			}
		case FORGOT_PASSWORD_POPUP_OPEND:
			return {
				...state,
				showForgotPasswordPopUp: action.payload,
				restartPasswordError: ''
			}
		case FORGOT_PASSWORD_POPUP_CLOSE:
			return {
				...state,
				showForgotPasswordPopUp: false
			}
		case HIDE_PASSWORD_REQUEST_ALERT:
			return {
				...state,
				resetRequestError: false,
				showForgotPasswordPopUp: false
			}
		case RESET_PASSWORD_FAIL:
			return {
				...state,
				showResetPasswordErrorAlert: true
			}
		case SHOW_RESET_PASSWORD_SUCCESS_POP_UP:
			return {
				...state,
				resetPasswordSucceeded: true,
				showResetPasswordErrorAlert: false
			}
		case SHOW_EMAIL_SENDING_SUCCESS_POPUP:
			return {
				...state,
				resetEmailRequestSuccess: true,
				showResetPasswordErrorAlert: false,
				showForgotPasswordPopUp: false
			}
		case SEND_RESET_REQUEST_CONFIRMED:
			return {
				...state,
				resetEmailRequestSuccess: false,
				showForgotPasswordPopUp: false,
				resetRequestError: ''
			}
		case FORGOT_PASSWORD_CHANGED: {
			const { newPassword, strengthScore } = action.payload
			return { ...state, forgotPassword: newPassword, strengthScore }
		}

		case FORGOT_REENTER_PASSWORD_CHANGED: {
			const { text, forgotReenterPasswordValid, forgotReenterPasswordInValid } =
				action.payload
			return {
				...state,
				forgotReenterPassword: text,
				forgotReenterPasswordValid,
				forgotReenterPasswordInValid
			}
		}
		case CONFIRM_RESTART_PASSWORD_EMAIL_FAILED:
			return { ...state, restartPasswordError: action.payload }
		case AUTHORIZATION_TOKEN_UPDATED: {
			const { token, token_type } = action.payload
			const authorizationToken = `${token_type} ${token}`
			return { ...state, authorizationToken }
		}
		case REFRESH_TOKEN_UPDATED: {
			const { refreshToken, token_type, refreshTokenExpireIn } = action.payload
			setJsonItemToLocalStorage('crt', {
				refreshToken,
				token_type,
				expireIn: refreshTokenExpireIn
			})
			return { ...state }
		}
		case USER_LOGOUT: {
			removeAllLocalStorage()
			return { ...state, authorizationToken: null }
		}

		case SKIP_LOGIN_PROVIDER: {
			return {
				...state,
				loginFields: getStringItemFromLocalStorage(LOGIN_LS_FIELDS)
			}
		}

		case AUTHORIZATION_GET_PROVIDERS_SUCCESS: {
			const {
				loginProviders,
				registerPage,
				loginFields: payloadLoginFields
			} = action.payload

			const loginFields =
				getStringItemFromLocalStorage(LOGIN_LS_FIELDS) || payloadLoginFields

			return {
				...state,
				loginProviders,
				registerPage,
				loginFields,
				loadedPage: true
			}
		}
		case AUTHORIZATION_GET_PROVIDERS_ERROR: {
			return {
				...state,
				loadedPage: true
			}
		}
		case GOT_AUTH_FEATURES: {
			const { features } = action.payload
			return {
				...state,
				authFeatures: features
			}
		}
		case SETUP_AVAILABLE_ORGANIZATIONS: {
			const { availableOrganizations, organizationId } = action.payload
			return {
				...state,
				availableOrganizations,
				organizationId
			}
		}
		case GET_ORGANIZATION_LICENSE_INFO: {
			const { organizationByEnvScenario } = action.payload
			return {
				...state,
				organizationByEnvScenario
			}
		}
		case CHOOSE_REGISTRATION_ORGANIZATION: {
			const { organizationId } = action.payload
			return {
				...state,
				organizationId
			}
		}
		default:
			return state
	}
}
const calculateStrength = (password, email, name, company) => {
	if (!password || password.length === 0) {
		return null
	} else {
		return zxcvbn(password, [email, company, name]).score
	}
}

export default authReducer
