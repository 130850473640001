import { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { FeatureComponentId } from '../../../../Services/models/Features'
import { getString } from '../../../../Services/Strings/StringService'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import { setUpUserPostProcesses } from './CustomizePostProcessesActions'
import FunctionEditorAlert from './FunctionEditorAlert'
import PostProcessRow from './PostProcessRow'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { IPostProcess } from 'Services/models/IPostProcess'

import './CustomizeUserPostProcesses.scss'

const CustomizeUserPostProcesses: FC = () => {
	const {
		allOptionalPostProcessesData,
		optionalPostProcessAvailability,
		customPostProcessTemplate
	} = useSelector((state: RootStateOrAny) => state.user)
	const { postProcesses, postProcessTemplate, functionEditorModalIsOpen } =
		useSelector((state: RootStateOrAny) => state.CustomizePostProcessesReducer)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const dispatch = useDispatch()

	const postProcessesHeaders =
		getString('CUSTOMIZE_POST_PROCESSES_HEADERS_ARRAY') || []

	useEffect(() => {
		dispatch(
			setUpUserPostProcesses(
				allOptionalPostProcessesData,
				optionalPostProcessAvailability,
				customPostProcessTemplate,
				adminSelectedOrganizationId
			)
		)
	}, [
		adminSelectedOrganizationId,
		allOptionalPostProcessesData,
		customPostProcessTemplate,
		dispatch,
		optionalPostProcessAvailability
	])

	return (
		<>
			<div
				data-qa="data-qa-post-processes-costs-block"
				id="customize-post-processes"
			>
				<CastorFormHeader
					explanationHeader={getString('CUSTOMIZE_MACHINING_TITLE')}
					explanationArray={getString('CUSTOMIZE_MACHINING_EXPLANATION_ARRAY')}
					isInCard={true}
				/>
				<div className="customize-post-process--wrapper">
					<div className="customize-post-process--row">
						{postProcessesHeaders.map((header: string, index: number) => {
							return (
								<div
									key={header + index}
									className="customize-post-process--header"
								>
									{header}
								</div>
							)
						})}
					</div>
					{postProcesses.map((postProcess: IPostProcess) => {
						if (postProcess.editDisabled) {
							return null
						}
						return (
							<PostProcessRow postProcess={postProcess} key={postProcess.id} />
						)
					})}
					<PostProcessRow postProcess={postProcessTemplate} newInput />
				</div>
			</div>
			{functionEditorModalIsOpen && <FunctionEditorAlert />}
		</>
	)
}

export default WithFeatureToggleHOC(
	CustomizeUserPostProcesses,
	FeatureComponentId.CUSTOMIZE_USER_POST_PROCESSES
)
