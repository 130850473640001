import { ChangeEvent, FC, memo, useEffect, useState } from 'react'

import { MenuItem, Select } from '@material-ui/core'

import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { IOption } from 'Scenes/Home/Customize/CustomizeSolutionFilters/CustomizeThreadService'
import { getString } from 'Services/Strings/StringService'

interface ISelectProps {
	title: string
	hideSaveButton?: boolean
	value: boolean
	selectOptions: Array<any>
	onUpdate: (value: boolean) => void
	updateSelectedValue?: (value: boolean) => void
}

const SelectContent: FC<ISelectProps> = ({
	title,
	value,
	selectOptions,
	onUpdate,
	hideSaveButton,
	updateSelectedValue
}) => {
	const [selectValue, setSelectValue] = useState(value)

	useEffect(() => {
		setSelectValue(value)
		updateSelectedValue?.(value)
	}, [value])

	return (
		<div className="customize-solution-filter__thread">
			<div className="customize-solution-filter__thread-block">
				<div>
					<div className="title">{title}:</div>
					<Select
						disableUnderline={false}
						onChange={e => {
							const { target } = e as ChangeEvent<HTMLInputElement>
							setSelectValue(Boolean(target.value))
							updateSelectedValue?.(Boolean(target.value))
						}}
						value={Number(selectValue)}
					>
						<MenuItem value="-" disabled>
							{title}
						</MenuItem>
						{selectOptions.map((option: IOption) => {
							return (
								<MenuItem key={option.info} value={option.value}>
									{option.label}
								</MenuItem>
							)
						})}
					</Select>
				</div>

				{hideSaveButton ? (
					<></>
				) : (
					<Button
						disabled={selectValue === value}
						color="primaryNewStyle"
						onClick={() => onUpdate(selectValue)}
					>
						{getString('SAVE')}
					</Button>
				)}
			</div>
		</div>
	)
}

export default memo(SelectContent)
