import { FC, memo } from 'react'

import LinearProgressBar from './LinearProgressBar'

import './index.scss'

interface ProgressBarProps {
	showProgressAlert?: boolean
	showAnalyze?: boolean
	bundleId?: string
}

const ProgressBar: FC<ProgressBarProps> = ({
	showProgressAlert,
	showAnalyze,
	bundleId
}) => {
	const shouldShowAnalyze =
		showProgressAlert || typeof showProgressAlert === 'undefined'
			? showAnalyze
			: undefined

	return (
		<LinearProgressBar showAnalyze={shouldShowAnalyze} bundleId={bundleId} />
	)
}

export default memo(ProgressBar)
