import React, { ChangeEvent, FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { FormControlLabel, MenuItem, RadioGroup } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import RecalculateOrganizationSelector from './RecalculateOrganizationSelector'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import CastorRadio from 'Scenes/Components/CastorRadio'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Devider from 'Scenes/Components/Devider/Devider'
import Flexbox from 'Scenes/Components/FlexBox'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import {
	clearProjectsData,
	getProjectsData,
	handleRecalculateProjects,
	stopAllRecalculatingAlert,
	toggleAllProjectsData,
	toggleOnlyProjectsData,
	toggleProjectsData,
	updateDeepResetType
} from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsAction'
import {
	CUSTOMIZE_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const MAX_PROJECTS = 15

const CustomizeRecalculateProjects: FC = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const {
		preparedProjects,
		selectedProjects,
		error,
		isLoading,
		deepReset,
		recalculateOrganizationId,
		recalculateWithOrganization
	} = useSelector(
		(state: RootStateOrAny) => state?.CustomizeRecalculateProjectsReducer
	)
	const recalculateButtonIsDisabled =
		!Feature.isFeatureOn(FeatureComponentId.RECALCULATE_PROJECTS) ||
		!selectedProjects.length ||
		(recalculateWithOrganization &&
			typeof recalculateOrganizationId !== 'number')

	useEffect(() => {
		dispatch(getProjectsData())

		return () => {
			dispatch(clearProjectsData())
		}
	}, [])

	const renderFormHeader = () => {
		return (
			<CastorFormHeader
				goBack={() => history.push(USER_HOME_ROUTE + CUSTOMIZE_ROUTE)}
				explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
		)
	}

	const allProjectsCount = preparedProjects?.length
	const selectedProjectsCount = selectedProjects?.length

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader()}
				formTitle={getString('SELECT_PROJECTS_HEADER')}
				alertType={AlertType.WARNING}
				content={
					<div className="multiple-projects">
						<p>
							{getString('CUSTOMIZE_RECALCULATE_PROJECTS_EXPLANATION_ARRAY')}
						</p>
						<FormControl className="multiple-projects__form">
							<InputLabel id="multiple-projects-label">
								{getString('SELECT_PROJECT_HEADER')}
							</InputLabel>
							<Select
								labelId="multiple-projects-label"
								id="multiple-projects"
								className="multiple-projects-select"
								data-qa="recalculate-projects-select"
								multiple
								MenuProps={{
									variant: 'menu',
									getContentAnchorEl: null,
									classes: { paper: 'multiple-projects-select-paper' }
								}}
								value={selectedProjects}
								onChange={(e: any) =>
									dispatch(toggleProjectsData(e.target.value, preparedProjects))
								}
								renderValue={(selected: any) => {
									const names = selected.map(
										(id: string) =>
											preparedProjects.find((project: any) => id === project.id)
												?.name
									)
									return (
										<div className="multiple-projects__selected-value">
											{names.length > 1
												? getString('PROJECTS_SELECTED').format(names.length)
												: getString('PROJECT_SELECTED').format(names.length)}
										</div>
									)
								}}
							>
								<div className={'multiple-projects-select-paper-button'}>
									<div className="multiple-projects-select-paper-count">
										<span>{getString('PROJECTS')}</span>:{' '}
										{selectedProjectsCount > 0
											? `${allProjectsCount}/${selectedProjectsCount}`
											: `${allProjectsCount}`}
									</div>
									{allProjectsCount > MAX_PROJECTS ? (
										<></>
									) : (
										<Button
											color="primary"
											data-qa="recalculate-all-projects"
											onClick={(e: React.MouseEvent<HTMLDivElement>) => {
												e.preventDefault()
												e.stopPropagation()
												dispatch(toggleAllProjectsData(preparedProjects))
											}}
										>
											<span>
												{!!selectedProjectsCount &&
												selectedProjectsCount === allProjectsCount
													? getString('REMOVE_ALL')
													: getString('SELECT_ALL_PROJECT_HEADER')}
											</span>
										</Button>
									)}
								</div>

								{preparedProjects?.map(({ id, name, selected }: any) => (
									<MenuItem key={id} value={id} className="menu-item regular">
										<Flexbox
											alignItems="center"
											justifyContent="flex-start"
											width="100%"
											data-qa={`project-menu-item-${name}`}
										>
											<Checkbox color="primary" checked={selected} />
											<div>{name}</div>
										</Flexbox>
										<div
											className="multiple-projects__select-only"
											onClick={(e: React.MouseEvent<HTMLDivElement>) => {
												e.stopPropagation()
												dispatch(toggleOnlyProjectsData(id, preparedProjects))
											}}
										>
											{getString('SELECT_ONLY_TECHNOLOGY')}
										</div>
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Flexbox
							className="multiple-projects-select-only"
							alignItems="stretch"
							width="100%"
							style={{ paddingTop: '20px', paddingBottom: '40px' }}
						>
							<Flexbox
								justifyContent="flex-start"
								alignItems="center"
								width="65%"
								height="100%"
							>
								<RadioGroup
									name="deepResetRadio"
									className="radio-button"
									value={deepReset}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										dispatch(updateDeepResetType(e.target.value))
									}
								>
									<FormControlLabel
										value={0}
										control={
											<CastorRadio
												data-qa="data-qa-keep-data-recalculate"
												className="step2-radio-button--radio"
											/>
										}
										label={getString('RECALCULATE_SELECT_KEEP_DATA')}
										classes={{
											root: `analysis-settings-radio-button--label`
										}}
									/>
									<div>
										<Flexbox justifyContent="flex-start" width="100%">
											<FormControlLabel
												value={1}
												control={
													<CastorRadio
														data-qa="data-qa-reset-data-recalculate"
														className="step2-radio-button--radio"
													/>
												}
												label={getString('RECALCULATE_SELECT_RESET_DATA')}
												classes={{
													root: `analysis-settings-radio-button--label`
												}}
											/>
											<Flexbox className="info-popup">
												<DetailsPopup
													isHover={true}
													data={getString('RECALCULATE_SELECT_RESET_DATA_INFO')}
													popperDirection={'auto'}
													popperClassName={'contact-us--wrapper'}
													popperContactClassName={'contact-us--wrapper--popper'}
												>
													<IconFactory iconName="info" />
												</DetailsPopup>
											</Flexbox>
										</Flexbox>
										<RecalculateOrganizationSelector deepReset={deepReset} />
									</div>
								</RadioGroup>
							</Flexbox>
							<Flexbox
								justifyContent="flex-end"
								width="35%"
								alignSelf="flex-end"
							>
								<ButtonWithLoader
									loading={isLoading}
									onClick={() =>
										dispatch(
											handleRecalculateProjects(
												selectedProjects,
												deepReset,
												preparedProjects,
												deepReset && recalculateWithOrganization
													? recalculateOrganizationId
													: undefined
											)
										)
									}
									disabled={recalculateButtonIsDisabled}
									qaDataElementName="recalculate-start-btn"
								>
									{getString('RECALCULATE')}
								</ButtonWithLoader>
							</Flexbox>
							<p className="error">{error}</p>
						</Flexbox>
						<Devider
							className="customize-form--divider"
							componentId={2}
							style={{ marginTop: '50px' }}
						/>

						<Flexbox
							justifyContent="flex-start"
							width="100%"
							style={{ paddingTop: '20px' }}
						>
							<Button
								style={{
									color: 'black',
									backgroundColor: '#fac0be',
									borderColor: 'black'
								}}
								onClick={() => dispatch(stopAllRecalculatingAlert())}
								data-qa="data-qa-stop-recalculate-btn"
							>
								{getString('STOP_RECALCULATING')}
							</Button>
						</Flexbox>
					</div>
				}
			/>
		</NavBarAndMaterial>
	)
}

export default memo(CustomizeRecalculateProjects)
