import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'

export const quantityOptions = ['10', '100', '1000']

export const initialState: IFileWithMaterialAndQuantity = {
	name: '',
	materialType: '',
	materialCategory: '',
	originalMaterial: '',
	materialId: 0,
	productionQuantity: null,
	standardCost: null,
	customToleranceValue: null,
	printingStandards: null
}

export const QUICK_UPLOAD_COLUMN = {
	FILE: 1,
	MATERIAL_TYPE: 2,
	MATERIAL_CATEGORY: 3,
	ORIGINAL_MATERIAL: 4,
	PRODUCTION_QUANTITY: 5,
	STANDARD_COST: 6,
	TOLERANCE: 7,
	PRINTING_STANDARDS: 8
}

export const materialColumnNames = [
	'materialType',
	'materialCategory',
	'originalMaterial'
]
