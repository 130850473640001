import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import {
	onEditAllToggle,
	onEditCO2Data,
	onEditToggle,
	onUpdateCO2Data
} from 'Scenes/Home/Customize/CustomizeCO2Cost/CustomizeCO2Actions'
import CustomizeCO2Cost from 'Scenes/Home/Customize/CustomizeCO2Cost/CustomizeCO2Cost'
import CustomizeCO2Holding from 'Scenes/Home/Customize/CustomizeCO2Cost/CustomizeCO2Holding'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

const Transportation: FC = () => {
	const co2EmissionProps = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2Reducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const { userUnitSystem } = useSelector((state: RootStateOrAny) => state.user)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric

	const dispatch = useDispatch()

	return (
		<div
			className="material-production--wrapper product-use"
			data-qa="data-qa-transportation-section"
		>
			<div className="material-production--header big">
				{getString('TRANSPORTATION_AND_HOLDING')}
			</div>
			<div className="custom-calculation-co2--title">
				{getString('TRANSPORTATION')}
			</div>
			<CustomizeCO2Cost
				{...co2EmissionProps}
				onEditToggle={(co2Key: string, isEdit: boolean) =>
					dispatch(onEditToggle(co2Key, isEdit))
				}
				onEditAllToggle={(isEditAll: boolean) =>
					dispatch(onEditAllToggle(isEditAll))
				}
				onEditCO2Data={(co2Key: string, keyLabel: string, value: any) =>
					dispatch(onEditCO2Data(co2Key, keyLabel, value, unitSystem))
				}
				onUpdateCO2Data={(reset: boolean, co2Key?: string) =>
					dispatch(onUpdateCO2Data(reset, co2Key, adminSelectedOrganizationId))
				}
			/>
			<div className="custom-calculation-co2--title">
				{getString('HOLDING')}
			</div>
			<CustomizeCO2Holding />
		</div>
	)
}

export default Transportation
