import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'

import InfoBox from '../InfoBox'
import IconFactory from '../StarIcon/IconFactory'
import { Button, Danger } from '../thirdParty/CreativeTim/components'
import Flexbox from 'Scenes/Components/FlexBox'
import { MIN_NUMBER_FIELD_VALUE } from 'Services/Constants'
import {
	isCommaKeyPressed,
	isDotKeyPressed,
	isMinusKeyPressed,
	isPlusKeyPressed
} from 'Services/getKeyCodesService'

import './TextField.scss'

interface IProps {
	label?: string
	type: string
	wrapperClassName?: string
	labelClassName?: string
	errorClassName?: string
	inputClassName?: string
	placeholder?: string
	error?: string
	suffix?: string
	input?: any
	touched?: boolean
	maxValue?: number
	minValue?: number
	showRemove?: boolean
	disabled?: boolean
	onRemoveClick?: Function
	onChange?: Function
	value?: any
	inputProps?: any
	infoData?: string
	labelInfoWrapperClassName?: string
	infoDataBoxDirection?: string
	disablePlus?: boolean
	disableMinus?: boolean
	disableDot?: boolean
	disableFloat?: boolean
	disableZero?: boolean
	dataQa?: string
	preventKeyDownProps?: (e: KeyboardEvent) => void
	withDebounce?: boolean
}

const renderRemoveButton = (
	showRemove: boolean,
	onRemoveClick: Function | null
) => {
	if (!showRemove || !onRemoveClick) {
		return <div />
	}
	return (
		<Button
			size="xs"
			justIcon
			color="transparent"
			onClick={onRemoveClick}
			className="text_field__romeve_button"
			title="remove"
		>
			<IconFactory iconName="clear" className="text_field__remove-icon" />
		</Button>
	)
}

const renderInfo = (infoData: string, infoDataBoxDirection: string) => {
	if (!infoData) {
		return <div></div>
	}
	return (
		<InfoBox
			boxContact={infoData}
			iconClassName="text_field__info_icon"
			boxDirection={infoDataBoxDirection as any}
		/>
	)
}

const TextField: FC<IProps> = ({
	label,
	type,
	wrapperClassName,
	labelClassName,
	errorClassName,
	inputClassName,
	placeholder,
	error,
	input,
	inputProps,
	touched,
	maxValue,
	minValue = MIN_NUMBER_FIELD_VALUE,
	onRemoveClick,
	showRemove,
	infoData = '',
	labelInfoWrapperClassName = '',
	disabled = false,
	infoDataBoxDirection = 'auto',
	disablePlus = false,
	disableMinus = false,
	disableFloat = false,
	disableDot = false,
	disableZero = false,
	suffix = '',
	dataQa = '',
	preventKeyDownProps,
	withDebounce = false
}) => {
	const initValue = input?.value || inputProps?.value || ''
	const [value, setValue] = useState(initValue)

	useEffect(() => {
		setValue(initValue)
	}, [input?.value, inputProps?.value])

	const getValue = () => {
		let newValue = value
		const isZeroValue = disableZero && +value === 0
		const isMaxValue = maxValue && newValue > maxValue

		// if disableZero to not allow 0 or to not allow more than maxValue if maxValue exist
		if (isZeroValue || isMaxValue) {
			newValue = initValue ? initValue : minValue
		}

		setValue(newValue)

		return newValue
	}

	useEffect(() => {
		let delayDebounceFn: null | ReturnType<typeof setTimeout> = null

		if (withDebounce) {
			if (value !== initValue) {
				delayDebounceFn = setTimeout(() => {
					input?.onChange({
						target: { value }
					} as ChangeEvent<HTMLInputElement>)
				}, 250)
			}
		}

		return () => {
			withDebounce &&
				clearTimeout(delayDebounceFn as ReturnType<typeof setTimeout>)
		}
	}, [value, withDebounce])

	const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (value !== initValue) {
			const newEvent = e
			newEvent.target.value = type === 'number' ? getValue() : value
			input?.onChange(newEvent)
		}
	}

	const preventKeyDown = (e: KeyboardEvent) => {
		if (
			(disableMinus && isMinusKeyPressed(e)) ||
			(disablePlus && isPlusKeyPressed(e)) ||
			(disableFloat && isCommaKeyPressed(e)) ||
			(disableDot && isDotKeyPressed(e))
		) {
			e.preventDefault()
		}
	}

	return (
		<div className={`text_field ${wrapperClassName || ''}`}>
			<Flexbox width="100%" justifyContent="space-between">
				<Flexbox
					width={showRemove ? '60%' : '100%'}
					className={labelInfoWrapperClassName}
					alignItems="center"
				>
					{label ? (
						<div className={`text_field__label ${labelClassName || ''}`}>
							{label}
						</div>
					) : (
						<div></div>
					)}
					{renderInfo(infoData, infoDataBoxDirection)}
				</Flexbox>

				{renderRemoveButton(showRemove || false, onRemoveClick || null)}
			</Flexbox>
			<div className="input-container">
				<input
					data-qa={`data-qa-input-filed-${label || dataQa}`}
					title={value || ''}
					disabled={disabled}
					{...input}
					{...inputProps}
					value={value}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setValue(e.target.value)
					}}
					onKeyDown={preventKeyDownProps || preventKeyDown}
					onMouseOut={inputChange}
					onBlur={inputChange}
					onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
						event.target.blur()
					}
					type={type}
					placeholder={placeholder}
					className={`text_field__input ${inputClassName || ''} ${
						error ? 'text_field__input_error' : ''
					}`}
				/>
				{suffix ? (
					<span className="suffix text_field__label">{suffix}</span>
				) : (
					<></>
				)}
			</div>

			{error && (
				<Danger className={`text_field__error ${errorClassName || ''}`}>
					{error}
				</Danger>
			)}
		</div>
	)
}

export default memo(TextField)
