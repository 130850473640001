import React, { FC, useRef } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import { bomFileTypes } from '../../fileTypes'
import { BOMFileSelected } from '../../UploadProjectActions'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import BomTemplate from 'Scenes/Components/BomTemplate'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import Flexbox from 'Scenes/Components/FlexBox'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { LEARN_HOW } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'
import { ReactComponent as FileExcel } from 'assets/img/svg/icons/file_excel.svg'

type IProps = {
	show: boolean
	isSubmitDisabled: boolean
	onConfirm: () => void
	onCancel: () => void
}
const AddBOMFileAlert: FC<IProps> = ({
	show,
	isSubmitDisabled,
	onConfirm,
	onCancel
}) => {
	const { fileInputKey, BOMFile } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const inputRef: any = useRef(null)
	const dispatch = useDispatch()

	const onUploadBom = () => inputRef?.current?.click()

	return (
		<CastorAlert
			headerTitle={getString('ADD_BOM_FILE')}
			show={show}
			onConfirm={onConfirm}
			showCancel
			onCancel={onCancel}
			cancelOptionalText={getString('CANCEL')}
			confirmOptionalText={getString('ADD_BOM')}
			onFooterCancel={onCancel}
			alertBodyClass="new-upload-project__quick-upload--add-bom-file-modal--body"
			disabled={isSubmitDisabled}
		>
			<div className="new-upload-project__quick-upload--add-bom-file-modal">
				<div>
					{getString('ADD_BOM_FILE_DESCRIPTION')}
					<TransparentButton
						isAnchor={true}
						href={'https://youtu.be/ICxxFlwZreU'}
						target="_blank"
						className="upload-form-learn-how-button"
					>
						{LEARN_HOW}
					</TransparentButton>
				</div>
				<Flexbox
					className="bom-file-upload"
					alignItems="center"
					justifyContent="space-between"
				>
					<Flexbox alignItems="center" justifyContent="flex-start">
						<input
							name="bomFileUpload"
							id="bomFileUpload"
							type="file"
							ref={inputRef}
							value={BOMFile ? '' : undefined}
							key={fileInputKey}
							onChange={(e: any) =>
								dispatch(BOMFileSelected(e.target?.files[0]))
							}
							className={cx('hide-input')}
							accept={bomFileTypes.join(',')}
						/>
						{BOMFile ? (
							<CastorSelectBox
								key={BOMFile?.name}
								selected={true}
								withIcon={false}
								boxClassName="box-around"
							>
								<div>
									<FileExcel className="icon-file" />
									<div className="text">{BOMFile?.name}</div>
									<CircleClose
										onClick={() => dispatch(BOMFileSelected(null))}
										className="icon-close"
									/>
								</div>
							</CastorSelectBox>
						) : (
							<>
								<div
									onClick={onUploadBom}
									className="bom-file-upload__button"
									data-qa="data-qa-bom-choose-file"
								>
									{getString('UPLOAD_FILE_CHOSE_FILE')}
								</div>
								<div className="bom-file-upload__text">
									{BOMFile?.name || getString('UPLOAD_FILE_NO_CHOSEN_FILE')}
								</div>
							</>
						)}
					</Flexbox>
					<BomTemplate
						showImage
						isOnPrem={false}
						isGenerativeFileOption={false}
					/>
				</Flexbox>
			</div>
		</CastorAlert>
	)
}

export default AddBOMFileAlert
