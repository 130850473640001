import React, { FC, memo } from 'react'

import RequestMultiplePartsIntoOneCard from '../../Home/ProjectAnalysis/MultiplePartsIntoOneWidgits/RequestMultiplePartsIntoOneCard'
import Devider from '../Devider/Devider'
import { IItemWithPicture, IMaterialCountInfo } from '../ItemsWithPicturesList'
import HeaderCard from '../thirdParty/CreativeTim/components/Cards/HeaderCard'
import WeightReduction from '../WeightReduction'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './ProjectExtraFeaturesSection.scss'

interface IProps {
	project: any
	clusterRequested: boolean
	updateWeightReductionButtonLoading: boolean
	showMultiplePartsIntoOneRequest: boolean
	showMultiplePartsIntoOneResults: boolean
	clusterRequestText: string
	projectClusterStatus: string
	projectWeightReductionStatus: string
	weightReductionRequested: boolean
	weightReductionLoading: boolean
	isSinglePartProject: boolean
	weightReductionRequestText: string
	weightReductionItemsWithPictures: IItemWithPicture[]
	projectWeightReductionMinimumThicknessThreshold: number
	projectWeightReductionThreshold: number
	onWeightReductionThresholdChanged: Function
	onWeightReductionMinimumThicknessThresholdChanged: Function
	sendWeightReductionRequest: Function
	onUpdateWeightReductionButtonClick: Function
	reducePartWeightClick: Function
	materialCountInfo?: IMaterialCountInfo
	confirmConsolidationAnalysis: Function
	sendMultiplePartsIntoOneRequest: Function
	clusterItemsWithPictures: any[]
	setupClustersOverview: Function
	disabled?: boolean
}

const ProjectExtraFeaturesSection: FC<IProps> = ({
	project,
	showMultiplePartsIntoOneResults,
	showMultiplePartsIntoOneRequest,
	clusterRequested,
	clusterRequestText,
	projectClusterStatus,
	weightReductionRequested,
	weightReductionRequestText,
	weightReductionItemsWithPictures,
	projectWeightReductionThreshold,
	projectWeightReductionMinimumThicknessThreshold,
	onWeightReductionThresholdChanged,
	onWeightReductionMinimumThicknessThresholdChanged,
	sendWeightReductionRequest,
	weightReductionLoading,
	projectWeightReductionStatus,
	onUpdateWeightReductionButtonClick,
	updateWeightReductionButtonLoading,
	isSinglePartProject,
	reducePartWeightClick,
	materialCountInfo,
	confirmConsolidationAnalysis,
	sendMultiplePartsIntoOneRequest,
	setupClustersOverview,
	clusterItemsWithPictures,
	disabled
}) => {
	const renderDevider = () => {
		if (
			!Feature.isAllOfTheFeaturesOn([
				FeatureComponentId.UNIFICATION,
				FeatureComponentId.WEIGHT_REDUCTION
			])
		) {
			return <div></div>
		}
		return <Devider size="100%" className="project-extras--devider" />
	}

	const renderMultiplePartsIntoOneRequest = () => {
		if (!showMultiplePartsIntoOneRequest) {
			return <div></div>
		}

		return (
			<>
				<RequestMultiplePartsIntoOneCard
					setupClustersOverview={setupClustersOverview}
					clusterItemsWithPictures={clusterItemsWithPictures}
					sendMultiplePartsIntoOneRequest={sendMultiplePartsIntoOneRequest}
					confirmConsolidationAnalysis={confirmConsolidationAnalysis}
					materialCountInfo={materialCountInfo}
					requested={clusterRequested}
					requestText={clusterRequestText}
					projectClusterStatus={projectClusterStatus}
					project={project}
					showResults={showMultiplePartsIntoOneResults && project}
				/>
				{renderDevider()}
			</>
		)
	}

	const renderContent = () => (
		<>
			{renderMultiplePartsIntoOneRequest()}
			<WeightReduction
				projectWeightReductionStatus={projectWeightReductionStatus}
				requested={weightReductionRequested}
				requestText={weightReductionRequestText}
				weightReductionItemsWithPictures={weightReductionItemsWithPictures}
				project={project}
				projectWeightReductionThreshold={projectWeightReductionThreshold}
				projectWeightReductionMinimumThicknessThreshold={
					projectWeightReductionMinimumThicknessThreshold
				}
				onWeightReductionThresholdChanged={onWeightReductionThresholdChanged}
				onWeightReductionMinimumThicknessThresholdChanged={
					onWeightReductionMinimumThicknessThresholdChanged
				}
				sendWeightReductionRequest={sendWeightReductionRequest}
				loading={weightReductionLoading}
				onUpdateWeightReductionButtonClick={onUpdateWeightReductionButtonClick}
				updateWeightReductionButtonLoading={updateWeightReductionButtonLoading}
				reducePartWeightClick={reducePartWeightClick}
			/>
		</>
	)

	if (
		disabled ||
		(!Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION) &&
			!showMultiplePartsIntoOneRequest)
	) {
		return <div></div>
	}

	const isOptimizationAnalysisOn = Feature.isFeatureOn(
		FeatureComponentId.OPTIMIZATION_ANALYSIS
	)

	return (
		<ErrorBoundary extra="ProjectExtraFeaturesSection">
			<HeaderCard
				id={`advanced-features${isSinglePartProject ? '-single-part' : ''}`}
				cardTitle={
					isOptimizationAnalysisOn
						? getString('ADVANCED_MANUFACTURING')
						: getString('PROJECT_ANALYSIS_ADVANCED_FEATURES_TITLE')
				}
				cardSubtitle=""
				contentRootClassName="project-extras--content--root"
				content={renderContent()}
			/>
		</ErrorBoundary>
	)
}

export default WithFeatureToggleHOC(memo(ProjectExtraFeaturesSection), [
	FeatureComponentId.UNIFICATION,
	FeatureComponentId.WEIGHT_REDUCTION
])
