import { FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { orderBy } from 'lodash'

import {
	ERROR_UPDATING_TAGS,
	OFF_THE_SHELF_SELECTOR_ITEMS
} from '../../../../Services/Strings'
import { getThemeString } from '../../../../themes/getThemeString'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import CastorTags from '../../../Components/CastorTags/CastorTags'
import { Danger } from '../../../Components/thirdParty/CreativeTim/components'
import { OFF_THE_MANUFACTURE, OFF_THE_SHELF } from '../CustomizeLogic'
import { changeHolesNotify } from '../CustomizeSolutionFilters/CustomizeThreadActions'
import {
	getPartFilteringData,
	partFilteringChanged
} from './CustomizePartFilteringActions'
import CustomizePartNameCheckbox from './CustomizePartNameCheckbox'
import {
	GeometryAnalysisPartEnum,
	TagParam,
	TagParams
} from './PartFilteringService'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

interface CustomizePartFilteringByNameProps {
	geometryAnalysisPart: Record<GeometryAnalysisPartEnum, boolean>
}

export const CustomizePartFilteringByName: FC<
	CustomizePartFilteringByNameProps
> = ({ geometryAnalysisPart }) => {
	const dispatch = useDispatch()

	const { excludePartSubstrings } = geometryAnalysisPart

	const {
		partFilteringArr,
		loading,
		errorSavingFilterString,
		filteredGlobalOffTheShelf
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizePartFilteringReducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	useEffect(() => {
		if (!partFilteringArr.length) {
			dispatch(getPartFilteringData(adminSelectedOrganizationId))
		}
	}, [])

	const sortPartTags = useMemo(
		() =>
			orderBy(
				partFilteringArr,
				[TagParam.label, item => item.text?.toLowerCase()],
				['desc', 'asc']
			),
		[partFilteringArr]
	)

	const renderAlertError = () => {
		if (errorSavingFilterString) {
			return <Danger>{ERROR_UPDATING_TAGS}</Danger>
		}
		return <div />
	}

	const setTagsContent = () => {
		const customizeLabel: Record<string, string> = {}
		customizeLabel[OFF_THE_SHELF_SELECTOR_ITEMS[OFF_THE_SHELF]] = 'info'
		customizeLabel[OFF_THE_SHELF_SELECTOR_ITEMS[OFF_THE_MANUFACTURE]] = 'danger'

		return (
			<div className="part-analysis-filter-name">
				<CastorFormHeader
					explanationHeader={getString('FILTER_BY_PART_NUMBER')}
					explanationArray={getThemeString(
						'CUSTOMIZE_OFF_THE_SHELF_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				<CastorTags
					filteredGlobalOffTheShelf={filteredGlobalOffTheShelf}
					selectedItems={OFF_THE_SHELF_SELECTOR_ITEMS}
					handleTags={handleTags}
					tags={sortPartTags}
					labels={customizeLabel}
					loading={loading}
				/>
				<CustomizePartNameCheckbox
					excludePartSubstrings={excludePartSubstrings}
					updateSelectedValue={(value: boolean) =>
						dispatch(
							changeHolesNotify(
								GeometryAnalysisPartEnum.excludePartSubstrings,
								value,
								adminSelectedOrganizationId
							)
						)
					}
				/>
				{renderAlertError()}
			</div>
		)
	}

	const handleTags = (
		newItemsArr: TagParams[],
		add: boolean,
		reset: boolean
	) => {
		dispatch(
			partFilteringChanged(newItemsArr, add, reset, adminSelectedOrganizationId)
		)
	}

	return <div>{setTagsContent()}</div>
}

export default memo(CustomizePartFilteringByName)
