import { useEffect, useMemo, useState } from 'react'

import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import {
	manufacturingMethodTypes,
	materialCategories,
	materialTypes
} from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Material } from 'Services/models/IMaterial'
import {
	MATERIAL_COMPARISON_HEADER_COMPARISON,
	MATERIAL_COMPARISON_HEADER_DEVIATION,
	MATERIAL_COMPARISON_HEADER_ORIGINAL,
	MATERIAL_COMPARISON_HEADER_PRINTED
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { tabOrder } = getTheme()

export const useSelectedTabOrder = (
	initialSelectedTab: number,
	tabs: any,
	showAnalysis: boolean,
	costBenefit: any,
	disableTabUpdate?: boolean
) => {
	const [selectedTabOrder, setSelectedTabOrder] = useState(initialSelectedTab)

	useEffect(() => {
		setSelectedTabOrder(
			getInitialSelectedTab(
				initialSelectedTab,
				tabs,
				showAnalysis,
				costBenefit,
				disableTabUpdate
			)
		)
	}, [initialSelectedTab, showAnalysis, tabs, costBenefit, disableTabUpdate])

	return selectedTabOrder
}

const getInitialSelectedTab = (
	initialSelectedTab: number,
	tabs: any,
	showAnalysis: boolean,
	costBenefit: any,
	disableTabUpdate?: boolean
) => {
	let selectedTabOrder = initialSelectedTab
	const tabLength = tabs?.length - 1

	if (disableTabUpdate) {
		selectedTabOrder = initialSelectedTab
	} else if (!showAnalysis) {
		selectedTabOrder = tabOrder.geometryAnalysis
	} else if (Feature.isFeatureOn(FeatureComponentId.SUMMARY_COST_TAB)) {
		selectedTabOrder = tabOrder.summary
	} else if (costBenefit) {
		selectedTabOrder = tabOrder.costAnalysis
	}

	const tabIndex = tabs?.findIndex((tab: any) => tab.index === selectedTabOrder)
	const initial =
		initialSelectedTab > tabLength ? tabLength : initialSelectedTab

	return tabIndex !== -1 ? tabIndex : initial
}

export const disableMaterialAndManufacturing = (
	material: Material,
	method: string,
	partStandardCost?: boolean
) => {
	const isMetal = material?.type === materialTypes.metal
	const isPlastic = material?.type === materialTypes.plastic
	const isMold = method === manufacturingMethodTypes.mold
	const isCast = method === manufacturingMethodTypes.cast
	const isInvestmentCast = method === manufacturingMethodTypes.investmentCast
	const isStandard = method === manufacturingMethodTypes.standardCost
	const isAluminumAlloys =
		material?.category === materialCategories.aluminumAlloys
	const isStainlessSteels =
		material?.category === materialCategories.stainlessSteels

	return (
		(isStandard && !partStandardCost) ||
		(!isMetal && isCast) ||
		(!isStainlessSteels && isInvestmentCast) ||
		(!isAluminumAlloys && isCast) ||
		(isMetal && isMold) ||
		(isPlastic && isCast)
	)
}

export const getManufacturingNameWihCompare = (manufacturingMethod: string) => {
	switch (manufacturingMethod) {
		case manufacturingMethodTypes.mold:
			return getString('PART_FINANCIAL_ANALYSIS_INJECTION_MOLDING')
		case manufacturingMethodTypes.standardCost:
			return getString('STANDARD_COST')
		case manufacturingMethodTypes.cast:
			return getString('CASTING')
		case manufacturingMethodTypes.investmentCast:
			return getString('INVESTMENT_CASTING')
		default:
			return getString('CNC')
	}
}

export const getMaterialComparisonTableHeaders = (
	dataTableService: DataTableService,
	isAmOriginalMaterial: boolean
) => {
	const materialComparisonHeaders = [
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Header,
			text: MATERIAL_COMPARISON_HEADER_COMPARISON,
			className: 'cost-summary--table--text--fixed-height'
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Header,
			text: MATERIAL_COMPARISON_HEADER_PRINTED,
			className: 'cost-summary--table--text--fixed-height'
		})
	]
	if (!isAmOriginalMaterial) {
		materialComparisonHeaders.push(
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: MATERIAL_COMPARISON_HEADER_ORIGINAL,
				className: 'cost-summary--table--text--fixed-height'
			})
		)
		materialComparisonHeaders.push(
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: MATERIAL_COMPARISON_HEADER_DEVIATION,
				className: 'cost-summary--table--text--fixed-height'
			})
		)
	}

	return materialComparisonHeaders
}
