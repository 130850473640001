import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	DEFAULT_SCENARIO_CHANGED,
	HANDLE_NOTIFICATION,
	UPLOAD_PROJECT_SCENARIO_DATA_CANCEL,
	UPLOAD_PROJECT_SCENARIO_DATA_CHANGE,
	UPLOAD_PROJECT_SCENARIO_DATA_EDIT,
	UPLOAD_PROJECT_SCENARIO_DATA_ENDED,
	UPLOAD_PROJECT_SCENARIO_DATA_ERROR,
	UPLOAD_PROJECT_SCENARIO_DATA_STARTED
} from '../../../../global actions/types'
import { store } from '../../../../index'
import {
	getProjectScenario,
	getProjectScenarioParameters,
	updateUserCustomizeDefaultScenario,
	updateUserCustomizeFullTrayAssumption,
	uploadUserSettings
} from '../../../../Services/Network'
import {
	setupCustomValuesToSend,
	setupValueToSend
} from './CustomizeSupplyChainService'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const onEditToggle = () => {
	return {
		type: UPLOAD_PROJECT_SCENARIO_DATA_EDIT,
		payload: {
			isEdit: true
		}
	}
}

export const onCancelToggle = () => {
	return {
		type: UPLOAD_PROJECT_SCENARIO_DATA_CANCEL,
		payload: {
			isEdit: false
		}
	}
}

export const updateDataValue = (
	value: number | string,
	name: string,
	labelKey: string
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: UPLOAD_PROJECT_SCENARIO_DATA_CHANGE,
			payload: {
				value,
				name,
				labelKey
			}
		})
	}
}

export const onApplyData = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const { supplyChainTableData } =
				store.getState().CustomizeSupplyChainReducer
			const { adminSelectedOrganizationId } = store.getState().CustomizeReducer

			const preparedDataToUpdate = setupValueToSend(supplyChainTableData)

			dispatch({
				type: UPLOAD_PROJECT_SCENARIO_DATA_STARTED
			})

			await uploadUserSettings(
				{
					projectSettingsScenarios: preparedDataToUpdate
				},
				adminSelectedOrganizationId
			)

			let defaultProjectScenario = await getProjectScenario(
				adminSelectedOrganizationId
			)
			let defaultProjectScenarioParameters =
				await getProjectScenarioParameters()

			defaultProjectScenario =
				defaultProjectScenario?.data?.defaultProjectScenario
			defaultProjectScenarioParameters =
				defaultProjectScenarioParameters?.data?.defaultProjectScenarioParams
			if (defaultProjectScenario) {
				dispatch({
					type: UPLOAD_PROJECT_SCENARIO_DATA_ENDED,
					payload: defaultProjectScenario,
					defaultProjectScenarioParameters
				})
			}
		} catch (err) {
			dispatch({
				type: UPLOAD_PROJECT_SCENARIO_DATA_ERROR
			})
		}
	}
}

export const onDefaultSecarioRadioSwitch = (
	fullTrayAssumptionOn: boolean,
	defaultScenario: string,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await updateUserCustomizeDefaultScenario(
				defaultScenario,
				fullTrayAssumptionOn,
				organizationId
			)
			dispatch({
				type: DEFAULT_SCENARIO_CHANGED,
				payload: defaultScenario
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'DEFAULT_PROJECT_SCENARIO_UPDATE_SUCCESS'
					)
				}
			})
		} catch (err) {
			dispatch({
				type: UPLOAD_PROJECT_SCENARIO_DATA_ERROR
			})
		}
	}
}
