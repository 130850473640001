import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { capitalize } from 'lodash'

import { IProjectPdfOptions } from '../SolutionPdfInterface'
import { prepareShowHideElements } from '../SolutionPdfService'
import AnalysisParametersPdf from './AnalysisParametersPdf'
import ConfigurationPdf from './ConfigurationPdf'
import CostAnalysisPdf from './CostAnalysisPdf'
import CostInformationPdf from './CostInformationPdf'
import GeometryAnalysisPdf from './GeometryAnalysisPdf'
import LeadTimePdf from './LeadTimePdf'
import MaterialAnalysisPdf from './MaterialAnalysisPdf'
import PartInformationPdf from './PartInformationPdf'
import FooterPdf from 'Scenes/Components/PdfComponents/FooterPdf'
import HeaderPdf from 'Scenes/Components/PdfComponents/HeaderPdf'
import RowComponent from 'Scenes/Components/PdfComponents/RowPdf'
import { defaultNamingPrinterConfiguration } from 'Services/Constants'
import { usePDFReduxState } from 'Services/CustomHooks/usePdfHooks'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

import './SolutionExportPdf.scss'

interface IProps {
	configurationId: number
	projectPdfOptions: IProjectPdfOptions
	useUnprintableCheckboxes: boolean
}

const SolutionPdf: FC<IProps> = ({
	configurationId,
	projectPdfOptions,
	useUnprintableCheckboxes
}) => {
	const configuration = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer.states[configurationId]
	})
	const { userUnitSystem } = useSelector((state: RootStateOrAny) => state.user)
	const {
		part,
		fileTiltleName,
		initialBatchSize,
		configurations,
		userCompany
	} = usePDFReduxState(configurationId, configuration.organizationId)

	const showHideElements = prepareShowHideElements(
		projectPdfOptions,
		useUnprintableCheckboxes
	)
	const showEmission = Feature.isFeatureOn(FeatureComponentId.CO2_EMISSIONS)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)
	const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric
	const currentConfiguration = configurations.find(
		(conf: any) => conf.id === configurationId
	)
	const partName =
		part.partName || part.partNumber || part.partFileName || fileTiltleName
	const isStandardCostManufacturing =
		currentConfiguration.manufactureMethod === ImanufacturingTypes.standardCost
	const isStandardCostProvided =
		isStandardCostManufacturing || part.blockManufacturingMethodOperation
	const isWeightReductionConfig =
		configuration.solutionName ===
		defaultNamingPrinterConfiguration.weightReduction
	const isAmOriginalMaterial = configuration.isAmOriginalMaterial

	return (
		<>
			<div className="page-view">
				<HeaderPdf
					isSolutionPage
					userCompany={userCompany}
					headerText={getString('AM_ANALYSIS')}
					headerDetailsText={`${getString('PART_NAME')}: ${capitalize(
						partName
					)}`}
				/>
				<table className="export-pdf">
					<thead>
						<RowComponent>
							<div className="header-space" />
						</RowComponent>
					</thead>
					<tbody>
						<RowComponent>
							<PartInformationPdf
								configurationId={configurationId}
								configuration={configuration}
								part={part}
								partName={partName}
								show={showHideElements.partInformation}
								unitSystem={unitSystem}
							/>
						</RowComponent>
						<RowComponent>
							<ConfigurationPdf
								showEmission={showHideElements.co2Emission && showEmission}
								manufacturingMethod={configuration.manufacturingMethod}
								initialBatchSize={initialBatchSize}
								configuration={currentConfiguration}
								part={part}
								show={showHideElements.amSolution}
								isAmOriginalMaterial={isAmOriginalMaterial}
							/>
						</RowComponent>
						{!part.isDrawing && (
							<RowComponent>
								<AnalysisParametersPdf
									isStandardCostManufacturing={isStandardCostManufacturing}
									isStandardCost={isStandardCostProvided}
									solution={currentConfiguration.solution}
									configuration={currentConfiguration || configuration}
									fullConfiguration={configuration}
									part={part}
									show={showHideElements.summaryTable}
									isNotSpecifiedQuantity={
										!configuration.isSpecifiedQuantity ||
										!part.isSpecifiedQuantity
									}
									isAmOriginalMaterial={isAmOriginalMaterial}
								/>
							</RowComponent>
						)}
						<RowComponent>
							<MaterialAnalysisPdf
								solution={currentConfiguration.solution}
								material={currentConfiguration.material}
								solutionPriorities={configuration.solutionPriorities}
								showAllMaterialCompars={configuration.showAllMaterialCompars}
								configurationId={configurationId}
								show={showHideElements.materialAnalysis}
								isAmOriginalMaterial={isAmOriginalMaterial}
								unitSystem={unitSystem}
							/>
						</RowComponent>
						<RowComponent>
							<GeometryAnalysisPdf
								analysisResultsRows={configuration.analysisResultsRows}
								part={part}
								show={showHideElements.geometryAnalysis}
							/>
						</RowComponent>
						{!isWeightReductionConfig && (
							<RowComponent>
								<CostInformationPdf
									configuration={currentConfiguration || configuration}
									configurationId={configurationId}
									show={showHideElements.financialBreak}
									isAmOriginalMaterial={isAmOriginalMaterial}
									isStandardCost={isStandardCostManufacturing}
								/>
							</RowComponent>
						)}
						{part.isSpecifiedQuantity && !part.isDrawing && (
							<CostAnalysisPdf
								part={part}
								solution={currentConfiguration.solution}
								configuration={currentConfiguration || configuration}
								manufacturingMethod={configuration.manufacturingMethod}
								show={showHideElements.summaryTable}
							/>
						)}
						{part.isSpecifiedQuantity && (
							<RowComponent>
								<LeadTimePdf
									fullConfiguration={configuration}
									solution={currentConfiguration.solution}
									configuration={currentConfiguration}
									manufacturingMethod={currentConfiguration.manufactureMethod}
									part={part}
									configurationId={configurationId}
									show={showHideElements.leadTime}
									isStandardCostManufacturing={isStandardCostManufacturing}
									isAmOriginalMaterial={isAmOriginalMaterial}
								/>
							</RowComponent>
						)}
					</tbody>
					<tfoot>
						<RowComponent>
							<div className="footer-space" />
						</RowComponent>
					</tfoot>
				</table>
				<FooterPdf userCompany={userCompany} />
			</div>
		</>
	)
}

export default memo(SolutionPdf)
