import React, { FC, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { FormControlLabel } from '@material-ui/core'

import {
	onOrganizationEditSubmit,
	onOrganizationNameChange,
	onOrganizationPrivacyChange
} from '../CustomizeActions'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import {
	CustomInput,
	Danger
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	show: boolean
	savedOrganizationName?: string
	isOrganizationPrivate: boolean
	organizationId: number
	onCancel: () => void
}
const EditOrganizationModal: FC<IProps> = ({
	show,
	savedOrganizationName,
	isOrganizationPrivate,
	organizationId,
	onCancel
}) => {
	const [loading, setLoading] = useState(false)
	const { organizationName, organizationIsPrivate, organizationNameError } =
		useSelector((state: RootStateOrAny) => state.CustomizeReducer)
	const dispatch = useDispatch()

	const isModalConfirmDisabled =
		!!organizationNameError ||
		(organizationName === savedOrganizationName &&
			isOrganizationPrivate === organizationIsPrivate)

	const handleOrganizationNameChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		dispatch(onOrganizationNameChange(e.target.value))
	}

	const handleOrganizationPrivacyChange = (checked: boolean) => {
		dispatch(onOrganizationPrivacyChange(checked))
	}

	const handleConfirmModal = () => {
		dispatch(
			onOrganizationEditSubmit(
				organizationId,
				organizationName,
				organizationIsPrivate,
				setLoading,
				onCancel
			)
		)
	}

	useEffect(() => {
		if (
			savedOrganizationName &&
			(!organizationName || savedOrganizationName !== organizationName)
		) {
			dispatch(onOrganizationNameChange(savedOrganizationName))
		}
		if (isOrganizationPrivate !== organizationIsPrivate) {
			dispatch(onOrganizationPrivacyChange(isOrganizationPrivate))
		}
	}, [show])

	return (
		<CastorAlert
			alertBodyClass="costing-function-editor--alert"
			headerTitle={getString('EDIT_ORGANIZATION_MODAL_TITLE')}
			show={show}
			onConfirm={handleConfirmModal}
			showCancel={true}
			onCancel={onCancel}
			cancelOptionalText={getString('CANCEL')}
			confirmOptionalText={getString('APPLY')}
			onFooterCancel={onCancel}
			disabled={isModalConfirmDisabled}
			loadingCalculation={loading}
		>
			<div className="edit-organization--alert-body">
				<div className="edit-organization--alert-body--name-input">
					<CustomInput
						error={!!organizationNameError}
						labelText={getString('EDIT_ORGANIZATION_NAME_LABEL')}
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							onChange: handleOrganizationNameChange,
							type: 'text',
							value: organizationName
						}}
					/>
					{!!organizationNameError && (
						<Danger
							style={{
								textAlign: 'left',
								position: 'absolute',
								bottom: '-10px',
								fontSize: '12px'
							}}
						>
							{organizationNameError}
						</Danger>
					)}
				</div>
				<div className="edit-organization--alert-body--private-checkbox">
					<FormControlLabel
						label={getString('PRIVATE')}
						classes={{ root: 'edit-organization--alert-body--checkbox' }}
						control={
							<CastorCheckbox
								checked={organizationIsPrivate}
								inputProps={{
									'aria-label': 'secondary checkbox'
								}}
							/>
						}
						onChange={(e, checked) => handleOrganizationPrivacyChange(checked)}
					/>
					<DetailsPopup
						isHover={true}
						data={getString('EDIT_ORGANIZATION_PRIVACY_INFO')}
						popperDirection="right"
						popperClassName="info-box-wrapper"
						popperContactClassName="info-box-data"
					>
						<IconFactory
							iconName="info"
							className="edit-organization--alert-body--private-checkbox--info"
						/>
					</DetailsPopup>
				</div>
			</div>
		</CastorAlert>
	)
}

export default EditOrganizationModal
