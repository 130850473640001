import React, { FC, memo } from 'react'
import AutosizeInput from 'react-input-autosize'

import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import { ActionWithPayload } from '../../../../../../global actions/ActionModels'
import useWindowDimensions from '../../../../../../Services/CustomHooks/useWindowDimensions'
import { ISolutionBenefit } from '../../../../../../Services/models/ISolutionBenefit'
import { MATERIAL_COMPARISON_NAME } from '../../../../../../Services/Strings'
import { CastorPartBenefits } from '../../../../../Components/CastorPartBenefits/CastorPartBenefits'
import IconFactory from '../../../../../Components/StarIcon/IconFactory'

import '../SolutionAnalysis.scss'

const IconButtonTS: any = IconButton
const EditIconTS: any = EditIcon
const AutosizeInputTSX: any = AutosizeInput

interface IProps {
	showSolutionDetails: boolean
	benefits: Array<ISolutionBenefit>
	showFullIcon?: boolean
	editMode?: boolean
	solutionColor: string
	newSolution?: boolean
	configurationId: number
	solutionName: string
	onInputFocusOut: Function
	showBenefits: boolean
	disableConfiguration: boolean
	isDesktopConfiguration: boolean
	onInputFocusIn: (id: number) => ActionWithPayload<any>
	onSolutionNameChange: (
		id: number,
		solutionName: string
	) => ActionWithPayload<any>
	isAmOriginalMaterial: boolean
}

const onNameClick = (
	e: any,
	onInputFocusIn: (id: number) => ActionWithPayload<any>,
	configurationId: number
): void => {
	e.stopPropagation()
	onInputFocusIn(configurationId)
}

const renderBenefits = (
	benefits: Array<ISolutionBenefit>,
	showBenefits: boolean,
	windowWidth: number,
	isAmOriginalMaterial: boolean,
	showFullIcon?: boolean
) => {
	if (!benefits || (benefits && !benefits.length) || !showBenefits) {
		return <div />
	}

	return (
		<CastorPartBenefits
			showHoverData={false}
			expandIconOnHover={true}
			showFullIcon={showFullIcon}
			small={true}
			benefits={benefits}
			className={'solution-analysis-header-benefits'}
			showWeightBenefit={false}
			isAmOriginalMaterial={isAmOriginalMaterial}
		/>
	)
}
const SolutionHeaderName: FC<IProps> = ({
	showSolutionDetails,
	benefits,
	editMode,
	solutionColor,
	onInputFocusOut,
	onInputFocusIn,
	configurationId,
	solutionName,
	onSolutionNameChange,
	showBenefits,
	isDesktopConfiguration,
	disableConfiguration,
	showFullIcon = false,
	isAmOriginalMaterial
}) => {
	const { width } = useWindowDimensions()

	return (
		<div className={'solution-analysis-header-wrapper'}>
			<IconButtonTS
				onClick={() => {
					const inputElement: any = document
						.getElementsByClassName(
							`solution-analysis-header-name ${configurationId}`
						)[0]
						.getElementsByTagName('input')[0]
					inputElement.focus()
				}}
			>
				<EditIconTS />
			</IconButtonTS>
			<AutosizeInputTSX
				disabled={isDesktopConfiguration || disableConfiguration}
				value={solutionName}
				title={solutionName}
				className={`solution-analysis-header-name ${configurationId} ${
					isDesktopConfiguration ? 'solution-analysis-header-name--desktop' : ''
				} ${editMode ? 'edit' : ''}`}
				inputStyle={{ color: solutionColor }}
				placeholder={`${MATERIAL_COMPARISON_NAME}...`}
				onClick={(e: any) => onNameClick(e, onInputFocusIn, configurationId)}
				onBlur={onInputFocusOut}
				onChange={(e: any) => {
					onSolutionNameChange(configurationId, e.target.value)
				}}
			/>
			<IconFactory
				iconName="chevronRight"
				className={`solution-analysis-header-chevron ${
					showSolutionDetails ? 'solution-analysis-header-chevron-rotate' : ''
				}`}
				data="open_close_configuration"
			/>
			{renderBenefits(
				benefits,
				showBenefits,
				width,
				isAmOriginalMaterial,
				showFullIcon
			)}
		</div>
	)
}
export default memo(SolutionHeaderName)
