import { FC, memo } from 'react'

import CustomizeCO2PowerTypicalKwh from './CO2TypicalKwh/CustomizeCO2PowerTypicalKwh'
import CustomizeCO2СarbonTypicalKwh from './CO2TypicalKwh/CustomizeCO2СarbonTypicalKwh'

const CustomizeCO2TypicalKwh: FC = () => {
	return (
		<>
			<CustomizeCO2PowerTypicalKwh />
			<CustomizeCO2СarbonTypicalKwh />
		</>
	)
}

export default memo(CustomizeCO2TypicalKwh)
