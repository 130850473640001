import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CustomizeCO2ProductUse from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2ProductUse/CustomizeCO2ProductUse'
import {
	onPuYearsCO2Change,
	onSetUserCustomizationSettings,
	onPuAnnualKgCO2Change
} from 'Scenes/Home/Customize/CustomizeCO2Calculation/CustomizeCO2CalculationAction'
import { getString } from 'Services/Strings/StringService'

const ProductUse: FC = () => {
	const { puAnnualKgCO2, puYearsCO2 } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { puAnnualKgCO2Value, puYearsCO2Value } = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const dispatch = useDispatch()

	return (
		<div
			className="material-production--wrapper product-use"
			data-qa="data-qa-product-use-section"
		>
			<div className="material-production--header">
				{getString('CO2_PARAMETERS_PRODUCT_USE_HEADER_TEXT')}
			</div>
			<CustomizeCO2ProductUse
				onPuAnnualKgCO2Change={(value: number) =>
					dispatch(onPuAnnualKgCO2Change(value))
				}
				onPuYearsCO2Change={(value: number) =>
					dispatch(onPuYearsCO2Change(value))
				}
				puAnnualKgCO2Value={puAnnualKgCO2Value}
				puYearsCO2Value={puYearsCO2Value}
				setUserCustomizationSettings={(value: number, parameter: string) =>
					dispatch(
						onSetUserCustomizationSettings(
							value,
							parameter,
							undefined,
							adminSelectedOrganizationId
						)
					)
				}
				puAnnualKgCO2={puAnnualKgCO2}
				puYearsCO2={puYearsCO2}
			></CustomizeCO2ProductUse>
		</div>
	)
}

export default ProductUse
