import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { isEmpty } from 'lodash'

import * as CustomizeActions from './CustomizeActions'
import { getThemeString } from '../../../themes/getThemeString'
import CastorForm from '../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import Devider from '../../Components/Devider/Devider'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import CustomizeFormSection from './CustomizeFormSection'
import { POST_PROCESSES_BLOCK_ID_HASH } from './CustomizeUserPostProcesses/Constants'
import OrganizationPanel from './OrganizationPanel/OrganizationPanel'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	CUSTOMIZE_CO2_CALCULATIONS_ROUTE,
	CUSTOMIZE_COST_AND_LEAD_ROUTE,
	CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE,
	CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE,
	CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE,
	CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE,
	CUSTOMIZE_SOLUTION_FILTER_ROUTE,
	CUSTOMIZE_TAGS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { customizeUserMaterial, uploadProjectRoute } from 'Services/routeFuncs'
import { OK } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { LengthUnit } from 'Services/UnitsConversionService'

import { styles } from '../styles/formStyles.css'
import './CustomizeForm.scss'

class CustomizeForm extends Component {
	componentDidMount = () => {
		const {
			fetchCustomizeData,
			costAndLeadNames,
			settingsData,
			printersFullData,
			organization_owner,
			history,
			userDetails,
			adminSelectedOrganizationId
		} = this.props
		if (!isEmpty(userDetails) && !organization_owner) {
			history.push(uploadProjectRoute())
		}
		fetchCustomizeData(
			costAndLeadNames,
			settingsData.userSettings,
			null,
			printersFullData,
			adminSelectedOrganizationId
		)
	}

	componentDidUpdate = prevProps => {
		const {
			fetchCustomizeData,
			costAndLeadNames,
			settingsData,
			printersFullData,
			adminSelectedOrganizationId
		} = this.props
		if (
			prevProps.adminSelectedOrganizationId &&
			prevProps.adminSelectedOrganizationId !== adminSelectedOrganizationId
		) {
			fetchCustomizeData(
				costAndLeadNames,
				settingsData.userSettings,
				null,
				printersFullData,
				adminSelectedOrganizationId
			)
		}
	}

	componentWillUnmount() {
		const { showCustomFunctionsWarning, onCustomFunctionsAlertClose } =
			this.props
		if (showCustomFunctionsWarning) {
			onCustomFunctionsAlertClose()
		}
	}

	renderCustomizeInHousePrinters = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_IN_HOUSE_PRINTERS)) {
			return <div></div>
		}
		return (
			<>
				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE}
					qaDataElementName="data-qa-customize-in-house-printers"
				/>
				<Devider className="customize-form--divider" componentId={2} />
			</>
		)
	}
	renderFormHeader = () => {
		return (
			<div className="customize-settings--header">
				<CastorFormHeader
					explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
					explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
				/>
				<OrganizationPanel showEdit showAdd />
			</div>
		)
	}
	renderSemiProPrinters = (
		desktopPrinter,
		onToggleDesktopPrinter,
		isShowSemiProfessionalPrintersToggle,
		adminSelectedOrganizationId
	) => {
		if (
			!Feature.isFeatureOn(
				FeatureComponentId.CUSTOMIZE_USER_SEMI_PRO_PRINTERS
			) ||
			!isShowSemiProfessionalPrintersToggle
		) {
			return <div></div>
		}
		return (
			<>
				<Devider className="customize-form--divider" componentId={2} />
				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_USER_SEMI_MATERIAL_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_USER_SEMI_MATERIAL_EXPLANATION_ARRAY'
					)}
					switchChecked={desktopPrinter}
					onToggleChange={() =>
						onToggleDesktopPrinter(desktopPrinter, adminSelectedOrganizationId)
					}
					qaDataElementName="data-qa-semi-professional-printers"
				/>
			</>
		)
	}

	renderCustomizeFilters = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_FILTERS)) {
			return <div />
		}
		return (
			<>
				<CustomizeFormSection
					explanationHeader={getString('CUSTOMIZE_SOLUTION_FILTER_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_SOLUTION_FILTER_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_SOLUTION_FILTER_ROUTE}
					qaDataElementName="data-qa-customize-solution-filtering"
				/>
				<Devider className="customize-form--divider" />
			</>
		)
	}

	renderMaterialNamesMapping = () => {
		if (
			!Feature.isFeatureOn(FeatureComponentId.MATERIAL_NAMES_MAPPING) ||
			!Feature.isFeatureOn(FeatureComponentId.DRAWING_ANALYSIS)
		) {
			return <div />
		}
		return (
			<>
				<CustomizeFormSection
					explanationHeader={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE'
					)}
					explanationArray={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE}
					qaDataElementName="data-qa-customize-material-names"
				/>
				<Devider className="customize-form--divider" />
			</>
		)
	}

	renderSustainabilityLifeCycle = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.SUSTAINABILITY_LIFE_CYCLE)) {
			return <div />
		}
		return (
			<>
				<Devider className="customize-form--divider" />
				<CustomizeFormSection
					explanationHeader={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}
					explanationArray={getString('CUSTOMIZE_CO2_CALCULATIONS_ARRAY')}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_CO2_CALCULATIONS_ROUTE}
					qaDataElementName="data-qa-customize-sustainability"
				/>
			</>
		)
	}

	renderCostingFunctionEditor = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.COST_FUNCTION_EDITOR)) {
			return <div></div>
		}

		return (
			<>
				<CustomizeFormSection
					explanationHeader={getString('COSTING_FUNCTION_EDITOR_TITLE')}
					explanationArray={getString(
						'COSTING_FUNCTION_EDITOR_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE}
					qaDataElementName="data-qa-costing-function-editor"
				/>
				<Devider className="customize-form--divider" />
			</>
		)
	}

	renderInputUnitsType = (
		selectedUnitsType,
		onSelectedInputUnitsType,
		adminSelectedOrganizationId
	) => {
		if (!Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_UNITS_TYPE)) {
			return <div></div>
		}

		// DE support only inches and mm:
		const cadUnitTypesFiltered = getString('CAD_UNIT_TYPES')?.filter(
			t => t.key === LengthUnit.mm || t.key === LengthUnit.inch
		)

		return (
			<>
				<Devider className="customize-form--divider" componentId={2} />

				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_INPUT_UNITS_TYPE_TITLE')}
					explanationArray={getString('CUSTOMIZE_INPUT_UNITS_TYPE_EXPLANATION')}
					select={true}
					selectTypes={cadUnitTypesFiltered}
					selectPlaceHolder={getString('UNIT_SELECTION_PLACEHOLDER')}
					onSelectedInputUnitsType={e =>
						onSelectedInputUnitsType(
							e.target.value,
							adminSelectedOrganizationId
						)
					}
					selectedValue={selectedUnitsType}
					qaDataElementName="data-qa-customize-input-units-type"
				/>
			</>
		)
	}

	renderUnitSystem = (
		selectedUnitSystem,
		onSelectedUnitSystem,
		adminSelectedOrganizationId
	) => {
		const unitSystems = getString('UNITS_SYSTEMS') || []

		return (
			<>
				<Devider className="customize-form--divider" componentId={2} />

				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_UNITS_SYSTEM_TITLE')}
					explanationArray={getString('CUSTOMIZE_UNITS_SYSTEM_DESCRIPTION')}
					select={true}
					selectTypes={unitSystems}
					selectPlaceHolder={getString('UNITS_SYSTEM_PLACEHOLDER')}
					onSelectedInputUnitsType={e =>
						onSelectedUnitSystem(e.target.value, adminSelectedOrganizationId)
					}
					selectedValue={selectedUnitSystem}
					qaDataElementName="data-qa-customize-units-system"
				/>
			</>
		)
	}

	renderRecalculateAllParts = () => {
		if (!Feature.isFeatureOn(FeatureComponentId.RECALCULATE_PROJECTS)) {
			return <div></div>
		}

		return (
			<>
				<Devider className="customize-form--divider" componentId={2} />

				<CustomizeFormSection
					componentId={2}
					explanationHeader={getString('CUSTOMIZE_RECALCULATE_PROJECTS_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_RECALCULATE_PROJECTS_EXPLANATION'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE}
					qaDataElementName="data-qa-customize-recalculate-projects"
					buttonName={getString('CHOOSE_PROJECTS_PLACEHOLDER')}
				/>
			</>
		)
	}

	renderCustomFunctionsAlert = () => {
		const { showCustomFunctionsWarning, onCustomFunctionsAlertClose } =
			this.props

		return (
			<CastorAlert
				headerTitle={getString('CONFIGURATION_CHANGES_WARNING')}
				show={showCustomFunctionsWarning}
				onConfirm={onCustomFunctionsAlertClose}
				confirmOptionalText={OK}
				onCancel={onCustomFunctionsAlertClose}
				alertType={AlertType.WARNING}
				showCancel={false}
			>
				<div className="custom-functions-alert-body">
					{getString('SWITCH_UNIT_SYSTEM_WITH_CUSTOM_FUNCTIONS_PART1')}
					<Link
						to={USER_HOME_ROUTE + CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE}
						target="_blank"
					>
						{getString('COST_FUNCTION_EDITOR')}
					</Link>
					{` ${getString('AND')} `}
					<Link
						to={
							USER_HOME_ROUTE +
							CUSTOMIZE_COST_AND_LEAD_ROUTE +
							POST_PROCESSES_BLOCK_ID_HASH
						}
						target="_blank"
					>
						{getString('CUSTOMIZE_MACHINING_TITLE')}
					</Link>
					{getString('SWITCH_UNIT_SYSTEM_WITH_CUSTOM_FUNCTIONS_PART2')}
				</div>
			</CastorAlert>
		)
	}

	renderInitialFormContent = () => {
		const customizeUnitSystem = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
		)
		const {
			onToggleDesktopPrinter,
			desktopPrinter,
			isShowSemiProfessionalPrintersToggle,
			selectedUnitsType,
			onSelectedInputUnitsType,
			adminSelectedOrganizationId,
			selectedUnitSystem,
			onSelectedUnitSystem
		} = this.props
		return (
			<Flexbox flexDirection="column" style={styles.formSuperview}>
				{this.renderCustomizeInHousePrinters()}

				<CustomizeFormSection
					explanationHeader={getString('CUSTOMIZE_COST_AND_LEAD_TITLE')}
					explanationArray={getString(
						'CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_COST_AND_LEAD_ROUTE}
					qaDataElementName="data-qa-cost-calculation-params"
				/>

				{this.renderSustainabilityLifeCycle()}
				<Devider className="customize-form--divider" />

				{this.renderCustomizeFilters()}
				{this.renderMaterialNamesMapping()}
				{this.renderCostingFunctionEditor()}
				<CustomizeFormSection
					explanationHeader={getString('CUSTOMIZE_OFF_THE_SHELF_TITLE')}
					explanationArray={getThemeString(
						'CUSTOMIZE_OFF_THE_SHELF_EXPLANATION_ARRAY'
					)}
					linkTo={USER_HOME_ROUTE + CUSTOMIZE_TAGS_ROUTE}
					qaDataElementName="data-customize-part-filtering"
				/>
				{Feature.isFeatureOn(FeatureComponentId.CUSTOM_MATERIALS) ? (
					<>
						<Devider className="customize-form--divider" />
						<CustomizeFormSection
							explanationHeader={getString('CUSTOMIZE_USER_MATERIAL_TITLE')}
							explanationArray={getThemeString(
								'CUSTOMIZE_SETTINGS_MATERIAL_EXPLANATION_ARRAY'
							)}
							linkTo={customizeUserMaterial()}
							qaDataElementName="data-qa-add-material"
						/>
					</>
				) : (
					<></>
				)}
				{this.renderSemiProPrinters(
					desktopPrinter,
					onToggleDesktopPrinter,
					isShowSemiProfessionalPrintersToggle,
					adminSelectedOrganizationId
				)}
				{customizeUnitSystem
					? this.renderUnitSystem(
							selectedUnitSystem,
							onSelectedUnitSystem,
							adminSelectedOrganizationId
					  )
					: this.renderInputUnitsType(
							selectedUnitsType,
							onSelectedInputUnitsType,
							adminSelectedOrganizationId
					  )}
				{this.renderRecalculateAllParts()}
				{this.renderCustomFunctionsAlert()}
			</Flexbox>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
				<CastorForm
					formHeader={this.renderFormHeader()}
					formTitle={getString('CUSTOMIZE_FORM_HEADER')}
					content={this.renderInitialFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({
	CostAndLeadReducer: { settingsData, costAndLeadNames },
	user: { printersFullData, userDetails },
	CustomizeReducer
}) => {
	return {
		settingsData,
		costAndLeadNames,
		printersFullData,
		userDetails,
		organization_owner: userDetails.organization_owner,
		...CustomizeReducer
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeForm)
