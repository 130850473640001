import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { differenceWith, isEqual } from 'lodash'

import { store } from '../../../../..'
import { HANDLE_NOTIFICATION } from 'global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import {
	EDIT_COST_ANALYSIS_DATA,
	GET_COST_ANALYSIS_DATA,
	UPDATING_COST_ANALYSIS_DATA
} from 'Scenes/Home/Customize/CustomizeAnalysisCost/CustomizeAnalysisCostTypes'
import { ICostAnalysisParam } from 'Services/models/ICostAnalysisParameter'
import {
	getCostAnalysisParameters,
	updateCostAnalysisParameters
} from 'Services/Network'
import { OK, SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const getCostAnalysisData = (organizationId?: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await getCostAnalysisParameters(organizationId)
			const costAnalysisData = response.data?.costAnalysisParameters

			dispatch({
				type: GET_COST_ANALYSIS_DATA,
				payload: {
					costAnalysisData
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
}

export const onChangeCategory = (category: string, on: boolean) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: EDIT_COST_ANALYSIS_DATA,
			payload: {
				category,
				on
			}
		})
	}
}

export const updateCostAnalysisData = (
	costData: ICostAnalysisParam[],
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({ type: UPDATING_COST_ANALYSIS_DATA, payload: true })
			let { initialCostAnalysis } =
				store.getState().CustomizeAnalysisCostReducer
			const costAnalysisParams = differenceWith(
				costData,
				initialCostAnalysis,
				isEqual
			)
			const response = await updateCostAnalysisParameters(
				costAnalysisParams,
				organizationId
			)

			dispatch({ type: UPDATING_COST_ANALYSIS_DATA, payload: false })
			dispatch({
				type: GET_COST_ANALYSIS_DATA,
				payload: {
					costAnalysisData: response?.data?.costAnalysisParameters || []
				}
			})
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('CONSIDER_RECALCULATING_ALERT_BODY'),
					headerTitle: getString('CONSIDER_RECALCULATING_ALERT_HEADER'),
					alertType: AlertType.SUCCESS,
					showCancel: false,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		} catch (err) {
			dispatch({ type: UPDATING_COST_ANALYSIS_DATA, payload: false })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('DATA_WAS_NOT_UPDATED')
				}
			})
			console.log(err)
		}
	}
}
