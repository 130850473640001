import {
	COST_AND_LEAD_ACTION_BUTTON_TEXT_CHANGED,
	COST_AND_LEAD_INPUTS_CHANGED,
	COST_AND_LEAD_TBL_DATA_UPDATED,
	CUSTOMIZE_BUTTON_LOADER_UPDATED,
	CUSTOMIZE_PRINTER_PARAMETERS_CHANGED,
	CUSTOMIZE_PRINTER_PROPS_COMPANY_CHANGED,
	CUSTOMIZE_PRINTER_PROPS_LAYER_THICKNESS_CHANGED,
	CUSTOMIZE_PRINTER_PROPS_PRINTER_PRICE_CHANGED,
	CUSTOMIZE_PRINTER_PROPS_PRINTER_SELECTED,
	CUSTOMIZE_PRINTER_PROPS_SETUPED,
	CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_TOGGLED,
	CUSTOMIZE_PRINTER_SETTINGS_DELETED,
	CUSTOMIZE_USER_SETTINGS_ADDED,
	DRAWING_COST_APPLY_TOGGLE,
	DRAWING_COST_EDIT_ALL_TOGGLE,
	DRAWING_COST_EDIT_DATA,
	DRAWING_COST_EDIT_TOGGLE,
	DRAWING_COST_UPDATE_ALL_DATA,
	DRAWING_COST_UPDATE_DATA,
	EDIT_USER_SETTING_CANCELED,
	EDITABLE_PRINTER_COSTS_CHANGE,
	HANDLE_NOTIFICATION,
	SETUP_PRINTER_COSTS,
	USER_SETTING_CHANGED,
	USER_SETTING_GOT_ERROR,
	VALIDATION_STATE_CHANGED,
	VALUE_CHANGED_TO_EDIT
} from '../../../../global actions/types'
import {
	DEFAULT_VALUE,
	ENTER_KEY_CODE,
	ESC_KEY_CODE
} from '../../../../Services/Constants'
import {
	createCustomizationPrinterSetting,
	updateDrawingCosts,
	uploadUserSettings
} from '../../../../Services/Network'
import { updateCustomizationPrinterSetting } from '../../../../Services/Network'
import {
	CONFIGURATION_CALCULATION_ERROR,
	COST_AND_LEAD_BUTTON_ACTIONS,
	SHOW_NOTIFICATION
} from '../../../../Services/Strings'
import { SimpleConfigurationSelectorService } from '../../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { getNewInputs, setupValidationStateKeys } from '../CustomizeLogic'
import { getPrinterCostInputErrorMessage } from './CostAndLeadService'
import { ValidationStatus } from './CustomizePrinterProps/CustomizePrinterCostsTypes'

let simpleConfigurationSelectorService =
	new SimpleConfigurationSelectorService()

export const onTextInputKeyPressed = (
	keyCode,
	inputKey,
	settingsData,
	userSettingsWithError,
	inputs,
	originalUserSettingsValues,
	costAndLeadNames,
	organizationId
) => {
	if (keyCode === ENTER_KEY_CODE && !userSettingsWithError[inputKey]) {
		return onActionButtonClicked(
			COST_AND_LEAD_BUTTON_ACTIONS.APPLY,
			settingsData,
			inputKey,
			inputs,
			originalUserSettingsValues,
			costAndLeadNames,
			organizationId
		)
	} else if (keyCode === ESC_KEY_CODE) {
		return onActionButtonClicked(
			COST_AND_LEAD_BUTTON_ACTIONS.CANCEL,
			settingsData,
			inputKey,
			inputs,
			originalUserSettingsValues,
			costAndLeadNames
		)
	} else {
		return {
			type: ''
		}
	}
}

export const onTextInputChanged = (
	value,
	validationType,
	stateNameEqualTo,
	maxValue,
	fieldType,
	inputKey,
	settingsData,
	actionButtonTexts
) => {
	return dispatch => {
		value = fieldType === 'text' ? parseInt(value) : value
		let propValue, isError
		switch (validationType) {
			case 'range':
				if (
					(fieldType === 'text' && verifyNumber(value)) ||
					fieldType === 'number'
				) {
					if (value >= stateNameEqualTo && value <= maxValue) {
						propValue = 'success'
						isError = false
					} else if (value) {
						propValue = 'error'
						isError = true
					} else {
						propValue = ''
						isError = false
					}
					handleValidationChanged(
						dispatch,
						validationType,
						inputKey,
						propValue,
						isError,
						actionButtonTexts
					)
				}
				break
			case 'min':
				if (
					(fieldType === 'text' && verifyNumber(value)) ||
					fieldType === 'number'
				) {
					if (value >= stateNameEqualTo) {
						propValue = 'success'
						isError = false
					} else if (value) {
						propValue = 'error'
						isError = true
					} else {
						propValue = ''
						isError = false
					}
					handleValidationChanged(
						dispatch,
						validationType,
						inputKey,
						propValue,
						isError,
						actionButtonTexts
					)
				}
				break
			default: {
				value = ''
				break
			}
		}

		changeUserSettings(settingsData, inputKey, value, dispatch)
	}
}

export const editUserSetting = (settingsData, key, inputs, value) => {
	const userSettings = { ...settingsData.userSettings, [key]: value }
	const newSettingsData = { ...settingsData, userSettings: userSettings }
	const newEditInputs = getNewInputs(key, inputs, false)
	return {
		type: VALUE_CHANGED_TO_EDIT,
		payload: { newSettingsData, newEditInputs }
	}
}

export const applyEditField = (
	inputKey,
	inputs,
	settingsData,
	validationStateKeyName,
	organizationId
) => {
	return dispatch => {
		uploadUserSettings(
			{
				[inputKey]: settingsData.userSettings[inputKey]
			},
			organizationId
		)
			.then(response => {
				const data = response.data
				const userSettings = {
					...settingsData.userSettings,
					[inputKey]: data.userSettings[inputKey]
				}
				const newSettingsData = { ...settingsData, userSettings }
				const newEditInputs = getNewInputs(inputKey, inputs, false)
				const validationStateKeys = {}
				if (validationStateKeyName) {
					validationStateKeys[validationStateKeyName] = ''
				}

				dispatch({
					type: COST_AND_LEAD_TBL_DATA_UPDATED,
					payload: {
						settingsData: newSettingsData,
						newEditInputs,
						originalUserSettingsValues: data.userSettings,
						inputKey,
						validationStateKeys
					}
				})
			})
			.catch(error => {
				console.error(error)
			})
	}
}

export const updateUserSettings = (
	settingsData,
	inputs,
	costAndLeadNames,
	organizationId
) => {
	return dispatch => {
		dispatch({
			type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
			payload: true
		})
		uploadUserSettings(settingsData.userSettings, organizationId)
			.then(response => {
				const data = response.data
				const newEditInputs = getNewInputs('all', inputs, false)
				const validationStateKeys = {}
				setupValidationStateKeys(
					settingsData,
					costAndLeadNames,
					validationStateKeys
				)
				dispatch({
					type: COST_AND_LEAD_TBL_DATA_UPDATED,
					payload: {
						settingsData: data,
						newEditInputs,
						originalUserSettingsValues: data.userSettings,
						inputKey: 'all',
						validationStateKeys,
						showUploadButton: false
					}
				})
				dispatch({
					type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
					payload: false
				})
			})
			.catch(error => {
				dispatch({
					type: CUSTOMIZE_BUTTON_LOADER_UPDATED,
					payload: false
				})
				console.error(error)
			})
	}
}

export const setupActionButtonText = (isDefault, edit, key) => {
	let actionButtonText = [COST_AND_LEAD_BUTTON_ACTIONS.DEFAULT]
	if (edit) {
		// this field is being edited
		actionButtonText = [COST_AND_LEAD_BUTTON_ACTIONS.CANCEL]
	} else if (isDefault) {
		actionButtonText = [COST_AND_LEAD_BUTTON_ACTIONS.EDIT]
	}
	return {
		type: COST_AND_LEAD_ACTION_BUTTON_TEXT_CHANGED,
		payload: { actionButtonText, key }
	}
}

export const onActionButtonClicked = (
	clickType,
	settingsData,
	inputKey,
	editInputs,
	originalUserSettingsValues,
	costAndLeadNames,
	organizationId
) => {
	const validationType = costAndLeadNames[inputKey].validation.type
	const validationStateKeyName = `${validationType}State_${inputKey}`
	switch (clickType) {
		case COST_AND_LEAD_BUTTON_ACTIONS.DEFAULT:
			return changeFieldToDefault(
				settingsData,
				inputKey,
				editInputs,
				organizationId
			)

		case COST_AND_LEAD_BUTTON_ACTIONS.EDIT:
			return changeFieldToEdit(
				inputKey,
				editInputs,
				settingsData,
				originalUserSettingsValues
			)

		case COST_AND_LEAD_BUTTON_ACTIONS.CANCEL:
			return cancelEditField(
				inputKey,
				editInputs,
				settingsData,
				originalUserSettingsValues,
				validationStateKeyName
			)

		case COST_AND_LEAD_BUTTON_ACTIONS.APPLY:
			return applyEditField(
				inputKey,
				editInputs,
				settingsData,
				validationStateKeyName,
				organizationId
			)
		default:
			break
	}
}

export const setupPrinterPropsData = printersFullData => {
	simpleConfigurationSelectorService = new SimpleConfigurationSelectorService(
		printersFullData,
		false,
		true
	)
	const printerCompanies =
		simpleConfigurationSelectorService.getCompaniesNamesList()
	return {
		type: CUSTOMIZE_PRINTER_PROPS_SETUPED,
		payload: { printerCompanies }
	}
}

export const onPrinterCompanyChange = printerCompany => {
	const printerNames =
		simpleConfigurationSelectorService.getPrintersNamesList(printerCompany)
	return {
		type: CUSTOMIZE_PRINTER_PROPS_COMPANY_CHANGED,
		payload: { printerNames, printerCompany }
	}
}

export const onPrinterCostParameterChange = printerCostParameters => {
	return {
		type: CUSTOMIZE_PRINTER_PARAMETERS_CHANGED,
		payload: { printerCostParameters }
	}
}

export const onPrinterNameChange = selectedPrinter => {
	return {
		type: CUSTOMIZE_PRINTER_PROPS_PRINTER_SELECTED,
		payload: { selectedPrinter }
	}
}

export const changeFieldToEdit = (key, editInputs) => {
	return dispatch => {
		const newEditInputs = getNewInputs(key, editInputs, true)
		handleChangeFieldToEdit(key, editInputs, dispatch, newEditInputs)
	}
}

export const onPrinterSettingsAdd = (
	selectedPrinter,
	selectedPrinterCost,
	selectedPrinterHourlyCost,
	selectedPrinterLayerThickness,
	editMode,
	organizationId
) => {
	return async dispatch => {
		try {
			if (!editMode) {
				dispatch({
					type: CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_TOGGLED
				})
			}

			const userPrinterSettingsResponse =
				await createCustomizationPrinterSetting({
					price: selectedPrinterCost,
					hourlyPrice: selectedPrinterHourlyCost,
					defaultLayerThickness: selectedPrinterLayerThickness,
					printer: selectedPrinter.printerId || selectedPrinter.id,
					organizationId
				})

			const userPrinterSettingsData =
				userPrinterSettingsResponse.data.userPrinterSettingsList
			dispatch({
				type: CUSTOMIZE_USER_SETTINGS_ADDED,
				payload: { userPrinterSettingsData }
			})
		} catch (error) {
			console.error(error.message)
			dispatch({
				type: CUSTOMIZE_PRINTER_SETTINGS_BUTTON_LOADER_TOGGLED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onPrinterSettingsDelete = (itemToDelete, organizationId) => {
	return async dispatch => {
		try {
			const userPrinterResponse = await updateCustomizationPrinterSetting(
				itemToDelete,
				organizationId
			)
			const userPrinterSettingsData =
				userPrinterResponse.data.userPrinterSettingsList
			dispatch({
				type: CUSTOMIZE_PRINTER_SETTINGS_DELETED,
				payload: { userPrinterSettingsData }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onPrinterPropsPriceChange = (
	selectedPrice,
	selectedPrinterCostParameter
) => {
	return {
		type: CUSTOMIZE_PRINTER_PROPS_PRINTER_PRICE_CHANGED,
		payload: { selectedPrice, selectedPrinterCostParameter }
	}
}

export const onPropsLayerThicknessChange = selectedThickness => {
	return {
		type: CUSTOMIZE_PRINTER_PROPS_LAYER_THICKNESS_CHANGED,
		payload: { selectedThickness }
	}
}

//privates
const changeUserSettings = (settingsData, key, value, dispatch) => {
	const userSettings = {
		...settingsData.userSettings,
		[key]: value ? value : ''
	}
	const newSettingsData = { ...settingsData, userSettings: userSettings }
	dispatch({
		type: USER_SETTING_CHANGED,
		payload: { newSettingsData }
	})
}

const changeUserSettingsError = (key, isError, actionButtonTexts, dispatch) => {
	let newActionButtonTexts = {}
	if (isError) {
		newActionButtonTexts = {
			...actionButtonTexts,
			[key]: [COST_AND_LEAD_BUTTON_ACTIONS.CANCEL]
		}
	} else {
		newActionButtonTexts = {
			...actionButtonTexts,
			[key]: [
				COST_AND_LEAD_BUTTON_ACTIONS.CANCEL,
				COST_AND_LEAD_BUTTON_ACTIONS.APPLY
			]
		}
	}
	dispatch({
		type: USER_SETTING_GOT_ERROR,
		payload: { key, isError, newActionButtonTexts }
	})
}

// function that verifies if value contains only numbers
const verifyNumber = value => {
	var numberRex = new RegExp('^[0-9]+$|^$')
	if (numberRex.test(value)) {
		return true
	}
	return false
}

const changeFieldToDefault = (settingsData, key, inputs, organizationId) => {
	const userSettings = { ...settingsData.userSettings, [key]: null }
	const newSettingsData = { ...settingsData, userSettings: userSettings }
	return applyEditField(key, inputs, newSettingsData, undefined, organizationId)
}

const cancelEditField = (
	key,
	inputs,
	settingsData,
	originalUserSettingsValues,
	validationStateKeyName
) => {
	const userSettings = {
		...settingsData.userSettings,
		[key]: originalUserSettingsValues[key]
	}
	const newSettingsData = { ...settingsData, userSettings: userSettings }
	const isOriginalValueIsDefaultValue = !originalUserSettingsValues[key]
	const newEditInputs = getNewInputs(key, inputs, false)
	return {
		type: EDIT_USER_SETTING_CANCELED,
		payload: {
			newEditInputs,
			key,
			newSettingsData,
			isOriginalValueIsDefaultValue,
			validationStateKeyName
		}
	}
}
const handleValidationChanged = (
	dispatch,
	validationType,
	inputKey,
	propValue,
	isError,
	actionButtonTexts
) => {
	dispatch({
		type: VALIDATION_STATE_CHANGED,
		payload: { validationType, propValue, inputKey }
	})
	changeUserSettingsError(inputKey, isError, actionButtonTexts, dispatch)
}

const handleChangeFieldToEdit = (key, editInputs, dispatch, newEditInputs) => {
	if (key === 'all') {
		for (const inputKey in editInputs) {
			dispatch({
				type: COST_AND_LEAD_INPUTS_CHANGED,
				payload: { newEditInputs, key: inputKey }
			})
		}
	} else {
		dispatch({
			type: COST_AND_LEAD_INPUTS_CHANGED,
			payload: { newEditInputs, key }
		})
	}
}

export const drawingEditToggle = (isEditButton, parameterKey) => {
	return async dispatch => {
		try {
			dispatch({
				type: DRAWING_COST_EDIT_TOGGLE,
				payload: {
					isEditButton,
					parameterKey
				}
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const drawingEditAllToggle = isEditButton => {
	return async dispatch => {
		try {
			dispatch({
				type: DRAWING_COST_EDIT_ALL_TOGGLE,
				payload: {
					isEditButton
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const drawingApplyToggle = (isApplyButton, parameterKey) => {
	return async dispatch => {
		try {
			dispatch({
				type: DRAWING_COST_APPLY_TOGGLE,
				payload: {
					isApplyButton,
					parameterKey
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const updateDrawingData = (
	updateValue,
	parameterKey,
	organizationId
) => {
	let drawingData = {}

	drawingData[parameterKey] = updateValue

	return async dispatch => {
		try {
			await updateDrawingCosts(drawingData, organizationId)
			dispatch({
				type: DRAWING_COST_UPDATE_DATA,
				payload: { parameterKey, updateValue }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const updateAllDrawingData = (drawingCost2dNames, organizationId) => {
	let drawingData = {}

	for (const parameterKey in drawingCost2dNames) {
		drawingData[parameterKey] = drawingCost2dNames[parameterKey].editInput
	}

	for (const parameterKey in drawingData) {
		if (drawingData[parameterKey] === '') {
			delete drawingData[parameterKey]
		}
	}

	return async dispatch => {
		try {
			await updateDrawingCosts(drawingData, organizationId)
			dispatch({
				type: DRAWING_COST_UPDATE_ALL_DATA,
				payload: { drawingData }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const editDrawingData = (updateValue, parameterKey) => {
	return async dispatch => {
		try {
			dispatch({
				type: DRAWING_COST_EDIT_DATA,
				payload: { parameterKey, updateValue }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const setupPrinterCostsTable = (
	userPrinterSettingsData,
	inHousePrintersData
) => {
	return dispatch => {
		const allPrintersCustomizedCosts = userPrinterSettingsData.filter(
			printer => printer.price || printer.printerHourlyCost
		)
		const editablePrinterCostsList = inHousePrintersData?.reduce(
			(acc, inHousePrinter) => {
				const customizedCostsForInHousePrinter =
					allPrintersCustomizedCosts.find(
						param => param.printer.id === inHousePrinter.id
					)
				const printerCostParam = {
					printer: inHousePrinter,
					price: customizedCostsForInHousePrinter?.price || '',
					printerHourlyCost:
						customizedCostsForInHousePrinter?.printerHourlyCost || '',
					defaultLayerThickness:
						customizedCostsForInHousePrinter?.defaultLayerThickness || '',
					edit: false,
					valueLabel:
						!customizedCostsForInHousePrinter?.printerHourlyCost &&
						!customizedCostsForInHousePrinter?.price
							? DEFAULT_VALUE
							: '',
					validationStatus: { price: '', printerHourlyCost: '' },
					validationMessage: { price: '', printerHourlyCost: '' },
					disabled: { price: false, printerHourlyCost: false }
				}
				acc.push(printerCostParam)
				return acc
			},
			[]
		)
		const deletablePrinterCostsList = allPrintersCustomizedCosts?.reduce(
			(acc, item) => {
				if (
					!inHousePrintersData?.some(printer => printer.id === item.printer.id)
				) {
					const printerCostParam = {
						printer: item.printer,
						price: item.price,
						printerHourlyCost: item.printerHourlyCost,
						defaultLayerThickness: item.defaultLayerThickness
					}
					acc.push(printerCostParam)
				}
				return acc
			},
			[]
		)

		dispatch({
			type: SETUP_PRINTER_COSTS,
			payload: { editablePrinterCostsList, deletablePrinterCostsList }
		})
	}
}

export const onPrinterCostChange = (
	printerId,
	printerCostKey,
	value,
	editablePrinterCostsList,
	isIncorrectNumberValue,
	relatedPrinterCostKey,
	forceDisableRelatedField
) => {
	return dispatch => {
		const updatedEditablePrinterCostsList = [...editablePrinterCostsList]
		const printerIndex = editablePrinterCostsList.findIndex(
			printerCost => printerCost.printer.id === printerId
		)
		updatedEditablePrinterCostsList[printerIndex][printerCostKey] = value
		updatedEditablePrinterCostsList[printerIndex].validationMessage[
			printerCostKey
		] = getPrinterCostInputErrorMessage(
			printerCostKey,
			value,
			isIncorrectNumberValue
		)
		updatedEditablePrinterCostsList[printerIndex].validationStatus[
			printerCostKey
		] = updatedEditablePrinterCostsList[printerIndex].validationMessage[
			printerCostKey
		]
			? ValidationStatus.error
			: value
			? ValidationStatus.success
			: ''
		updatedEditablePrinterCostsList[printerIndex].disabled[
			relatedPrinterCostKey
		] = !!value || isIncorrectNumberValue || forceDisableRelatedField

		dispatch({
			type: EDITABLE_PRINTER_COSTS_CHANGE,
			payload: { editablePrinterCostsList: updatedEditablePrinterCostsList }
		})
	}
}

export const onPrinterCostEditClick = (
	item,
	editablePrinterCostsList,
	editMode
) => {
	return dispatch => {
		const updatedEditablePrinterCostsList = [...editablePrinterCostsList]
		const printerIndex = editablePrinterCostsList.findIndex(
			printerCost => printerCost.printer.id === item.printer.id
		)
		updatedEditablePrinterCostsList[printerIndex].edit = editMode

		if (!editMode) {
			updatedEditablePrinterCostsList[printerIndex].price = ''
			updatedEditablePrinterCostsList[printerIndex].printerHourlyCost = ''
			updatedEditablePrinterCostsList[printerIndex].validationStatus = {
				price: '',
				printerHourlyCost: ''
			}
			updatedEditablePrinterCostsList[printerIndex].validationMessage = {
				price: '',
				printerHourlyCost: ''
			}
			updatedEditablePrinterCostsList[printerIndex].disabled = {
				price: false,
				printerHourlyCost: false
			}
		}
		dispatch({
			type: EDITABLE_PRINTER_COSTS_CHANGE,
			payload: { editablePrinterCostsList: updatedEditablePrinterCostsList }
		})
	}
}

export const onPrinterCostActionClicked = (
	clickType,
	item,
	editablePrinterCostsList,
	organizationId
) => {
	switch (clickType) {
		case COST_AND_LEAD_BUTTON_ACTIONS.DEFAULT:
			return onPrinterSettingsDelete(
				{
					printer: item.printer.id,
					printerSettings: { price: null, printerHourlyCost: null }
				},
				organizationId
			)

		case COST_AND_LEAD_BUTTON_ACTIONS.EDIT:
			return onPrinterCostEditClick(item, editablePrinterCostsList, true)

		case COST_AND_LEAD_BUTTON_ACTIONS.CANCEL:
			return onPrinterCostEditClick(item, editablePrinterCostsList, false)

		case COST_AND_LEAD_BUTTON_ACTIONS.APPLY:
			return onPrinterSettingsAdd(
				item.printer,
				item.price,
				item.printerHourlyCost,
				item.defaultLayerThickness,
				true,
				organizationId
			)
		default:
			break
	}
}
