import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import Checkbox from '@material-ui/core/Checkbox'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { IProjectPdfOptions, pdfOptions } from './SolutionPdfInterface'
import { changeProjectPdfOptions } from './SolutionPdfOptionsActions'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { useSolutionReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import { manufacturingMethodTypes } from 'Services/Constants'
import { usePDFReduxState } from 'Services/CustomHooks/usePdfHooks'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

const CheckboxTSX: any = Checkbox

enum DisabledCheckbox {
	LEAD_TIME = 'LEAD_TIME',
	SUMMARY_TABLE = 'SUMMARY_TABLE',
	CO2_EMISSION = 'CO2_EMISSION'
}

interface IProps {
	projectPdfOptions: IProjectPdfOptions
	isPartIn2dFormat: boolean | undefined
	configurationId: number
	useUnprintableCheckboxes: boolean
}

interface ICheckboxProps {
	checked: boolean
	disabled: boolean
	name: string
	title: string
	parentName: string | null
	nestedParentName?: string | null
	configurationId: number
}

const OptionCheckbox: FC<ICheckboxProps> = ({
	checked,
	disabled,
	name,
	title,
	parentName = null,
	nestedParentName = null,
	configurationId
}) => {
	const dispatch = useDispatch()

	return (
		<div
			className={cx({
				'solution-options__checkboxes-child': !!parentName,
				'solution-options__checkboxes-nested': !!nestedParentName,
				'solution-options__checkboxes-parent': !parentName
			})}
		>
			<CheckboxTSX
				color="primary"
				className="checkbox"
				disabled={disabled}
				checked={checked}
				onClick={() => {
					dispatch(
						changeProjectPdfOptions(
							!checked,
							name,
							parentName,
							nestedParentName,
							configurationId
						)
					)
				}}
			/>
			<div>{getString('EXPORT_TO_PDF_OPTIONS')[title]}</div>
		</div>
	)
}

const SolutionPdfOptions: FC<IProps> = ({
	projectPdfOptions,
	isPartIn2dFormat,
	configurationId,
	useUnprintableCheckboxes
}) => {
	const { isAmOriginalMaterial } = useSolutionReducer(configurationId)
	const configuration = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer?.states[configurationId]
	})
	const dispatch = useDispatch()
	const { part, manufacturingMethod } = usePDFReduxState(
		configurationId,
		configuration.organizationId
	)
	const emptyOptions = isEmpty(projectPdfOptions)
	const showEmission = Feature.isFeatureOn(FeatureComponentId.CO2_EMISSIONS)
	const standardCost = configuration?.standardCost || part?.standardCost

	const disableEmission =
		isPartIn2dFormat ||
		!showEmission ||
		part.blockManufacturingMethodOperation ||
		(standardCost &&
			manufacturingMethod === manufacturingMethodTypes.standardCost)

	// Function for auto uncheck projectPdfOptions fields for hidden
	// checkboxes if they have checked:true
	useEffect(() => {
		if (!emptyOptions) {
			Object.keys(projectPdfOptions)?.forEach((keyElem: string) => {
				const emptyData = isEmpty(projectPdfOptions[keyElem]?.data)
				{
					!emptyData &&
						projectPdfOptions[keyElem].data?.forEach(
							(pdfOption: pdfOptions) => {
								if (
									((disableEmission &&
										pdfOption.name === DisabledCheckbox.CO2_EMISSION) ||
										((part.isDrawing || !part.isSpecifiedQuantity) &&
											pdfOption.title === DisabledCheckbox.SUMMARY_TABLE)) &&
									pdfOption.checked
								) {
									dispatch(
										changeProjectPdfOptions(
											false,
											pdfOption.name,
											keyElem,
											null,
											configurationId
										)
									)
								}
							}
						)
				}
			})
		}
	}, [])

	if (emptyOptions)
		return (
			<div className="solution-options">
				<p>{getString('NO_PDF_OPTIONS')}</p>
			</div>
		)

	return (
		<div className="solution-options">
			{useUnprintableCheckboxes && configuration.solution && (
				<div className="solution-options__warning">
					<IconFactory iconName="info" />
					{getString('GENERATE_REPORT_WARNING')}
				</div>
			)}
			<div className="solution-options__header">
				{getString('INCLUDE_REPORT')}:
			</div>
			<div>
				{Object.keys(projectPdfOptions)?.map((keyElem: string) => {
					const emptyData = isEmpty(projectPdfOptions[keyElem]?.data)
					const childData = projectPdfOptions[keyElem].data
					if (
						(!part.isSpecifiedQuantity &&
							projectPdfOptions[keyElem].title ===
								DisabledCheckbox.LEAD_TIME) ||
						(useUnprintableCheckboxes &&
							!projectPdfOptions[keyElem].showForUnprintable)
					) {
						return <></>
					}
					return (
						<div className="solution-options__checkboxes" key={keyElem}>
							<OptionCheckbox
								checked={projectPdfOptions[keyElem].checked}
								disabled={projectPdfOptions[keyElem].disabled}
								title={projectPdfOptions[keyElem].title}
								name={keyElem}
								parentName={null}
								configurationId={configurationId}
							/>
							<div className="solution-options__checkboxes-nested">
								{!emptyData &&
									childData?.map((pdfOption: pdfOptions) => {
										const emptyChildData = isEmpty(pdfOption.data)
										if (
											((disableEmission || isAmOriginalMaterial) &&
												pdfOption.title === DisabledCheckbox.CO2_EMISSION) ||
											((part.isDrawing || !part.isSpecifiedQuantity) &&
												pdfOption.title === DisabledCheckbox.SUMMARY_TABLE) ||
											(useUnprintableCheckboxes &&
												!pdfOption.showForUnprintable)
										)
											return <></>

										return (
											<div
												className="solution-options__checkboxes"
												key={pdfOption.name}
											>
												<OptionCheckbox
													key={pdfOption.name}
													title={pdfOption.title}
													checked={pdfOption.checked}
													disabled={pdfOption.disabled}
													name={pdfOption.name}
													parentName={keyElem}
													configurationId={configurationId}
												/>
												{!emptyChildData &&
													pdfOption.data?.map((pdfChildOption: pdfOptions) => (
														<OptionCheckbox
															key={pdfChildOption.name}
															title={pdfChildOption.title}
															checked={pdfChildOption.checked}
															disabled={pdfChildOption.disabled}
															name={pdfChildOption.name}
															parentName={keyElem}
															nestedParentName={pdfOption.name}
															configurationId={configurationId}
														/>
													))}
											</div>
										)
									})}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default memo(SolutionPdfOptions)
