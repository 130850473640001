import { composeWithDevTools } from '@redux-devtools/extension'

export const composeEnhancers = composeWithDevTools({
	maxAge: 40,
	actionsBlacklist: ['HANDLE_LOADER', '@@redux-form/*'], // Disable selected actions
	// actionsWhitelist: [''], // Allow only selected actions
	latency: 1000,
	serialize: true,
	trace: true,
	traceLimit: 25,
	features: {
		pause: true,
		lock: true,
		persist: true,
		export: true,
		jump: true,
		skip: true,
		reorder: true,
		dispatch: true
	}
})
