import { FC, memo, useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import {
	CANCEL,
	DOWNLOAD,
	END_DATE,
	SELECT_START_AND_END_DATES,
	START_DATE
} from '../../../Services/Strings'
import { AlertType } from '../alerts/AlertTypes'
import CastorAlert from '../alerts/CastorAlert'
import dayjs, { Dayjs } from 'dayjs'

import './DatePickers.scss'

interface IProps {
	show: boolean
	onCancel: Function
	downloadReport: Function
	input?: any
	label?: any
}

const CastorDatePickers: FC<IProps> = ({ show, onCancel, downloadReport }) => {
	const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()))
	const [endDate, setEndDate] = useState<Dayjs>(dayjs(new Date()))
	function changeStartDate(newValue: Dayjs): Dayjs {
		setStartDate(newValue)
		if (endDate < newValue) {
			setEndDate(newValue)
		}
		return newValue
	}
	function changeEndDate(newValue: Dayjs): Dayjs {
		setEndDate(newValue)
		if (startDate > newValue) {
			setStartDate(newValue)
		}
		return newValue
	}

	return (
		<CastorAlert
			alertType={AlertType.SUCCESS}
			show={show}
			headerTitle={SELECT_START_AND_END_DATES}
			onCancel={onCancel}
			cancelOptionalText={CANCEL}
			showConfirm={true}
			confirmOptionalText={DOWNLOAD}
			onConfirm={() => downloadReport(startDate, endDate)}
		>
			<div className="date-range-picker-wrapper">
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<DatePicker
						PopoverProps={{
							className: 'dropdown'
						}}
						disableToolbar
						variant="inline"
						label={START_DATE}
						value={startDate}
						autoOk={true}
						format="MMMM d, yyyy"
						onChange={(newValue: any) => changeStartDate(newValue)}
					/>

					<DatePicker
						PopoverProps={{
							className: 'dropdown'
						}}
						disableToolbar
						variant="inline"
						label={END_DATE}
						value={endDate}
						autoOk={true}
						format="MMMM d, yyyy"
						onChange={(newValue: any) => changeEndDate(newValue)}
					/>
				</MuiPickersUtilsProvider>
			</div>
		</CastorAlert>
	)
}

export default memo(CastorDatePickers)
