import { useCallback, useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { cloneDeep } from 'lodash'

import { Part } from 'Services/models/IPart'
import { Project } from 'Services/models/IProject'
import { STANDALONE_PARTS } from 'Services/Strings'

export const usePDFReduxState = (
	configurationId: number,
	organizationId: number
) => {
	const drawingCostPercentage = useSelector((state: RootStateOrAny) => {
		return (
			state?.MainPartAnalysisReducer?.allConfigurationsOrganizationSettings[
				organizationId
			]?.drawingCostPercentage ||
			state?.user?.userCustomizationSettings?.drawingCostPercentage ||
			state?.MainPartAnalysisReducer?.drawingCostPercentage
		)
	})

	const userCompany = useSelector((state: RootStateOrAny) => {
		return state?.user?.userDetails?.company || ''
	})

	const {
		tempChainBenefits,
		tempSolutionPostProcessToggles,
		chartData,
		isCostEffective,
		effectiveQuantity,
		costLoading,
		costBenefit,
		costDetails,
		chartLeadData,
		leadTimeLoading,
		unlockLeadTimeGraph,
		timeBenefit,
		manufacturingMethod
	} = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer.states[configurationId]
	})

	const allOptionalPostProcessesData = useSelector((state: RootStateOrAny) => {
		return state?.user?.allOptionalPostProcessesData || []
	})

	const {
		partPrintIssues,
		part,
		fileTiltleName,
		initialBatchSize,
		configurations
	} = useSelector((state: RootStateOrAny) => {
		return state?.MainPartAnalysisReducer
	})

	return {
		drawingCostPercentage,
		tempChainBenefits,
		tempSolutionPostProcessToggles,
		allOptionalPostProcessesData,
		chartData,
		isCostEffective,
		effectiveQuantity,
		costLoading,
		costBenefit,
		costDetails,
		partPrintIssues,
		part,
		fileTiltleName,
		initialBatchSize,
		configurations,
		chartLeadData,
		leadTimeLoading,
		unlockLeadTimeGraph,
		timeBenefit,
		userCompany,
		manufacturingMethod
	}
}

export const useUpdateChart = (chartData: any) => {
	const [chart, setChart] = useState<any>({})
	const [update, setUpdate] = useState(true)

	// need to rerender the chart 1 time, because of tooltip
	const updateChart = useCallback((updatedData: any, update: boolean) => {
		if (update) {
			setTimeout(() => {
				setChart(cloneDeep(updatedData))
			}, 2000)
		}
		setUpdate(false)
	}, [])

	useEffect(() => {
		updateChart(chartData, update)

		// need to move it back when unmount
		return () => {
			setChart({})
			setUpdate(true)
		}
	}, [chartData, updateChart, update])

	return chart
}

export const useGetBundleProjectNamesFromParts = (parts: Part[]) => {
	const projects = useSelector((state: RootStateOrAny) => {
		return state.ProjectBundleReducer?.projects
	})

	const projectNames = [] as string[]
	const projectIds = [] as string[]

	parts.forEach(part => {
		if (projectIds.includes(part.projectId as string)) return
		projectIds.push(part.projectId as string)
		const projectName = projects.find(
			(project: Project) => project.id === part.projectId
		)?.name
		if (projectName && projectName.endsWith(STANDALONE_PARTS)) {
			projectNames.push(STANDALONE_PARTS)
		} else {
			projectNames.push(projectName)
		}
	})

	return projectNames
}
