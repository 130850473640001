import React, { FC, memo } from 'react'

import cx from 'classnames'

import { getString } from '../../../../../../../../../Services/Strings/StringService'
import IconFactory from '../../../../../../../../Components/StarIcon/IconFactory'
import { getAllDataForTMResults } from './LeadTimeService'

interface IProps {
	configuration: any
	solution: any
	timeBenefit: any
	isStandard: any
	methodText: any
	isAmOriginalMaterial: boolean
}

const LeadTimeChartExplanation: FC<IProps> = ({
	configuration,
	solution,
	timeBenefit,
	isStandard,
	methodText,
	isAmOriginalMaterial
}) => {
	const { tmResults, amResults } = configuration?.leadTimeResults
	const { firstTmDay, firstTmPart } = getAllDataForTMResults(
		amResults,
		tmResults
	)

	return (
		<div className="cost-comparison-tab--information--cost-explenation lead-time-results">
			<div className="text-with-benefit">
				<IconFactory
					iconName={'time'}
					className={cx('cost-comparison-tab--cost-explenation--icon', {
						'not-active': !timeBenefit
					})}
				/>
				{getString('PARTS_DELIVERED_IN_DAYS').format(
					solution.leadTimeDetails?.firstShipments,
					configuration?.quantity,
					solution?.leadTimeDetails?.firstLeadTime,
					getString('AM')
				)}
			</div>
			{!isStandard && !isAmOriginalMaterial && (
				<div className="text-with-explanation">
					{getString('PARTS_DELIVERED_IN_DAYS').format(
						firstTmPart,
						configuration?.quantity,
						firstTmDay,
						methodText
					)}
				</div>
			)}
		</div>
	)
}

export default memo(LeadTimeChartExplanation)
