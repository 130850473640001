import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import cx from 'classnames'

import CastorAlert from '../../../Components/alerts/CastorAlert'
import { useSetParameterModal } from './CostFunctionEditorHooks'
import { FunctionEditorInitialState } from './CostingFunctionEditorReducer'
import FieldWithLabel from 'Scenes/Components/FieldWithLabel'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	reducerStateId: string
}

const SetNewParameterAlert: FC<IProps> = ({ reducerStateId }) => {
	const { parameterModalIsOpen, variablesList, addCustomVariableLoading } =
		useSelector(
			(state: RootStateOrAny) =>
				state.CostingFunctionEditorReducer?.functionEditorStates[
					reducerStateId
				] || new FunctionEditorInitialState()
		)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const {
		name,
		nameError,
		value,
		alertIsDisabled,
		closeModal,
		handleNameChange,
		handleValueChange,
		handleConfirm
	} = useSetParameterModal(
		variablesList,
		reducerStateId,
		adminSelectedOrganizationId
	)

	return (
		<CastorAlert
			alertBodyClass="costing-function-editor--alert"
			headerTitle={getString('COSTING_FUNCTION_SET_NEW_PARAMETER_HEADER')}
			show={parameterModalIsOpen}
			onConfirm={handleConfirm}
			showCancel={true}
			onCancel={closeModal}
			cancelOptionalText={getString('CANCEL')}
			confirmOptionalText={
				<>
					<SaveOutlinedIcon className="costing-function-editor--icon" />
					{getString('SAVE')}
				</>
			}
			onFooterCancel={closeModal}
			disabled={alertIsDisabled}
			loadingCalculation={addCustomVariableLoading}
		>
			<div className="costing-function-editor--alert--description">
				{getString('COSTING_FUNCTION_SET_NEW_PARAMETER_DESCRIPTION')}
			</div>
			<div className="costing-function-editor--alert--body">
				<FieldWithLabel
					fieldId="costing-function-editor--name-field"
					labelName={getString('COSTING_FUNCTION_SET_NEW_PARAMETER_NAME_LABEL')}
					fieldPlaceholder={getString(
						'COSTING_FUNCTION_SET_NEW_PARAMETER_NAME_PLACEHOLDER'
					)}
					fieldValue={name}
					fieldOnChange={handleNameChange}
					fieldClassName={cx('costing-function-editor--alert--input', {
						error: !!nameError
					})}
					wrapperClassName="costing-function-editor--alert--input-wrapper"
					error={!!nameError}
					helperText={nameError}
					qaDataElementName="data-qa-constant-name-input"
					autoFocus
				/>
				<FieldWithLabel
					fieldId="costing-function-editor--name-field"
					labelName={getString(
						'COSTING_FUNCTION_SET_NEW_PARAMETER_VALUE_LABEL'
					)}
					fieldValue={value}
					fieldOnChange={handleValueChange}
					fieldClassName="costing-function-editor--alert--input number"
					wrapperClassName="costing-function-editor--alert--input-wrapper"
					autoFocus={false}
					step={0.1}
					isCommaDisabled={false}
					isDotDisabled={false}
					qaDataElementName="data-qa-constant-value-input"
					isNumberField
				/>
			</div>
		</CastorAlert>
	)
}

export default memo(SetNewParameterAlert)
