import React, { FC, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { STEP_ID, SUSTAINABILITY_IDS } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { useStep } from '../../OnboardingWizardHooks'
import {
	isContentPresent,
	isTransportationTableChanged
} from '../../OnboardingWizardService'
import OnboardingStepFrame from '../OnboardingStepFrame'
import EndOfLife from './EndOfLife'
import MaterialProduction from './MaterialProduction'
import Production from './Production'
import ProductUse from './ProductUse'
import Transportation from './Transportation'
import Devider from 'Scenes/Components/Devider/Devider'
import CustomizeCO2CarbonCreditCost from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2CarbonCreditCost/CustomizeCO2CarbonCreditCost'
import { fetchUserCo2Data } from 'Scenes/Home/Customize/CustomizeCO2Calculation/CustomizeCO2CalculationAction'

import './Sustainability.scss'

const SustainabilityComponents = {
	[SUSTAINABILITY_IDS.MATERIAL_PRODUCTION]: <MaterialProduction />,
	[SUSTAINABILITY_IDS.PRODUCTION]: <Production />,
	[SUSTAINABILITY_IDS.PRODUCT_USE]: <ProductUse />,
	[SUSTAINABILITY_IDS.TRANSPORTATION]: <Transportation />,
	[SUSTAINABILITY_IDS.END_OF_LIFE]: <EndOfLife />,
	[SUSTAINABILITY_IDS.CARBON_CREDIT]: <CustomizeCO2CarbonCreditCost />
}

const Sustainability: FC = () => {
	const { steps, parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { printersList, materialList, printersTypicalkwhList, typicalKwhList } =
		useSelector((state: RootStateOrAny) => state.CustomizeCO2CalculationReducer)
	const {
		co2PerKW,
		puAnnualKgCO2,
		puYearsCO2,
		disposeFactor,
		carbonCreditCost,
		holdFactor,
		userSettingsDefaultValues
	} = useSelector((state: RootStateOrAny) => state.user)
	const { co2InitialData, settingsData } = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2Reducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const { step, stepContent, childSteps } = useStep(
		steps,
		STEP_ID.SUSTAINABILITY
	)

	const dispatch = useDispatch()

	const changesInCurrentStep =
		(isContentPresent(stepContent, SUSTAINABILITY_IDS.MATERIAL_PRODUCTION) &&
			(printersList.length > 0 || materialList.length > 0)) ||
		(isContentPresent(stepContent, SUSTAINABILITY_IDS.PRODUCTION) &&
			(+co2PerKW !== userSettingsDefaultValues.co2PerKW ||
				printersTypicalkwhList.filter(
					(printer: any) => printer.typicalKwh !== null
				).length > 0 ||
				typicalKwhList?.filter(
					(technology: { typicalPowerConsumption: null | number }) =>
						technology.typicalPowerConsumption !== null
				).length > 0)) ||
		(isContentPresent(stepContent, SUSTAINABILITY_IDS.PRODUCT_USE) &&
			(+puAnnualKgCO2 !== 0 || +puYearsCO2 !== 0)) ||
		(isContentPresent(stepContent, SUSTAINABILITY_IDS.END_OF_LIFE) &&
			+disposeFactor !== 0) ||
		(isContentPresent(stepContent, SUSTAINABILITY_IDS.TRANSPORTATION) &&
			(isTransportationTableChanged(co2InitialData, settingsData) ||
				+holdFactor !== userSettingsDefaultValues.holdFactor)) ||
		(isContentPresent(stepContent, SUSTAINABILITY_IDS.CARBON_CREDIT) &&
			+carbonCreditCost !== userSettingsDefaultValues.carbonCreditCost)

	const onContinueClick = () => {
		const stepIndexInChildSteps =
			childSteps && childSteps.findIndex(childStep => childStep.id === step.id)
		const stepID =
			stepIndexInChildSteps !== childSteps.length - 1
				? childSteps[stepIndexInChildSteps + 1].id
				: undefined
		dispatch(goToNextStep(parentSteps, currentStep, stepID))
	}

	useEffect(() => {
		dispatch(fetchUserCo2Data(adminSelectedOrganizationId))
	}, [adminSelectedOrganizationId, dispatch])

	return (
		<OnboardingStepFrame
			step={step}
			childSteps={childSteps}
			onNextClickAnotherAction={onContinueClick}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div className="sustainability--wrapper">
				{stepContent.map((component, index) => {
					const addDevider =
						stepContent.length > 1 && index !== stepContent.length - 1
					return (
						<React.Fragment key={component.id}>
							{
								SustainabilityComponents[
									component.id as keyof typeof SustainabilityComponents
								]
							}
							{addDevider && <Devider className="cost-model--devider" />}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default Sustainability
