import React, { FC, useCallback } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import {
	onRemoveAllUserMaterialNameMapping,
	onRemoveUserMaterialNameMapping,
	onUpdateMaterialNameMapping
} from 'Scenes/Home/Customize/CustomizeActions'
import MaterialNamesMappingList from 'Scenes/Home/Customize/CustomizeMaterialNamesMapping/MaterialNamesMappingList'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getString } from 'Services/Strings/StringService'

const EditMaterialNames: FC = () => {
	const {
		disableMaterialNameMappingSaveAll,
		userMaterialNamesMapping,
		materials,
		materialTypes,
		materialCategories,
		isLoadingMaterial
	} = useSelector((state: RootStateOrAny) => state.user)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()

	const userMaterialNames = userMaterialNamesMapping?.filter(
		(materialName: IUserMaterialNamesMapping) => !materialName.defaultFormatType
	)

	const updateMaterialNameMapping = useCallback(
		(materialNameMapping: IUserMaterialNamesMapping[]) => {
			dispatch(
				onUpdateMaterialNameMapping(
					materialNameMapping,
					adminSelectedOrganizationId
				)
			)
		},
		[adminSelectedOrganizationId, dispatch]
	)

	const removeUserMaterialNameMapping = useCallback(
		(id: string) => {
			dispatch(
				onRemoveUserMaterialNameMapping(
					id,
					false,
					undefined,
					adminSelectedOrganizationId
				)
			)
		},
		[adminSelectedOrganizationId, dispatch]
	)

	const removeAllUserMaterialNameMapping = useCallback(() => {
		dispatch(
			onRemoveAllUserMaterialNameMapping(true, adminSelectedOrganizationId)
		)
	}, [adminSelectedOrganizationId, dispatch])

	return (
		<MaterialNamesMappingList
			title={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_EDIT_MATERIALS_TITLE')}
			explanation={getString(
				'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EDIT_MATERIALS_EXPLANATION'
			)}
			disableMaterialNameMappingSaveAll={disableMaterialNameMappingSaveAll}
			userMaterialNamesMapping={userMaterialNames}
			isLoadingMaterial={isLoadingMaterial}
			materialCategories={materialCategories}
			materialTypes={materialTypes}
			materials={materials}
			isActive
			updateMaterialNameMapping={updateMaterialNameMapping}
			removeUserMaterialNameMapping={removeUserMaterialNameMapping}
			removeAllUserMaterialNameMapping={removeAllUserMaterialNameMapping}
		/>
	)
}

export default EditMaterialNames
