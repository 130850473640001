import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isLoggedInSuccess } from 'global actions'
import {
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	NEXT_STEP_LOADER,
	TOGGLE_INCLUDE_ALL_COSTS_CHECKBOX,
	UPDATE_USER_ONBOARDING_STATE
} from 'global actions/types'
import { History } from 'history'
import { store } from 'index'
import { IOnboardingWizardStep } from 'Services/models/IOnboardingWizardStep'
import { getUserProfileData, updateUserOnboardingState } from 'Services/Network'
import { uploadProjectRoute } from 'Services/routeFuncs'
import { SHOW_NOTIFICATION } from 'Services/Strings'

export const skipOrCompleteOnboarding = (history: History) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			const response = await updateUserOnboardingState(true)
			const user = response.data.user
			dispatch({
				type: UPDATE_USER_ONBOARDING_STATE,
				payload: {
					onboardingCompleted: user.onboardingCompleted,
					wizardStep: user.wizardStep,
					wizardOrganizationId: user.wizardOrganizationId
				}
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			history.push(uploadProjectRoute())
		} catch (err) {
			console.error(err)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}

export const goToNextStep = (
	parentSteps: IOnboardingWizardStep[],
	currentStep: IOnboardingWizardStep,
	nextStepID?: number,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const currentStepIndex = parentSteps.findIndex(
			step => step.id === currentStep.id || step.id === currentStep.parentStepId
		)
		const nextStepId = nextStepID || parentSteps[currentStepIndex + 1]?.id || 1
		const onboardingCompleted = nextStepId === 1
		try {
			dispatch({
				type: NEXT_STEP_LOADER,
				payload: true
			})

			const response = await updateUserOnboardingState(
				onboardingCompleted,
				nextStepId,
				organizationId
			)
			const user = response.data.user

			dispatch({
				type: UPDATE_USER_ONBOARDING_STATE,
				payload: {
					onboardingCompleted: user.onboardingCompleted,
					wizardStep: user.wizardStep
				}
			})
		} catch (err: any) {
			console.error(err)
			dispatch({
				type: NEXT_STEP_LOADER,
				payload: false
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message
				}
			})
		}
	}
}

export const goToPrevStep = (
	parentSteps: IOnboardingWizardStep[],
	currentStep: IOnboardingWizardStep,
	childSteps?: IOnboardingWizardStep[]
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		let prevStepId: number

		const currentStepIndexInParentSteps = parentSteps.findIndex(
			step => step.id === currentStep.id
		)
		const currentStepIndexInChildSteps = childSteps?.findIndex(
			step => step.id === currentStep.id
		)
		const stepIsInChildSteps =
			childSteps &&
			typeof currentStepIndexInChildSteps !== 'undefined' &&
			currentStepIndexInChildSteps > -1

		if (stepIsInChildSteps) {
			const stepIsNotFirstChildStep =
				currentStepIndexInChildSteps > 0 && currentStep.order
			prevStepId = stepIsNotFirstChildStep
				? childSteps[currentStepIndexInChildSteps - 1].id
				: (childSteps[currentStepIndexInChildSteps].parentStepId as number)
		} else {
			prevStepId = parentSteps[currentStepIndexInParentSteps - 1]?.id || 1
		}

		dispatch({
			type: UPDATE_USER_ONBOARDING_STATE,
			payload: {
				onboardingCompleted: false,
				wizardStep: prevStepId
			}
		})
	}
}

export const toggleIncludeAllCostsCheckbox = (value: boolean) => {
	return {
		type: TOGGLE_INCLUDE_ALL_COSTS_CHECKBOX,
		payload: { includeAllCostsCheckbox: value }
	}
}

export const setupOrganizationDataForWizard = (organizationId: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			let userId =
				store.getState().user?.userId || store.getState().user?.userDetails?.id

			const response = await getUserProfileData(userId, false, organizationId)
			isLoggedInSuccess(dispatch, response, undefined, true)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message
				}
			})
		}
	}
}
