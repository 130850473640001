import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MenuItem from '@material-ui/core/MenuItem'
import cx from 'classnames'

import * as MaterialSelectorActions from './MaterialSelectorActions'
import { materialTypes } from '../../../../Services/Constants'
import {
	SELECT_MATERIAL_CATEGORY_PLACEHOLDER,
	SELECT_MATERIAL_PLACEHOLDER,
	SELECT_MATERIAL_TYPE_PLACEHOLDER
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import TransparentButton from '../../TransparentButton'
import {
	renderButtonField,
	renderCategorySelector,
	renderSelectField
} from '../Common/MultipleMaterialSelector'

import './MaterialSelector.scss'

class MaterialSelector extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hideMaterialNameSelector: props.hideMaterialNameSelector
		}
	}

	componentWillMount() {
		const {
			setup,
			materials,
			onChange,
			defaultMaterialForTypeArr,
			defaultMaterial,
			materialCategories,
			hideMaterialNameSelector
		} = this.props
		this.setState({ hideMaterialNameSelector })
		setup(
			materials,
			onChange,
			defaultMaterial,
			defaultMaterialForTypeArr,
			materialCategories
		)
	}

	componentDidUpdate(prevProps) {
		const {
			material,
			setup,
			materials,
			onChange,
			defaultMaterialForTypeArr,
			defaultMaterial,
			materialCategories
		} = this.props
		if (prevProps.material !== material) {
			onChange(material)
		}
		if (prevProps.materials.length !== materials.length) {
			setup(
				materials,
				onChange,
				defaultMaterial,
				defaultMaterialForTypeArr,
				materialCategories
			)
		}
	}

	materialChanged = event => {
		this.props.materialChanged(event.target.value)
	}

	materialTypeChanged = event => {
		this.props.materialTypeChanged(event.target.value)
	}

	materialCategoryChanged = event => {
		this.props.materialCategoryChanged(event.target.value)
	}

	renderMaterialMenuItems = materialArr => {
		return materialArr.map(material => {
			return (
				<MenuItem key={material.id} value={material.name}>
					{material.name}
				</MenuItem>
			)
		})
	}

	renderNameFieldWithoutSelector = label => {
		const { material, disabled, isButtonSelector } = this.props
		return (
			<div>
				{isButtonSelector && <div className="label">{label}</div>}
				<div className="flex outlined specified">
					<div
						title={material.name}
						className="material-selector--no-select-field"
					>
						{material.name}
					</div>
					<TransparentButton
						disabled={disabled}
						onClick={() => this.setState({ hideMaterialNameSelector: false })}
					>
						{getString('SPECIFY_MATERIAL')}
					</TransparentButton>
				</div>
			</div>
		)
	}

	renderNameSelector = () => {
		const {
			material,
			materialArr,
			isButtonSelector,
			qaDataElementNameMaterial,
			disableOriginalMaterial
		} = this.props

		if (
			this.state.hideMaterialNameSelector &&
			material.type !== materialTypes.plastic
		) {
			return this.renderNameFieldWithoutSelector(
				getString('ORIGINAL_MATERIALS_BUTTON')
			)
		}
		const selectClassName = isButtonSelector
			? 'field-with-label outlined'
			: 'material-selector material-last-selector'

		return renderSelectField(
			selectClassName,
			material.name,
			this.materialChanged,
			SELECT_MATERIAL_PLACEHOLDER,
			materialArr,
			this.renderMaterialMenuItems(materialArr),
			'',
			this.props,
			getString('ORIGINAL_MATERIALS_BUTTON'),
			disableOriginalMaterial
		)
	}

	render() {
		const {
			materialType,
			materialTypesArr,
			isButtonSelector,
			qaDataElementNameType
		} = this.props
		return (
			<div
				className={cx('material-selctors-div', {
					'material-selctors-div__buttons': isButtonSelector
				})}
			>
				{isButtonSelector
					? renderButtonField(
							materialType,
							materialTypesArr,
							qaDataElementNameType,
							getString('ORIGINAL_MATERIAL_TYPE'),
							this.props.materialTypeChanged
					  )
					: renderSelectField(
							'material-selector',
							materialType,
							this.materialTypeChanged,
							SELECT_MATERIAL_TYPE_PLACEHOLDER,
							materialTypesArr,
							isButtonSelector,
							'',
							this.props
					  )}
				<div className="flex">
					{renderCategorySelector(
						this.props,
						getString('ORIGINAL_MATERIAL_CATEGORY'),
						this.materialCategoryChanged,
						SELECT_MATERIAL_CATEGORY_PLACEHOLDER
					)}
					{this.renderNameSelector()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ MaterialSelectorReducer, user }, ownProps) => {
	const {
		material,
		materialArr,
		materialTypesArr,
		materialType,
		materialCategory,
		materialCategoriesArr,
		showCategorySelector,
		materialCategories: currentMaterialCategories
	} = MaterialSelectorReducer
	const { materials: userMaterials, materialCategories } = user

	return {
		material,
		materialArr,
		materials: ownProps.materials || userMaterials,
		materialTypesArr,
		materialType,
		materialCategory,
		materialCategoriesArr,
		showCategorySelector,
		materialCategories,
		currentMaterialCategories
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...MaterialSelectorActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialSelector)
