import { cloneDeep } from 'lodash'

export const getUpdatedMaterial = (
	newMaterial: Record<string, any>,
	existMaterialList: Array<Record<string, any>>,
	paramName: string
) => {
	let changedUserPrinterMaterialList = cloneDeep(existMaterialList)

	const materialIndex = changedUserPrinterMaterialList.findIndex(
		(material: any) => material.material.id === newMaterial.material.id
	)
	if (materialIndex === -1) {
		changedUserPrinterMaterialList.push(newMaterial)
	} else {
		changedUserPrinterMaterialList[materialIndex] = newMaterial
	}

	return changedUserPrinterMaterialList.filter(
		material => !!material[paramName]
	)
}
