export const styles = {
	pageStyle: {
		backgroundColor: '#eeeeee'
	},
	goBackButton: {
		margin: '10px 0 0 0',
		padding: '5px',
		minWidth: '10px',
		minHeight: '10px',
		height: '30px'
	},
	superview: { margin: 20 },
	cardDiv: {
		maxWidth: 900,
		alignSelf: 'center',
		textAlign: 'left',
		width: '100%'
	},
	explanationText: {
		alignSelf: 'left',
		textAlign: 'left',
		margin: 12
	},
	explanationTextHeader: {
		alignSelf: 'left',
		textAlign: 'left',
		marginTop: 20,
		marginLeft: 12
	},
	extraExplanationText: {
		fontSize: 10,
		alignSelf: 'left',
		textAlign: 'left',
		margin: 12
	},
	topText: {
		width: 900,
		alignSelf: 'center',
		alignItems: 'left'
	},
	topCardText: {
		alignSelf: 'center',
		alignItems: 'left'
	},
	formSuperview: {
		maxWidth: 1000,
		justifyContent: 'center',
		marginLeft: 30,
		marginRight: 30
	}
}
