import React, { FC, memo, MouseEvent, useEffect, useRef, useState } from 'react'
import { browserName } from 'react-device-detect'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'

import SolutionPdf from './PdfFileComponent/index'
import { pageStyle } from './pdfPageStyles'
import SolutionPdfOptions from './SolutionPdfOptions'
import { updateProjectPdfOptions } from './SolutionPdfOptionsActions'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { usePartReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import { Chrome, pdfBuggyVersionOfChrome } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

import '../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostComparisonTab.scss'
import './PdfFileComponent/SolutionExportPdf.scss'

interface IProps {
	configurationId: number
	showModal: boolean
	setShowModal: Function
	isPartIn2dFormat?: boolean
	configurationOrganizationId: number
}

const delay = (m: number) => new Promise(r => setTimeout(r, m))

const SolutionPdfModal: FC<IProps> = ({
	configurationId,
	showModal,
	setShowModal,
	isPartIn2dFormat,
	configurationOrganizationId
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [isBrokenChrome, setIsBrokenChrome] = useState(false)
	const delayTime = 6000
	const componentRef: any = useRef()
	const dispatch = useDispatch()

	const [projectPdfOptionsAll, configuration, mainInfo] = useSelector(
		(state: RootStateOrAny) => [
			state?.MainPartAnalysisReducer?.projectPdfOptions,
			state?.SolutionAnalysisReducer.states[configurationId],
			state?.MainPartAnalysisReducer || {}
		]
	)
	const { part } = usePartReducer()
	const projectPdfOptions = projectPdfOptionsAll[configurationId]

	useEffect(() => {
		if (browserName === Chrome) {
			const match = /Chrome\/(\d+\.\d+)/.exec(navigator.userAgent)
			if (match && +match[1] === pdfBuggyVersionOfChrome) {
				setIsBrokenChrome(true)
			}
		}
	}, [])

	const handlePrint = useReactToPrint({
		content: () => componentRef?.current,
		onBeforePrint: () => setIsLoading(false),
		documentTitle: `${mainInfo.fileTiltleName || mainInfo.part.name} report - ${
			configuration.solutionName
		}`,
		pageStyle: () => pageStyle(isBrokenChrome),
		suppressErrors: true,
		onAfterPrint: () => setShowModal(false),
		onPrintError: (errorLocation, error) => {
			console.error('errorLocation:', errorLocation, 'error:', error)
		}
	})

	const handleConfirm = async () => {
		setIsLoading(true)
		// need to give some time for render
		await delay(delayTime)
		handlePrint()
		dispatch(
			updateProjectPdfOptions(
				projectPdfOptions,
				configurationOrganizationId,
				part.id
			)
		)
	}

	const onPreventEvent = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
	}

	return (
		<div onClick={onPreventEvent}>
			<CastorAlert
				preventCancel={isLoading}
				alertClass="pdf-export-alert"
				show={showModal}
				headerTitle={getString('EXPORT_TO_PDF')}
				onCancel={() => setShowModal(false)}
				loadingCalculation={isLoading}
				onConfirm={() => handleConfirm()}
			>
				<div className="pdf-section">
					<SolutionPdfOptions
						configurationId={configurationId}
						isPartIn2dFormat={isPartIn2dFormat}
						projectPdfOptions={projectPdfOptions}
						useUnprintableCheckboxes={!configuration.showAnalysis}
					/>
					<div className="pdf-section__hidden">
						<div ref={componentRef}>
							<SolutionPdf
								configurationId={configurationId}
								projectPdfOptions={projectPdfOptions}
								useUnprintableCheckboxes={!configuration.showAnalysis}
							/>
						</div>
					</div>
				</div>
			</CastorAlert>
		</div>
	)
}

export default memo(SolutionPdfModal)
