import React, { FC, memo, useEffect, useState } from 'react'

import cx from 'classnames'

import Loader from 'Scenes/Loader/Loader'
import { FormatType } from 'Services/models/IPart'

import { ReactComponent as CADFile } from 'assets/img/svg/cad.svg'
import { ReactComponent as MetaData } from 'assets/img/svg/metadata.svg'
import { ReactComponent as PDF } from 'assets/img/svg/pdf.svg'

import './PartImageWithFallback.scss'

type Props = {
	is2DFormat?: boolean
	formatType?: string
	fallbackClassName?: string
	isWeightReductionLoading?: boolean
} & React.ImgHTMLAttributes<HTMLImageElement>

const PartImageWithFallback: FC<Props> = ({
	fallbackClassName,
	formatType = FormatType.threeD,
	src,
	alt,
	isWeightReductionLoading = false,
	...rest
}) => {
	const [imageError, setImageError] = useState(false)
	useEffect(() => {
		setImageError(false)
	}, [src])
	const showBackgroundImage =
		formatType === FormatType.pdf || formatType === FormatType.metadata

	const fallBackImage = () => {
		if (formatType === FormatType.pdf) {
			return <PDF />
		}
		if (formatType === FormatType.metadata) {
			return <MetaData />
		}
		return <CADFile />
	}

	const handleShowFallbackImg = () => setImageError(true)

	if (isWeightReductionLoading) {
		return (
			<div
				className={cx('fallback-wrapper', fallbackClassName, {
					'drawing-image': showBackgroundImage
				})}
			>
				<Loader
					load={true}
					message=""
					wrapperClassName="fallback-wrapper--loader"
					size={50}
				/>
			</div>
		)
	}

	if (imageError || !src)
		return (
			<div
				className={cx('fallback-wrapper', fallbackClassName, {
					'drawing-image': showBackgroundImage
				})}
			>
				{fallBackImage()}
			</div>
		)

	return <img {...rest} src={src} alt={alt} onError={handleShowFallbackImg} />
}

export default memo(PartImageWithFallback)
