import React, { FC, memo } from 'react'

import cx from 'classnames'

import {
	getAnalysisPdfData,
	getSurfaceMachining,
	makeRoundIfNeeded,
	makeTotalCostForStandard
} from '../SolutionPdfService'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { NA } from 'Services/Constants'
import { usePDFReduxState } from 'Services/CustomHooks/usePdfHooks'
import { displayInRange } from 'Services/global/calculateRange'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	part: Part
	configuration: any
	fullConfiguration: any
	solution: any
	show: boolean
	isStandardCost: boolean
	isStandardCostManufacturing: boolean
	isNotSpecifiedQuantity: boolean
	isAmOriginalMaterial: boolean
}

const checkMark = (value: boolean) => {
	if (value) {
		return <IconFactory iconName="success" className="check-mark success" />
	}

	return <span className="check-mark failed">-</span>
}

export const AnalysisParametersPdf: FC<IProps> = ({
	part,
	fullConfiguration,
	configuration,
	solution,
	show,
	isStandardCost,
	isStandardCostManufacturing,
	isNotSpecifiedQuantity,
	isAmOriginalMaterial
}) => {
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)

	const {
		drawingCostPercentage,
		tempChainBenefits,
		tempSolutionPostProcessToggles,
		allOptionalPostProcessesData
	} = usePDFReduxState(configuration.id, configuration.organizationId)

	const { surfaceIsOn } = getSurfaceMachining(
		allOptionalPostProcessesData,
		tempSolutionPostProcessToggles
	)

	if (!show) return <div />

	const { isDrawing, cost, complexGeometry, result, methodText } =
		getAnalysisPdfData(part, configuration, fullConfiguration)

	let totalCost = cost

	const {
		totalCost: basicTotalCost,
		minCostDetails,
		maxCostDetails
	} = solution?.costDetails

	let costDetailTotal = makeRoundIfNeeded(
		basicTotalCost,
		drawingCostPercentage,
		isDrawing
	)

	if (isStandardCost) {
		totalCost = makeTotalCostForStandard(part, configuration, tempChainBenefits)
	}

	if (isShowValuesInRanges && minCostDetails && maxCostDetails) {
		costDetailTotal = displayInRange(
			minCostDetails?.totalCost,
			maxCostDetails?.totalCost,
			costDetailTotal,
			null,
			isDrawing ? drawingCostPercentage : undefined
		)
	}

	const dynamicClassName =
		isAmOriginalMaterial && !isStandardCost
			? 'analysis-table grid-am-table'
			: 'analysis-table grid-table'

	return (
		<div className="inline">
			<div>
				<div className={cx('export-pdf__analysis flex')}>
					<div className="big">
						<h2>
							{isAmOriginalMaterial && !isStandardCost
								? getString('3D_PRINTING_COST')
								: getString('PRINTING_VS_METHOD').format(methodText)}
						</h2>
						<br />
						<div className={dynamicClassName}>
							<div className="break-word analysis-header">
								<p>
									<span>{getString('MANUFACTURING_METHOD_TEXT')}</span>
								</p>
							</div>
							<div className="flex analysis-header">
								<p>
									<span>{getString('3D_PRINTING')}</span>
									<span className="result">
										<IconFactory
											iconName={
												configuration.result ||
												configuration.configurationResult
											}
											className={`data-table-row-icon`}
										/>
										{result}
									</span>
								</p>
							</div>
							{!isAmOriginalMaterial || isStandardCost ? (
								<div className="analysis-header">
									<p>
										<span>{methodText}</span>
									</p>
								</div>
							) : (
								<></>
							)}
							<div>
								<p>{getString('TOTAL_PART_COST').format('$')}</p>
							</div>
							<div>
								<div className="flex row">
									<div className="flex-analysis">
										{fullConfiguration.isCostEffective &&
											(!isAmOriginalMaterial || isStandardCost) && (
												<IconFactory
													iconName={'dollar'}
													className="cost-comparison-tab--cost-explenation--icon"
												/>
											)}
										<p>{costDetailTotal}</p>
									</div>
								</div>
							</div>
							{!isAmOriginalMaterial || isStandardCost ? (
								<div>
									<p>
										{makeRoundIfNeeded(
											totalCost,
											drawingCostPercentage,
											isDrawing,
											isStandardCost
										)}
									</p>
								</div>
							) : (
								<></>
							)}
							{!isNotSpecifiedQuantity && (
								<>
									<div>
										<p>
											{getString('LEAD_TIME_LABEL')} (
											{getString('CONFIGURATION_RESULTS_DAYS')})
										</p>
									</div>
									<div className="flex row">
										<p>
											{fullConfiguration.timeBenefit &&
												(!isAmOriginalMaterial || isStandardCost) && (
													<IconFactory
														small
														iconName="time-saving"
														smallClassName="cost-comparison-tab--cost-explenation--icon"
													/>
												)}
											{solution.leadTime}
										</p>
									</div>
									{!isAmOriginalMaterial || isStandardCost ? (
										<div>
											<p>
												{configuration?.leadTimeResults &&
												!isStandardCostManufacturing
													? configuration?.leadTimeResults?.leadTimeDetails
															?.lastDay
													: NA}
											</p>
										</div>
									) : (
										<></>
									)}
								</>
							)}
							<div>
								<p>{getString('NEW_PART_CONFIGURATION_MATERIAL')}</p>
							</div>
							<div>
								<p>{solution.printerMaterial.name}</p>
							</div>
							{!isAmOriginalMaterial || isStandardCost ? (
								<div>
									<p>{configuration.material.name}</p>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
					<div className="small">
						<h2>{getString('COST_PARAMETER')}</h2>
						<br />
						<div className={cx('analysis-table cost')}>
							{part?.productLifeCycle && (
								<div>
									<p>{getString('PRODUCT_LIFE_CYCLE')} </p>
									{configuration.productLifeCycle ||
										getString('UPLOAD_PROJECT_SCENARIO_METHODS_HEADER')[
											part.productLifeCycle
										]}
								</div>
							)}
							<div>
								<p>{getString('SURFACE_AREA_ADDED')} </p>
								{checkMark(surfaceIsOn)}
							</div>
							<div>
								<p>{getString('INITIAL_SETUP')}</p>
								{checkMark(!!configuration.isTooling)}
							</div>
							<div>
								<p>{getString('COMPLEX_PART')} </p>
								{checkMark(!!complexGeometry)}
							</div>
							<div>
								<p>{getString('BATCH_SIZE')} </p>
								{getString('PART_S').format(configuration.quantity)}
								{configuration.quantity &&
									configuration.quantity > 1 &&
									's'}{' '}
								&nbsp;
								{part.countInAssembly > 1 && (
									<>({getString('IN_ASSEMBLY').format(part.countInAssembly)})</>
								)}
							</div>
						</div>
					</div>
				</div>
				<LineBreak />
			</div>
		</div>
	)
}

export default memo(AnalysisParametersPdf)
