import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { every, uniqBy } from 'lodash'

import {
	CONFIGURATION_CALCULATED,
	DO_REFRESH_CONFIGURATIONS,
	GET_PROJECTS_FAILED,
	GET_PROJECTS_SUCCESS,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION
} from 'global actions/types'
import {
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { checkReanalysisProjectsPoller } from 'global actions/UserActions'
import { store } from 'index'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { ReanalyzingStatus } from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import { SelectProject } from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsInterface'
import { prepareDataToSelect } from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsService'
import {
	CHANGE_ALL_PROJECTS_DATA,
	CHANGE_PROJECTS_DATA,
	CHANGE_RADIO_BUTTON_DEEP_RESET,
	CHANGE_RECALCULATE_ORGANIZATION_ID,
	CLEAR_ALL_PROJECTS_DATA,
	GET_ALL_PROJECTS_DATA,
	TOGGLE_USE_ORGANIZATION_CHECKBOX,
	UPDATE_PROJECTS_DATA,
	UPDATE_PROJECTS_DATA_ERROR,
	UPDATE_PROJECTS_DATA_SUCCESS
} from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsTypes'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Project, ProjectStatus } from 'Services/models/IProject'
import {
	calculateProjects,
	checkRecalculateProcessByUser,
	getProjects,
	stopRecalculatingByUser
} from 'Services/Network'
import Poller from 'Services/PollingService/Poller'
import { SHOW_NOTIFICATION, YES } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

let poller = new Poller()

export const handleRecalculateProjects = (
	projects: Array<string>,
	deepReset: string,
	preparedProjects: Array<SelectProject>,
	recalculateOrganizationId?: number
) => {
	return async (dispatch: any) => {
		if (recalculateOrganizationId) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: getString('RECALCULATE_WITH_SITE_WARNING_HEADER'),
					confirmText: YES,
					showCancel: true,
					text: getString('RECALCULATE_WITH_SITE_WARNING_BODY'),
					onConfirm: () => {
						dispatch(
							updateProjectsData(
								projects,
								deepReset,
								preparedProjects,
								recalculateOrganizationId
							)
						)
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					alertType: AlertType.WARNING
				}
			})
		} else {
			dispatch(
				updateProjectsData(
					projects,
					deepReset,
					preparedProjects,
					recalculateOrganizationId
				)
			)
		}
	}
}

export const updateProjectsData = (
	projects: Array<string>,
	deepReset: string,
	preparedProjects: Array<SelectProject>,
	recalculateOrganizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: UPDATE_PROJECTS_DATA })
		const query = projects.map(project => {
			return { projectId: project }
		})
		const { features, maxAllowedUploadProjects, roles } = store.getState().user
		try {
			await calculateProjects({
				query,
				toCheckDaytimeRange: false,
				deepReset,
				organizationId: recalculateOrganizationId
			})
			store.dispatch(toggleProjectsData([], preparedProjects))
			try {
				store.dispatch(getProjectsData())
				const response = await getProjects()
				const projects = response?.data?.projects
				const userProjectFolders = response?.data?.userProjectFolders

				if (projects && projects[0]?.ownerId) {
					store.dispatch(checkReanalysisProjectsPoller(projects[0]?.ownerId))
					dispatch({
						type: GET_PROJECTS_SUCCESS,
						payload: {
							projects,
							features,
							maxAllowedUploadProjects,
							roles,
							userProjectFolders
						}
					})
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.SUCCESS,
							notificationMessage: getString(
								'RECALCULATE_PROJECTS_NOTIFICATION'
							)
						}
					})
				}
			} catch (error: any) {
				dispatch({ type: GET_PROJECTS_FAILED, payload: error.message })
			}

			dispatch({ type: UPDATE_PROJECTS_DATA_SUCCESS })
			dispatch({
				type: CONFIGURATION_CALCULATED
			})
			dispatch({
				type: DO_REFRESH_CONFIGURATIONS,
				payload: { doRefreshConfigurations: true }
			})
		} catch (err) {
			dispatch({ type: UPDATE_PROJECTS_DATA_ERROR })
			console.error(err)
		}
	}
}
export const stopAllRecalculatingAlert = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				headerTitle: getString('STOP_RECALCULATING'),
				confirmText: getString('STOP_RECALCULATING'),
				text: getString('STOP_RECALCULATING_ALERT_BODY'),
				onConfirm: () => stopAllRecalculating(dispatch),
				alertType: AlertType.WARNING
			}
		})
	}
}

export const stopAllRecalculating = async (dispatch: Dispatch<AnyAction>) => {
	dispatch({
		type: ALERT_CALCULATION_STARTED
	})
	try {
		const response = await stopRecalculatingByUser()
		if (response?.status === 200) {
			let timeOutDateTime = new Date()
			timeOutDateTime.setHours(timeOutDateTime.getHours() + 1)
			poller = new Poller(
				1000,
				timeOutDateTime,
				() => checkRecalculateProcessByUser(),
				(res: any) => res?.data?.finished
			)

			poller
				.start()
				.then((res: any) => {
					if (res?.data?.finished) {
						getProjects().then((projects: any) => {
							const { features, maxAllowedUploadProjects, roles } =
								store.getState().user
							dispatch({
								type: GET_PROJECTS_SUCCESS,
								payload: {
									projects: projects.data.projects,
									userProjectFolders: projects.data.userProjectFolders,
									features,
									maxAllowedUploadProjects,
									roles
								}
							})
						})

						dispatch({
							type: HANDLE_NOTIFICATION,
							payload: {
								notificationType: SHOW_NOTIFICATION.SUCCESS,
								notificationMessage: getString('STOP_RECALCULATING_SUCCESS_MSG')
							}
						})
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					}
				})
				.catch((error: any) => {
					console.error(error)
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.ERROR,
							notificationMessage: getString('STOP_RECALCULATING_FAILED_MSG')
						}
					})
					dispatch({
						type: ALERT_POPUP_CANCELED
					})
				})
		} else {
			console.error(response)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('STOP_RECALCULATING_FAILED_MSG')
				}
			})
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
		}
	} catch (error) {
		console.error(error)
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: getString('STOP_RECALCULATING_FAILED_MSG')
			}
		})
		dispatch({
			type: ALERT_POPUP_CANCELED
		})
	}
}

export const toggleProjectsData = (
	selected: Array<string>,
	preparedProjects: Array<SelectProject>
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		let updated = preparedProjects?.map((project: SelectProject) => {
			project.selected = selected.some(
				(selectedId: string) => selectedId === project.id
			)
			return project
		})

		dispatch({
			type: CHANGE_PROJECTS_DATA,
			payload: {
				projectData: updated
			}
		})
	}
}
export const toggleAllProjectsData = (
	preparedProjects: Array<SelectProject>
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const isSelected = every(preparedProjects, { selected: true })
		let updated = preparedProjects?.map((project: SelectProject) => {
			project.selected = !isSelected
			return project
		})

		dispatch({
			type: CHANGE_ALL_PROJECTS_DATA,
			payload: {
				projectData: updated
			}
		})
	}
}

export const toggleOnlyProjectsData = (
	id: string,
	preparedProjects: Array<SelectProject>
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		let updated = preparedProjects?.map((project: SelectProject) => {
			project.selected = project.id === id
			return project
		})

		dispatch({
			type: CHANGE_PROJECTS_DATA,
			payload: {
				projectData: updated
			}
		})
	}
}

export const getProjectsData = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			let response = await getProjects()

			let projects = response?.data?.projects
				?.sort((a: Project, b: Project) => (b.createdAt > a.createdAt ? 1 : -1))
				.filter((project: Project) =>
					[
						ProjectStatus.published,
						ProjectStatus.complete,
						ProjectStatus.failed
					].includes(project.status)
				)
				.filter(
					(project: any) =>
						project.reanalyzingStatus === ReanalyzingStatus.complete
				)

			if (
				Feature.isFeatureOn(FeatureComponentId.PROJECT_BUNDLE_PAGE) &&
				projects
			) {
				projects = projects.map((project: any) => ({
					id: project.bundleId ? project.bundle.id : project.id,
					name: project.bundleId ? project.bundle.name : project.name
				}))
				projects = uniqBy(projects, 'id')
			}

			dispatch({
				type: GET_ALL_PROJECTS_DATA,
				payload: {
					projectData: prepareDataToSelect(projects)
				}
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (err) {
			console.error(err)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}

export const clearProjectsData = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CLEAR_ALL_PROJECTS_DATA
		})
	}
}

export const updateDeepResetType = (value: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CHANGE_RADIO_BUTTON_DEEP_RESET,
			payload: {
				deepReset: value
			}
		})
	}
}

export const toggleRecalculateWithOrganizationCheckbox = (value: boolean) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: TOGGLE_USE_ORGANIZATION_CHECKBOX,
			payload: {
				recalculateWithOrganization: value
			}
		})
	}
}

export const changeRecalculateOrganizationId = (organizationId: number) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CHANGE_RECALCULATE_ORGANIZATION_ID,
			payload: {
				recalculateOrganizationId: organizationId
			}
		})
	}
}
