import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import {
	onApplicationChange,
	onCADChange,
	onChangeProjectSettingsScenarios,
	onQuantityChange,
	onStandardCostCheckboxChange,
	projectNameChanged,
	updateProjectStandardCost
} from '../../UploadProjectActions'
import OrganizationSelector from './OrganizationSelector'
import UploadProjectScenarios from './UploadProjectScenarios'
import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import CastorSubscriptionAlert from 'Scenes/Components/CastorSubscriptionAlert'
import FieldWithLabel from 'Scenes/Components/FieldWithLabel'
import FlexBox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import SelectorWithOther from 'Scenes/Components/SelectorWithOther'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

const UploadDetails: FC = () => {
	const {
		projectName,
		loadingLifeCycle,
		selectedProjectScenario,
		projectSettingsScenario,
		quantity,
		CAD,
		isStandardCostBoxChecked
	} = useSelector((state: RootStateOrAny) => state.uploadProject)
	const { applications, defaultProjectScenario } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const projectSettingsScenariosOn = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_SETTINGS_SCENARIOS
	)
	const isCurrentStandardCostOn = Feature.isFeatureOn(
		FeatureComponentId.CURRENT_STANDARD_COST
	)
	const applicationSelector = Feature.isFeatureOn(
		FeatureComponentId.APPLICATION_SELECTOR
	)
	const cadSoftware = Feature.isFeatureOn(FeatureComponentId.CAD_SOFTWARE_INPUT)
	const addProjectName = Feature.isFeatureOn(
		FeatureComponentId.ADD_PROJECT_NAME
	)
	const customizeOrganizationsIsOn = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
	)
	const dispatch = useDispatch()
	const { projectStandardCost } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	return (
		<CardHeaderBox
			contentClass="new-upload-project__card"
			title={getString('NEW_UPLOAD_SECTION_DETAILS')}
			content={
				<div
					className={cx('new-upload-project__content', {
						'with-project-name': addProjectName,
						'without-project-name': !addProjectName
					})}
				>
					<CastorSubscriptionAlert />
					<div
						className={cx({
							'new-upload-project--name-site-wrapper':
								customizeOrganizationsIsOn
						})}
					>
						{addProjectName && (
							<FieldWithLabel
								fieldId="upload-form-field--project-name"
								labelName={getString('UPLOAD_PROJECT_PROJECT_NAME')}
								fieldPlaceholder={getString('NEW_UPLOAD_ENTER_TEXT')}
								fieldValue={projectName}
								fieldOnChange={value => dispatch(projectNameChanged(value))}
								qaDataElementName="data-qa-project-name-field"
							/>
						)}
						<OrganizationSelector />
					</div>
					<div>
						{projectSettingsScenariosOn && (
							<UploadProjectScenarios
								loadingLifeCycle={loadingLifeCycle}
								onChangeProjectSettingsScenarios={(
									name: string,
									selectedProjectScenario: any
								) =>
									dispatch(
										onChangeProjectSettingsScenarios(
											name,
											selectedProjectScenario
										)
									)
								}
								selectedProjectScenario={selectedProjectScenario}
								projectSettingsScenario={projectSettingsScenario}
								userScenario={defaultProjectScenario}
							/>
						)}
					</div>
					<FieldWithLabel
						isNumberField
						fieldId="upload-form-field--yearly-prod"
						labelName={getString('UPLOAD_PROJECT_YEARLY_PROD')}
						fieldValue={quantity}
						fieldClassName="short"
						fieldOnChange={value => dispatch(onQuantityChange(value))}
						qaDataElementName="data-qa-upload-page-quantity"
					/>
					{isCurrentStandardCostOn ? (
						<FlexBox
							flexDirection="column"
							alignItems="flex-start"
							justifyContent="flex-start"
							width="100%"
							marginBottom={10}
						>
							<FlexBox
								alignItems="center"
								justifyContent="flex-start"
								className="label-field"
							>
								{getString('CURRENT_STANDART_COST_UPLOAD_PAGE')}
								<InfoBox
									boxDirection="bottom-start"
									boxContact={getString(
										'CURRENT_STANDART_COST_UPLOAD_PAGE_HOVER_TEXT'
									)}
									iconClassName="icon-info"
								/>
							</FlexBox>
							<FlexBox
								alignItems="center"
								justifyContent="flex-start"
								width="100%"
							>
								<FieldWithLabel
									disabled={isStandardCostBoxChecked}
									isNumberField
									isCommaDisabled={false}
									isDotDisabled={false}
									fieldId="upload-form-field--yearly-prod"
									wrapperClassName="upload-form-standard-cost"
									labelName={''}
									fieldValue={
										projectStandardCost > 0 ? projectStandardCost : ''
									}
									fieldClassName={
										isStandardCostBoxChecked ? 'short__disabled_box' : 'short'
									}
									fieldOnChange={value =>
										dispatch(updateProjectStandardCost(value))
									}
									qaDataElementName="data-qa-upload-page-estimated-quantity"
									currency={'$'}
									isHoverText={true}
								/>
								<div className="upload-form-standard-cost-box">
									<CastorCheckbox
										checked={isStandardCostBoxChecked}
										onChange={() =>
											dispatch(
												onStandardCostCheckboxChange(!isStandardCostBoxChecked)
											)
										}
										className="check-boxs--icon"
										inputProps={{
											'aria-label': 'secondary checkbox'
										}}
									/>
								</div>
								<h1 className="upload-form-standard-cost-text">
									{getString('NO_STANDARD_COST')}
								</h1>
							</FlexBox>
						</FlexBox>
					) : (
						<></>
					)}
					{applicationSelector && (
						<SelectorWithOther
							items={applications.map((application: any) => application.name)}
							placeHolder={getString('UPLOAD_PROJECT_APPLICATION')}
							onChange={(value: any) => dispatch(onApplicationChange(value))}
							className="upload-form-field-application"
						/>
					)}
					{cadSoftware && (
						<FieldWithLabel
							fieldId="upload-form-field--project-cad"
							labelName={getString('UPLOAD_PROJECT_CAD_SOFTWARE')}
							fieldValue={CAD}
							fieldOnChange={value => dispatch(onCADChange(value))}
						/>
					)}
				</div>
			}
		/>
	)
}

export default memo(UploadDetails)
