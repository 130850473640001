import { FC, memo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { METADATA, TAB_2d_INDEX, UPLOAD } from '../../constants'
import {
	onSelectToleranceClass,
	onToleranceToggle
} from '../../UploadProjectActions'
import ToleranceCheckBox from '../../UploadTolerance/ToleranceCheckBox'
import ToleranceSlider from '../../UploadTolerance/ToleranceSlider'
import UploadAdvancedSettings from './UploadAdvancedSettings'
import UploadMaterialsParameters from './UploadMaterialsParameters'
import UploadMetaData from './UploadMetaData'
import UploadProjectType from './UploadProjectType'
import { getUploadExplanation, makeFilterItemsList } from './UploadTypeService'
import { removeFiltersItem } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import Devider from 'Scenes/Components/Devider/Devider'
import Flexbox from 'Scenes/Components/FlexBox'
import ItemsList, { ItemList } from 'Scenes/Components/ItemsList'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

interface UploadTypeProps {
	selectedType: string | null
	is2dUpload: boolean
	isDrawingFeatureOn?: boolean
	uploadTabIndex: number
	setSelectedType: (value: string | null) => void
}

const UploadType: FC<UploadTypeProps> = ({
	selectedType,
	is2dUpload,
	isDrawingFeatureOn,
	uploadTabIndex,
	setSelectedType
}) => {
	const dispatch = useDispatch()
	const [showToleranceAlert, setShowToleranceAlert] = useState<boolean>(false)
	const [showAdvancedSettingsAlert, setShowAdvancedSettingsAlert] =
		useState<boolean>(false)
	const { filterItemsList, geometryAnalysisPart } = useSelector(
		(state: RootStateOrAny) => {
			return (
				state.AdvancedSettingsReducer?.advancedStates[UPLOAD] ||
				new AdvancedSettingsInitialState()
			)
		}
	)
	const { toleranceIncluded, filesToUpload } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const removeFilter = (filterItem: ItemList) => {
		dispatch(
			removeFiltersItem(UPLOAD, filterItem.key, !!filterItem.geometryAnalysis)
		)
	}

	let filterItems = makeFilterItemsList(filterItemsList, geometryAnalysisPart)

	let { explanationText, explanationSubText } =
		getUploadExplanation(selectedType)

	return (
		<div className="new-upload-project__block box-type">
			<CardHeaderBox
				contentClass="new-upload-project__card"
				title={getString('NEW_UPLOAD_SECTION_TYPE')}
				content={
					<div className="new-upload-project__content">
						<div>
							<div className="label">{getString('NEW_UPLOAD_FILE_TYPES')}</div>
							<UploadProjectType
								filesLength={filesToUpload?.length}
								selectedType={selectedType}
								setSelectedType={setSelectedType}
							/>
						</div>
						<div className="new-upload-project__content-material">
							<div className="label">{explanationText}</div>
							{explanationSubText && <p>{explanationSubText}</p>}
							{selectedType === METADATA ? (
								<UploadMetaData isOnPrem={isOnPrem} />
							) : (
								<UploadMaterialsParameters
									isDrawingFeatureOn={isDrawingFeatureOn}
									selectedType={selectedType}
									uploadTabIndex={uploadTabIndex}
									is2dUpload={is2dUpload}
								/>
							)}
						</div>
						<div className="new-upload-project__content-advanced">
							<Flexbox alignItems="flex-end">
								<Button
									color="transparent"
									onClick={() => setShowAdvancedSettingsAlert(true)}
									className="upload-project-filters-button"
								>
									{getString('ADVANCED_MATERIAL_SETTINGS')}
								</Button>
								<ItemsList
									dataQa={'upload-list-filters'}
									roundIcon={true}
									items={filterItems || []}
									onRemoveItem={(filterItem: any) => removeFilter(filterItem)}
								/>
							</Flexbox>
						</div>
						<Devider size="100%" />
						<div className="new-upload-project__content-tolerance">
							{showToleranceAlert && (
								<ToleranceSlider
									changeToleranceAlert={() => setShowToleranceAlert(false)}
									showToleranceAlert={showToleranceAlert}
								/>
							)}
							<ToleranceCheckBox
								renderButtonComponents={true}
								is2dUpload={uploadTabIndex === TAB_2d_INDEX}
								componentId={2}
								toleranceIncluded={toleranceIncluded}
								onClick={() => setShowToleranceAlert(true)}
								onChange={() => dispatch(onToleranceToggle())}
								onSelectToleranceClass={(e: any) =>
									dispatch(onSelectToleranceClass(e))
								}
							/>
						</div>
					</div>
				}
			/>
			<UploadAdvancedSettings
				showAdvancedSettingsAlert={showAdvancedSettingsAlert}
				setShowAdvancedSettingsAlert={(show: boolean) =>
					setShowAdvancedSettingsAlert(show)
				}
			/>
		</div>
	)
}

export default memo(UploadType)
