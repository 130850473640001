import React, { FC, memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import cx from 'classnames'

import { panelConfiguration, panelMap } from '../../PartAnalysisConstants'
import ConfigurationInformation from './ConfigurationInformation'
import ConfigurationSolutionMap from './ConfigurationSolutionMap'
import FlexBox from 'Scenes/Components/FlexBox'
import NewFeatureTag from 'Scenes/Components/NewFeatureTag'
import {
	usePartReducer,
	useSolutionReducer
} from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import Loader from 'Scenes/Loader/Loader'
import {
	defaultNamingPrinterConfiguration,
	partResults
} from 'Services/Constants'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IClusterPart } from 'Services/models/ICluster'
import { LocationPartsParams, Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

interface ConfigurationDetailsProps {
	configuration: any
	part: Part
	cluster: IClusterPart | null
	partSolution: any
	activeTabChange: Function
	initialToleranceValue: any
	disablePart: boolean
}

const ConfigurationDetails: FC<ConfigurationDetailsProps> = ({
	configuration,
	part,
	cluster,
	partSolution,
	initialToleranceValue,
	activeTabChange,
	disablePart
}) => {
	const [expanded, setExpanded] = useState<any>(undefined)
	const { alternativeSolutionsLoading, initialAlternativeSolutions } =
		usePartReducer()
	const { loadingCalculation } = useSolutionReducer(configuration.id)
	const prevLoadingCalculation = usePrevious(loadingCalculation) || false
	const prevAlternativeLoadingCalculation =
		usePrevious(alternativeSolutionsLoading) || false
	const isSolutionMapFeatureOn =
		Feature.isFeatureOn(FeatureComponentId.ALTERNATIVE_SOLUTIONS_MAP) &&
		!part?.isDrawing &&
		!cluster &&
		part?.formatType !== METADATA
	const showParameterSection =
		Feature.isFeatureOn(FeatureComponentId.CONFIGURATION_PARAMETER_SECTION) &&
		part?.formatType !== METADATA &&
		cluster?.formatType !== METADATA

	useEffect(() => {
		//close solution map if calculation is in process
		if (
			(loadingCalculation && prevLoadingCalculation !== loadingCalculation) ||
			(alternativeSolutionsLoading &&
				prevAlternativeLoadingCalculation !== alternativeSolutionsLoading)
		) {
			handleChange({}, false, panelMap)
		}
	}, [loadingCalculation, alternativeSolutionsLoading])

	const configurationMapDisabled =
		!configuration.name.includes(defaultNamingPrinterConfiguration.bestMatch) ||
		!!configuration.filters?.printerMaterialID ||
		!configuration.solution ||
		!!configuration.cluster ||
		configuration.result === partResults.notPrintable ||
		initialAlternativeSolutions.length < 2

	const handleChange = (e: any, isExpanded: any, panel: any) => {
		setExpanded(isExpanded ? panel : false)
	}

	const scrollTo = (top?: boolean) => {
		scroller.scrollTo(top ? 'scrollTop' : 'scrollBottom', {
			duration: 100,
			delay: 0,
			smooth: true,
			containerId: `main-content-scroller`
		})
	}

	useEffect(() => {
		if (configuration.result === CADAnalysisResult.notPrintable) {
			setExpanded(false)
		}
	}, [configuration])

	const isSolutionMapOpen = expanded === panelMap
	const isPanelConfigurationOpen = expanded === panelConfiguration
	const location = useLocation<LocationPartsParams>()
	const isWeightReductionConfiguration = location?.state?.isWeightReduction
	return (
		<div className="part-tabpanel--details">
			{showParameterSection && (
				<Accordion
					className="part-tabpanel--details_accordion"
					expanded={isPanelConfigurationOpen}
					TransitionProps={{
						onEntered: () => scrollTo()
					}}
					onChange={(e: any, expanded) => {
						handleChange(e, expanded, panelConfiguration)
					}}
				>
					<AccordionSummary
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						className="part-tabpanel--details_content"
					>
						<ExpandMoreIcon
							className={cx('expand', {
								open: isPanelConfigurationOpen
							})}
						/>
						<div className="part-tabpanel--details_heading">
							{getString('CALCULATED_CONFIGURATION_TITLE')}
						</div>
					</AccordionSummary>
					<AccordionDetails className="part-tabpanel--details_body">
						<ConfigurationInformation
							initialToleranceValue={initialToleranceValue}
							configuration={configuration}
							part={part}
							partSolution={partSolution}
							disablePart={disablePart}
						/>
					</AccordionDetails>
				</Accordion>
			)}
			{isSolutionMapFeatureOn && !isWeightReductionConfiguration && (
				<Accordion
					TransitionProps={{
						onExited: () => scrollTo(true),
						onEntered: () => scrollTo(),
						timeout: 0
					}}
					disabled={
						configurationMapDisabled || loadingCalculation || disablePart
					}
					className={cx('part-tabpanel--details_accordion', {
						loading: alternativeSolutionsLoading,
						disabled:
							configurationMapDisabled || loadingCalculation || disablePart
					})}
					expanded={isSolutionMapOpen}
					onChange={(e: any, expanded) => {
						handleChange(e, expanded, panelMap)
					}}
				>
					<AccordionSummary
						aria-controls="panel2bh-content"
						id="panel2bh-header"
						className="part-tabpanel--details_content"
					>
						{alternativeSolutionsLoading ? (
							<Loader load={true} size={40} message="" showFlex={false} />
						) : (
							<ExpandMoreIcon
								className={cx('expand', {
									open: isSolutionMapOpen
								})}
							/>
						)}
						<FlexBox
							className="part-tabpanel--details_heading"
							alignItems="center"
						>
							{getString('SOLUTION_MAP_TITLE')}
						</FlexBox>
						<NewFeatureTag isWhite={true} />
						<div className="part-tabpanel--details_sub-heading">
							{configuration.result === partResults.notPrintable
								? getString('UNPRINTABLE_SOLUTION_MAP')
								: getString('SOLUTION_MAP_SUBTITLE')}
						</div>
					</AccordionSummary>
					<AccordionDetails className="part-tabpanel--details_body">
						{isSolutionMapOpen && (
							<ConfigurationSolutionMap
								activeTabChange={activeTabChange}
								disablePart={disablePart}
								configuration={configuration}
							/>
						)}
					</AccordionDetails>
				</Accordion>
			)}
			<div id="scrollBottom" />
		</div>
	)
}

export default memo(ConfigurationDetails)
