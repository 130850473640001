import { ChangeEvent, FC, memo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'

import {
	onDeleteCycleKwh,
	onDeletePrinterTypicalKwh,
	onPrinterTypicalkwhChange,
	onSetNewPrinterTypicalkwh,
	onTmTypicalkwhChange,
	onUpdateTypicalkwhTm
} from '../../CustomizeCO2CalculationAction'
import CO2ManufacturingCycleSelector from '../CO2ManufacturingCycleSelector'
import CO2ManufacturingPrinterSelector from '../CO2ManufacturingPrinterSelector'
import CustomizeCO2CycleTable from './CustomizeCO2CycleTable'
import CustomizeCO2TypicalKwhTable from './CustomizeCO2TypicalKwhTable'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { Printer } from 'Services/models/IPrinter'
import { TYPICAL_POWER_CONSUMPTION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField
const DEFAULT_SELECTED_VALUE = '0'

const CustomizeCo2PowerTypicalKwh: FC = () => {
	const { printersFullData } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const {
		typicalKwhList,
		typicalKwhPrinter,
		typicalKwhTm,
		cycleList,
		printersTypicalkwhList
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()
	const [isErrorPrinter, setIsErrorPrinter] = useState(false)
	const [isErrorTm, setIsErrorTm] = useState(false)
	const [selectedPrinterCompany, setSelectedPrinterCompany] = useState(
		DEFAULT_SELECTED_VALUE
	)
	const [selectedPrinterName, setSelectedPrinterName] = useState(
		DEFAULT_SELECTED_VALUE
	)
	const [selectedPrinterId, setSelectedPrinterId] = useState(null)
	const [selectedCycle, setSelectedCycle] = useState(DEFAULT_SELECTED_VALUE)

	const typicalKwhChange = (value: string) => {
		if (Number(value) < 0 || Number(value) > 1000) {
			setIsErrorPrinter(true)
		} else {
			setIsErrorPrinter(false)
			dispatch(onPrinterTypicalkwhChange(value))
		}
	}
	const typicalKwhTmChange = (value: string) => {
		if (Number(value) < 0 || Number(value) > 1000) {
			setIsErrorTm(true)
		} else {
			setIsErrorTm(false)
			dispatch(onTmTypicalkwhChange(value))
		}
	}

	const onSaveTypicalKwh = () => {
		dispatch(
			onSetNewPrinterTypicalkwh(
				{
					printer: {
						id: selectedPrinterId,
						name: selectedPrinterName
					},
					typicalKwh: typicalKwhPrinter
				},
				adminSelectedOrganizationId
			)
		)
		setSelectedPrinterCompany(DEFAULT_SELECTED_VALUE)
		setSelectedPrinterName(DEFAULT_SELECTED_VALUE)
		setSelectedPrinterId(null)
	}

	const onSaveTypicalKwhTm = () => {
		dispatch(
			onUpdateTypicalkwhTm(
				{
					cycleName: selectedCycle,
					typicalPowerConsumption: typicalKwhTm
				},
				adminSelectedOrganizationId
			)
		)
		setSelectedCycle(DEFAULT_SELECTED_VALUE)
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	return (
		<div>
			<div className="custom-calculation-co2--prime-title">
				{getString('TYPICAL_KWH_PER_PRINTER_EXPLANATION')}
			</div>
			<>
				<Flexbox
					flexDirection="row"
					className="custom-calculation-co2--wrapper"
				>
					<Flexbox flexDirection="row" alignItems="center" width="100%">
						<CO2ManufacturingPrinterSelector
							printersFullData={printersFullData}
							selectedPrinterCompany={selectedPrinterCompany}
							setSelectedPrinterCompany={setSelectedPrinterCompany}
							selectedPrinterName={selectedPrinterName}
							setSelectedPrinterName={setSelectedPrinterName}
							setSelectedPrinterId={setSelectedPrinterId}
						></CO2ManufacturingPrinterSelector>
						<TextFieldTSX
							className="custom-calculation-co2--twh-picker"
							data-qa="data-qa-printer-input"
							type="number"
							placeholder={TYPICAL_POWER_CONSUMPTION}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								typicalKwhChange(e.target.value)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							onKeyDown={handleKeyDown}
							value={typicalKwhPrinter}
						/>
						<div className="custom-calculation-co2--prime-title">
							{getString('KWH')}
						</div>
					</Flexbox>
					<ButtonWithLoader
						onClick={onSaveTypicalKwh}
						disabled={
							isErrorPrinter ||
							isEmpty(typicalKwhPrinter) ||
							selectedPrinterName === DEFAULT_SELECTED_VALUE ||
							selectedPrinterCompany === DEFAULT_SELECTED_VALUE
						}
					>
						{getString('SET')}
					</ButtonWithLoader>
				</Flexbox>
				{isErrorPrinter && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_PRODUCT_USE_DATA_VALIDATION')}
					</div>
				)}
				{printersTypicalkwhList.filter(
					(printer: Printer) => printer.typicalKwh !== null
				).length > 0 && (
					<CustomizeCO2TypicalKwhTable
						printersTypicalkwhList={printersTypicalkwhList}
						deletePrinterTypicalKwh={(data: any) =>
							dispatch(
								onDeletePrinterTypicalKwh(data, adminSelectedOrganizationId)
							)
						}
					></CustomizeCO2TypicalKwhTable>
				)}
			</>
			<>
				<Flexbox
					flexDirection="row"
					className="custom-calculation-co2--wrapper"
				>
					<Flexbox flexDirection="row" alignItems="center" width="100%">
						<CO2ManufacturingCycleSelector
							cycleList={cycleList}
							selectedCycle={selectedCycle}
							setSelectedCycle={setSelectedCycle}
						></CO2ManufacturingCycleSelector>
						<TextFieldTSX
							className="custom-calculation-co2--twh-picker"
							data-qa="data-qa-tecnology-input"
							type="number"
							placeholder={TYPICAL_POWER_CONSUMPTION}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								typicalKwhTmChange(e.target.value)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							onKeyDown={handleKeyDown}
							value={typicalKwhTm}
						/>
						<div className="custom-calculation-co2--prime-title">
							{getString('KWH')}
						</div>
					</Flexbox>
					<ButtonWithLoader
						onClick={onSaveTypicalKwhTm}
						disabled={
							isErrorTm ||
							isEmpty(typicalKwhTm) ||
							selectedCycle === DEFAULT_SELECTED_VALUE
						}
					>
						{getString('SET')}
					</ButtonWithLoader>
				</Flexbox>
				{isErrorTm && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_PRODUCT_USE_DATA_VALIDATION')}
					</div>
				)}
				{typicalKwhList?.filter(
					(x: { typicalPowerConsumption: null }) =>
						x.typicalPowerConsumption !== null
				).length > 0 && (
					<CustomizeCO2CycleTable
						deleteCycleTypicalKwh={(data: any) =>
							dispatch(onDeleteCycleKwh(data, adminSelectedOrganizationId))
						}
						typicalKwhList={typicalKwhList}
					/>
				)}
			</>
		</div>
	)
}

export default memo(CustomizeCo2PowerTypicalKwh)
