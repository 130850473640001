import { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select, TextField } from '@material-ui/core'
import { map } from 'lodash'

import {
	onCo2PerKwChange,
	onUpdateCo2CarbonTypicalKwh
} from '../../CustomizeCO2CalculationAction'
import CustomizeCO2СarbonTypicalTable from './CustomizeCO2СarbonTypicalTable'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { DEFAULT_VALUE, manufactureTypeByCosting } from 'Services/Constants'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { getString } from 'Services/Strings/StringService'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem
const TextFieldTSX: any = TextField
const DEFAULT = 'default'
const DEFAULT_SELECTED_VALUE = '0'

enum ListNameEnum {
	COSTING_CO2_DIE_CASTING = 'COSTING_CO2_DIE_CASTING',
	COSTING_CO2_INVESTMENT_CASTING = 'COSTING_CO2_INVESTMENT_CASTING',
	COSTING_CO2_INJECTION_MOLDING = 'COSTING_CO2_INJECTION_MOLDING',
	COSTING_CO2_CNC = 'COSTING_CO2_CNC'
}

const CustomizeCO2СarbonTypicalKwh: FC = () => {
	const [isCarbonError, setIsCarbonError] = useState(false)
	const [isCarbonDefaultError, setIsCarbonDefaultError] = useState(false)
	const [carbonValue, setCarbonValue] = useState<string | number>('')

	const dispatch = useDispatch()
	const { initialCo2PerKwValue, co2PerKwValue, cycleList } = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)
	const { userSettingsDefaultValues } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const [selectedManufacture, setSelectedManufacture] = useState(
		DEFAULT_SELECTED_VALUE
	)

	const co2ManufactureValue = co2PerKwValue?.[selectedManufacture]
	const isEmptyValue = co2PerKwValue?.[selectedManufacture] === 0
	const co2UserManufactureDefault =
		userSettingsDefaultValues.co2PerKW?.[DEFAULT]
	const co2ManufactureDefault = co2PerKwValue?.[DEFAULT]
	const noValue = selectedManufacture === DEFAULT_SELECTED_VALUE

	const co2PerKwCheck = (
		value: string | number,
		singleInput: boolean = false
	) => {
		const numericValue = Number(value)
		const isOutOfRange = numericValue < 0.01 || numericValue > 5

		if (isOutOfRange) {
			singleInput ? setIsCarbonDefaultError(true) : setIsCarbonError(true)
		} else {
			singleInput ? setIsCarbonDefaultError(false) : setIsCarbonError(false)
		}
		if (singleInput) setCarbonValue(value)
		dispatch(
			onCo2PerKwChange(singleInput ? DEFAULT : selectedManufacture, value)
		)
	}

	const onSaveManufactureCo2Kwh = (singleInput: boolean = false) => {
		if (singleInput) {
			dispatch(
				onUpdateCo2CarbonTypicalKwh(
					{
						...initialCo2PerKwValue,
						[DEFAULT]: co2ManufactureDefault
					},
					undefined,
					adminSelectedOrganizationId
				)
			)
			setCarbonValue('')
		} else {
			dispatch(
				onUpdateCo2CarbonTypicalKwh(
					{
						...co2PerKwValue,
						[DEFAULT]: initialCo2PerKwValue?.[DEFAULT]
					},
					undefined,
					adminSelectedOrganizationId
				)
			)
		}
	}

	const returnToDefault = (singleInput: boolean = false) => {
		dispatch(
			onUpdateCo2CarbonTypicalKwh(
				{
					...co2PerKwValue,
					[DEFAULT]: co2UserManufactureDefault
				},
				undefined,
				adminSelectedOrganizationId
			)
		)
		singleInput && setCarbonValue('')
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	return (
		<>
			<div>
				<Flexbox
					flexDirection="row"
					className="custom-calculation-co2--wrapper"
				>
					<Flexbox flexDirection="row" alignItems="center" gap="13px">
						<div className="custom-calculation-co2--explanation-text">
							{getString('CARBON_CO2_CALCULATION')}
						</div>
						<TextFieldTSX
							data-qa="data-qa-production-electricity-co2-input-filed-general"
							type="number"
							placeholder={
								co2ManufactureDefault === co2UserManufactureDefault
									? co2ManufactureDefault + DEFAULT_VALUE
									: `${co2ManufactureDefault} ${getString('KG_UNITS')}`
							}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								co2PerKwCheck(e.target.value, true)
							}
							onKeyDown={handleKeyDown}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							value={carbonValue}
						/>
						<div className="custom-calculation-co2--explanation-text">
							{getString('CO2_PER_KWH')}
						</div>
						<InfoBox
							boxContact={getString('CARBON_CO2_CALCULATION_DATA')}
							boxClassName="custom-calculation-co2--icon-info-content"
							boxDirection="right-end"
						/>
					</Flexbox>
					<div>
						{co2ManufactureDefault !== co2UserManufactureDefault && (
							<ButtonWithLoader
								className="button-to-default"
								color="transparent"
								onClick={() => returnToDefault(true)}
							>
								{getString('RETURN_TO_DEFAULT')}
							</ButtonWithLoader>
						)}
						<ButtonWithLoader
							onClick={() => onSaveManufactureCo2Kwh(true)}
							disabled={
								!carbonValue ||
								co2ManufactureDefault === co2UserManufactureDefault ||
								!co2ManufactureDefault
							}
						>
							{getString('SET')}
						</ButtonWithLoader>
					</div>
				</Flexbox>
				{isCarbonDefaultError && (
					<div className="custom-calculation-co2--error">
						{getString('CARBON_CO2_CALCULATION_VALIDATION')}
					</div>
				)}
			</div>
			<div className="custom-calculation-co2--prime-title">
				{getString('TYPICAL_KWH_PER_PRINTER_MANUFACTURING')}
			</div>
			<Flexbox flexDirection="row" className="custom-calculation-co2--wrapper">
				<Flexbox flexDirection="row" alignItems="center" width="100%">
					<Flexbox className="cycle-selector">
						<SelectTsx
							className="select"
							data-qa="data-qa-technology-select-carbon-credit"
							value={selectedManufacture}
							disableUnderline={false}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setSelectedManufacture(e.target.value)
							}}
							MenuProps={{
								PaperProps: {
									style: {
										transform: 'translate3d(0, 0, 0)'
									}
								}
							}}
						>
							<MenuItemTsx value="0" disabled>
								{getString('TECHNOLOGY_NAME')}
							</MenuItemTsx>
							{map(
								cycleList,
								(
									list: Record<string, any> & {
										name: ListNameEnum
									}
								) => {
									return (
										<MenuItemTsx
											key={list.name}
											value={manufactureTypeByCosting[list.name] as string}
										>
											{list.label}
										</MenuItemTsx>
									)
								}
							)}
						</SelectTsx>
					</Flexbox>
					<Flexbox flexDirection="row" alignItems="center" gap="13px">
						<div className="custom-calculation-co2--explanation-text">
							{getString('CARBON_CO2_CALCULATION')}
						</div>
						<TextFieldTSX
							className="co2-technology-field"
							data-qa="data-qa-production-electricity-co2-input-filed"
							type="number"
							disabled={selectedManufacture === DEFAULT_SELECTED_VALUE}
							placeholder={
								noValue
									? getString('SELECT_THE_TECHNOLOGY')
									: co2ManufactureValue === co2UserManufactureDefault ||
									  isEmptyValue
									? `${co2UserManufactureDefault} ${DEFAULT_VALUE}`
									: `${co2ManufactureValue} ${getString('KG_UNITS')}`
							}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								co2PerKwCheck(e.target.value)
							}
							onKeyDown={handleKeyDown}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							value={co2PerKwValue?.[selectedManufacture] || ''}
						/>
						<div className="custom-calculation-co2--explanation-text">
							{getString('CO2_PER_KWH')}
						</div>
						<InfoBox
							boxContact={getString('CARBON_CO2_CALCULATION_DATA')}
							boxClassName="custom-calculation-co2--icon-info-content"
							boxDirection="right-end"
						/>
					</Flexbox>
				</Flexbox>

				<ButtonWithLoader
					onClick={() => {
						onSaveManufactureCo2Kwh()
					}}
					disabled={
						noValue ||
						isCarbonError ||
						co2ManufactureValue === DEFAULT_SELECTED_VALUE ||
						co2ManufactureValue === co2UserManufactureDefault ||
						isEmptyValue
					}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</Flexbox>
			{isCarbonError && (
				<div className="custom-calculation-co2--error">
					{getString('CARBON_CO2_CALCULATION_VALIDATION')}
				</div>
			)}
			{<CustomizeCO2СarbonTypicalTable />}
		</>
	)
}

export default memo(CustomizeCO2СarbonTypicalKwh)
