import { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { changeHolesNotify } from './CustomizeThreadActions'
import {
	cadName,
	customizeGeometryAnalysisSelectOptions,
	heatDeformationName,
	holesName,
	internalCavitiesName,
	millingMetalSupportName,
	orientationStabilityName,
	threadName,
	toleranceName,
	wallThicknessName
} from './CustomizeThreadService'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import SelectContent from 'Scenes/Components/SelectContent'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	CUSTOMIZE_GEOMETRY_ANALYSIS_EXPLANATION_ARRAY,
	CUSTOMIZE_GEOMETRY_ANALYSIS_TITLE
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

type IThreadContentProps = {
	showHeader?: boolean
}

const ThreadContent: FC<IThreadContentProps> = ({ showHeader }) => {
	const dispatch = useDispatch()
	const {
		holesFiltering,
		threadDetection,
		wallThicknessAlert,
		toleranceAlert,
		internalCavitiesAlert,
		cadAlert,
		millingMetalSupportAlert,
		heatDeformationAlert,
		orientationStabilityAlert
	} = useSelector(
		(state: RootStateOrAny) => state?.user?.userCustomizationSettings
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const isThreadDetectionOn = Feature.isFeatureOn(
		FeatureComponentId.THREAD_DETECTION
	)
	return (
		<div className="customize-solution-filter__threads--wrapper">
			{showHeader && (
				<CastorFormHeader
					explanationHeader={CUSTOMIZE_GEOMETRY_ANALYSIS_TITLE}
					explanationArray={CUSTOMIZE_GEOMETRY_ANALYSIS_EXPLANATION_ARRAY}
					isInCard={true}
				/>
			)}
			<div className="customize-solution-filter__threads">
				{isThreadDetectionOn && (
					<SelectContent
						value={threadDetection}
						title={getString('THREAD_BEHAVIOUR')}
						selectOptions={customizeGeometryAnalysisSelectOptions}
						onUpdate={(value: boolean) =>
							dispatch(
								changeHolesNotify(
									threadName,
									value,
									adminSelectedOrganizationId
								)
							)
						}
					/>
				)}

				<SelectContent
					value={holesFiltering}
					title={getString('HOLES_FILTERING')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(
							changeHolesNotify(holesName, value, adminSelectedOrganizationId)
						)
					}
				/>

				<SelectContent
					value={wallThicknessAlert}
					title={getString('MINIMAL_WALL_THICKNESS_GEOMETRY_ANALYSIS_TITLE')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(
							changeHolesNotify(
								wallThicknessName,
								value,
								adminSelectedOrganizationId
							)
						)
					}
				/>

				<SelectContent
					value={toleranceAlert}
					title={getString('TOLERANCE')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(
							changeHolesNotify(
								toleranceName,
								value,
								adminSelectedOrganizationId
							)
						)
					}
				/>

				<SelectContent
					value={internalCavitiesAlert}
					title={getString('INTERNAL_CAVITIES_GEOMETRY_ANALYSIS_TITLE')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(
							changeHolesNotify(
								internalCavitiesName,
								value,
								adminSelectedOrganizationId
							)
						)
					}
				/>

				<SelectContent
					value={cadAlert}
					title={getString('PART_RESULTS_CAD')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(
							changeHolesNotify(cadName, value, adminSelectedOrganizationId)
						)
					}
				/>

				<SelectContent
					value={millingMetalSupportAlert}
					title={getString(
						'MILLING_METAL_SUPPORT_ISSUES_GEOMETRY_ANALYSIS_TITLE'
					)}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(
							changeHolesNotify(
								millingMetalSupportName,
								value,
								adminSelectedOrganizationId
							)
						)
					}
				/>

				<SelectContent
					value={heatDeformationAlert}
					title={getString('HEAT_DEFORMATION_CONCERNS_GEOMETRY_ANALYSIS_TITLE')}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(
							changeHolesNotify(
								heatDeformationName,
								value,
								adminSelectedOrganizationId
							)
						)
					}
				/>

				<SelectContent
					value={orientationStabilityAlert}
					title={getString(
						'ORIENTATION_STABILITY_CONCERNS_GEOMETRY_ANALYSIS_TITLE'
					)}
					selectOptions={customizeGeometryAnalysisSelectOptions}
					onUpdate={(value: boolean) =>
						dispatch(
							changeHolesNotify(
								orientationStabilityName,
								value,
								adminSelectedOrganizationId
							)
						)
					}
				/>
			</div>
		</div>
	)
}

export default memo(ThreadContent)
