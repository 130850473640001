import React, { FC, memo } from 'react'

import DetailsPopup from '../DetailsPopup'
import { PART_ANALYSIS_REMOVE } from 'Services/Strings'

import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'

import './ItemsList.scss'

export interface ItemList {
	key: string
	value: string
	allowRemove: boolean
	geometryAnalysis?: boolean
	viewOnly?: boolean
	rest?: ItemList[]
}

interface ItemProps {
	item: ItemList
	onRemoveItem: (item: ItemList) => void
	roundIcon: boolean
}

export interface ItemListProps {
	items: ItemList[]
	onRemoveItem: (item: ItemList) => void
	roundIcon: boolean
	dataQa?: string
}

const SingleItem = memo(({ item, onRemoveItem, roundIcon }: ItemProps) => {
	return (
		<div className="items-list--item">
			<div>{item.value}</div>
			{item.allowRemove && (
				<div
					className="items-list--item--remove"
					onClick={() => {
						onRemoveItem(item)
					}}
					title={PART_ANALYSIS_REMOVE}
				>
					{roundIcon ? <CircleClose className="icon-close" /> : 'x'}
				</div>
			)}
		</div>
	)
})

const ItemsList: FC<ItemListProps> = ({
	dataQa,
	items,
	onRemoveItem,
	roundIcon
}) => {
	return (
		<div className="items-list" data-qa={dataQa || ''}>
			{items.map((item: ItemList) => (
				<DetailsPopup
					key={item.value}
					isHover={true}
					data={
						item?.rest ? (
							<div className="items-list popup" data-qa={dataQa || ''}>
								{item?.rest?.map(r => {
									return (
										<SingleItem
											key={r.value}
											item={r}
											onRemoveItem={onRemoveItem}
											roundIcon={roundIcon}
										/>
									)
								})}
							</div>
						) : (
							''
						)
					}
					targetClassName="pointer"
					popperDirection="bottom"
					popperClassName="info-box-wrapper pointer"
					popperContactClassName="info-box-data"
					inPortal={true}
				>
					<SingleItem
						key={item.value}
						item={item}
						onRemoveItem={onRemoveItem}
						roundIcon={roundIcon}
					/>
				</DetailsPopup>
			))}
		</div>
	)
}

export default memo(ItemsList)
