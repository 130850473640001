import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { getString } from 'Services/Strings/StringService'
import { generateKey } from 'Services/Utils/uniqKeyGenerator'

import costAnalysisTestImage from '../../../../assets/img/costAnalysisTestImage.png'

import './index.scss'

interface CostTextParam {
	name: string
	value: number
}

const CustomizeAnalysisCostImage: FC = () => {
	const { isImperialSystem } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const costingParametersExample = isImperialSystem
		? getString('COSTING_PARAMETERS_EXAMPLE_PART_DATA_IMPERIAL')
		: getString('COSTING_PARAMETERS_EXAMPLE_PART_DATA')

	return (
		<div className="cost-analysis-parameters__section-image">
			<div className="img">
				<img src={costAnalysisTestImage} alt="costAnalysisTestImage" />
			</div>
			<div className="text-block">
				<div className="explanation">
					<h3>{getString('COSTING_PARAMETERS_EXAMPLE_PART')}</h3>
					{getString('COSTING_PARAMETERS_EXAMPLE_PART_EXPLANATION')?.map(
						(string: string) => (
							<div key={generateKey(string)}>
								<p>{string}</p>
							</div>
						)
					) || ''}
				</div>
				<hr />
				<div className="properties">
					{costingParametersExample?.map((item: CostTextParam) => {
						return (
							<div className="property" key={item.name}>
								<span>{getString(item.name)}</span>
								<span>{item.value}</span>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
export default memo(CustomizeAnalysisCostImage)
