import { getString } from 'Services/Strings/StringService'

export enum GeometryAnalysisPartEnum {
	fastenersAnalyze = 'fastenersAnalyze',
	tubeAnalyze = 'tubeAnalyze',
	sheetMetalAnalyze = 'sheetMetalAnalyze',
	excludePartSubstrings = 'excludePartSubstrings'
}

export const customizeGeometryAnalysisStrings = {
	fastenersAnalyze: 'SCREW_NUTS_FILTERING',
	tubeAnalyze: 'RODS_PIPE_FILTERING',
	sheetMetalAnalyze: 'SHEET_METAL_FILTERING',
	excludePartSubstrings: 'INCLUDE_SUBSTRING_TO_ANALYSIS'
}

export const customizeGeometryAnalysisSelectOptions = [
	{
		value: 1,
		label: getString('INCLUDE_IN_ANALYSIS')
	},
	{
		value: 0,
		label: getString('EXCLUDE_FROM_ANALYSIS')
	}
]

export enum TagParam {
	text = 'text',
	label = 'label',
	itemType = 'itemType'
}

export interface TagParams {
	text: string,
	label: string,
	itemType: string
}
