export const ADLinks = [
	{
		teaserName: 'Additive Minds Academy Training',
		teaserHeadline: 'Identifying <br/> AM Applications',
		teaserButton: 'Sign Up Now',
		link: 'https://us.store.eos.info/collections/online-courses/products/identifying-am-applications',
		text:
			'<ul><li>Understand why choosing the RIGHT applications is' +
			' key.</li>' +
			' <li>Reduce' +
			' time to bring your additive manufacturing applications to' +
			' market.</li>' +
			' <li>Reduce cost per part for the identified applications.</li></ul>',
		image:
			'https://cdn.shopify.com/s/files/1/0550/9064/3121/products/Bild_product_Partscreening_selection_1000x.jpg?v=1632832288'
	},
	{
		teaserName: 'Additive Minds Academy Training',
		teaserHeadline: 'Design for AM (LPBF)',
		teaserButton: 'Sign Up Now',
		link: 'https://us.store.eos.info/products/design-for-am-lpbf?_pos=3&_fid=bafa27ea0&_ss=c',
		text: 'Design is one of the most important parts of the additive manufacturing process chain. Without good design, every other stage is unpredictable <br/>  <br/> This additive manufacturing module will teach you the ins and outs of proper design procedure, enabling you to design high quality and efficient projects that minimize build time and maximize profit.',
		image:
			'https://us.store.eos.info/cdn/shop/files/FundamentelsAMDesign.gif?v=1692081784&width=5760'
	},
	{
		teaserName: 'Additive Minds Academy Training',
		teaserHeadline: 'Getting started with Industrial 3D Printing',
		teaserButton: 'Sign Up Now',
		link: 'https://us.store.eos.info/products/getting-started-with-industrial-3d-printing',
		text:
			'Gain fundamental knowledge of additive manufacturing (AM), including: <br/>' +
			'<ul><li>Understand additive manufacturing (AM) technology</li>' +
			'<li>Opportunities and limitations of AM technology</li>' +
			'<li>Best-in-class case studies from a variety of industries</li>' +
			'<li>How to get started with additive manufacturing</li></ul>',
		image:
			'https://us.store.eos.info/cdn/shop/products/EOS_Hummingbird.00_04_03_04.Standbild004_2.jpg?v=1669738297&width=1920'
	}
]
