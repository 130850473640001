import { store } from '../../index'

export class Feature {
	id: number = 0
	name: string = ''
	on: boolean = true
	active: boolean = true
	description: string = ''
	componentId: string = ''

	static isFeatureOn: (
		featureId: FeatureComponentId | string,
		features?: Feature[]
	) => boolean | undefined = (
		featureId: FeatureComponentId | string,
		features: Feature[] = store.getState().user.features || []
	): boolean | undefined => {
		const feature = features.find((f: Feature) => f.componentId === featureId)
		return feature?.on
	}

	static isOneOfTheFeaturesOn = (
		featuresId: string[],
		features: Feature[] = store.getState().user.features || []
	): boolean | undefined => {
		const feature = features.find(
			(f: Feature) => featuresId.includes(f.componentId) && f.on
		)
		return !!feature
	}

	static isAllOfTheFeaturesOn = (
		featuresId: string[],
		features: Feature[] = store.getState().user.features || []
	): boolean | undefined => {
		return featuresId.every((f: string) => Feature.isFeatureOn(f, features))
	}

	static isFeatureActive = (
		featureId: FeatureComponentId | string,
		features: Feature[] = store.getState().user.features || []
	): boolean | undefined => {
		const feature = features.find((f: Feature) => f.componentId === featureId)
		return feature?.active
	}
}

export enum FeatureComponentId {
	MESH_HEALING = 'mesh_healing',
	UNIFICATION = 'unification',
	TRAY_ORIENTATION = 'tray_orientation',
	LANGUAGE = 'language',
	COMMUNICATION_TOOL = 'communication_tool',
	CUSTOM_MATERIALS = 'custom_materials',
	CURRENCY = 'currency',
	WEIGHT_REDUCTION = 'weight_reduction',
	WEIGHT_REDUCTION_OLD = 'weight_reduction_old',
	STANDARD_COST = 'standard_cost',
	SIMPLE_CONFIGURATION = 'simple_configuration',
	USER_PANEL = 'user_panel',
	CHAIN_BENEFITS = 'chain_benefits',
	VERSION_VIEWER = 'version_viewer',
	USER_PRINTER_PROPS = 'user_printer_props',
	CUSTOMIZE_IN_HOUSE_PRINTERS = 'customize_in_house_printers',
	CUSTOMIZE_FILTERS = 'customize_filters',
	CUSTOMIZE_USER_SEMI_PRO_PRINTERS = 'customize_user_semi_pro_printers',
	FEA = 'fea',
	ENTERPRISE_FEATURES = 'enterprise_features',
	POWERED_BY = 'powered_by',
	ENTERPRISE_UPGRADE = 'enterprise_upgrade',
	BREADCRUMBS = 'breadcrumbs',
	OVERHANGING = 'overhanging',
	HOLES_ANALYSIS = 'holes_analysis',
	THREAD_DETECTION = 'thread_detection',
	TAKE_A_TOUR = 'take_a_tour',
	TOLERANCES = 'tolerances',
	ADDRESS = 'address',
	REVIEW_AND_FIX_ISSUES = 'reviewAndFixIssues',
	AUTO_NAME_MATERIAL_SELECTOR = 'auto_name_material_selector',
	COST_ANALYSIS_TABLE = 'cost_analysis_table',
	SUMMARY_COST_TAB = 'summary_cost_tab',
	LEAD_TIME_TAB = 'lead_time_tab',
	DISABLE_RIBBON_INFORMATION = 'disable_ribbon_information',
	CNC_SUPPORT_REMOVAL = 'cnc_support_removal',
	CNC_SUPPORT_REMOVAL_RESULTS_DISPLAY = 'cnc_support_removal_results_display',
	PART_COMPLEXITY_SCORE = 'part_complexity_score',
	BOM_TEMPLATE = 'bom_template',
	UPLOAD_BOM_FILE = 'upload_bom_file',
	GET_A_Q = 'get_a_q',
	CUSTOMIZE_USER_POST_PROCESSES = 'customize_user_post_processes',
	APPLICATION_SELECTOR = 'application_selector',
	CAD_SOFTWARE_INPUT = 'cad_software_input',
	COMBINE_AROUND_PART = 'combine_around_part',
	CONTACT_US = 'contact_us',
	NEW_CONFIGURATION_PAGE = 'new_configuration_page',
	SIDE_BAR_AND_MENU = 'side_bar_and_menu',
	COST_TAB_INFORMATION = 'cost_tab_information',
	CONFIGURATION_HEADER = 'configuration_header',
	ACTION_COLUMN = 'action_column',
	MATERIAL_QUANTITY_RIBBON = 'material_quantity_ribbon',
	CONFIGURATION_FOOTER = 'configuration_footer',
	DISABLE_PART_LOADER = 'disable_part_loader',
	RETRIEVE_RESULTE_UNPRINTABLE = 'retrieve_result_unprintable',
	LOGO_ON_RIBBON = 'logo_on_ribbon',
	EXPORT_OPTIONS = 'export_options',
	CNC_ORIENTED_SUPPORT_REMOVAL = 'cnc_oriented_support_removal',
	INTERNAL_CAVITY = 'internal_cavity',
	ORIENTATION_STRUCTURAL_STABILITY = 'orientation_structural_stability',
	HEAT_DEFORMATION = 'heat_deformation',
	DARK_BACKGROUND = 'dark_background',
	UPLOAD_SCRIPT = 'upload_script',
	OFFLINE_ADDRESS = 'offline_address',
	DISABLE_SUPPORT_REMOVAL_MACHINING_USER_SETTINGS = 'disable_support_removal_machining_user_settings',
	DRAWING_ANALYSIS = 'drawing_analysis',
	DRAWING_COST_COST_AND_LEAD = 'drawing_cost',
	CONSOLE_QA = 'console_qa',
	MATERIAL_NAMES_MAPPING = 'material_names_mapping',
	RECALCULATE_PROJECTS = 'recalculate_projects',
	CUSTOMIZE_UNITS_TYPE = 'customize_units_type',
	ADMIN_SHOW_PROJECT = 'admin_show_project',
	ADMIN_PUBLISH_PROJECT = 'admin_publish_project',
	CO2_EMISSIONS = 'co2_emissions',
	CO2_BENEFIT = 'co2_benefit',
	CAD_FILE_INTACT = 'cad_file_intact',
	FULL_TRAY_ASSUMPTION = 'full_tray_assumption',
	SHOW_XYZ_PROPERTY = 'show_xyz_property',
	CUSTOMIZE_SUPPLY_CHAIN = 'customize_in_supply_chain',
	COST_ANALYSIS_TOOLTIP = 'cost_analysis_tooltip',
	LEAD_TIME_TOOLTIP = 'lead_time_tooltip',
	EXPORT_TO_PDF = 'export_to_pdf',
	BUY_TO_FLY = 'buy_to_fly',
	PROJECT_SETTINGS_SCENARIOS = 'project_settings_scenarios',
	HIDES_PRINTABLE_PRINT_ISSUES = 'hides_printable_print_issues',
	COMBINE_3D_2D = 'combine_3d_2d',
	FEATURE_TOGGLE = 'feature_toggle',
	DEFAULT_SCENARIO = 'default_project_scenario',
	ALLOW_RESET_ALL_PARTS_PROPERTIES = 'allow_reset_all_parts_properties',
	RECALCULATE_ALL_PARTS_PROPERTIES = 'recalculate_all_parts_properties',
	PART_ANALYSIS_TAB = 'part_analysis_tab',
	ALTERNATIVE_SOLUTIONS_MAP = 'alternative_solutions_map',
	CONFIGURATION_PARAMETER_SECTION = 'configuration_parameter_section',
	SHOW_VALUES_IN_RANGES = 'show_values_in_ranges',
	SKIP_PROJECT_PAGE = 'skip_project_page',
	PARTS_EXPORT = 'parts_export',
	DELETE_PROJECT = 'delete_project',
	DELETE_PART = 'delete_part',
	DELETE_ASSEMBLY_PART = 'delete_assembly_part',
	STRESS_ANALYSIS = 'stress_analysis',
	UPLOAD_2D_PROJECT = 'upload_2d_pdf_drawing',
	MATERIAL_FROM_FILES = 'material_from_files',
	IS_INHOUSE_PRINTER_FILTER = 'ihhouse_printer',
	UPDATE_CONFIGURATION = 'update_configuration',
	REMOVAL_MACHINING = 'post_processes_removal_machining',
	SURFACE_AREA_MACHINING = 'post_processes_surface_area_machining',
	SHOW_ACTUAL_SCORE = 'show_actual_score',
	UPLOAD_PROJECT_MESSAGE = 'upload_project_message',
	ADD_PROJECT_NAME = 'add_project_name',
	SHOW_AGREEMENT = 'show_agreement',
	SHOW_PROGRESS_ALERT = 'show_progress_alert',
	SHOW_AD_BLOCK = 'show_ad_block',
	DISABLE_NEW_CONFIGURATION_BUTTON = 'disable_new_configuration_button',
	SHOW_ADMIN_PANEL = 'show_admin_panel',
	SHOW_USER_PROFILE = 'show_user_profile',
	SHOW_USER_DOWNLOAD_HEALTH_STATISTICS = 'show_user_download_health_statistics',
	SHOW_USER_SETTINGS = 'show_user_settings',
	SHOW_SEND_TO_BUTTON = 'show_send_to_button',
	SHOW_SAVE_BUTTON = 'show_save_button',
	SHOW_CONTACT_ADDITIVE_MINDS_BUTTON = 'show_contact_additive_minds_button',
	SHOW_WARNING_IN_CIRCLE = 'show_warning_in_circle',
	SHOW_NO_CONFIGURATION_CONTENT = 'show_no_configuration_content',
	SHOW_CONTACT_US_BANNER = 'show_contact_us_banner',
	EDIT_PROJECT = 'edit_project',
	EDIT_PART = 'edit_part',
	EDIT_USER = 'edit_user',
	EDIT_USER_PERMISSION = 'edit_user_permission',
	CUSTOMIZE_USER = 'customize_user',
	SHOW_HOVER_DETAILS = 'show_hover_details',
	PART_PAGE_SIDE_BAR_AND_MENU = 'part_page_side_bar_and_menu',
	ADMIN_MENU_PROJECTS = 'admin_menu_projects',
	ADMIN_MENU_USERS = 'admin_menu_users',
	ADMIN_MENU_PRINTERS = 'admin_menu_printers',
	ADMIN_MENU_MATERIALS = 'admin_menu_materials',
	ADMIN_MENU_PRINTER_MATERIALS = 'admin_menu_printer_materials',
	ADMIN_MENU_PRINTERS_NAMES = 'admin_menu_printers_names',
	ADMIN_MENU_BUILD_RATE = 'admin_menu_build_rate',
	ADMIN_MENU_SETTINGS = 'admin_menu_settings',
	ADMIN_MENU_STRINGS = 'admin_menu_strings',
	ADMIN_MENU_COST_PARAMETERS = 'admin_menu_cost_parameters',
	ADMIN_MENU_3D_TECH_PARAMETERS = 'admin_menu_3d_tech_parameters',
	ADMIN_MENU_FEATURE_TOGGLE = 'admin_menu_feature_toggle',
	ADMIN_MENU_LICENSE_CREATOR = 'admin_menu_license_creator',
	ADMIN_MENU_LICENSE_SCREEN = 'admin_menu_license_screen',
	ADMIN_MENU_SERVICE_HEALTH = 'admin_menu_service_health',
	GLOBAL_SKIP_PROJECT_PAGE = 'global_skip_project_page',
	CHANGE_QUANTITY_CONFIGURATION = 'change_quantity_configuration',
	FORCE_SPECIFY_QUANTITY = 'force_specify_quantity',
	QUICK_CAD_UPLOAD = 'quick_cad_upload',
	FREE_PROVIDERS_REGISTRATION = 'free_providers_registration',
	LIGHT_USER_MULTIPLE_UPLOADS = 'light_user_multiple_uploads',
	USER_MENU = 'user_menu',
	USER_NAME = 'user_name',
	ALLOW_SIMPLE_LOGIN = 'allow_simple_login',
	PROJECT_BUNDLE_PAGE = 'project_bundle_page',
	USE_CASTOR_PART = 'use_castor_part',
	SMTP_SETTINGS = 'smtp_settings',
	SINGLETONS_SETTINGS = 'singletons_settings',
	ROLE_EDITING = 'role_editing',
	CALCULATE_SHIPPING_COST = 'calculate_shipping_cost',
	GEOMETRY_ANALYSIS_TAB = 'geometry_analysis_tab',
	STEP_FILE_WINDOW_UPLOAD_PAGE = 'step_files_window_upload_page',
	GRAB_CAD_PRINT = 'grab_cad_print',
	DRAWING_3D = 'drawing_3d',
	UPLOAD_PROJECT_PAGE_REDIRECTION = 'upload_project_page_redirection',
	INCLUDE_MACHINING_POST_PROCESS = 'include_machining_post_process',
	ATTACHMENT_OPTION = 'attachment_option',
	META_DATA_PROJECT = 'meta_data_project',
	PROJECT_PARTS_IN_SIDEBAR = 'project_parts_in_sidebar',
	LEAD_TIME_PARAMETERS = 'lead_time_paramaters',
	CANCEL_PROJECT = 'cancel_project',
	SIGN_OUT = 'sign_out',
	FEA_IN_NEW_TAB = 'FEA_in_new_tab',
	PIE_CHART_3D_MISSING_INFORMATION = 'pie_chart_3d_missing_information',
	EXPORT_PROJECT_TO_PDF = 'export_project_to_pdf',
	SHOW_LEADING_CONFIGURATION = 'show_leading_configuration',
	SINGLE_PART_PROJECT_VIEW = 'single_part_project_view',
	SWITCH_PARTS_VIEW_BUTTON = 'switch_parts_view_button',
	REVIEW_3D_IN_PROJECT_ANALYSIS = 'review_3D_in_project_analysis',
	UPLOAD_USER_COUNTRY_FIELD = 'upload_user_country_field',
	CLIP_PLANE_GEOMETRY_ANALYSIS = 'clip_plane_geometry_analysis',
	COST_FUNCTION_EDITOR = 'cost_function_editor',
	EDIT_MULTIPLE_CONFIGURATION_PART_FIELDS = 'edit_multiple_configuration_part_fields',
	SUSTAINABILITY_LIFE_CYCLE = 'sustainability_life_cycle',
	DEFAULT_MATERIAL_FILTERS = 'default_material_filters',
	LIGHT_USER_LOGOUT = 'light_user_logout',
	THREED_EXPERT_INTEGRATION_BUTTON = '3d_expert_Integration_button',
	COST_ANALYSIS_PARAMETERS = 'cost_analysis_parameters',
	ONBOARDING_WIZARD = 'onboarding_wizard',
	CURRENT_STANDARD_COST = 'current_standard_cost',
	WEIGHT_REDUCTION_IN_GRAMS = 'weight_reduction_in_grams',
	NOT_COST_EFFECTIVE = 'not_cost_effective',
	OPEN_LINKS_IN_NEW_TAB = 'open_links_in_new_tab',
	MULTIPLE_MATERIALS_NAME = 'multiple_materials_name',
	PRINTING_STANDARDS = 'printing_standards',
	LICENSE_MANAGER = 'license_manager',
	GENERATIVE_DESIGN = 'generative_design',
	POLYMER_ALTERNATIVE = 'polymer_alternative',
	ALLOW_NUMBER_EXTENSION = 'allow_number_extension',
	PUSH_NOTIFICATIONS = 'push_notifications',
	USER_FOLDERS_IN_SIDEBAR = 'user_folders_in_sidebar',
	SEARCH_PROJECTS_IN_SIDEBAR = 'search_projects_in_sidebar',
	ALWAYS_SHOW_CO2_DETAILS = 'always_show_CO2_details',
	SILENT_UPLOADING_NOTIFICATION = 'silent_uploading_notification',
	RESET_SERVER = 'reset_server',
	CUSTOMIZE_ORGANIZATIONS = 'customize_organizations',
	GEOMETRY_ANALYSIS_FILTER = 'geometry_analysis_filter',
	OPTIMIZATION_ANALYSIS = 'optimization_analysis',
	COST_SAVING_ANALYSIS = 'cost_saving_analysis',
	PAGINATION_DROPDOWN = 'pagination_dropdown',
	CUSTOMIZE_UNIT_SYSTEM = 'customize_unit_system',
	CUSTOMIZE_LAYER_THICKNESS = 'customize_layer_thickness'
}
