import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { ConfigurationResultTypes } from '../ConfigurationResultTypes'
import { changeOrganizationToConfigure } from '../SolutionAnalysisActions'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { IOrganization } from 'Services/models/IOrganization'
import { getString } from 'Services/Strings/StringService'
import { renderOrganizationValue } from 'Services/Utils/organizationTools'

type IProps = {
	configurationOrganizationId: number
	configurationId: number
	disabled: boolean
	configurationResultType: ConfigurationResultTypes
}

const ConfigurationOrganizationSelector: FC<IProps> = ({
	configurationOrganizationId,
	configurationId,
	disabled,
	configurationResultType
}) => {
	const { availableOrganizations } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const dispatch = useDispatch()

	return (
		<div className="solution-analysis--organization-selector">
			<label htmlFor="upload-with-organization">
				{getString('SOLUTION_CONFIGURATION_CALCULATE_WITH_SITE')}
			</label>
			<Select
				disabled={disabled}
				id="upload-with-organization"
				className="customize-settings--header--organization--select-field"
				value={configurationOrganizationId}
				renderValue={selected =>
					renderOrganizationValue(availableOrganizations, selected as number)
				}
				title={renderOrganizationValue(
					availableOrganizations,
					configurationOrganizationId
				)}
				onChange={event => {
					dispatch(
						changeOrganizationToConfigure(
							configurationId,
							event.target.value as number,
							false,
							configurationResultType
						)
					)
				}}
				classes={{
					select: 'customize-settings--header--organization--select'
				}}
				inputProps={{
					name: 'simpleSelect',
					id: 'simple-select'
				}}
				MenuProps={{
					PaperProps: {
						style: {
							transform: 'translate3d(0, 0, 0)'
						}
					}
				}}
			>
				{availableOrganizations?.map((organization: IOrganization) => {
					return (
						<MenuItem key={organization.id} value={organization.id}>
							{`${organization?.name}${
								organization?.private ? ` (${getString('PRIVATE')})` : ''
							}`}
						</MenuItem>
					)
				})}
			</Select>
		</div>
	)
}

export default WithFeatureToggleHOC(
	ConfigurationOrganizationSelector,
	FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
)
