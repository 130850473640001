import React, { FC, useCallback, useEffect } from 'react'
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { formValueSelector } from 'redux-form'

import { setupPrinterMaterialForm } from '../CustomizeUserMaterialsActions'
import { userPrinterMaterialFormName } from './constants'
import { createNewPrinterMaterial } from 'Scenes/admin/AdminHome/AdminPrinterMaterials/AdminPrinterMaterialsActions'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import PrinterMaterialForm from 'Scenes/Components/PrinterMaterialForm/PrinterMaterialForm'
import { customizeUserMaterial } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	selectedType: string
	selectedPrinterTechnology: string
	selectedPrinterName: string
	selectedPrinterCompany: string
	selectedCategory: string
	selectedSubCategory: string
	selectedId: string
}

const NewAMMaterial: FC<IProps> = ({
	selectedType,
	selectedPrinterTechnology,
	selectedPrinterName,
	selectedPrinterCompany,
	selectedCategory,
	selectedSubCategory,
	selectedId
}) => {
	const {
		PMCategoriesAverageCO2,
		selectedNewPrinterMaterial,
		allPrintersCompanies,
		allPMCategories,
		allPMSubCategories,
		addingNewItem,
		printerMaterialAdded
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizeUserMaterialsReducer
	)
	const { printingTechnologies } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		if (!allPMCategories.length) {
			dispatch(setupPrinterMaterialForm(adminSelectedOrganizationId))
		}
	}, [allPMCategories.length, dispatch, adminSelectedOrganizationId])

	useEffect(() => {
		if (printerMaterialAdded) {
			history.push(customizeUserMaterial())
		}
	}, [history, printerMaterialAdded])

	const onSubmit = useCallback(
		(data: Record<string, unknown>) => {
			dispatch(
				createNewPrinterMaterial(data, true, adminSelectedOrganizationId)
			)
		},
		[adminSelectedOrganizationId, dispatch]
	)

	return (
		<NavBarAndMaterial
			title={getString('CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_TITLE')}
		>
			<CastorForm
				formTitle={getString(
					'CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_TITLE'
				)}
				content={
					<PrinterMaterialForm
						formName={userPrinterMaterialFormName}
						selectedId={selectedId}
						initialValues={selectedNewPrinterMaterial}
						allPrintersCompanies={allPrintersCompanies}
						allCategories={allPMCategories}
						allSubCategories={allPMSubCategories}
						printingTechnologies={printingTechnologies}
						selectedPrinterName={selectedPrinterName}
						selectedCategory={selectedCategory}
						selectedPrinterCompany={selectedPrinterCompany}
						selectedSubCategory={selectedSubCategory}
						onSubmit={onSubmit}
						addingNewItem={addingNewItem}
						selectedType={selectedType}
						selectedPrinterTechnology={selectedPrinterTechnology}
						userCurrencySign={'$'}
						categoriesAverageCO2={PMCategoriesAverageCO2}
						isAdminForm={false}
					/>
				}
			/>
		</NavBarAndMaterial>
	)
}

const selector = formValueSelector(userPrinterMaterialFormName)

const mapStateToProps = (state: Record<string, unknown>) => {
	return {
		selectedType: selector(state, 'type'),
		selectedPrinterTechnology: selector(state, 'printerTechnologyName'),
		selectedPrinterName: selector(state, 'printers'),
		selectedPrinterCompany: selector(state, 'printerCompany'),
		selectedCategory: selector(state, 'category'),
		selectedId: selector(state, 'id'),
		selectedSubCategory: selector(state, 'subCategory')
	}
}

export default connect(mapStateToProps)(NewAMMaterial)
