import {
	findEffectivePoints,
	getPostProcessIdsWithAbnormalValues,
	SolutionAnalysisService
} from '../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisService'
import {
	CONFIGURATION_CALCULATED,
	HANDLE_NOTIFICATION
} from 'global actions/types'
import { ALERT_CALCULATION_FINISHED } from 'global actions/types/CastorAlertTypes'
import {
	ALTERNATIVE_SOLUTION_HOVER_ELEMENT,
	GET_ALTERNATIVE_SOLUTION_CALCULATED,
	GET_ALTERNATIVE_SOLUTION_CALCULATION,
	MAIN_PART_PART_PRINT_ISSUES_UPDATED,
	NEW_ALTERNATIVE_SOLUTION_CALCULATED,
	NEW_ALTERNATIVE_SOLUTION_CALCULATION,
	SOLUTION_CONFIGURATION_SOLUTION_UPDATED
} from 'global actions/types/partAnalysisTypes'
import { store } from 'index'
import { showCustomFunctionWarningPopup } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import { CostComparisonService } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostComparisonService'
import { LeadTimeService } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LeadTimeService'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { manufacturingMethodTypes } from 'Services/Constants'
import { CostData, LeadData } from 'Services/models/CostComparisonModels'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import {
	acceptPrintingStandards,
	createOrUpdateSolution,
	getConfigurationSolutions
} from 'Services/Network/PartAnalysisNetwork'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'
import { getTheme } from 'themes/getTheme'

const { tabOrder } = getTheme()
let solutionAnalysisService: SolutionAnalysisService
const costComparisonService = new CostComparisonService()
const leadTimeService = new LeadTimeService()

export const getAllAlternativeSolutions = (
	configurationId: number,
	organizationId: number
) => {
	return async (dispatch: any) => {
		const { allConfigurationsOrganizationSettings } =
			store.getState().MainPartAnalysisReducer
		const { defaultSettings } = store.getState().user

		dispatch({
			type: GET_ALTERNATIVE_SOLUTION_CALCULATION,
			payload: true
		})

		try {
			const response: any = await getConfigurationSolutions(configurationId)
			const customizeUnitSystem = Feature.isFeatureOn(
				FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
			)
			const { userUnitSystem } = store.getState().user
			const unitSystem = customizeUnitSystem
				? userUnitSystem
				: UnitSystem.metric

			if (response?.data) {
				dispatch({
					type: GET_ALTERNATIVE_SOLUTION_CALCULATED,
					payload: {
						solutions: response.data.solutions || [],
						drawingCostPercentage:
							allConfigurationsOrganizationSettings[organizationId]
								.customizationSettings.drawingCostPercentage ||
							defaultSettings.drawingCostPercentage,
						unitSystem
					}
				})
				dispatch({
					type: GET_ALTERNATIVE_SOLUTION_CALCULATION,
					payload: false
				})
			}
		} catch (e) {
			console.error(e)

			dispatch({
				type: GET_ALTERNATIVE_SOLUTION_CALCULATION,
				payload: false
			})
		}
	}
}

export const onChangeHoverAlternativeSolution = (
	hoverElement: number | null
) => {
	return (dispatch: any) => {
		dispatch({
			type: ALTERNATIVE_SOLUTION_HOVER_ELEMENT,
			payload: {
				hoverElement
			}
		})
	}
}

export const onAcceptPrintingStandards = (
	partId: number,
	printingStandardsAlertAccepted: boolean
) => {
	return async (dispatch: any) => {
		try {
			const response = await acceptPrintingStandards(
				partId,
				printingStandardsAlertAccepted
			)
			if (response) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: getString('DATA_WAS_UPDATED')
					}
				})
			}
		} catch (err: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message || getString('SOMETHING_WENT_WRONG')
				}
			})
		}
	}
}

export const calculateAlternativeSolutions = (
	solution: any,
	partReducer: any,
	configuration: any
) => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: NEW_ALTERNATIVE_SOLUTION_CALCULATION,
				payload: {
					solutionId: solution.id,
					isLoading: true
				}
			})

			solutionAnalysisService = new SolutionAnalysisService(
				configuration,
				undefined,
				[],
				undefined
			)

			const user = store.getState().user
			const {
				MainPartAnalysisReducer: {
					allConfigurationsOrganizationSettings,
					drawingCostPercentage: drawingPartPercentage
				}
			} = store.getState()
			const customizationSettings =
				allConfigurationsOrganizationSettings?.[configuration.organizationId]
					?.customizationSettings || user.defaultSettings
			const drawingCostPercentage =
				customizationSettings.drawingCostPercentage || drawingPartPercentage
			const customizeUnitSystem = Feature.isFeatureOn(
				FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
			)
			const unitSystem = customizeUnitSystem
				? user.userUnitSystem
				: UnitSystem.metric

			const { isAmOriginalMaterial } =
				store.getState().SolutionAnalysisReducer.states[configuration.id] || {}

			const response: any = await createOrUpdateSolution(
				solution.id,
				solution.partId,
				partReducer.projectId,
				configuration.priorities || {},
				{
					printerMaterialID: solution.printerMaterial.id
				},
				-1,
				!!partReducer?.part?.isSpecifiedQuantity,
				solution.printer.name,
				configuration.material?.id || configuration.materialId,
				null,
				partReducer?.initialBatchSize,
				{},
				solution.partCluster,
				undefined,
				undefined,
				true,
				solution.printerId,
				solution.printerMaterialId,
				false,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				configuration.organizationId
			)

			let partPrintIssues = []

			if (response.data) {
				configuration = response.data.configuration
			}

			let configurations =
				store.getState().MainPartAnalysisReducer.configurations

			const resPoller = await solutionAnalysisService.startConditionPoller(
				configuration,
				configuration.id,
				configurations
			)

			if (resPoller) {
				dispatch({ type: ALERT_CALCULATION_FINISHED })

				if (resPoller.data) {
					configuration = resPoller.data.configuration
					solution = resPoller.data.solution
					partPrintIssues = resPoller.data.partPrintIssues
				}
			} else {
				configuration = response.data.configuration
				solution = response.data.solution
				partPrintIssues = response.data.partPrintIssues
			}

			const postProcessesIdsWithAbnormalValues =
				getPostProcessIdsWithAbnormalValues(solution)

			if (
				solution?.isAbnormalPrice ||
				configuration?.traditionalCostIsAbnormal ||
				postProcessesIdsWithAbnormalValues?.length
			) {
				dispatch(
					showCustomFunctionWarningPopup(postProcessesIdsWithAbnormalValues)
				)
			}

			let costData: CostData = new CostData()
			let leadTimeData: LeadData = new LeadData()
			let costBenefit: any = null
			let timeBenefit: any = null
			let costDetails: any = null
			let PostProcessOnCounter: number = 0

			const manufacturingMethod: string =
				manufacturingMethodTypes[
					solutionAnalysisService.getManufacturingMethod(configuration)
				]
			if (configuration.costResults) {
				costData = costComparisonService.getCostData(
					configuration.costResults,
					manufacturingMethod,
					user.userCurrencySign,
					solution?.costDetails?.totalCost || 0,
					isAmOriginalMaterial,
					drawingCostPercentage,
					configuration.part?.isDrawing ||
						configuration.part?.formatType === METADATA,
					configuration.part?.blockManufacturingMethodOperation,
					configuration?.isSpecifiedQuantity || false
				)
			}

			if (configuration.leadTimeResults) {
				leadTimeData = leadTimeService.getLeadData(
					configuration.leadTimeResults,
					manufacturingMethod,
					configuration.unlockLeadTimeGraph,
					configuration.quantity,
					!!partReducer?.standardCost && !configuration.standardCost,
					configuration,
					isAmOriginalMaterial
				)
			}

			const postProcessToggles: any =
				solutionAnalysisService.setupSolutionPostPostProcessToggles(
					configuration.id,
					false,
					configuration?.postProcessesOptional,
					solution
				)

			const showWeightReductionButton =
				solutionAnalysisService.toShowWeightReductionButton(
					configuration?.type,
					configuration?.result,
					partReducer
				)

			if (solution) {
				costBenefit = solutionAnalysisService.getCostBenefit(solution)
				timeBenefit = solutionAnalysisService.getTimeBenefit(solution)
				costDetails =
					solutionAnalysisService.createCostDetailsPostProcessesLabels(
						configuration?.postProcessesOptional,
						solution?.costDetails
					)

				PostProcessOnCounter =
					solutionAnalysisService.howManyPostProcessesOn(postProcessToggles)
			}

			const configurationPrintIssues: PartPrintIssue[] = partPrintIssues.filter(
				(partPrintIssue: PartPrintIssue) =>
					partPrintIssue.configurationId === configuration.id ||
					!partPrintIssue.configurationId
			)

			const trayOrientationVector: number[] =
				configuration?.trayOrientation?.trayNormalVector ||
				solution?.trayOrientationVector ||
				[]

			const analysisResultsRows =
				solutionAnalysisService.getAnalysisResultsRows(
					solution,
					configuration,
					configurationPrintIssues,
					trayOrientationVector,
					partReducer?.isDrawing,
					partReducer?.formatType === METADATA
				)
			const failedPrintIssuesIds =
				solutionAnalysisService.getFailedPrintIssues(analysisResultsRows)
			const { effectiveQuantity } = findEffectivePoints(
				configuration,
				undefined,
				drawingCostPercentage
			)

			let trayOrientationCustom = solutionAnalysisService.isOrientationCustom(
				configuration,
				solution
			)
			const alternativeSolutions = configuration?.alternativeSolutions
			let chosenOrientation = solutionAnalysisService.getChosenOrientation(
				partReducer?.trayOrientations?.data ||
					partReducer?.trayOrientations ||
					[],
				trayOrientationVector
			)
			dispatch({
				type: SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
				payload: {
					id: solution.id,
					configuration,
					costBenefit,
					costDetails,
					timeBenefit,
					solution,
					optionalPostProcessAvailability: {},
					allOptionalPostProcessData: {},
					optionalPostProcessesBreakDown: {},
					postProcessToggles,
					PostProcessOnCounter,
					chosenMaterial: solution.material,
					trayOrientationCustom,
					initialSelectedTab:
						solutionAnalysisService.getInitialSelectedTab(solution),
					...costData,
					analysisResultsRows,
					failedPrintIssuesIds,
					manufacturingMethod,
					printingOrientationCalc: false,
					alternativeSolutions,
					chosenOrientation,
					chosenOrientationVector: trayOrientationVector,
					showWeightReductionButton,
					effectiveQuantity,
					disableTabUpdate: true,
					filterInitialValues: configuration.filters,
					chartLeadData: leadTimeData.chartData,
					tabIndex: tabOrder.costAnalysis,
					filterToggles: {
						printerMaterialID: solution.printerMaterial.id
					},
					unitSystem
				}
			})

			dispatch({
				type: MAIN_PART_PART_PRINT_ISSUES_UPDATED,
				payload: {
					partPrintIssues
				}
			})

			dispatch({
				type: CONFIGURATION_CALCULATED
			})

			dispatch({
				type: NEW_ALTERNATIVE_SOLUTION_CALCULATION,
				payload: {
					solutionId: solution.id,
					isLoading: false
				}
			})

			dispatch({
				type: NEW_ALTERNATIVE_SOLUTION_CALCULATED,
				payload: {
					newConfiguration: configuration,
					solutionId: solution.id
				}
			})
		} catch (e: any) {
			console.trace(e)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: e.message || getString('SOMETHING_WENT_WRONG')
				}
			})
			dispatch({
				type: NEW_ALTERNATIVE_SOLUTION_CALCULATION,
				payload: {
					solutionId: solution.id,
					isLoading: false
				}
			})
		}
	}
}
