import { partResults } from 'Services/Constants'
import {
	ALL,
	BUY_TO_FLY,
	CASTING_CNC,
	CO2_SAVING,
	COMPLEX_GEOMETRY,
	CONFIGURATION_RESULT_NOT_PRINTABLE,
	CONFIGURATION_RESULT_PRINTABLE,
	COST_SAVING,
	LATHE_CNC,
	OPTIMIZED,
	SAVED,
	SHEET_METAL,
	STANDALONE_PARTS,
	TIME_SAVING
} from '../../../Services/Strings'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { benefitType } from 'Services/models/ISolutionBenefit'

export const filters = {
	SAVED,
	PRINTABLE: CONFIGURATION_RESULT_PRINTABLE,
	ALL,
	COST_SAVING,
	TIME_SAVING,
	BUY_TO_FLY,
	COMPLEX_GEOMETRY,
	NOT_PRINTABLE: CONFIGURATION_RESULT_NOT_PRINTABLE,
	STANDALONE_PARTS,
	CO2_SAVING,
	LATHE_CNC,
	CASTING_CNC,
	SHEET_METAL,
	OPTIMIZED
}

export const benefitsFiltersValues = {
	[COST_SAVING]: benefitType.costSaving,
	[TIME_SAVING]: benefitType.timeSaving,
	[BUY_TO_FLY]: benefitType.buyToFly,
	[COMPLEX_GEOMETRY]: benefitType.complexityScore,
	[CO2_SAVING]: benefitType.co2Saving
}

export const statusFiltersValues = {
	[CONFIGURATION_RESULT_PRINTABLE]: CADAnalysisResult.printable,
	[CONFIGURATION_RESULT_NOT_PRINTABLE]: CADAnalysisResult.notPrintable,
	[LATHE_CNC]: CADAnalysisResult.printable,
	[CASTING_CNC]: CADAnalysisResult.notPrintable,
	[SHEET_METAL]: partResults.sheetMetal,
	[OPTIMIZED]: CADAnalysisResult.borderline
}
