import { ClearCacheProvider } from 'react-clear-cache'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'
import { composeEnhancers } from 'Services/Utils/reduxDevTools'

import {
	createGenerateClassName,
	StylesProvider
} from '@material-ui/core/styles'

import App from './App'
import reducers from './global reducers'
import {throttledMiddleware} from './middleware/throttled'
import registerServiceWorker from './registerServiceWorker'
import ErrorInterceptor from 'Scenes/Components/ErrorInterseptor/ErrorInterceptor'
import { ErrorProvider } from 'Scenes/Components/ErrorInterseptor/ErrorInterceptorProvider'
import 'symbol-observable'

import './index.css'

const generateClassName = createGenerateClassName({
	seed: 'castor'
})

export const store = createStore(
	reducers,
	{},
	composeEnhancers(applyMiddleware(throttledMiddleware, ReduxThunk))
)

ReactDOM.render(
	<StylesProvider generateClassName={generateClassName}>
		<Provider store={store}>
			<ClearCacheProvider>
				<ErrorProvider>
					<ErrorInterceptor />
					<App />
				</ErrorProvider>
			</ClearCacheProvider>
		</Provider>
	</StylesProvider>,
	document.getElementById('root')
)

registerServiceWorker()
