import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	HANDLE_NOTIFICATION,
	METADATA_COST_APPLY_TOGGLE,
	METADATA_COST_EDIT_ALL_TOGGLE,
	METADATA_COST_EDIT_DATA,
	METADATA_COST_EDIT_TOGGLE,
	METADATA_COST_UPDATE_ALL_DATA,
	METADATA_COST_UPDATE_DATA
} from 'global actions/types'
import { updateMetadataCosts } from 'Services/Network'
import {
	CONFIGURATION_CALCULATION_ERROR,
	SHOW_NOTIFICATION
} from 'Services/Strings'

export const metadataEditToggle = (
	isEditButton: boolean,
	parameterKey: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: METADATA_COST_EDIT_TOGGLE,
				payload: {
					isEditButton,
					parameterKey
				}
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const metadataEditAllToggle = (isEditButton: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: METADATA_COST_EDIT_ALL_TOGGLE,
				payload: {
					isEditButton
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const metadataApplyToggle = (
	isApplyButton: boolean,
	parameterKey: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: METADATA_COST_APPLY_TOGGLE,
				payload: {
					isApplyButton,
					parameterKey
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const updateMetadataData = (
	updateValue: string | number,
	parameterKey: string,
	organizationId?: number
) => {
	let metadataData: Record<string, any> = {}

	metadataData[parameterKey] = updateValue

	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await updateMetadataCosts(metadataData, organizationId)
			dispatch({
				type: METADATA_COST_UPDATE_DATA,
				payload: { parameterKey, updateValue }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const updateAllMetadataData = (metadataCostNames: any, organizationId?: number) => {
	let metadataData: Record<string, any> = {}

	for (const parameterKey in metadataCostNames) {
		metadataData[parameterKey] = metadataCostNames[parameterKey].editInput
	}

	for (const parameterKey in metadataData) {
		if (metadataData[parameterKey] === '') {
			delete metadataData[parameterKey]
		}
	}

	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await updateMetadataCosts(metadataData, organizationId)
			dispatch({
				type: METADATA_COST_UPDATE_ALL_DATA,
				payload: { metadataData }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const editMetadataData = (
	updateValue: string | number,
	parameterKey: string
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: METADATA_COST_EDIT_DATA,
				payload: { parameterKey, updateValue }
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}
