import { FC, memo } from 'react'

import AdvancedSettings from 'Scenes/Components/AdvancedSettings'

import './SolutionConfigureFeatures.scss'

interface Props {
	id: string | number
	showTitle?: boolean
	title?: string
	extraExplanation?: string
	explanationArray?: Array<any>
	hideInHouseFilter?: boolean
}

let SolutionConfigureFeaturesFilters: FC<Props> = ({
	id,
	title,
	extraExplanation,
	explanationArray,
	showTitle,
	hideInHouseFilter
}) => {
	return (
		<AdvancedSettings
			id={id}
			withFilterTitle={false}
			allowCustomize={false}
			showTitle={showTitle}
			title={title}
			extraExplanation={extraExplanation}
			explanationArray={explanationArray}
			hideInHouseFilter={hideInHouseFilter}
		/>
	)
}

export default memo(SolutionConfigureFeaturesFilters)
