import React, { FC, useCallback, useEffect } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import * as CustomizeUserMaterialsActions from 'Scenes/Home/Customize/CustomizeUserMaterials/CustomizeUserMaterialsActions'
import { STEP_ID } from '../../constants'
import { goToNextStep } from '../../OnboardingWizardActions'
import { materialFormName } from 'Scenes/admin/AdminHome/AdminMaterials/constants'
import MaterialForm from 'Scenes/Components/MaterialForm/MaterialForm'
import {
	IMaterialCategory,
	MaterialType,
	SurfaceFinishMethod
} from 'Services/models/IMaterial'
import { IOnboardingWizardStep } from 'Services/models/IOnboardingWizardStep'

type IProps = {
	selectedType: MaterialType
	selectedCategory: string
	selectedSurfaceFinishMethod: SurfaceFinishMethod
	defaultFilters: Record<string, boolean>
	onUserMaterialAdd: (
		data: Record<string, any>,
		userId: string,
		materialCategories: IMaterialCategory[],
		organizationId?: number
	) => void
	onMaterialTypeChange: () => void
	goToNextStep: (
		parentSteps: IOnboardingWizardStep[],
		currentStep: IOnboardingWizardStep,
		nextStepID?: number
	) => void
}

const TMMaterialForm: FC<IProps> = ({
	selectedType,
	selectedCategory,
	selectedSurfaceFinishMethod,
	defaultFilters,
	onUserMaterialAdd,
	onMaterialTypeChange,
	goToNextStep
}) => {
	const { parentSteps, currentStep } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { materialAdded, userId, materialCategoriesToShow, addingNewItem } =
		useSelector((state: RootStateOrAny) => state.CustomizeUserMaterialsReducer)
	const { materialCategories, userCurrencySign, materials } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	useEffect(() => {
		if (materialAdded) {
			goToNextStep(parentSteps, currentStep, STEP_ID.TM_MATERIAL)
		}
	}, [currentStep, goToNextStep, materialAdded, parentSteps])

	const onSubmit = useCallback(
		(data: Record<string, any>) => {
			onUserMaterialAdd(
				data,
				userId,
				materialCategories,
				adminSelectedOrganizationId
			)
		},
		[adminSelectedOrganizationId, materialCategories, onUserMaterialAdd, userId]
	)

	return (
		<div className="tm-material--form-wrapper">
			<MaterialForm
				onSubmit={onSubmit}
				materialCategories={materialCategoriesToShow}
				allMaterialCategories={materialCategories}
				materials={materials}
				onMaterialTypeChange={onMaterialTypeChange}
				addingNewItem={addingNewItem}
				userCurrencySign={userCurrencySign}
				selectedMaterialType={selectedType}
				selectedMaterialCategory={selectedCategory}
				selectedSurfaceFinishMethod={selectedSurfaceFinishMethod}
				defaultFilters={defaultFilters}
				formName={materialFormName}
			/>
		</div>
	)
}

const selector = formValueSelector(materialFormName)

const mapStateToProps = (state: Record<string, unknown>) => {
	return {
		selectedType: selector(state, 'type'),
		selectedCategory: selector(state, 'category'),
		selectedSurfaceFinishMethod: selector(state, 'surfaceFinishMethod'),
		defaultFilters: selector(state, 'defaultFilters')
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) => {
	return bindActionCreators(
		{ ...CustomizeUserMaterialsActions, goToNextStep },
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(TMMaterialForm)
