import { filterStepsAndContentByFeatures } from './OnboardingWizardService'
import {
	GOT_LOGIN_DATA,
	NEW_ORGANIZATION_ADDED,
	NEXT_STEP_LOADER,
	TOGGLE_INCLUDE_ALL_COSTS_CHECKBOX,
	UPDATE_USER_ONBOARDING_STATE
} from 'global actions/types'
import { IOnboardingWizardStep } from 'Services/models/IOnboardingWizardStep'

const INITIAL_STATE = {
	steps: [] as IOnboardingWizardStep[],
	progressBarSteps: [] as IOnboardingWizardStep[],
	parentSteps: [] as IOnboardingWizardStep[],
	currentStepId: 1,
	currentStep: {},
	nextStepLoading: false,
	includeAllCostsCheckbox: false,
	wizardOrganizationId: null
}

const OnboardingWizardReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case GOT_LOGIN_DATA: {
			const {
				generalData: {
					wizardSteps,
					userDetails: { wizardStep: wizardStepId, wizardOrganizationId },
					features
				},
				disableStepChange
			} = action.payload

			const wizardStep = disableStepChange ? state.currentStepId : wizardStepId
			const wizardOrganization = disableStepChange
				? state.wizardOrganizationId
				: wizardOrganizationId

			const filteredByFeaturesSteps = filterStepsAndContentByFeatures(
				wizardSteps,
				features
			)
			const sortedStepsToShow = filteredByFeaturesSteps
				.filter((step: IOnboardingWizardStep) => step.show)
				.sort(
					(a: IOnboardingWizardStep, b: IOnboardingWizardStep) =>
						a.order - b.order
				)
			const parentSteps = sortedStepsToShow.filter(
				(step: IOnboardingWizardStep) => !step.parentStepId
			)
			const progressBarSteps = sortedStepsToShow.filter(
				(step: IOnboardingWizardStep) => step.partOfProgressBar
			)
			const currentStep = sortedStepsToShow.find(
				(step: IOnboardingWizardStep) => step.id === wizardStep
			)
			return {
				...state,
				steps: sortedStepsToShow,
				currentStepId: wizardStep,
				currentStep,
				progressBarSteps,
				parentSteps,
				wizardOrganizationId: wizardOrganization
			}
		}
		case UPDATE_USER_ONBOARDING_STATE: {
			const { wizardStep } = action.payload
			const currentStep = state.steps.find(
				(step: IOnboardingWizardStep) => step.id === wizardStep
			)
			return {
				...state,
				currentStepId: wizardStep,
				currentStep,
				nextStepLoading: false
			}
		}
		case NEXT_STEP_LOADER: {
			const nextStepLoading = action.payload
			return {
				...state,
				nextStepLoading
			}
		}
		case TOGGLE_INCLUDE_ALL_COSTS_CHECKBOX: {
			const { includeAllCostsCheckbox } = action.payload
			return {
				...state,
				includeAllCostsCheckbox
			}
		}
		case NEW_ORGANIZATION_ADDED: {
			const { organization } = action.payload
			return {
				...state,
				wizardOrganizationId: organization.id
			}
		}
		default:
			return state
	}
}

export default OnboardingWizardReducer
