import React, { FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { match } from 'react-router'
import { useLocation } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import * as ProjectAnalysisActions from '../ProjectAnalysis/ProjectAnalysisActions'
import CastorForm from '../../Components/CastorForm/CastorForm'
import DataTable from '../../Components/DataTable'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import PdfViewer from '../NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionPartDetails/PdfViewer/PdfViewer'
import { getProjectInformation } from '../ProjectPage/ProjectPageAction'
import {
	buildGridTemplateColumns,
	buildPartPropertiesHeaders,
	buildPartPropertyRows,
	getGridColumnsGapInVW,
	onRecalculateClick
} from './PartPropertiesService'
import PartPropertyCalculateAlert from './PartPropertyCalculateAlert'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorPagination from 'Scenes/Components/CastorPagination/CastorPagination'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import Flexbox from 'Scenes/Components/FlexBox'
import TransparentButton from 'Scenes/Components/TransparentButton'
import GeometryAnalysisReviewAndFixes from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/GeometryAnalysisReviewAndFixes'
import Loader from 'Scenes/Loader/Loader'
import { PROJECT_REDUCER } from 'Services/Constants'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { PartProperty } from 'Services/models/IPartPropertiest'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

import './PartProperties.scss'

interface IProps {
	match: match<any>
	projectId: string
	onGetPartsProperties: Function
	onPartsPropertiesChange: Function
	onPartsPropertiesReset: Function
	onRestAllPartProperties: Function
	updatePartProperty: Function
	onPartsPropertiesGoBack: Function
	changePartsPropertiesPage: Function
	getProjectInformation: Function
}

const PartProperties: FC<IProps> = ({
	match: {
		params: { projectId }
	},
	onGetPartsProperties,
	onPartsPropertiesChange,
	onPartsPropertiesReset,
	onRestAllPartProperties,
	updatePartProperty,
	onPartsPropertiesGoBack,
	changePartsPropertiesPage,
	getProjectInformation
}) => {
	const { state } = useLocation() as { state?: Record<string, boolean> }
	const [showModal, setShowModal] = useState(false)
	const [selectedPart, setSelectedPart] = useState<any>({})
	const [show, setShow] = useState(false)
	const [showAlert, setPartPropertyCalculateAlert] = useState(false)
	const [partIndex, setPartIndex] = useState(-1)
	const [partProperties, setPartProperties] = useState([])
	const projectBundle = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_BUNDLE_PAGE
	)
	const customizeUnitSystem = Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
	)

	const { isBundle } = useSelector(
		(state: RootStateOrAny) => state.ProjectPageReducer
	)
	const { userUnitSystem } = useSelector((state: RootStateOrAny) => state.user)
	const unitSystem = customizeUnitSystem ? userUnitSystem : UnitSystem.metric
	const reducerName =
		projectBundle && isBundle
			? PROJECT_REDUCER.BUNDLE
			: PROJECT_REDUCER.ANALYSIS

	const {
		partId,
		partsProperties,
		partsPropertiesCalculating,
		partsPropertiesReset,
		inapplicablePartsProperties,
		partsPropertiesAll,
		partPropertiesPaginationData: paginationData,
		partsPropertiesLoading
	} = useSelector((state: RootStateOrAny) => state[reducerName])

	const propertiesList = inapplicablePartsProperties?.length
		? [...partsProperties, ...inapplicablePartsProperties]
		: partsProperties

	const setPartToViewer = (part: Part) => {
		setSelectedPart(part)
		setShow(true)
	}

	const setPartToPDFViewer = (part: Part) => {
		setSelectedPart(part)
		setShowModal(true)
	}

	const onConfirmClick = (index: number, partPropertiesPerClick: any) => {
		const partPropertiesApplicable = partPropertiesPerClick.filter(
			(part: PartProperty) => !part.isInapplicable
		)
		setPartIndex(index)
		setPartProperties(partPropertiesApplicable)
		setPartPropertyCalculateAlert(true)
	}

	useEffect(() => {
		if (projectId && isBundle !== null) {
			onGetPartsProperties(projectId, paginationData.page, paginationData.limit)
		}
	}, [
		projectId,
		onGetPartsProperties,
		paginationData.page,
		paginationData.limit,
		isBundle
	])

	useEffect(() => {
		if (isBundle == null) {
			getProjectInformation(projectId)
		}
	}, [getProjectInformation, isBundle, projectId])

	let partPropertyRows: any[][]

	// need for catching errors in
	// service and pass to parent boundary
	try {
		partPropertyRows = buildPartPropertyRows(
			propertiesList,
			partId,
			projectId,
			partsPropertiesCalculating,
			partsPropertiesReset,
			updatePartProperty,
			onConfirmClick,
			onPartsPropertiesReset,
			partsPropertiesAll,
			setPartToViewer,
			setPartToPDFViewer,
			unitSystem
		)
	} catch (e) {
		console.error(e)
	}

	const renderButtons = () => {
		const allowResetAllPartsProperties = Feature.isFeatureOn(
			FeatureComponentId.ALLOW_RESET_ALL_PARTS_PROPERTIES
		)
		const recalculateAllPartsProperties = Feature.isFeatureOn(
			FeatureComponentId.RECALCULATE_ALL_PARTS_PROPERTIES
		)
		return (
			<Flexbox className={'part-properties-buttons'}>
				{allowResetAllPartsProperties && (
					<TransparentButton
						color="secondary"
						disabled={
							partsPropertiesCalculating.calculating ||
							partsPropertiesReset.reset
						}
						loading={partsPropertiesReset.rowIndex === -1}
						onClick={() => onRestAllPartProperties(projectId, propertiesList)}
					>
						{getString('RESET_ALL')}
					</TransparentButton>
				)}
				{recalculateAllPartsProperties && (
					<ButtonWithLoader
						className="part-properties-buttons-cal-button"
						color="primary"
						disabled={
							partsPropertiesCalculating.calculating ||
							partsPropertiesReset.reset
						}
						loading={partsPropertiesCalculating.rowIndex === -1}
						onClick={() => onConfirmClick(-1, propertiesList)}
					>
						{getString('RECALCULATE_ALL')}
					</ButtonWithLoader>
				)}
			</Flexbox>
		)
	}

	const renderFormContent = () => {
		const gridColumnGap = getGridColumnsGapInVW(propertiesList[0]?.data.length)
		const gridTemplateColumns = buildGridTemplateColumns(
			propertiesList[0]?.data || []
		)
		return (
			<Flexbox className={'part-properties'} flexDirection="column">
				<Loader
					load={partsPropertiesLoading}
					wrapperClassName="loading-wrapper"
				/>
				<DataTable
					tableClassName="part-properties-table"
					tableHead={buildPartPropertiesHeaders(
						propertiesList[0]?.data
							.filter((property: any) => property.showInUI)
							.sort((a: any, b: any) => {
								return a.index > b.index ? 1 : -1
							})
							.map((partPropertiesHeader: any) => partPropertiesHeader),
						unitSystem
					)}
					tableDataRows={partPropertyRows}
					tableStyle={{
						gridTemplateColumns,
						gridTemplateRows: `60px repeat(${
							partId ? 1 : propertiesList.length
						}, 80px 10px)`,
						columnGap: `${gridColumnGap}vw`
					}}
					tableHeadStyle={{
						gridTemplateColumns,
						columnGap: `${gridColumnGap}vw`,
						left: '20px',
						width: '100%',
						maxWidth: 'calc(100% - 40px)'
					}}
					showBreakLines
					showBreakLineAtEndOfTable
					fixedHeader
				/>
				{!partId && partsProperties?.length > 1 && renderButtons()}
				{!partId && (
					<div className="pagination-wrapper">
						<CastorPagination
							showPagination={paginationData.totalPagesCount > 1}
							pageNumber={paginationData.page}
							isLastPage={!paginationData.enableNext}
							total={paginationData.totalPartsCount}
							limit={paginationData.limit}
							className={{ pagination: 'parts-pagination' }}
							showingFrom={paginationData.showingFrom}
							showingTo={paginationData.showingTo}
							onNextClick={() =>
								changePartsPropertiesPage(paginationData.page + 1)
							}
							onPrevClick={() =>
								changePartsPropertiesPage(paginationData.page - 1)
							}
							onPageClick={(page: number) => changePartsPropertiesPage(page)}
						/>
					</div>
				)}
			</Flexbox>
		)
	}

	return (
		<ErrorBoundary extra="PartProperties">
			<NavBarAndMaterial title={getString('PARTS_PROPERTIES')}>
				<CastorForm
					formTitle={getString('PARTS_PROPERTIES')}
					content={renderFormContent()}
					style={{ maxWidth: 'unset' }}
					headerCardClasses={{ content: 'part-properties--card-content' }}
				/>
				<ButtonWithLoader
					className="part-properties-back-button"
					color="primary"
					disabled={
						partsPropertiesCalculating.calculating || partsPropertiesReset.reset
					}
					onClick={() => onPartsPropertiesGoBack(projectId, state?.prevUrl)}
				>
					{getString('PARTS_PROPERTIES_BACK_BUTTON')}
				</ButtonWithLoader>

				<PartPropertyCalculateAlert
					setPartPropertyCalculateAlert={setPartPropertyCalculateAlert}
					removeConfigurationLoading={false}
					projectId={projectId}
					partProperties={partProperties}
					partIndex={partIndex}
					onPartsPropertiesChange={onPartsPropertiesChange}
					onRecalculateClick={onRecalculateClick}
					showAlert={showAlert}
					page={paginationData.page}
					limit={paginationData.limit}
				/>
				<GeometryAnalysisReviewAndFixes
					show={show}
					partImageUrl={selectedPart?.meshUploadURL}
					configurationPrintIssues={[]}
					orientationVector={[]}
					configuration={{}}
					solution={undefined}
					printIssues={[]}
					partHealedStlURLExist={undefined}
					printIssueLoaders={{}}
					onWallThicknessClick={() => {}}
					onTolerancesClick={() => {}}
					part={selectedPart}
					onPartPrintIssueChanged={() => {}}
					onMeshHealingClick={() => {}}
					onViewerModelError={() => {}}
					onCancel={() => setShow(false)}
				/>
				<PdfViewer
					onCloseModal={() => setShowModal(false)}
					showModal={showModal}
					partImageSrc={selectedPart.imageURL}
					fileUrl={selectedPart.fileURL}
				/>
			</NavBarAndMaterial>
		</ErrorBoundary>
	)
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{ ...ProjectAnalysisActions, getProjectInformation },
		dispatch
	)

export default WithFeatureToggleHOC(
	memo(connect(null, mapDispatchToProps)(PartProperties)),
	FeatureComponentId.DRAWING_ANALYSIS
)
