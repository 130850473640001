import React, { FC, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import ClearIcon from '@material-ui/icons/Clear'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import cx from 'classnames'

import { FUNCTION_NUMBERS, FUNCTION_OPERATORS } from './constants'
import { useDragAndDrop } from './CostFunctionEditorHooks'
import {
	clearCustomFunction,
	deleteCustomVariable,
	deleteVariableFromFunction,
	openParameterModal,
	saveCustomFunction
} from './CostingFunctionEditorActions'
import { getUserReadableFunctionCategoryName } from './CostingFunctionEditorService'
import {
	IFunctionVariableExtended,
	VariableType
} from './CustomizeCostingFunctionTypes'
import SetNewParameterAlert from './SetNewParameterAlert'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import { IUser } from 'Services/models/IUser'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	reducerStateId: string
	saveButton?: boolean
}

const CreateFunctionContent: FC<IProps> = ({
	reducerStateId,
	saveButton = true
}) => {
	const dispatch = useDispatch()
	const {
		isLoadingStep2,
		variablesList,
		customFunction,
		selectedFunctionCategory,
		selectedPrintingTechnologies,
		allCustomFunctionsList
	} = useSelector(
		(state: RootStateOrAny) =>
			state.CostingFunctionEditorReducer?.functionEditorStates[reducerStateId]
	)
	const { printingTechnologies }: IUser = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	const {
		handleDragStart,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleClick
	} = useDragAndDrop(reducerStateId)

	const functionCategoryReadableName: string = useMemo(
		() => getUserReadableFunctionCategoryName(selectedFunctionCategory),
		[selectedFunctionCategory]
	)

	return (
		<>
			<div className="costing-function-editor--create-function--upper-blocks">
				<div>
					<div className="costing-function-editor--create-function--label-wrapper">
						<div className="costing-function-editor--create-function--label with-border">
							{getString('COSTING_FUNCTION_PARAMETERS_LABEL')}
						</div>
						<Button
							color="transparent"
							className="costing-function-editor--create-function--transparent-button"
							onClick={() => dispatch(openParameterModal(reducerStateId))}
							data-qa="data-qa-set-constant"
						>
							{getString('COSTING_FUNCTION_SET_PARAMETER')}
						</Button>
					</div>
					<div className="costing-function-editor--create-function--variables">
						{variablesList.map((variable: IFunctionVariableExtended) => {
							const isUsedInFunctionTimes = customFunction.filter(
								(funcVariable: IFunctionVariableExtended) =>
									funcVariable.name === variable.name
							).length
							const isCustomVariable =
								variable.type === VariableType.customVariable
							return (
								<DetailsPopup
									data={variable.value}
									popperDirection="bottom-end"
									isHover={isCustomVariable}
									popperClassName="info-box-wrapper"
									popperContactClassName="info-box-data"
									targetClassName="detail-title--star"
									key={variable.name}
								>
									<div
										data-qa={`data-qa-function-parameter-${variable.name}`}
										className={cx(
											'costing-function-editor--create-function--operator',
											'variable',
											{ deletable: isCustomVariable }
										)}
										onDragStart={e => handleDragStart(e, variable)}
										onDragEnd={handleDragEnd}
										onClick={() => handleClick(variable)}
										draggable
									>
										{variable.userReadableName}
										{!!isUsedInFunctionTimes && (
											<span className="costing-function-editor--create-function--variable-count">
												{isUsedInFunctionTimes}
											</span>
										)}
										{isCustomVariable && (
											<span
												data-qa={`data-qa-remove-function-parameter-${variable.name}`}
												className="costing-function-editor--create-function--variable-count delete custom"
												onClick={(e: React.MouseEvent<HTMLDivElement>) => {
													e.stopPropagation()
													dispatch(
														deleteCustomVariable(
															variable,
															allCustomFunctionsList,
															reducerStateId,
															adminSelectedOrganizationId
														)
													)
												}}
											>
												<ClearIcon />
											</span>
										)}
									</div>
								</DetailsPopup>
							)
						})}
					</div>
				</div>
				<div>
					<div className="costing-function-editor--create-function--label">
						{getString('COSTING_FUNCTION_OPERATORS_LABEL')}
					</div>
					<div className="costing-function-editor--create-function--operators-wrapper">
						<div className="costing-function-editor--create-function--operators">
							{FUNCTION_OPERATORS.map(operator => (
								<div
									key={operator.name}
									className="costing-function-editor--create-function--operator"
									onDragStart={e => handleDragStart(e, operator)}
									onClick={() => handleClick(operator)}
									draggable
									data-qa={`data-qa-function-parameter-${operator.userReadableName}`}
								>
									{operator.userReadableName}
								</div>
							))}
						</div>
						<div className="costing-function-editor--create-function--operators">
							{FUNCTION_NUMBERS.map(number => (
								<div
									key={number.name}
									className="costing-function-editor--create-function--operator"
									onDragStart={e => handleDragStart(e, number)}
									onClick={() => handleClick(number)}
									draggable
									data-qa={`data-qa-function-parameter-${number.userReadableName}`}
								>
									{number.userReadableName}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="costing-function-editor--create-function--drop-zone--wrapper">
				<div
					onDragOver={handleDragOver}
					onDrop={handleDrop}
					className="costing-function-editor--create-function--drop-zone"
				>
					<div className="costing-function-editor--create-function--operators">
						<div className="costing-function-editor--create-function--drop-zone--function-name">
							{functionCategoryReadableName} =
						</div>
						{customFunction.map((variable: IFunctionVariableExtended) => (
							<div
								data-qa={`data-qa-in-function-parameter-${variable.name}`}
								key={variable.id}
								className={cx(
									'costing-function-editor--create-function--operator',
									'deletable',
									{
										variable:
											variable.type !== VariableType.operator &&
											variable.type !== VariableType.number
									}
								)}
							>
								{variable.userReadableName}
								<span
									data-qa={`data-qa-remove-in-function-parameter-${variable.name}`}
									className="costing-function-editor--create-function--variable-count delete"
									onClick={() =>
										dispatch(
											deleteVariableFromFunction(variable.id, reducerStateId)
										)
									}
								>
									<ClearIcon />
								</span>
							</div>
						))}
					</div>
				</div>
				{saveButton && (
					<ButtonWithLoader
						disabled={!customFunction.length}
						id="costing-function-editor--button"
						className="costing-function-editor--button"
						loading={isLoadingStep2}
						qaDataElementName="data-qa-save-function-btn"
						onClick={() =>
							dispatch(
								saveCustomFunction(
									customFunction,
									selectedFunctionCategory,
									selectedPrintingTechnologies,
									printingTechnologies,
									variablesList,
									reducerStateId,
									adminSelectedOrganizationId
								)
							)
						}
					>
						<SaveOutlinedIcon className="costing-function-editor--icon" />
						{getString('SAVE')}
					</ButtonWithLoader>
				)}
				<Button
					disabled={!customFunction.length}
					color="secondary"
					id="costing-function-editor--button"
					className="costing-function-editor--button secondary"
					onClick={() => dispatch(clearCustomFunction(reducerStateId))}
				>
					{getString('CLEAR')}
				</Button>
			</div>
			<SetNewParameterAlert reducerStateId={reducerStateId} />
		</>
	)
}

export default CreateFunctionContent
