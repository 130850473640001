import React, { FC, memo, useMemo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import cx from 'classnames'

import { UploadingProjectInitialState } from '../UploadingProjectReducer'
import StatusMessage from './StatusMessage'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Loader from 'Scenes/Loader/Loader'
import { PUBLISHED } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

const PaperTSX: any = Paper

interface LinearProgressBarProps {
	small?: boolean
	bottomAnalyze?: boolean
	hideFiles?: boolean
	showAnalyze?: boolean
	bundleId?: string
}

const LinearProgressBar: FC<LinearProgressBarProps> = ({
	small,
	bottomAnalyze,
	hideFiles,
	showAnalyze = true,
	bundleId = null
}) => {
	let uploadPartsMessage = null

	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const { projectId: paramProjectId } = useParams() as { projectId: string }
	const { projectId, progress, filesUploaded, totalFiles, uploadSuccess } =
		useSelector((state: RootStateOrAny) => {
			return state?.uploadProject
		})

	const emptyFileUPloads = useMemo(
		() => filesUploaded.length === 0 && totalFiles.length === 0,
		[filesUploaded, totalFiles]
	)

	const equalFilesUpload = useMemo(
		() =>
			filesUploaded.length &&
			totalFiles.length &&
			filesUploaded.length === totalFiles.length,
		[filesUploaded, totalFiles]
	)

	const [{ progressBar, numberOfTotalPartsAnalyzed, projectStatus }] =
		useSelector((state: RootStateOrAny) => {
			return [
				state.UploadingProjectReducer?.projectUploading[
					bundleId || paramProjectId || projectId || 0
				] || new UploadingProjectInitialState()
			]
		})

	const { failedSilentConnection } = useSelector((state: RootStateOrAny) => {
		return state?.PushNotificationReducer
	})
	const finishedFillsUpload =
		(equalFilesUpload || progress === 100) && hideFiles

	const stillInProgress = !equalFilesUpload && progress < 100 && !hideFiles
	const projectStillInProgress =
		!!numberOfTotalPartsAnalyzed && !!numberOfTotalPartsAnalyzed

	if (!stillInProgress || !projectStillInProgress) {
		if (isOnPrem) {
			uploadPartsMessage = getString('UPLOAD_PARTS_YOU_CAN_LEAVE_ONPREM')
		} else {
			uploadPartsMessage = getString('UPLOAD_PARTS_YOU_CAN_LEAVE')
		}
	}

	return (
		<div className={cx('linear-progress-bar', { 'small-view': small })}>
			{!hideFiles && (
				<PaperTSX className="linear-progress-bar__box">
					<h2>
						{getString('UPLOAD')}
						{!finishedFillsUpload && (
							<span>
								<IconFactory iconName="warn" />
								{getString('UPLOAD_PARTS_KEEP_PAGE_OPEN')}
							</span>
						)}
					</h2>
					<div
						className="linear-progress-bar__box-progress"
						data-qa="data-qa-upload-bar"
					>
						<div className="linear-progress-bar__loader">
							{!stillInProgress &&
							!numberOfTotalPartsAnalyzed &&
							!equalFilesUpload ? (
								<Loader load={true} size={100} message="" showFlex={false} />
							) : (
								<IconFactory iconName="checkmark" />
							)}
						</div>
						<div className="linear-progress-bar__info">
							<div className="linear-progress-bar--progress-indicator">
								<div>{!numberOfTotalPartsAnalyzed ? +progress : 100}%</div>
							</div>
							<LinearProgress
								classes={{
									root: 'progress-line',
									bar: 'progress-bar'
								}}
								className="progress"
								variant="determinate"
								value={!numberOfTotalPartsAnalyzed ? +progress : 100}
							/>
							<div className="linear-progress-bar--files-indicator">
								{emptyFileUPloads
									? ''
									: getString('UPLOADING_MESSAGE').format(
											filesUploaded.length,
											totalFiles.length
									  )}
							</div>
						</div>
					</div>
				</PaperTSX>
			)}
			{showAnalyze && (
				<PaperTSX className="linear-progress-bar__box">
					<h2>
						{getString('ANALYZE')}
						<span>{uploadPartsMessage}</span>
					</h2>
					<div
						className="linear-progress-bar__box-progress"
						data-qa="data-qa-analyze-bar"
					>
						<div className="linear-progress-bar__loader">
							{projectStatus !== PUBLISHED &&
							(projectStillInProgress ||
								(!stillInProgress && uploadSuccess)) ? (
								<Loader load={true} size={100} message="" showFlex={false} />
							) : (
								<IconFactory iconName="checkmark" />
							)}
						</div>
						<div className="linear-progress-bar__info">
							<div className="linear-progress-bar--progress-indicator"></div>
							<div className="linear-progress-bar__info flex-column">
								{failedSilentConnection ? (
									<p className="error">
										{getString('NOTIFICATION_UPLOADING_MESSAGE_FAIL')}
									</p>
								) : (
									<>
										<LinearProgress
											classes={{
												root: 'progress-line',
												bar: cx('progress-bar', { empty: progressBar === 0 })
											}}
											className="progress"
											variant="determinate"
											value={+progressBar}
										/>
										{(projectStillInProgress || !stillInProgress) && (
											<p>
												<StatusMessage bundleId={bundleId} />
											</p>
										)}
									</>
								)}
							</div>
							<div className="linear-progress-bar--files-indicator"></div>
						</div>
					</div>
				</PaperTSX>
			)}
			{bottomAnalyze && !stillInProgress && (
				<span>{getString('UPLOAD_PARTS_YOU_CAN_LEAVE')}</span>
			)}
			{bottomAnalyze && stillInProgress && (
				<span>{getString('UPLOAD_PARTS_KEEP_PAGE_OPEN')}</span>
			)}
		</div>
	)
}

export default memo(LinearProgressBar)
