import React, { Component } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { AppBar, Toolbar, Typography } from '@material-ui/core'
import { default as classNames } from 'classnames'

import { logoutPressed } from '../../global actions'
import Breadcrumbs from './Breadcrumbs'
import MyMuiTheme from './MyMuiTheme'
import SmallTooltip from './SmallTooltip'
import VersionViewer from './VersionViewer'
import { CastorBannerType } from 'Scenes/Components/CastorBanner/CastorBannerType.enum'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './NavBarAndMaterial.scss'

const MAX_TEXT_COUNT = 28

const theme = getTheme()

const AppBarTSX: any = AppBar
const ToolbarTSX: any = Toolbar
const TypographyTSX: any = Typography

interface IProps {
	title: string
	crumbs: any
	show: boolean
	type?: CastorBannerType
	children: JSX.Element[] | JSX.Element | string
	fullWidth?: boolean
	wrapperStyle?: string
	isLightUser: boolean
	email: string
	logoutPressed: (email: string, forceToLoginPage?: boolean) => void
	showLogOutLink?: boolean
	isWeightReduction?: boolean
}

interface IReduxStore {
	user: any
	crumbs: any
	userHome: any
	CastorBannerReducer: any
}

class NavBarAndMaterial extends Component<IProps> {
	renderTitle = (logOnRibbonOn: boolean, isLightUser?: boolean) => {
		const { title } = this.props
		if (isLightUser) {
			return <div className="name">{title}</div>
		}
		if (!logOnRibbonOn) {
			return (
				<SmallTooltip
					rootClass="tooltip-title"
					name={title}
					maxTextCount={MAX_TEXT_COUNT}
				/>
			)
		}
		return <img src={theme.logo} alt="logo" className="header-toolbar--logo" />
	}

	render() {
		const versionOn = Feature.isFeatureOn(FeatureComponentId.VERSION_VIEWER)
		const sideBarAndMenu = Feature.isFeatureOn(
			FeatureComponentId.SIDE_BAR_AND_MENU
		)
		const showContactUs = Feature.isFeatureOn(
			FeatureComponentId.SHOW_CONTACT_US_BANNER
		)

		const logOnRibbonOn = !!Feature.isFeatureOn(
			FeatureComponentId.LOGO_ON_RIBBON
		)
		const isQuickCadUploadOn = Feature.isFeatureOn(
			FeatureComponentId.QUICK_CAD_UPLOAD
		)
		const isSideBarAndMenuOn = Feature.isFeatureOn(
			FeatureComponentId.SIDE_BAR_AND_MENU
		)
		const {
			crumbs,
			children,
			fullWidth,
			show,
			type,
			wrapperStyle,
			isLightUser,
			email,
			logoutPressed,
			showLogOutLink,
			isWeightReduction
		} = this.props

		const shouldShowBanner =
			(type === CastorBannerType.CONTACT_US && showContactUs) ||
			type !== CastorBannerType.CONTACT_US

		const shouldShowLogOutLink = isLightUser && showLogOutLink

		return (
			<MyMuiTheme>
				<div className={classNames(wrapperStyle)}>
					<AppBarTSX
						position="static"
						className={classNames({
							'header-toolbar--wrapper': logOnRibbonOn
						})}
						color="inherit"
					>
						<ToolbarTSX
							className={classNames('header-toolbar', {
								'version-on': versionOn,
								'with-logo': logOnRibbonOn,
								'full-width': fullWidth && !isSideBarAndMenuOn,
								'logout-link': shouldShowLogOutLink
							})}
						>
							<TypographyTSX variant="h6" color="inherit">
								{this.renderTitle(logOnRibbonOn, isLightUser)}
							</TypographyTSX>
							<Breadcrumbs crumbs={crumbs} />

							{shouldShowLogOutLink && (
								<div
									className="header-toolbar--logout-link"
									onClick={() => logoutPressed(email, true)}
								>
									{getString('LIGHT_USER_LOGOUT_LINK')}
								</div>
							)}
						</ToolbarTSX>
					</AppBarTSX>
					<div
						id="main-content-scroller"
						className={classNames('main-content-scroller', {
							'with-banner': show && shouldShowBanner,
							'light-user': isLightUser && shouldShowBanner,
							'quick-cad-upload': isQuickCadUploadOn,
							'is-weight-reduction-page': isWeightReduction
						})}
					>
						{children}
					</div>
				</div>
				{versionOn && !sideBarAndMenu && <VersionViewer />}
			</MyMuiTheme>
		)
	}
}

const mapStateToProps = ({
	CastorBannerReducer: { show, type },
	user: {
		loggedIn,
		isLightUser,
		userDetails: { email }
	},
	userHome: { crumbs }
}: IReduxStore) => {
	return { loggedIn, crumbs, show, type, isLightUser, email }
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ logoutPressed }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NavBarAndMaterial)
