import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'

import * as CustomizeActions from '../../CustomizeActions'
import { ParameterTypes } from '../ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { isDisabledKeyPressed } from 'Services/getKeyCodesService'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface IProps {
	puAnnualKgCO2Value: string
	puYearsCO2Value: string
	onPuAnnualKgCO2Change: Function
	onPuYearsCO2Change: Function
	setUserCustomizationSettings: Function
	puAnnualKgCO2: number
	puYearsCO2: number
}

interface IReduxStore {
	user: any
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2ProductUse: FC<IProps> = ({
	onPuAnnualKgCO2Change,
	onPuYearsCO2Change,
	puAnnualKgCO2Value,
	puYearsCO2Value,
	setUserCustomizationSettings,
	puAnnualKgCO2,
	puYearsCO2
}) => {
	const [isKgError, setIsKgError] = useState(false)
	const [isErrorYears, setIsErrorYears] = useState(false)
	const {
		puAnnualKgCO2Loading,
		puAnnualKgCO2ResetLoading,
		puYearsCO2Loading,
		puYearsCO2ResetLoading,
		userSettingsDefaultValues
	} = useSelector((state: RootStateOrAny) => state.user)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)

	useEffect(() => {
		if (!puYearsCO2Loading && !puYearsCO2ResetLoading) {
			onPuYearsCO2Change('')
			setIsErrorYears(false)
		}
	}, [puYearsCO2Loading, puYearsCO2ResetLoading])

	useEffect(() => {
		if (!puAnnualKgCO2Loading && !puAnnualKgCO2ResetLoading) {
			onPuAnnualKgCO2Change('')
			setIsKgError(false)
		}
	}, [puAnnualKgCO2Loading, puAnnualKgCO2ResetLoading])

	const savedKgAmountChecked = (value: string) => {
		if (Number(value) < 0 || Number(value) > 1000) {
			setIsKgError(true)
		} else {
			setIsKgError(false)
		}
		onPuAnnualKgCO2Change(value)
	}

	const savedKgYearsChecked = (value: string) => {
		if (Number(value) < 0 || Number(value) > 20) {
			setIsErrorYears(true)
		} else {
			setIsErrorYears(false)
		}
		onPuYearsCO2Change(value)
	}

	const onSaveProductUseKg = (reset?: boolean) => {
		setUserCustomizationSettings(
			reset ? userSettingsDefaultValues.puAnnualKgCO2 : puAnnualKgCO2Value,
			ParameterTypes.puAnnualKgCO2,
			reset,
			adminSelectedOrganizationId
		)
	}

	const onSaveProductUseYears = (reset?: boolean) => {
		setUserCustomizationSettings(
			reset ? userSettingsDefaultValues.puYearsCO2 : puYearsCO2Value,
			ParameterTypes.puYearsCO2,
			reset,
			adminSelectedOrganizationId
		)
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (isDisabledKeyPressed(event)) {
			event.preventDefault()
		}
	}

	const renderSavedKg = () => {
		return (
			<>
				<div className="custom-calculation-co2--explanation">
					{getString('CO2_CALCULATION_PRODUCT_USE_SUBTITLE')}
				</div>
				<Flexbox
					flexDirection="row"
					className="custom-calculation-co2--wrapper"
				>
					<Flexbox
						flexDirection="row"
						alignItems="center"
						width="100%"
						gap="13px"
					>
						<div className="custom-calculation-co2--explanation-text">
							{getString('CO2_CALCULATION_PRODUCT_USE_BY_WEIGHT_INPUT')}
						</div>
						<TextFieldTSX
							className="custom-calculation-co2--product-use-picker"
							type="number"
							placeholder={puAnnualKgCO2}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								savedKgAmountChecked(e.target.value)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							onKeyDown={handleKeyDown}
							value={puAnnualKgCO2Value}
						/>
						<div className="custom-calculation-co2--explanation-text">
							{getString('CO2_KG_ANNUALLY')}
						</div>
					</Flexbox>
					<Flexbox flexDirection="row" alignItems="center">
						{userSettingsDefaultValues.puAnnualKgCO2 !== puAnnualKgCO2 && (
							<ButtonWithLoader
								className="button-to-default"
								color="transparent"
								loading={puAnnualKgCO2ResetLoading}
								onClick={() => onSaveProductUseKg(true)}
							>
								{getString('RETURN_TO_DEFAULT')}
							</ButtonWithLoader>
						)}
						<ButtonWithLoader
							loading={puAnnualKgCO2Loading}
							onClick={() => onSaveProductUseKg(false)}
							disabled={isKgError || !puAnnualKgCO2Value}
						>
							{getString('SET')}
						</ButtonWithLoader>
					</Flexbox>
				</Flexbox>
				{isKgError && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_PRODUCT_USE_DATA_VALIDATION')}
					</div>
				)}
				<Flexbox
					flexDirection="row"
					className="custom-calculation-co2--wrapper"
				>
					<Flexbox flexDirection="row" alignItems="center">
						<div className="custom-calculation-co2--explanation-text">
							{getString('CO2_CALCULATION_PART_LIFE')} &nbsp;
						</div>
						<TextFieldTSX
							className="custom-calculation-co2--product-use-picker"
							type="number"
							placeholder={puYearsCO2}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								savedKgYearsChecked(e.target.value)
							}
							onWheel={(event: ChangeEvent<HTMLInputElement>) =>
								event.target.blur()
							}
							onKeyDown={handleKeyDown}
							value={puYearsCO2Value}
						/>
						<div className="custom-calculation-co2--explanation-text">
							&nbsp;{getString('YEARS').toLowerCase()}
						</div>
					</Flexbox>
					<Flexbox flexDirection="row" alignItems="center">
						{userSettingsDefaultValues.puYearsCO2 !== puYearsCO2 && (
							<ButtonWithLoader
								className="button-to-default"
								color="transparent"
								loading={puYearsCO2ResetLoading}
								onClick={() => onSaveProductUseYears(true)}
							>
								{getString('RETURN_TO_DEFAULT')}
							</ButtonWithLoader>
						)}
						<ButtonWithLoader
							loading={puYearsCO2Loading}
							onClick={() => onSaveProductUseYears(false)}
							disabled={isErrorYears || !puYearsCO2Value}
						>
							{getString('SET')}
						</ButtonWithLoader>
					</Flexbox>
				</Flexbox>
				{isErrorYears && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_PRODUCT_USE_DATA_VALIDATION_YEARS')}
					</div>
				)}
			</>
		)
	}

	return <>{renderSavedKg()}</>
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2ProductUse)
)
