import { toLower, toNumber } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import Numeral from 'numeral'

import { getMethodName } from '../../SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LeadTimeService'
import { NA } from 'Services/Constants'
import { calculate2DRange } from 'Services/global/calculateRange'
import { ChainBenefitsNames } from 'Services/models/IChainBenefits'
import { Part } from 'Services/models/IPart'
import { IPostProcess } from 'Services/models/IPostProcess'
import { benefitType } from 'Services/models/ISolutionBenefit'
import { PART_RESULTS_OPTIONS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import AsahiLogo from 'assets/img/asahi.png'
import BaxterLogo from 'assets/img/baxter_pdf.png'
import HpLogo from 'assets/img/hp_pdf.png'
import StratasysLogo from 'assets/img/stratasys.png'

const { logo } = getTheme()

export const hpCompany = ['hp', 'hp inc', 'hewlett-packard']
export const asahiCompany = ['asahikasei', 'asahi kasei']
export const baxterCompany = ['baxter']
export const stratasysCompany = ['stratasys']
export const detailedTable = 'detailedTable'
export const pdfTopOrder = [
	'partInformation',
	'amSolution',
	'materialAnalysis',
	'geometryAnalysis',
	'costEstimation',
	'leadTime'
]

export const makeRoundIfNeeded = (
	cost: number,
	drawingCostPercentage: number,
	isDrawing: boolean,
	disableRanges?: boolean
) => {
	// for N/A
	if (isNaN(+cost)) return cost

	if (isDrawing && !disableRanges)
		return calculate2DRange(toNumber(cost), drawingCostPercentage)

	return toNumber(cost)?.toFixed(2)
}

export const prepareShowHideElements = (
	projectPdfOptions: any,
	useUnprintableCheckboxes: boolean
) => {
	let showHideElements: any = {
		partInformation: false,
		amSolution: false,
		materialAnalysis: false,
		co2Emission: false,
		geometryAnalysis: false,
		costEstimation: false,
		summaryTable: false,
		financialBreak: false,
		leadTime: false
	}

	!isEmpty(projectPdfOptions) &&
		Object.keys(projectPdfOptions)?.forEach((keyElem: any) => {
			showHideElements[keyElem] = useUnprintableCheckboxes
				? projectPdfOptions[keyElem].showForUnprintable &&
				  projectPdfOptions[keyElem].checked
				: projectPdfOptions[keyElem].checked
			projectPdfOptions[keyElem]?.data?.forEach((elem: any) => {
				showHideElements[elem.name] = useUnprintableCheckboxes
					? elem.showForUnprintable && elem.checked
					: elem.checked
			})
		})

	return showHideElements
}

export const getAnalysisPdfData = (
	part: Part,
	configuration: any,
	fullConfiguration: any
) => {
	const isDrawing = part?.isDrawing || false
	const camExistence = part?.cncProps?.camExistence || false
	const configurationTraditionalCost = configuration?.costResults?.actualResult
	const standardCost =
		configuration?.standardCost || configuration.part?.standardCost
	const cost = configurationTraditionalCost?.toFixed(2) || standardCost
	const complexGeometry = fullConfiguration.solution.benefits?.find(
		(benefit: any) => benefit.type === benefitType.complexityScore
	)
	const methodText = getMethodName(configuration.manufactureMethod)
	const result = PART_RESULTS_OPTIONS.find(
		(partResultOption: any) =>
			partResultOption.value === configuration?.result ||
			partResultOption.value === configuration?.configurationResult
	).title

	return {
		isDrawing,
		camExistence,
		cost,
		complexGeometry,
		result,
		methodText
	}
}

export const getCostInformationPdfData = (
	configuration: any,
	isCostEffective: boolean,
	effectiveQuantity: number
) => {
	const methodText = getMethodName(configuration.manufactureMethod)
	const costResult = getString('COST_RESULT_EXPLENATION').format(
		isCostEffective || effectiveQuantity > 0
			? getString('IS')
			: getString('ISNT')
	)
	const costUpTo = getString('COST_RESULT_EXPLENATION_UP_TO').format(
		Numeral(effectiveQuantity).format('0,0')
	)

	return {
		methodText,
		costResult,
		costUpTo
	}
}

export const makeTotalCostForStandard = (
	part: Part,
	configuration: any,
	chainBenefits: any
) => {
	const standardCost = Numeral(
		configuration?.standardCost || part.standardCost
	).format('0,0[.]00')

	const supplyChainTraditionalDetails =
		configuration?.costResults?.supplyChainTraditionalDetails

	const totalCostPerU =
		supplyChainTraditionalDetails?.perPartCosts?.totalCostPerUnit
	const totalCostPerUnit = Numeral(totalCostPerU).format('0,0[.]00')

	const chainBenefitsIsOn = chainBenefits[ChainBenefitsNames.Global]?.on

	return totalCostPerU > 0 && chainBenefitsIsOn
		? totalCostPerUnit
		: standardCost || NA
}

export const getSurfaceMachining = (
	allOptionalPostProcessesData: IPostProcess[],
	togglesPostProcesses: any
) => {
	// Surface area machining
	const surfaceAreaId = 4
	const machiningSurface: any =
		allOptionalPostProcessesData.find((el: any) => el.id === surfaceAreaId) ||
		{}
	const machiningToggle: any = togglesPostProcesses[surfaceAreaId]

	return {
		surfaceName: getString(machiningSurface?.labelName || ''),
		surfaceIsOn: machiningToggle.toggled
	}
}

export const getPDFLogo = (company: string = '') => {
	const companyLowerCase = toLower(company).trim()

	switch (true) {
		case hpCompany.includes(companyLowerCase):
			return HpLogo
		case asahiCompany.includes(companyLowerCase):
			return AsahiLogo
		case baxterCompany.includes(companyLowerCase):
			return BaxterLogo
		case stratasysCompany.includes(companyLowerCase):
			return StratasysLogo
		default:
			return logo
	}
}

export const shouldShowPoweredBy = (company: string) => {
	const companyLowerCase = toLower(company)

	return [
		...hpCompany,
		...asahiCompany,
		...baxterCompany,
		...stratasysCompany
	].includes(companyLowerCase)
}
