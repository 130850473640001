import registerPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle'
import {
	ChangeEvent,
	FC,
	memo,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import React from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { MenuItem, TextField, withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Business from '@material-ui/icons/Business'
import Email from '@material-ui/icons/Email'
import Face from '@material-ui/icons/Face'
import HomeIcon from '@material-ui/icons/Home'
import InfoOutline from '@material-ui/icons/InfoOutlined'
import LockOutline from '@material-ui/icons/LockOutlined'

import {
	changeOrganization,
	checkboxClicked,
	companyChanged,
	emailChanged,
	hidePasswordStrengthAlert,
	hideVerifyUserAlert,
	loginStateChanged,
	nameChanged,
	reenterPasswordChanged,
	registerPasswordChanged,
	registerUser,
	resetAuthState,
	setupAvailableOrganizations,
	showPasswordStrengthAlert
} from '../../../global actions'
import { ENTER_KEY_CODE } from '../../../Services/Constants'
import { LOGIN_ROUTE } from '../../../Services/Constants/RoutesConstants'
import { uploadProjectRoute } from '../../../Services/routeFuncs'
import {
	REGISTER_COMPANY_PLACEHOLDER,
	REGISTER_EMAIL_PLACEHOLDER,
	REGISTER_LOGIN_BUT,
	REGISTER_LOGIN_TEXT,
	REGISTER_NAME_PLACEHOLDER,
	REGISTER_PASS_PLACEHOLDER,
	REGISTER_REENTER_PASS_PLACEHOLDER,
	REGISTER_TITLE,
	RESET_VERIFY_EMAIL_REQUEST_SUCCESS,
	RESET_VERIFY_USER_POPUP_HEADER,
	USER_ADDRESS
} from '../../../Services/Strings'
import { getString } from '../../../Services/Strings/StringService'
import { getTheme } from '../../../themes/getTheme'
import CastorAlert from '../../Components/alerts/CastorAlert'
import ButtonWithLoader from '../../Components/ButtonWithLoader'
import CastorLocationDropdown from '../../Components/CastorLocationSearchInput/CastorLocationDropdown/CastorLocationDropdown'
import CastorLocationSearchInput from '../../Components/CastorLocationSearchInput/CastorLocationSearchInput'
import PoweredBy from '../../Components/PoweredBy'
import CustomInput from '../../Components/thirdParty/CreativeTim/components/CustomInput/CustomInput'
import GridContainer from '../../Components/thirdParty/CreativeTim/components/Grid/GridContainer'
import ItemGrid from '../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid'
import RegisterExplanation from './RegisterExplanation'
import { styles } from './style'
import UsageAgreementCheckbox from './UsageAgreementCheckbox'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import Flexbox from 'Scenes/Components/FlexBox'
import LoginCard from 'Scenes/Components/thirdParty/CreativeTim/components/Cards/LoginCard'
import { FeatureComponentId } from 'Services/models/Features'
import { IOrganization } from 'Services/models/IOrganization'

import '../Login/Login.scss'

const registerPageStyleTS: any = registerPageStyle
const theme = getTheme()

const Register: FC<any> = ({ classes }) => {
	const wrapperRef = useRef(null)
	const dispatch = useDispatch()
	const history = useHistory()
	const {
		email,
		password,
		reenterPassword,
		name,
		company,
		error,
		loading,
		loggedIn,
		agreedToTerms,
		strengthScore,
		emailValid,
		showingSimpleAlertText,
		showingRenderVerifyUser,
		showingSimpleAlertTitle,
		reenterPasswordInValid,
		reenterPasswordValid,
		language,
		authFeatures,
		availableOrganizations,
		organizationId
	} = useSelector((state: RootStateOrAny) => state.auth)
	const {
		city,
		country,
		countryCode,
		formatted_address,
		lat,
		long,
		state,
		validAddress,
		zip_code
	} = useSelector((state: RootStateOrAny) => state.CastorLocationSearchReducer)
	const { isOnPrem } = useSelector(
		(state: RootStateOrAny) => state.GlobalReducer
	)
	const [loggedInState, setLoggedInState] = useState(loggedIn)

	const customizeOrganizationsFeatureIsOn = useMemo(
		() =>
			authFeatures.find(
				(feature: Record<string, any>) =>
					feature.name === FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
			)?.on,
		[authFeatures]
	)

	useEffect(() => {
		dispatch(resetAuthState())
	}, [])

	useEffect(() => {
		if (loggedInState !== loggedIn) {
			updateStateAndRoute()
			setLoggedInState(loggedIn)
		}
	}, [loggedIn, loggedInState])

	useEffect(() => {
		if (customizeOrganizationsFeatureIsOn) {
			dispatch(setupAvailableOrganizations())
		}
	}, [customizeOrganizationsFeatureIsOn, dispatch])

	const onKeyUp = (event: KeyboardEvent) => {
		if (event.keyCode === ENTER_KEY_CODE) {
			onSubmitClicked()
		}
	}

	const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(emailChanged(event.target.value))
	}

	const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			registerPasswordChanged(
				event.target.value,
				strengthScore,
				reenterPassword
			)
		)
	}

	const onReenterPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			reenterPasswordChanged(strengthScore, password, event.target.value)
		)
	}

	const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(nameChanged(event.target.value))
	}

	const onCompanyChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(companyChanged(event.target.value))
	}

	const onOrganizationChange = (value: number) => {
		dispatch(changeOrganization(value))
	}

	const onSubmitClicked = () => {
		dispatch(
			registerUser({
				email,
				password,
				reenterPassword,
				name,
				company,
				agreedToTerms,
				strengthScore,
				formatted_address,
				city,
				state,
				country,
				long,
				lat,
				zip_code,
				validAddress,
				language,
				countryCode,
				organizationId,
				customizeOrganizationsFeatureIsOn,
				availableOrganizations
			})
		)
	}

	const updateStateAndRoute = () => {
		dispatch(loginStateChanged(true))
		history.push(uploadProjectRoute())
	}

	const renderAlert = () => {
		return (
			<CastorAlert
				alertType={AlertType.WARNING}
				show={showingSimpleAlertText}
				headerTitle={showingSimpleAlertTitle}
				onConfirm={() => dispatch(hidePasswordStrengthAlert())}
				onCancel={() => dispatch(hidePasswordStrengthAlert())}
				showCancel={false}
				confirmOptionalText={getString('CLOSE')}
			>
				{showingSimpleAlertText}
			</CastorAlert>
		)
	}

	const renderVerifyUserAlert = () => {
		return (
			<CastorAlert
				alertType={AlertType.SUCCESS}
				show={showingRenderVerifyUser}
				headerTitle={RESET_VERIFY_USER_POPUP_HEADER}
				onConfirm={() => dispatch(hideVerifyUserAlert())}
				onCancel={() => dispatch(hideVerifyUserAlert())}
				showCancel={false}
				confirmOptionalText={getString('CLOSE')}
			>
				{RESET_VERIFY_EMAIL_REQUEST_SUCCESS}
			</CastorAlert>
		)
	}

	return (
		<div className={classes.wrapper} ref={wrapperRef}>
			{renderAlert()}
			{renderVerifyUserAlert()}

			<div className={classes.fullPage}>
				<div className={classes.content}>
					<div className={classes.container}>
						<GridContainer justifyContent="center">
							<ItemGrid xs={12} sm={12} md={10}>
								<LoginCard
									cardTitle={REGISTER_TITLE}
									headerColor="rose"
									customCardTitleClasses={classes.cardTitle}
									customCardClasses={classes.cardClasses}
									customCardHeaderClasses={classes.cardHeader}
									content={
										<div>
											<GridContainer justifyContent="center">
												<ItemGrid xs={12} sm={12} md={5}>
													<div className={classes.center}>
														<img
															alt={'img-register'}
															style={styles.logoImage}
															src={theme.whiteBackgroundLogo || theme.logo}
														/>
													</div>
													<RegisterExplanation />
												</ItemGrid>
												<ItemGrid xs={12} sm={8} md={5}>
													<br />
													<form
														className={classes.form}
														style={{ display: 'flex', flexDirection: 'column' }}
													>
														<CustomInput
															success={emailValid}
															error={emailValid === false}
															formControlProps={{
																fullWidth: true,
																className: classes.customFormControlClasses
															}}
															inputProps={{
																startAdornment: (
																	<InputAdornment
																		position="start"
																		className={classes.inputAdornment}
																	>
																		<Email
																			className={classes.inputAdornmentIcon}
																		/>
																	</InputAdornment>
																),
																placeholder: REGISTER_EMAIL_PLACEHOLDER,
																value: email,
																onChange: onEmailChange,
																autoComplete: 'email',
																onKeyUp: onKeyUp
															}}
														/>
														<CustomInput
															success={!!name}
															formControlProps={{
																fullWidth: true,
																className: classes.customFormControlClasses
															}}
															inputProps={{
																startAdornment: (
																	<InputAdornment
																		position="start"
																		className={classes.inputAdornment}
																	>
																		<Face
																			className={classes.inputAdornmentIcon}
																		/>
																	</InputAdornment>
																),
																placeholder: REGISTER_NAME_PLACEHOLDER,
																value: name,
																onChange: onNameChange,
																autoComplete: 'name',
																onKeyUp: onKeyUp
															}}
														/>
														{availableOrganizations.length > 1 && (
															<TextField
																className="register-form--organization-input"
																data-qa="data-qa-organization-select"
																InputProps={{
																	startAdornment: <Business />
																}}
																InputLabelProps={{
																	shrink: !!organizationId
																}}
																select
																SelectProps={{
																	MenuProps: {
																		PopoverClasses: {
																			root: 'dropdown'
																		}
																	}
																}}
																label={getString(
																	'REGISTER_FORM_ORGANIZATION_LABEL'
																)}
																value={organizationId}
																onChange={(
																	e: ChangeEvent<HTMLInputElement>
																) => {
																	onOrganizationChange(Number(e.target.value))
																}}
															>
																<MenuItem disabled>
																	{getString(
																		'REGISTER_FORM_ORGANIZATION_LABEL'
																	)}
																</MenuItem>
																{availableOrganizations?.map(
																	(organization: IOrganization) => {
																		return (
																			<MenuItem
																				key={organization.id}
																				value={organization.id}
																			>
																				{organization.name}
																			</MenuItem>
																		)
																	}
																)}
															</TextField>
														)}
														{!customizeOrganizationsFeatureIsOn && (
															<CustomInput
																success={!!company}
																formControlProps={{
																	fullWidth: true,
																	className: classes.customFormControlClasses
																}}
																inputProps={{
																	startAdornment: (
																		<InputAdornment
																			position="start"
																			className={classes.inputAdornment}
																		>
																			<Business
																				className={classes.inputAdornmentIcon}
																			/>
																		</InputAdornment>
																	),
																	placeholder: REGISTER_COMPANY_PLACEHOLDER,
																	value: company,
																	onChange: onCompanyChange,
																	autoComplete: 'company',
																	onKeyUp: onKeyUp
																}}
															/>
														)}
														{isOnPrem ? (
															<CastorLocationDropdown
																wrapperClass={'register'}
																withIcons
															/>
														) : (
															<CastorLocationSearchInput
																placeHolder={USER_ADDRESS}
																managerStyle={{
																	width: '100%',
																	margin: '0 12px'
																}}
																inputStyle={{ margin: 'unset' }}
																inputProps={{
																	startAdornment: (
																		<InputAdornment
																			position="start"
																			className={classes.inputAdornment}
																		>
																			<HomeIcon
																				className={classes.inputAdornmentIcon}
																			/>
																		</InputAdornment>
																	),
																	autoComplete: 'shipping'
																}}
															/>
														)}

														<Flexbox flexDirection="row">
															<CustomInput
																success={strengthScore > 1}
																error={
																	password.length > 0 && strengthScore <= 1
																}
																formControlProps={{
																	fullWidth: true,
																	className: classes.customFormControlClasses
																}}
																inputProps={{
																	startAdornment: (
																		<InputAdornment
																			position="start"
																			className={classes.inputAdornment}
																		>
																			<LockOutline
																				className={classes.inputAdornmentIcon}
																			/>
																		</InputAdornment>
																	),
																	placeholder: REGISTER_PASS_PLACEHOLDER,
																	value: password,
																	onChange: onPasswordChange,
																	type: 'password',
																	autoComplete: 'new-password',
																	onKeyUp: onKeyUp
																}}
															/>

															<IconButton
																onClick={() =>
																	dispatch(showPasswordStrengthAlert())
																}
																//@ts-ignore
																style={
																	password.length > 0 && strengthScore <= 1
																		? styles.infoBut
																		: styles.infoButHidden
																}
															>
																<InfoOutline />
															</IconButton>
														</Flexbox>
														<CustomInput
															success={reenterPasswordValid}
															error={reenterPasswordInValid}
															formControlProps={{
																fullWidth: true,
																className: classes.customFormControlClasses
															}}
															inputProps={{
																startAdornment: (
																	<InputAdornment
																		position="start"
																		className={classes.inputAdornment}
																	>
																		<LockOutline
																			className={classes.inputAdornmentIcon}
																		/>
																	</InputAdornment>
																),
																placeholder: REGISTER_REENTER_PASS_PLACEHOLDER,
																value: reenterPassword,
																onChange: onReenterPasswordChange,
																type: 'password',
																autoComplete: 'new-password',
																onKeyUp: onKeyUp
															}}
														/>
														<UsageAgreementCheckbox
															openInTab={true}
															agreedToTerms={agreedToTerms}
															checkboxClicked={(checked: boolean) =>
																dispatch(checkboxClicked(checked))
															}
														/>
														<div className={classes.center}>
															<ButtonWithLoader
																qaDataElementName="data-qa-register-btn"
																className="login-button"
																loading={loading}
																color={'primaryNewStyle'}
																style={styles.submitButtonStyle}
																onClick={onSubmitClicked}
															>
																{REGISTER_TITLE}
															</ButtonWithLoader>
															<br />
															<p style={styles.errorTextStyle}>{error}</p>
															<Flexbox
																flexDirection="row"
																className="register-text"
															>
																<p>{REGISTER_LOGIN_TEXT}</p>
																<div
																	className="link"
																	onClick={() =>
																		history.push(LOGIN_ROUTE, {
																			disableAnimation: true,
																			resetAuthState: true
																		})
																	}
																>
																	{REGISTER_LOGIN_BUT}
																</div>
															</Flexbox>
														</div>
													</form>
												</ItemGrid>
											</GridContainer>
											<PoweredBy className="register-powered-by" />
										</div>
									}
								/>
							</ItemGrid>
						</GridContainer>
					</div>
				</div>

				<div
					className={classes.fullPageBackground}
					style={{ backgroundImage: 'url(' + theme.bgImage + ')' }}
				/>
			</div>
		</div>
	)
}

export default memo(withStyles(registerPageStyleTS)(Register))
