import React, { FC } from 'react'

import CustomizeCO2EndOfLife from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2EndofLife/CustomizeCO2EndOfLife'
import { getString } from 'Services/Strings/StringService'

const EndOfLife: FC = () => {
	return (
		<div
			className="material-production--wrapper product-use"
			data-qa="data-qa-end-of-life-section"
		>
			<div className="material-production--header">
				{getString('CO2_PARAMETERS_END_OF_LIFE_HEADER_TEXT')}
			</div>
			<CustomizeCO2EndOfLife />
		</div>
	)
}

export default EndOfLife
