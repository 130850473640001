import React, { FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MATERIAL_NAMES_IDS, STEP_ID } from '../../constants'
import { useStep } from '../../OnboardingWizardHooks'
import { isContentPresent } from '../../OnboardingWizardService'
import OnboardingStepFrame from '../OnboardingStepFrame'
import AddMaterialNames from './AddMaterialNames'
import DefaultMaterial from './DefaultMaterial'
import EditMaterialNames from './EditMaterialNames'
import { fetchUserMaterialNameMapping } from 'Scenes/Home/Customize/CustomizeActions'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getTheme } from 'themes/getTheme'

import './MaterialNames.scss'

const { defaultMaterial } = getTheme()

const MaterialNamesComponents = {
	[MATERIAL_NAMES_IDS.DEFAULT_MATERIAL]: <DefaultMaterial />,
	[MATERIAL_NAMES_IDS.MATERIAL_BY_NAME]: <AddMaterialNames />,
	[MATERIAL_NAMES_IDS.EDIT_MATERIAL_NAMES]: <EditMaterialNames />
}

const MaterialNames: FC = () => {
	const { steps } = useSelector(
		(state: RootStateOrAny) => state.OnboardingWizardReducer
	)
	const { userMaterialNamesMapping, initialUserDefaultMaterial } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const { step, stepContent } = useStep(steps, STEP_ID.MATERIAL_NAMES)
	const dispatch = useDispatch()

	const changesInCurrentStep =
		(isContentPresent(stepContent, MATERIAL_NAMES_IDS.EDIT_MATERIAL_NAMES) &&
			userMaterialNamesMapping?.filter(
				(materialName: IUserMaterialNamesMapping) =>
					!materialName.defaultFormatType && materialName.active
			).length > 0) ||
		(isContentPresent(stepContent, MATERIAL_NAMES_IDS.DEFAULT_MATERIAL) &&
			initialUserDefaultMaterial.material?.id !== defaultMaterial.id)

	useEffect(() => {
		dispatch(fetchUserMaterialNameMapping(adminSelectedOrganizationId))
	}, [adminSelectedOrganizationId, dispatch])

	return (
		<OnboardingStepFrame
			step={step}
			changesInCurrentStep={changesInCurrentStep}
		>
			<div className="material-names--wrapper">
				{stepContent?.map(component => {
					return (
						<React.Fragment key={component.id}>
							{
								MaterialNamesComponents[
									component.id as keyof typeof MaterialNamesComponents
								]
							}
						</React.Fragment>
					)
				})}
			</div>
		</OnboardingStepFrame>
	)
}

export default memo(MaterialNames)
