import parse from 'html-react-parser'
import React, { useMemo, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { onFileMaterialAndQuantityChange } from '../../UploadProjectActions'
import { initialState, QUICK_UPLOAD_COLUMN } from './constants'
import { PrintingStandardsEnum } from './FileRowColumns/PrintingStandards'
import { MaterialSelectorService } from 'Scenes/Components/MaterialSelector/TmMaterialSelector/MaterialSelectorService'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'
import { IUploadProjectComponent } from 'Services/models/IUploadProjectComponent'
import { getString } from 'Services/Strings/StringService'

export const useFileMaterialSelectorState = (name: string) => {
	const { filesWithMaterialAndQuantity } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const state = filesWithMaterialAndQuantity.find(
		(file: IFileWithMaterialAndQuantity) => file.name === name
	) as IFileWithMaterialAndQuantity
	const user = useSelector((state: RootStateOrAny) => state.user)
	const printingStandardsList = user.printingStandardsList

	const dispatch = useDispatch()

	const materialsService = new MaterialSelectorService(
		user.materials,
		state.materialType as string,
		state.materialCategory as string,
		state.originalMaterial,
		user.materialCategories
	)

	const materialAndQuantityChanged = (
		changedFile: IFileWithMaterialAndQuantity
	) => {
		dispatch(onFileMaterialAndQuantityChange(changedFile))
	}

	const onMaterialTypeChange = (value: string) => {
		const { chosenMaterialType, chosenMaterialCategory, chosenMaterial } =
			materialsService.materialTypeChanged(value)

		materialAndQuantityChanged({
			...initialState,
			name,
			productionQuantity: state.productionQuantity,
			standardCost: state.standardCost,
			customToleranceValue: state.customToleranceValue,
			materialType: chosenMaterialType,
			materialCategory: chosenMaterialCategory,
			originalMaterial: chosenMaterial.name,
			materialId: chosenMaterial.id,
			printingStandards: state.printingStandards
		})
	}

	const onMaterialCategoryChange = (value: string) => {
		const { chosenMaterialCategory, chosenMaterial } =
			materialsService.materialCategoryChanged(value)

		materialAndQuantityChanged({
			...state,
			materialType: state.materialType,
			materialCategory: chosenMaterialCategory,
			originalMaterial: chosenMaterial.name,
			materialId: chosenMaterial.id
		})
	}

	const onMaterialChange = (value: string) => {
		const { chosenMaterial } = materialsService.materialChanged(value)

		materialAndQuantityChanged({
			...state,
			originalMaterial: chosenMaterial.name,
			materialId: chosenMaterial.id
		})
	}

	const onQuantityChange = (
		event: React.ChangeEvent<{}>,
		newValue: string | null
	) => {
		materialAndQuantityChanged({
			...state,
			productionQuantity: newValue
		})
	}

	const onStandardCostChange = (value: number) => {
		materialAndQuantityChanged({
			...state,
			standardCost: value
		})
	}

	const onToleranceChange = (value: ToleranceClass) => {
		materialAndQuantityChanged({
			...state,
			customToleranceValue: value || ToleranceClass.TOLERANCE_CLASS_IRRELEVANT
		})
	}

	const onPrintingStandardsChange = (value: PrintingStandardsEnum) => {
		materialAndQuantityChanged({
			...state,
			printingStandards: value
		})
	}

	const onQuantityBlur = (inputValue: string) => {
		materialAndQuantityChanged({
			...state,
			productionQuantity: inputValue
		})
	}

	return {
		state,
		printingStandardsList,
		onMaterialTypeChange,
		onMaterialCategoryChange,
		onMaterialChange,
		onQuantityChange,
		onQuantityBlur,
		onStandardCostChange,
		onToleranceChange,
		onPrintingStandardsChange,
		materialsService
	}
}

export const useFilesValidation = (
	files: IFileWithMaterialAndQuantity[],
	columns: IUploadProjectComponent[]
) => {
	const [errorFiles, setErrorFiles] = useState<string[]>([])

	const mandatoryFields = useMemo(
		() =>
			columns.filter(
				column => !column.optional && column.id !== QUICK_UPLOAD_COLUMN.FILE
			),
		[columns]
	)

	const validateFiles = () => {
		const errors: string[] = []
		files.forEach(file => {
			mandatoryFields.forEach(field => {
				if (!file[field.name as keyof typeof file]) {
					errors.push(file.name)
				}
			})
		})
		if (errors.length) {
			setErrorFiles(errors)
			return false
		}
		setErrorFiles([])
		return true
	}

	const revalidateFiles = () => {
		if (!errorFiles.length) return
		validateFiles()
	}

	return { errorFiles, validateFiles, revalidateFiles }
}

export const useGridColumns = (columnsCount: number) => {
	const fileColumnWidth = 100 / (columnsCount - 1)
	return `${fileColumnWidth}vw repeat(${columnsCount - 1}, 1fr)`
}

export const useComsToolUploadErrorMessage = (noRemainingParts: boolean) => {
	let errorMessage: string | null | React.ReactNode = ''
	const user = useSelector((state: RootStateOrAny) => state.user)
	if (noRemainingParts) {
		const userName = user?.userDetails?.name
		const userEmail = user?.userDetails?.email
		const emailSubject = getString(
			'LIGHT_USER_CONTACT_US_EMAIL_SUBJECT'
		).format(userName ? `- ${userName}` : '')
		const emailFrom = userName && userEmail ? userEmail : ''
		const emailBody = encodeURIComponent(
			getString('LIGHT_USER_CONTACT_US_EMAIL_BODY').format(userName || '')
		)
		errorMessage = parse(
			getString('LIGHT_USER_LIMIT_REACHED_INFO').format(
				user?.contactUsEmail,
				emailSubject,
				emailBody,
				emailFrom
			)
		)
	}

	return errorMessage
}
