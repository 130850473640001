import { getString } from './Strings/StringService'
import { UnitSystem } from './UnitsConversionService'

//auth
export const CONNECTION_LOST = getString('CONNECTION_LOST')
export const ACCEPT = getString('ACCEPT')
export const ACTION_UNAUTHORIZED_MASSAGE = getString(
	'ACTION_UNAUTHORIZED_MASSAGE'
)
export const ADD = getString('ADD')
export const ADD_FILTER_FEATURES_TITLE = getString('ADD_FILTER_FEATURES_TITLE')
export const ADD_FILTER_FIELDS_TITLE = getString('ADD_FILTER_FIELDS_TITLE')
export const ADD_IMAGE_DROPZONE_DROPZONE_HAS_FILES = getString(
	'ADD_IMAGE_DROPZONE_DROPZONE_HAS_FILES'
)
export const ADD_IMAGE_DROPZONE_NO_FILES = getString(
	'ADD_IMAGE_DROPZONE_NO_FILES'
)
export const ADD_NEW = getString('ADD_NEW')
export const ADD_PART_RESULT_SUBMIT_BUT = getString(
	'ADD_PART_RESULT_SUBMIT_BUT'
)
export const UPLOADING_MESSAGE = getString('UPLOADING_MESSAGE')
export const PROJECT_INFORMATION = getString('PROJECT_INFORMATION')
export const USER_INFORMATION = getString('USER_INFORMATION')
export const STATUS_INFORMATION = getString('STATUS_INFORMATION')
export const PROGRESS = getString('PROGRESS')
export const STATUS_BREAKDOWN = getString('STATUS_BREAKDOWN')
export const PART_STATUSES_TEXTS = getString('PART_STATUSES_TEXTS')
export const AM_TYPE_IMPROVEMENTS = getString('AM_TYPE_IMPROVEMENTS')

export const ADD_TO_FAVORITES = getString('ADD_TO_FAVORITES')
export const ADDED_PART_MESSAGE = getString('ADDED_PART_MESSAGE')
export const ADMIN_MATERIAL_PARAMS = getString('ADMIN_MATERIAL_PARAMS')
export const ADMIN_MATERIAL_PRINTER_PAIRS_ADDED_SUCCESS = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_ADDED_SUCCESS'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_EDIT = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_EDIT'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_ERROR = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_ERROR'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_NEW = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_NEW'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_NEW_ERROR = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_NEW_ERROR'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_NEW_MATERIAL_PRINTER_PAIR = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_NEW_MATERIAL_PRINTER_PAIR'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_NO_MATERIAL_PRINTER_PAIRS_FOUND =
	getString('ADMIN_MATERIAL_PRINTER_PAIRS_NO_MATERIAL_PRINTER_PAIRS_FOUND')
export const ADMIN_MATERIAL_PRINTER_PAIRS_PAGE_HEADER = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_PAGE_HEADER'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_PARAMS = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_PARAMS'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_SUB_TITLE = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_SUB_TITLE'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_TABLE_TEXTS = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_TABLE_TEXTS'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_ERROR = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_ERROR'
)
export const ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_SUCCESS = getString(
	'ADMIN_MATERIAL_PRINTER_PAIRS_UPDATE_SUCCESS'
)
export const ADMIN_MATERIAL_TABLE_TEXTS = getString(
	'ADMIN_MATERIAL_TABLE_TEXTS'
)
export const ADMIN_MATERIALS_ADDED_SUCCESS = getString(
	'ADMIN_MATERIALS_ADDED_SUCCESS'
)
export const CUSTOMIZE_FORM_IN_HOUSE_PRINTERS_HEADER = getString(
	'CUSTOMIZE_FORM_IN_HOUSE_PRINTERS_HEADER'
)
export const IN_HOUSE_PRINTERS_PRINTER_NAME = getString(
	'IN_HOUSE_PRINTERS_PRINTER_NAME'
)
export const IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_TITLE = getString(
	'IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_TITLE'
)
export const IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_CONTENT = getString(
	'IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_CONTENT'
)
export const IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME = getString(
	'IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME'
)
export const IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME = getString(
	'IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME'
)
export const IN_HOUSE_PRINTERS_COMPANY = getString('IN_HOUSE_PRINTERS_COMPANY')
export const IN_HOUSE_PRINTERS_AMOUNT = getString('IN_HOUSE_PRINTERS_AMOUNT')
export const IN_HOUSE_PRINTERS_ACTION = getString('IN_HOUSE_PRINTERS_ACTION')
export const ADMIN_MATERIALS_EDIT = getString('ADMIN_MATERIALS_EDIT')
export const ADMIN_MATERIALS_ERROR = getString('ADMIN_MATERIALS_ERROR')
export const ADMIN_MATERIALS_NEW = getString('ADMIN_MATERIALS_NEW')
export const ADMIN_MATERIALS_NEW_USER_MATERIAL = getString(
	'ADMIN_MATERIALS_NEW_USER_MATERIAL'
)
export const ADMIN_MATERIALS_NEW_ERROR = getString('ADMIN_MATERIALS_NEW_ERROR')
export const ADMIN_MATERIALS_NEW_MATERIAL = getString(
	'ADMIN_MATERIALS_NEW_MATERIAL'
)
export const ADMIN_MATERIALS_NO_MATERIALS_FOUND = getString(
	'ADMIN_MATERIALS_NO_MATERIALS_FOUND'
)
export const ADMIN_MATERIALS_PAGE_HEADER = getString(
	'ADMIN_MATERIALS_PAGE_HEADER'
)
export const ADMIN_MATERIALS_SUB_TITLE = getString('ADMIN_MATERIALS_SUB_TITLE')
export const ADMIN_MATERIALS_UPDATE_ERROR = getString(
	'ADMIN_MATERIALS_UPDATE_ERROR'
)
export const ADMIN_MATERIALS_UPDATE_SUCCESS = getString(
	'ADMIN_MATERIALS_UPDATE_SUCCESS'
)
export const ADMIN_PANEL = getString('ADMIN_PANEL')
export const ADMIN_PARTS_AMOUNT_IN_ASSMBLY = getString(
	'ADMIN_PARTS_AMOUNT_IN_ASSMBLY'
)
export const ADMIN_PARTS_AREA = getString('ADMIN_PARTS_AREA')
export const ADMIN_PARTS_CAD_SCORE = getString('ADMIN_PARTS_CAD_SCORE')
export const ADMIN_PARTS_CLUSTER_PARTS_NO = getString(
	'ADMIN_PARTS_CLUSTER_PARTS_NO'
)
export const ADMIN_PARTS_EXTERNAL_ID = getString('ADMIN_PARTS_EXTERNAL_ID')
export const ADMIN_PARTS_IS_OFF_THE_SHELF = getString(
	'ADMIN_PARTS_IS_OFF_THE_SHELF'
)
export const ADMIN_PARTS_ORIGINAL_MATERIAL = getString(
	'ADMIN_PARTS_ORIGINAL_MATERIAL'
)
export const ADMIN_PARTS_PART_ID = getString('ADMIN_PARTS_PART_ID')
export const ADMIN_PARTS_RESULT = getString('ADMIN_PARTS_RESULT')
export const ADMIN_PARTS_SIZE = getString('ADMIN_PARTS_SIZE')
export const ADMIN_PARTS_SIZE_SCORE = getString('ADMIN_PARTS_SIZE_SCORE')
export const ADMIN_PARTS_STATUS = getString('ADMIN_PARTS_STATUS')
export const ADMIN_PARTS_TRANSFORMATION_MATRIX_HASH = getString(
	'ADMIN_PARTS_TRANSFORMATION_MATRIX_HASH'
)
export const ADMIN_PARTS_VOLUME = getString('ADMIN_PARTS_VOLUME')
export const ADMIN_PARTS_WALL_THICKNESS_SCORE = getString(
	'ADMIN_PARTS_WALL_THICKNESS_SCORE'
)
export const ADMIN_PRINTER_MATERIALS_ADDED_SUCCESS = getString(
	'ADMIN_PRINTER_MATERIALS_ADDED_SUCCESS'
)
export const ADMIN_PRINTER_MATERIALS_EDIT = getString(
	'ADMIN_PRINTER_MATERIALS_EDIT'
)
export const ADMIN_PRINTER_MATERIALS_ERROR = getString(
	'ADMIN_PRINTER_MATERIALS_ERROR'
)
export const ADMIN_PRINTER_MATERIALS_NEW = getString(
	'ADMIN_PRINTER_MATERIALS_NEW'
)
export const ADMIN_PRINTER_MATERIALS_NEW_ERROR = getString(
	'ADMIN_PRINTER_MATERIALS_NEW_ERROR'
)
export const ADMIN_PRINTER_MATERIALS_NEW_PRINTER_MATERIAL = getString(
	'ADMIN_PRINTER_MATERIALS_NEW_PRINTER_MATERIAL'
)
export const ADMIN_PRINTER_MATERIALS_NO_PRINTER_MATERIALS_FOUND = getString(
	'ADMIN_PRINTER_MATERIALS_NO_PRINTER_MATERIALS_FOUND'
)
export const ADMIN_PRINTER_MATERIALS_PAGE_HEADER = getString(
	'ADMIN_PRINTER_MATERIALS_PAGE_HEADER'
)
export const ADMIN_PRINTER_MATERIALS_SUB_TITLE = getString(
	'ADMIN_PRINTER_MATERIALS_SUB_TITLE'
)
export const ADMIN_PRINTER_MATERIALS_UPDATE_ERROR = getString(
	'ADMIN_PRINTER_MATERIALS_UPDATE_ERROR'
)
export const ADMIN_PRINTER_MATERIALS_UPDATE_SUCCESS = getString(
	'ADMIN_PRINTER_MATERIALS_UPDATE_SUCCESS'
)
export const ADMIN_PRINTER_NAMES_ADDED_SUCCESS = getString(
	'ADMIN_PRINTER_NAMES_ADDED_SUCCESS'
)
export const ADMIN_PRINTER_NAMES_EDIT = getString('ADMIN_PRINTER_NAMES_EDIT')
export const ADMIN_PRINTER_NAMES_ERROR = getString('ADMIN_PRINTER_NAMES_ERROR')
export const ADMIN_PRINTER_NAMES_MOVE_TO_PRINTERS = getString(
	'ADMIN_PRINTER_NAMES_MOVE_TO_PRINTERS'
)
export const ADMIN_PRINTER_NAMES_NEW = getString('ADMIN_PRINTER_NAMES_NEW')
export const ADMIN_PRINTER_NAMES_NEW_ERROR = getString(
	'ADMIN_PRINTER_NAMES_NEW_ERROR'
)
export const ADMIN_PRINTER_NAMES_NEW_PRINTER_NAME = getString(
	'ADMIN_PRINTER_NAMES_NEW_PRINTER_NAME'
)
export const ADMIN_PRINTER_NAMES_NO_PRINTER_NAMES_FOUND = getString(
	'ADMIN_PRINTER_NAMES_NO_PRINTER_NAMES_FOUND'
)
export const ADMIN_PRINTER_NAMES_PAGE_HEADER = getString(
	'ADMIN_PRINTER_NAMES_PAGE_HEADER'
)
export const ADMIN_PRINTER_NAMES_PARAMS = getString(
	'ADMIN_PRINTER_NAMES_PARAMS'
)
export const ADMIN_PRINTER_NAMES_SUB_TITLE = getString(
	'ADMIN_PRINTER_NAMES_SUB_TITLE'
)
export const ADMIN_PRINTER_NAMES_TABLE_TEXTS = getString(
	'ADMIN_PRINTER_NAMES_TABLE_TEXTS'
)
export const ADMIN_PRINTER_NAMES_UPDATE_ERROR = getString(
	'ADMIN_PRINTER_NAMES_UPDATE_ERROR'
)
export const ADMIN_PRINTER_NAMES_UPDATE_SUCCESS = getString(
	'ADMIN_PRINTER_NAMES_UPDATE_SUCCESS'
)
export const ADMIN_PRINTERS_ADDED_SUCCESS = getString(
	'ADMIN_PRINTERS_ADDED_SUCCESS'
)
export const ADMIN_PRINTERS_EDIT = getString('ADMIN_PRINTERS_EDIT')
export const ADMIN_PRINTERS_ERROR = getString('ADMIN_PRINTERS_ERROR')
export const ADMIN_PRINTERS_NEW = getString('ADMIN_PRINTERS_NEW')
export const ADMIN_PRINTERS_NEW_ERROR = getString('ADMIN_PRINTERS_NEW_ERROR')
export const ADMIN_PRINTERS_NEW_PRINTER = getString(
	'ADMIN_PRINTERS_NEW_PRINTER'
)
export const ADMIN_PRINTERS_NO_PRINTERS_FOUND = getString(
	'ADMIN_PRINTERS_NO_PRINTERS_FOUND'
)
export const ADMIN_PRINTERS_PAGE_HEADER = getString(
	'ADMIN_PRINTERS_PAGE_HEADER'
)
export const ADMIN_PRINTERS_PARAMS = getString('ADMIN_PRINTERS_PARAMS')

export const USER_PRINTERS_ADDED_SUCCESS = getString(
	'USER_PRINTERS_ADDED_SUCCESS'
)
export const USER_PRINTERS_NAME_ALREADY_EXIST_ERROR = getString(
	'USER_PRINTERS_NAME_ALREADY_EXIST_ERROR'
)
export const USER_PRINTERS_UPDATE_ERROR = getString(
	'USER_PRINTERS_UPDATE_ERROR'
)
export const ADMIN_PRINTERS_SUB_TITLE = getString('ADMIN_PRINTERS_SUB_TITLE')
export const ADMIN_PRINTERS_TABLE_TEXTS = getString(
	'ADMIN_PRINTERS_TABLE_TEXTS'
)
export const ADMIN_PRINTERS_UPDATE_ERROR = getString(
	'ADMIN_PRINTERS_UPDATE_ERROR'
)
export const ADMIN_PRINTERS_NAME_ALREADY_EXIST_ERROR = getString(
	'ADMIN_PRINTERS_NAME_ALREADY_EXIST_ERROR'
)
export const ADMIN_PRINTERS_UPDATE_SUCCESS = getString(
	'ADMIN_PRINTERS_UPDATE_SUCCESS'
)
export const ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL = getString(
	'ADMIN_PROJECTS_ASSEMBLY_OR_INDIVIDUAL'
)
export const ADMIN_PROJECTS_COMPANY = getString('ADMIN_PROJECTS_COMPANY')
export const ADMIN_PROJECTS_CREATED_AT = getString('ADMIN_PROJECTS_CREATED_AT')
export const ADMIN_PROJECTS_PUBLISHED_AT = getString(
	'ADMIN_PROJECTS_PUBLISHED_AT'
)
export const ADMIN_PROJECTS_DEFAULT_MATERIAL = getString(
	'ADMIN_PROJECTS_DEFAULT_MATERIAL'
)
export const ADMIN_PROJECTS_EMAIL = getString('ADMIN_PROJECTS_EMAIL')
export const ADMIN_PROJECTS_EXTERNAL_ASSEMBLY_FILE_ID = getString(
	'ADMIN_PROJECTS_EXTERNAL_ASSEMBLY_FILE_ID'
)
export const ADMIN_PROJECTS_ID = getString('ADMIN_PROJECTS_ID')
export const ADMIN_PROJECTS_MATERIAL_OR_BOM = getString(
	'ADMIN_PROJECTS_MATERIAL_OR_BOM'
)
export const ADMIN_PROJECTS_NAME = getString('ADMIN_PROJECTS_NAME')
export const ADMIN_PROJECTS_PARTS_IN_PROJECT = getString(
	'ADMIN_PROJECTS_PARTS_IN_PROJECT'
)
export const PRODUCT_LIFE_CYCLE = getString('PRODUCT_LIFE_CYCLE')
export const STANDARD_COST = getString('STANDARD_COST')
export const MANUFACTURING_METHOD_TEXT = getString('MANUFACTURING_METHOD_TEXT')
export const AM_VALUE_TEXT = getString('AM_VALUE_TEXT')
export const ADMIN_PROJECTS_STATUS = getString('ADMIN_PROJECTS_STATUS')

export const ADMIN_PROJECTS_FILTER_NAME = getString(
	'ADMIN_PROJECTS_FILTER_NAME'
)
export const ADMIN_PROJECTS_FILTER_NOT_PUBLISHED = getString(
	'ADMIN_PROJECTS_FILTER_NOT_PUBLISHED'
)
export const ADMIN_PROJECTS_FILTER_NOT_QA = getString(
	'ADMIN_PROJECTS_FILTER_NOT_QA'
)
export const ADMIN_PROJECTS_FILTER_NOT_ADMIN = getString(
	'ADMIN_PROJECTS_FILTER_NOT_ADMIN'
)

export const ADMIN_TABLE_ERROR = getString('ADMIN_TABLE_ERROR')
export const ADMIN_TABLE_NO_DATA_FOUND = getString('ADMIN_TABLE_NO_DATA_FOUND')
export const ADMIN_UNIFICATIONS_ERROR = getString('ADMIN_UNIFICATIONS_ERROR')
export const ADMIN_UNIFICATIONS_NO_UNIFICATIONS_FOUND = getString(
	'ADMIN_UNIFICATIONS_NO_UNIFICATIONS_FOUND'
)
export const ADMIN_UNIFICATIONS_PAGE_HEADER = getString(
	'ADMIN_UNIFICATIONS_PAGE_HEADER'
)
export const ADMIN_USER_INFO_EDIT_HEADER = getString(
	'ADMIN_USER_INFO_EDIT_HEADER'
)
export const ADMIN_USER_INFO_EDIT_SUB_HEADER = getString(
	'ADMIN_USER_INFO_EDIT_SUB_HEADER'
)
export const ADMIN_USERS_ADMIN_USER = getString('ADMIN_USERS_ADMIN_USER')
export const ADMIN_USERS_CHARGED = getString('ADMIN_USERS_CHARGED')
export const ADMIN_USERS_CUSTOMIZE_USER = getString(
	'ADMIN_USERS_CUSTOMIZE_USER'
)
export const ADMIN_USERS_CUSTOMIZE_USER_ADDED = getString(
	'ADMIN_USERS_CUSTOMIZE_USER_ADDED'
)
export const ADMIN_USERS_ERROR = getString('ADMIN_USERS_ERROR')
export const ADMIN_USERS_IS_TRIAL = getString('ADMIN_USERS_IS_TRIAL')
export const ADMIN_USERS_NO_USERS_FOUND = getString(
	'ADMIN_USERS_NO_USERS_FOUND'
)
export const ADMIN_USERS_PAGE_HEADER = getString('ADMIN_USERS_PAGE_HEADER')
export const ADMIN_USERS_SCANNED = getString('ADMIN_USERS_SCANNED')
export const ADMIN_USERS_SORT_SUBSCRIPTION = getString(
	'ADMIN_USERS_SORT_SUBSCRIPTION'
)
export const ADMIN_USERS_SUBSCRIPTION_ENDS = getString(
	'ADMIN_USERS_SUBSCRIPTION_ENDS'
)
export const ADMIN_USERS_TRIAL_ENDS = getString('ADMIN_USERS_TRIAL_ENDS')
export const ADMIN_USERS_VIEW_PROJECTS = getString('ADMIN_USERS_VIEW_PROJECTS')
export const ADMIN_USERS_VIEW_USER_INFO = getString(
	'ADMIN_USERS_VIEW_USER_INFO'
)
export const ADVANCED_MATERIAL_SETTINGS = getString(
	'ADVANCED_MATERIAL_SETTINGS'
)
export const ADVANCED_SETTINGS = getString('ADVANCED_SETTINGS')
export const ALL = getString('ALL')
export const AM_PRINTING_CHART_INDEX = getString('AM_PRINTING_CHART_INDEX')
export const AMOUNT_FIELD_TECH_ANALYSIS = getString(
	'AMOUNT_FIELD_TECH_ANALYSIS'
)
export const ARE = getString('ARE')
export const ARE_YOU_SURE_DELETE = getString('ARE_YOU_SURE_DELETE')
export const ASSEMBLY = getString('ASSEMBLY')
export const ASSEMBLY_DROP_FILE_EXPLANATION = getString(
	'ASSEMBLY_DROP_FILE_EXPLANATION'
)
export const BANNER_PARTS_END_MSG = getString('BANNER_PARTS_END_MSG')
export const BANNER_PROJECT_QUEUED_TO_RECALCULATE = getString(
	'BANNER_PROJECT_QUEUED_TO_RECALCULATE'
)
export const BOM = getString('BOM')
export const BOM_RAIDO_TEXT_ON_UPLOAD = getString('BOM_RAIDO_TEXT_ON_UPLOAD')
export const BUY_FULL_VERSION = getString('BUY_FULL_VERSION')
export const CAD_UNIT_TYPES = getString('CAD_UNIT_TYPES')
export const CANCEL = getString('CANCEL')
export const EXPORT_FILE_NAME = getString('EXPORT_FILE_NAME')

export const START_DATE = getString('START_DATE')
export const END_DATE = getString('END_DATE')

export const SELECT_START_AND_END_DATES = getString(
	'SELECT_START_AND_END_DATES'
)

export const CELSIUS_SIGN = getString('CELSIUS_SIGN')
export const CHANGE_QUANTITY = getString('CHANGE_QUANTITY')
export const CHART_LABEL_INPROPER_CAD = getString('CHART_LABEL_INPROPER_CAD')
export const CHART_LABEL_OFFTHESHELF = getString('CHART_LABEL_OFFTHESHELF')
export const CHART_LABEL_SIZE_OUT_OF_BOUND = getString(
	'CHART_LABEL_SIZE_OUT_OF_BOUND'
)
export const CHART_LABEL_WALL_THICKNESS = getString(
	'CHART_LABEL_WALL_THICKNESS'
)
export const CHOOSE_MATERIAL_ON_UPLOAD_EXPLINATION = getString(
	'CHOOSE_MATERIAL_ON_UPLOAD_EXPLINATION'
)
export const CHOOSE_MATERIAL_ON_UPLOAD_EXPLANATION_ASSEMBLY = getString(
	'CHOOSE_MATERIAL_ON_UPLOAD_EXPLANATION_ASSEMBLY'
)
export const CLUSTER_STRUCTURE = getString('CLUSTER_STRUCTURE')
export const COMBINE_MULTIPLE_INTO_ONE_CARD_REQ_TXT = getString(
	'COMBINE_MULTIPLE_INTO_ONE_CARD_REQ_TXT'
)
export const COMBINE_MULTIPLE_INTO_ONE_CARD_TITLE = getString(
	'COMBINE_MULTIPLE_INTO_ONE_CARD_TITLE'
)
export const COMBINE_MULTIPLE_INTO_ONE_REQ_BODY = getString(
	'COMBINE_MULTIPLE_INTO_ONE_REQ_BODY'
)
export const COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE = getString(
	'COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE'
)
export const COMBINE_MULTIPLE_NUMBER_OF_PARTS = getString(
	'COMBINE_MULTIPLE_NUMBER_OF_PARTS'
)
export const COMBINE_MULTIPLE_PARTS_INCLUDED = getString(
	'COMBINE_MULTIPLE_PARTS_INCLUDED'
)
export const COMBINE_MULTIPLE_SUGGESTION = getString(
	'COMBINE_MULTIPLE_SUGGESTION'
)
export const COMBINE_MULTIPLE_SUGGESTION_NUMBER = getString(
	'COMBINE_MULTIPLE_SUGGESTION_NUMBER'
)
export const COMPARE = getString('COMPARE')
export const COMPLETE_FAST_UPLOAD_SUCCESS_MSG = getString(
	'COMPLETE_FAST_UPLOAD_SUCCESS_MSG'
)
export const COMPLETE_FAST_UPLOAD_SUCCESS_MSG_LIGHT_USER = getString(
	'COMPLETE_FAST_UPLOAD_SUCCESS_MSG_LIGHT_USER'
)

export const COMPLETE_FAST_UPLOAD_SUCCESS_TITLE = getString(
	'COMPLETE_FAST_UPLOAD_SUCCESS_TITLE'
)
export const COMPLETE_UPLOAD_FAILED_MSG = getString(
	'COMPLETE_UPLOAD_FAILED_MSG'
)
export const COMPLETE_UPLOAD_FAILED_TITLE = getString(
	'COMPLETE_UPLOAD_FAILED_TITLE'
)
export const COMPLETE_UPLOAD_SUCCESS_MSG = getString(
	'COMPLETE_UPLOAD_SUCCESS_MSG'
)
export const COMPLETE_UPLOAD_SUCCESS_TITLE = getString(
	'COMPLETE_UPLOAD_SUCCESS_TITLE'
)
export const CONFIGURATION_CALCULATION_ERROR = getString(
	'CONFIGURATION_CALCULATION_ERROR'
)
export const CONFIGURATION_CALCULATION_LONG_TIME = getString(
	'CONFIGURATION_CALCULATION_LONG_TIME'
)
export const CONFIGURATION_INHOUSE_ALERT_TEXT = getString(
	'CONFIGURATION_INHOUSE_ALERT_TEXT'
)
export const CONFIGURATION_MESH_HEALING_CONFIRM = getString(
	'CONFIGURATION_MESH_HEALING_CONFIRM'
)
export const CONFIGURATION_MESH_HEALING_EXPLENATION = getString(
	'CONFIGURATION_MESH_HEALING_EXPLENATION'
)
export const CONFIGURATION_MESH_HEALING_OPERATED_EXPLENATION = getString(
	'CONFIGURATION_MESH_HEALING_OPERATED_EXPLENATION'
)
export const CONFIGURATION_MESH_HEALING_OPERATED_TITLE = getString(
	'CONFIGURATION_MESH_HEALING_OPERATED_TITLE'
)
export const CONFIGURATION_RESULT_BORDERLINE = getString(
	'CONFIGURATION_RESULT_BORDERLINE'
)
export const CONFIGURATION_RESULT_NOT_PRINTABLE = getString(
	'CONFIGURATION_RESULT_NOT_PRINTABLE'
)
export const CONFIGURATION_RESULT_PRINTABLE = getString(
	'CONFIGURATION_RESULT_PRINTABLE'
)
export const CONFIRM = getString('CONFIRM')
export const CONNECT_CASTOR = getString('CONNECT_CASTOR')
export const CONNECT_CASTOR_POPUP_HEADER = getString(
	'CONNECT_CASTOR_POPUP_HEADER'
)
export const CONNECT_CASTOR_POPUP_RADIO_HEADER = getString(
	'CONNECT_CASTOR_POPUP_RADIO_HEADER'
)
export const COST_AND_LEAD_ACTION_BUTTON_CANCEL_TEXT = getString(
	'COST_AND_LEAD_ACTION_BUTTON_CANCEL_TEXT'
)
export const COST_AND_LEAD_ACTION_BUTTON_DEFAULT_TEXT = getString(
	'COST_AND_LEAD_ACTION_BUTTON_DEFAULT_TEXT'
)
export const COST_AND_LEAD_ACTION_BUTTON_EDIT_TEXT = getString(
	'COST_AND_LEAD_ACTION_BUTTON_EDIT_TEXT'
)
export const COST_AND_LEAD_APPLY_CHANGES = getString(
	'COST_AND_LEAD_APPLY_CHANGES'
)
export const COST_AND_LEAD_APPLY_CHANGES_ALERT = getString(
	'COST_AND_LEAD_APPLY_CHANGES_ALERT'
)
export const COST_AND_LEAD_BUTTON_ACTIONS = getString(
	'COST_AND_LEAD_BUTTON_ACTIONS'
)
export const COST_AND_LEAD_EDIT_ALL = getString('COST_AND_LEAD_EDIT_ALL')
export const COST_AND_LEAD_LABELS = getString('COST_AND_LEAD_LABELS')
export const COST_AND_LEAD_LABELS_TEXTS = getString(
	'COST_AND_LEAD_LABELS_TEXTS'
)
export const COST_AND_LEAD_PROPERTY_NAME = getString(
	'COST_AND_LEAD_PROPERTY_NAME'
)
export const COST_AND_LEAD_VALUE = getString('COST_AND_LEAD_VALUE')
export const COST_ESTIMATE_INFO = getString('COST_ESTIMATE_INFO')
export const COST_ESTIMATE_TITLE_INFO = getString('COST_ESTIMATE_TITLE_INFO')
export const COST_ESTIMATE_WARNING = getString('COST_ESTIMATE_WARNING')
export const CUSTOM = getString('CUSTOM')
export const CUSTOMIZE = getString('CUSTOMIZE')
export const CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_COST_AND_LEAD_TITLE = getString(
	'CUSTOMIZE_COST_AND_LEAD_TITLE'
)
export const CUSTOMIZE_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_EXPLINATION_1_HEADER = getString(
	'CUSTOMIZE_EXPLINATION_1_HEADER'
)
export const CUSTOMIZE_FORM_COST_AND_LEAD_HEADER = getString(
	'CUSTOMIZE_FORM_COST_AND_LEAD_HEADER'
)
export const CUSTOMIZE_FORM_HEADER = getString('CUSTOMIZE_FORM_HEADER')
export const CUSTOMIZE_FORM_TAGS_HEADER = getString(
	'CUSTOMIZE_FORM_TAGS_HEADER'
)
export const CUSTOMIZE_FORM_USER_MATERIAL_HEADER = getString(
	'CUSTOMIZE_FORM_USER_MATERIAL_HEADER'
)
export const CUSTOMIZE_FORM_USER_NEW_MATERIAL_HEADER = getString(
	'CUSTOMIZE_FORM_USER_NEW_MATERIAL_HEADER'
)
export const CUSTOMIZE_FORM_USER_PRINTER_MATERIAL_HEADER = getString(
	'CUSTOMIZE_FORM_USER_PRINTER_MATERIAL_HEADER'
)
export const CUSTOMIZE_FORM_USER_PRINTER_HEADER = getString(
	'CUSTOMIZE_FORM_USER_PRINTER_HEADER'
)
export const CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_RECALCULATE_PROJECTS_EXPLANATION = getString(
	'CUSTOMIZE_RECALCULATE_PROJECTS_EXPLANATION'
)
export const CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE = getString(
	'CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE'
)
export const CUSTOMIZE_RECALCULATE_PROJECTS_TITLE = getString(
	'CUSTOMIZE_RECALCULATE_PROJECTS_TITLE'
)
export const CUSTOMIZE_MATERIAL_COST_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_MATERIAL_COST_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_MATERIAL_COST_TITLE = getString(
	'CUSTOMIZE_MATERIAL_COST_TITLE'
)
export const CUSTOMIZE_GEOMETRY_ANALYSIS_TITLE = getString(
	'CUSTOMIZE_GEOMETRY_ANALYSIS_TITLE'
)
export const CUSTOMIZE_GEOMETRY_ANALYSIS_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_GEOMETRY_ANALYSIS_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_OFF_THE_SHELF_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_OFF_THE_SHELF_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_OFF_THE_SHELF_TITLE = getString(
	'CUSTOMIZE_OFF_THE_SHELF_TITLE'
)
export const CUSTOMIZE_USER_MATERIAL_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_USER_MATERIAL_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_SETTINGS_MATERIAL_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_SETTINGS_MATERIAL_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_EXPLANATION_ARRAY =
	getString('CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_EXPLANATION_ARRAY')

export const CUSTOMIZE_USER_3D_PRINTERS_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_USER_3D_PRINTERS_EXPLANATION_ARRAY'
)
export const CUSTOMIZE_USER_MATERIAL_TITLE = getString(
	'CUSTOMIZE_USER_MATERIAL_TITLE'
)
export const CUSTOMIZE_TRADITIONAL_MANUFACTURING_MATERIALS_TITLE = getString(
	'CUSTOMIZE_TRADITIONAL_MANUFACTURING_MATERIALS_TITLE'
)
export const CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_TITLE = getString(
	'CUSTOMIZE_ADDITIVE_MANUFACTURING_MATERIALS_TITLE'
)
export const CUSTOMIZE_3D_PRINTERS_TITLE = getString(
	'CUSTOMIZE_3D_PRINTERS_TITLE'
)
export const CUSTOMIZE_USER_SEMI_MATERIAL_EXPLANATION_ARRAY = getString(
	'CUSTOMIZE_USER_SEMI_MATERIAL_EXPLANATION_ARRAY'
)
export const USER_SETTINGS_DEFAULT_OBJECTIVES_TITLE = getString(
	'USER_SETTINGS_DEFAULT_OBJECTIVES_TITLE'
)
export const USER_SETTINGS_DEFAULT_OBJECTIVES_ARRAY = getString(
	'USER_SETTINGS_DEFAULT_OBJECTIVES_ARRAY'
)
export const CUSTOMIZE_USER_SEMI_MATERIAL_TITLE = getString(
	'CUSTOMIZE_USER_SEMI_MATERIAL_TITLE'
)
export const CUSTOMIZE_USER_SEMI_MATERIAL_TITLE_FOR_ADMIN = getString(
	'CUSTOMIZE_USER_SEMI_MATERIAL_TITLE_FOR_ADMIN'
)
export const CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM = getString(
	'CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM'
)
export const CUSTOMIZE_PRINTER_SETTINGS_ACTION = getString(
	'CUSTOMIZE_PRINTER_SETTINGS_ACTION'
)

export const PRINTER_SETTING_KG_PRICE = getString('PRINTER_SETTING_KG_PRICE')
export const PRINTER_SETTING_NAME = getString('PRINTER_SETTING_NAME')

export const PRINTER_SETTING_PRICE = getString('PRINTER_SETTING_PRICE_IN_USD')
export const PRINTER_SETTING_LAYER_THICKNESS = getString(
	'PRINTER_SETTING_LAYER_THICKNESS'
)

export const DELETE_ITEM = getString('DELETE_ITEM')
export const DO_NOT_FIT_TRAY = getString('DO_NOT_FIT_TRAY')
export const DOWNLOAD = getString('DOWNLOAD')
export const DOWNLOAD_ASSEMBLY = getString('DOWNLOAD_ASSEMBLY')
export const DOWNLOAD_PART = getString('DOWNLOAD_PART')
export const DROPZONE_HAS_FILES = getString('DROPZONE_HAS_FILES')
export const DROPZONE_NO_FILES = getString('DROPZONE_NO_FILES')
export const EDIT = getString('EDIT')
export const ADVANCED = getString('ADVANCED')
export const EDIT_PART = getString('EDIT_PART')
export const EDIT_PROJECT = getString('EDIT_PROJECT')
export const EMAIL_INVALID = getString('EMAIL_INVALID')
export const EMAIL_NOT_ENTERED = getString('EMAIL_NOT_ENTERED')
export const ENTER_PART_ANALYSIS_EXPLINATION_ARRAY = getString(
	'ENTER_PART_ANALYSIS_EXPLINATION_ARRAY'
)
export const ERROR = getString('ERROR')
export const ERROR_UPDATING_TAGS = getString('ERROR_UPDATING_TAGS')
export const ESTIMATED_PRICE_LABEL = getString('ESTIMATED_PRICE_LABEL')
export const EXIT = getString('EXIT')
export const EXPLODE_PROJECT = getString('EXPLODE_PROJECT')
export const EXPORT = getString('EXPORT')
export const EXPORT_AS_CSV = getString('EXPORT_AS_CSV')
export const EXPORT_AS_PDF = getString('EXPORT_AS_PDF')
export const EXTEND_SUBSCRIPTION = getString('EXTEND_SUBSCRIPTION')
export const FAILED_PROCESSING_PART_ALERT_TITLE = getString(
	'FAILED_PROCESSING_PART_ALERT_TITLE'
)
export const FILE_EXPLANATION_ARRAY = getString('FILE_EXPLANATION_ARRAY')
export const GENERATIVE_FILE_EXPLANATION_ARRAY = getString(
	'GENERATIVE_FILE_EXPLANATION_ARRAY'
)
export const FINANCIAL_COST_AFFECTIVE_LABEL = getString(
	'FINANCIAL_COST_AFFECTIVE_LABEL'
)
export const FINANCIAL_COUNT_LABEL_1 = getString('FINANCIAL_COUNT_LABEL_1')
export const FINANCIAL_COUNT_LABEL_2 = getString('FINANCIAL_COUNT_LABEL_2')
export const FINANCIAL_COUNT_LABEL_3 = getString('FINANCIAL_COUNT_LABEL_3')
export const FINANCIAL_COUNT_LABEL_4 = getString('FINANCIAL_COUNT_LABEL_4')
export const FINANCIAL_EDIT_EXPLANATION = getString(
	'FINANCIAL_EDIT_EXPLANATION'
)
export const FINANCIAL_EDIT_EXPLINATION_CAST = getString(
	'FINANCIAL_EDIT_EXPLINATION_CAST'
)
export const FINANCIAL_EDIT_EXPLINATION_INVESTMENT_CAST = getString(
	'FINANCIAL_EDIT_EXPLINATION_INVESTMENT_CAST'
)
export const FINANCIAL_EDIT_EXPLINATION_CNC = getString(
	'FINANCIAL_EDIT_EXPLINATION_CNC'
)
export const FINANCIAL_EDIT_MANUFACTURING_METHOD = getString(
	'FINANCIAL_EDIT_MANUFACTURING_METHOD'
)
export const FORGOT_PASSWORD = getString('FORGOT_PASSWORD')
export const FORGOT_PASSWORD_POPUP_BODY = getString(
	'FORGOT_PASSWORD_POPUP_BODY'
)
export const FORGOT_PASSWORD_POPUP_TITLE = getString(
	'FORGOT_PASSWORD_POPUP_TITLE'
)
export const GENERAL_DROP_FILE_EXPLANATION = getString(
	'GENERAL_DROP_FILE_EXPLANATION'
)
export const GENERAL_DROP_FILE_EXPLANATION_SINGLE_AND_BOM = getString(
	'GENERAL_DROP_FILE_EXPLANATION_SINGLE_AND_BOM'
)
export const GENERAL_PROPERTIES = getString('GENERAL_PROPERTIES')
export const BATCH_SIZE = getString('BATCH_SIZE')
export const GENERIC_ERROR_MESSAGE = getString('GENERIC_ERROR_MESSAGE')
export const GO = getString('GO')
export const GO_TO_SETTINGS = getString('GO_TO_SETTINGS')
export const GRAM_UNITS = getString('GRAM_UNITS')
export const HEATMAP_SWITCH_TITLE = getString('HEATMAP_SWITCH_TITLE')
export const HIGH_ACCURACY_ALERT_TEXT = getString('HIGH_ACCURACY_ALERT_TEXT')
export const HIGH_ACCURACY_ALERT_TITLE = getString('HIGH_ACCURACY_ALERT_TITLE')
export const ID = getString('ID')
export const IMPORTANT = getString('IMPORTANT')
export const IN_HOUSE_SWITCH_TITLE = getString('IN_HOUSE_SWITCH_TITLE')
export const INCLUDES_POST_PROCESS = getString('INCLUDES_POST_PROCESS')
export const INDEX = getString('INDEX')
export const IS = getString('IS')
export const ITEM_ALLREADY_EXIST = getString('ITEM_ALLREADY_EXIST')
export const KG_UNITS = getString('KG_UNITS')
export const LEAD_TIME_LABEL = getString('LEAD_TIME_LABEL')
export const LEAD_TIME_TEXT = getString('LEAD_TIME_TEXT')
export const LEAD_TIME_TITLE_INFO = getString('LEAD_TIME_TITLE_INFO')
export const LEARN_HOW = getString('LEARN_HOW')
export const WARN_BOUNDING_BOX = getString('WARN_BOUNDING_BOX')
export const LOADING_MESSAGE_PART_TECH_ANALYSIS = getString(
	'LOADING_MESSAGE_PART_TECH_ANALYSIS'
)
export const LOCKED_PARTS_BANNER = getString('LOCKED_PARTS_BANNER')
export const LOCKED_PARTS_BANNER_6 = getString('LOCKED_PARTS_BANNER_6')
export const LOCKED_PARTS_BANNER_7 = getString('LOCKED_PARTS_BANNER_7')
export const LOCKED_PARTS_CARD_SENT_REQ_BUTTON_TEXT = getString(
	'LOCKED_PARTS_CARD_SENT_REQ_BUTTON_TEXT'
)
export const LOGIN_EMAIL_PLACEHOLDER = getString('LOGIN_EMAIL_PLACEHOLDER')
export const LOGIN_PASSWORD_PLACEHOLDER = getString(
	'LOGIN_PASSWORD_PLACEHOLDER'
)
export const LOGIN_REGISTER_BUT = getString('LOGIN_REGISTER_BUT')
export const LOGIN_REGISTER_HEADER = getString('LOGIN_REGISTER_HEADER')
export const LOGIN_TITLE = getString('LOGIN_TITLE')
export const LOGOUT = getString('LOGOUT')
export const MAP_HEAT_TOGGLE_BUTTON_TEXT = getString(
	'MAP_HEAT_TOGGLE_BUTTON_TEXT'
)
export const MATERIAL_COMPARISON_ELONGATION_AT_BREAK = getString(
	'MATERIAL_COMPARISON_ELONGATION_AT_BREAK'
)
export const MATERIAL_COMPARISON_HEADER_COMPARISON = getString(
	'MATERIAL_COMPARISON_HEADER_COMPARISON'
)
export const MATERIAL_COMPARISON_HEADER_DEVIATION = getString(
	'MATERIAL_COMPARISON_HEADER_DEVIATION'
)
export const MATERIAL_COMPARISON_HEADER_ORIGINAL = getString(
	'MATERIAL_COMPARISON_HEADER_ORIGINAL'
)
export const MATERIAL_COMPARISON_HEADER_PRINTED = getString(
	'MATERIAL_COMPARISON_HEADER_PRINTED'
)
export const MATERIAL_COMPARISON_NAME = getString('MATERIAL_COMPARISON_NAME')
export const MATERIAL_COMPARISON_ORIGINAL_COLUMN_NAME = getString(
	'MATERIAL_COMPARISON_ORIGINAL_COLUMN_NAME'
)
export const MATERIAL_COMPARISON_PERCENT_COLUMN_NAME = getString(
	'MATERIAL_COMPARISON_PERCENT_COLUMN_NAME'
)
export const MATERIAL_COMPARISON_PRINTABLE_COLUMN_NAME = getString(
	'MATERIAL_COMPARISON_PRINTABLE_COLUMN_NAME'
)
export const MATERIAL_COMPARISON_YIELD_STENGTH = getString(
	'MATERIAL_COMPARISON_YIELD_STENGTH'
)
export const MATERIAL_COMPARISON_YOUNG_MODULES = getString(
	'MATERIAL_COMPARISON_YOUNG_MODULES'
)
export const MATERIAL_COST_ACTION = getString('MATERIAL_COST_ACTION')
export const MATERIAL_COST_DELETE_ITEM = getString('MATERIAL_COST_DELETE_ITEM')
export const MATERIAL_COST_FIELDS_ALERT = getString(
	'MATERIAL_COST_FIELDS_ALERT'
)
export const MATERIAL_COST_INPUTS_LBS_EXPLENATION = getString(
	'MATERIAL_COST_INPUTS_LBS_EXPLENATION'
)
export const MATERIAL_COST_INPUTS_TITLE = getString(
	'MATERIAL_COST_INPUTS_TITLE'
)
export const MATERIAL_COST_INPUTS_WEIGHT_UNIT = getString(
	'MATERIAL_COST_INPUTS_WEIGHT_UNIT'
)
export const MATERIAL_COST_KG_PRICE = getString('MATERIAL_COST_KG_PRICE')
export const MATERIAL_COST_LB_PRICE = getString('MATERIAL_COST_LB_PRICE')
export const MATERIAL_COST_PRICE = {
	[UnitSystem.metric]: MATERIAL_COST_KG_PRICE,
	[UnitSystem.imperial]: MATERIAL_COST_LB_PRICE
}
export const MATERIAL_COST_MATERIAL = getString('MATERIAL_COST_MATERIAL')
export const MATERIAL_COST_MATERIAL_NAME = getString(
	'MATERIAL_COST_MATERIAL_NAME'
)
export const MATERIAL_COST_PRICE_IN_USD = getString(
	'MATERIAL_COST_PRICE_IN_USD'
)
export const MATERIAL_COST_PRINTER_TECH = getString(
	'MATERIAL_COST_PRINTER_TECH'
)
export const MATERIAL_COST_TECHNOLOGY = getString('MATERIAL_COST_TECHNOLOGY')
export const MATERIAL_TABLE_TEXTS = getString('MATERIAL_TABLE_TEXTS')
export const PRINTER_MATERIAL_TABLE_TEXTS = getString(
	'PRINTER_MATERIAL_TABLE_TEXTS'
)
export const MATERIAL_TABLE_INFO = getString('MATERIAL_TABLE_INFO')
export const MATERIALS_COMPARISON = getString('MATERIALS_COMPARISON')
export const MESH_HEALING = getString('MESH_HEALING')
export const METRICS_TITLE = getString('METRICS_TITLE')
export const MISSING_BOM_OR_MATERIAL = getString('MISSING_BOM_OR_MATERIAL')
export const MISSING_CAD_FILES = getString('MISSING_CAD_FILES')
export const MISSING_CAD_NAME = getString('MISSING_CAD_NAME')
export const UPLOAD_UNIT_TYPE_EXPLENATION = getString(
	'UPLOAD_UNIT_TYPE_EXPLENATION'
)
export const MISSING_COMPANY_NAME = getString('MISSING_COMPANY_NAME')
export const MISSING_ERP_NAME = getString('MISSING_ERP_NAME')
export const MISSING_PROJECT_FILES = getString('MISSING_PROJECT_FILES')
export const MISSING_PROJECT_NAME = getString('MISSING_PROJECT_NAME')
export const MISSING_PROJECT_QUANTITY = getString('MISSING_PROJECT_QUANTITY')
export const MISSING_PROJECT_WRONG_QUANTITY = getString(
	'MISSING_PROJECT_WRONG_QUANTITY'
)
export const MISSING_PROJECT_UPLOAD_TYPE = getString(
	'MISSING_PROJECT_UPLOAD_TYPE'
)
export const MISSING_UNIT_TYPE = getString('MISSING_UNIT_TYPE')
export const MULTI_MATERIAL = getString('MULTI_MATERIAL')
export const MULTIPLE_FILES_ERROR = getString('MULTIPLE_FILES_ERROR')
export const MULTIPLE_PARTS_IN_PROJECT_WARNING = getString(
	'MULTIPLE_PARTS_IN_PROJECT_WARNING'
)
export const MUST_AGREE_TO_TURMS = getString('MUST_AGREE_TO_TURMS')
export const MUST_CHOOSE_ORGANIZATION = getString('MUST_CHOOSE_ORGANIZATION')
export const NAME_NOT_ENTERED = getString('NAME_NOT_ENTERED')
export const NAV_TITLE_CLUSTER_ANALYSIS = getString(
	'NAV_TITLE_CLUSTER_ANALYSIS'
)
export const NAV_TITLE_CUSTOMIZE_USER = getString('NAV_TITLE_CUSTOMIZE_USER')
export const NAV_TITLE_INPUT_PART = getString('NAV_TITLE_INPUT_PART')
export const NAV_TITLE_LOGIN = getString('NAV_TITLE_LOGIN')
export const NAV_TITLE_MY_PROJECTS = getString('NAV_TITLE_MY_PROJECTS')
export const NAV_TITLE_PART_ANALYSIS = getString('NAV_TITLE_PART_ANALYSIS')
export const NAV_TITLE_PROJECT_ANALYSIS = getString(
	'NAV_TITLE_PROJECT_ANALYSIS'
)
export const NAV_TITLE_PROJECT_UPLOAD = getString('NAV_TITLE_PROJECT_UPLOAD')
export const NAV_TITLE_HOME = getString('NAV_TITLE_HOME')
export const NAV_TITLE_PROJECTS = getString('NAV_TITLE_PROJECTS')
export const NAV_TITLE_ANALYSIS_CLUSTER = getString(
	'NAV_TITLE_ANALYSIS_CLUSTER'
)
export const NAV_TITLE_PROJECT_UPLOADING = getString(
	'NAV_TITLE_PROJECT_UPLOADING'
)
export const NAV_TITLE_REGISTER = getString('NAV_TITLE_REGISTER')
export const NAV_TITLE_USER_PROFILE = getString('NAV_TITLE_USER_PROFILE')
export const NEW = getString('NEW')
export const NEXT = getString('NEXT')
export const BACK = getString('BACK')
export const DONE = getString('DONE')
export const NO = getString('NO')
export const NO_CAD_FILE_ERROR = getString('NO_CAD_FILE_ERROR')
export const NO_INHOUSE_PRINTER_WAS_SET = getString(
	'NO_INHOUSE_PRINTER_WAS_SET'
)
export const NO_PDF_FILE_ERROR = getString('NO_PDF_FILE_ERROR')
export const NO_FOLDER_ERROR = getString('NO_FOLDER_ERROR')
export const NO_MATERIAL_FOR_PROJECT_ERROR = getString(
	'NO_MATERIAL_FOR_PROJECT_ERROR'
)
export const NO_RESULT_FOR_CONFIGURATION = getString(
	'NO_RESULT_FOR_CONFIGURATION'
)
export const MATERIAL = getString('MATERIAL')
export const NO_USER_MATERIALS_MSG = getString('NO_USER_MATERIALS_MSG')
export const NO_USER_PRINTERS_MSG = getString('NO_USER_PRINTERS_MSG')
export const NON_ASSEMBLY_DROP_FILE_EXPLANATION = getString(
	'NON_ASSEMBLY_DROP_FILE_EXPLANATION'
)
export const NOT_ASSEMBLY_FORMAT_ERROR = getString('NOT_ASSEMBLY_FORMAT_ERROR')
export const NOT_FOLDER_ERROR = getString('NOT_FOLDER_ERROR')
export const NOT_PART_FORMAT_ERROR = getString('NOT_PART_FORMAT_ERROR')
export const ACCEPTED_FORMAT_ERROR = getString('ACCEPTED_FORMAT_ERROR')
export const OFF_THE_SHELF_ALERT_TEXT = getString('OFF_THE_SHELF_ALERT_TEXT')
export const OFF_THE_SHELF_ALERT_TITLE = getString('OFF_THE_SHELF_ALERT_TITLE')
export const OFF_THE_SHELF_PART_ALERT_TEXT = getString(
	'OFF_THE_SHELF_PART_ALERT_TEXT'
)
export const OFF_THE_SHELF_PART_ALERT_TITLE = getString(
	'OFF_THE_SHELF_PART_ALERT_TITLE'
)
export const OFF_THE_SHELF_SELECTOR_ITEMS = getString(
	'OFF_THE_SHELF_SELECTOR_ITEMS'
)
export const OK = getString('OK')
export const ADDRESS = getString('ADDRESS')
export const ORIGINAL_MATERIALS_BUTTON = getString('ORIGINAL_MATERIALS_BUTTON')
export const MAINTENANCE = getString('MAINTENANCE')
export const OWNER_ID = getString('OWNER_ID')
export const OWNER_INVALID = getString('OWNER_INVALID')
export const OWNER_NAME = getString('OWNER_NAME')
export const PART = getString('PART')
export const PART_ANALYSIS_3D_VIEW_TITLE = getString(
	'PART_ANALYSIS_3D_VIEW_TITLE'
)
export const PART_ANALYSIS_ADD_CONFIGURATION_BUTTON = getString(
	'PART_ANALYSIS_ADD_CONFIGURATION_BUTTON'
)
export const ADD_NEW_CONFIGURATION = getString('ADD_NEW_CONFIGURATION')
export const PART_ANALYSIS_ADD_FEATURES = getString(
	'PART_ANALYSIS_ADD_FEATURES'
)
export const PART_ANALYSIS_ADD_FEATURES_FILTERS = getString(
	'PART_ANALYSIS_ADD_FEATURES_FILTERS'
)
export const PART_ANALYSIS_ADD_POST_PROCESS_HEADER = getString(
	'PART_ANALYSIS_ADD_POST_PROCESS_HEADER'
)
export const PART_ANALYSIS_BEST_MATCH = getString('PART_ANALYSIS_BEST_MATCH')
export const PART_ANALYSIS_CONFIGURE = getString('PART_ANALYSIS_CONFIGURE')
export const PART_ANALYSIS_CONFIGURE_BACK = getString(
	'PART_ANALYSIS_CONFIGURE_BACK'
)
export const PART_ANALYSIS_CONFIGURE_CALCULATE = getString(
	'PART_ANALYSIS_CONFIGURE_CALCULATE'
)
export const PART_ANALYSIS_CONFIGURE_FEATURE = getString(
	'PART_ANALYSIS_CONFIGURE_FEATURE'
)
export const PART_ANALYSIS_CONFIGURE_FILTER_FEATURES = getString(
	'PART_ANALYSIS_CONFIGURE_FILTER_FEATURES'
)
export const PART_ANALYSIS_CONFIGURE_FILTER_TITLE = getString(
	'PART_ANALYSIS_CONFIGURE_FILTER_TITLE'
)
export const PART_ANALYSIS_CONFIGURE_FILTER_TITLE_HOVER = getString(
	'PART_ANALYSIS_CONFIGURE_FILTER_TITLE_HOVER'
)
export const PART_ANALYSIS_CONFIGURE_MAX_MIN = getString(
	'PART_ANALYSIS_CONFIGURE_MAX_MIN'
)
export const PART_ANALYSIS_CONFIGURE_PRIORITIES_FEATURES = getString(
	'PART_ANALYSIS_CONFIGURE_PRIORITIES_FEATURES'
)
export const PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE = getString(
	'PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE'
)
export const MATERIAL_PROPERTIES_IMPORTANCE = getString(
	'MATERIAL_PROPERTIES_IMPORTANCE'
)
export const PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE_HOVER = getString(
	'PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE_HOVER'
)
export const PART_ANALYSIS_CONFIGURE_RESET = getString(
	'PART_ANALYSIS_CONFIGURE_RESET'
)
export const PART_ANALYSIS_COST_COMPARISON_ACCURACY = getString(
	'PART_ANALYSIS_COST_COMPARISON_ACCURACY'
)
export const PART_ANALYSIS_COST_COMPARISON_CAM_EXISTENCE = getString(
	'PART_ANALYSIS_COST_COMPARISON_CAM_EXISTENCE'
)
export const PART_ANALYSIS_COST_COMPARISON_COMPLEXITY = getString(
	'PART_ANALYSIS_COST_COMPARISON_COMPLEXITY'
)
export const PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE = getString(
	'PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE'
)
export const PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE_INFO = getString(
	'PART_ANALYSIS_COST_COMPARISON_OPERATION_PRICE_INFO'
)
export const PART_ANALYSIS_COST_COMPARISON_PROGRAMING_PRICE = getString(
	'PART_ANALYSIS_COST_COMPARISON_PROGRAMING_PRICE'
)
export const PART_ANALYSIS_DIMENSIONS = getString('PART_ANALYSIS_DIMENSIONS')
export const PART_ANALYSIS_GETAQ = getString('PART_ANALYSIS_GETAQ')
export const PART_ANALYSIS_HEADER = getString('PART_ANALYSIS_HEADER')
export const PART_ANALYSIS_LED_WITH_TITLE_INDEXES = getString(
	'PART_ANALYSIS_LED_WITH_TITLE_INDEXES'
)
export const PART_ANALYSIS_NEW_SOLUTION_NAME = getString(
	'PART_ANALYSIS_NEW_SOLUTION_NAME'
)
export const PART_ANALYSIS_NON_PRINTABLE_BUTTON = getString(
	'PART_ANALYSIS_NON_PRINTABLE_BUTTON'
)
export const PART_ANALYSIS_NON_PRINTABLE_DESCRIPTION = getString(
	'PART_ANALYSIS_NON_PRINTABLE_DESCRIPTION'
)
export const PART_ANALYSIS_REMOVE = getString('PART_ANALYSIS_REMOVE')
export const PART_ANALYSIS_SAVE = getString('PART_ANALYSIS_SAVE')
export const PART_ANALYSIS_SAVED = getString('PART_ANALYSIS_SAVED')
export const PART_ANALYSIS_TABS_TITLE_ANALYSIS_RESULTS = getString(
	'PART_ANALYSIS_TABS_TITLE_ANALYSIS_RESULTS'
)
export const PART_ANALYSIS_TABS_TITLE_CLUSTER_PARTS = getString(
	'PART_ANALYSIS_TABS_TITLE_CLUSTER_PARTS'
)
export const PART_ANALYSIS_TABS_TITLE_COST_SUMMARY = getString(
	'PART_ANALYSIS_TABS_TITLE_COST_SUMMARY'
)
export const PART_ANALYSIS_TABS_TITLE_LEAD_TIME = getString(
	'PART_ANALYSIS_TABS_TITLE_LEAD_TIME'
)
export const PART_ANALYSIS_TABS_TITLE_COST_COMPARTION = getString(
	'PART_ANALYSIS_TABS_TITLE_COST_COMPARTION'
)
export const PART_ANALYSIS_TABS_TITLE_MATERIAL_COMPARTION = getString(
	'PART_ANALYSIS_TABS_TITLE_MATERIAL_COMPARTION'
)
export const PART_ANALYSIS_TOP_DETAILS_COST_ESTIMATION_POP_UP_ROWS = getString(
	'PART_ANALYSIS_TOP_DETAILS_COST_ESTIMATION_POP_UP_ROWS'
)
export const PART_ANALYSIS_TOP_DETAILS_NEW_CONFIGURATION = getString(
	'PART_ANALYSIS_TOP_DETAILS_NEW_CONFIGURATION'
)
export const PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION = getString(
	'PART_ANALYSIS_TOP_DETAILS_NO_SOLUTION'
)
export const PART_ANALYSIS_WALL_THICKNESS_ANALYZE = getString(
	'PART_ANALYSIS_WALL_THICKNESS_ANALYZE'
)
export const PART_COUNT_IN_ASSEMBLY = getString('PART_COUNT_IN_ASSEMBLY')
export const PART_DIMENSIONS = getString('PART_DIMENSIONS')
export const PART_FILE_NOT_IN_ENGLISH = getString('PART_FILE_NOT_IN_ENGLISH')
export const PART_FINANCIAL_ANALYSIS_AVARAGE_COST = getString(
	'PART_FINANCIAL_ANALYSIS_AVARAGE_COST'
)
export const PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE = getString(
	'PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE'
)
export const PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_METAL = getString(
	'PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_METAL'
)
export const PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_LEAD_METAL = getString(
	'PART_FINANCIAL_ANALYSIS_CARD_SUB_TITLE_LEAD_METAL'
)
export const PART_FINANCIAL_ANALYSIS_CARD_TITLE = getString(
	'PART_FINANCIAL_ANALYSIS_CARD_TITLE'
)
export const PART_FINANCIAL_ANALYSIS_DFM_COSTS_PLACEHOLDER = getString(
	'PART_FINANCIAL_ANALYSIS_DFM_COSTS_PLACEHOLDER'
)
export const PART_FINANCIAL_ANALYSIS_FORM_EXPLANATION = getString(
	'PART_FINANCIAL_ANALYSIS_FORM_EXPLANATION'
)
export const PART_FINANCIAL_ANALYSIS_INJECTION_MOLDING = getString(
	'PART_FINANCIAL_ANALYSIS_INJECTION_MOLDING'
)
export const PART_FINANCIAL_ANALYSIS_MINIMUM_ORDER_QUANTITY_PLACEHOLDER =
	getString('PART_FINANCIAL_ANALYSIS_MINIMUM_ORDER_QUANTITY_PLACEHOLDER')
export const PART_FINANCIAL_ANALYSIS_MOLD_MAINTENANCE_PRICE_PLACEHOLDER =
	getString('PART_FINANCIAL_ANALYSIS_MOLD_MAINTENANCE_PRICE_PLACEHOLDER')
export const PART_FINANCIAL_ANALYSIS_MOLD_PART_PRICE_PLACEHOLDER = getString(
	'PART_FINANCIAL_ANALYSIS_MOLD_PART_PRICE_PLACEHOLDER'
)
export const PART_FINANCIAL_ANALYSIS_MOLD_PRICE_PLACEHOLDER = getString(
	'PART_FINANCIAL_ANALYSIS_MOLD_PRICE_PLACEHOLDER'
)
export const PART_FINANCIAL_ANALYSIS_NUMBER_OF_PARTS_PRODUCED = getString(
	'PART_FINANCIAL_ANALYSIS_NUMBER_OF_PARTS_PRODUCED'
)
export const PART_FINANCIAL_ANALYSIS_PART_COUNT = getString(
	'PART_FINANCIAL_ANALYSIS_PART_COUNT'
)
export const PART_IS_OFF_THE_SHELF = getString('PART_IS_OFF_THE_SHELF')
export const PART_ORIGINAL_WEIGHT = getString('PART_ORIGINAL_WEIGHT')
export const PART_ORIGINAL_WEIGHT_GRAMS = getString(
	'PART_ORIGINAL_WEIGHT_GRAMS'
)
export const PART_REQUIREMENT_SPECIAL_REQUIREMENTS = getString(
	'PART_REQUIREMENT_SPECIAL_REQUIREMENTS'
)
export const PART_REQUIREMENT_SURFACE_FINISH = getString(
	'PART_REQUIREMENT_SURFACE_FINISH'
)
export const PART_REQUITEMENT_ACCURACY = getString('PART_REQUITEMENT_ACCURACY')
export const PART_RESULTS_BORDERLINE = getString('PART_RESULTS_BORDERLINE')
export const PART_RESULTS_CAD = getString('PART_RESULTS_CAD')
export const INTERNAL_CAVITIES = getString('INTERNAL_CAVITIES')
export const PART_RESULTS_CARD_SUB_HEADER = getString(
	'PART_RESULTS_CARD_SUB_HEADER'
)
export const PART_RESULTS_CLICK_FOR_MORE = getString(
	'PART_RESULTS_CLICK_FOR_MORE'
)
export const PART_RESULTS_MATERIAL = getString('PART_RESULTS_MATERIAL')
export const PART_RESULTS_ORIENTATION_STABILITY = getString(
	'PART_RESULTS_ORIENTATION_STABILITY'
)
export const PART_ORIENTATION_FAILED_REASON = getString(
	'PART_ORIENTATION_FAILED_REASON'
)
export const PART_RESULTS_NOT_PRINTABLE = getString(
	'PART_RESULTS_NOT_PRINTABLE'
)
export const COST_LEAD_PERCENTAGE_DRAWING_COST = getString(
	'COST_LEAD_PERCENTAGE_DRAWING_COST'
)
export const PART_RESULTS_OPTIONS = getString('PART_RESULTS_OPTIONS')
export const PART_RESULTS_PRINTABLE = getString('PART_RESULTS_PRINTABLE')
export const PART_RESULTS_SIZE = getString('PART_RESULTS_SIZE')
export const THREADS = getString('THREADS')
export const PART_RESULTS_WALL_THICKNESS = getString(
	'PART_RESULTS_WALL_THICKNESS'
)
export const PART_VOLUME = getString('PART_VOLUME')
export const PART_COMPLEXITY_SCORE = getString('PART_COMPLEXITY_SCORE')
export const RATE_3D = getString('RATE_3D')
export const PART_IS_COMPLEX = getString('PART_IS_COMPLEX')
export const PART_IS_NOT_COMPLEX = getString('PART_IS_NOT_COMPLEX')
export const PARTS = getString('PARTS')
export const PARTS_ADMIN_PERFORM_ACTION = getString(
	'PARTS_ADMIN_PERFORM_ACTION'
)
export const PARTS_BUNDLE_END_NOT_TRIAL = getString(
	'PARTS_BUNDLE_END_NOT_TRIAL'
)
export const PARTS_BUNDLE_END_TRIAL = getString('PARTS_BUNDLE_END_TRIAL')
export const PARTS_CREDIT_END_POPUP_HEADER = getString(
	'PARTS_CREDIT_END_POPUP_HEADER'
)
export const PASSWORD = getString('PASSWORD')
export const PASSWORD_INVALID = getString('PASSWORD_INVALID')
export const PASSWORD_NOT_ENTERED = getString('PASSWORD_NOT_ENTERED')
export const PASSWORD_NOT_MATCHING = getString('PASSWORD_NOT_MATCHING')
export const PASSWORD_STENGTH_EXPLINATION_BODY = getString(
	'PASSWORD_STENGTH_EXPLINATION_BODY'
)
export const PASSWORD_STENGTH_EXPLINATION_TITLE = getString(
	'PASSWORD_STENGTH_EXPLINATION_TITLE'
)
export const POLLER_REACHED_TIMEOUT = getString('POLLER_REACHED_TIMEOUT')
export const POLLER_CANNOT_CONNECT_TO_THE_SERVER = getString(
	'POLLER_CANNOT_CONNECT_TO_THE_SERVER'
)
export const POPUP_HOVER_COST_BENEFIT = getString('POPUP_HOVER_COST_BENEFIT')
export const POPUP_HOVER_TIME_BENEFIT = getString('POPUP_HOVER_TIME_BENEFIT')
export const POST_PROCESSES = getString('POST_PROCESSES')
export const POST_PROCESSES_GENERAL_TITLE = getString(
	'POST_PROCESSES_GENERAL_TITLE'
)
export const POWERED_BY = getString('POWERED_BY')
export const PRICE = getString('PRICE')
export const PRINTER_MATERIAL_PARAMS = getString('PRINTER_MATERIAL_PARAMS')
export const PRINTER_TABLE_TEXTS = getString('PRINTER_TABLE_TEXTS')
export const PRINTER_TABLE_INFO = getString('PRINTER_TABLE_INFO')
export const PRINTING_ORIENTATION_ALERT_CONFIRM_BUTTON_TEXT = getString(
	'PRINTING_ORIENTATION_ALERT_CONFIRM_BUTTON_TEXT'
)
export const PRINTING_ORIENTATION_ALERT_TEXTS = getString(
	'PRINTING_ORIENTATION_ALERT_TEXTS'
)
export const PRINTING_ORIENTATION_BUTTON_DEFAULT_TEXT = getString(
	'PRINTING_ORIENTATION_BUTTON_DEFAULT_TEXT'
)
export const PRINTING_ORIENTATION_BUTTON_TEXT = getString(
	'PRINTING_ORIENTATION_BUTTON_TEXT'
)
export const PRINTING_ORIENTATION_GENERAL_TITLE = getString(
	'PRINTING_ORIENTATION_GENERAL_TITLE'
)
export const PRINTING_ORIENTATION_NEW_CONF_MSG = getString(
	'PRINTING_ORIENTATION_NEW_CONF_MSG'
)
export const PRINTING_RECOMMENDATIONS_SUB_TITLE = getString(
	'PRINTING_RECOMMENDATIONS_SUB_TITLE'
)
export const PRINTING_RECOMMENDATIONS_TITLE = getString(
	'PRINTING_RECOMMENDATIONS_TITLE'
)
export const PRINTING_TECH = getString('PRINTING_TECH')
export const PRINTING_TECHNOLOGY_VALUE_TO_READABLE = getString(
	'PRINTING_TECHNOLOGY_VALUE_TO_READABLE'
)
export const PRIORITY_ALERT_TEXT = getString('PRIORITY_ALERT_TEXT')
export const PRIORITY_ALERT_TITLE = getString('PRIORITY_ALERT_TITLE')
export const PROFILE_FORM_COMPANY_LABEL = getString(
	'PROFILE_FORM_COMPANY_LABEL'
)
export const PROFILE_FORM_EMAIL_LABEL = getString('PROFILE_FORM_EMAIL_LABEL')
export const PROFILE_FORM_SITE_LABEL = getString('PROFILE_FORM_SITE_LABEL')
export const PROFILE_FORM_FULL_NAME_LABEL = getString(
	'PROFILE_FORM_FULL_NAME_LABEL'
)
export const PROFILE_FORM_HEADER = getString('PROFILE_FORM_HEADER')
export const PROFILE_NOTIFICATION_ERROR = getString(
	'PROFILE_NOTIFICATION_ERROR'
)
export const PROFILE_NOTIFICATION_SUCCESS = getString(
	'PROFILE_NOTIFICATION_SUCCESS'
)
export const PROJECT_ANALYSIS_DELETE_PART_ALERT = getString(
	'PROJECT_ANALYSIS_DELETE_PART_ALERT'
)
export const PROJECT_ANALYSIS_OUT_OF = getString('PROJECT_ANALYSIS_OUT_OF')
export const CANCEL_PROJECT = getString('CANCEL_PROJECT')
export const CANCEL_ANALYSIS_PROJECT_HOVER = getString(
	'CANCEL_ANALYSIS_PROJECT_HOVER'
)
export const CANCEL_FAILED_PROJECT_HOVER = getString(
	'CANCEL_FAILED_PROJECT_HOVER'
)
export const ARE_YOU_SURE_CANCEL_PROJECT = getString(
	'ARE_YOU_SURE_CANCEL_PROJECT'
)
export const PROJECT_ANALYSIS_CANCEL_PROJECT = getString(
	'PROJECT_ANALYSIS_CANCEL_PROJECT'
)
export const PROJECT_ANALYSIS_REMOVE_PROJECT = getString(
	'PROJECT_ANALYSIS_REMOVE_PROJECT'
)
export const PROJECT_ANALYSIS_REMOVE_PROJECT_ERROR = getString(
	'PROJECT_ANALYSIS_REMOVE_PROJECT_ERROR'
)
export const PROJECT_ANALYSIS_REMOVE_PROJECT_TEXT = getString(
	'PROJECT_ANALYSIS_REMOVE_PROJECT_TEXT'
)
export const PROJECT_ANALYSIS_SHOW_ALL_RESULTS = getString(
	'PROJECT_ANALYSIS_SHOW_ALL_RESULTS'
)
export const PROJECT_ANALYSIS_SHOW_FAILED_PARTS = getString(
	'PROJECT_ANALYSIS_SHOW_FAILED_PARTS'
)
export const PROJECT_ANALYSIS_SHOW_FAILED_PARTS_BUTTON = getString(
	'PROJECT_ANALYSIS_SHOW_FAILED_PARTS_BUTTON'
)
export const PROJECT_ANALYSIS_SUB_TITLE = getString(
	'PROJECT_ANALYSIS_SUB_TITLE'
)
export const PROJECT_ANALYSIS_WARNING = getString('PROJECT_ANALYSIS_WARNING')
export const PROJECT_ANALYSYS_ERROR_DOWNLOAD = getString(
	'PROJECT_ANALYSYS_ERROR_DOWNLOAD'
)
export const PROJECT_ANALYSYS_ERROR_REMOVE_PART = getString(
	'PROJECT_ANALYSYS_ERROR_REMOVE_PART'
)
export const PROJECT_ERP_OPTIONS = getString('PROJECT_ERP_OPTIONS')
export const PROJECT_PENDING_SUBTITLE = getString('PROJECT_PENDING_SUBTITLE')
export const PROJECT_PENDING_TEXT = getString('PROJECT_PENDING_TEXT')
export const PROJECT_PENDING_TITLE = getString('PROJECT_PENDING_TITLE')
export const PROJECT_PENDING_VALUE = getString('PROJECT_PENDING_VALUE')
export const PROJECT_FAILED_VALUE = getString('PROJECT_FAILED_VALUE')
export const PROJECT_FAILED_SUBTITLE = getString('PROJECT_FAILED_SUBTITLE')
export const PROJECT_FAILED_TEXT = getString('PROJECT_FAILED_TEXT')
export const PROJECT_FAILED_TEXT_SINGLE = getString(
	'PROJECT_FAILED_TEXT_SINGLE'
)

export const PROJECT_UNIT_TYPE = getString('PROJECT_UNIT_TYPE')
export const PROJECT_UPDATED_SUCCESS = getString('PROJECT_UPDATED_SUCCESS')
export const PUBLISH = getString('PUBLISH')
export const PUBLISH_CLUSTER = getString('PUBLISH_CLUSTER')
export const PUBLISH_CLUSTERS = getString('PUBLISH_CLUSTERS')
export const PUBLISH_PROJECT = getString('PUBLISH_PROJECT')
export const RE_ENTER_PASSWORD = getString('RE_ENTER_PASSWORD')
export const READ_MORE = getString('READ_MORE')
export const RECOMMENDED_MATERIAL_LABEL = getString(
	'RECOMMENDED_MATERIAL_LABEL'
)
export const RECOMMENDED_PRINTER_LABEL = getString('RECOMMENDED_PRINTER_LABEL')
export const RECOMMENDED_TECH_LABEL = getString('RECOMMENDED_TECH_LABEL')
export const REGISTER_COMPANY_PLACEHOLDER = getString(
	'REGISTER_COMPANY_PLACEHOLDER'
)
export const REGISTER_EMAIL_PLACEHOLDER = getString(
	'REGISTER_EMAIL_PLACEHOLDER'
)
export const REGISTER_EXPLINATION = getString('REGISTER_EXPLINATION')
export const REGISTER_LOGIN_BUT = getString('REGISTER_LOGIN_BUT')
export const REGISTER_LOGIN_TEXT = getString('REGISTER_LOGIN_TEXT')
export const REGISTER_NAME_PLACEHOLDER = getString('REGISTER_NAME_PLACEHOLDER')
export const REGISTER_PASS_PLACEHOLDER = getString('REGISTER_PASS_PLACEHOLDER')
export const REGISTER_REENTER_PASS_PLACEHOLDER = getString(
	'REGISTER_REENTER_PASS_PLACEHOLDER'
)
export const REGISTER_TITLE = getString('REGISTER_TITLE')
export const REJECT = getString('REJECT')
export const REMOVE_FROM_FAVORITES = getString('REMOVE_FROM_FAVORITES')
export const REQUEST_FAILED = getString('REQUEST_FAILED')
export const REQUIRED = getString('REQUIRED')
export const REQUIREMENTS_ALERT_TEXT = getString('REQUIREMENTS_ALERT_TEXT')
export const RESET_PASSWORD = getString('RESET_PASSWORD')
export const RESET_PASSWORD_EMAIL_POPUP_HEADER = getString(
	'RESET_PASSWORD_EMAIL_POPUP_HEADER'
)
export const RESET_VERIFY_USER_POPUP_HEADER = getString(
	'RESET_VERIFY_USER_POPUP_HEADER'
)
export const RESET_VERIFY_EMAIL_REQUEST_SUCCESS = getString(
	'RESET_VERIFY_EMAIL_REQUEST_SUCCESS'
)
export const RESET_PASSWORD_EMAIL_REQUEST_SUCCESS_HEADER = getString(
	'RESET_PASSWORD_EMAIL_REQUEST_SUCCESS_HEADER'
)
export const RESET_PASSWORD_ERROR_ALERT = getString(
	'RESET_PASSWORD_ERROR_ALERT'
)
export const RESET_PASSWORD_REQUEST_ERROR = getString(
	'RESET_PASSWORD_REQUEST_ERROR'
)
export const RESET_PASSWORD_REQUEST_SUCCESS = getString(
	'RESET_PASSWORD_REQUEST_SUCCESS'
)
export const RESET_PASSWORD_SUCCESS_POPUP_BODY = getString(
	'RESET_PASSWORD_SUCCESS_POPUP_BODY'
)
export const RESET_PASSWORD_SUCCESS_POPUP_HEADER = getString(
	'RESET_PASSWORD_SUCCESS_POPUP_HEADER'
)
export const RESULT = getString('RESULT')
export const RESULT_LABEL = getString('RESULT_LABEL')
export const RESULTS = getString('RESULTS')
export const SAME_MATERIAL_SWITCH = getString('SAME_MATERIAL_SWITCH')
export const SAVE = getString('SAVE')
export const SCHEME = getString('SCHEME')
export const SEARCH = getString('SEARCH')
export const SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER = getString(
	'SELECT_ACTION_PARTS_ADMIN_PLACEHOLDER'
)
export const SELECT_MATERIAL_CATEGORY_PLACEHOLDER = getString(
	'SELECT_MATERIAL_CATEGORY_PLACEHOLDER'
)
export const SELECT_AM_MATERIAL_CATEGORY_PLACEHOLDER = getString(
	'SELECT_AM_MATERIAL_CATEGORY_PLACEHOLDER'
)
export const SELECT_MATERIAL_EXPLAINED = getString('SELECT_MATERIAL_EXPLAINED')
export const SELECT_MATERIAL_PLACEHOLDER = getString(
	'SELECT_MATERIAL_PLACEHOLDER'
)
export const SELECT_AM_MATERIAL_PLACEHOLDER = getString(
	'SELECT_AM_MATERIAL_PLACEHOLDER'
)
export const SELECT_AM_SUBCATEGORY_PLACEHOLDER = getString(
	'SELECT_AM_SUBCATEGORY_PLACEHOLDER'
)
export const SELECT_MATERIAL_TYPE_PLACEHOLDER = getString(
	'SELECT_MATERIAL_TYPE_PLACEHOLDER'
)
export const SELECTION_PLACEHOLDER = getString('SELECTION_PLACEHOLDER')
export const SEND = getString('SEND')
export const SEND_EMAIL_BUT = getString('SEND_EMAIL_BUT')
export const SEND_TO_SERVICE_BUREAU_ADDRESS_HEADER = getString(
	'SEND_TO_SERVICE_BUREAU_ADDRESS_HEADER'
)
export const SEND_TO_SERVICE_BUREAU_ADDRESS_LINE_1 = getString(
	'SEND_TO_SERVICE_BUREAU_ADDRESS_LINE_1'
)
export const SEND_TO_SERVICE_BUREAU_ADDRESS_LINE_2 = getString(
	'SEND_TO_SERVICE_BUREAU_ADDRESS_LINE_2'
)
export const SEND_TO_SERVICE_BUREAU_CITY = getString(
	'SEND_TO_SERVICE_BUREAU_CITY'
)
export const SEND_TO_SERVICE_BUREAU_COUNTRY = getString(
	'SEND_TO_SERVICE_BUREAU_COUNTRY'
)
export const SEND_TO_SERVICE_BUREAU_EXPLANATION = getString(
	'SEND_TO_SERVICE_BUREAU_EXPLANATION'
)
export const SEND_TO_SERVICE_BUREAU_HOW_TITLE = getString(
	'SEND_TO_SERVICE_BUREAU_HOW_TITLE'
)
export const SEND_TO_SERVICE_BUREAU_MATERIAL = getString(
	'SEND_TO_SERVICE_BUREAU_MATERIAL'
)
export const SEND_TO_SERVICE_BUREAU_NOTES = getString(
	'SEND_TO_SERVICE_BUREAU_NOTES'
)
export const SEND_TO_SERVICE_BUREAU_NOTES_EXTRA = getString(
	'SEND_TO_SERVICE_BUREAU_NOTES_EXTRA'
)
export const SEND_TO_SERVICE_BUREAU_PRINTER = getString(
	'SEND_TO_SERVICE_BUREAU_PRINTER'
)
export const SEND_TO_SERVICE_BUREAU_QUANTITY_LABEL = getString(
	'SEND_TO_SERVICE_BUREAU_QUANTITY_LABEL'
)
export const SEND_TO_SERVICE_BUREAU_STATE = getString(
	'SEND_TO_SERVICE_BUREAU_STATE'
)
export const SEND_TO_SERVICE_BUREAU_SUBMIT = getString(
	'SEND_TO_SERVICE_BUREAU_SUBMIT'
)
export const SEND_TO_SERVICE_BUREAU_SUBTITLE = getString(
	'SEND_TO_SERVICE_BUREAU_SUBTITLE'
)
export const SEND_TO_SERVICE_BUREAU_SUCCESS_POPUP_MESSAGE = getString(
	'SEND_TO_SERVICE_BUREAU_SUCCESS_POPUP_MESSAGE'
)
export const SEND_TO_SERVICE_BUREAU_SUCCESS_POPUP_TITLE = getString(
	'SEND_TO_SERVICE_BUREAU_SUCCESS_POPUP_TITLE'
)
export const SEND_TO_SERVICE_BUREAU_TITLE = getString(
	'SEND_TO_SERVICE_BUREAU_TITLE'
)
export const SEND_TO_SERVICE_BUREAU_UPLOAD_DRAWING = getString(
	'SEND_TO_SERVICE_BUREAU_UPLOAD_DRAWING'
)
export const SEND_TO_SERVICE_BUREAU_UPLOAD_DRAWING_EXPLAINED = getString(
	'SEND_TO_SERVICE_BUREAU_UPLOAD_DRAWING_EXPLAINED'
)
export const SEND_TO_SERVICE_BUREAU_ZIP = getString(
	'SEND_TO_SERVICE_BUREAU_ZIP'
)
export const SERVICE_BUREAU_EXPLAINED = getString('SERVICE_BUREAU_EXPLAINED')
export const SERVICE_BUREAU_HEADER = getString('SERVICE_BUREAU_HEADER')
export const SERVICE_BUREAU_SUBHEADER = getString('SERVICE_BUREAU_SUBHEADER')
export const SET = getString('SET')
export const SHOW_ALL = getString('SHOW_ALL')
export const SHOW_NOTIFICATION = getString('SHOW_NOTIFICATION')
export const SHOW_PRINTABLE_PARTS_ONLY_SWITCH = getString(
	'SHOW_PRINTABLE_PARTS_ONLY_SWITCH'
)
export const SIGN_OUT = getString('SIGN_OUT')
export const SOLUTION_ORIENTATION_GENERAL_TEXT = getString(
	'SOLUTION_ORIENTATION_GENERAL_TEXT'
)
export const SOLUTION_ORIENTATION_HEADER = getString(
	'SOLUTION_ORIENTATION_HEADER'
)
export const STANDALONE_PARTS = getString('STANDALONE_PARTS')
export const STARRED = getString('STARRED')
export const START_OVER = getString('START_OVER')
export const STRENGTH = getString('STRENGTH')
export const SUBMIT = getString('SUBMIT')
export const SUBMIT_CHANGES = getString('SUBMIT_CHANGES')
export const SUBMIT_REQUEST = getString('SUBMIT_REQUEST')
export const SUBSCRIPTION_REQUEST_SECCEDD = getString(
	'SUBSCRIPTION_REQUEST_SECCEDD'
)
export const SUBSCRIPTION_REQUEST_SECCEDD_HEADER = getString(
	'SUBSCRIPTION_REQUEST_SECCEDD_HEADER'
)
export const SUBSCRIPTION_REQUEST_SENT = getString('SUBSCRIPTION_REQUEST_SENT')
export const SUBSCRUPTION_LOGIN_MSG = getString('SUBSCRUPTION_LOGIN_MSG')
export const SUBSCRUPTION_LOGIN_MSG_HEADER = getString(
	'SUBSCRUPTION_LOGIN_MSG_HEADER'
)
export const SUBSCRUPTION_TRIAL_LOGIN_MSG = getString(
	'SUBSCRUPTION_TRIAL_LOGIN_MSG'
)
export const SUBSCRUPTION_TRIAL_LOGIN_MSG_HEADER = getString(
	'SUBSCRUPTION_TRIAL_LOGIN_MSG_HEADER'
)
export const SUCCESS = getString('SUCCESS')
export const SUPPORT = getString('SUPPORT')
export const SURFACE_FINISH_ALERT_TEXT = getString('SURFACE_FINISH_ALERT_TEXT')
export const SURFACE_FINISH_ALERT_TITLE = getString(
	'SURFACE_FINISH_ALERT_TITLE'
)
export const TESTING = getString('TESTING')
export const THREE_D_BUTTON_TEXT = getString('THREE_D_BUTTON_TEXT')
export const TROUBLE_TO_LOGIN_TEXT = getString('TROUBLE_TO_LOGIN_TEXT')
export const TURMS_CHECKBOX_LINK_TEXT = getString('TURMS_CHECKBOX_LINK_TEXT')
export const TURMS_CHECKBOX_TEXT = getString('TURMS_CHECKBOX_TEXT')
export const TWO_FACTOR_LOGIN_HEADER = getString('TWO_FACTOR_LOGIN_HEADER')
export const TWO_FACTOR_LOGIN_SUB_HEADER = getString(
	'TWO_FACTOR_LOGIN_SUB_HEADER'
)
export const TWO_FACTOR_SETUP_HEADER = getString('TWO_FACTOR_SETUP_HEADER')
export const TWO_FACTOR_SETUP_SUB_HEADER = getString(
	'TWO_FACTOR_SETUP_SUB_HEADER'
)
export const TWO_FACTOR_TROUBLE_POPUP_BODY_TEXT = getString(
	'TWO_FACTOR_TROUBLE_POPUP_BODY_TEXT'
)
export const TWO_FACTOR_TROUBLE_POPUP_ERROR = getString(
	'TWO_FACTOR_TROUBLE_POPUP_ERROR'
)
export const TWO_FACTOR_TROUBLE_POPUP_HEADER = getString(
	'TWO_FACTOR_TROUBLE_POPUP_HEADER'
)
export const TWO_FACTOR_WRONG_CODE = getString('TWO_FACTOR_WRONG_CODE')
export const UNDO_CHANGES = getString('UNDO_CHANGES')
export const UNIFORM_MATERIAL_RADIO_TEXT_ON_UPLOAD = getString(
	'UNIFORM_MATERIAL_RADIO_TEXT_ON_UPLOAD'
)
export const UNIT_SELECTION_PLACEHOLDER = getString(
	'UNIT_SELECTION_PLACEHOLDER'
)
export const UPDATE_CLUSTER = getString('UPDATE_CLUSTER')
export const UPDATE_NOW = getString('UPDATE_NOW')
export const UPDATE_USER_PROFILE = getString('UPDATE_USER_PROFILE')
export const UPLOAD_ASSEMBLY_SELECTION = getString('UPLOAD_ASSEMBLY_SELECTION')
export const UPLOAD_COMPLETE_SUCCESSFULLY = getString(
	'UPLOAD_COMPLETE_SUCCESSFULLY'
)
export const UPLOAD_DROPEZONE_HEADER = getString('UPLOAD_DROPEZONE_HEADER')
export const UPLOAD_EXPLAIN_TEXT_TAB = getString('UPLOAD_EXPLAIN_TEXT_TAB')
export const UPLOAD_EXPLAIN_TITLE = getString('UPLOAD_EXPLAIN_TITLE')
export const UPLOAD_EXPLANATION_ARRAY = getString('UPLOAD_EXPLANATION_ARRAY')
export const UPLOAD_EXPLINATION_1_HEADER = getString(
	'UPLOAD_EXPLINATION_1_HEADER'
)
export const UPLOAD_EXPLINATION_2_HEADER = getString(
	'UPLOAD_EXPLINATION_2_HEADER'
)
export const UPLOAD_FAILED = getString('UPLOAD_FAILED')
export const UPLOAD_FILES_BUTTON = getString('UPLOAD_FILES_BUTTON')
export const UPLOAD_FORM_HEADER = getString('UPLOAD_FORM_HEADER')
export const UPLOAD_INDIVIDUAL_PARTS_SELECTION = getString(
	'UPLOAD_INDIVIDUAL_PARTS_SELECTION'
)
export const UPLOAD_PARTS_KEEP_PAGE_OPEN = getString(
	'UPLOAD_PARTS_KEEP_PAGE_OPEN'
)
export const UPLOAD_PARTS_SELECTION = getString('UPLOAD_PARTS_SELECTION')
export const UPLOAD_PROJECT_APPLICATION = getString(
	'UPLOAD_PROJECT_APPLICATION'
)
export const UPLOAD_PROJECT_CAD_SOFTWARE = getString(
	'UPLOAD_PROJECT_CAD_SOFTWARE'
)
export const UPLOAD_PROJECT_MATERIAL_TOLERANCE = getString(
	'UPLOAD_PROJECT_MATERIAL_TOLERANCE'
)
export const UPLOAD_PROJECT_MATERIAL_TOLERANCE_ALERT_TITLE = getString(
	'UPLOAD_PROJECT_MATERIAL_TOLERANCE_ALERT_TITLE'
)
export const UPLOAD_PROJECT_MATERIAL_TOLERANCE_WARNING = getString(
	'UPLOAD_PROJECT_MATERIAL_TOLERANCE_WARNING'
)
export const UPLOAD_PROJECT_PROJECT_NAME = getString(
	'UPLOAD_PROJECT_PROJECT_NAME'
)
export const UPLOAD_PROJECT_YEARLY_PROD = getString(
	'UPLOAD_PROJECT_YEARLY_PROD'
)
export const UPLOAD_SOLIDWORKS_EXPORT_TAB = getString(
	'UPLOAD_SOLIDWORKS_EXPORT_TAB'
)
export const USAGE_AGREEMENT_LINK = getString('USAGE_AGREEMENT_LINK')
export const USER_ADDRESS = getString('USER_ADDRESS')
export const USER_INFO_UPDATED_SUCCESS = getString('USER_INFO_UPDATED_SUCCESS')
export const USER_INFORMATION_FROM_EDIT_LABELS = getString(
	'USER_INFORMATION_FROM_EDIT_LABELS'
)
export const USER_INFORMATION_HEADERS = getString('USER_INFORMATION_HEADERS')
export const USER_MATERIAL_ADD_FAILED = getString('USER_MATERIAL_ADD_FAILED')
export const USER_MATERIAL_ADD_NEW = getString('USER_MATERIAL_ADD_NEW')
export const USER_PRINTER_ADD_NEW = getString('USER_PRINTER_ADD_NEW')
export const USER_MATERIAL_ADDED = getString('USER_MATERIAL_ADDED')
export const USER_MATERIAL_DELETE_ALERT_BODY = getString(
	'USER_MATERIAL_DELETE_ALERT_BODY'
)
export const USER_PRINTER_DELETE_ALERT_BODY = getString(
	'USER_PRINTER_DELETE_ALERT_BODY'
)
export const USER_PRINTER_MATERIAL_DELETE_ALERT_BODY = getString(
	'USER_PRINTER_MATERIAL_DELETE_ALERT_BODY'
)
export const USER_MATERIAL_DELETE_ALERT_TITLE = getString(
	'USER_MATERIAL_DELETE_ALERT_TITLE'
)
export const USER_PRINTER_DELETE_ALERT_TITLE = getString(
	'USER_PRINTER_DELETE_ALERT_TITLE'
)
export const USER_MATERIAL_DELETE_FAILED = getString(
	'USER_MATERIAL_DELETE_FAILED'
)
export const USER_MATERIAL_METHOD = getString('USER_MATERIAL_METHOD')
export const USER_MATERIAL_NAME = getString('USER_MATERIAL_NAME')
export const USER_MATERIAL_NO_MATERIALS_FOUND = getString(
	'USER_MATERIAL_NO_MATERIALS_FOUND'
)
export const USER_PRINTERS_NO_PRINTERS_FOUND = getString(
	'USER_PRINTERS_NO_PRINTERS_FOUND'
)
export const USER_MATERIAL_SURFACE_FINISH_METHOD = getString(
	'USER_MATERIAL_SURFACE_FINISH_METHOD'
)
export const USER_MATERIAL_TEMPERATURE_UNIT = getString(
	'USER_MATERIAL_TEMPERATURE_UNIT'
)
export const USER_MATERIAL_TYPE = getString('USER_MATERIAL_TYPE')
export const USER_MATERIAL_UNITS = getString('USER_MATERIAL_UNITS')
export const USER_MENU_PROFILE = getString('USER_MENU_PROFILE')
export const USER_MENU_HEALTH_STATISTICS = getString(
	'USER_MENU_HEALTH_STATISTICS'
)
export const HEALTH_STATISTICS_ERROR = getString('HEALTH_STATISTICS_ERROR')
export const HEALTH_STATISTICS_SUCCESS = getString('HEALTH_STATISTICS_SUCCESS')
export const HEALTH_STATISTICS_FAILED = getString('HEALTH_STATISTICS_FAILED')
export const USER_MENU_SETTINGS = getString('USER_MENU_SETTINGS')
export const USER_MENU_TAKE_A_TOUR = getString('USER_MENU_TAKE_A_TOUR')
export const USER_MENU_UPLOAD_SCRIPT = getString('USER_MENU_UPLOAD_SCRIPT')
export const USER_PROFILE_ADDRESS_ALERT = getString(
	'USER_PROFILE_ADDRESS_ALERT'
)
export const COUNTRY_NOT_VALID = getString('COUNTRY_NOT_VALID')
export const STATE_NOT_VALID = getString('STATE_NOT_VALID')
export const USER_PROFILE_ADDRESS_PLACEHOLDER = getString(
	'USER_PROFILE_ADDRESS_PLACEHOLDER'
)
export const USER_PROFILE_COMPANY_ALERT = getString(
	'USER_PROFILE_COMPANY_ALERT'
)
export const USER_PROFILE_EMAIL_ALERT = getString('USER_PROFILE_EMAIL_ALERT')
export const USER_PROFILE_NAME_ALERT = getString('USER_PROFILE_NAME_ALERT')
export const VERSION_POPUP_TEXT = getString('VERSION_POPUP_TEXT')
export const VERSION_POPUP_TITLE = getString('VERSION_POPUP_TITLE')
export const VIEW_PART = getString('VIEW_PART')
export const VIEW_PROJECT = getString('VIEW_PROJECT')
export const WALL_THICKNESS_ALERT_HELPER_TEXT = getString(
	'WALL_THICKNESS_ALERT_HELPER_TEXT'
)
export const WALL_THICKNESS_DEMAND_ALERT_DESCRIPTION = getString(
	'WALL_THICKNESS_DEMAND_ALERT_DESCRIPTION'
)
export const WALL_THICKNESS_DEMAND_ALERT_TITLE = getString(
	'WALL_THICKNESS_DEMAND_ALERT_TITLE'
)
export const WALL_THICKNESS_DEMAND_BUTTON_PLACEHOLDER = getString(
	'WALL_THICKNESS_DEMAND_BUTTON_PLACEHOLDER'
)
export const WALL_THICKNESS_DEMAND_BUTTON_TEXT = getString(
	'WALL_THICKNESS_DEMAND_BUTTON_TEXT'
)
export const WALL_THICKNESS_NOTIFICATION_FAILED = getString(
	'WALL_THICKNESS_NOTIFICATION_FAILED'
)
export const WALL_THICKNESS_NOTIFICATION_SUCCEDED = getString(
	'WALL_THICKNESS_NOTIFICATION_SUCCEDED'
)
export const WALL_THICKNESS_NOTIFICATION_TOO_LONG = getString(
	'WALL_THICKNESS_NOTIFICATION_TOO_LONG'
)
export const WALL_THICKNESS_UPDATE_ERROR = getString(
	'WALL_THICKNESS_UPDATE_ERROR'
)
export const WEIGHT_RESULT_INFO = getString('WEIGHT_RESULT_INFO')
export const WEIGHT_RESULT_LABEL = getString('WEIGHT_RESULT_LABEL')
export const WEIGHT_RESULT_TITLE_INFO = getString('WEIGHT_RESULT_TITLE_INFO')
export const YES = getString('YES')
export const PART_FINANCIAL_ANALYSIS_FORM_CHAIN_BENEFITS_INVENTORY_EXPLANATION =
	getString('PART_FINANCIAL_ANALYSIS_FORM_CHAIN_BENEFITS_INVENTORY_EXPLANATION')
export const PART_FINANCIAL_ANALYSIS_FORM_CHAIN_BENEFITS_SHIPMENT_EXPLANATION =
	getString('PART_FINANCIAL_ANALYSIS_FORM_CHAIN_BENEFITS_SHIPMENT_EXPLANATION')
export const CHAIN_BENEFITS_HEADER = getString('CHAIN_BENEFITS_HEADER')
export const SHIPMENT = getString('SHIPMENT')
export const INVENTORY = getString('INVENTORY')
export const ORDERING = getString('ORDERING')
export const OBSOLESCENCE = getString('OBSOLESCENCE')
export const OTHER = getString('OTHER')
export const ADD_SUPPLY_CHAIN = getString('ADD_SUPPLY_CHAIN')
export const ORDERING_COST_ANALYSIS = getString('ORDERING_COST_ANALYSIS')
export const OBSOLESCENCE_COST_ANALYSIS = getString(
	'OBSOLESCENCE_COST_ANALYSIS'
)
export const MAINTENANCE_COST_ANALYSIS = getString('MAINTENANCE_COST_ANALYSIS')

export const SHIPMENT_FORM_LABEL = getString('SHIPMENT_FORM_LABEL')
export const INVENTORY_FORM_PRICE_LABEL = getString(
	'INVENTORY_FORM_PRICE_LABEL'
)
export const INVENTORY_FORM_QUANTITY_LABEL = getString(
	'INVENTORY_FORM_QUANTITY_LABEL'
)
export const SHIPMENT_INFO_CHECK_BOX = getString('SHIPMENT_INFO_CHECK_BOX')
export const INVENTORY_INFO_CHECK_BOX = getString('INVENTORY_INFO_CHECK_BOX')
export const MANUFACTURING = getString('MANUFACTURING')
export const EMISSIONS_COMPARISON_TEXT = getString('EMISSIONS_COMPARISON_TEXT')
export const EMISSIONS_COMPARISON_CO2 = getString('EMISSIONS_COMPARISON_CO2')
export const SAVED = getString('SAVED')
export const EMISSIONS_COMPARISON_KG = getString('EMISSIONS_COMPARISON_KG')
export const POTENTIAL_SAVING = getString('POTENTIAL_SAVING')

export const CNC_USER_CUSTOM_SETTING_TAXES = getString(
	'CNC_USER_CUSTOM_SETTING_TAXES'
)
export const CNC_USER_CUSTOM_SETTING_DOMESTIC_SHIPMENT = getString(
	'CNC_USER_CUSTOM_SETTING_DOMESTIC_SHIPMENT'
)
export const CNC_USER_CUSTOM_SETTING_PROFIT_MARGIN = getString(
	'CNC_USER_CUSTOM_SETTING_PROFIT_MARGIN'
)
export const CNC_USER_CUSTOM_SETTINGS_HEADER = getString(
	'CNC_USER_CUSTOM_SETTINGS_HEADER'
)
export const CHAIN_BENEFITS_FORM_POPUP_EXPLATION = getString(
	'CHAIN_BENEFITS_FORM_POPUP_EXPLATION'
)
export const TRAY_ORIENTATION_RESULT_WARNING = getString(
	'TRAY_ORIENTATION_RESULT_WARNING'
)

export const CASTOR_ENTERPRISE = getString('CASTOR_ENTERPRISE')

export const IMAGES_TAB_HEADER_STRUCTURAL_LIMITATION = getString(
	'IMAGES_TAB_HEADER_STRUCTURAL_LIMITATION'
)
export const IMAGES_TAB_HEADER_TRAY_ORIENTATION = getString(
	'IMAGES_TAB_HEADER_TRAY_ORIENTATION'
)
export const CHOOSE_ORIENTATION_BUTTON = getString('CHOOSE_ORIENTATION_BUTTON')
export const ORIENTATION_CHANGED_MSG = getString('ORIENTATION_CHANGED_MSG')
export const TIME_DETAILS_HOVER_HEADER = getString('TIME_DETAILS_HOVER_HEADER')
export const TIME_DETAILS_HOVER_PRODUCING_LABEL = getString(
	'TIME_DETAILS_HOVER_PRODUCING_LABEL'
)
export const TIME_DETAILS_HOVER_DELIVERY_LABEL = getString(
	'TIME_DETAILS_HOVER_DELIVERY_LABEL'
)
export const UPDATE_TOLERANCE = getString('UPDATE_TOLERANCE')
export const UPDATE_TOLERANCE_PLACEHOLDER = getString(
	'UPDATE_TOLERANCE_PLACEHOLDER'
)
export const UPDATE_TOLERANCE_POPUP_HEADER = getString(
	'UPDATE_TOLERANCE_POPUP_HEADER'
)
export const UPDATE_TOLERANCE_POPUP_MESSAGE = getString(
	'UPDATE_TOLERANCE_POPUP_MESSAGE'
)
export const TOLERANCE_UPDATE_BUTTON = getString('TOLERANCE_UPDATE_BUTTON')
export const ORIENTATION_FAIL_ON_SIZE_MSG = getString(
	'ORIENTATION_FAIL_ON_SIZE_MSG'
)
export const ORIENTATION_OVERHANGING_FAIL_MSG = getString(
	'ORIENTATION_OVERHANGING_FAIL_MSG'
)
export const ORIENTATION_OVERHANGING_BORDERLINE_MSG = getString(
	'ORIENTATION_OVERHANGING_BORDERLINE_MSG'
)
export const TOLERANCES_UPDATE_SUCCESS_MSG = getString(
	'TOLERANCES_UPDATE_SUCCESS_MSG'
)

export const WEIGHT_REDUCTION_PROGRESS_STEP1_HEADERS = getString(
	'WEIGHT_REDUCTION_PROGRESS_STEP1_HEADERS'
)
export const WEIGHT_REDUCTION_PROGRESS_STEP1_TITLES = getString(
	'WEIGHT_REDUCTION_PROGRESS_STEP1_TITLES'
)
export const WEIGHT_REDUCTION_PROGRESS_STEP2_FAST_TRACK = getString(
	'WEIGHT_REDUCTION_PROGRESS_STEP2_FAST_TRACK'
)
export const WEIGHT_REDUCTION_PROGRESS_STEP2_OPTIMIZED_TRACK = getString(
	'WEIGHT_REDUCTION_PROGRESS_STEP2_OPTIMIZED_TRACK'
)
export const WEIGHT_REDUCTION_PROGRESS_STEP2_HEADERS = getString(
	'WEIGHT_REDUCTION_PROGRESS_STEP2_HEADERS'
)
export const WIEGHT_REDUCTION_PROGRESS_POPUP_HEADER = getString(
	'WIEGHT_REDUCTION_PROGRESS_POPUP_HEADER'
)
export const WEIGHT_REDUCTION_STL_FEA_NOT_POSSIBLE_MSG = getString(
	'WEIGHT_REDUCTION_STL_FEA_NOT_POSSIBLE_MSG'
)
export const WEIGHT_REDUCTION_NO_BREP_DATA_FEA_NOT_POSSIBLE_MSG = getString(
	'WEIGHT_REDUCTION_NO_BREP_DATA_FEA_NOT_POSSIBLE_MSG'
)
export const WEIGHT_REDUCTION_CONFIGURATION_BUTTON = getString(
	'WEIGHT_REDUCTION_CONFIGURATION_BUTTON'
)
export const WEIGHT_REDUCTION_FEA_REQ_FAILED_HEADER = getString(
	'WEIGHT_REDUCTION_FEA_REQ_FAILED_HEADER'
)
export const WEIGHT_REDUCTION_FEA_REQ_SUCCEED_HEADER = getString(
	'WEIGHT_REDUCTION_FEA_REQ_SUCCEED_HEADER'
)
export const WEIGHT_REDUCTION_PROGRESS_MODEL_HEADER = getString(
	'WEIGHT_REDUCTION_PROGRESS_MODEL_HEADER'
)
export const REDUCE_WEIGHT_BUTTON_TEXT = getString('REDUCE_WEIGHT_BUTTON_TEXT')
export const WEIGHT_REDUCTION_PROGRESS_CUSTOMIZE_CONFIGURATIONS_BUTTONS =
	getString('WEIGHT_REDUCTION_PROGRESS_CUSTOMIZE_CONFIGURATIONS_BUTTONS')
export const WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_HEADER = getString(
	'WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_HEADER'
)
export const WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_TEXT = getString(
	'WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_TEXT'
)
export const WEIGHT_REDUCTION_PROGRESS_CUSTOM_TAKE_A_TOUR_TEXT = getString(
	'WEIGHT_REDUCTION_PROGRESS_CUSTOM_TAKE_A_TOUR_TEXT'
)
export const USAGE_AGREEMENT_HEADER = getString('USAGE_AGREEMENT_HEADER')

export const FORGOT_PASSWORD_ON_PREM_TEXT = getString(
	'FORGOT_PASSWORD_ON_PREM_TEXT'
)
export const PARAMETER_UPDATED_SUCCESS = getString('PARAMETER_UPDATED_SUCCESS')
export const PARAMETER_UPDATED_FAILED = getString('PARAMETER_UPDATED_FAILED')

export const WEIGHT_REDUCTION_FDM_WARNING_TEXT = getString(
	'WEIGHT_REDUCTION_FDM_WARNING_TEXT'
)
export const WEIGHT_REDUCTION_FDM_WARNING_HEADER = getString(
	'WEIGHT_REDUCTION_FDM_WARNING_HEADER'
)
export const COST_SUMMARY_ROWS_HEADERS = getString('COST_SUMMARY_ROWS_HEADERS')
export const COST_SUMMARY_TRADITIONAL_METHOD = getString(
	'COST_SUMMARY_TRADITIONAL_METHOD'
)
export const COST_SUMMARY_METHOD = getString('COST_SUMMARY_METHOD')
export const COST_SUMMARY_ADDITIVE_METHOD = getString(
	'COST_SUMMARY_ADDITIVE_METHOD'
)
export const COST_SUMMARY_TABLE_HEADERS = getString(
	'COST_SUMMARY_TABLE_HEADERS'
)
export const PART_RESULTS_CNC = getString('PART_RESULTS_CNC')
export const ADMIN_PARTS_CNC_PRINTABLE_AREA = getString(
	'ADMIN_PARTS_CNC_PRINTABLE_AREA'
)
export const PART_RESULTS_CNC_SUPPORT_REMOVAL = getString(
	'PART_RESULTS_CNC_SUPPORT_REMOVAL'
)
export const COST_SUMMARY_TABLE_SUB_TEXT = getString(
	'COST_SUMMARY_TABLE_SUB_TEXT'
)
export const CNC_SUPPORT_REMOVAL_SUCCESS_MSG = getString(
	'CNC_SUPPORT_REMOVAL_SUCCESS_MSG'
)
export const COST_SAVING = getString('COST_SAVING')
export const TIME_SAVING = getString('TIME_SAVING')
export const CO2_SAVING = getString('CO2_SAVING')
export const COMPLEX_GEOMETRY = getString('COMPLEX_GEOMETRY')
export const PART_CONSOLIDATION_OPPORTUNITIES = getString(
	'PART_CONSOLIDATION_OPPORTUNITIES'
)
export const PART_CONSOLIDATION_OPPORTUNITIES_HELPER = getString(
	'PART_CONSOLIDATION_OPPORTUNITIES_HELPER'
)
export const SEARCH_AGAIN = getString('SEARCH_AGAIN')
export const WITH_SAME_MATERIAL = getString('WITH_SAME_MATERIAL')
export const WITH_ANY_MATERIAL = getString('WITH_ANY_MATERIAL')
export const ANY_MATERIAL = getString('ANY_MATERIAL')
export const SAME_MATERIAL = getString('SAME_MATERIAL')
export const CONSOLIDATION_WILL_BE_OVERWRITTEN = getString(
	'CONSOLIDATION_WILL_BE_OVERWRITTEN'
)
export const CONSOLIDATION_DELETE_RESULTS = getString(
	'CONSOLIDATION_DELETE_RESULTS'
)
export const CONSOLIDATION_KEEP_RESULTS = getString(
	'CONSOLIDATION_KEEP_RESULTS'
)
export const CONSOLIDATION_WILL_BE_REMOVED = getString(
	'CONSOLIDATION_WILL_BE_REMOVED'
)
export const CONSOLIDATION_DELETE_PART = getString('CONSOLIDATION_DELETE_PART')
export const CONSOLIDATION_KEEP_PART = getString('CONSOLIDATION_KEEP_PART')
export const COMBINE_AROUND_THIS_PART = getString('COMBINE_AROUND_THIS_PART')
export const SEARCH_AGAIN_FOR_RESULT = getString('SEARCH_AGAIN_FOR_RESULT')
export const SEARCH_WITHIN_SAME_CATEGORY = getString(
	'SEARCH_WITHIN_SAME_CATEGORY'
)
export const COMBINE_AROUND_THIS_PART_INFO = getString(
	'COMBINE_AROUND_THIS_PART_INFO'
)
export const COMBINE_AROUND_THIS_PART_SEARCH_EMPTY = getString(
	'COMBINE_AROUND_THIS_PART_SEARCH_EMPTY'
)
export const COMBINE_MULTIPLE_INTO_ONE_CARD_COMPLETED_TXT = getString(
	'COMBINE_MULTIPLE_INTO_ONE_CARD_COMPLETED_TXT'
)

export const NEW_PART_CONFIGURATION_SEND_FAILURE_TEXT = getString(
	'NEW_PART_CONFIGURATION_SEND_FAILURE_TEXT'
)
export const NEW_PART_CONFIGURATION_SEND_SUCCESS_TEXT = getString(
	'NEW_PART_CONFIGURATION_SEND_SUCCESS_TEXT'
)
export const NEW_CONFIGURATION_PROPERTIES = getString(
	'NEW_CONFIGURATION_PROPERTIES'
)

export const TAB_HEADER_MANUFACTURABILITY = getString(
	'TAB_HEADER_MANUFACTURABILITY'
)
export const TAB_HEADER_PRODUCTION_TIME = getString(
	'TAB_HEADER_PRODUCTION_TIME'
)
export const TAB_HEADER_COST_PER_PART = getString('TAB_HEADER_COST_PER_PART')
export const SYSTEM_RECOMMENDATION = getString('SYSTEM_RECOMMENDATION')
export const OFF_THE_SHELF = getString('OFF_THE_SHELF')
export const THICKNESS_SCORE = getString('THICKNESS_SCORE')
export const THICKNESS_FAIL_REASON = getString('THICKNESS_FAIL_REASON')
export const SIZE_SCORE = getString('SIZE_SCORE')
export const SIZE_FAIL_REASON = getString('SIZE_FAIL_REASON')
export const CAD_SCORE = getString('CAD_SCORE')
export const CAD_FAIL_REASON = getString('CAD_FAIL_REASON')
export const MATERIAL_SCORE = getString('MATERIAL_SCORE')
export const MATERIAL_FAIL_REASON = getString('MATERIAL_FAIL_REASON')
export const COMPLEXITY_SCORE = getString('COMPLEXITY_SCORE')
export const UPDATE_PART_PROPERTIES = getString('UPDATE_PART_PROPERTIES')
export const PART_RESULT = getString('PART_RESULT')
export const ERROR_WILL_BE_SHOWN = getString('ERROR_WILL_BE_SHOWN')
export const PAGE_INVALIDATED = getString('PAGE_INVALIDATED')
export const ERROR_FILE_FORMAT_SUPPORT_PDF = getString(
	'ERROR_FILE_FORMAT_SUPPORT_PDF'
)
export const NEW_CONFIGURATION_ERROR = getString('NEW_CONFIGURATION_ERROR')
export const MANUFACTURABILITY_SHOULD_BE_VALIDATED = getString(
	'MANUFACTURABILITY_SHOULD_BE_VALIDATED'
)
export const PRINTING_ORIENTATION_ALERT_TITLE = getString(
	'PRINTING_ORIENTATION_ALERT_TITLE'
)
export const PRINTER_TITLE_COMPANY = getString('PRINTER_TITLE_COMPANY')
export const PRINTER_TITLE_TECHNOLOGY = getString('PRINTER_TITLE_TECHNOLOGY')
export const PRINTER_TITLE_DESCRIPTION = getString('PRINTER_TITLE_DESCRIPTION')
export const PRINTER_TITLE_CATEGORY = getString('PRINTER_TITLE_CATEGORY')
export const TABLE_DEVIATION_DATA_ERROR = getString(
	'TABLE_DEVIATION_DATA_ERROR'
)
export const TABLE_VALUE_DATA_ERROR = getString('TABLE_VALUE_DATA_ERROR')
export const UPDATE_DATABASE_INFO = getString('UPDATE_DATABASE_INFO')
export const RUN_DATABASE_UPDATE = getString('RUN_DATABASE_UPDATE')
export const CREATE_FILE_FOR_LICENSE = getString('CREATE_FILE_FOR_LICENSE')
export const DROP_DATABASE_FILE = getString('DROP_DATABASE_FILE')
export const UPDATE_DATABASE_FAILED = getString('UPDATE_DATABASE_FAILED')
export const UPDATE_LICENSE_FAILED = getString('UPDATE_LICENSE_FAILED')
export const UPDATE_DATABASE_SUCCESS = getString('UPDATE_DATABASE_SUCCESS')
export const UPDATE_DATABASE_ERROR_FILE_LENGTH = getString(
	'UPDATE_DATABASE_ERROR_FILE_LENGTH'
)
export const UPDATE_DATABASE_ERROR_FILE_FORMAT = getString(
	'UPDATE_DATABASE_ERROR_FILE_FORMAT'
)
export const LICENSE_UPDATE = getString('LICENSE_UPDATE')
export const UPDATE_DATABASE_LICENSE = getString('UPDATE_DATABASE_LICENSE')
export const FUTURE_START_DATE_LICENSE_MSG = getString(
	'FUTURE_START_DATE_LICENSE_MSG'
)
export const EXPIRED_DATE_LICENSE_MSG = getString('EXPIRED_DATE_LICENSE_MSG')
export const DROP_DATABASE_LICENSE_FILE = getString(
	'DROP_DATABASE_LICENSE_FILE'
)
export const RUN_DATABASE_INSERT_LICENSE = getString(
	'RUN_DATABASE_INSERT_LICENSE'
)
export const LICENSE_SUCCESS_ALERT_TEXT = getString(
	'LICENSE_SUCCESS_ALERT_TEXT'
)
export const FOOD_SAFE = getString('FOOD_SAFE')
export const DYEING = getString('DYEING')
export const POLISHING = getString('POLISHING')
export const MACHINING = getString('MACHINING')
export const BIO_COMPATIBLE = getString('BIO_COMPATIBLE')
export const ESD_SUPPORTED = getString('ESD_SUPPORTED')
export const FLAME_RETARDANT = getString('FLAME_RETARDANT')
export const EU_REACH = getString('EU_REACH')
export const ROHS = getString('ROHS')
export const FST = getString('FST')
export const SKIN_CONTACT = getString('SKIN_CONTACT')
export const ULTRA_LOW_OUTGASSING = getString('ULTRA_LOW_OUTGASSING')
export const FILTER_CORROSION_RESISTANT = getString(
	'FILTER_CORROSION_RESISTANT'
)
export const ADMIN_PRINTER_TRAY_WEIGHT_TIP = getString(
	'ADMIN_PRINTER_TRAY_WEIGHT_TIP'
)
export const ADMIN_PRINTER_HOLE_SIZE_TIP = getString(
	'ADMIN_PRINTER_HOLE_SIZE_TIP'
)
export const ADMIN_PRINTER_MAINTENANCE_PERCENTAGE_TIP = getString(
	'ADMIN_PRINTER_MAINTENANCE_PERCENTAGE_TIP'
)
export const ADMIN_PRINTER_MAINTENANCE_FIXED_TIP = getString(
	'ADMIN_PRINTER_MAINTENANCE_FIXED_TIP'
)
export const ADMIN_PRINTER_BUILD_RATE_TIP = getString(
	'ADMIN_PRINTER_BUILD_RATE_TIP'
)
export const ADMIN_PRINTER_MACHINE_COST_TIP = getString(
	'ADMIN_PRINTER_MACHINE_COST_TIP'
)
export const ADMIN_PRINTER_LAYER_TIP = getString('ADMIN_PRINTER_LAYER_TIP')
export const ADMIN_PRINTER_MINIMAL_TOLERANCE_TIP = getString(
	'ADMIN_PRINTER_MINIMAL_TOLERANCE_TIP'
)
export const ADMIN_PRINTER_ACCURACY_TIP = getString(
	'ADMIN_PRINTER_ACCURACY_TIP'
)
export const ADMIN_PRINTER_HOURLY_TIP = getString('ADMIN_PRINTER_HOURLY_TIP')

export const ADMIN_PRINTER_INFRASTRUCTURE_TIP = getString(
	'ADMIN_PRINTER_INFRASTRUCTURE_TIP'
)
export const ADMIN_TYPICAL_KWH_TIP = getString('ADMIN_TYPICAL_KWH_TIP')
export const ADMIN_PRINTER_TRAY_COMPENSATION_TIP = getString(
	'ADMIN_PRINTER_TRAY_COMPENSATION_TIP'
)
export const ADMIN_TYPICAL_KWH_TIP_WITH_EXPLAIN = getString(
	'ADMIN_TYPICAL_KWH_TIP_WITH_EXPLAIN'
)
export const MAIN_RESULT_TITLE = getString('MAIN_RESULT_TITLE')
export const BENEFITS_RESULT_TITLE = getString('BENEFITS_RESULT_TITLE')

export const PRINTER_COMPANY_NOT_EXIST = getString('PRINTER_COMPANY_NOT_EXIST')
export const INITIAL_TECHNOLOGY_SETUP = getString('INITIAL_TECHNOLOGY_SETUP')
export const INITIAL_SETUP = getString('INITIAL_SETUP')
export const OUTSOURCED_CNC_MANUFACTURING = getString(
	'OUTSOURCED_CNC_MANUFACTURING'
)
export const REMOVAL_RATE_CNC = getString('REMOVAL_RATE_CNC')
export const MOQ = getString('MOQ')
export const MOQ_INFO = getString('MOQ_INFO')
export const REMOVAL_RATE_CNC_INFO = getString('REMOVAL_RATE_CNC_INFO')
export const INITIAL_TECHNOLOGY_SETUP_INFO = getString(
	'INITIAL_TECHNOLOGY_SETUP_INFO'
)
export const PARAMETERS = getString('PARAMETERS')
export const PARTS_DELIVERED_IN_DAYS = getString('PARTS_DELIVERED_IN_DAYS')
export const COST_LEAD_MACHINE_LIFE = getString('COST_LEAD_MACHINE_LIFE')
export const COST_LEAD_HOURS_PER_DAY = getString('COST_LEAD_HOURS_PER_DAY')
export const COST_LEAD_DAYS_PER_WEEK = getString('COST_LEAD_DAYS_PER_WEEK')
export const COST_LEAD_ENGINEER_HOUR = getString('COST_LEAD_ENGINEER_HOUR')
export const COST_LEAD_INITIAL_CNC = getString('COST_LEAD_INITIAL_CNC')
export const COST_LEAD_INITIAL_CASTING = getString('COST_LEAD_INITIAL_CASTING')
export const COST_LEAD_TECHNICAL_HOURS_COST = getString(
	'COST_LEAD_TECHNICAL_HOURS_COST'
)
export const COST_LEAD_OPERATOR_HOURS_COST = getString(
	'COST_LEAD_OPERATOR_HOURS_COST'
)

export const UPLOAD_TAB_TEXTS = getString('UPLOAD_TAB_TEXTS')
export const USE_MATERIALS_FROM_DRAWING = getString(
	'USE_MATERIALS_FROM_DRAWING'
)
export const TWO_D_DROP_FILE_EXPLANATION = getString(
	'TWO_D_DROP_FILE_EXPLANATION'
)
export const NOT_TWO_D_FORMAT_ERROR = getString('NOT_TWO_D_FORMAT_ERROR')
export const MISSING_DRAWING_OR_MATERIAL = getString(
	'MISSING_DRAWING_OR_MATERIAL'
)
export const MISSING_MATERIAL = getString('MISSING_MATERIAL')

export const PARTS_FILTER = getString('PARTS_FILTER')
export const PARTS_PROPERTIES = getString('PARTS_PROPERTIES')

export const EMISSION_COMPARISON_TITLE = getString('EMISSION_COMPARISON_TITLE')
export const EMISSION_COMPARISON_EXPLANATION_ARRAY = getString(
	'EMISSION_COMPARISON_EXPLANATION_ARRAY'
)
export const EMISSION_COMPARISON_TABLE = getString('EMISSION_COMPARISON_TABLE')

export const UPLOAD_PROJECT_SCENARIO_METHODS_HEADER = getString(
	'UPLOAD_PROJECT_SCENARIO_METHODS_HEADER'
)
export const UPLOAD_PROJECT_SCENARIO_METHODS_ROWS = getString(
	'UPLOAD_PROJECT_SCENARIO_METHODS_ROWS'
)
export const UPLOAD_PROJECT_SCENARIO_METHODS_HOVER = getString(
	'UPLOAD_PROJECT_SCENARIO_METHODS_HOVER'
)
export const DRAWING_COST_SUPPORT_VOLUME = getString(
	'DRAWING_COST_SUPPORT_VOLUME'
)
export const DRAWING_COST_PACKING_DENSITY = getString(
	'DRAWING_COST_PACKING_DENSITY'
)
export const CUSTOMIZE_DRAWING_COST_TITLE = getString(
	'CUSTOMIZE_DRAWING_COST_TITLE'
)
export const CUSTOMIZE_DRAWING_COST_EXPLANATION = getString(
	'CUSTOMIZE_DRAWING_COST_EXPLANATION'
)
export const DRAWING_COSTS_TABLE = getString('DRAWING_COSTS_TABLE')

export const PARAMETERS_MANAGER_SUPPLY_CHAIN = getString(
	'PARAMETERS_MANAGER_SUPPLY_CHAIN'
)

export const PARAMETERS_MANAGER_CO2_MENUFATURING = getString(
	'PARAMETERS_MANAGER_CO2_MENUFATURING'
)

export const PARAMETERS_MANAGER_GENERAL_COSTING = getString(
	'PARAMETERS_MANAGER_GENERAL_COSTING'
)
export const PARAMETERS_MANAGER_CNC_COSTING = getString(
	'PARAMETERS_MANAGER_CNC_COSTING'
)
export const PARAMETERS_MANAGER_3D_PRINTING = getString(
	'PARAMETERS_MANAGER_3D_PRINTING'
)
export const PARAMETERS_MANAGER_INJECTION = getString(
	'PARAMETERS_MANAGER_INJECTION'
)
export const PARAMETERS_MANAGER_CASTING = getString(
	'PARAMETERS_MANAGER_CASTING'
)
export const PARAMETERS_MANAGER_INVESTMENT_CASTING = getString(
	'PARAMETERS_MANAGER_INVESTMENT_CASTING'
)
export const PARAMETERS_MANAGER_2D_DRAWING = getString(
	'PARAMETERS_MANAGER_2D_DRAWING'
)
export const BUY_TO_FLY = getString('BUY_TO_FLY')
export const USER_PANEL_PRINTING_SAVING_UPFRONT_INDEX_TITLE = getString(
	'USER_PANEL_PRINTING_SAVING_UPFRONT_INDEX_TITLE'
)
export const MY_PANEL_SAVINGS = getString('MY_PANEL_SAVINGS')
export const MY_PANEL_DISSAVINGS = getString('MY_PANEL_DISSAVINGS')
export const MY_PANEL_TOTAL_SAVINGS = getString('MY_PANEL_TOTAL_SAVINGS')
export const MOLDINGS = getString('MOLDINGS')

export const CUSTOMIZE_CO2_CALCULATIONS_TITLE = getString(
	'CUSTOMIZE_CO2_CALCULATIONS_TITLE'
)
export const CUSTOMIZE_CO2_CALCULATIONS_ARRAY = getString(
	'CUSTOMIZE_CO2_CALCULATIONS_ARRAY'
)
export const CUSTOMIZE_CO2_EMISSIONS_TITLE = getString(
	'CUSTOMIZE_CO2_EMISSIONS_TITLE'
)
export const CUSTOMIZE_CO2_EMISSIONS_PRINTER_MATERIAL_TITLE = getString(
	'CUSTOMIZE_CO2_EMISSIONS_PRINTER_MATERIAL_TITLE'
)
export const CO2_PER_KG_PLACE_HOLDER = getString('CO2_PER_KG_PLACE_HOLDER')
export const CO2_PER_KG_DATA_VALIDATION = getString(
	'CO2_PER_KG_DATA_VALIDATION'
)
export const CO2_PER_KWH = getString('CO2_PER_KWH')
export const CARBON_CO2_CALCULATION = getString('CARBON_CO2_CALCULATION')
export const CARBON_CO2_CALCULATION_VALIDATION = getString(
	'CARBON_CO2_CALCULATION_VALIDATION'
)
export const TYPICAL_KWH_PER_PRINTER_EXPLANATION = getString(
	'TYPICAL_KWH_PER_PRINTER_EXPLANATION'
)
export const TYPICAL_POWER_CONSUMPTION = getString('TYPICAL_POWER_CONSUMPTION')
export const LAYERS = getString('LAYERS')
export const PROJECT_SUMMARY_PDF_ROW_NAMES = getString(
	'PROJECT_SUMMARY_PDF_ROW_NAMES'
)
export const ONBOARDING_WIZARD_PAGE_NAME = getString(
	'ONBOARDING_WIZARD_PAGE_NAME'
)
export const USER_MENU_ONBOARDING_WIZARD = getString(
	'USER_MENU_ONBOARDING_WIZARD'
)
export const LATHE_CNC = getString('LATHE_CNC')
export const CASTING_CNC = getString('CASTING_CNC')
export const SHEET_METAL = getString('SHEET_METAL')
export const OPTIMIZED = getString('OPTIMIZED')
