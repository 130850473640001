import { CO2Params } from './CustomizeCO2CalculationEnums'
import { getUpdatedMaterial } from './CustomizeCO2CalculationService'
import {
	CO2_CARBON_CREDIT_COST_CHANGED,
	CO2_HOLD_FACTOR_CHANGED,
	CO2_MATERIAL_DELETED,
	CO2_NEW_MATERIAL_ADDED,
	CO2_NEW_PRINTER_MATERIAL_ADDED,
	CO2_NEW_PRINTER_TYPICAL_KWH_ADDED,
	CO2_NEW_TM_TYPICAL_KWH_ADDED,
	CO2_PER_KG_MATERIAL_CHANGED,
	CO2_PER_KG_PRINTER_MATERIAL_CHANGED,
	CO2_PER_KW_CHANGED,
	CO2_PRINTER_MATERIAL_DELETED,
	CO2_PRINTER_TYPICAL_KWH_DELETED,
	CO2_TM_TYPICAL_KWH_DELETED,
	DISPOSE_FACTOR_MATERIAL_CHANGED,
	DISPOSE_FACTOR_MATERIAL_DELETED,
	DISPOSE_FACTOR_NEW_MATERIAL_ADDED,
	DISPOSE_FACTOR_NEW_PRINTER_MATERIAL_ADDED,
	DISPOSE_FACTOR_PRINTER_MATERIAL_CHANGED,
	DISPOSE_FACTOR_PRINTER_MATERIAL_DELETED,
	CO2_USER_SETTINGS_CARBON_CHANGED,
	END_OF_LIFE_DISPOSE_FACTOR_CHANGED,
	GET_CO2_MATERIAL,
	PART_LIFE_TIME_CHANGED,
	PRINTER_TYPICAL_KWH_CHANGED,
	TM_TYPICAL_KWH_CHANGED,
	YEARLY_SAVED_AMOUNT_CHANGED
} from 'global actions/types'

interface IState {
	disposeFactorPrinterMaterial: string
	disposeFactorMaterial: string
	co2PerKgMaterial: string
	co2PerKgPrinterMaterial: string
	co2PerKwValue: Record<string, number>
	initialCo2PerKwValue: Record<string, number>
	puAnnualKgCO2Value: string
	puYearsCO2Value: string
	disposeFactorValue: string
	holdFactorValue: string
	carbonCreditCostValue: string
	typicalKwhPrinter: string
	typicalKwhTm: string
	materialList: any
	disposeMaterialList: any
	disposePrinterList: any
	printersList: any
	cycleList: any
	typicalKwhList: any
	printersTypicalkwhList: any
}

const INITIAL_STATE: IState = {
	disposeFactorPrinterMaterial: '',
	disposeFactorMaterial: '',
	co2PerKgMaterial: '',
	co2PerKgPrinterMaterial: '',
	co2PerKwValue: {},
	initialCo2PerKwValue: {},
	puAnnualKgCO2Value: '',
	puYearsCO2Value: '',
	disposeFactorValue: '',
	holdFactorValue: '',
	carbonCreditCostValue: '',
	typicalKwhPrinter: '',
	typicalKwhTm: '',
	materialList: [],
	disposeMaterialList: [],
	disposePrinterList: [],
	printersList: [],
	cycleList: [],
	typicalKwhList: [],
	printersTypicalkwhList: []
}

const CustomizeCO2CalculationReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case GET_CO2_MATERIAL: {
			const {
				materialList,
				cycleList,
				typicalKwhList,
				printersList,
				printersTypicalkwhList,
				disposeMaterialList,
				disposePrinterList,
				co2PerKW
			} = action.payload

			return {
				...state,
				initialCo2PerKwValue: co2PerKW,
				co2PerKwValue: co2PerKW,
				materialList: materialList,
				disposePrinterList: disposePrinterList,
				disposeMaterialList: disposeMaterialList,
				cycleList: cycleList,
				typicalKwhList: typicalKwhList,
				printersList: printersList,
				printersTypicalkwhList: printersTypicalkwhList
			}
		}

		case CO2_PER_KG_MATERIAL_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				co2PerKgMaterial: value
			}
		}

		case CO2_PER_KG_PRINTER_MATERIAL_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				co2PerKgPrinterMaterial: value
			}
		}

		case DISPOSE_FACTOR_MATERIAL_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				disposeFactorMaterial: value
			}
		}

		case DISPOSE_FACTOR_PRINTER_MATERIAL_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				disposeFactorPrinterMaterial: value
			}
		}

		case PRINTER_TYPICAL_KWH_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				typicalKwhPrinter: value
			}
		}

		case TM_TYPICAL_KWH_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				typicalKwhTm: value
			}
		}

		case YEARLY_SAVED_AMOUNT_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				puAnnualKgCO2Value: value
			}
		}

		case PART_LIFE_TIME_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				puYearsCO2Value: value
			}
		}

		case END_OF_LIFE_DISPOSE_FACTOR_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				disposeFactorValue: value
			}
		}

		case CO2_HOLD_FACTOR_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				holdFactorValue: value
			}
		}

		case CO2_CARBON_CREDIT_COST_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				carbonCreditCostValue: value
			}
		}

		case CO2_PER_KW_CHANGED: {
			const { name, value } = action.payload
			return {
				...state,
				co2PerKwValue: {
					...state.co2PerKwValue,
					[name]: value
				}
			}
		}

		case CO2_USER_SETTINGS_CARBON_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				co2PerKwValue: value,
				initialCo2PerKwValue: value
			}
		}

		case CO2_NEW_MATERIAL_ADDED: {
			const { newMaterial } = action.payload
			const changedUserMaterialList = getUpdatedMaterial(
				newMaterial,
				state.materialList,
				CO2Params.co2PerKg
			)
			return {
				...state,
				materialList: changedUserMaterialList,
				co2PerKgMaterial: ''
			}
		}

		case DISPOSE_FACTOR_NEW_MATERIAL_ADDED: {
			const { newMaterial } = action.payload
			const changedUserMaterialList = getUpdatedMaterial(
				newMaterial,
				state.disposeMaterialList,
				CO2Params.disposeFactor
			)
			return {
				...state,
				disposeMaterialList: changedUserMaterialList,
				disposeFactorMaterial: ''
			}
		}

		case CO2_MATERIAL_DELETED: {
			const { materialToDelete } = action.payload
			const changedUserMaterialList = state.materialList.filter(
				(materials: { material: any; id: any }) =>
					materials.material.id !== materialToDelete
			)
			return {
				...state,
				materialList: changedUserMaterialList
			}
		}
		case CO2_PRINTER_MATERIAL_DELETED: {
			const { materialToDelete } = action.payload
			const changedUserprintersList = state.printersList.filter(
				(materials: { material: any; id: any }) =>
					materials.material.id !== materialToDelete
			)
			return {
				...state,
				printersList: changedUserprintersList
			}
		}
		case DISPOSE_FACTOR_PRINTER_MATERIAL_DELETED: {
			const { materialToDelete } = action.payload
			const changedUserPrintersList = state.disposePrinterList.filter(
				(materials: { material: any; id: any }) =>
					materials.material.id !== materialToDelete
			)
			return {
				...state,
				disposePrinterList: changedUserPrintersList
			}
		}
		case DISPOSE_FACTOR_MATERIAL_DELETED: {
			const { materialToDelete } = action.payload
			const changedUserMaterialList = state.disposeMaterialList.filter(
				(materials: { material: any; id: any }) =>
					materials.material.id !== materialToDelete
			)
			return {
				...state,
				disposeMaterialList: changedUserMaterialList
			}
		}
		case CO2_NEW_PRINTER_MATERIAL_ADDED: {
			const { newPrinterMaterial } = action.payload
			const changedUserPrinterMaterialList = getUpdatedMaterial(
				newPrinterMaterial,
				state.printersList,
				CO2Params.co2PerKg
			)

			return {
				...state,
				printersList: changedUserPrinterMaterialList,
				co2PerKgPrinterMaterial: ''
			}
		}

		case DISPOSE_FACTOR_NEW_PRINTER_MATERIAL_ADDED: {
			const { newPrinterMaterial } = action.payload
			const changedUserPrinterMaterialList = getUpdatedMaterial(
				newPrinterMaterial,
				state.disposePrinterList,
				CO2Params.disposeFactor
			)

			return {
				...state,
				disposePrinterList: changedUserPrinterMaterialList,
				disposeFactorPrinterMaterial: ''
			}
		}

		case CO2_NEW_PRINTER_TYPICAL_KWH_ADDED: {
			const { printersTypicalkwhList } = action.payload

			return {
				...state,
				printersTypicalkwhList: printersTypicalkwhList,
				typicalKwhPrinter: ''
			}
		}
		case CO2_NEW_TM_TYPICAL_KWH_ADDED: {
			const { cycle } = action.payload
			let changedTypicalKwhList = state.typicalKwhList
			const isCycle = changedTypicalKwhList.findIndex(
				(cycleObject: any) => cycleObject.cycleName === cycle.cycleName
			)
			if (isCycle === -1) {
				changedTypicalKwhList.push(cycle)
			} else {
				changedTypicalKwhList[isCycle] = {
					...changedTypicalKwhList[isCycle],
					...cycle
				}
			}

			return {
				...state,
				typicalKwhList: changedTypicalKwhList,
				typicalKwhTm: ''
			}
		}
		case CO2_PRINTER_TYPICAL_KWH_DELETED: {
			const { printersTypicalkwhList } = action.payload
			return {
				...state,
				printersTypicalkwhList: printersTypicalkwhList
			}
		}
		case CO2_TM_TYPICAL_KWH_DELETED: {
			const { cycleToDelete } = action.payload
			let changedTypicalKwhList = state.typicalKwhList.filter(
				(typicalKwhObject: { id: any }) => typicalKwhObject.id !== cycleToDelete
			)
			return {
				...state,
				typicalKwhList: changedTypicalKwhList
			}
		}

		default:
			return state
	}
}

export default CustomizeCO2CalculationReducer
