import React, { FC, memo, useCallback, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import PartCardActionIcons from '../../PartCard/PartCardActionIcons'
import {
	renderBenefits,
	renderConsolidationDeletionAlert,
	stopEvent
} from '../../ProjectPartHelpers'
import {
	getPartStatusAndIcon,
	onViewerModelError,
	useGetPartInfo,
	usePartGeometryAnalysis
} from './PartsListViewService'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import InfoBox from 'Scenes/Components/InfoBox'
import PartImageWithFallback from 'Scenes/Components/PartImageWithFallback/PartImageWithFallback'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { starPartClicked } from 'Scenes/Components/StarIcon/StarIconActions'
import ThreeDButton from 'Scenes/Components/ThreeDButton/ThreeDButton'
import GeometryAnalysisReviewAndFixes from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/GeometryAnalysisReviewAndFixes'
import {
	onPartClick,
	onPartPropertiesClick,
	onRemovePartClick,
	onRemovePartConfirm
} from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisActions'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { Part, PartStatus } from 'Services/models/IPart'
import { FormatType } from 'Services/models/IPart'
import {
	CASTING_CNC,
	LATHE_CNC,
	OPTIMIZED,
	SHEET_METAL
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

type IProps = {
	part: Part & Record<any, any>
	leadingConfigurationData: IBestMatchData[]
	isBundle: boolean
	toPrint?: boolean
	isQuickCADUpload?: boolean
	isGenerativeDesignOn?: boolean
}

const PartRow: FC<IProps> = ({
	part,
	leadingConfigurationData,
	isBundle,
	toPrint = false,
	isQuickCADUpload,
	isGenerativeDesignOn
}) => {
	const [showAlert, setShowAlert] = useState(false)
	const [showGeometryAnalysis, setShowGeometryAnalysis] = useState(false)
	const {
		id,
		imageURL,
		partNumber,
		starred,
		isDefaultMaterial,
		formatType,
		externalId,
		thumbnailURL
	} = part
	const user = useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()

	const showRemovePart = Feature.isFeatureActive(FeatureComponentId.DELETE_PART)
	const review3dInProjectAnalysis = Feature.isFeatureOn(
		FeatureComponentId.REVIEW_3D_IN_PROJECT_ANALYSIS
	)
	const allowRemoveAssemblyPart = Feature.isFeatureActive(
		FeatureComponentId.DELETE_ASSEMBLY_PART
	)
	const showRemove = part?.parentAssemblyId
		? allowRemoveAssemblyPart
		: showRemovePart
	const isAwaitingStatus = part?.status === PartStatus.awaitingCombinedHeatmap

	const {
		materialName,
		isInapplicable,
		isPrintable,
		costEffectiveQuantity,
		printerTechnologyName,
		printerMaterialName,
		printerTechnologyDescription,
		printerMaterialDescription
	} = useGetPartInfo(leadingConfigurationData, part)

	const {
		configuration,
		solution,
		configurationPrintIssues,
		wallThickessModelURL,
		chosenOrientationVector
	} = usePartGeometryAnalysis(leadingConfigurationData, part)

	const {
		iconName,
		resultLabel,
		partIsFailed,
		isPartNotCostEffective,
		isPartUpdating,
		isPartUnprintable
	} = getPartStatusAndIcon(part.status, part.result, !!isInapplicable)

	const isOptimizationAnalysisOn = Feature.isFeatureOn(
		FeatureComponentId.OPTIMIZATION_ANALYSIS
	)

	const textPopup =
		isPartNotCostEffective ||
		partIsFailed ||
		(isPartUnprintable && !review3dInProjectAnalysis)

	const onPartRowClick = () => {
		if (isQuickCADUpload) return
		isInapplicable
			? dispatch(onPartPropertiesClick(part.projectId))
			: dispatch(onPartClick(part, false, user))
	}
	const onStarPartClicked = () => dispatch(starPartClicked(part))
	const onShowModalOrRemove = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			stopEvent(e)
			if (part.isUsedByCluster) {
				setShowAlert(true)
			} else {
				dispatch(onRemovePartClick(id, partNumber))
			}
		},
		[dispatch, id, part.isUsedByCluster, partNumber]
	)
	const onConfirmRemove = useCallback(() => {
		setShowAlert(false)
		dispatch(onRemovePartConfirm(id, externalId, isBundle))
	}, [dispatch, externalId, id, isBundle])

	const openGeometryAnalysis = () => {
		setShowGeometryAnalysis(true)
	}

	const closeGeometryAnalysis = () => {
		setShowGeometryAnalysis(false)
	}

	const renderResultPopup = () => {
		if (review3dInProjectAnalysis) {
			return isPartNotCostEffective ? (
				getString('QUICK_UPLOAD_OFF_THE_SHELF')
			) : partIsFailed ? (
				getString('QUICK_UPLOAD_NOT_SUITABLE_FOR_PROCESSING')
			) : (
				<ThreeDButton
					show3dModalIframe={openGeometryAnalysis}
					buttonClassName="parts-list--part-row--three-d"
					buttonText={getString('THREE_D_REVIEW')}
				/>
			)
		}
		return (
			<div data-qa="data-qa-part-row-hint">
				{part?.unprintableReason}
				{!!part.extraUnprintableReasonsCount && (
					<span className="parts-list--part-row--extra-reasons">
						+{part.extraUnprintableReasonsCount}
					</span>
				)}
			</div>
		)
	}

	const moсkPartResultAnalysis = (part: Part, result: string) => {
		if (!isOptimizationAnalysisOn) return result

		if (part.sheetMetal) {
			return SHEET_METAL
		}

		switch (part.result) {
			case CADAnalysisResult.borderline:
				return OPTIMIZED
			case CADAnalysisResult.printable:
				return LATHE_CNC
			case CADAnalysisResult.notPrintable:
				return CASTING_CNC
			default:
				return result
		}
	}

	return (
		<>
			<div
				className={cx('parts-list--part-row', {
					'in-quick-cad-upload': isQuickCADUpload,
					'is-generative-parts': isGenerativeDesignOn
				})}
				data-qa="data-qa-part-row-view"
				onClick={onPartRowClick}
			>
				<div
					className={cx('parts-list--part-row--cell-img', {
						drawing: formatType === FormatType.pdf
					})}
				>
					<PartImageWithFallback
						data-qa={`data-qa-list-view-part-img-${partNumber}`}
						fallbackClassName="cell-fallback-img"
						className="cell-img"
						src={thumbnailURL || imageURL}
						alt={partNumber}
						formatType={formatType}
					/>
				</div>
				<div
					data-qa={`data-qa-part-filed-${partNumber}`}
					className="parts-list--part-row--cell-text pure-text"
					title={partNumber}
				>
					{partNumber}
				</div>
				<div
					className="parts-list--part-row--cell-text"
					data-qa="part-row-part-status"
				>
					<DetailsPopup
						popperDirection="bottom-start"
						inPortal={!isQuickCADUpload}
						isHover={
							!isAwaitingStatus &&
							((review3dInProjectAnalysis && !isPartUpdating) ||
								isPartUnprintable)
						}
						data={renderResultPopup()}
						popperClassName={
							textPopup ? 'not-cost-effective-popup' : 'three-d-button-popup'
						}
						popperContactClassName={
							textPopup
								? 'not-cost-effective-popup--data'
								: 'three-d-button-popup--data'
						}
					>
						{!isOptimizationAnalysisOn && !isPartUpdating && (
							<IconFactory
								iconName={iconName}
								className="card--result-label-icon"
							/>
						)}
						<div
							title={
								isPartUpdating ||
								(!review3dInProjectAnalysis && !isPartUnprintable)
									? resultLabel
									: null
							}
							data-qa="data-qa-part-row-printability-status"
						>
							{moсkPartResultAnalysis(part, resultLabel)}
						</div>
					</DetailsPopup>
				</div>
				<div
					className="parts-list--part-row--cell-text"
					data-qa="part-row-benefits-section"
				>
					{renderBenefits(part)}
				</div>
				<div
					className="parts-list--part-row--cell-text with-info"
					data-qa="part-row-printer-technology"
				>
					{printerTechnologyName}
					{isPrintable && !toPrint && isQuickCADUpload && (
						<InfoBox
							boxContactElement={printerTechnologyDescription}
							iconClassName="parts-list--header--info-icon"
							inPortal
						/>
					)}
				</div>
				<div
					className="parts-list--part-row--cell-text"
					title={materialName}
					data-qa="part-row-original-material"
				>
					{!part?.isAmOriginalMaterial && (isPrintable || materialName)
						? materialName && (
								<span className={cx({ defaultText: isDefaultMaterial })}>
									{materialName}
								</span>
						  )
						: '-'}
				</div>
				<div
					className="parts-list--part-row--cell-text with-info"
					data-qa="part-row-printer-material"
				>
					<span title={printerMaterialName}>{printerMaterialName}</span>
					{isPrintable && !toPrint && isQuickCADUpload && (
						<InfoBox
							boxContactElement={printerMaterialDescription}
							iconClassName="parts-list--header--info-icon"
							inPortal
						/>
					)}
				</div>
				{isGenerativeDesignOn && (
					<>
						<div
							className="parts-list--part-row--cell-text pure-text"
							data-qa="part-row-volume"
							title={part.generativeVolume}
						>
							{(part.generativeVolume &&
								Number(part.generativeVolume).toFixed(2)) ||
								'-'}
						</div>
						<div
							className="parts-list--part-row--cell-text pure-text"
							data-qa="part-row-mass"
							title={part.generativeMass}
						>
							{(part.generativeMass &&
								Number(part.generativeMass).toFixed(2)) ||
								'-'}
						</div>
						<div
							className="parts-list--part-row--cell-text pure-text"
							data-qa="part-row-stress"
							title={part.generativeStress}
						>
							{(part.generativeStress &&
								Number(part.generativeStress).toFixed(2)) ||
								'-'}
						</div>
						<div
							className="parts-list--part-row--cell-text pure-text"
							data-qa="part-row-safety"
							title={part.generativeMinFactorSafety}
						>
							{(part.generativeMinFactorSafety &&
								Number(part.generativeMinFactorSafety).toFixed(2)) ||
								'-'}
						</div>
						<div
							className="parts-list--part-row--cell-text pure-text"
							data-qa="part-row-max-displacement-global"
							title={part.generativeMaxDisplacementGlobal}
						>
							{(part.generativeMaxDisplacementGlobal &&
								Number(part.generativeMaxDisplacementGlobal).toFixed(2)) ||
								'-'}
						</div>{' '}
					</>
				)}
				{!toPrint && !isQuickCADUpload ? (
					<div className="parts-list--part-row--cell-text star-delete">
						<PartCardActionIcons
							starred={starred}
							onStarClicked={onStarPartClicked}
							onRemoveClicked={onShowModalOrRemove}
							showRemove={showRemove}
						/>
					</div>
				) : isQuickCADUpload ? (
					<div className="parts-list--part-row--cell-text pure-text">
						{costEffectiveQuantity}
					</div>
				) : null}
				{showAlert &&
					renderConsolidationDeletionAlert({
						show: showAlert,
						onCancel: () => setShowAlert(false),
						onConfirm: onConfirmRemove
					})}
			</div>
			{showGeometryAnalysis && configuration && (
				<GeometryAnalysisReviewAndFixes
					show={showGeometryAnalysis}
					partImageUrl={wallThickessModelURL}
					configurationPrintIssues={configurationPrintIssues}
					orientationVector={chosenOrientationVector}
					configuration={configuration}
					solution={solution}
					printIssues={user?.printIssues}
					partHealedStlURLExist={!!part?.healedStlURL}
					printIssueLoaders={{}}
					onWallThicknessClick={() => {}}
					onTolerancesClick={() => {}}
					part={configuration?.part}
					onPartPrintIssueChanged={() => {}}
					onMeshHealingClick={() => {}}
					onViewerModelError={(error: any) =>
						onViewerModelError(error, dispatch, closeGeometryAnalysis)
					}
					onCancel={closeGeometryAnalysis}
				/>
			)}
		</>
	)
}

export default memo(PartRow)
