import { cloneDeep, isEmpty, some } from 'lodash'

import {
	RESET_SETTINGS_STATE,
	UPLOAD_PROJECT_SCENARIO_DATA_APPLIED,
	UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED,
	UPLOAD_PROJECT_SCENARIO_DATA_CANCEL,
	UPLOAD_PROJECT_SCENARIO_DATA_CHANGE,
	UPLOAD_PROJECT_SCENARIO_DATA_EDIT,
	UPLOAD_PROJECT_SCENARIO_DATA_ENDED,
	UPLOAD_PROJECT_SCENARIO_DATA_ERROR,
	UPLOAD_PROJECT_SCENARIO_DATA_STARTED
} from '../../../../global actions/types'
import {
	getErrorMessage,
	setupValueLabels
} from './CustomizeSupplyChainService'

interface IState {
	supplyChainTableData: any[]
	initialDataValues: any[]
	isEdit: boolean
	isLoading: boolean
	isError: boolean
	isApplyDisabled: boolean
	projectScenarioSettings: any[]
	defaultUserScenario: string
	baseDefaultProjectScenarios: any[]
}

const INITIAL_STATE: IState = {
	supplyChainTableData: [],
	initialDataValues: [],
	isEdit: false,
	isLoading: false,
	isError: false,
	isApplyDisabled: false,
	projectScenarioSettings: [],
	defaultUserScenario: '',
	baseDefaultProjectScenarios: []
}

export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED: {
			let {
				defaultProjectScenario,
				defaultProjectScenarioParameters,
				baseDefaultProjectScenarios
			} = action.payload

			const initialDataValues = setupValueLabels(
				defaultProjectScenario,
				defaultProjectScenarioParameters
			)
			const supplyChainTableData = cloneDeep(initialDataValues)

			return {
				...state,
				initialDataValues,
				supplyChainTableData,
				projectScenarioSettings: defaultProjectScenario,
				baseDefaultProjectScenarios
			}
		}

		case UPLOAD_PROJECT_SCENARIO_DATA_EDIT: {
			return {
				...state,
				isEdit: true
			}
		}

		case UPLOAD_PROJECT_SCENARIO_DATA_APPLIED: {
			return {
				...state,
				isEdit: false
			}
		}

		case UPLOAD_PROJECT_SCENARIO_DATA_ERROR: {
			return {
				...state,
				isLoading: false,
				isError: true
			}
		}

		case UPLOAD_PROJECT_SCENARIO_DATA_STARTED: {
			return {
				...state,
				isLoading: true
			}
		}

		case UPLOAD_PROJECT_SCENARIO_DATA_ENDED: {
			let { defaultProjectScenario, defaultProjectScenarioParameters } =
				action.payload
			const initialDataValues =
				(defaultProjectScenario &&
					setupValueLabels(
						defaultProjectScenario,
						defaultProjectScenarioParameters
					)) ||
				state.supplyChainTableData

			return {
				...state,
				isLoading: false,
				isError: false,
				isEdit: false,
				supplyChainTableData: initialDataValues,
				initialDataValues
			}
		}

		case UPLOAD_PROJECT_SCENARIO_DATA_CANCEL: {
			return {
				...state,
				supplyChainTableData: state.initialDataValues,
				isEdit: false
			}
		}

		case UPLOAD_PROJECT_SCENARIO_DATA_CHANGE: {
			const { supplyChainTableData } = state
			const { value, name, labelKey } = action.payload
			const changedDataValue = cloneDeep(supplyChainTableData)
			let isApplyDisabled = false

			changedDataValue.map((row: any) => {
				if (row.key === name) {
					const idx = row.data.findIndex(
						(elem: any) => elem.labelKey === labelKey
					)
					row.data[idx].value = value
					row.data[idx].error = getErrorMessage(row.key, value)
				}
				return row
			})

			isApplyDisabled = some(changedDataValue, (row: any) =>
				some(row.data, (elem: any) => !isEmpty(elem.error))
			)

			return {
				...state,
				isApplyDisabled,
				supplyChainTableData: changedDataValue
			}
		}

		case RESET_SETTINGS_STATE: {
			return INITIAL_STATE
		}

		default:
			return state
	}
}
