import { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'

import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import HeaderCard from '../../Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import LicenseDropZone from './LicenseDropZone'
import { getLicenseMessage, LicenseData } from './LicenseManagerService'
import { UPLOAD_LICENSE_SCRIPT } from 'global actions/types'
import { SKIP_LICENSE_LS_KEY } from 'Services/Constants'
import { LOGIN_ROUTE } from 'Services/Constants/RoutesConstants'
import { useSkipKeyDown } from 'Services/CustomHooks/useSkipKeyDown'
import history from 'Services/history'
import {
	getJsonItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { LICENSE_UPDATE } from 'Services/Strings'
import { getTheme } from 'themes/getTheme'

import './index.scss'

const { cardBoxColor } = getTheme()

interface ILocationState {
	licenseData: LicenseData
}

const License: FC = () => {
	const dispatch = useDispatch()
	const { isLicense } = useSelector(
		(state: RootStateOrAny) => state.LicenseReducer
	)
	const { state: { licenseData } = {} } = useLocation() as {
		state?: ILocationState
	}
	const massageLicense = getLicenseMessage(licenseData)

	useSkipKeyDown(() => {
		dispatch({
			type: UPLOAD_LICENSE_SCRIPT,
			payload: true
		})
		setStringItemToLocalStorage(SKIP_LICENSE_LS_KEY, 'true')
		history.push(LOGIN_ROUTE)
	})

	useEffect(() => {
		const isSkipLicense = getJsonItemFromLocalStorage(SKIP_LICENSE_LS_KEY)
		if (isSkipLicense === true) {
			dispatch({
				type: UPLOAD_LICENSE_SCRIPT,
				payload: true
			})

			history.push(LOGIN_ROUTE)
		}
	}, [dispatch])

	useEffect(() => {
		if (isLicense) history.push(LOGIN_ROUTE)
	}, [isLicense])

	return (
		<div className="upload-script">
			<NavBarAndMaterial title={LICENSE_UPDATE}>
				<div className="upload-script-body">
					<HeaderCard
						cardTitle={LICENSE_UPDATE}
						cardSubtitle=""
						headerColor={cardBoxColor || 'green'}
						content={
							<div>
								<br />
								<p className="upload-script-error">{massageLicense}</p>
								<br />
								<Divider />
								<br />
								<LicenseDropZone />
							</div>
						}
					/>
				</div>
			</NavBarAndMaterial>
		</div>
	)
}

export default memo(License)
