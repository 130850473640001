import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import Flexbox from 'Scenes/Components/FlexBox'
import { ISimpleConfigurationMaterial } from 'Scenes/Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { NAME, ORGANIZATION_ID } from 'Services/Constants'
import { PrinterMaterial } from 'Services/models/IPrinterMaterial'
import { getString } from 'Services/Strings/StringService'

export interface Placeholders {
	selectMaterialPlaceHolder: string
	selectMaterialTypePlaceholder: string
	selectMaterialCategoryPlaceholder: string
}

export const renderCategorySelector = (
	props: any,
	label: string,
	materialCategoryChanged: Function,
	placeholder: string
) => {
	const {
		showCategorySelector,
		materialCategory,
		materialCategoriesArr,
		isButtonSelector,
		disableMaterialCategory
	} = props
	const selectClassName = isButtonSelector
		? 'field-with-label outlined'
		: 'material-selector material-middle-selector'

	if (!showCategorySelector) {
		return <div />
	}

	return renderSelectField(
		`${selectClassName} material-category`,
		materialCategory,
		materialCategoryChanged,
		placeholder,
		materialCategoriesArr,
		undefined,
		'',
		props,
		label,
		disableMaterialCategory
	)
}

export const renderSelectField = (
	className: string,
	value: string,
	onChange: any,
	disabledMenuItem: string,
	menuItems: Record<string, any>[],
	renderMenuItems: any,
	type: string,
	props: any,
	label?: string,
	disableField?: boolean
) => {
	const {
		disabled,
		selectClassName = '',
		isButtonSelector,
		rootSelectClassName = ''
	} = props
	return (
		<div>
			{isButtonSelector && <div className="label">{label}</div>}
			<Select
				data-qa={`data-qa-${label}`}
				title={value}
				disabled={disabled || disableField}
				className={className}
				value={value || ''}
				renderValue={() => (disableField ? getString('SELECT') : value || '')}
				onChange={onChange}
				classes={{
					select: selectClassName,
					root: rootSelectClassName
				}}
				inputProps={{
					name: 'simpleSelect',
					id: 'simple-select'
				}}
				MenuProps={{
					PaperProps: {
						style: {
							transform: 'translate3d(0, 0, 0)'
						}
					}
				}}
			>
				<MenuItem disabled>{disabledMenuItem}</MenuItem>
				{renderMenuItems
					? renderMenuItems
					: menuItems?.map((menuItem: Record<string, any>) => {
							return (
								<MenuItem
									key={type ? menuItem[type] : menuItem}
									style={{ textTransform: 'capitalize' }}
									value={type ? menuItem[type] : menuItem}
								>
									{type ? menuItem[type] : menuItem}
								</MenuItem>
							)
					  })}
			</Select>
		</div>
	)
}

export const renderButtonField = (
	value: string,
	menuItems: any[],
	qaDataElementNameType: any,
	label: string,
	materialTypeChanged: Function
) => {
	return (
		<div>
			<div className="label">{label}</div>
			<Flexbox>
				{menuItems?.map((menuItem: string) => {
					const selected = menuItem === value

					return (
						<CastorSelectBox
							key={menuItem}
							setSelectedType={() => {
								materialTypeChanged(menuItem)
							}}
							selected={selected}
							boxClassName="material-selector--box"
							qaDataElementName={`data-qa-${menuItem}`}
						>
							{menuItem}
						</CastorSelectBox>
					)
				})}
			</Flexbox>
		</div>
	)
}

export const sortPrinterMaterials = (materials: PrinterMaterial[]) => {
	return materials.sort(dynamicSort([ORGANIZATION_ID, NAME]))
}
export const dynamicSort = (properties: string[]) => {
	return function (
		a: PrinterMaterial | Record<string, any>,
		b: PrinterMaterial | Record<string, any>
	) {
		for (let i = 0; i < properties.length; i++) {
			let prop = properties[i]
			if (prop === ORGANIZATION_ID) {
				if (a[prop] > b[prop]) return -1
				if (a[prop] < b[prop]) return 1
			}
			if (prop === NAME) {
				if (a[prop] < b[prop]) return -1
				if (a[prop] > b[prop]) return 1
			}
		}
		return 0
	}
}
