import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isEmpty, omit, toLower } from 'lodash'

import { getPostProcessIdsWithAbnormalValues } from '../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisService'
import { IConfigureProperty } from './NewPartConfigurationTypes'
import {
	HANDLE_LOADER,
	MATERIAL_CATEGORY_CHANGED,
	MATERIAL_CHANGED,
	MATERIAL_TYPE_CHANGED
} from 'global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import {
	GET_NEW_CONFIGURATION_FIELDS,
	NEW_CONFIGURATION_CHANGE_MANUFACTURING,
	NEW_CONFIGURATION_CHANGE_OBJECTIVE,
	NEW_CONFIGURATION_CHANGE_PRINTER,
	NEW_CONFIGURATION_CHANGE_PROPERTY_NAME,
	NEW_CONFIGURATION_LOADED,
	NEW_CONFIGURATION_LOADING,
	NEW_CONFIGURATION_SAVE_PROPERTIES
} from 'global actions/types/partAnalysisTypes'
import { History } from 'history'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { MaterialSelectorService } from 'Scenes/Components/MaterialSelector/TmMaterialSelector/MaterialSelectorService'
import {
	ISimpleConfigurationCompany,
	SimpleConfigurationSelectorService
} from 'Scenes/Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { showCustomFunctionWarningPopup } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import {
	defaultMetal,
	defaultProperties,
	defaultPropertiesOptions,
	manufacturingMethodTypes,
	materialCategories,
	materialTypes,
	POLLER_DELAY
} from 'Services/Constants'
import { timeout } from 'Services/global/timeout'
import { ChainBenefitsNames } from 'Services/models/IChainBenefits'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { IMaterialCategory, Material } from 'Services/models/IMaterial'
import { Part, PartStatus } from 'Services/models/IPart'
import { Printer } from 'Services/models/IPrinter'
import {
	IDefaultConfiguration,
	IPrinter,
	IPrintersTypes
} from 'Services/models/IPrintersTypes'
import { IPriority } from 'Services/models/IPriority'
import {
	createOrUpdateSolution,
	getPart,
	getPartSolutions,
	updateSolutionQuantity
} from 'Services/Network/PartAnalysisNetwork'
import { partConfigurationRoute } from 'Services/routeFuncs'
import {
	NEW_CONFIGURATION_ERROR,
	NEW_PART_CONFIGURATION_SEND_FAILURE_TEXT,
	OK
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { printLeadTimeBreakDown } from 'Services/Utils/consoleQAUtils'
import { getTheme } from 'themes/getTheme'

let materialsService: MaterialSelectorService
const { defaultMaterial } = getTheme()

const convertMaterialId = (
	materials: Array<Material>,
	partMaterialId: number
) => {
	if (materials && materials.length) {
		const partMaterialById = materials.find(
			(material: Material) => material.id === partMaterialId
		)
		if (!partMaterialById) {
			return defaultMaterial
		}
		return partMaterialById
	}
}

const createPrintersArrayOfCompany = (
	confSelector: SimpleConfigurationSelectorService
): any => {
	let companyPrinters: any = []
	const names = confSelector.getCompaniesNamesList()

	names.forEach((elem: string) => {
		const newObj = {
			company: elem,
			printers: confSelector.getPrintersNamesList(elem)
		}
		companyPrinters.push(newObj)
	})

	return companyPrinters
}

export const getManufacturingName = (manufacturingMethod: string) => {
	switch (manufacturingMethod) {
		case manufacturingMethodTypes.mold:
			return ImanufacturingTypes.mold
		case manufacturingMethodTypes.cnc:
			return ImanufacturingTypes.cnc
		case manufacturingMethodTypes.cast:
			return ImanufacturingTypes.cast
		case manufacturingMethodTypes.standardCost:
			return ImanufacturingTypes.standardCost
		case manufacturingMethodTypes.investmentCast:
			return ImanufacturingTypes.investmentCast
		default:
			return ImanufacturingTypes.mold
	}
}

const getManufacturingMethod = (type: string, category: string) => {
	let manufacturingMethod: string =
		manufacturingMethodTypes[ImanufacturingTypes.cnc]
	let allowedManufacturingMethods: Array<string> = [
		manufacturingMethodTypes.cnc,
		manufacturingMethodTypes.mold
	]

	if (type === materialTypes?.metal) {
		allowedManufacturingMethods = [manufacturingMethodTypes.cnc]

		if (
			category?.toLowerCase() ===
			(materialCategories?.aluminumAlloys).toLowerCase()
		) {
			allowedManufacturingMethods.push(manufacturingMethodTypes.cast)
		}

		if (
			category?.toLowerCase() ===
			(materialCategories?.stainlessSteels).toLowerCase()
		) {
			allowedManufacturingMethods.push(manufacturingMethodTypes.investmentCast)
		}

		manufacturingMethod = manufacturingMethodTypes[ImanufacturingTypes.cnc]
	}

	if (type === materialTypes?.plastic) {
		manufacturingMethod = manufacturingMethodTypes[ImanufacturingTypes.mold]
	}

	return {
		allowedManufacturingMethods,
		manufacturingMethod
	}
}

export const getPropertiesByType = (
	type: string,
	category: string,
	properties: any
) => {
	let newProperties = {}

	for (let key of Object.keys(properties)) {
		let newProperty = properties[key]
		const firstTwoKeys = key === '1' || key === '2'
		const lastTwoKeys = key === '3' || key === '4'

		newProperty.disabled = false
		newProperty.toggled = false

		if (
			(type === materialTypes?.metal && firstTwoKeys) ||
			(type === materialTypes?.plastic && lastTwoKeys)
		) {
			newProperty.disabled = true
		}

		if (toLower(category) === toLower(defaultMetal.category) && key === '4') {
			newProperty.toggled = true
		}

		newProperties = { ...newProperties, newProperty }
	}
	return newProperties
}

export const setupNewConfiguration = (
	materials: Array<Material>,
	materialCategories: Array<IMaterialCategory>,
	initialBatchSize: number,
	partId: number,
	printersFullData: Array<ISimpleConfigurationCompany>,
	defaultConfigurations: Array<IDefaultConfiguration>,
	semiProfessionalOn: boolean
): any => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: HANDLE_LOADER, payload: 1 })

		const response = await getPartSolutions(partId)

		let {
			part: { material, materialId }
		} = response?.data

		const partMaterial: any = convertMaterialId(
			materials,
			material?.id || materialId
		)

		const inHouses = new SimpleConfigurationSelectorService(
			printersFullData,
			true,
			false
		)
		const printersFullDataForSelector = printersFullData
			// remove this map if you want to show all printers (desktop, mainstream and semi professional)
			?.map(printerData => {
				if (!semiProfessionalOn) {
					printerData.printers = printerData.printers.filter(
						printer => printer.isMainstream && !printer.isDesktop
					)
				}
				return printerData
			})
			.filter(printerData => printerData.printers.length > 0)
		const allPrinter = new SimpleConfigurationSelectorService(
			printersFullDataForSelector,
			false,
			true
		)

		let inHouseList: any = createPrintersArrayOfCompany(inHouses)
		let allPrinterList: any = createPrintersArrayOfCompany(allPrinter)

		const initialMaterialType = partMaterial?.type
		const initialMaterialCategory = partMaterial?.category
		const initMaterial = partMaterial
		const initialSolutionBatchSize = initialBatchSize

		materialsService = new MaterialSelectorService(
			materials,
			initialMaterialType,
			initialMaterialCategory,
			initMaterial,
			materialCategories
		)

		const manufacturing = getManufacturingMethod(
			partMaterial?.type,
			partMaterial?.category
		)

		const manufacturingMethod: string = manufacturing.manufacturingMethod

		const allowedManufacturingMethods: Array<string> =
			manufacturing.allowedManufacturingMethods

		const { materialTypesList, categoriesList, materialsList } =
			materialsService.getDataList()

		dispatch({
			type: GET_NEW_CONFIGURATION_FIELDS,
			payload: {
				initialMaterial: initMaterial,
				chosenMaterial: initMaterial,
				chosenMaterialType: initialMaterialType,
				chosenMaterialCategory: initialMaterialCategory,
				allowedManufacturingMethods: allowedManufacturingMethods,
				manufacturingMethod,
				materialTypesList,
				categoriesList,
				materialsList,
				initialSolutionBatchSize,
				inHouseList,
				allPrinterList,
				defaultConfigurations,
				partMaterial
			}
		})

		dispatch({ type: HANDLE_LOADER, payload: -1 })
	}
}

export const onChangeNewMaterialCategory = (value: string) => {
	const { chosenMaterialType, chosenMaterialCategory, chosenMaterial } =
		materialsService.materialCategoryChanged(value)
	const { materialTypesList, categoriesList, materialsList } =
		materialsService.getDataList()

	const manufacturing = getManufacturingMethod(
		chosenMaterialType,
		chosenMaterialCategory
	)
	const allowedManufacturingMethods: Array<string> =
		manufacturing.allowedManufacturingMethods
	const manufacturingMethod: string = manufacturing.manufacturingMethod

	return {
		type: MATERIAL_CATEGORY_CHANGED,
		payload: {
			chosenMaterialType,
			chosenMaterial,
			chosenMaterialCategory,
			materialTypesList,
			categoriesList,
			materialsList,
			allowedManufacturingMethods,
			manufacturingMethod
		}
	}
}
export const onChangeNewMaterial = (value: string) => {
	const { chosenMaterialType, chosenMaterialCategory, chosenMaterial } =
		materialsService.materialChanged(value)
	const { materialTypesList, categoriesList, materialsList } =
		materialsService.getDataList()

	const manufacturing = getManufacturingMethod(
		chosenMaterialType,
		chosenMaterialCategory
	)
	const allowedManufacturingMethods: Array<string> =
		manufacturing.allowedManufacturingMethods
	const manufacturingMethod: string = manufacturing.manufacturingMethod

	return {
		type: MATERIAL_CHANGED,
		payload: {
			chosenMaterialType,
			chosenMaterial,
			chosenMaterialCategory,
			materialTypesList,
			categoriesList,
			materialsList,
			allowedManufacturingMethods,
			manufacturingMethod
		}
	}
}

export const onChangeNewMaterialType = (value: string) => {
	const { chosenMaterialType, chosenMaterialCategory, chosenMaterial } =
		materialsService.materialTypeChanged(value)
	const { materialTypesList, categoriesList, materialsList } =
		materialsService.getDataList()

	const manufacturing = getManufacturingMethod(
		chosenMaterialType,
		chosenMaterialCategory
	)
	const allowedManufacturingMethods: Array<string> =
		manufacturing.allowedManufacturingMethods
	const manufacturingMethod: string = manufacturing.manufacturingMethod

	return {
		type: MATERIAL_TYPE_CHANGED,
		payload: {
			chosenMaterialType,
			chosenMaterialCategory,
			chosenMaterial,
			materialTypesList,
			categoriesList,
			materialsList,
			allowedManufacturingMethods,
			manufacturingMethod
		}
	}
}

export const onChangeSelectedPrinter = (
	printerConfiguration: Printer,
	key: string
): any => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: NEW_CONFIGURATION_CHANGE_PRINTER,
			payload: {
				printerConfiguration,
				key
			}
		})
	}
}

export const onChangeManufacturingMethod = (
	manufacturingMethod: string
): any => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: NEW_CONFIGURATION_CHANGE_MANUFACTURING,
			payload: {
				manufacturingMethod
			}
		})
	}
}

export const onSaveNewPriorities = (
	configurationProperties: Array<IConfigureProperty>
): any => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: NEW_CONFIGURATION_SAVE_PROPERTIES,
			payload: {
				configurationProperties
			}
		})
	}
}

export const onChangeProperty = (propertyName: string): any => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: NEW_CONFIGURATION_CHANGE_PROPERTY_NAME,
			payload: {
				propertyName
			}
		})
	}
}

export const onChangeObjectiveMethod = (objectivesValue: string): any => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: NEW_CONFIGURATION_CHANGE_OBJECTIVE,
			payload: {
				objectivesValue
			}
		})
	}
}

export const onSendNewConfiguration = (
	batchSize: number,
	yearsOfDemand: number,
	history: History,
	partId: number,
	projectId: string,
	manufacturingMethod: string,
	priorities: IPriority[],
	printerConfiguration: Printer,
	partMaterial: Material,
	chosenMaterial: Material,
	chosenPrinterConfigurationType: string,
	propertyName: string,
	turnOnSupply: boolean,
	settingScenario: string,
	fullTrayAssumption: boolean,
	isSpecifiedQuantity: boolean
): any => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: NEW_CONFIGURATION_LOADING })

		let defaultConfiguration = null,
			sendPostProcessToggles = {},
			isSimpleInhouseConfiguration = false,
			solutionName =
				printerConfiguration.name || getString('NEW_CONFIGURATION'),
			sendPriorities: any = defaultProperties,
			filters = {}

		const solutionId = 0,
			id = 0,
			clusterId = null,
			chosenPrinterConfiguration = null,
			printingOrientationVector = undefined,
			orientationChange = false,
			isSimpleConfiguration = false,
			newSupportVolume = undefined,
			sendMaterial =
				chosenMaterial && !isEmpty(chosenMaterial)
					? chosenMaterial
					: partMaterial

		const manufactureMethod = getManufacturingName(manufacturingMethod)

		switch (chosenPrinterConfigurationType) {
			case IPrintersTypes.BEST_MATCH:
				defaultConfiguration = printerConfiguration
				break
			case IPrintersTypes.CUSTOM:
				sendPriorities = priorities
				solutionName = getString('CUSTOM_CONFIGURATION')
				if (propertyName === defaultPropertiesOptions.basic) {
					sendPriorities = omit(priorities, [
						'youngsModulus',
						'percentElongationAtBreak',
						'maximumServiceTemperature'
					])
				}
				break
			case IPrintersTypes.ELIGIBLE:
			case IPrintersTypes.IN_HOUSE:
				filters = {
					printer: [printerConfiguration]
				}
				break
		}

		const chainBenefits = {
			[ChainBenefitsNames.Global]: {
				on: turnOnSupply
			},
			[ChainBenefitsNames.Ordering]: {
				on: turnOnSupply
			},
			[ChainBenefitsNames.Obsolescence]: {
				on: turnOnSupply
			},
			[ChainBenefitsNames.Maintenance]: {
				on: turnOnSupply
			}
		}

		try {
			const response = await createOrUpdateSolution(
				solutionId,
				partId,
				projectId,
				sendPriorities,
				filters,
				id,
				isSpecifiedQuantity,
				solutionName,
				sendMaterial?.id,
				null,
				batchSize,
				sendPostProcessToggles,
				clusterId,
				printingOrientationVector,
				orientationChange,
				isSimpleConfiguration,
				chosenPrinterConfiguration,
				sendMaterial,
				isSimpleInhouseConfiguration,
				newSupportVolume,
				manufactureMethod,
				yearsOfDemand,
				defaultConfiguration,
				settingScenario,
				fullTrayAssumption
			)

			if (response) {
				const { configuration = {}, solution = {} } = response?.data

				const postProcessesIdsWithAbnormalValues =
					getPostProcessIdsWithAbnormalValues(solution)

				if (
					solution?.isAbnormalPrice ||
					configuration?.traditionalCostIsAbnormal ||
					postProcessesIdsWithAbnormalValues?.length
				) {
					dispatch(
						showCustomFunctionWarningPopup(postProcessesIdsWithAbnormalValues)
					)
				}

				//Only for lead time testing
				printLeadTimeBreakDown(
					configuration?.name || '',
					configuration?.leadTimeResults?.leadTimeDetails
						?.printingLeadTimeBreakDown || {},
					solution?.leadTimeDetails?.printingLeadTimeBreakDown || {}
				)

				await startPartPoller(configuration.part)

				await updateSolutionQuantity(
					configuration?.id,
					configuration?.quantity,
					configuration?.postProcessesOptional,
					chainBenefits,
					configuration?.expectedYearsOfDemand,
					configuration?.trayOrientation
				)

				dispatch({ type: NEW_CONFIGURATION_LOADED })
				history.push(
					partConfigurationRoute(
						projectId,
						partId,
						response?.data?.configuration?.id
					)
				)
			}
		} catch (e) {
			dispatch({ type: NEW_CONFIGURATION_LOADED })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: NEW_PART_CONFIGURATION_SEND_FAILURE_TEXT,
					headerTitle: NEW_CONFIGURATION_ERROR,
					showCancel: false,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		}
	}
}

const startPartPoller = async (part: Part) => {
	let counter = 1
	let response: any = null
	let partStatus = part?.status
	const partId = part?.id
	while (partStatus && partStatus === PartStatus.awaitingCombinedHeatmap) {
		const pathArray = window.location.pathname.split('/')
		const endPath = pathArray[pathArray.length - 1]
		if (endPath !== 'new-configuration') {
			response = null
			break
		}
		await timeout(counter * POLLER_DELAY)
		response = await getPart(partId)
		partStatus = response.data.part?.status
		counter++
	}
}
