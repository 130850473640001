import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { cloneDeep } from 'lodash'

import { INVALID_FUNCTION_ERROR_CODE } from '../CustomizeCostingFunctionEditor/constants'
import {
	moveToCreateFunction,
	setupCostingFunctionEditor
} from '../CustomizeCostingFunctionEditor/CostingFunctionEditorActions'
import { getFunctionStringFromArray } from '../CustomizeCostingFunctionEditor/CostingFunctionEditorService'
import {
	FunctionCategories,
	IFunctionVariable
} from '../CustomizeCostingFunctionEditor/CustomizeCostingFunctionTypes'
import {
	chooseOptionValue,
	FUNCTION_EDITOR_ALERT_TYPE,
	getReducerStateId
} from './Constants'
import {
	DO_REFRESH_CONFIGURATIONS,
	FUNCTION_TYPE_SELECTED,
	HANDLE_NOTIFICATION,
	NEW_POST_PROCESS_CHANGED,
	POST_PROCESS_CHANGED,
	POST_PROCESS_UPDATED,
	ROLL_BACK_TO_FIRST_STEP,
	TOGGLE_FUNCTION_EDITOR_MODAL,
	USER_POST_PROCESSES_SET_UP
} from 'global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { store } from 'index'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IFunctionString } from 'Services/models/IFunctionString'
import { IFunctionStringParam } from 'Services/models/IFunctionStringParam'
import { IPostProcess } from 'Services/models/IPostProcess'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import {
	deleteUserCustomizePostProcesses,
	getFunctionStringParams,
	updateUserCustomizePostProcesses
} from 'Services/Network'
import { OK, SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { UnitSystem } from 'Services/UnitsConversionService'

export const setUpUserPostProcesses = (
	optionalPostProcesses: IPostProcess[],
	optionalPostProcessesPermissions: Record<
		number | string,
		Record<string, any>
	>,
	customPostProcessTemplate: IPostProcess,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			let allFunctionStringParams: IFunctionStringParam[] = []
			if (Feature.isFeatureOn(FeatureComponentId.COST_FUNCTION_EDITOR)) {
				const { userUnitSystem } = store.getState().user
				const customizeUnitSystem = Feature.isFeatureOn(
					FeatureComponentId.CUSTOMIZE_UNIT_SYSTEM
				)
				const unitSystem = customizeUnitSystem
					? userUnitSystem
					: UnitSystem.metric

				const response = await getFunctionStringParams(
					undefined,
					undefined,
					organizationId,
					unitSystem
				)
				allFunctionStringParams = response?.data?.functionStringParams
			}

			const postProcesses = cloneDeep(optionalPostProcesses).map(
				postProcess => {
					let selectedTechnologies: string[] = []
					if (optionalPostProcessesPermissions[postProcess.id]) {
						selectedTechnologies = Object.keys(
							optionalPostProcessesPermissions[postProcess.id]
						)
					}

					postProcess.printingTechnologies = selectedTechnologies
					postProcess.editMode = false
					postProcess.changesPerformed = false
					postProcess.loading = false
					return postProcess
				}
			)

			const postProcessTemplate: Record<string, any> = cloneDeep(
				customPostProcessTemplate
			)
			postProcessTemplate.costCalculationValue = ''
			postProcessTemplate.costCalculationType = chooseOptionValue
			postProcessTemplate.leadTimeCalculationType = chooseOptionValue
			postProcessTemplate.leadTimeCalculationValue = ''
			postProcessTemplate.printingTechnologies = []

			delete postProcessTemplate.id
			delete postProcessTemplate.postProcessId
			delete postProcessTemplate.organizationId

			dispatch({
				type: USER_POST_PROCESSES_SET_UP,
				payload: { postProcesses, postProcessTemplate, allFunctionStringParams }
			})
		} catch (error) {
			console.log(error)
		}
	}
}

export const changePostProcess = (
	updatedPostProcess: IPostProcess,
	performChanges: boolean = true
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const { postProcesses: prevPostProcesses } =
			store.getState().CustomizePostProcessesReducer

		if (updatedPostProcess.postProcessId) {
			const postProcesses = prevPostProcesses?.map(
				(prevPostProcess: IPostProcess) => {
					if (prevPostProcess.id === updatedPostProcess.id) {
						if (performChanges) {
							updatedPostProcess.changesPerformed = true
						}

						return updatedPostProcess
					}
					return prevPostProcess
				}
			)

			dispatch({
				type: POST_PROCESS_CHANGED,
				payload: { postProcesses }
			})
		} else {
			dispatch({
				type: NEW_POST_PROCESS_CHANGED,
				payload: { updatedPostProcess }
			})
		}
	}
}

export const openFunctionEditorModal = (
	postProcess: IPostProcess,
	functionEditorAlertType: string
) => {
	return {
		type: TOGGLE_FUNCTION_EDITOR_MODAL,
		payload: {
			open: true,
			postProcessInFunctionEditor: postProcess,
			functionEditorAlertType
		}
	}
}

export const closeFunctionEditorModal = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: TOGGLE_FUNCTION_EDITOR_MODAL,
			payload: {
				open: false,
				postProcessInFunctionEditor: null,
				functionEditorAlertType: ''
			}
		})
	}
}

export const setupFunctionEditorModal = (
	functionCategory: FunctionCategories,
	stateId: string,
	printingTechnologies: IPrintingTechnology[],
	editableFunctionStrings: IFunctionString[],
	organizationId?: number
) => {
	return async (dispatch: any) => {
		dispatch(
			setupCostingFunctionEditor(
				editableFunctionStrings,
				printingTechnologies,
				stateId,
				true,
				organizationId
			)
		)
		dispatch({
			type: FUNCTION_TYPE_SELECTED,
			payload: { id: stateId, type: functionCategory }
		})
		dispatch(
			moveToCreateFunction(
				functionCategory,
				[],
				[],
				stateId,
				false,
				organizationId
			)
		)
	}
}

export const saveCustomFunction = (
	postProcess: IPostProcess,
	customFunction: IFunctionVariable[],
	functionEditorAlertType: string
) => {
	return (dispatch: any) => {
		try {
			const functionString = getFunctionStringFromArray(customFunction)

			switch (functionEditorAlertType) {
				case FUNCTION_EDITOR_ALERT_TYPE.COST: {
					postProcess.costCalculationValue = functionString
					break
				}
				case FUNCTION_EDITOR_ALERT_TYPE.LEAD_TIME: {
					postProcess.leadTimeCalculationValue = functionString
					break
				}
			}

			dispatch(changePostProcess(postProcess))
			dispatch(closeFunctionEditorModal())
		} catch (err: any) {
			if (err.code === INVALID_FUNCTION_ERROR_CODE) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						text: getString('INVALID_FUNCTION_ALERT_BODY'),
						headerTitle: getString('INVALID_FUNCTION_ALERT_HEADER'),
						alertType: AlertType.WARNING,
						showCancel: false,
						onConfirm: () => {
							dispatch({
								type: ALERT_POPUP_CANCELED
							})
						},
						confirmText: OK
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: err.message
					}
				})
			}
		}
	}
}

export const saveCustomizedPostProcess = (
	postProcessToUpdate: IPostProcess,
	organizationId?: number
) => {
	return async (dispatch: any) => {
		try {
			postProcessToUpdate.loading = true
			dispatch(changePostProcess(postProcessToUpdate))
			const postProcess = cloneDeep(postProcessToUpdate)
			const printingTechnologies = postProcess.printingTechnologies

			const costReducerStateId = getReducerStateId(
				postProcess.postProcessId,
				FUNCTION_EDITOR_ALERT_TYPE.COST
			)
			const leadTimeReducerStateId = getReducerStateId(
				postProcess.postProcessId,
				FUNCTION_EDITOR_ALERT_TYPE.LEAD_TIME
			)

			delete postProcess.editMode
			delete postProcess.createdAt
			delete postProcess.updatedAt
			delete postProcess.printingTechnologies
			delete postProcess.loading
			delete postProcess.changesPerformed

			const { data } = await updateUserCustomizePostProcesses(
				postProcess,
				printingTechnologies,
				organizationId
			)

			if (data) {
				dispatch({
					type: ROLL_BACK_TO_FIRST_STEP,
					payload: { id: costReducerStateId }
				})
				dispatch({
					type: ROLL_BACK_TO_FIRST_STEP,
					payload: { id: leadTimeReducerStateId }
				})
				dispatch({
					type: POST_PROCESS_UPDATED,
					payload: {
						optionalPostProcesses: data.optionalPostProcesses,
						optionalPostProcessesPermissions:
							data.optionalPostProcessesPermissions
					}
				})
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: getString('SAVE_CUSTOM_POST_PROCESS_SUCCESS')
					}
				})
				dispatch({
					type: ALERT_POPPED,
					payload: {
						text: getString('CONSIDER_RECALCULATING_ALERT_BODY'),
						headerTitle: getString('CONSIDER_RECALCULATING_ALERT_HEADER'),
						alertType: AlertType.SUCCESS,
						showCancel: false,
						onConfirm: () => {
							dispatch({
								type: ALERT_POPUP_CANCELED
							})
						},
						confirmText: OK
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: getString('UPDATE_CUSTOM_POST_PROCESS_ERROR')
					}
				})
			}
		} catch (err: any) {
			console.log(err)
			postProcessToUpdate.loading = false
			dispatch(changePostProcess(postProcessToUpdate))
			if (err.code === INVALID_FUNCTION_ERROR_CODE) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						text: `${err.message} ${getString('INVALID_FUNCTION_ALERT_BODY')}`,
						headerTitle: getString('INVALID_FUNCTION_ALERT_HEADER'),
						alertType: AlertType.WARNING,
						showCancel: false,
						onConfirm: () => {
							dispatch({
								type: ALERT_POPUP_CANCELED
							})
						},
						confirmText: OK
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: err.message
					}
				})
			}
		}
	}
}

export const removeCustomizedPostProcess = (
	postProcess: IPostProcess,
	organizationId?: number
) => {
	return async (dispatch: any) => {
		try {
			postProcess.loading = true
			dispatch(changePostProcess(postProcess))

			const { data } = await deleteUserCustomizePostProcesses(
				postProcess.postProcessId,
				organizationId
			)

			if (data) {
				let successMessage = getString(
					'REMOVE_POST_PROCESS_CUSTOMIZATION_SUCCESS'
				)
				dispatch({
					type: POST_PROCESS_UPDATED,
					payload: {
						optionalPostProcesses: data.optionalPostProcesses,
						optionalPostProcessesPermissions:
							data.optionalPostProcessesPermissions
					}
				})
				if (postProcess.custom) {
					successMessage = getString('REMOVE_CUSTOM_POST_PROCESS_SUCCESS')
				}
				dispatch({
					type: DO_REFRESH_CONFIGURATIONS,
					payload: { doRefreshConfigurations: true }
				})
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: successMessage
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: getString('UPDATE_CUSTOM_POST_PROCESS_ERROR')
					}
				})
			}
		} catch (err: any) {
			console.log(err)
			postProcess.loading = false
			dispatch(changePostProcess(postProcess))

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message
				}
			})
		}
	}
}
