import {
	IDefaultProjectScenario,
	IDefaultProjectScenarioParameter
} from './IDefaultProjectScenario'
import { IFeature } from './IFeature'
import { IFilter } from './IFilter'
import { IFunctionString } from './IFunctionString'
import { IMaterialCategory, Material, MaterialType } from './IMaterial'
import { IUserMaterialNamesMapping } from './IMaterialNamesMapping'
import { IOnboardingWizardStep } from './IOnboardingWizardStep'
import { IPostProcess, IPostProcessBreakDown } from './IPostProcess'
import { IPrinter } from './IPrintersTypes'
import { IPrintingTechnology } from './IPrintingTechnology'
import { IPriority } from './IPriority'
import { IUserFilter } from './IUserFilter'
import { PrintIssue } from './PartPrintIssue'
import { IPage } from 'Scenes/Components/TakeATour/types'

export enum Locale {
	en = 'en',
	ja = 'ja'
}

export const Language: Record<Locale, string> = {
	[Locale.en]: 'English (US)',
	[Locale.ja]: '日本語'
}

export const Currency: Record<Locale, string> = {
	[Locale.en]: 'USD',
	[Locale.ja]: 'JPY'
}

export const CurrencyName: Record<Locale, string> = {
	[Locale.en]: 'US dollar',
	[Locale.ja]: 'Japanese yen'
}

// export enum Currency {
//   en = 'USD',
//   ja = 'JPY'
// }

// export enum CurrencyName {
//   en = 'US dollar',
//   ja = 'Japanese yen'
// }

export interface IOwner {
	admin: boolean
	backgroundCalculation: boolean
	city: null | string
	clientId: string
	company: string
	country: string
	createdAt: string
	currency: typeof Currency.en | typeof Currency.ja
	email: string
	formatted_address: string
	id: number
	lat: number
	light: boolean
	locale: Locale
	long: number
	name: string
	organizationId: number
	organization_confirmed: boolean
	organization_owner: boolean
	pagesVisited: string[]
	password_reset_token: null | string
	restartCalculation: boolean
	roles: number[]
	showInHouseSolutions: boolean
	state: null | string
	taskPriorityBonus: number
	two_factor_enabled: boolean
	two_factor_secret: null | string
	two_factor_session_created_time: null | string
	two_factor_temp_secret: null | string
	updatedAt: string
	zip_code: null | string
	desktopPrinter?: boolean
	trial?: boolean
}

export interface IUserDetails {
	name: string
	company: string
	email: string
	admin: boolean
	organization_owner: boolean
	formatted_address: string
	city: null | string
	state: null | string
	country: string
	zip_code: null | string
	pagesVisited: string[]
	wizardStep: number
}

export interface IUser {
	loggedIn: boolean
	loginPending: boolean
	stateUnknown: boolean
	user: null
	roles: number[]
	features: IFeature[]
	userOnTrial: null | boolean
	materials: Material[]
	materialTypes: MaterialType[]
	userSettings: Record<string, unknown>
	userDetails: IUserDetails
	printerTechnologies: Record<string, string[]>
	userLanguage: Locale
	userCurrencySign: string
	printersFullData: Record<string, unknown>[]
	printerTechnologiesIds: Array<string[]>
	printingTechnologies: IPrintingTechnology[]
	printers: IPrinter[]
	userMaterials: Material[]
	materialCategories: IMaterialCategory[]
	filters: IFilter[]
	userFilters: IUserFilter[]
	priorities: IPriority[]
	allOptionalPostProcessesData: IPostProcess[]
	optionalPostProcessesBreakDown: IPostProcessBreakDown[]
	applications: []
	acceptedFileTypes: string[]
	partsFileSizeLimit: number
	notSupportedUnitTypeFormats: string[]
	defaultConfigurations: Record<string, unknown>[]
	uploadProjectSelectedRadioButton: null | number
	defaultUploadProjectSelectedRadioButton: null
	defaultUploadProjectMaterialType: MaterialType
	filterPartsDefaultSelection: string | null
	printerMaterialUniqueNames: Record<string, unknown>[]
	threeDViewerURL: Record<string, unknown>
	currentPageName: string
	pagesVisited: string[] | null
	pages: IPage[]
	userCustomizationSettings: Record<string, unknown>
	printIssues: PrintIssue[]
	contactUsEmail: string
	userProviders: []
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
	doMaterialNameMappingRefresh: boolean
	disableMaterialNameMappingSaveAll: boolean
	isLoadingMaterial: boolean
	defaultProjectScenario: string
	co2PerKW: number
	puAnnualKgCO2: number
	puYearsCO2: number
	disposeFactor: number
	carbonCreditCost: number
	maxAllowedUploadParts: string
	maxAllowedLightUploadParts: string
	maxAllowedUploadProjects: string
	uploadUnitType: string
	defaultFileName: string
	isLightUser: boolean
	defaultProjectScenarios: IDefaultProjectScenario[]
	defaultProjectScenarioParameters: IDefaultProjectScenarioParameter[]
	partsBundle: number
	editableFunctionStrings: IFunctionString[]
	wizardSteps: IOnboardingWizardStep[]
	onboardingCompleted: boolean
	isOrganizationOwnerExist: boolean
}
