import { cloneDeep, isNil, isNumber, isString } from 'lodash'

import { getPartStatusAndIcon } from '../PartsListView/PartsListViewService'
import {
	ALL,
	DIFF_REDUCE,
	GENERATIVE_DESIGN_KEYS,
	HIDDEN,
	MAX_TICK_AMOUNT,
	MIN_DIFF,
	MIN_TICK_AMOUNT,
	NONE,
	result,
	status,
	tooltipSelector,
	VISIBLE
} from './Constants'
import { GenerativePart } from './PartsGraphView'
import { chartGraphDataOptions } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

type BubbleChart = {
	x: number
	y: number
	z: number
}

let prevIsOverTooltip: boolean = false
let prevIsOutMarker: boolean = false
let hoveredMarkerIndex: number | null = null

const makeVisibleTooltip = (visible: boolean) => {
	const tooltip: HTMLElement | null = document.querySelector(tooltipSelector)

	if (!tooltip) return

	if (visible) {
		tooltip.style.opacity = VISIBLE
		tooltip.style.pointerEvents = ALL
	} else {
		tooltip.style.opacity = HIDDEN
		tooltip.style.pointerEvents = NONE
	}
}

const isMouseOverTooltip = (event: MouseEvent) => {
	const tooltip: HTMLElement | null = document.querySelector(tooltipSelector)

	if (!tooltip || tooltip.style.opacity === HIDDEN) return false

	const tooltipRect = tooltip.getBoundingClientRect()
	const mouseX = event.clientX
	const mouseY = event.clientY

	return (
		mouseX >= tooltipRect.left &&
		mouseX <= tooltipRect.right &&
		mouseY >= tooltipRect.top &&
		mouseY <= tooltipRect.bottom
	)
}
const isCursorOnMarker = (event: MouseEvent) => {
	if (hoveredMarkerIndex === null) return false

	const markerElement = document.querySelector(
		'circle[rel="' + hoveredMarkerIndex + '"]'
	)

	if (!markerElement) return false

	const markerRect = markerElement.getBoundingClientRect()
	const mouseX = event.clientX
	const mouseY = event.clientY

	const markerWidth = (markerRect.width + 2) / 2
	const markerCenterX = markerRect.left + markerWidth
	const markerCenterY = markerRect.top + markerWidth

	const dx = mouseX - markerCenterX
	const dy = mouseY - markerCenterY
	const distance = Math.sqrt(dx * dx + dy * dy)
	return distance <= markerWidth
}

const handleExportCsv = (generativeDesignParts: GenerativePart[] = []) => {
	// Extract all keys from the series data and remove status from it
	const keys = Array.from(
		new Set(
			generativeDesignParts.flatMap(d =>
				Object.keys(d).filter(k => k !== status)
			)
		)
	)
	// make readable column headers
	const keysToShow = keys.map((key: any) => GENERATIVE_DESIGN_KEYS[key] || key)

	// start creation the CSV content from headers
	let csvContent = keysToShow.join(',') + '\n'

	// continue creation the CSV content with body
	generativeDesignParts.forEach((obj: any) => {
		const { resultLabel } = getPartStatusAndIcon(obj.status, obj.result, false)
		keys.forEach((key, i) => {
			if (obj.hasOwnProperty(key)) {
				if (key === result) {
					csvContent += `${resultLabel}`
				} else {
					csvContent += `${isNumber(obj[key]) ? obj[key].toFixed(4) : obj[key]}`
				}
			}
			csvContent += i < keys.length - 1 ? ',' : '\n'
		})
	})

	return csvContent
}

const removeAllEventListeners = (element: any) => {
	const parent = element.parentNode
	const clone = element.cloneNode(true)
	parent.replaceChild(clone, element)
}

const downloadCSV = (csvContent: string, exportFileName: string) => {
	const blob = new Blob([csvContent], { type: 'text/csv' })
	const url = window.URL.createObjectURL(blob)
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', exportFileName)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

const sortGenerativeParts = (generativeDesignParts: GenerativePart[] = []) =>
	generativeDesignParts
		?.filter(gp => gp.mass && gp.stress && gp.cost)
		?.sort((a, b) => a.cost - b.cost)

export const findGraphParameters = (
	generativeDesignParts: GenerativePart[] = [],
	updatedData?: Record<string, any> | null
) => {
	// check if user's input are not provided ar all
	const isNotUpdatedMinX =
		isNil(updatedData?.minX) || isString(updatedData?.minX)
	const isNotUpdatedMaxX =
		isNil(updatedData?.maxX) || isString(updatedData?.maxX)
	const isNotUpdatedMinY =
		isNil(updatedData?.minY) || isString(updatedData?.minY)
	const isNotUpdatedMaxY =
		isNil(updatedData?.maxY) || isString(updatedData?.maxY)

	// filter and sort babbles chart
	const bubbleChartData: BubbleChart[] = sortGenerativeParts(
		generativeDesignParts
	)?.map(gp => {
		return {
			x: gp.mass,
			y: gp.stress,
			z: gp.cost
		}
	})

	// verify if it's user setup value then use user's input
	const minX = isNotUpdatedMinX
		? bubbleChartData?.reduce(
				(min: number, obj: BubbleChart) => (obj.x < min ? obj.x : min),
				bubbleChartData[0]?.x
		  )
		: updatedData?.minX
	const maxX = isNotUpdatedMaxX
		? bubbleChartData?.reduce(
				(max: number, obj: BubbleChart) => (obj.x > max ? obj.x : max),
				bubbleChartData[0]?.x
		  )
		: updatedData?.maxX
	const minY = isNotUpdatedMinY
		? bubbleChartData?.reduce(
				(min: number, obj: BubbleChart) => (obj.y < min ? obj.y : min),
				bubbleChartData[0]?.y
		  )
		: updatedData?.minY
	const maxY = isNotUpdatedMaxY
		? bubbleChartData?.reduce(
				(max: number, obj: BubbleChart) => (obj.y > max ? obj.y : max),
				bubbleChartData[0]?.y
		  )
		: updatedData?.maxY
	const minZ = bubbleChartData?.reduce(
		(min: number, obj: BubbleChart) => (obj.z < min ? obj.z : min),
		bubbleChartData[0]?.z
	)
	const maxZ = bubbleChartData?.reduce(
		(max: number, obj: BubbleChart) => (obj.z > max ? obj.z : max),
		bubbleChartData[0]?.z
	)

	const singleBubblesChart = bubbleChartData.length === 1

	// we need somehow to show mass of the bubble in proportion
	if (!singleBubblesChart) {
		bubbleChartData?.forEach((obj: BubbleChart, index: number) => {
			const zDiffPercentage = (obj.z - minZ / 2) / (maxZ - minZ)
			const newZ = index * 10 + zDiffPercentage // New 'z' value based on index and proportion
			obj.z = Math.abs(newZ) || obj.z
		})
	}

	// find the difference between min/max
	let diffX = bubbleChartData?.length > 1 ? maxX - minX : maxX
	let diffY = bubbleChartData?.length > 1 ? maxY - minY : maxY

	// reduce min/max on established number
	let middleX = diffX / DIFF_REDUCE
	let middleY = diffY / DIFF_REDUCE

	// make min and max values a bit more/less than provided or use user's input,
	// to show dots inside the graph, not on the borders
	const minXValue = isNotUpdatedMinX ? minX - middleX : updatedData?.minX
	const maxXValue = isNotUpdatedMaxX ? maxX + middleX : updatedData?.maxX
	const minYValue = isNotUpdatedMinY ? minY - middleY : updatedData?.minY
	const maxYValue = isNotUpdatedMaxY ? maxY + middleY : updatedData?.maxY

	const includesMinXNumber = maxXValue - minXValue > MIN_DIFF
	const includesMinYNumber = maxYValue - minYValue > MIN_DIFF

	// check if equal values, if so
	// we need to use less than min and more than max
	let maxYCalculated =
		minYValue === maxYValue
			? maxYValue + maxYValue / MIN_TICK_AMOUNT
			: maxYValue
	let minYCalculated =
		minYValue === maxYValue
			? minYValue - minYValue / MIN_TICK_AMOUNT
			: minYValue
	let maxXCalculated =
		minXValue === maxXValue
			? maxXValue + maxXValue / MIN_TICK_AMOUNT
			: maxXValue
	let minXCalculated =
		minXValue === maxXValue
			? minXValue - minXValue / MIN_TICK_AMOUNT
			: minXValue

	// the min amount of bubble should be 3 for correct graph display
	if (!!bubbleChartData?.length && bubbleChartData?.length <= 2) {
		bubbleChartData.push({
			x: 0,
			y: 0,
			z: 0
		})
	}

	// tick amount should depends on min allowed difference
	const tickXAmountX = includesMinXNumber ? MAX_TICK_AMOUNT : MIN_TICK_AMOUNT
	const tickXAmountY = includesMinYNumber ? MAX_TICK_AMOUNT : MIN_TICK_AMOUNT

	// the values that we should use for searching closest dots
	// we take difference, tickAmount and DIFF_REDUCE that divide the data between 2 dots on similar pieces
	let tickAmountMiddleX = diffX / tickXAmountX / DIFF_REDUCE
	let tickAmountMiddleY = diffY / tickXAmountY / DIFF_REDUCE

	return {
		minX: minXCalculated > 0 ? minXCalculated : 0,
		maxX: maxXCalculated,
		minY: minYCalculated > 0 ? minYCalculated : 0,
		maxY: maxYCalculated,
		middleX: tickAmountMiddleX,
		middleY: tickAmountMiddleY,
		tickXAmount: tickXAmountX,
		tickYAmount: tickXAmountY,
		bubbleChartData
	}
}

export const graphChartData = (
	{
		minX,
		maxX,
		minY,
		maxY,
		middleX,
		middleY,
		bubbleChartData,
		tickXAmount,
		tickYAmount
	}: Record<string, any>,
	generativeDesign: GenerativePart[] = [],
	projectName: string
) => {
	const componentChartData = cloneDeep(chartGraphDataOptions)
	const generativeDesignParts = sortGenerativeParts(generativeDesign)
	const exportFileName = `${getString('GENERATIVE_DESIGN')} - ${projectName}`

	const options = {
		chart: {
			...componentChartData.chart,
			events: {
				click: (event: MouseEvent | any) => {
					const exportButton = document.querySelector('.exportCSV')
					const menu: any = document.querySelector('.apexcharts-menu')

					if (exportButton) {
						removeAllEventListeners(exportButton)
					}
					if (event?.target?.classList?.contains('exportCSV')) {
						const csvContent = handleExportCsv(generativeDesignParts)
						downloadCSV(csvContent, exportFileName)
						menu.classList.remove('apexcharts-menu-open')
					}
				},
				mouseMove: function (event: MouseEvent) {
					const isOverTooltip = isMouseOverTooltip(event)
					const isOverMarker = isCursorOnMarker(event)

					const isTooltipChanged = prevIsOverTooltip !== isOverTooltip
					const isMarkerChanged = isOverMarker !== prevIsOutMarker

					// check if cursor in tooltip area
					if (isTooltipChanged) {
						if (isOverTooltip) {
							makeVisibleTooltip(true)
						} else {
							// hide only if we are not on marker
							if (!isOverMarker) {
								makeVisibleTooltip(false)
							}
						}
					}

					// check if cursor in marker area
					if (isMarkerChanged) {
						if (!isOverMarker) {
							// hide only if we are not on tooltip
							if (!isOverTooltip) {
								makeVisibleTooltip(false)
							}

							hoveredMarkerIndex = null
						}
					}

					prevIsOutMarker = isOverMarker
					prevIsOverTooltip = isOverTooltip
				},
				mouseLeave: function () {
					makeVisibleTooltip(false)
				},
				dataPointMouseEnter: function (
					event: MouseEvent,
					chartContext: Record<string, any>,
					{ seriesIndex, dataPointIndex }: Record<string, any>
				) {
					makeVisibleTooltip(true)
					hoveredMarkerIndex = dataPointIndex
				}
			},
			zoom: {
				enabled: false
			},
			toolbar: {
				tools: {
					download: true,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
					customIcons: []
				},
				export: {
					svg: {
						filename: exportFileName
					},
					png: {
						filename: exportFileName
					}
				}
			}
		},
		colors: componentChartData.colors[1],
		xaxis: {
			min: minX,
			max: maxX,
			step: tickXAmount,
			tickAmount: tickXAmount,
			title: {
				text: getString('MASS')
			},
			labels: {
				formatter: function (value: number | string) {
					const formattedLabel = +value
					if (formattedLabel === 0) return 0
					return formattedLabel?.toFixed(4)
				}
			}
		},
		yaxis: {
			min: minY,
			max: maxY,
			step: tickYAmount,
			tickAmount: tickYAmount,
			title: {
				text: getString('STRUCTURE_CAPABILITY')
			},
			labels: {
				formatter: function (value: number | string) {
					const formattedLabel = +value
					if (formattedLabel === 0) return 0
					return formattedLabel?.toFixed(4)
				}
			}
		},
		dataLabels: {
			enabled: false
		},
		plotOptions: {
			bubble: {
				minBubbleRadius: 5,
				maxBubbleRadius: 80
			}
		},
		tooltip: {
			custom: function ({ dataPointIndex }: Record<string, any>) {
				const index = dataPointIndex
				const middleLength = generativeDesignParts.length / 2
				const bubblePart = bubbleChartData[dataPointIndex]
				const part = generativeDesignParts[index]

				let result = generativeDesignParts?.filter((gp: GenerativePart) => {
					const isEqualData = gp.mass === part.mass && gp.stress === part.stress
					const closeDataByX = Math.abs(gp.mass - part.mass) < middleX
					const closeDataByY = Math.abs(gp.stress - part.stress) < middleY
					const fullCloseData =
						closeDataByX &&
						closeDataByY &&
						bubblePart.z > (middleLength + 1) * 10

					if (
						minX < gp.mass &&
						maxX > gp.mass &&
						minY < gp.stress &&
						maxY > gp.stress
					) {
						return (
							fullCloseData ||
							isEqualData ||
							(closeDataByY &&
								gp.mass === part.mass &&
								minX < part.mass &&
								maxX > part.mass) ||
							(closeDataByX &&
								gp.stress === part.stress &&
								minY < part.stress &&
								maxY > part.stress)
						)
					}
					return false
				})

				return `
				<div class="custom-tooltip generative-tooltip" data-qa="data-qa-generative-tooltip">
					${result
						?.map((r: GenerativePart) => {
							const { iconName, resultLabel } = getPartStatusAndIcon(
								r.status,
								r.result,
								false
							)
							return `<div> 
								<span>${getString('PART_NAME')}:</span> <div class="name">${
								r.partNumber
							}</div></div>
								<div> 
								<span>${getString('COST')} $:</span> ${Number(r.cost).toFixed(2)}
								</div>
								<div> 
								<span>${getString('MASS')}:</span> ${Number(r.mass).toFixed(4)}
								</div>
								<div>
								<span>${getString('STRESS')} [Mpa]:</span> ${Number(r.stress).toFixed(4)}
								</div>	
								<div>
								<span>${getString('ADMIN_PARTS_STATUS')}:</span> <span class="${iconName
								.replace(/\s/g, '')
								.toLowerCase()}"></span>
									 ${resultLabel}
								</div>`
						})
						.join('<br/>')}
          </div>
				`
			}
		}
	}

	const series = [
		{
			name: getString('STRUCTURE_CAPABILITY'),
			data: bubbleChartData,
			dataLabels: {
				enabled: false // Disable text labels on markers
			}
		}
	]

	return {
		componentChartData: options,
		series,
		effectivePointIndex: 0
	}
}
