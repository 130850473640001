import {
	CUSTOMIZE_BUTTON_LOADER_UPDATED,
	OFF_THE_SHELF_SUBSTRING_CHANGE,
	PART_FILTERING_FORM_DATA_ARRANGED,
	RESET_SETTINGS_STATE,
	SAVE_PART_FILTERING_ERROR
} from '../../../../global actions/types'

const INITIAL_STATE = {
	partFilteringArr: [],
	loading: false,
	offTheShelfSubstrings: [],
	offTheShelfSubstringsToRemove: [],
	manufacturedSubstrings: [],
	manufacturedSubstringsToRemove: [],
	errorSavingFilterString: false,
	filteredGlobalOffTheShelf: []
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PART_FILTERING_FORM_DATA_ARRANGED:
			const { partFilteringArr, filteredGlobalOffTheShelf } = action.payload

			return {
				...state,
				partFilteringArr,
				filteredGlobalOffTheShelf
			}
		case OFF_THE_SHELF_SUBSTRING_CHANGE: {
			const {
				partFilteringArr,
				offTheShelfSubstrings,
				manufacturedSubstrings
			} = action.payload
			return {
				...state,
				partFilteringArr,
				offTheShelfSubstrings,
				manufacturedSubstrings,
				errorSavingFilterString: false
			}
		}
		case CUSTOMIZE_BUTTON_LOADER_UPDATED:
			return {
				...state,
				loading: action.payload
			}
		case SAVE_PART_FILTERING_ERROR:
			return {
				...state,
				errorSavingFilterString: true
			}
		case RESET_SETTINGS_STATE: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
