import { Button } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

import { generateKey } from '../../../Services/Utils/uniqKeyGenerator'
import FlexBox from '../FlexBox'

import { styles } from './styles.css'

const CastorFormHeader = props => {
	const {
		explanationArray,
		explanationHeader,
		isInCard,
		extraExplanation,
		goBack
	} = props
	let paragraph =
		explanationArray.length > 0 &&
		(isObject(explanationArray) || isArray(explanationArray)) &&
		explanationArray.map((string, index) => (
			<div key={generateKey(string)}>
				<p style={styles.explanationText}>{string}</p>
			</div>
		))

	paragraph &&
		explanationHeader &&
		paragraph.unshift(
			<FlexBox alignItems="center">
				{goBack && (
					<Button style={styles.goBackButton} onClick={() => goBack()}>
						<ArrowBack />
					</Button>
				)}
				<h3 key={explanationHeader} style={styles.explanationTextHeader}>
					{explanationHeader}
				</h3>
			</FlexBox>
		)

	return (
		<div
			style={isInCard ? styles.topCardText : styles.topText}
			className="form-header-wrapper"
		>
			{paragraph}
			{extraExplanation && (
				<p style={styles.extraExplanationText}>{extraExplanation}</p>
			)}
		</div>
	)
}

export default CastorFormHeader
