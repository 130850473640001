import React, { FC, memo, useEffect, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { FormControlLabel } from '@material-ui/core'
import cx from 'classnames'

import {
	changeInHousePrintersOnlyCheckbox,
	touchInHousePrintersOnlyCheckbox
} from './CustomizeInHousePrintersActions'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { IUserFilter, IUserFilterNames } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'

import './CustomizeInHousePrinters.scss'

type IProps = {
	className?: string
}

const InHousePrintersOnlyCheckbox: FC<IProps> = ({ className }) => {
	const { userId, userPrinters, inHousePrintersOnly } = useSelector(
		(state: RootStateOrAny) => state.CustomizeInHousePrintersReducer
	)
	const { printers, userFilters } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const { adminSelectedOrganizationId } = useSelector(
		(state: RootStateOrAny) => state.CustomizeReducer
	)
	const dispatch = useDispatch()

	const inHousePrinters = useMemo(
		() => (userId ? userPrinters : printers),
		[printers, userId, userPrinters]
	)

	const inHousePrintersFilter: IUserFilter = userFilters.find(
		(filter: IUserFilter) => filter.name === IUserFilterNames.inHousePrinters
	)

	const onCheckboxChange = () => {
		inHousePrintersFilter.on = !inHousePrintersOnly
		dispatch(
			changeInHousePrintersOnlyCheckbox(
				inHousePrintersFilter,
				userFilters,
				adminSelectedOrganizationId
			)
		)
	}

	useEffect(() => {
		dispatch(touchInHousePrintersOnlyCheckbox(inHousePrintersFilter?.on))
	}, [userFilters])

	return (
		<div className={cx('in-house-printers--checkbox--wrapper', className)}>
			<FormControlLabel
				label={getString('IN_HOUSE_PRINTERS_ONLY')}
				classes={{ root: 'in-house-printers--checkbox' }}
				disabled={!inHousePrinters?.length}
				control={
					<CastorCheckbox
						checked={inHousePrintersFilter?.on}
						inputProps={{
							'aria-label': 'secondary checkbox'
						}}
					/>
				}
				onChange={onCheckboxChange}
			/>
			<div className="in-house-printers--checkbox--explanation">
				<IconFactory iconName="info" />
				{getString('IN_HOUSE_PRINTERS_ONLY_EXPLANATION')}
			</div>
		</div>
	)
}

export default memo(InHousePrintersOnlyCheckbox)
