import { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import cx from 'classnames'
import { isEmpty } from 'lodash'

import AssemblingCostModal from './AssemblingCostModal'
import { useCostAnalysisTableHeaders } from './CostAnalysisTableService'
import DataTable from 'Scenes/Components/DataTable'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { ConfigurationResultTypes } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/ConfigurationResultTypes'
import {
	removeLastElement,
	useCostAnalysisTableRows
} from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostAnalysisTable/CostAnalysisTableHooks'
import { IConfiguration } from 'Scenes/Home/NewPartAnalysis/NewPartConfiguration/NewPartConfigurationTypes'
import {
	defaultNamingPrinterConfiguration,
	manufacturingMethodTypes
} from 'Services/Constants'
import {
	CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import { AssemblingParams } from 'Services/models/IConfiguration'
import { Part } from 'Services/models/IPart'
import { ISolution } from 'Services/models/ISolution'
import { PRINTING_TECHNOLOGY_VALUE_TO_READABLE } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	traditionalMethod: string
	solution: ISolution
	configuration: IConfiguration | any
	chainBenefits: IChainBenefits | any
	shouldShowDetailed?: boolean
	shouldShowNested?: boolean
	part: Part
	showAssemblingCostAlert?: boolean
	assemblingParams?: AssemblingParams | null
	toggleAssemblingCostModal?: (id: number, open: boolean) => void
}

const CostAnalysisTable: FC<IProps> = ({
	traditionalMethod,
	solution,
	configuration,
	chainBenefits,
	shouldShowNested,
	part,
	shouldShowDetailed,
	showAssemblingCostAlert,
	assemblingParams,
	toggleAssemblingCostModal
}) => {
	const { isAmOriginalMaterial } = useSelector((state: RootStateOrAny) => {
		return state?.SolutionAnalysisReducer.states[configuration.id]
	})

	const {
		allConfigurationsOrganizationSettings,
		drawingCostPercentage: _drawingCostPercentage
	} = useSelector((state: RootStateOrAny) => state.MainPartAnalysisReducer)
	const { defaultSettings } = useSelector((state: RootStateOrAny) => state.user)
	const customizationSettings =
		allConfigurationsOrganizationSettings?.[configuration.organizationId]
			?.customizationSettings || defaultSettings

	const drawingCostPercentage =
		customizationSettings.drawingCostPercentage || _drawingCostPercentage

	const drawingPrinter = `${
		PRINTING_TECHNOLOGY_VALUE_TO_READABLE[solution?.printer?.technologyId] ||
		solution?.printerTechnology?.userReadableName ||
		solution?.printer?.technology
	} ${getString('PRINTER')}`

	const isWRConfig =
		configuration.resultType === ConfigurationResultTypes.WeightReduction

	const originalDesignSolution =
		isWRConfig && configuration.originalDesignSolution

	const showRecalculateWarning =
		isWRConfig &&
		!configuration.originalDesignSolution &&
		!configuration.originalDesignPartIsUnprintable

	const showTripleComparison =
		originalDesignSolution || configuration.originalDesignPartIsUnprintable

	let configName = showTripleComparison
		? getString('COST_ANALYSIS_TABLE_HEADER_WR_PART')
		: part.isDrawing
		? drawingPrinter
		: solution?.printer?.name || getString('3D_PRINTING')
	const secondConfigName = showTripleComparison
		? getString('COST_ANALYSIS_TABLE_HEADER_ORIGINAL_DESIGN_PART')
		: ''
	const secondConfigHoverText = configuration.originalDesignPartIsUnprintable
		? getString('COST_ANALYSIS_ORIGINAL_DESIGN_SOLUTION_IS_NOT_PRINTABLE')
		: ''

	if (configuration.cluster) {
		traditionalMethod = getString(
			'PART_ANALYSIS_TABS_TITLE_PART_WITHOUT_CONSOLIDATION'
		)
		configName = getString('PART_ANALYSIS_TABS_TITLE_PART_CONSOLIDATION')
	}

	const onEditAssemblingCostClick = () => {
		if (toggleAssemblingCostModal) {
			toggleAssemblingCostModal(configuration.id, true)
		}
	}

	const onAssemblyModalCancel = () => {
		if (toggleAssemblingCostModal) {
			toggleAssemblingCostModal(configuration.id, false)
		}
	}
	let tableClassName =
		'solution-tab--material-table solution-tab--material-three-column solution-tab--new-table'

	const isStandardCost =
		traditionalMethod === manufacturingMethodTypes.standardCost

	const costAnalysisTableHeaders = useCostAnalysisTableHeaders(
		traditionalMethod,
		configName,
		secondConfigName,
		isAmOriginalMaterial,
		configuration,
		isStandardCost,
		configuration.originalDesignPartIsUnprintable,
		secondConfigHoverText
	)
	const isWeightReduction =
		configuration.name === defaultNamingPrinterConfiguration.weightReduction
	if (isAmOriginalMaterial && !configuration.cluster && !isStandardCost) {
		tableClassName =
			'solution-tab--material-table solution-tab--material-two-column solution-tab--new-table'
	}

	const costAnalysisTableRows: any = useCostAnalysisTableRows(
		solution,
		configuration,
		chainBenefits,
		customizationSettings,
		defaultSettings,
		part,
		drawingCostPercentage,
		originalDesignSolution,
		configuration.originalDesignPartIsUnprintable,
		onEditAssemblingCostClick
	)

	if (isAmOriginalMaterial && !configuration.cluster && !isStandardCost) {
		removeLastElement(costAnalysisTableRows, isWeightReduction)
	}

	if (
		configuration.cluster &&
		(!configuration.clusterCombinedCostDetails ||
			isEmpty(configuration.clusterCombinedCostDetails))
	) {
		return (
			<div className="no-combined-cost-details-wrapper">
				<div className="no-combined-cost-details">
					<IconFactory iconName="info" />
					{getString('COST_ANALYSIS_NO_COMBINED_COST_DETAILS_WARNING_PART1')}
					<Link to={USER_HOME_ROUTE + CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE}>
						{getString(
							'COST_ANALYSIS_NO_ORIGINAL_DESIGN_SOLUTION_WARNING_PART2'
						)}
					</Link>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="cost-comparison-tab--table--wrapper">
				{isWRConfig && showRecalculateWarning && (
					<div className="no-original-design-solution-warning">
						<IconFactory iconName="info" />
						{getString(
							'COST_ANALYSIS_NO_ORIGINAL_DESIGN_SOLUTION_WARNING_PART1'
						)}
						<Link to={USER_HOME_ROUTE + CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE}>
							{getString(
								'COST_ANALYSIS_NO_ORIGINAL_DESIGN_SOLUTION_WARNING_PART2'
							)}
						</Link>
					</div>
				)}
				<DataTable
					shouldShowDetailed={shouldShowDetailed}
					shouldShowNested={shouldShowNested}
					showChildren={true}
					tableClassName={cx(tableClassName, {
						'triple-comparison': showTripleComparison
					})}
					tableHead={costAnalysisTableHeaders}
					tableDataRows={costAnalysisTableRows}
					showBreakLines={true}
					tableStyle={{ alignItems: 'normal' }}
				/>
			</div>
			<AssemblingCostModal
				configurationId={configuration.id}
				onAssemblyModalCancel={onAssemblyModalCancel}
				showAssemblingCostAlert={!!showAssemblingCostAlert}
				assemblingParams={assemblingParams}
			/>
		</>
	)
}

export default memo(CostAnalysisTable)
