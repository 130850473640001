import { FC, memo } from 'react'

import CastorPagination from './CastorPagination'
import CastorPaginationDropdown from './CastorPaginationDropdown'
import { Feature, FeatureComponentId } from 'Services/models/Features'

interface IProps {
	showPagination: boolean
	pageNumber: number
	isLastPage: boolean
	linkTo?: string
	showingFrom: string
	showingTo: string
	limit: number
	total: number
	className?: Record<string, any>
	onNextClick: () => void
	onPrevClick: () => void
	onPageClick: (page: number) => void
}

const CastorPaginationSelector: FC<IProps> = props => {
	const isPaginationDropdown = Feature.isFeatureOn(
		FeatureComponentId.PAGINATION_DROPDOWN
	)

	if (isPaginationDropdown) {
		return <CastorPaginationDropdown {...props} />
	}

	return <CastorPagination {...props} />
}

export default memo(CastorPaginationSelector)
