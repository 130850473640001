import React, { FC, memo } from 'react'
import { Field, FormErrors, InjectedFormProps, reduxForm } from 'redux-form'

import {
	OWNER_INVALID,
	REQUIRED,
	SUBMIT
} from '../../../../../Services/Strings'
import ButtonWithLoader from '../../../../Components/ButtonWithLoader'
import TextField from '../../../../Components/TextField/TextField'
import TransparentButton from '../../../../Components/TransparentButton'
import Flexbox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

import './AdminProjectForm.scss'

const ReduxFormField: any = Field

interface IProps {
	onSubmitClick: any
	projectUpdateLoading: boolean
}

interface IFormData {
	name: string
	owner: string
}

const validate = (values: IFormData) => {
	const errors: FormErrors<IFormData, any> = {
		name: '',
		owner: ''
	}
	if (!values.name) {
		errors.name = REQUIRED
	}
	if (!values.owner) {
		errors.owner = REQUIRED
	}
	if (parseInt(values.owner) < 1) {
		errors.owner = OWNER_INVALID
	}

	return errors
}

const renderTextField = ({
	input,
	label,
	type,
	placeholder,
	meta: { touched, error, warning },
	inputProps
}: any) => (
	<TextField
		wrapperClassName="admin-project-edit-form--field"
		label={label}
		error={error}
		type={type}
		input={input}
		touched={touched}
		placeholder={placeholder}
		inputProps={inputProps}
	/>
)

const AdminProjectForm: FC<InjectedFormProps<IFormData, IProps> & IProps> = ({
	handleSubmit,
	onSubmitClick,
	reset,
	pristine,
	submitting,
	valid,
	projectUpdateLoading
}) => (
	<Flexbox className="admin-project-form" justifyContent="space-between">
		<Flexbox className="admin-project-form" flexDirection="row">
			<ReduxFormField
				name="name"
				component={renderTextField}
				label={`name`}
				type="text"
				placeholder={'name'}
			/>
			<ReduxFormField
				name="owner"
				component={renderTextField}
				label={`owner id`}
				type="number"
				placeholder={'owner id'}
				inputProps={{ min: 1 }}
			/>
		</Flexbox>
		<Flexbox className="admin-project-form" flexDirection="row">
			<Flexbox
				alignItems="center"
				alignSelf="flex-end"
				justifyContent="space-between"
				width="200px"
			>
				<TransparentButton onClick={() => reset()}>
					{getString('RESET')}
				</TransparentButton>
				<ButtonWithLoader
					onClick={handleSubmit(onSubmitClick)}
					loading={projectUpdateLoading}
					disabled={pristine || submitting || !valid}
				>
					{SUBMIT}
				</ButtonWithLoader>
			</Flexbox>
		</Flexbox>
	</Flexbox>
)

export default memo(
	reduxForm<IFormData, IProps>({
		form: 'adminProject',
		validate
	})(AdminProjectForm)
)
