import { cloneDeep, find, isEmpty, map, some } from 'lodash'

import {
	filterAlternativeSolutions,
	MainPartAnalysisService,
	makeAlternativeSolutionsData
} from './MainPartAnalysisService'
import { Action } from 'global actions/ActionModels'
import {
	FEA_ANALYSIS_DATA_FETCHED,
	GRAB_CAD_PRINT_UPDATED,
	PART_FEA_ANALYSIS_DATA_FETCHED,
	PRINTER_ADDED_TO_USER,
	PRINTER_REMOVED_FROM_USER
} from 'global actions/types'
import {
	ALTERNATIVE_SOLUTION_ADDED,
	ALTERNATIVE_SOLUTION_HOVER_ELEMENT,
	ALTERNATIVE_SOLUTION_MAP_FILTER_CHANGE,
	ASSEMBLING_PARAMS_UPDATED,
	CLUSTER_PARTS_FETCHED,
	CONFIGURATION_INHOUSE_ALERT_CANCELED,
	CONFIGURATION_INHOUSE_ALERT_OPENED,
	CONFIGURATION_IS_SET_LEADING,
	EXTEND_CONFIGURATION_ORGANIZATION_SETTINGS,
	GET_ALTERNATIVE_SOLUTION_CALCULATED,
	GET_ALTERNATIVE_SOLUTION_CALCULATION,
	MAIN_PART_ANALYSIS_DISABLE_PART_TOGGLED,
	MAIN_PART_ANALYSIS_SHOW_TOUR_UPDATED,
	MAIN_PART_CHAIN_BENEFITS_QUANTITY_CHANGED,
	MAIN_PART_PART_PRINT_ISSUES_UPDATED,
	MAIN_PART_UPDATE_CHAIN_BENEFITS,
	NEW_ALTERNATIVE_SOLUTION_CALCULATED,
	NEW_ALTERNATIVE_SOLUTION_CALCULATION,
	NEW_SOLUTION_CONFIGURATION_ADDED,
	PART_ANALYSIS_ADD_TOUR_STEPS,
	PART_ANALYSIS_CAM_EXISTENCE_CHANGE,
	PART_ANALYSIS_CAST_TOOLING_CHANGE,
	PART_ANALYSIS_INVESTMENT_CAST_TOOLING_CHANGE,
	PART_ANALYSIS_LEAD_FORM_SUBMITED,
	PART_ANALYSIS_METAL_FORM_SUBMITED,
	PART_ANALYSIS_MOLD_PRICE_CHANGE,
	PART_ANALYSIS_PLASTIC_FORM_SUBMITED,
	PART_ANALYSIS_SET_CURRENT_STEP,
	PART_CONFIGURATIONS_SOLUTIONS_UPDATED,
	PROJECT_PDF_OPTIONS_CHANGE,
	PROJECT_PDF_OPTIONS_SUCCESS,
	SOLUTION_CONFIGURATION_ADDED,
	SOLUTION_CONFIGURATION_MESH_HEALING_PART_UPDATED,
	SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
	SOLUTION_CONFIGURE_CANCELLED,
	SOLUTION_CONFIGURE_PRESSED,
	SOLUTION_FINISH_REMOVED,
	SOLUTION_NAME_UPDATED,
	SOLUTION_PRINTER_MATERIAL_CONFIGURATION_ADDED,
	SOLUTION_PRINTER_MATERIAL_CONFIGURE_CANCELLED,
	SOLUTION_PRINTER_MATERIAL_CONFIGURE_PRESSED,
	SOLUTION_REMOVED,
	SOLUTION_START_REMOVED,
	SOLUTIONS_FETCHED,
	SOLUTIONS_FETCHED_CLEARED,
	SOLUTIONS_FETCHED_FINISHED,
	SOLUTIONS_FETCHED_FORMATED,
	TOGGLE_PRINTING_ALERT,
	TOUR_CONFIGURATION_CALCULATION_LOADING,
	UPDATE_CONFIGURATION_NAME_TOGGLED,
	UPDATE_PART_STATUS,
	UPDATE_QUANTITY_TOGGLED
} from 'global actions/types/partAnalysisTypes'
import { filters } from 'Scenes/Components/FilterPartsGrid/filterPartsEnum'
import { changeFilterValue } from 'Scenes/Components/FilterPartsGrid/FilterPartsService'
import { TourSteps } from 'Scenes/Components/TakeATour/types'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { pdfOptions } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfInterface'
import { pdfTopOrder } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfService'
import {
	defaultConfigurations,
	defaultConstantsMetalCostValues,
	defaultSolution
} from 'Services/Constants'
import { MAIN_PART_ANALYSIS } from 'Services/Constants/pageNamesConstants'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import { ICluster, IClusterPart } from 'Services/models/ICluster'
import { IConfigurationCustomizationSettings } from 'Services/models/IConfigurationCustomizationSettings'
import { PartStatus } from 'Services/models/IPart'
import { Part } from 'Services/models/IPart'
import { Project } from 'Services/models/IProject'
import { IDataSolutionMap } from 'Services/models/ISolution'
import { ISolutionFea } from 'Services/models/ISolutionFea'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import 'Services/Protos.d'
import {
	NAV_TITLE_CLUSTER_ANALYSIS,
	NAV_TITLE_PART_ANALYSIS
} from 'Services/Strings'
import { getTheme } from 'themes/getTheme'

let { metalCostValues } = getTheme()
const defaultMetalCostValues =
	metalCostValues || defaultConstantsMetalCostValues
const mainPartAnalysisService = new MainPartAnalysisService()

export interface MainPartAnalysisInitialState {
	readonly solutions: any[]
	readonly configurations: any[]
	readonly part: Part | null
	readonly cluster: IClusterPart | null
	readonly clusterParts: Part[] | null
	readonly newSolutionAdded: boolean
	readonly newPrinterMaterialConfigurationAdded: boolean
	readonly partId: number | null
	readonly clusterId: number | null
	readonly projectId: string | null
	readonly project: Project | null
	readonly solutionsFetched: boolean
	readonly stepURLExist: boolean
	readonly solutionsFetchCalled: boolean
	readonly initialBatchSize: number
	readonly partMaterialId: number
	readonly partMaterial: object
	readonly imageUrl: string
	readonly plasticFormValues: any
	readonly castFormValues: any
	readonly metalFormValues: any
	readonly fileTiltleName: string
	readonly showInhouseAlert: boolean
	readonly chainBenefits: IChainBenefits | any
	readonly feaId: string | null
	readonly partFeas: ISolutionFea[]
	readonly pageName: string
	readonly tourSteps: TourSteps
	readonly currentStepIndex: number
	readonly currentStepTargetId: string
	readonly noStep: boolean
	readonly tourConfigurationId: number | undefined
	readonly isTourConfigurationCalculationLoading: boolean
	readonly isWeightReductionPart: boolean
	readonly partPrintIssues: PartPrintIssue[]
	readonly updateQuantityLoader: boolean
	readonly updateConfigurationNameLoader: boolean
	readonly initialToleranceValue: string
	// readonly expectedYearsOfDemand?: number
	readonly disableRibbonInfo?: boolean
	readonly disablePart?: boolean
	readonly removeConfigurationLoading: boolean
	readonly drawingCostPercentage: number
	readonly projectPdfOptions: any
	readonly alternativeSolutions: any
	readonly initialAlternativeSolutions: any
	readonly alternativeSolutionsFilter: Array<string>
	readonly alternativeSolutionsLoading: any
	readonly alternativeSolutionHover: any
	readonly showPrintingStandardAlert: boolean
	readonly isInitialLoadingAnyConfiguration: boolean
	readonly allConfigurationsOrganizationSettings: Record<
		number,
		IConfigurationCustomizationSettings
	>
}

const initialState: MainPartAnalysisInitialState = {
	solutions: [defaultSolution],
	configurations: defaultConfigurations,
	part: null,
	cluster: null,
	clusterParts: null,
	fileTiltleName: '',
	newSolutionAdded: false,
	newPrinterMaterialConfigurationAdded: false,
	partId: null,
	project: null,
	clusterId: null,
	projectId: null,
	solutionsFetched: false,
	solutionsFetchCalled: false,
	initialBatchSize: 0,
	partMaterialId: 0,
	partMaterial: {},
	imageUrl: '',
	plasticFormValues: {},
	castFormValues: {},
	metalFormValues: {},
	showInhouseAlert: false,
	chainBenefits: {},
	stepURLExist: false,
	feaId: null,
	partFeas: [],
	pageName: MAIN_PART_ANALYSIS,
	tourSteps: [],
	currentStepIndex: 0,
	currentStepTargetId: '',
	noStep: false,
	tourConfigurationId: undefined,
	isTourConfigurationCalculationLoading: false,
	isWeightReductionPart: false,
	partPrintIssues: [],
	updateQuantityLoader: false,
	updateConfigurationNameLoader: false,
	initialToleranceValue: ToleranceClass.TOLERANCE_CLASS_IRRELEVANT,
	disableRibbonInfo: false,
	disablePart: false,
	removeConfigurationLoading: false,
	drawingCostPercentage: 1,
	projectPdfOptions: {},
	initialAlternativeSolutions: [],
	alternativeSolutions: [],
	alternativeSolutionsFilter: [filters.ALL],
	alternativeSolutionsLoading: false,
	alternativeSolutionHover: null,
	showPrintingStandardAlert: false,
	allConfigurationsOrganizationSettings: {},
	isInitialLoadingAnyConfiguration: false
}

const mainPartReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case SOLUTIONS_FETCHED:
			return {
				...state,
				solutionsFetchCalled: true
			}
		case SOLUTIONS_FETCHED_FINISHED: {
			const {
				disableRibbonInfo,
				drawingCostPercentage,
				part = {},
				cluster = {},
				configurations = [],
				solutions = [],
				partPrintIssues = [],
				allConfigurationsOrganizationSettings
			} = payload
			if (!state.solutionsFetched) {
				const {
					initialBatchSize = state.initialBatchSize,
					partMaterialId = state.partMaterialId
				} = payload
				if (!isEmpty(part)) {
					return setupPartAnalysisState(
						part,
						state,
						payload,
						initialBatchSize,
						partMaterialId,
						drawingCostPercentage,
						allConfigurationsOrganizationSettings
					)
				} else if (!isEmpty(cluster)) {
					cluster.isCluster = true
					const partOrganizationSettings: IConfigurationCustomizationSettings =
						allConfigurationsOrganizationSettings?.[cluster.organizationId]
					const includeSupplyChainCosts =
						!!partOrganizationSettings.customizationSettings
							.includeSupplyChainCosts
					return setupClusterAnalysisState(
						cluster,
						state,
						payload,
						initialBatchSize,
						partMaterialId,
						includeSupplyChainCosts,
						drawingCostPercentage,
						allConfigurationsOrganizationSettings
					)
				}
			} else {
				return {
					...state,
					partPrintIssues: isEmpty(partPrintIssues)
						? state.partPrintIssues
						: partPrintIssues,
					configurations: defaultConfigurations.concat(configurations),
					isInitialLoadingAnyConfiguration: configurations?.some(
						(c: any) => c.loadingInitialAnalysis || c.skippedInitialAnalysis
					),
					drawingCostPercentage,
					disableRibbonInfo,
					cluster: isEmpty(cluster) ? state.cluster || null : cluster,
					part: isEmpty(part) ? state.part || null : part,
					disablePart:
						part?.status === PartStatus.awaitingCombinedHeatmap ||
						cluster?.status === PartStatus.awaitingCombinedHeatmap,
					solutions: solutions.length
						? [defaultSolution].concat(solutions)
						: state.solutions,
					allConfigurationsOrganizationSettings: {
						...state.allConfigurationsOrganizationSettings,
						...allConfigurationsOrganizationSettings
					}
				}
			}
		}
		case SOLUTIONS_FETCHED_FORMATED:
			return { ...state, solutionsFetched: false }
		case SOLUTIONS_FETCHED_CLEARED:
			return {
				...state,
				solutionsFetched: false,
				solutionsFetchCalled: false,
				newSolutionAdded: false,
				newPrinterMaterialConfigurationAdded: false,
				configurations: defaultConfigurations,
				solutions: [defaultSolution],
				part: null,
				cluster: null
			}
		case SOLUTION_CONFIGURE_PRESSED: {
			return {
				...state,
				newSolutionAdded: true
			}
		}
		case SOLUTION_CONFIGURE_CANCELLED:
			return {
				...state,
				newSolutionAdded: false
			}
		case SOLUTION_CONFIGURATION_ADDED:
			return {
				...state,
				newSolutionAdded: false
			}

		case SOLUTION_PRINTER_MATERIAL_CONFIGURE_PRESSED: {
			return {
				...state,
				newPrinterMaterialConfigurationAdded: true
			}
		}
		case SOLUTION_PRINTER_MATERIAL_CONFIGURE_CANCELLED:
			return {
				...state,
				newPrinterMaterialConfigurationAdded: false
			}
		case SOLUTION_PRINTER_MATERIAL_CONFIGURATION_ADDED:
			return {
				...state,
				newPrinterMaterialConfigurationAdded: false
			}

		case NEW_SOLUTION_CONFIGURATION_ADDED: {
			const { configuration, solution, id, partPrintIssues } = payload
			const configurations = state.configurations
				.map(c => {
					if (c.id === id) {
						configuration.newSolution = true
						return configuration
					}
					c.newSolution = false
					return c
				})
				.filter(c => c.id > 0)
			let solutions
			if (solution) {
				solutions = state.solutions.map(s => {
					if (s.id === 0) {
						solution.newSolution = true
						return solution
					}
					s.newSolution = false
					return s
				})
			}

			return {
				...state,
				configurations: defaultConfigurations.concat(configurations),
				solutions: solutions
					? [defaultSolution].concat(solutions)
					: state.solutions,
				newSolutionAdded: false,
				newPrinterMaterialConfigurationAdded: false,
				partPrintIssues
			}
		}
		case ALTERNATIVE_SOLUTION_HOVER_ELEMENT: {
			const { hoverElement } = payload

			return {
				...state,
				alternativeSolutionHover: hoverElement
			}
		}
		case ALTERNATIVE_SOLUTION_ADDED: {
			const { solution, prevSolutionId } = payload
			const solutionExist = state.solutions.find(
				item => item.id === solution.id
			)
			if (!solutionExist) {
				state.solutions.push(solution)
				return {
					...state,
					solutions: state.solutions
				}
			}
			return { ...state }
		}
		case NEW_ALTERNATIVE_SOLUTION_CALCULATION: {
			const { solutionId, isLoading } = payload

			const solutions = cloneDeep(state.initialAlternativeSolutions)
			const changedIndex = solutions.findIndex(
				(solution: any) => solution.id === solutionId
			)

			solutions[changedIndex].isLoading = isLoading

			// disable/enable all solutions if some isLoading
			solutions.map((sol: IDataSolutionMap) => {
				sol.disabled = isLoading
				return sol
			})

			return {
				...state,
				alternativeSolutions: filterAlternativeSolutions(
					state.alternativeSolutionsFilter,
					solutions
				),
				initialAlternativeSolutions: solutions
			}
		}
		case NEW_ALTERNATIVE_SOLUTION_CALCULATED: {
			const { newConfiguration, solutionId } = payload

			const updatedSolutions = cloneDeep(state.initialAlternativeSolutions)
			const oldConfiguration = cloneDeep(state.configurations)

			const changedIndex = state.initialAlternativeSolutions.findIndex(
				(solution: any) => solution.id === solutionId
			)

			updatedSolutions[changedIndex].url = updatedSolutions.length + 1

			return {
				...state,
				alternativeSolutions: filterAlternativeSolutions(
					state.alternativeSolutionsFilter,
					updatedSolutions
				),
				initialAlternativeSolutions: updatedSolutions,
				configurations: oldConfiguration.concat([newConfiguration])
			}
		}
		case GET_ALTERNATIVE_SOLUTION_CALCULATION: {
			if (payload) {
				return {
					...state,
					alternativeSolutionsLoading: payload,
					alternativeSolutions: [],
					initialAlternativeSolutions: []
				}
			}

			return {
				...state,
				alternativeSolutionsLoading: payload
			}
		}
		case GET_ALTERNATIVE_SOLUTION_CALCULATED: {
			const { solutions, drawingCostPercentage, unitSystem } = payload

			const alternativeSolutions = makeAlternativeSolutionsData(
				solutions || [],
				state.configurations,
				drawingCostPercentage,
				unitSystem
			)

			return {
				...state,
				alternativeSolutions: filterAlternativeSolutions(
					state.alternativeSolutionsFilter,
					alternativeSolutions
				),
				initialAlternativeSolutions: alternativeSolutions,
				alternativeSolutionsLoading: false
			}
		}
		case SOLUTION_CONFIGURATION_SOLUTION_UPDATED:
			const { id, configuration, solution } = payload
			let oldSolutionId = 0
			const newConfigurations = state.configurations.map(c => {
				if (c.id === id) {
					oldSolutionId = c.solution?.id || c.solution
					return { ...c, ...configuration }
				}
				return c
			})
			let updated = false
			let newSolutions = state.solutions.map(s => {
				if (s.id === solution?.id) {
					updated = true
					return { ...s, ...solution }
				}
				return s
			})
			if (!updated && solution) {
				newSolutions.push(solution)
			}
			if (!solution && oldSolutionId) {
				// no solution found
				newSolutions = newSolutions.filter(s => s.id !== oldSolutionId)
			}
			return {
				...state,
				configurations: newConfigurations,
				solutions: newSolutions,
				part: configuration?.part || state.part,
				disablePart: false
			}
		case SOLUTION_CONFIGURATION_MESH_HEALING_PART_UPDATED: {
			const { part, configurations, partPrintIssues } = payload

			const sortConfigurations =
				mainPartAnalysisService.sortConfiguration(configurations)

			return {
				...state,
				part,
				partPrintIssues: partPrintIssues || state.partPrintIssues,
				configurations: defaultConfigurations.concat(sortConfigurations)
			}
		}
		case PART_CONFIGURATIONS_SOLUTIONS_UPDATED: {
			const { part, cluster, configurations, solutions, partPrintIssues } =
				payload

			const sortConfigurations =
				mainPartAnalysisService.sortConfiguration(configurations)

			const partEntity = part || state.part

			return {
				...state,
				part: partEntity,
				initialToleranceValue: partEntity?.customToleranceValue,
				cluster: cluster || state.cluster,
				partPrintIssues: partPrintIssues || state.partPrintIssues,
				configurations: defaultConfigurations.concat(sortConfigurations),
				solutions: solutions
					? [defaultSolution].concat(solutions)
					: state.solutions
			}
		}

		case MAIN_PART_PART_PRINT_ISSUES_UPDATED:
			const { partPrintIssues } = payload
			return { ...state, partPrintIssues }

		case UPDATE_QUANTITY_TOGGLED:
			return { ...state, updateQuantityLoader: !state.updateQuantityLoader }

		case UPDATE_CONFIGURATION_NAME_TOGGLED:
			return {
				...state,
				updateConfigurationNameLoader: !state.updateConfigurationNameLoader
			}

		case ASSEMBLING_PARAMS_UPDATED: {
			const { configuration: updatedConfiguration } = payload

			const newConfigurations = state.configurations.map(configuration => {
				if (configuration.id === updatedConfiguration.id) {
					return {
						...configuration,
						clusterCombinedCostDetails:
							updatedConfiguration.clusterCombinedCostDetails
					}
				}
				return configuration
			})

			return {
				...state,
				configurations: newConfigurations
			}
		}

		case MAIN_PART_CHAIN_BENEFITS_QUANTITY_CHANGED: {
			const { value } = payload
			return {
				...state,
				tempStorageQuantity: parseInt(value)
			}
		}

		case MAIN_PART_UPDATE_CHAIN_BENEFITS: {
			const { partPrintIssues } = payload

			return {
				...state,
				partPrintIssues: partPrintIssues || state.partPrintIssues
			}
		}
		case SOLUTION_NAME_UPDATED: {
			const { id, solutionName } = payload
			const newConfigurations = state.configurations.map(configuration => {
				if (configuration.id === id) {
					return { ...configuration, name: solutionName }
				}
				return configuration
			})
			return {
				...state,
				configurations: newConfigurations
			}
		}
		case SOLUTION_REMOVED: {
			const { configurations, drawingCostPercentage, unitSystem } = payload
			const sortConfigurations =
				mainPartAnalysisService.sortConfiguration(configurations)

			const alternativeSolutions = makeAlternativeSolutionsData(
				state.initialAlternativeSolutions || [],
				sortConfigurations,
				drawingCostPercentage,
				unitSystem
			)

			return {
				...state,
				alternativeSolutions: filterAlternativeSolutions(
					state.alternativeSolutionsFilter,
					alternativeSolutions
				),
				initialAlternativeSolutions: alternativeSolutions,
				configurations: defaultConfigurations.concat(sortConfigurations)
			}
		}
		case CONFIGURATION_IS_SET_LEADING: {
			const { leadingConfigurationId, leadingByUserChoice } = payload

			const updatedConfigurations = state.configurations.map(configuration => {
				if (!configuration?.dontShow) {
					configuration.isLeading = configuration.id === leadingConfigurationId
					configuration.leadingByUserChoice =
						configuration.id === leadingConfigurationId
							? leadingByUserChoice
							: false
				}

				return configuration
			})

			return {
				...state,
				configurations: updatedConfigurations
			}
		}
		case SOLUTION_START_REMOVED: {
			return {
				...state,
				removeConfigurationLoading: true
			}
		}
		case SOLUTION_FINISH_REMOVED: {
			return {
				...state,
				removeConfigurationLoading: false
			}
		}
		case PART_ANALYSIS_LEAD_FORM_SUBMITED: {
			const { part } = payload
			return {
				...state,
				part
			}
		}
		case PART_ANALYSIS_PLASTIC_FORM_SUBMITED:
			const { part } = payload
			const plasticFormValues = {
				moldCost: part.moldCost || '',
				moldPartCost: part.moldPartCost || '',
				moldMaintenanceCost: part.moldMaintenanceCost || '',
				DFMCosts: part.DFMCosts || ''
			}
			const castFormValues = {
				castToolingCost: part.castToolingCost || '',
				investmentCastToolingCost: part.investmentCastToolingCost || ''
			}
			return {
				...state,
				part,
				plasticFormValues,
				castFormValues
			}
		case PART_ANALYSIS_METAL_FORM_SUBMITED: {
			const {
				part,
				part: { cncProps }
			} = payload
			const metalFormValues = {
				camExistence:
					cncProps.camExistence != null
						? cncProps.camExistence
						: defaultMetalCostValues.camExistence,
				accuracy:
					cncProps.accuracy != null
						? cncProps.accuracy
						: defaultMetalCostValues.accuracy,
				complexity:
					cncProps.complexity != null
						? cncProps.complexity
						: defaultMetalCostValues.complexity,
				cncProgrammingPricePerHour: cncProps.cncProgrammingPricePerHour || '',
				operationCostPerHour: cncProps.operationCostPerHour || ''
			}
			return {
				...state,
				part,
				metalFormValues
			}
		}

		case PART_ANALYSIS_CAM_EXISTENCE_CHANGE: {
			const { camExistence } = payload
			const { part, metalFormValues } = state

			return {
				...state,
				part: {
					...part,
					cncProps: {
						...part?.cncProps,
						camExistence
					}
				},
				metalFormValues: {
					...metalFormValues,
					camExistence: camExistence
				}
			}
		}

		case PART_ANALYSIS_CAST_TOOLING_CHANGE: {
			const { castToolingCost } = payload
			const { part } = state

			return {
				...state,
				part: {
					...part,
					castToolingCost: castToolingCost
				}
			}
		}

		case PART_ANALYSIS_INVESTMENT_CAST_TOOLING_CHANGE: {
			const { investmentCastToolingCost } = payload
			const { part } = state

			return {
				...state,
				part: {
					...part,
					investmentCastToolingCost: investmentCastToolingCost
				}
			}
		}

		case PART_ANALYSIS_MOLD_PRICE_CHANGE: {
			const { moldCost } = payload
			const { part } = state

			return {
				...state,
				part: {
					...part,
					moldCost: moldCost
				}
			}
		}

		case CONFIGURATION_INHOUSE_ALERT_CANCELED:
			return {
				...state,
				showInhouseAlert: false
			}
		case CONFIGURATION_INHOUSE_ALERT_OPENED:
			return {
				...state,
				showInhouseAlert: true
			}
		case FEA_ANALYSIS_DATA_FETCHED:
			const { solutionFea } = payload as { solutionFea: ISolutionFea }
			const newPartFeas = state.partFeas.map(pF => {
				if (pF.id === solutionFea.id) {
					return { ...pF, ...solutionFea }
				}
				return pF
			})
			return { ...state, partFeas: newPartFeas }
		case PART_FEA_ANALYSIS_DATA_FETCHED:
			const { partFeas } = payload
			return { ...state, partFeas }
		case PART_ANALYSIS_ADD_TOUR_STEPS:
			return { ...state, tourSteps: payload }
		case PART_ANALYSIS_SET_CURRENT_STEP: {
			const { tourSteps, currentStepIndex } = state
			return {
				...state,
				noStep: false,
				currentStepIndex: currentStepIndex + 1,
				currentStepTargetId: tourSteps[currentStepIndex + 1]?.target
			}
		}
		case CLUSTER_PARTS_FETCHED: {
			const { clusterParts } = payload
			return {
				...state,
				clusterParts: clusterParts
					? state.clusterParts?.concat(clusterParts)
					: state.clusterParts
			}
		}
		case MAIN_PART_ANALYSIS_SHOW_TOUR_UPDATED:
			return { ...state, showTour: payload.showTour }
		case TOUR_CONFIGURATION_CALCULATION_LOADING:
			return { ...state, isTourConfigurationCalculationLoading: payload }

		case MAIN_PART_ANALYSIS_DISABLE_PART_TOGGLED:
			const { updating } = payload
			return { ...state, disablePart: updating }

		case PROJECT_PDF_OPTIONS_SUCCESS: {
			const options = payload?.projectPdfOptions || state.projectPdfOptions
			let orderingPdfOptions: Record<string, any> = {}
			for (const configurationId in options) {
				orderingPdfOptions[configurationId as string] = Object.keys(
					options[configurationId]
				)
					.sort((key, prevKey) => {
						return (
							pdfTopOrder.findIndex(element => key.includes(element)) -
							pdfTopOrder.findIndex(element => prevKey.includes(element))
						)
					})
					.reduce((obj: any, key) => {
						obj[key] = options[configurationId][key]
						return obj
					}, {})
			}

			return {
				...state,
				projectPdfOptions: orderingPdfOptions
			}
		}

		case PROJECT_PDF_OPTIONS_CHANGE: {
			const {
				checkedOption,
				parentName,
				currentName,
				nestedParentName,
				configurationId
			} = payload
			let stateProjectPdfOptions = cloneDeep(state.projectPdfOptions)
			let updatedData = stateProjectPdfOptions[configurationId]

			for (let option in updatedData) {
				// 1st level
				if (option === currentName) {
					updatedData[currentName].checked = checkedOption
					if (updatedData[currentName].data) {
						// 2nd level
						updatedData[currentName].data = updatedData[currentName].data.map(
							(elem: pdfOptions) => {
								elem.checked = checkedOption
								if (elem.data) {
									// 3nd level
									elem.data = elem.data?.map((childElem: pdfOptions) => {
										childElem.checked = checkedOption
										return childElem
									})
								}

								return elem
							}
						)
					}
				}

				// 2nd level
				if (option === parentName) {
					const index = updatedData[parentName].data.findIndex(
						(elem: pdfOptions) => elem.name === currentName
					)

					if (index > -1) {
						let nestedChildrenData = updatedData[parentName].data[index].data
						updatedData[parentName].data[index].checked = checkedOption

						if (nestedChildrenData) {
							// 3d level
							updatedData[parentName].data[index].data = nestedChildrenData.map(
								(elem: pdfOptions) => {
									elem.checked = checkedOption
									return elem
								}
							)
						}
					}

					// 3d level
					if (nestedParentName) {
						const childOption = updatedData[parentName].data.find(
							(elem: pdfOptions) => elem.name === nestedParentName
						)
						const childOptionIndex = updatedData[parentName].data.findIndex(
							(elem: pdfOptions) => elem.name === nestedParentName
						)
						const childIndex = childOption.data.findIndex(
							(elem: pdfOptions) => elem.name === currentName
						)
						if (childIndex > -1) {
							updatedData[parentName].data[childOptionIndex].data[
								childIndex
							].checked = checkedOption
						}

						// check if some of 3d level is on then check the 2nd level
						if (
							some(
								updatedData[parentName].data[childOptionIndex].data,
								option => option.checked
							)
						) {
							updatedData[parentName].data[childOptionIndex].checked =
								checkedOption
						}
					}

					if (updatedData[parentName].disabled === false) {
						updatedData[parentName].checked = some(
							updatedData[parentName].data,
							(elem: pdfOptions) => elem.checked
						)
					}
				}
			}

			return {
				...state,
				projectPdfOptions: stateProjectPdfOptions
			}
		}

		case GRAB_CAD_PRINT_UPDATED: {
			const { configurations: updatedConfigurations } = payload
			let configurations = cloneDeep(state.configurations)

			configurations.map(conf => {
				const updatedConfiguration = find(
					updatedConfigurations,
					newConf => newConf.id === conf.id
				)
				if (
					updatedConfiguration &&
					conf.grabCadPrintConfiguration !==
						updatedConfiguration.grabCadPrintConfiguration
				) {
					conf.grabCadPrintConfiguration =
						updatedConfiguration.grabCadPrintConfiguration
				}

				return conf
			})

			return {
				...state,
				configurations: configurations
			}
		}

		case TOGGLE_PRINTING_ALERT: {
			const { showPrintingStandardAlert } = payload

			return {
				...state,
				showPrintingStandardAlert: showPrintingStandardAlert
			}
		}

		case ALTERNATIVE_SOLUTION_MAP_FILTER_CHANGE: {
			const { value } = payload
			const updatedValue = changeFilterValue(
				value,
				state.alternativeSolutionsFilter
			)
			return {
				...state,
				alternativeSolutionsFilter: updatedValue,
				alternativeSolutions: filterAlternativeSolutions(
					updatedValue,
					state.initialAlternativeSolutions
				)
			}
		}

		case UPDATE_PART_STATUS: {
			const { cluster } = state
			const { partId } = payload
			if (cluster) {
				return {
					...state,
					cluster: {
						...cluster,
						parts: map(cluster.parts, p => {
							if (p.id === partId) {
								p.status = PartStatus.awaitingCombinedHeatmap
							}
							return p
						})
					}
				}
			}
			return {
				...state
			}
		}

		case EXTEND_CONFIGURATION_ORGANIZATION_SETTINGS: {
			const { organizationId, configurationOrganizationSettings } = payload
			return {
				...state,
				allConfigurationsOrganizationSettings: {
					...state.allConfigurationsOrganizationSettings,
					[organizationId]: configurationOrganizationSettings
				}
			}
		}

		case PRINTER_ADDED_TO_USER: {
			const { printers, organizationId } = payload
			if (!state.allConfigurationsOrganizationSettings[organizationId]) {
				return { ...state, allConfigurationsOrganizationSettings: {} }
			}
			const configurationOrganizationSettings =
				state.allConfigurationsOrganizationSettings[organizationId]
			configurationOrganizationSettings.printers = printers

			return {
				...state,
				allConfigurationsOrganizationSettings: {
					...state.allConfigurationsOrganizationSettings,
					[organizationId]: configurationOrganizationSettings
				}
			}
		}

		case PRINTER_REMOVED_FROM_USER: {
			const { printers, organizationId } = payload
			if (!state.allConfigurationsOrganizationSettings[organizationId]) {
				return { ...state, allConfigurationsOrganizationSettings: {} }
			}
			const configurationOrganizationSettings =
				state.allConfigurationsOrganizationSettings[organizationId]
			configurationOrganizationSettings.printers = printers

			return {
				...state,
				allConfigurationsOrganizationSettings: {
					...state.allConfigurationsOrganizationSettings,
					[organizationId]: configurationOrganizationSettings
				}
			}
		}

		default:
			return state
	}
}

const setupPartAnalysisState = (
	part: Part,
	state: any,
	payload: any,
	initialBatchSize: number,
	partMaterialId: number,
	drawingCostPercentage: number = 1,
	allConfigurationsOrganizationSettings: Record<number, any>
) => {
	const { solutions, configurations, ...otherPayloads } = payload

	const sortConfigurations =
		mainPartAnalysisService.sortConfiguration(configurations)

	const plasticFormValues = {
		moldCost: part.moldCost || '',
		moldPartCost: part.moldPartCost || '',
		moldMaintenanceCost: part.moldMaintenanceCost || '',
		DFMCosts: part.DFMCosts || ''
	}
	const castFormValues = {
		castToolingCost: part.castToolingCost || '',
		investmentCastToolingCost: part.investmentCastToolingCost || ''
	}
	const cncProps = part.cncProps || {}

	const metalFormValues = {
		camExistence:
			cncProps.camExistence != null
				? cncProps.camExistence
				: defaultMetalCostValues.camExistence,
		accuracy:
			cncProps.accuracy != null
				? cncProps.accuracy
				: defaultMetalCostValues.accuracy,
		complexity:
			cncProps.complexity != null
				? cncProps.complexity
				: defaultMetalCostValues.complexity,
		cncProgrammingPricePerHour: cncProps.cncProgrammingPricePerHour || '',
		operationCostPerHour: cncProps.operationCostPerHour || ''
	}
	const imageUrl = part.imageURL

	return {
		...state,
		...otherPayloads,
		solutions: [defaultSolution].concat(solutions),
		configurations: defaultConfigurations.concat(sortConfigurations),
		isInitialLoadingAnyConfiguration: sortConfigurations?.some(
			(c: any) => c.loadingInitialAnalysis || c.skippedInitialAnalysis
		),
		solutionsFetched: true,
		initialBatchSize,
		partMaterialId,
		imageUrl,
		plasticFormValues,
		castFormValues,
		metalFormValues,
		fileTiltleName: `${part.partNumber} ${NAV_TITLE_PART_ANALYSIS}`,
		stepURLExist: part.stepURL != null,
		disablePart: part.status === PartStatus.awaitingCombinedHeatmap,
		drawingCostPercentage,
		showPrintingStandardAlert: !part.printingStandardsAlertAccepted,
		allConfigurationsOrganizationSettings: {
			...state.allConfigurationsOrganizationSettings,
			...allConfigurationsOrganizationSettings
		}
	}
}

const setupClusterAnalysisState = (
	cluster: ICluster,
	state: any,
	payload: any,
	initialBatchSize: number,
	partMaterialId: number,
	includeSupplyChainCosts: boolean = false,
	drawingCostPercentage: number = 1,
	allConfigurationsOrganizationSettings: Record<number, any>
) => {
	const { solutions, configurations, ...otherPayloads } = payload

	const sortConfigurations =
		mainPartAnalysisService.sortConfiguration(configurations)

	const plasticFormValues = {
		moldCost: '',
		moldPartCost: '',
		moldMaintenanceCost: '',
		DFMCosts: ''
	}

	const castFormValues = {
		castToolingCost: '',
		investmentCastToolingCost: ''
	}
	const metalFormValues = null
	const imageUrl = cluster.imageURL
	const chainBenefits = mainPartAnalysisService.getChainBenefits(
		cluster,
		includeSupplyChainCosts
	)
	return {
		...state,
		...otherPayloads,
		clusterParts: cluster?.parts,
		solutions: state.solutions.concat(solutions),
		configurations: state.configurations.concat(sortConfigurations),
		isInitialLoadingAnyConfiguration: sortConfigurations?.some(
			(c: any) => c.loadingInitialAnalysis || c.skippedInitialAnalysis
		),
		solutionsFetched: true,
		initialBatchSize,
		partMaterialId,
		imageUrl,
		plasticFormValues,
		castFormValues,
		metalFormValues,
		fileTiltleName: NAV_TITLE_CLUSTER_ANALYSIS,
		chainBenefits,
		stepURLExist: cluster.stepURL != null,
		disablePart: cluster.status === PartStatus.awaitingCombinedHeatmap,
		isCluster: cluster?.isCluster,
		drawingCostPercentage,
		showPrintingStandardAlert: !cluster.printingStandardsAlertAccepted,
		allConfigurationsOrganizationSettings: {
			...state.allConfigurationsOrganizationSettings,
			...allConfigurationsOrganizationSettings
		}
	}
}

export default mainPartReducer
