import { FC, memo } from 'react'

import { FormControlLabel } from '@material-ui/core'

import CastorCheckbox from '../CastorCheckbox'
import CastorSwitch from '../CastorSwitch'
import DetailsPopup from '../DetailsPopup'
import { getString } from 'Services/Strings/StringService'
import OverrideComponentHOC from 'themes/OverrideComponentHOC'

const FormControlLabelTSX: any = FormControlLabel

interface IProps {
	simpleInhouseConfiguration: boolean
	disableConfiguration: boolean
	onSimpleInhouseConfigurationChange: Function
	isNewDesign?: boolean
	disabledWithPopup?: boolean
}

const ControlForm: FC<any> = ({ isNewDesign, ...props }) => {
	if (isNewDesign) return <CastorCheckbox {...props} />

	return <CastorSwitch {...props} />
}

const SimpleConfigurationInHouse: FC<IProps & any> = ({
	simpleInhouseConfiguration,
	disableConfiguration,
	onSimpleInhouseConfigurationChange,
	isNewDesign,
	disabledWithPopup
}: IProps) => {
	return disableConfiguration ? (
		<div />
	) : (
		<DetailsPopup
			isHover={disabledWithPopup}
			data={getString('UPLOAD_PROJECT_DISABLED_IN_HOUSE_PRINTER_EXPLANATION')}
			popperDirection="right"
			popperClassName="info-box-wrapper details-popup--contact-us"
			popperContactClassName="info-box-data"
			targetClassName="solution-feature-modal-checkbox-popup"
		>
			<FormControlLabelTSX
				control={
					<ControlForm
						isNewDesign={isNewDesign}
						onChange={(e: any) =>
							onSimpleInhouseConfigurationChange(e.target.checked)
						}
						checked={simpleInhouseConfiguration}
						disabled={disabledWithPopup}
					/>
				}
				label={
					<div className="simpleConfiguration--toggle">
						{getString('IN_HOUSE_PRINTERS_ONLY')}
					</div>
				}
			/>
		</DetailsPopup>
	)
}

export default memo(OverrideComponentHOC(SimpleConfigurationInHouse))
